import React from "react";
import { useTranslation } from "react-i18next";
const BlockProduct = () => {
  /// TRANSLATION
  const { t } = useTranslation();
  return (
    <div id="soldOut" className="detail__product__sold__out">
      {/* <div className="detail__product__sold__out__icon">
        <InputAdornment position="start">
          <Error />
        </InputAdornment>
      </div> */}
      <div className="detail__product__sold__out__badge">
        <div className="detail__product__sold__out__badge__motto">
          {t("__product_Detail_Module_Soldout_Text")}
        </div>
      </div>
    </div>
  );
};

export default BlockProduct;
