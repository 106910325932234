import { call, put, takeLatest } from "redux-saga/effects";
import {
  DATA_FETCH_FAILED,
  REQUEST_CART_ADD_MEMBERSHIP,
  REQUEST_CART_CANCEL_ORDER,
  REQUEST_CART_COUPON_LIST,
  REQUEST_CART_CURRENT_ORDER,
  REQUEST_CART_TAX_ORDER,
  REQUEST_CART_INVENTORY_ASSYGN,
  REQUEST_CART_GET_CLOSED_ORDER,
  REQUEST_CART_GET_ORDER,
  REQUEST_CART_ORDER_CHARGES,
  REQUEST_CLOSE_CART_ORDER,
  RESPONSE_CART_ADD_MEMBERSHIP,
  RESPONSE_CART_CANCEL_ORDER,
  RESPONSE_CART_COUPON_LIST,
  RESPONSE_CART_CURRENT_ORDER,
  RESPONSE_CART_TAX_ORDER,
  RESPONSE_CART_GET_CLOSED_ORDER,
  RESPONSE_CART_GET_ORDER,
  RESPONSE_CART_ORDER_CHARGES,
  RESPONSE_CLOSE_CART_ORDER,
  RESPONSE_CART_INVENTORY_ASSYGN,
  REQUEST_CLOSE_CART_ORDER_GUEST,
  RESPONSE_CLOSE_CART_ORDER_GUEST,
  RESPONSE_CART_GET_GIFT_LIST,
  REQUEST_CART_GET_GIFT_LIST,
  RESPONSE_CART_ADD_CONFIGURABLE_GIFT,
  REQUEST_CART_ADD_CONFIGURABLE_GIFT,
  RESPONSE_FIRST_CHARGE_SITE_RELOAD,
} from "../../Constants";
import Cart from "../../../../Libraries/Cart";

function* requestCartCurrentOrder(data) {
  try {
    const response = yield call(
      Cart.CartOrderOpCurrentOrder,
      data.payload.parameters
    );
    const { opType } = data.payload;
    yield put({
      type: RESPONSE_CART_CURRENT_ORDER,
      payload: { response: response.cartOrder_OpCurrentOrder, opType },
    });
  } catch (error) {
    console.error("current order ex error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}
function* requestCartTaxOrder(data) {
  try {
    const response = yield call(
      Cart.CartOrderOpCurrentOrder,
      data.payload.parameters
    );
    yield put({
      type: RESPONSE_CART_TAX_ORDER,
      payload: { response: response.cartOrder_OpCurrentOrder },
    });
  } catch (error) {
    console.error("current TAX order ex error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}
function* requestCartAssignInventory(data) {
  try {
    const response = yield call(
      Cart.CartOrderOpCurrentOrder,
      data.payload.parameters
    );
    yield put({
      type: RESPONSE_CART_INVENTORY_ASSYGN,
      payload: { response: response.cartOrder_OpCurrentOrder },
    });
  } catch (error) {
    console.error("current TAX order ex error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

function* requestGetCartOrder(data) {
  try {
    const response = yield call(
      Cart.GetCartOrder,
      data.payload.userId,
      data.payload.sessionId
    );
    yield put({
      type: RESPONSE_CART_GET_ORDER,
      payload: { response: response.cartOrder_GetOrder },
    });
  } catch (error) {
    console.error("get order ex error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

function* requestGetCartClosedOrder(data) {
  try {
    const response = yield call(
      Cart.GetCartClosedOrder,
      data.payload.userId,
      data.payload.sessionId
    );
    yield put({
      type: RESPONSE_CART_GET_CLOSED_ORDER,
      payload: { response: response.cartOrder_GetClosedOrder },
    });
  } catch (error) {
    console.error("get order ex error", error);

    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

function* requestGetCouponList(data) {
  try {
    const response = yield call(Cart.CartGetCouponList, data.payload.sessionId);
    yield put({
      type: RESPONSE_CART_COUPON_LIST,
      payload: { response: response.cartGetCouponList },
    });
  } catch (error) {
    console.error("get coupon List ex error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}
function* requestCartCancelOrder(data) {
  try {
    const response = yield call(Cart.CartCancelOrder, data.payload.sessionId);
    yield put({
      type: RESPONSE_CART_CANCEL_ORDER,
      payload: { response: response.cartOrder_CancelOrder },
    });
  } catch (error) {
    console.error("cancel order ex error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}
function* requestCartOrderCharges(data) {
  try {
    const response = yield call(
      Cart.CartOrderCharges,
      data.payload.opc,
      data.payload.orderId
    );
    yield put({
      type: RESPONSE_CART_ORDER_CHARGES,
      payload: { response: response.cartOrder_Charges },
    });
  } catch (error) {
    console.error("charges order ex error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}
function* requestCartAddMembership(data) {
  try {
    const response = yield call(
      Cart.CartOrderAddMembership,
      data.payload.idPedidoDet,
      data.payload.idpromo,
      data.payload.planType,
      data.payload.sessionId,
      data.payload.userGuid,
      data.payload.withAssign,
      data.payload.itemCode
    );
    yield put({
      type: RESPONSE_CART_ADD_MEMBERSHIP,
      payload: { response: response.cartOrder_AddMembershipOrder },
    });
  } catch (error) {
    console.error("add membership ex error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}
function* requestCartCloseOrder(data) {
  try {
    const response = yield call(
      Cart.CartCloseOrder,
      data.payload.sessionId,
      data.payload.benefitType,
      data.payload.evaluatePt
    );
    yield put({
      type: RESPONSE_CLOSE_CART_ORDER,
      payload: { response: response.cartCloseOrder },
    });
    if (response.cartCloseOrder.code == 100)
      yield put({
        type: RESPONSE_FIRST_CHARGE_SITE_RELOAD,
        payload: { firstChargeModel: response.cartCloseOrder.user },
      });
  } catch (error) {
    console.error("add membership ex error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}
///////
function* requestCloseCartOrderGuest(data) {
  try {
    const response = yield call(
      Cart.CartCloseOrderGuest,
      data.payload.sessionId,
      data.payload.benefitType,
      data.payload.parameters
    );
    yield put({
      type: RESPONSE_CLOSE_CART_ORDER,
      payload: { response: response.cartCloseOrderGuest },
    });
    if (response.cartCloseOrderGuest.code == 100)
      yield put({
        type: RESPONSE_FIRST_CHARGE_SITE_RELOAD,
        payload: { firstChargeModel: response.cartCloseOrderGuest.user },
      });
  } catch (error) {
    console.error("add membership ex error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

function* requestCartGetGiftList(data) {
  try {
    const response = yield call(Cart.CartGetGiftList, data.payload.sessionId);
    yield put({
      type: RESPONSE_CART_GET_GIFT_LIST,
      payload: { response: response.cartGetGiftList },
    });
  } catch (error) {
    console.error("add membership ex error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}
function* requestCartAddConfigurableGift(data) {
  try {
    const response = yield call(
      Cart.CartAddConfigurableGift,
      data.payload.sessionId,
      data.payload.parameters
    );
    yield put({
      type: RESPONSE_CART_ADD_CONFIGURABLE_GIFT,
      payload: { response: response.cartAddConfigurableGift },
    });
  } catch (error) {
    console.error("add membership ex error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

export function* watchRequestCartCurrentOrder() {
  yield takeLatest(REQUEST_CART_CURRENT_ORDER, requestCartCurrentOrder);
}
export function* watchRequestCartTaxOrder() {
  yield takeLatest(REQUEST_CART_TAX_ORDER, requestCartTaxOrder);
}
export function* watchRequestGetCartOrder() {
  yield takeLatest(REQUEST_CART_GET_ORDER, requestGetCartOrder);
}
export function* watchRequestGetCouponList() {
  yield takeLatest(REQUEST_CART_COUPON_LIST, requestGetCouponList);
}
export function* watchRequestCartCancelOrder() {
  yield takeLatest(REQUEST_CART_CANCEL_ORDER, requestCartCancelOrder);
}
export function* watchRequestCartOrderCharges() {
  yield takeLatest(REQUEST_CART_ORDER_CHARGES, requestCartOrderCharges);
}
export function* watchRequestCartAddMembership() {
  yield takeLatest(REQUEST_CART_ADD_MEMBERSHIP, requestCartAddMembership);
}
export function* watchRequestCartCloseOrder() {
  yield takeLatest(REQUEST_CLOSE_CART_ORDER, requestCartCloseOrder);
}
export function* watchRequestGetCartClosedOrder() {
  yield takeLatest(REQUEST_CART_GET_CLOSED_ORDER, requestGetCartClosedOrder);
}
export function* watchRequestCartAssignInventory() {
  yield takeLatest(REQUEST_CART_INVENTORY_ASSYGN, requestCartAssignInventory);
}
export function* watchRequestCloseCartOrderGuest() {
  yield takeLatest(REQUEST_CLOSE_CART_ORDER_GUEST, requestCloseCartOrderGuest);
}
export function* watchRequestCartGetGiftList() {
  yield takeLatest(REQUEST_CART_GET_GIFT_LIST, requestCartGetGiftList);
}
export function* watchRequestCartAddConfigurableGift() {
  yield takeLatest(
    REQUEST_CART_ADD_CONFIGURABLE_GIFT,
    requestCartAddConfigurableGift
  );
}
