import { Skeleton } from "@mui/material";
import React, { Fragment, useState } from "react";
import { isMobile, isTablet } from "react-device-detect";

const YourBusinessBanner = ({ t, isLanguageLoaded }) => {
  return (
    <div
      className={`your__business__banner__main ${
        isTablet ? "tablet" : isMobile ? "mobile" : ""
      }`}
    >
      {!isLanguageLoaded ? (
        <Skeleton
          variant="wave"
          width={"95%"}
          height={40}
          style={{ marginBottom: 10 }}
        />
      ) : (
        <span
          className={`your__business__banner__main__title ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          {t("__your__business__new__members__title")}
        </span>
      )}
      {!isLanguageLoaded ? (
        <Skeleton
          variant="wave"
          width={"95%"}
          height={50}
          style={{ marginBottom: 10 }}
        />
      ) : (
        <span
          className={`your__business__banner__main__subtitle ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          {t("__your__business__new__members__subtitle__one")}
        </span>
      )}

      {!isLanguageLoaded ? (
        <Skeleton
          variant="wave"
          width={"95%"}
          height={20}
          style={{ marginBottom: 10 }}
        />
      ) : (
        <span
          className={`your__business__banner__main__warning ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          {t("__your__business__new__members__subitle__two")}
        </span>
      )}
    </div>
  );
};

export default YourBusinessBanner;
