import { call, put, takeLatest } from 'redux-saga/effects'
import {REQUEST_FOOTER_MODULE_INFO, RESPONSE_FOOTER_MODULE_INFO, DATA_FETCH_FAILED} from '../../Constants'
import FooterModule from '../../../../Libraries/Footer'



function* requestFooterModuleInfo(){
    try {
        const response = yield call(FooterModule.GetFooterModule);
        yield put({type: RESPONSE_FOOTER_MODULE_INFO, payload: {response:response.getModules}});

    } catch (error) {
        console.error("load footer module error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}

export function* watchRequestFooterModuleInfo() {
    yield takeLatest(REQUEST_FOOTER_MODULE_INFO, requestFooterModuleInfo);
}