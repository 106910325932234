import React, { useState, Fragment, useEffect } from "react";
import {
  Button,
  FormControlLabel,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  MenuItem,
} from "@mui/material";
import ModalCongratulations from "../../base/components/ModalCongratulations";
import ListRepayment from "./ListRepayment";
import Lote from "../../../../assets/media/images/ModalIcons/lote.svg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import ItemProductRepayment from "./ItemProductRepayment";
import ItemProductRepaymentNew from "./ItemProductRepaymentNew";
import ModalSliderRepaymentAll from "./ModalSliderRepaymentAll";
import { CardsMobileRepayment } from "./CardsMobileRepayment";
//
import {
  requestPartialRefund,
  requestFullRefund,
  requestCleanRequestFullRefundReducer,
} from "./../../../ReduxSaga/Actions/Repayment";
import { isDesktop } from "react-device-detect";
import { requestLoggUserActivity } from "./../../../ReduxSaga/Actions/General";

const ShoppingRepaymentContainer = () => {
  //Notistack
  const { enqueueSnackbar } = useSnackbar();

  //state
  const [kitsArray, setKitsArray] = useState([]); //ARTICULOS PARA DEVOLVER
  const [value, setValue] = useState("");
  const [contentShow, setContenShow] = useState("");
  const [returnOpc, setReturnOpc] = useState("");
  const [isOpenCongratulations, setIsOpenCongratulations] = useState(false);
  const [isOpenNotification, setIsOpenNotification] = useState(true);
  const [isOpenModalReturnAll, setIsOpenModalReturnAll] = useState(false);
  ///
  const [orderDetails, setOrderDetails] = useState([]);
  const [orderSelected, setOrderSelected] = useState({});
  const [exchangeRateList, setExchangeRateList] = useState([]);
  const [reasonsReturnList, setReasonsReturnList] = useState([]);

  //REFUND HOOKS FINALS
  const [finalArrayReturnRender, setFinalArrayReturnRender] = useState([]); //ARREGLO FINAL PARA DEVOLVER (RENDER) SOLO PARA VISUALIZAR

  const [expanded, setExpanded] = useState(false);
  //notifications modal repayment
  //const [isOpenNotification, setIsOpenNotification] = useState(false);
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false);
  //REFUND HOOKS ALL PRODUCTS
  const [fullCancellationType, setFullCancellationType] = useState(0);
  const [reasonCompleteCancellation, setReasonCompleteCancellation] =
    useState(0);
  const [fullCancellationComment, setFullCancellationComment] = useState(0);
  //REFUND TYPE CANCELLATION (2= devolucion parcial, 3= devolucion total)
  const [returnType, setReturnType] = useState(0);
  //REFUND TYPE CANCELLATION (2= devolucion parcial, 3= devolucion total)
  const [isSelectAnyItem, setIsSelectAnyItem] = useState(0);

  /// TRANSLATION
  const { t } = useTranslation();

  /// REDUX
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.client = useSelector((state) => state.client);
  appReducers.repayment = useSelector((state) => state.repayment);

  /// PARAMS ORDER
  const orderId = useParams().idPedido;

  ///USEEFFECTS (HOOKS)
  useEffect(() => {
    if (appReducers?.client?.clientConfirmedOrders) {
      const orders = appReducers?.client?.clientConfirmedOrders.orders;
      setOrderSelected(orders.find((x) => x.idPedido === parseInt(orderId)));
      setOrderDetails(
        orders.find((x) => x.idPedido === parseInt(orderId)).detail
      );
    } else {
      window.history.back();
    }
  }, [appReducers?.client?.clientConfirmedOrders]);

  useEffect(() => {
    if (appReducers?.repayment?.exchangeRate) {
      if (appReducers.repayment.exchangeRate.code === 100) {
        setExchangeRateList(
          appReducers.repayment.exchangeRate.exchangeRateModels
        );
      }
    }
  }, [appReducers?.repayment?.exchangeRate]);

  useEffect(() => {
    if (appReducers?.repayment?.reasonsReturn) {
      if (appReducers.repayment.reasonsReturn.code === 100) {
        setReasonsReturnList(
          appReducers.repayment.reasonsReturn.reasonsReturnModels
        );
      }
    }
  }, [appReducers?.repayment?.reasonsReturn]);

  useEffect(() => {
    if (appReducers?.repayment?.fullRefund) {
      if (appReducers.repayment.fullRefund.code === 100) {
        if (fullCancellationType === 1) {
          dispatch(
            requestLoggUserActivity(
              36,
              0,
              0,
              "",
              "Se realizo el proceso de devolucion de forma Correcta. Folio: " +
                String(appReducers.repayment.fullRefund.result)
            )
          );
        } else if (fullCancellationType === 2) {
          dispatch(
            requestLoggUserActivity(
              36,
              0,
              0,
              "",
              "Se realizo el proceso de devolucion de forma Correcta. Nuevo Pedido: " +
                String(appReducers.repayment.fullRefund.result)
            )
          );
        }

        dispatch(requestCleanRequestFullRefundReducer());
      }
    }
  }, [appReducers?.repayment?.fullRefund]);

  useEffect(() => {
    if (appReducers?.repayment?.partialRefund) {
      if (appReducers.repayment.partialRefund.code === 100) {
        dispatch(
          requestLoggUserActivity(
            38,
            0,
            0,
            "",
            "Se realizo el proceso de devolucion parcial de forma Correcta."
          )
        );

        dispatch(requestCleanRequestFullRefundReducer());
      }
    }
  }, [appReducers?.repayment?.partialRefund]);

  useEffect(() => {
    if (isOpenNotification) {
      enqueueSnackbar(t("__notification_successful"), {
        variant: "success",
      });
    }
  }, [isOpenNotification]);

  //FUNCIONES
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const showOneReturn = () => {
    setContenShow("one");
  };
  const showAllReturn = () => {
    setIsOpenModalReturnAll(true);
    dispatch(
      requestLoggUserActivity(36, 0, 0, "", "Click en: DEVOLVER TODO EL PEDIDO")
    );
  };
  const showAllProductsReturn = () => {
    //++articulos en el resultados++\\
    if (kitsArray.length > 0) {
      //validar si tiene un cupon
      //validar el precio de cupon
      //sacar el precio % del cupon

      let newArrayArtSelected = [];
      let cloneKitsArray = [...kitsArray];

      //ciclo recursivo para sacar articulos seleccionados incluso a los hijos en caso de que sea set
      cloneKitsArray.map((elemt, idx) => {
        //si tiene hijos
        if (elemt?.child && elemt.child.length > 0) {
          //si la cabecera tiene se regresara completa
          if (elemt.selectedReturn && elemt.piecesR > 0) {
            //regresar todos los componentes divididos por la cantidad seleccionada
            //1) sacamos el unitario de piezas dentro del set
            let cantSets = elemt.cant;

            //2) arraeglo con elementos por set unico
            let arrayItemsUni = [];

            let cloneKitsChildsArray = [...elemt.child];

            cloneKitsChildsArray.map((child) => {
              let objArt = Object.assign({}, child);

              objArt.cant = objArt.cant / cantSets;

              arrayItemsUni.push(objArt);
            });

            //3) tomar los elemtos quenecesito de cada elemnto dentro del set
            arrayItemsUni.map((childReturn) => {
              let objArtReturn = Object.assign({}, childReturn);

              //OBJETO FINAL
              let objFinal = {};

              objFinal.orderDetailId = objArtReturn.idPedidoDet;
              objFinal.itemCode = objArtReturn.itemCode;
              objFinal.quantity = objArtReturn.cant * elemt.piecesR;
              objFinal.amount = objArtReturn.price.toFixed(4) * elemt.piecesR;
              objFinal.cancellationType = elemt.exchangeType;
              objFinal.reasonCancellation = elemt.reasonsReturn;
              objFinal.comment = elemt.comment;

              objFinal.descEs = objArtReturn.descEs;
              objFinal.descEn = objArtReturn.descEn;
              objFinal.imgProd = objArtReturn.imgProd;
              objFinal.productBatch = objArtReturn.productBatch;

              newArrayArtSelected.push(objFinal);
            });
          } else {
            //COMPONENTES DEL SET SOLOS
            let cloneKitsChildsArray = [...elemt.child];
            cloneKitsChildsArray.map((child, idxC) => {
              if (child.selectedReturn && child.piecesR > 0) {
                let objArtReturn = Object.assign({}, child);
                //OBJETO FINAL
                let objFinal = {};

                objFinal.orderDetailId = objArtReturn.idPedidoDet;
                objFinal.itemCode = objArtReturn.itemCode;
                objFinal.quantity = objArtReturn.piecesR;
                objFinal.amount =
                  objArtReturn.price.toFixed(4) * objArtReturn.piecesR;
                objFinal.cancellationType = objArtReturn.exchangeType;
                objFinal.reasonCancellation = objArtReturn.reasonsReturn;
                objFinal.comment = objArtReturn.comment;

                objFinal.descEs = objArtReturn.descEs;
                objFinal.descEn = objArtReturn.descEn;
                objFinal.imgProd = objArtReturn.imgProd;
                objFinal.productBatch = objArtReturn.productBatch;

                newArrayArtSelected.push(objFinal);
              }
            });
          }
        } else {
          ///row en el detalle (PRODUCTO NORMAL)
          if (elemt.selectedReturn && elemt.piecesR > 0) {
            let objArtReturn = Object.assign({}, elemt);
            //OBJETO FINAL
            let objFinal = {};

            objFinal.orderDetailId = objArtReturn.idPedidoDet;
            objFinal.itemCode = objArtReturn.itemCode;
            objFinal.quantity = objArtReturn.piecesR;
            objFinal.amount =
              objArtReturn.price.toFixed(4) * objArtReturn.piecesR;
            objFinal.cancellationType = objArtReturn.exchangeType;
            objFinal.reasonCancellation = objArtReturn.reasonsReturn;
            objFinal.comment = objArtReturn.comment;

            objFinal.descEs = objArtReturn.descEs;
            objFinal.descEn = objArtReturn.descEn;
            objFinal.imgProd = objArtReturn.imgProd;
            objFinal.productBatch = objArtReturn.productBatch;

            newArrayArtSelected.push(objFinal);
          }
        }
      });

      //indicamos que es una devo parcial
      setReturnType(2);

      setFinalArrayReturnRender(newArrayArtSelected);
    }
    setContenShow("listAll");
    //
    dispatch(
      requestLoggUserActivity(
        38,
        0,
        0,
        "",
        "Click en: ENVIAR DEVOLUCION PARCIAL."
      )
    );
  };
  const handleChangeSelect = (event) => {
    setReturnOpc(event.target.value);
  };
  const handleModalLote = () => {
    setIsOpenCongratulations(true);
  };
  const sendPetitionRefund = () => {
    if (returnType === 2) {
      let arraySendPetition = [];

      finalArrayReturnRender.map((item, index) => {
        let objFinal = {};
        let objArt = Object.assign({}, item);

        objFinal.orderDetailId = objArt.orderDetailId;
        objFinal.itemCode = objArt.itemCode;
        objFinal.quantity = objArt.quantity;
        objFinal.amount = objArt.amount;
        objFinal.cancellationType = objArt.cancellationType;
        objFinal.reasonCancellation = objArt.reasonCancellation;
        objFinal.comment = objArt.comment;
        objFinal.productBatch = !objArt.productBatch ? "" : objArt.productBatch;

        arraySendPetition.push(objFinal);
      });
      dispatch(requestPartialRefund(orderId, arraySendPetition));
      setIsOpenModalConfirm(false);
    } else {
      dispatch(
        requestFullRefund(
          orderId,
          fullCancellationType,
          reasonCompleteCancellation,
          fullCancellationComment
        )
      );
      setIsOpenModalConfirm(false);
    }
  };
  const handleClickShippingAllProducts = () => {
    //armar objetos de toda la orden
    const cloneOrderDetails = [...orderDetails];

    let newArrayArtSelected = [];
    let finalKits = [];

    const kits = cloneOrderDetails.filter(
      (item) => item.typeLine !== "C" && item.typeLine !== "N"
    );
    const components = cloneOrderDetails.filter(
      (item) => item.typeLine === "C"
    );
    const lineProduct = cloneOrderDetails.filter(
      (item) => item.typeLine === "N"
    );

    let newArrayKit = [];
    kits.forEach((i) => {
      const result = components.filter((e) => e.codean === i.idPedidoDet);
      const final = { ...i, child: result };
      newArrayKit.push(final);
    });
    finalKits = lineProduct.concat(newArrayKit);

    if (finalKits && finalKits.length > 0) {
      finalKits.map((elemt, idx) => {
        //si tiene hijos
        if (elemt?.child && elemt.child.length > 0) {
          //regresar todos los componentes divididos por la cantidad seleccionada
          //1) sacamos el unitario de piezas dentro del set
          let cantSets = elemt.cant;

          //2) arraeglo con elementos por set unico
          let arrayItemsUni = [];

          let cloneKitsChildsArray = [...elemt.child];

          cloneKitsChildsArray.map((child) => {
            let objArt = Object.assign({}, child);

            objArt.cant = objArt.cant / cantSets;

            arrayItemsUni.push(objArt);
          });

          //3) tomar los elemtos quenecesito de cada elemnto dentro del set
          arrayItemsUni.map((childReturn) => {
            let objArtReturn = Object.assign({}, childReturn);

            //OBJETO FINAL
            let objFinal = {};

            objFinal.orderDetailId = objArtReturn.idPedidoDet;
            objFinal.itemCode = objArtReturn.itemCode;
            objFinal.quantity = objArtReturn.cant * cantSets;
            objFinal.amount = objArtReturn.price.toFixed(4) * cantSets;
            objFinal.cancellationType = fullCancellationType;
            objFinal.reasonCancellation = reasonCompleteCancellation;
            objFinal.comment = fullCancellationComment;

            objFinal.descEs = objArtReturn.descEs;
            objFinal.descEn = objArtReturn.descEn;
            objFinal.imgProd = objArtReturn.imgProd;

            newArrayArtSelected.push(objFinal);
          });
        } else {
          ///row en el detalle (PRODUCTO NORMAL)
          let objArtReturn = Object.assign({}, elemt);
          //OBJETO FINAL
          let objFinal = {};

          objFinal.orderDetailId = objArtReturn.idPedidoDet;
          objFinal.itemCode = objArtReturn.itemCode;
          objFinal.quantity = objArtReturn.cant;
          objFinal.amount = objArtReturn.price.toFixed(4) * objArtReturn.cant;
          objFinal.cancellationType = fullCancellationType;
          objFinal.reasonCancellation = reasonCompleteCancellation;
          objFinal.comment = fullCancellationComment;

          objFinal.descEs = objArtReturn.descEs;
          objFinal.descEn = objArtReturn.descEn;
          objFinal.imgProd = objArtReturn.imgProd;

          newArrayArtSelected.push(objFinal);
        }
      });
      setFinalArrayReturnRender(newArrayArtSelected);
    }

    //cerrar el modal
    setIsOpenModalReturnAll(false);

    //indicamos que es una devo completa
    setReturnType(3);

    //mostrar la lista de los productos de la orden
    setContenShow("listAll");

    //
    dispatch(
      requestLoggUserActivity(
        36,
        0,
        0,
        "",
        "Click en: ENVIAR DEVOLUCION. Motivo: " +
          t(
            reasonsReturnList.find((e) => e.id === reasonCompleteCancellation)
              .value
          ) +
          " .Tipo de Cambio: " +
          t(exchangeRateList.find((e) => e.id === fullCancellationType).value)
      )
    );
  };

  //// switch
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case "one":
        return (
          <div className="firstWrapperInBaseContainer shopping__repayment">
            <div className="shopping__repayment__container">
              <div className="shopping__repayment__container__title">
                <Typography variant="h5" gutterBottom>
                  {t("__profile_repayment_container_title")}
                </Typography>
              </div>
              <div className="shopping__repayment__container__description">
                <Typography variant="caption" display="block" gutterBottom>
                  {t("__profile_repayment_container_subtitle")}
                </Typography>
              </div>
              <div className="shopping__repayment__container__wrapper">
                <div>
                  <FormControl variant="filled">
                    <TextField
                      id="standard-select-currency"
                      select
                      value={returnOpc}
                      onChange={handleChangeSelect}
                      helperText="Please select your currency"
                      className="shopping__repayment__container__wrapper__select"
                    >
                      <MenuItem value="">
                        <em>
                          {t("__profile_repayment_container_select_label")}
                        </em>
                      </MenuItem>
                      <MenuItem value={1}>
                        {t(
                          "__profile_repayment_container_select_option1_label"
                        )}
                      </MenuItem>
                      <MenuItem value={2}>
                        {t(
                          "__profile_repayment_container_select_option2_label"
                        )}
                      </MenuItem>
                      <MenuItem value={3}>
                        {t(
                          "__profile_repayment_container_select_option3_label"
                        )}
                      </MenuItem>
                      <MenuItem value={4}>
                        {t(
                          "__profile_repayment_container_select_option4_label"
                        )}
                      </MenuItem>
                      <MenuItem value={5}>
                        {t(
                          "__profile_repayment_container_select_option5_label"
                        )}
                      </MenuItem>
                      <MenuItem value={6}>
                        {t(
                          "__profile_repayment_container_select_option6_label"
                        )}
                      </MenuItem>
                      <MenuItem value={7}>
                        {t(
                          "__profile_repayment_container_select_option7_label"
                        )}
                      </MenuItem>
                    </TextField>
                  </FormControl>
                </div>
                {returnOpc !== "" && (
                  <div className="shopping__repayment__container__wrapper__radios">
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="gender"
                        name="gender1"
                        value={value}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="product"
                          control={<Radio />}
                          label={t(
                            "__profile_repayment_container_select_product_label"
                          )}
                        />
                        <FormControlLabel
                          value="cash"
                          control={<Radio />}
                          label={t(
                            "__profile_repayment_container_select_cash_label"
                          )}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                )}
                {returnOpc === 1 ||
                  returnOpc === 2 ||
                  (returnOpc === 3 && (
                    <Fragment>
                      <div className="shopping__repayment__container__description">
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {t(
                            "__profile_repayment_container_modal_locate_lote_label"
                          )}
                        </Typography>
                      </div>
                      <div className="shopping__repayment__container__wrapper__input">
                        <TextField
                          error
                          id="outlined-error-helper-text"
                          label={t(
                            "__profile_repayment_container_locate_lote_label"
                          )}
                          helperText="Incorrect entry."
                        />
                        <div className="shopping__repayment__container__wrapper__finish">
                          <Typography
                            onClick={handleModalLote}
                            variant="button"
                            display="block"
                            gutterBottom
                          >
                            <a rel="lote">
                              {t(
                                "__profile_repayment_container_where_locate_lote_label"
                              )}
                            </a>
                          </Typography>
                        </div>
                      </div>
                    </Fragment>
                  ))}
                {returnOpc === 7 && (
                  <div className="shopping__repayment__container__wrapper__other">
                    <div className="shopping__repayment__container__wrapper__other__up">
                      &nbsp; &nbsp;
                    </div>
                    <TextField
                      id="standard-multiline-flexible"
                      multiline
                      fullWidth
                      rows={2}
                      value="hello"
                    />
                  </div>
                )}
                <Button
                  onClick={() => {
                    setContenShow("");
                  }}
                  variant="contained"
                >
                  {t("__profile_repayment_container_return_products_label")}
                </Button>
              </div>
            </div>
          </div>
        );
      case "list":
        return <ListRepayment isAllOrder={false} />;
      case "listAll":
        return (
          <ListRepayment
            isAllOrder={true}
            setContenShow={setContenShow}
            finalArrayReturnRender={finalArrayReturnRender}
            orderSelected={orderSelected}
            //isOpenNotification={isOpenNotification}
            setIsOpenNotification={setIsOpenNotification}
            isOpenModalConfirm={isOpenModalConfirm}
            setIsOpenModalConfirm={setIsOpenModalConfirm}
            sendPetitionRefund={sendPetitionRefund}
            setReasonCompleteCancellation={setReasonCompleteCancellation}
            setFullCancellationType={setFullCancellationType}
          />
        );
      case "cash":
        return (
          <div className="firstWrapperInBaseContainer repayment__cash">
            <div className="repayment__cash__container">
              <div className="shopping__repayment__container__title">
                <Typography variant="h5" gutterBottom>
                  {t("__profile_repayment_container_request_received_label")}
                </Typography>
              </div>
              <div className="shopping__repayment__container__description">
                <Typography variant="caption" display="block" gutterBottom>
                  {t("__profile_repayment_container_request_received_text1")}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  {t("__profile_repayment_container_request_received_text2")}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  {t("__profile_repayment_container_request_received_text3")}
                </Typography>
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div
            id="firstWrapperInBaseContainer"
            className="firstWrapperInBaseContainer"
          >
            {orderDetails && (
              <div className="shopping__repayment__container">
                <div className="shopping__repayment__container__title">
                  <Typography variant="h5" gutterBottom>
                    {t("__profile_repayment_container_returns_label")}
                  </Typography>
                </div>
                <div className="shopping__repayment__container__description">
                  <Typography variant="caption" display="block" gutterBottom>
                    {t("__profile_repayment_container_returns_text")}
                  </Typography>
                  <Typography variant="caption" display="block" gutterBottom>
                    {t(
                      "__profile_repayment_container_returns_select_product_text"
                    )}
                  </Typography>
                </div>
                {isDesktop ? (
                  <ItemProductRepayment
                    value={value}
                    handleChange={handleChange}
                    detailShopping={orderDetails}
                    setIsOpenNotification={setIsOpenNotification}
                    exchangeRateList={exchangeRateList}
                    reasonsReturnList={reasonsReturnList}
                    kitsArray={kitsArray}
                    setKitsArray={setKitsArray}
                    expanded={expanded}
                    setExpanded={setExpanded}
                    setIsSelectAnyItem={setIsSelectAnyItem}
                    dispatch={dispatch}
                    requestLoggUserActivity={requestLoggUserActivity}
                  />
                ) : (
                  <CardsMobileRepayment
                    value={value}
                    handleChange={handleChange}
                    detailShopping={orderDetails}
                    setIsOpenNotification={setIsOpenNotification}
                    exchangeRateList={exchangeRateList}
                    reasonsReturnList={reasonsReturnList}
                    kitsArray={kitsArray}
                    setKitsArray={setKitsArray}
                    expanded={expanded}
                    setExpanded={setExpanded}
                    setIsSelectAnyItem={setIsSelectAnyItem}
                  />
                )}

                <div className="shopping__repayment__container__button">
                  <Button
                    disabled={isSelectAnyItem === 0}
                    onClick={showAllProductsReturn}
                    className="button__with__icon bg__dark__pink no__left__margin"
                  >
                    <div className="button__with__icon__svg__wrapper dark__pink__ball">
                      <div className="bagWhiteIcon" />
                    </div>
                    <span>
                      {t("__profile_repayment_container_returns_product_label")}
                    </span>
                  </Button>
                </div>
                <div className="shopping__repayment__container__all">
                  <a onClick={showAllReturn}>
                    {t("__profile_repayment_container_returns_all_order_label")}
                  </a>
                </div>
              </div>
            )}
          </div>
        );
    }
  }

  return (
    <div>
      {/* opc=list => va la lista de productos que se necesitar pedirnuevamente  */}
      {/* opc=cash => cuando el reembolso es un dinero */}
      {/* <NotificationSnackbar
        isOpenNotification={isOpenNotification}
        setIsOpenNotification={setIsOpenNotification}
        message={t("__notification_successful")}
        status="success"
        icon="Check"
      /> */}
      <ModalSliderRepaymentAll
        isOpenModalReturnAll={isOpenModalReturnAll}
        setIsOpenModalReturnAll={setIsOpenModalReturnAll}
        setContenShow={setContenShow}
        exchangeRateList={exchangeRateList}
        reasonsReturnList={reasonsReturnList}
        handleClickShippingAllProducts={handleClickShippingAllProducts}
        setFullCancellationType={setFullCancellationType}
        fullCancellationType={fullCancellationType}
        setReasonCompleteCancellation={setReasonCompleteCancellation}
        reasonCompleteCancellation={reasonCompleteCancellation}
        setFullCancellationComment={setFullCancellationComment}
        fullCancellationComment={fullCancellationComment}
      />

      {isOpenCongratulations && (
        <ModalCongratulations
          title={t("__profile_repayment_container_locate_lote2_label")}
          image={Lote}
          message={t("__profile_repayment_container_locate_lote3_label")}
          isButtonActive={false}
          textButton=""
          navigationRoute=""
          isOpenCongratulations={isOpenCongratulations}
          setIsOpenCongratulations={setIsOpenCongratulations}
        />
      )}

      {getStepContent(contentShow)}
    </div>
  );
};

export default ShoppingRepaymentContainer;
