import React from "react";
import { isChrome, isChromium } from "react-device-detect";

const TextFieldNewMembers = ({
  id = "my_input",
  labelName,
  typeInput,
  valueInput,
  erroHelp,
  isActiveHelp,
  setValue,
  placeholderInput,
  onBlur,
  maxLength = 0,
  withLeftMargin,
  isRequire,
}) => {
  return (
    <>
      <div
        style={{ marginLeft: withLeftMargin ? 8 : 0 }}
        className="textfield__new__members"
      >
        <label className={isRequire ? `text__required` : ""} htmlFor={id}>
          {labelName}
        </label>
        <input
          autoComplete={isChromium || isChrome ? "nope" : "off"}
          id={id}
          placeholder={placeholderInput !== "" ? placeholderInput : ""}
          type={typeInput}
          value={valueInput}
          aria-describedby="my-helper-text"
          onInput={setValue}
          onBlur={onBlur}
          maxLength={maxLength > 0 ? maxLength : 1000}
        />
        {isActiveHelp && <span id="my-helper-text">{erroHelp}.</span>}
      </div>
    </>
  );
};

export default TextFieldNewMembers;
