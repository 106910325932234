import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  Skeleton,
} from "@mui/material";
import { isMobile, isTablet } from "react-device-detect";
import { Check, Clear } from "@mui/icons-material";
import ErrorIcon from "@mui/icons-material/Error";
///components
import TextFieldComponent from "./TextFieldComponent";
import AddressValidation from "./AddressValidation";
//Actions
import { requestAddressValidation } from "./../../../ReduxSaga/Actions/Address";
import { requestStatesAndCities } from "./../../../ReduxSaga/Actions/General";

//Assets
import { ReactComponent as DoorIcon } from "./../../../../assets/media/images/icons/doorOpenBlackIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { requestCleanAddressReducer } from "../../../ReduxSaga/Actions/Client";

import { restrictToOnlyNumbersString } from "../../../../Libraries/Utils/utils";

const AddressSection = ({
  isLanguageLoaded,
  t,
  isFromLogin,
  enqueueSnackbar,
  formData,
  setFormData,
  addressControl,
  setAddressControl,
  benefitType,
  clientInfoToCloseOrder,
  setClientInfoToCloseOrder,
  hanldeTaxCalculationOnOrder,
}) => {
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.general = useSelector((state) => state.general);
  appReducers.address = useSelector((state) => state.address);

  ////states
  const [snackActive, setSnackActive] = useState(false);
  const [isAddresShippingVerify, setIsAddresShippingVerify] = useState(true);
  const [isOpenAddressValidationModal, setIsOpenAddressValidationModal] =
    useState(false);
  const [
    isOpenFiscalAddressValidationModal,
    setIsOpenFiscalAddressValidationModal,
  ] = useState(false);
  const [addressListSugestion, setAddressListSugestion] = useState([]);
  const [addressTypeToValidate, setAddressTypeToValidate] = useState("");
  const [statesResult, setstatesResult] = useState([]);

  //// useEffect
  useEffect(() => {
    dispatch(requestStatesAndCities());
  }, []);

  useEffect(() => {
    if (typeof appReducers?.general?.errorLoadStatesAndCities !== "undefined") {
      if (!appReducers?.general?.errorLoadStatesAndCities) {
        if (
          appReducers?.general?.statesAndCities &&
          appReducers?.general?.statesAndCities.length > 0
        ) {
          setstatesResult(appReducers?.general?.statesAndCities);
        }
      }
    }
    return () => {
      if (statesResult?.length > 0) setstatesResult([]);
    };
  }, [
    appReducers?.general?.errorLoadStatesAndCities,
    appReducers?.general?.statesAndCities,
  ]);

  useEffect(() => {
    if (snackActive) {
      enqueueSnackbar(t("__forms_email_validation_error_execution_message"), {
        variant: "error",
      });
    }
  }, [snackActive]);
  ///EFFECT ON ADDRESS VALIDATION
  useEffect(() => {
    if (
      typeof appReducers?.address?.errorClientAddressValidation !== "undefined"
    ) {
      const { typeAddress } = appReducers?.address;
      setAddressTypeToValidate(typeAddress);
      if (appReducers?.address?.errorClientAddressValidation) {
        if (
          appReducers?.address?.addressSugestion &&
          appReducers?.address?.addressSugestion.length > 0
        ) {
          setAddressListSugestion(appReducers?.address?.addressSugestion);
          if (typeAddress === "e") {
            setIsOpenAddressValidationModal(true);
          } else {
            setIsOpenFiscalAddressValidationModal(true);
          }
        } else {
          enqueueSnackbar(
            t("__forms_address_verification_error_noData_message"),
            {
              variant: "error",
            }
          );
          if (typeAddress === "e") {
            setAddressControl({
              ...addressControl,
              isShippValidAddress: false,
            });
            setIsOpenAddressValidationModal(false);
          } else {
            setAddressControl({
              ...addressControl,
              isFiscalValidAddress: false,
            });
            setIsOpenFiscalAddressValidationModal(false);
          }
          setAddressListSugestion([]);
          dispatch(requestCleanAddressReducer());
        }
      } else {
        enqueueSnackbar(t("__forms_address_verification_success_message"), {
          variant: "success",
        });
        setAddressListSugestion([]);

        if (typeAddress === "e") {
          setIsOpenAddressValidationModal(false);

          if (addressControl.isSameAddress) {
            setFormData({
              ...formData,
              fiscalStreet: {
                error: formData.shippStreet.value.length === 0,
                isEmpty: formData.shippStreet.value.length === 0,
                value: formData.shippStreet.value,
              },
              fiscalSuite: {
                error: false,
                isEmpty: false,
                value: formData.shippSuite.value,
              },
              fiscalCity: {
                error: formData.shippCity.value.length === 0,
                isEmpty: formData.shippCity.value.length === 0,
                value: formData.shippCity.value,
              },
              fiscalState: {
                error: formData.shippState.value.length === 0,
                isEmpty: formData.shippState.value.length === 0,
                value: formData.shippState.value,
              },
              fiscalZipCode: {
                error:
                  formData.shippZipCode.value.length > 0 &&
                  formData.shippZipCode.value.length < 5,
                isEmpty: formData.shippZipCode.value.length === 0,
                value: formData.shippZipCode.value,
              },
            });
            setAddressControl({
              ...addressControl,
              isShippValidAddress: true,
              isFiscalValidAddress: true,
            });
          } else {
            setAddressControl({
              ...addressControl,
              isShippValidAddress: true,
            });
          }

          if (!isFromLogin) {
            hanldeTaxCalculationOnOrder(formData.shippZipCode.value);
          }
        } else {
          setIsOpenFiscalAddressValidationModal(false);
          setAddressControl({
            ...addressControl,
            isFiscalValidAddress: true,
          });
        }

        dispatch(requestCleanAddressReducer());
      }
    }
  }, [
    appReducers?.address.errorClientAddressValidation,
    appReducers?.address.addressSugestion,
    //addressControl.isSameAddress,
  ]);

  //HANDLERS
  const handleAddressOpenValidation = (type) => {
    setAddressControl({
      ...addressControl,
      selectedAddressInModalAddressSuggestion: {},
    });
    if (type === "e") {
      dispatch(
        requestAddressValidation(
          formData.shippStreet.value,
          formData.shippSuite.value,
          formData.shippState.value,
          formData.shippCity.value,
          formData.shippZipCode.value,
          type,
          false
        )
      );
    } else {
      dispatch(
        requestAddressValidation(
          formData.fiscalStreet.value,
          formData.fiscalSuite.value,
          formData.fiscalCity.value,
          formData.fiscalState.value,
          formData.fiscalZipCode.value,
          type,
          false
        )
      );
    }
  };

  const handlerSelectAddressFromSugestion = (
    address,
    suite,
    state,
    city,
    zip,
    type
  ) => {
    console.log("handlerSelectAddressFromSugestion", type);
    if (type === "e") {
      setIsOpenAddressValidationModal(false);

      if (addressControl.isSameAddress) {
        setFormData({
          ...formData,
          shippStreet: {
            error: false,
            isEmpty: false,
            value: address,
          },
          shippSuite: {
            error: false,
            isEmpty: false,
            value: suite.trim(),
          },
          shippCity: {
            error: false,
            isEmpty: false,
            value: city,
          },
          shippState: {
            error: false,
            isEmpty: false,
            value: state,
          },
          shippZipCode: {
            error: false,
            isEmpty: false,
            value: zip,
          },
          fiscalStreet: {
            error: false,
            isEmpty: false,
            value: address,
          },
          fiscalSuite: {
            error: false,
            isEmpty: false,
            value: suite.trim(),
          },
          fiscalCity: {
            error: false,
            isEmpty: false,
            value: city,
          },
          fiscalState: {
            error: false,
            isEmpty: false,
            value: state,
          },
          fiscalZipCode: {
            error: false,
            isEmpty: false,
            value: zip,
          },
        });
        setAddressControl({
          ...addressControl,
          isShippValidAddress: true,
          isFiscalValidAddress: true,
        });
      } else {
        setFormData({
          ...formData,
          shippStreet: {
            error: false,
            isEmpty: false,
            value: address,
          },
          shippSuite: {
            error: false,
            isEmpty: false,
            value: suite.trim(),
          },
          shippCity: {
            error: false,
            isEmpty: false,
            value: city,
          },
          shippState: {
            error: false,
            isEmpty: false,
            value: state,
          },
          shippZipCode: {
            error: false,
            isEmpty: false,
            value: zip,
          },
        });
        setAddressControl({
          ...addressControl,
          isShippValidAddress: true,
        });
      }
    } else {
      setIsOpenFiscalAddressValidationModal(false);
      setFormData({
        ...formData,
        fiscalStreet: {
          error: false,
          isEmpty: false,
          value: address,
        },
        fiscalSuite: {
          error: false,
          isEmpty: false,
          value: suite.trim(),
        },
        fiscalCity: {
          error: false,
          isEmpty: false,
          value: city,
        },
        fiscalState: {
          error: false,
          isEmpty: false,
          value: state,
        },
        fiscalZipCode: {
          error: false,
          isEmpty: false,
          value: zip,
        },
      });
      setAddressControl({
        ...addressControl,
        isFiscalValidAddress: true,
      });
    }
    dispatch(requestCleanAddressReducer());
  };

  return (
    <div
      className={`address__section ${
        isTablet ? "tablet" : isMobile ? "mobile" : ""
      }`}
    >
      {/* direccion de envio */}
      <Box
        className={`address__section__container__boxMain ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }`}
      >
        <AddressValidation
          setIsOpenValidationModal={setIsOpenAddressValidationModal}
          addressListSugestion={addressListSugestion}
          handlerSelectAddressFromSugestion={handlerSelectAddressFromSugestion}
          open={isOpenAddressValidationModal}
          type={addressTypeToValidate}
        />

        <div
          className={`address__section__container__boxMain__header ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          <div className="address__section__container__boxMain__header__title">
            {isLanguageLoaded ? (
              t("shopping__payment__headers__ship")
            ) : (
              <Skeleton width={150} height={30} variant="wave" />
            )}
          </div>
          <div className="address__section__container__boxMain__header__subtitle">
            {isLanguageLoaded ? (
              t("__general__shipment")
            ) : (
              <Skeleton width={150} height={30} variant="wave" />
            )}
          </div>
        </div>
        <div
          className={`address__section__container__boxMain__delivery ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          <div
            className={`address__section__container__boxMain__delivery__column ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            <div
              className={`address__section__container__boxMain__delivery__field ${
                isMobile && !isTablet ? "mobile" : isTablet ? "tablet" : ""
              }`}
            >
              <FormControl variant="outlined">
                <OutlinedInput
                  id="street"
                  placeholder={
                    isLanguageLoaded
                      ? t("__checkout_order_steps_pay_register_shipping_street")
                      : ""
                  }
                  value={formData.shippStreet.value}
                  endAdornment={
                    <InputAdornment position="end">
                      {formData.shippStreet.error ||
                      formData.shippStreet.isEmpty ? (
                        <Clear className="errorIcon" />
                      ) : (
                        <Check className="successIcon" />
                      )}
                    </InputAdornment>
                  }
                  aria-describedby="street"
                  inputProps={{
                    "aria-label": "street",
                  }}
                  onInput={(e) => {
                    setFormData({
                      ...formData,
                      //isShipmentAddressVerified: false,
                      shippStreet: {
                        error: e.target.value.length === 0,
                        isEmpty: e.target.value.length === 0,
                        value: e.target.value.toUpperCase(),
                      },
                    });
                    setAddressControl({
                      ...addressControl,
                      isShippValidAddress: false,
                    });
                  }}
                />
                <FormHelperText id="street">
                  {isLanguageLoaded && formData.shippStreet.isEmpty
                    ? t("__forms_empty_field_error_message")
                    : " "}
                </FormHelperText>
              </FormControl>
            </div>
            {/* suite */}
            <div
              className={`address__section__container__boxMain__delivery__field ${
                isMobile && !isTablet ? "mobile" : isTablet ? "tablet" : ""
              }`}
            >
              <FormControl variant="outlined">
                <OutlinedInput
                  id="suite"
                  placeholder={
                    isLanguageLoaded
                      ? t("__checkout_order_steps_pay_register_shipping_suite")
                      : ""
                  }
                  value={formData.shippSuite.value}
                  endAdornment={
                    <InputAdornment position="end">
                      {formData.shippSuite.error ||
                      formData.shippSuite.isEmpty ? (
                        <Clear className="errorIcon" />
                      ) : (
                        <Check className="successIcon" />
                      )}
                    </InputAdornment>
                  }
                  aria-describedby="suite"
                  inputProps={{
                    "aria-label": "suite",
                  }}
                  onInput={(e) => {
                    setFormData({
                      ...formData,
                      shippSuite: {
                        error: e.target.value.length === 0,
                        isEmpty: e.target.value.length === 0,
                        value: e.target.value.toUpperCase(),
                      },
                    });
                    setAddressControl({
                      ...addressControl,
                      isShippValidAddress: false,
                    });
                  }}
                />
                {/* <FormHelperText id="suite">
                  {isLanguageLoaded
                    ? t("__forms_empty_field_error_message")
                    : ""}
                </FormHelperText> */}
              </FormControl>
            </div>
          </div>
          <div
            className={`address__section__container__boxMain__delivery__column ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            {/* state */}

            <div
              className={`address__section__container__boxMain__delivery__field ${
                isMobile && !isTablet ? "mobile" : isTablet ? "tablet" : ""
              }`}
            >
              <FormControl variant="outlined">
                <Select
                  labelId="state"
                  id="select"
                  required
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  value={formData.shippState.value}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      shippState: {
                        error: e.target.value.length === 0,
                        isEmpty: e.target.value.length === 0,
                        value: e.target.value,
                      },
                    });
                    setAddressControl({
                      ...addressControl,
                      isShippValidAddress: false,
                    });
                  }}
                  //error={false}
                  className={isAddresShippingVerify ? "isOK" : ""}
                >
                  <MenuItem selected disabled value="">
                    <em>
                      {isLanguageLoaded
                        ? t(
                            "__checkout_order_steps_pay_register_shipping_state"
                          )
                        : ""}
                    </em>
                  </MenuItem>
                  {statesResult.map((state, index) => (
                    <MenuItem key={index} value={state.state}>
                      {state.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            {/* city */}
            <div
              className={`address__section__container__boxMain__delivery__field ${
                isMobile && !isTablet ? "mobile" : isTablet ? "tablet" : ""
              }`}
            >
              <FormControl variant="outlined">
                <OutlinedInput
                  id="city"
                  placeholder={
                    isLanguageLoaded
                      ? t("__checkout_order_steps_pay_register_shipping_city")
                      : ""
                  }
                  value={formData.shippCity.value}
                  endAdornment={
                    <InputAdornment position="end">
                      {formData.shippCity.error ||
                      formData.shippCity.isEmpty ? (
                        <Clear className="errorIcon" />
                      ) : (
                        <Check className="successIcon" />
                      )}
                    </InputAdornment>
                  }
                  aria-describedby="city"
                  inputProps={{
                    "aria-label": "city",
                  }}
                  onInput={(e) => {
                    setFormData({
                      ...formData,
                      //isShipmentAddressVerified: false,
                      shippCity: {
                        error: e.target.value.length === 0,
                        isEmpty: e.target.value.length === 0,
                        value: e.target.value.toUpperCase(),
                      },
                    });
                    setAddressControl({
                      ...addressControl,
                      isShippValidAddress: false,
                    });
                  }}
                />
                <FormHelperText id="city">
                  {isLanguageLoaded &&
                  (formData.shippCity.error || formData.shippCity.isEmpty)
                    ? t("__forms_empty_field_error_message")
                    : " "}
                </FormHelperText>
              </FormControl>
            </div>
          </div>
          <div
            className={`address__section__container__boxMain__delivery__column ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            {/* zip */}
            <div
              className={`address__section__container__boxMain__delivery__field ${
                isMobile && !isTablet ? "mobile" : isTablet ? "tablet" : ""
              }`}
            >
              <FormControl variant="outlined">
                <OutlinedInput
                  id="zip"
                  placeholder={
                    isLanguageLoaded
                      ? t("__checkout_order_steps_pay_register_shipping_CP")
                      : ""
                  }
                  value={formData.shippZipCode.value}
                  endAdornment={
                    <InputAdornment position="end">
                      {formData.shippZipCode.error ||
                      formData.shippZipCode.isEmpty ? (
                        <Clear className="errorIcon" />
                      ) : (
                        <Check className="successIcon" />
                      )}
                    </InputAdornment>
                  }
                  aria-describedby="zip"
                  inputProps={{
                    "aria-label": "zip",
                  }}
                  onInput={(e) => {
                    setFormData({
                      ...formData,
                      shippZipCode: {
                        error:
                          e.target.value.length > 0 &&
                          e.target.value.length < 5,
                        isEmpty: e.target.value.length === 0,
                        value: restrictToOnlyNumbersString(e, 5),
                      },
                    });
                    setAddressControl({
                      ...addressControl,
                      isShippValidAddress: false,
                    });
                  }}
                />
                <FormHelperText id="zip">
                  {isLanguageLoaded && formData.shippZipCode.isEmpty
                    ? t("__forms_empty_field_error_message")
                    : " "}
                </FormHelperText>
              </FormControl>
            </div>
            {/* verify address */}
            <div
              className={`address__section__container__boxMain__delivery__field verify__address ${
                isMobile && !isTablet ? "mobile" : isTablet ? "tablet" : ""
              }`}
            >
              {isLanguageLoaded ? (
                <>
                  {!addressControl.isShippValidAddress && (
                    <Button
                      disabled={
                        formData.shippStreet.error ||
                        formData.shippStreet.isEmpty ||
                        formData.shippCity.error ||
                        formData.shippCity.isEmpty ||
                        formData.shippState.error ||
                        formData.shippState.isEmpty ||
                        formData.shippZipCode.error ||
                        formData.shippZipCode.isEmpty
                      }
                      variant="contained"
                      className="button__with__icon bg__dark__red"
                      onClick={() => {
                        handleAddressOpenValidation("e");
                      }}
                    >
                      {t(
                        "__checkout_order_steps_pay_register_shipping_Address"
                      )}
                    </Button>
                  )}
                </>
              ) : (
                <Skeleton width={200} height={30} variant="wave" />
              )}
            </div>
          </div>
          <div></div>
        </div>
      </Box>

      {/* direccion fiscal */}

      {!isFromLogin && (
        <>
          {/* checkout */}

          {benefitType === 1 && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={addressControl.isSameAddress}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      setFormData({
                        ...formData,
                        fiscalStreet: {
                          error: true,
                          isEmpty: true,
                          value: "",
                        },
                        fiscalSuite: {
                          error: true,
                          isEmpty: true,
                          value: "",
                        },
                        fiscalCity: { error: true, isEmpty: true, value: "" },
                        fiscalState: {
                          error: true,
                          isEmpty: true,
                          value: "",
                        },
                        fiscalZipCode: {
                          error: true,
                          isEmpty: true,
                          value: "",
                        },
                      });

                      setAddressControl({
                        ...addressControl,
                        isFiscalValidAddress: false,
                        isSameAddress: e.target.checked,
                      });
                    } else {
                      setFormData({
                        ...formData,
                        fiscalStreet: {
                          error: formData.shippStreet.value.length === 0,
                          isEmpty: formData.shippStreet.value.length === 0,
                          value: formData.shippStreet.value,
                        },
                        fiscalSuite: {
                          error: false,
                          isEmpty: false,
                          value: formData.shippSuite.value,
                        },
                        fiscalCity: {
                          error: formData.shippCity.value.length === 0,
                          isEmpty: formData.shippCity.value.length === 0,
                          value: formData.shippCity.value,
                        },
                        fiscalState: {
                          error: formData.shippState.value.length === 0,
                          isEmpty: formData.shippState.value.length === 0,
                          value: formData.shippState.value,
                        },
                        fiscalZipCode: {
                          error:
                            formData.shippZipCode.value.length > 0 &&
                            formData.shippZipCode.value.length < 5,
                          isEmpty: formData.shippZipCode.value.length === 0,
                          value: formData.shippZipCode.value,
                        },
                      });
                      setAddressControl({
                        ...addressControl,
                        isFiscalValidAddress:
                          addressControl.isShippValidAddress,
                        isSameAddress: e.target.checked,
                      });
                    }
                  }}
                  className="checkbox__new__color"
                  // disabled={
                  //   orderCloseType === "guest" &&
                  //   guestInfoToCloseOrder.streetF.length > 0 /// faltan mas validaciones, pero mientras esta
                  // }
                />
              }
              label={
                isLanguageLoaded
                  ? t("__checkout_order_steps_pay_register_use_fiscal_Address")
                  : ""
              }
            />
          )}

          {!addressControl.isSameAddress && (
            <Box
              className={`address__section__container__boxMain fiscal__addres ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              <AddressValidation
                setIsOpenValidationModal={setIsOpenFiscalAddressValidationModal}
                addressListSugestion={addressListSugestion}
                handlerSelectAddressFromSugestion={
                  handlerSelectAddressFromSugestion
                }
                open={isOpenFiscalAddressValidationModal}
                type={addressTypeToValidate}
              />

              <div
                className={`address__section__container__boxMain__header ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                <div className="address__section__container__boxMain__header__subtitle">
                  {isLanguageLoaded ? (
                    t("shopping__payment__title__fiscal__label")
                  ) : (
                    <Skeleton width={200} height={30} variant="wave" />
                  )}
                </div>
              </div>
              <div
                className={`address__section__container__boxMain__delivery ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                <div
                  className={`address__section__container__boxMain__delivery__column ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  }`}
                >
                  <div
                    className={`address__section__container__boxMain__delivery__field ${
                      isMobile && !isTablet
                        ? "mobile"
                        : isTablet
                        ? "tablet"
                        : ""
                    }`}
                  >
                    <FormControl variant="outlined">
                      <OutlinedInput
                        id="streetFiscal"
                        placeholder={
                          isLanguageLoaded
                            ? t(
                                "__checkout_order_steps_pay_register_shipping_street"
                              )
                            : ""
                        }
                        value={formData.fiscalStreet.value}
                        endAdornment={
                          <InputAdornment position="end">
                            {formData.fiscalStreet.error ||
                            formData.fiscalStreet.isEmpty ? (
                              <Clear className="errorIcon" />
                            ) : (
                              <Check className="successIcon" />
                            )}
                          </InputAdornment>
                        }
                        aria-describedby="streetFiscal"
                        inputProps={{
                          "aria-label": "streetFiscal",
                        }}
                        onInput={(e) => {
                          setFormData({
                            ...formData,
                            fiscalStreet: {
                              error: e.target.value.length === 0,
                              isEmpty: e.target.value.length === 0,
                              value: e.target.value.toUpperCase(),
                            },
                          });
                          setAddressControl({
                            ...addressControl,
                            isFiscalValidAddress: false,
                          });
                        }}
                      />
                      <FormHelperText id="streetFiscal">
                        {isLanguageLoaded && formData.fiscalStreet.isEmpty
                          ? t("__forms_empty_field_error_message")
                          : " "}
                      </FormHelperText>
                    </FormControl>
                  </div>
                  {/* suite */}
                  <div
                    className={`address__section__container__boxMain__delivery__field ${
                      isMobile && !isTablet
                        ? "mobile"
                        : isTablet
                        ? "tablet"
                        : ""
                    }`}
                  >
                    <FormControl variant="outlined">
                      <OutlinedInput
                        id="suiteFiscal"
                        placeholder={
                          isLanguageLoaded
                            ? t(
                                "__checkout_order_steps_pay_register_shipping_suite"
                              )
                            : ""
                        }
                        value={formData.fiscalSuite.value}
                        endAdornment={
                          <InputAdornment position="end">
                            {formData.fiscalSuite.error ||
                            formData.fiscalSuite.isEmpty ? (
                              <Clear className="errorIcon" />
                            ) : (
                              <Check className="successIcon" />
                            )}
                          </InputAdornment>
                        }
                        aria-describedby="suiteFiscal"
                        inputProps={{
                          "aria-label": "suiteFiscal",
                        }}
                        onInput={(e) => {
                          setFormData({
                            ...formData,
                            fiscalSuite: {
                              error: e.target.value.length === 0,
                              isEmpty: e.target.value.length === 0,
                              value: e.target.value.toUpperCase(),
                            },
                          });
                          setAddressControl({
                            ...addressControl,
                            isFiscalValidAddress: false,
                          });
                        }}
                      />
                      {/* <FormHelperText id="suiteFiscal">
                        {isLanguageLoaded
                          ? t("__forms_empty_field_error_message")
                          : ""}
                      </FormHelperText> */}
                    </FormControl>
                  </div>
                </div>
                <div
                  className={`address__section__container__boxMain__delivery__column ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  }`}
                >
                  {/* state */}

                  <div
                    className={`address__section__container__boxMain__delivery__field ${
                      isMobile && !isTablet
                        ? "mobile"
                        : isTablet
                        ? "tablet"
                        : ""
                    }`}
                  >
                    <FormControl variant="outlined">
                      <Select
                        labelId="fiscalState"
                        id="select"
                        required
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        value={formData.fiscalState.value}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            fiscalState: {
                              error: e.target.value.length === 0,
                              isEmpty: e.target.value.length === 0,
                              value: e.target.value,
                            },
                          });
                          setAddressControl({
                            ...addressControl,
                            isFiscalValidAddress: false,
                          });
                        }}
                        className={isAddresShippingVerify ? "isOK" : ""}
                      >
                        <MenuItem selected disabled value="">
                          <em>
                            {isLanguageLoaded
                              ? t(
                                  "__checkout_order_steps_pay_register_shipping_state"
                                )
                              : ""}
                          </em>
                        </MenuItem>
                        {statesResult.map((state, index) => (
                          <MenuItem key={index} value={state.state}>
                            {state.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  {/* city */}
                  <div
                    className={`address__section__container__boxMain__delivery__field ${
                      isMobile && !isTablet
                        ? "mobile"
                        : isTablet
                        ? "tablet"
                        : ""
                    }`}
                  >
                    <FormControl variant="outlined">
                      <OutlinedInput
                        id="cityFiscal"
                        placeholder={
                          isLanguageLoaded
                            ? t(
                                "__checkout_order_steps_pay_register_shipping_city"
                              )
                            : ""
                        }
                        value={formData.fiscalCity.value}
                        endAdornment={
                          <InputAdornment position="end">
                            {formData.fiscalCity.error ||
                            formData.fiscalCity.isEmpty ? (
                              <Clear className="errorIcon" />
                            ) : (
                              <Check className="successIcon" />
                            )}
                          </InputAdornment>
                        }
                        aria-describedby="cityFiscal"
                        inputProps={{
                          "aria-label": "cityFiscal",
                        }}
                        onInput={(e) => {
                          setFormData({
                            ...formData,
                            fiscalCity: {
                              error: e.target.value.length === 0,
                              isEmpty: e.target.value.length === 0,
                              value: e.target.value.toUpperCase(),
                            },
                          });
                          setAddressControl({
                            ...addressControl,
                            isFiscalValidAddress: false,
                          });
                        }}
                      />
                      <FormHelperText id="cityFiscal">
                        {isLanguageLoaded &&
                        (formData.fiscalCity.error ||
                          formData.fiscalCity.isEmpty)
                          ? t("__forms_empty_field_error_message")
                          : " "}
                      </FormHelperText>
                    </FormControl>
                  </div>
                </div>
                <div
                  className={`address__section__container__boxMain__delivery__column ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  }`}
                >
                  {/* zip */}
                  <div
                    className={`address__section__container__boxMain__delivery__field ${
                      isMobile && !isTablet
                        ? "mobile"
                        : isTablet
                        ? "tablet"
                        : ""
                    }`}
                  >
                    <FormControl variant="outlined">
                      <OutlinedInput
                        id="zipFiscal"
                        placeholder={
                          isLanguageLoaded
                            ? t(
                                "__checkout_order_steps_pay_register_shipping_CP"
                              )
                            : ""
                        }
                        value={formData.fiscalZipCode.value}
                        endAdornment={
                          <InputAdornment position="end">
                            {formData.fiscalZipCode.error ||
                            formData.fiscalZipCode.isEmpty ? (
                              <Clear className="errorIcon" />
                            ) : (
                              <Check className="successIcon" />
                            )}
                          </InputAdornment>
                        }
                        aria-describedby="zipFiscal"
                        inputProps={{
                          "aria-label": "zipFiscal",
                        }}
                        onInput={(e) => {
                          setFormData({
                            ...formData,
                            fiscalZipCode: {
                              error:
                                e.target.value.length > 0 &&
                                e.target.value.length < 5,
                              isEmpty: e.target.value.length === 0,
                              value: restrictToOnlyNumbersString(e, 5),
                            },
                          });
                          setAddressControl({
                            ...addressControl,
                            isFiscalValidAddress: false,
                          });
                        }}
                      />
                      <FormHelperText id="zipFiscal">
                        {isLanguageLoaded && formData.fiscalZipCode.isEmpty
                          ? t("__forms_empty_field_error_message")
                          : " "}
                      </FormHelperText>
                    </FormControl>
                  </div>
                  {/* verify address */}
                  <div
                    className={`address__section__container__boxMain__delivery__field verify__fiscal ${
                      isMobile && !isTablet
                        ? "mobile"
                        : isTablet
                        ? "tablet"
                        : ""
                    }`}
                  >
                    {isLanguageLoaded ? (
                      <>
                        {!addressControl.isFiscalValidAddress && (
                          <Button
                            disabled={
                              formData.fiscalStreet.error ||
                              formData.fiscalStreet.isEmpty ||
                              formData.fiscalCity.error ||
                              formData.fiscalCity.isEmpty ||
                              formData.fiscalState.error ||
                              formData.fiscalState.isEmpty ||
                              formData.fiscalZipCode.error ||
                              formData.fiscalZipCode.isEmpty
                            }
                            variant="contained"
                            className="button__with__icon bg__dark__red"
                            onClick={() => {
                              handleAddressOpenValidation("f");
                            }}
                          >
                            {t(
                              "__checkout_order_steps_pay_register_shipping_Address"
                            )}
                          </Button>
                        )}
                      </>
                    ) : (
                      <Skeleton width={200} height={30} variant="wave" />
                    )}
                  </div>
                </div>
                <div></div>
              </div>
            </Box>
          )}
        </>
      )}
    </div>
  );
};

export default AddressSection;
