import GraphqlHandler from "../GraphqlHandler";
import GraphqlHandlerNoInterceptor from "../GraphqlHandler/noInterceptor"
import { ENVIROMENT_URL } from "../../React/ReduxSaga/Constants";

const route = ENVIROMENT_URL + "apiV3/CustomLabelMicroservice/CustomLabel";

class CustomLabels {
  static GetCustomLabelsConfiguration(orderId) {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      route,
      `(@autodeclare){
            getCustomLabelsConfiguration(orderId:$orderId)
            {
              code 
              message                    
              customLabelList
              {
                  id
                  image
                  itemCode
                  label
                  name
                  status
                  template
                  templateId
              }
            }
        }`,
      true,
      {
        "orderId!Int": orderId,
      }
    );
    return result;
  }
  static ValidateCustomLabelConfigurationOnOrder(model) {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlMutation(
      route,
      `(@autodeclare){
            validateCustomLabelConfigurationOnOrder(model:$model)
            {
              code 
              message                    
              pendingValidation
            }
        }`,
      true,
      {
        "model!CustomLabelOpIALInputModelType": model,
      }
    );
    return result;
  }
  static UpdateLabelConfigurationOnOrder(model) {
    const result = GraphqlHandler.openRequestGraphQlMutation(
      route,
      `(@autodeclare){
        updateCustomLabelConfigurationOnOrder(model:$model)
            {
              code 
              message                    
            }
        }`,
      true,
      {
        "model!CustomLabelOpUALInputModelType": model,
      }
    );
    return result;
  }
  static BuildImagesOnServer(orderId) {
    const result  = GraphqlHandlerNoInterceptor.openRequestGraphQlMutation(
      route,
      `(@autodeclare){
        buildCustomLabelsImages(orderId:$orderId)
            {
              code 
              message                    
            }
        }`,
      true,
      {
        "orderId!Int": orderId,
      }
    )
    return result;
  }
  static GetCustomLabelsConfigurationByOrderDetail(orderId, orderDetailId){
    const result = GraphqlHandler.openRequestGraphQlQuery(
      route,
      `(@autodeclare){
        getCustomLabelsConfigurationV2(orderId:$orderId, orderDetailId:$orderDetailId)
            {
              code 
              message                    
              customLabelList
              {
                id
                itemCode
                image
                isSaved
                label
                template
              }
            }
        }`,
      true,
      {
        "orderId!Int": orderId,
        "orderDetailId!Int": orderDetailId
      }
    );
    return result;
  }
  static UpdateLabelConfigurationOnOrderByDetail(model){
    const result = GraphqlHandler.openRequestGraphQlMutation(
      route,
      `(@autodeclare){
        updateCustomLabelConfigurationOnOrderByDetail(model:$model)
            {
              code 
              message                    
            }
        }`,
      true,
      {
        "model!CustomLabelOpULAInputModelType": model,
      }
    );
    return result;
  }
  static GetCustomLabelsConfigurationByPromotion(itemCode){
    const result = GraphqlHandler.openRequestGraphQlQuery(
      route,
      `(@autodeclare){
        getCustomLabelsConfigurationByPromotion(itemCode:$itemCode)
            {
              code 
              message                    
              customLabelList
              {
                id
                itemCode
                image
                isSaved
                label
                template
              }
            }
        }`,
      true,
      {
        itemCode
      }
    );
    return result;
  }
}
export default CustomLabels;
