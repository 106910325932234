import React from "react";
import { isIOS, isMobile, isTablet } from "react-device-detect";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

const FloatingKitInfo = ({ open, setIsActiveFlotingKitInfo, imageKit }) => {
  return (
    <div
      className={`floating__kit__info ${open ? "active" : ""} ${
        isIOS ? "is__iOS" : ""
      }`}
    >
      <div className="floating__kit__info__header">
        <div className="floating__kit__info__header__close__wrapper">
          <IconButton
            onClick={() => {
              setIsActiveFlotingKitInfo(false);
            }}
          >
            <Close />
          </IconButton>
        </div>
      </div>

      <div
        className={`floating__kit__info__main ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }`}
      >
        <div
          className={`floating__kit__info__main__content__image ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          <img
            src={imageKit}
            alt="kit"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FloatingKitInfo;
