import { Skeleton } from "@mui/material";
import React, { Fragment } from "react";
import { isMobile, isTablet } from "react-device-detect";

const CompanyInformation = ({ backColor, t, isLanguageLoaded }) => {
  return (
    <div
      style={{
        backgroundColor: `${backColor}`,
      }}
      className={`company__information__main ${
        isTablet ? "tablet" : isMobile ? "mobile" : ""
      }`}
    >
      <div
        className={`company__information__main__wrapper ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }`}
      >
        {!isLanguageLoaded ? (
          <Fragment>
            <Skeleton
              variant="wave"
              width={isMobile ? 300 : 500}
              height={20}
              style={{ marginBottom: 10 }}
            />
            <Skeleton
              variant="wave"
              width={isMobile ? 300 : 500}
              height={20}
              style={{ marginBottom: 10 }}
            />
          </Fragment>
        ) : (
          <Fragment>
            <span
              className={`company__information__main__wrapper__title ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              {t("__company__info__new__members__title")}
            </span>
            <span
              className={`company__information__main__wrapper__subtitle ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              {t("__company__info__new__members__subtitle")}
            </span>
          </Fragment>
        )}

        <div
          className={`company__information__main__wrapper__image ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          {!isLanguageLoaded ? (
            <Skeleton width={300} height={300} />
          ) : (
            <img src="https://www.websizusa.net/imgprod/zu/modules/14/86/3/Imagen_detail_web.jpg" />
          )}
        </div>
      </div>
      <div
        className={`company__information__main__message ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }`}
      >
        <div
          className={`company__information__main__message__wrapper__one ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          {!isLanguageLoaded ? (
            <Fragment>
              <Skeleton
                variant="wave"
                width={isMobile ? 300 : 500}
                height={20}
                style={{ marginBottom: 10 }}
              />
              <Skeleton
                variant="wave"
                width={isMobile ? 300 : 500}
                height={20}
                style={{ marginBottom: 10 }}
              />
            </Fragment>
          ) : (
            <Fragment>
              {" "}
              <span>{t("__company__info__new__members__message__one")}</span>
              <br />
              <span>{t("__company__info__new__members__message__two")}</span>
            </Fragment>
          )}
        </div>
        <div
          className={`company__information__main__message__wrapper__two ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          {!isLanguageLoaded ? (
            <Fragment>
              <Skeleton
                variant="wave"
                width={isMobile ? 300 : 500}
                height={20}
                style={{ marginBottom: 10 }}
              />
              <Skeleton
                variant="wave"
                width={isMobile ? 300 : 500}
                height={20}
                style={{ marginBottom: 10 }}
              />
            </Fragment>
          ) : (
            <Fragment>
              {" "}
              <span>
                {t("__company__info__new__members__message__three")}
                <br />
                {t("__company__info__new__members__message__four")}
              </span>
              <br />
              <span>{t("__company__info__new__members__message__five")}</span>
            </Fragment>
          )}
        </div>
        <div
          className={`company__information__main__message__wrapper__three ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          {!isLanguageLoaded ? (
            <Fragment>
              <Skeleton
                variant="wave"
                width={isMobile ? 300 : 500}
                height={20}
                style={{ marginBottom: 10 }}
              />
            </Fragment>
          ) : (
            <Fragment>
              {" "}
              <span>{t("__company__info__new__members__message__six")}</span>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanyInformation;
