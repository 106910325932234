import React from "react";
import { useTranslation } from "react-i18next";
import html2canvas from "html2canvas";
import { Button } from "@mui/material";
import { isMobile, isTablet } from "react-device-detect";
import { Save } from "@mui/icons-material";

const CardPersonalizedFraganceSet = ({
  listFragancesCustomer,
  item,
  setConfigurableArray,
  configurableArray,
  indexElement,
  dispatch,
  requestLoggUserActivity,
}) => {
  /// TRANSLATION
  const { t } = useTranslation();

  /// hooks

  const handleSetB64 = () => {
    //Generate b64
    html2canvas(document.getElementById(`divFinalCanvas${indexElement}`), {
      backgroundColor: "white",
    }).then(function (canvas) {
      //document.body.appendChild(canvas);
      const WrapperForLabelCanvas = document.getElementById(
        "WrapperForLabelCanvas"
      );
      WrapperForLabelCanvas.appendChild(canvas);
      const b64 = canvas.toDataURL("image/jpeg");

      //   const cloneArray = [...listFragancesCustomer];
      //   const cloneItem = { ...item, template: b64, isSaved: true };
      //   const index = cloneArray.findIndex((i) => i.id === cloneItem.id);

      //   cloneArray[index] = cloneItem;

      //  setCustomLabelList(cloneArray);

      let cloneConfigurableArray = [...configurableArray];
      //console.log("foundIndex", foundIndex);
      let cloneElement = cloneConfigurableArray[indexElement];

      //cloneElement.item.id = indexElement
      cloneElement.item.template = b64;
      cloneElement.item.isSaved = true;

      setConfigurableArray(cloneConfigurableArray);
      //
      dispatch(
        requestLoggUserActivity(
          78,
          0,
          0,
          cloneElement.item.itemCode,
          "Texto configurado: " + cloneElement.item.label
        )
      );
    });
  };

  return (
    <div
      className={`card__personalizes__fragance ${
        isTablet ? "tablet" : isMobile ? "mobile" : ""
      }`}
    >
      <div>
        <div className="edit__customer__badge__content" id="finalDivForCanvas">
          <div className="card__personalizes__fragance__image">
            <img
              //src="https://www.websizusa.net/imgprod/fullsize/83277_.jpg"
              //"https://www.websizusa.net/imgprod/fullsize/82615_.jpg" //{FraganceBig1}
              src={item?.image}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src =
                  "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
              }}
              alt="imagen del producto"
            />
            <div
              className={`edit__customer__badge__content__left__center__wrapper__images__message ${
                // item?.templateId === 1 ? "hexagonal" : "rectangular"
                "hexagonal"
              }`}
            >
              <span>{item?.label}</span>
            </div>
            <div
              id={`divFinalCanvas${indexElement}`}
              className={`div__final__canvas ${
                //item?.templateId === 1 ? "hexagonal" : "rectangular"
                "hexagonal"
              }`}
            >
              <span>{item?.label}</span>
            </div>
          </div>
          <div>
            <div className="textfield inner">
              <div className="label__and__button">
                <label htmlFor="my-input">
                  {t("__customer_support_form_message_label")}
                </label>
                <Button
                  className="button__with__icon bg__pink no__left__margin small"
                  disabled={item.isSaved || item.label.length <= 0}
                  onClick={() => {
                    handleSetB64();
                  }}
                >
                  <div className="button__with__icon__svg__wrapper pink__ball">
                    <Save />
                  </div>
                  <span className="button__with__icon__text">
                    {t("btn_text_save")}
                  </span>
                </Button>
              </div>

              <textarea
                id="my-input"
                style={{ height: 50 }}
                maxLength={30}
                rows={isMobile ? "5" : "5"}
                cols={isMobile ? "30" : "30"}
                onInput={(e) => {
                  //setMessage(e.target.value);

                  const updatedText = e.target.value.replace(
                    /[^a-zA-Z0-9!¡¿?áéíóúÁÉÍÓÚñÑ ]/gm,
                    ""
                  );

                  // setProductOnEdition({
                  //   ...productOnEdition,
                  //   label: updatedText,
                  // });

                  let cloneConfigurableArray = [...configurableArray];
                  //console.log("foundIndex", foundIndex);
                  let cloneElement = cloneConfigurableArray[indexElement];

                  cloneElement.item.label = updatedText;
                  cloneElement.item.template = "";
                  cloneElement.item.isSaved = false;

                  setConfigurableArray(cloneConfigurableArray);

                  //   const clone = [...listFragancesCustomer];
                  //   clone[foundIndex] = {
                  //     ...clone[foundIndex],
                  //     label: updatedText,
                  //     template: "",
                  //     isSaved: false,
                  //   };

                  //setCustomLabelList(clone);

                  ////setMessageFirstLevel(updatedText);
                }}
                onKeyDown={(e) => {
                  //console.log("key", e.key);

                  if (e.key === "Tab") {
                    e.preventDefault();
                  }
                }}
                inputprops={{ maxLength: 30 }}
                value={item.label}
                aria-describedby="my-helper-text"
              />
            </div>
          </div>
          <div
            className={`card__personalizes__fragance__warning ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            {t("__personalized__fragances_label_warning")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardPersonalizedFraganceSet;
