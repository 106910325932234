import GraphqlHandler from "../GraphqlHandler";
import GraphqlHandlerNoInterceptor from "../GraphqlHandler/noInterceptor";
import { ENVIROMENT_URL } from "../../React/ReduxSaga/Constants";

const route = ENVIROMENT_URL + "apiV3/ClientMicroservice/Client";
const routevalidation =
  ENVIROMENT_URL + "apiV3/ValidationMicroservice/Validation";
const routeGenderizeAPI = "https://api.genderize.io";

class Client {
  static ClientLogin(user) {
    const result = GraphqlHandler.openRequestGraphQlQuery(
      route,
      //'http://localhost:59622/api/ClientSession',
      `(@autodeclare){
            userLogin(user:$user)
                {
                    response
                    {
                        code message
                    },
                    userData
                    {
                        email
                        idClient
                        idGuestUser
                        idLang
                        idPage
                        idUser
                        isGuestUser
                        isOlder
                        nameClient
                        sessionId
                        sponsorId
                        typeUser
                    }
                }
              }`,
      true,
      {
        "user!UserLoginModelType": user,
      }
    );
    return result;
  }
  static NewClientRegister(newRegister) {
    const result = GraphqlHandler.openRequestGraphQlMutation(
      route,
      `
        (@autodeclare){
          userNewRegister(newRegister:$newRegister)
          {
            response
            {
              code,
              message
            },
            userData
            {
              email,
              idClient,
              idGuestUser,
              idLang,
              idPage
              idUser,
              isGuestUser,
              isOlder,
              nameClient,
              sessionId,
              sponsorId,
              typeUser
            }
          }
        }
      `,
      true,
      {
        "newRegister!NewRegisterModelType": newRegister,
      }
    );
    return result;
  }
  static WebPageContactRegister(sessionId, name, cellPhone, comment) {
    const result = GraphqlHandler.openRequestGraphQlMutation(
      route,
      `(@autodeclare){
        sendPendingContact(sessionId:$sessionId, name:$name, cellPhone:$cellPhone, comment:$comment)
        {
          code,
          message
        }
      }
      `,
      true,
      {
        "sessionId!Int": sessionId,
        name,
        cellPhone,
        comment,
      }
    );
    return result;
  }
  static GetMenuClientAdministration() {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      route,
      `
      {
        getDataAdministratorMenu
        {
          code,
          message,
          adminDataList
          {
            anchorResources,
            descriptionModConten,
            idLang,
            idMod,
            idModContent,
            idModContentRes,
            idModContentResType,
            idModContentType,
            nameModConten,
            orderRender,
            image
          }
        }
      }
      `,
      false
    );
    return result;
  }
  static GetClientInformation(sessionId) {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      route,
      //'https://localhost:44352/Client',
      `(@autodeclare)
      {
        getUserInformation(sessionId:$sessionId)
        {
          code,
          message,
          clientInfo
          {
            addFiscalExists,
            address,
            city,
            email,
            lastName,
            name,
            password,
            state,
            suite,
            telcell,
            zip,
            typeUser,
            birthday,
            identificationType,
            identification,
            gender
          }
        }
      }
      `,
      true,
      {
        "sessionId!Int": sessionId,
      }
    );
    return result;
  }
  static UpdateClientPersonalInformation(updateModel) {
    const result = GraphqlHandler.openRequestGraphQlMutation(
      route,
      `
      (@autodeclare) {
        updateUserData(updateModel: $updateModel)
        {
          code,
          message
        }
      }
      `,
      true,
      {
        "updateModel!UpdateModelType": updateModel,
      }
    );
    return result;
  }
  static SendEmailFromRetargetingProgram(sessionId, email) {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlMutation(
      route,
      `(@autodeclare) {
        sendEmailFinalClient(email:$email, sessionId:$sessionId)
        {
          code,
          message
        }
      }`,
      true,
      {
        email,
        "sessionId!Int": sessionId,
      }
    );
    return result;
  }
  static CloseSessionOnServer(sessionId) {
    const result = GraphqlHandler.openRequestGraphQlMutation(
      route,
      `(@autodeclare){
        closeUserSession(sessionId: $sessionId)
        {
          code,
          message
        }
      }`,
      true,
      {
        "sessionId!Int": sessionId,
      }
    );
    return result;
  }
  static RecoveryUserPassword(recoveryType, cellPhone, email) {
    const result = GraphqlHandler.openRequestGraphQlMutation(
      route,
      `(@autodeclare){
          userPasswordRecovery(recoveryType:$recoveryType, cellPhone:$cellPhone, email:$email)
          {
            code,
            message
          }
        }`,
      true,
      {
        "recoveryType!Int": recoveryType,
        "cellPhone!String": cellPhone,
        "email!String": email,
      }
    );
    return result;
  }
  static ValidationRecoveryPasswordCode(code) {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlMutation(
      route,
      //'https://localhost:44352/Client',
      `(@autodeclare)
        {
          userRecoveryCodeValidation(code:$code)
          {
            code,
            message,
            idClient
          }
        }`,
      true,
      {
        "code!String": code,
      }
    );
    return result;
  }
  static ResetUserPassword(clientId, password) {
    const result = GraphqlHandler.openRequestGraphQlMutation(
      route,
      //'https://localhost:44352/Client',
      `(@autodeclare)
      {
        userResetPassword(clientId:$clientId, password:$password)
        {
          code,
          message
        }
      }`,
      true,
      {
        "clientId!Int": clientId,
        "password!String": password,
      }
    );
    return result;
  }
  static GetClientConfirmedOrders(sessionId) {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      route,
      //'https://localhost:44352/Client',
      `(@autodeclare)
      {
        getUserConfirmedOrders(sessionId: $sessionId)
        {
          code,
          message,
          orders
          {
            address,
            charges,
            city,
            country,
            coupon,
            fecPedido,
            idPedido,
            imgPedido,
            itOnTime,
            orderCancellationStatus,
            name,
            state,
            statusPed,
            suite,
            totalAmount,
            subTotalAmount,
            tracking,
            zip,
            detail
            {
              cant,
              descEn,
              descEs,
              idPedido,
              imgProd,
              itemCode,
              price,
              typeLine,
              idPedidoDet,
              codean,
              stock,
            },
            trackingDetails
            {
              estimatedDeliveryDate,
              events
              {
                date,
                event,
                place
              }
            }
          }
        }
      }`,
      true,
      {
        "sessionId!Int": sessionId,
      }
    );
    return result;
  }
  static ValidateInitialBannerFlag(sessionId) {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      route,
      //'https://localhost:44352/Client',
      `(@autodeclare)
      {
        validateBannerFlag(sessionId: $sessionId)
        {
          code,
          message,
          showBanner
        }
      }`,
      true,
      {
        "sessionId!Int": sessionId,
      }
    );
    return result;
  }
  static ValidateIdentificationType(clientId, idType, idNumber) {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      route,
      //'https://localhost:44352/Client',
      `(@autodeclare)
      {
        validateIdentificationType(clientId:$clientId, idType:$idType, idNumber: $idNumber)
        {
          code,
          message,
          isExists
        }
      }`,
      true,
      {
        "clientId!Int": clientId,
        "idType!String": idType,
        "idNumber!String": idNumber,
      }
    );
    return result;
  }
  static UpdateDataFromCheckoutForm(model) {
    const result = GraphqlHandler.openRequestGraphQlMutation(
      route,
      //'https://localhost:44352/Client',
      `(@autodeclare)
      {
        updateCheckoutData(model:$model)
        {
          response
          {
            code,
            message
          },
          userData
          {
            email,
            idClient,
            idGuestUser,
            idLang,
            idPage
            idUser,
            isGuestUser,
            isOlder,
            nameClient,
            sessionId,
            sponsorId,
            typeUser,
            birthday,
            identification,
            identificationType,
            gender
          }
        }
      }
      `,
      true,
      {
        "model!UpdateCheckoutModelType": model,
      }
    );
    return result;
  }
  static InsertFiscalAddress(clientId, street, suite, zip, state, city) {
    const result = GraphqlHandler.openRequestGraphQlMutation(
      route,
      //'https://localhost:44352/Client',
      `(@autodeclare)
      {
        insertFiscalAddress
        (
          clientId:$clientId,
          street:$street,
          suite:$suite,
          zip:$zip,
          state:$state,
          city:$city
        )
        {
          code,
          message
        }
      }
      `,
      true,
      {
        "clientId!Int": clientId,
        "street!String": street,
        "suite!String": suite,
        "zip!String": zip,
        "state!String": state,
        "city!String": city,
      }
    );
    return result;
  }
  static ValidateEmailOwner(sessionId, email) {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      route,
      //'https://localhost:44352/Client',
      `(@autodeclare)
      {
        validateOwnerEmail(sessionId:$sessionId, email:$email)
        {
          code,
          message
        }
      }
      `,
      true,
      {
        "sessionId!Int": sessionId,
        "email!String": email,
      }
    );
    return result;
  }
  static ValidatePhoneOwner(sessionId, phone) {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      route,
      //'https://localhost:44352/Client',
      `(@autodeclare)
      {
        validateOwnerCellPhone(sessionId:$sessionId, cellPhone:$phone)
        {
          code,
          message
        }
      }
      `,
      true,
      {
        "sessionId!Int": sessionId,
        "phone!String": phone,
      }
    );
    return result;
  }
  //busqueda de patrocinador
  static GetDataSponsorById(sponsorId) {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      route,
      //'https://localhost:44352/Client',
      `(@autodeclare)
      {
        getDataSponsorById(sponsorId:$sponsorId)
        {
          code
          message
          assignedSponsors
          {
            city
            entreprenourId
            imageProfile
            lastName
            name
            state 
            email
            cellPhone   
          }
        }
      }
      `,
      true,
      {
        "sponsorId!Int": sponsorId,
      }
    );
    return result;
  }
  static GetDataSponsorByName(name, lastName) {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      route,
      //'https://localhost:44352/Client',
      `(@autodeclare)
      {
        getDataSponsorByName(name:$name,lastName:$lastName)
        {
          code
          message
          assignedSponsors
          {
            city
            entreprenourId
            imageProfile
            lastName
            name
            state   
            email
            cellPhone 
          }
        }
      }
      `,
      true,
      {
        "name!String": name,
        "lastName!String": lastName,
      }
    );
    return result;
  }
  static GetDataSponsorByCity(idState, city) {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      route,
      //'https://localhost:44352/Client',
      `(@autodeclare)
      {
        getDataSponsorByCity(idState:$idState,city:$city)
        {
          code
          message
          assignedSponsors
          {
            city
            entreprenourId
            imageProfile
            lastName
            name
            state 
            email
            cellPhone   
          }
        }
      }
      `,
      true,
      {
        "idState!Int": idState,
        "city!String": city,
      }
    );
    return result;
  }
  static AssignSponsor(sponsorId, sessionId) {
    const result = GraphqlHandler.openRequestGraphQlMutation(
      route,
      //'https://localhost:44352/Client',
      `(@autodeclare)
      {
        assignSponsor
        (
          sponsor:$sponsor,
          sessionId:$sessionId
        )
        {
          code,
          message
        }
      }
      `,
      true,
      {
        "sponsor!Int": sponsorId,
        "sessionId!Int": sessionId,
      }
    );
    return result;
  }
  static UpdateShippingAddress(sessionId, street, suite, zip, state, city) {
    const result = GraphqlHandler.openRequestGraphQlMutation(
      route,
      //'https://localhost:44352/Client',
      `(@autodeclare)
      {
        updateShippingAddress
        (
          sessionId:$sessionId,
          street:$street,
          suite:$suite,
          zip:$zip,
          state:$state,
          city:$city
        )
        {
          code,
          message
        }
      }
      `,
      true,
      {
        "sessionId!Int": sessionId,
        "street!String": street,
        "suite!String": suite,
        "zip!String": zip,
        "state!String": state,
        "city!String": city,
      }
    );
    return result;
  }
  static ValidateEmailReferrer(email) {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      route,
      //'https://localhost:44352/Client',
      `(@autodeclare)
      {
        validateEmailReferred(email:$email)
        {
          code message isExists
        }
      }
      `,
      true,
      {
        email: email,
      }
    );
    return result;
  }
  static ValidateCellPhoneReferrer(cellPhone) {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      route,
      //'https://localhost:44352/Client',
      `(@autodeclare)
      {
        validateCellPhoneReferred(cellPhone:$cellPhone)
        {
          code message isExists
        }
      }
      `,
      true,
      {
        cellPhone: cellPhone,
      }
    );
    return result;
  }
  static RegisterNewReferrer(referred) {
    const result = GraphqlHandler.openRequestGraphQlMutation(
      route,
      //'https://localhost:44352/Client',
      `(@autodeclare)
      {
        addNewReferredClient(referred: $referred)
        {
          code message
        }
      }
      `,
      true,
      {
        "referred!ReferrerModelInputType": referred,
      }
    );
    return result;
  }
  static GetReferrerCode(sessionId) {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      route,
      //'https://localhost:44352/Client',
      `(@autodeclare)
      {
        getReferrerCode(sessionId: $sessionId)
        {
          code message
        }
      }
      `,
      true,
      {
        "sessionId!Int": sessionId,
      }
    );
    return result;
  }
  static LogReferrerCodeSended(sendedCodeModel) {
    const result = GraphqlHandler.openRequestGraphQlMutation(
      route,
      //'https://localhost:44352/Client',
      `(@autodeclare)
      {
        insertReferrerCodeSended(sendedCodeModel: $sendedCodeModel)
        {
          code message
        }
      }
      `,
      true,
      {
        "sendedCodeModel!SendedReferrerCodeModelInputType": sendedCodeModel,
      }
    );
    return result;
  }
  static GetReferrerListByReferrerCode(referrerCode) {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      route,
      //'https://localhost:44352/Client',
      `(@autodeclare)
      {
        getReferrersByCodeList(referrerCode: $referrerCode)
        {
          code message
          referrers{
            id name isRegistered dateAudit
         }
        }
      }
      `,
      true,
      {
        referrerCode,
      }
    );
    return result;
  }
  static UploadImageProfile(data, fileName) {
    const result = GraphqlHandler.openRequestGraphQlMutation(
      route,
      //'https://localhost:44352/Client',
      `(@autodeclare)
      {
        uploadImageProfile(data: $data, fileName: $fileName)
        {
          code message
        }
      }
      `,
      true,
      {
        data,
        fileName,
      }
    );
    return result;
  }
  static RegisterSupportQuestion(model) {
    const result = GraphqlHandler.openRequestGraphQlMutation(
      route,
      //'https://localhost:44309/Client',
      `(@autodeclare)
      {
        registerSupportQuestion(model: $model)
        {
          code message
        }
      }
      `,
      true,
      {
        "model!SupportQuestionInputModelType": model,
      }
    );
    return result;
  }
  static RequestImmediateGains(model) {
    const result = GraphqlHandler.openRequestGraphQlMutation(
      route,
      //'https://localhost:44352/Client',
      `(@autodeclare)
      {
        requestImmediateGains(model: $model)
        {
          code message
        }
      }
      `,
      true,
      {
        "model!ImmediateGainsInputModelType": model,
      }
    );
    return result;
  }
  static GetDataSponsorAutomatic() {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      route,
      //'https://localhost:44352/Client',
      `(@autodeclare)
      {
        getDataSponsorAutomatic
        {
          code
          message
          assignedSponsors
          {
            city
            entreprenourId
            imageProfile
            lastName
            name
            state   
            email
            cellPhone 
          }
        }
      }
      `,
      true
    );
    return result;
  }
  static AssignSponsorAutomatic(sponsorId, sessionId) {
    const result = GraphqlHandler.openRequestGraphQlMutation(
      route,
      //'https://localhost:44352/Client',
      `(@autodeclare)
      {
        assignSponsorAutomatic
        (
          sponsor:$sponsor,
          sessionId:$sessionId
        )
        {
          code,
          message
        }
      }
      `,
      true,
      {
        "sponsor!Int": sponsorId,
        "sessionId!Int": sessionId,
      }
    );
    return result;
  }
  static LoggUserActivity(
    sessionId,
    topicItemId,
    idSubCategory,
    idCategory,
    productSku,
    aditionalInfo
  ) {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlMutation(
      route,
      //'https://localhost:44352/Client',
      `(@autodeclare)
      {
        loggUserActivity
        (
          sessionId:$sessionId,
          topicItemId:$topicItemId,
          idSubCategory:$idSubCategory,
          productSku:$productSku,
          aditionalInfo:$aditionalInfo,
          idCategory:$idCategory,
        )
        {
          code,
          message,
          result
        }
      }
      `,
      true,
      {
        "sessionId!Int": sessionId,
        "topicItemId!Int": topicItemId,
        "idSubCategory!Int": idSubCategory,
        "idCategory!Int": idCategory,
        "productSku!String": productSku,
        "aditionalInfo!String": aditionalInfo,
      }
    );
    return result;
  }
  static GetClientGuestInfoByEmail(email) {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      route,
      //'http://localhost:55692/Client',
      `(@autodeclare)
      {
        getClientGuestInfoByEmail(email: $email)
        {
          code
          message
          clientGuestInfo {
            name
            lastName
            email
            phone
            stateS
            cityS
            streetS
            zipS
            suiteS
            stateF
            cityF
            streetF
            zipF
            suiteF
            isExists
            idClient
          }
        }
      }
      `,
      true,
      {
        "email!String": email,
      }
    );
    return result;
  }
  static VerifyNewsLetterSubscription = (email) => {
    const result = GraphqlHandler.openRequestGraphQlMutation(
      route,
      //'https://localhost:44352/Client',
      `(@autodeclare)
      {
        requestVerificationNewsLetterSubscription(email: $email)
        {
          code message
        }
      }
      `,
      true,
      {
        email,
      }
    );
    return result;
  };
  static GetGenderPosibilityByName = async (name) => {
    return fetch(
      `${routeGenderizeAPI}?name=${name}&apikey=411ef589ddb53e421d07d0843c3a4853`
    )
      .then((response) => response.json())
      .catch((error) => error);
  };
  static GetMembershipInformation(sessionId) {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      route,
      //'https://localhost:44352/Client',
      `(@autodeclare)
      {
        getMembershipInformation(sessionId:$sessionId)
        {
          code
          message
          membershipInfo{
            activeMembership
            currentDate
            endMembership
            intMembership
            remainingDays
            
          }
        }
      }
      `,
      true,
      {
        "sessionId!Int": sessionId,
      }
    );
    return result;
  }
  static GetUserDataValidation(model) {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      routevalidation,
      //'https://localhost:44352/Client',
      `(@autodeclare)
      {
        validateUserData(model:$model)
        {
          code
          message
          validationList {
            validationType
            validationDescription
          }
        }
      }
      `,
      true,
      {
        "model!UserDataValidationType": model,
      }
    );
    return result;
  }
  //New
  static NewRegistrationLoginData(model) {
    const result = GraphqlHandler.openRequestGraphQlMutation(
      route,
      `
        (@autodeclare){
          newRegistrationLoginData(model:$model)
          {
            response
            {
              code,
              message
            },
            userData
            {
              birthday,
              email,
              gender,
              idClient,
              identification,
              identificationType,
              idGuestUser,
              idLang,
              idPage
              idUser,
              isGuestUser,
              isOlder,
              nameClient,
              sessionId,
              sponsorId,
              typeUser
            }
          }
        }
      `,
      true,
      {
        "model!NewRegistrationModelType": model,
      }
    );
    return result;
  }
  static NewRegistrationCheckoutData(model) {
    const result = GraphqlHandler.openRequestGraphQlMutation(
      route,
      `
        (@autodeclare){
          newRegistrationCheckoutData(model:$model)
          {
            response
            {
              code,
              message
            },
            userData
            {
              birthday,
              email,
              gender,
              idClient,
              identification,
              identificationType,
              idGuestUser,
              idLang,
              idPage
              idUser,
              isGuestUser,
              isOlder,
              nameClient,
              sessionId,
              sponsorId,
              typeUser,
              isExists
            }
          }
        }
      `,
      true,
      {
        "model!NewRegistrationCheckoutModelType": model,
      }
    );
    return result;
  }
  static UpdateAddressCheckoutData(model) {
    const result = GraphqlHandler.openRequestGraphQlMutation(
      route,
      `
        (@autodeclare){
          updateAddressCheckoutData(model:$model)
          {
            code
            message
          }
        }
      `,
      true,
      {
        "model!UpdateAddressModelType": model,
      }
    );
    return result;
  }
}
export default Client;
