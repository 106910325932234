import React, { Fragment } from "react";
import {
  Drawer,
  Button,
  Chip,
  Paper,
  TextField,
  Typography,
  FormControl,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const ModalSliderRepaymentAll = ({
  isOpenModalReturnAll,
  setIsOpenModalReturnAll,
  setContenShow,

  exchangeRateList,
  reasonsReturnList,

  handleClickShippingAllProducts,

  setFullCancellationType,
  fullCancellationType,
  setReasonCompleteCancellation,
  reasonCompleteCancellation,
  setFullCancellationComment,
}) => {
  /// TRANSLATION
  const { t } = useTranslation();

  //// hooks
  const handleCloseSlideModal = () => {
    setReasonCompleteCancellation(0);
    setFullCancellationType(0);
    setIsOpenModalReturnAll(false);
  };

  // const handleCloseNotification = () => {
  //   setIsOpenNotification(false);
  // };

  return (
    <Fragment>
      {/* <NotificationSnackbar
        isOpenNotification={isOpenNotification}
        setIsOpenNotification={handleCloseNotification}
        message={t("__notification_successful")}
        status="success"
        icon="Check"
      /> */}
      <Drawer
        anchor="bottom"
        className="slide__container"
        onClose={handleCloseSlideModal}
        open={isOpenModalReturnAll}
      >
        <Paper elevation={3}>
          <div className="modal__slide__container__pill">
            <Chip clickable onClick={handleCloseSlideModal} />
          </div>

          <div className="slide__content__main">
            <Paper className="slide__content__wrapper" elevation={3} square>
              <div className="slide__content__wrapper__title">
                <Typography variant="subtitle1" gutterBottom>
                  {t("__profile_repayment_container_title")}
                </Typography>
              </div>
            </Paper>

            <div>
              <div className="shopping__repayment__container__description">
                <Typography variant="caption" display="block" gutterBottom>
                  {t("__profile_repayment_container_subtitle")}
                </Typography>
              </div>
              <div className="shopping__repayment__container__wrapper">
                <div>
                  <FormControl variant="filled">
                    <TextField
                      id="standard-select-currency"
                      select
                      color="error"
                      value={reasonCompleteCancellation}
                      onChange={(event) => {
                        setReasonCompleteCancellation(
                          parseInt(event.target.value)
                        );
                        setFullCancellationComment(
                          t(
                            reasonsReturnList.find(
                              (e) => e.id === parseInt(event.target.value)
                            ).value
                          )
                        );
                      }}
                      className="shopping__repayment__container__wrapper__select"
                    >
                      <MenuItem value={0} disabled>
                        <em>
                          {t("__profile_repayment_container_select_label")}
                        </em>
                      </MenuItem>
                      {reasonsReturnList.map((item, indx) => {
                        return (
                          item.isFullRefund && (
                            <MenuItem key={indx} value={item.id}>
                              {t(item.value)}
                            </MenuItem>
                          )
                        );
                      })}
                    </TextField>
                  </FormControl>
                </div>

                {reasonCompleteCancellation > 0 && (
                  <div className="shopping__repayment__container__wrapper__radios">
                    <div className="shopping__repayment__container__list__item_wrapper__change">
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="gender"
                          defaultValue="female"
                          name="radio-buttons-group"
                          color=""
                          value={fullCancellationType}
                          onChange={(event) => {
                            setFullCancellationType(
                              parseInt(event.target.value)
                            );
                          }}
                        >
                          {exchangeRateList.map((item, indx) => {
                            return (
                              <FormControlLabel
                                key={indx}
                                value={item.id}
                                control={<Radio />}
                                label={t(item.value)}
                              />
                            );
                          })}
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                )}
                <div className="shopping__repayment__container__description">
                  <Typography variant="caption" display="block" gutterBottom>
                    {t("__profile_repayment_container_finish_warning_label")}
                  </Typography>
                </div>
                <div className="shopping__repayment__container__footer">
                  <Button
                    color="error"
                    disabled={
                      reasonCompleteCancellation === 0 ||
                      fullCancellationType === 0
                    }
                    onClick={() => {
                      //handleCloseSlideModal();

                      handleClickShippingAllProducts();

                      //setContenShow("listAll");
                    }}
                    variant="contained"
                  >
                    {t("btn_text_send")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </Drawer>
    </Fragment>
  );
};

export default ModalSliderRepaymentAll;
