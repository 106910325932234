import React, { useState, useEffect } from "react";
//Libraries
import { convertNameToInitials } from "./../../../Libraries/Utils/utils";
//Assets
import AvatarPlaceholder from "./../../../assets/media/images/avatarPlaceholder.jpg";

const ImageProfile = ({ route, name }) => {
  //State
  const [showImage, setShowImage] = useState(false);

  ///Effects
  ///EFFECT ON ROUTE CHANGE
  useEffect(() => {
    // wrap your async call here
    const loadData = async () => {
      if (route.length > 0) {
        const result = await downloadProfileImageValidationSponsor(route);
        setShowImage(result);
      } else {
        setShowImage(false);
      }
    };

    // then call it here
    loadData();
  }, [route]);

  //Handlers
  const downloadProfileImageValidationSponsor = async (route) => {
    return new Promise((resolve, reject) => {
      var download = new Image();
      download.id = "emprendedor";
      download.src = route;
      download.onload = () => {
        resolve(true);
      };
      download.onerror = function (err, msg) {
        resolve(false);
      };
    });
  };

  return (
    <div
      className={`circle__avatar__wrapper small__size ${
        !showImage ? "with__initials" : ""
      }`}
    >
      {showImage ? (
        <img
          src={route}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = AvatarPlaceholder;
          }}
          alt=""
        />
      ) : (
        <span>{convertNameToInitials(name)}</span>
      )}
    </div>
  );
};

export default ImageProfile;
