import {
    REQUEST_NEWMEMBER_REGISTER_NEW_PROSPECT,
    REQUEST_NEWMEMBER_GET_REGISTERED_NEW_PROSPECT,
    CLEAR_NEWMEMBER_REDUCER_REGISTRATION,
} from './../../Constants'


export const requestRegisterNewProspect = model => {
    let sessionId = 0;
    if (localStorage.getItem("ZUsaUInfo")) {
    sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
    }
    model.sessionId = parseInt(sessionId)
    return {
        type: REQUEST_NEWMEMBER_REGISTER_NEW_PROSPECT,
        payload: {
            model
        }
    }
}
export const requestGetRegisteredProspect = clientId => {
    return {
        type: REQUEST_NEWMEMBER_GET_REGISTERED_NEW_PROSPECT,
        payload: {clientId}
    }
}
export const cleanNewMemberReducer = () => {
    return {
        type: CLEAR_NEWMEMBER_REDUCER_REGISTRATION,
        payload: { }
    }
}