import GraphqlHandler from '../GraphqlHandler';
import GraphqlHandlerNoInterceptor from '../GraphqlHandler/noInterceptor'
import { ENVIROMENT_URL } from '../../React/ReduxSaga/Constants';

const routeRepayment = ENVIROMENT_URL + 'apiV3/RepaymentMicroservice/Repayment'

class RepaymentModule {

    static GetExchangeRate () {
        const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
            routeRepayment,
          `
          (@autodeclare)
          {
            repayment_GetExchangeRate{
              code
              message
              exchangeRateModels
              {
                id
                description
                value
              }
            }
          }
          `,
          true,
          { }
        );
        return result;
      }

    static GetReasonsReturn () {
        const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
            routeRepayment,
          `
          (@autodeclare)
          {
            repayment_GetReasonsReturn{
              code
              message
              reasonsReturnModels
              {
                id
                description
                value
                isFullRefund
                isPartialRefund
              }
            }
          }
          `,
          true,
          { }
        );
        return result;
      }

    static SetCompleteCancellation (orderId, sessionId) {
        const result = GraphqlHandler.openRequestGraphQlMutation(
            routeRepayment,
            `
            (@autodeclare){
              repayment_setCompleteCancellation(orderId:$orderId,sessionId:$sessionId){
                    code,
                    message,
                    result
                }
            }
            `,
            true,
            {
                "orderId!Int": orderId,
                "sessionId!Int": sessionId
            }
        );
        return result;
    }

    static SetFullRefund (orderId, sessionId, exchangeType, refoundReason, refoundComment) {
      const result = GraphqlHandler.openRequestGraphQlMutation(
          routeRepayment,
          //'https://localhost:44306/Repayment',
          `
          (@autodeclare){
            repayment_setFullRefund(orderId:$orderId,sessionId:$sessionId,exchangeType:$exchangeType,refoundReason:$refoundReason,refoundComment:$refoundComment){
                  code,
                  message,
                  result
              }
          }
          `,
          true,
          {
              "orderId!Int": orderId,
              "sessionId!Int": sessionId,

              "exchangeType!Int": exchangeType,
              "refoundReason!Int": refoundReason,
              "refoundComment!String": refoundComment
          }
      );
      return result;
    }

    static SetPartialRefund (orderId, sessionId, articlesReturnModel) {
      const result = GraphqlHandler.openRequestGraphQlMutation(
          routeRepayment,
          //'https://localhost:44306/Repayment',
          `
          (@autodeclare){
            repayment_setPartialRefund(orderId:$orderId,sessionId:$sessionId,articlesReturnModel:$articlesReturnModel){
                  code,
                  message,
                  result
              }
          }
          `,
          true,
          {
              "orderId!Int": orderId,
              "sessionId!Int": sessionId,
              "articlesReturnModel![ArticlesReturnModelType]": articlesReturnModel
          }
      );
      return result;
    }

}
export default RepaymentModule