import React, { useState } from "react";
import { isMobile, isTablet } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
//MUI
import { Skeleton } from "@mui/material";
//Assets
import { ReactComponent as ZermatLogoBlack } from "../../../../assets/media/images/zermatLogoBlack.svg";
import { ReactComponent as PrevArrowLanguageSwitcher } from "../../../../assets/media/images/icons/prevArrowLanguageSwitcher.svg";
import { ReactComponent as NextArrowLanguageSwitcher } from "../../../../assets/media/images/icons/nextArrowLanguageSwitcher.svg";

const NavbarNewMembers = () => {
  //React Router
  const history = useHistory();
  //Translation
  const { t, i18n } = useTranslation();

  //// States
  const [isLanguageLoaded, setIsLanguageLoaded] = useState(true);

  return (
    <div
      className={`navbar__newMembers ${isMobile || isTablet ? "mobile" : ""} `}
    >
      <div className={`navbar__header ${isMobile || isTablet ? "mobile" : ""}`}>
        <div
          className={`navbar__newMembers__logo ${
            isMobile || isTablet ? "mobile" : ""
          }`}
        >
          <ZermatLogoBlack
            onClick={() => {
              let target = "";
              if (localStorage.getItem("ZUsaUrlPage")) {
                target = `/mypage/${localStorage.getItem("ZUsaUrlPage")}`;
              } else {
                target = "/home";
              }
              if (target.length > 0) {
                history.push(target);
              }
            }}
          />
        </div>
        <div
          className={`navbar__floating__bar ${
            isTablet ? "isTablet" : isMobile ? "mobile" : ""
          }`}
        >
          {!window.location.pathname.includes("/home") &&
          !window.location.pathname.includes("/mypage") ? (
            <span></span>
          ) : (
            <span></span>
          )}

          <div className="language__switcher">
            <div className="prev">
              <PrevArrowLanguageSwitcher />
            </div>
            <Swiper
              id="languageSwiper"
              //initialSlide={i18n.language === "en" ? 1 : 0}
              slidesPerView={1}
              updateOnWindowResize
              centeredSlides
              loop
              navigation={{
                prevEl: ".prev",
                nextEl: ".next",
              }}
              onSlideChange={(swiper) => {
                //console.log("onSlideChange => index:", swiper.realIndex);
                // if (swiper.realIndex === 0) {
                //   if (i18n.language !== "es") {
                //     //console.log("Cambiar idioma a español");
                //     i18n.changeLanguage("es");
                //   }
                // } else {
                //   if (i18n.language !== "en") {
                //     //console.log("Cambiar idioma a inglés");
                //     i18n.changeLanguage("en");
                //   }
                // }
                if (swiper.realIndex === 0) {
                  i18n.changeLanguage("en");
                } else if (swiper.realIndex === 1) {
                  i18n.changeLanguage("es");
                }
              }}
              modules={[Navigation]}
            >
              <SwiperSlide>
                <span>
                  {!i18next.isInitialized ? (
                    <Skeleton
                      variant="rect"
                      width={50}
                      height={20}
                      style={{ margin: "0 5px" }}
                    />
                  ) : (
                    t("__general__navbar_language_english")
                  )}
                </span>
              </SwiperSlide>
              <SwiperSlide>
                <span>
                  {" "}
                  {!i18next.isInitialized ? (
                    <Skeleton
                      variant="rect"
                      width={50}
                      height={20}
                      style={{ margin: "0 5px" }}
                    />
                  ) : (
                    t("__general__navbar_language_spanish")
                  )}
                </span>
              </SwiperSlide>
            </Swiper>

            <div className="next">
              <NextArrowLanguageSwitcher />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarNewMembers;
