import GraphqlHandlerNoInterceptor from "../GraphqlHandler/noInterceptor"
import { ENVIROMENT_URL } from '../../React/ReduxSaga/Constants';

const route = ENVIROMENT_URL + "apiV3/ClientMicroservice/Client";

class NewMember {
  static RegisterProspect(model) {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlMutation(
      route,
      //'http://localhost:59622/api/ClientSession',
      `(@autodeclare){
        requestRegisterNewClient(model:$model)
        {
          code
          message
          result
        }   
               
      }`,
      true,
      {
        "model!RegisterNewClientModelType": model
      }
    );
    return result;
  }
  static GetRegisteredProspect(clientId){
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
        route,
        //'http://localhost:59622/api/ClientSession',
        `(@autodeclare){
            getNewClientInformation(idClient:$idClient)
          {
            code
            message
            newClientInformation {
              clientId
              name
              lastName
              mothersLastName
              cellPhone
              email
              login
              password
              imageProfileSponsor
            }
          }   
                 
        }`,
        true,
        {
          "idClient!Int": clientId
        }
      );
      return result;
  }
}

export default NewMember