import { REQUEST_CLIENT_ADDRESS_VALIDATION } from './../../Constants'

export const requestAddressValidation = (street, suite, state, city, zip, type, dbCheck = true) => {
    // jsonAddress:"{'address1':'3434 W LITTLE YORK RD', 'address2':'', 'suite':'APT 2207', 'city':'HOUSTON', 'state':'TX', 'zip':'77091'}",
    // type:"f" o "e",
    // dbCheck:true
    const jsonAddress = {
        address1: street,
        address2: "",
        suite,
        city,
        state,
        zip
    }
    return {
        type: REQUEST_CLIENT_ADDRESS_VALIDATION,
        payload: {
            jsonAddress,
            type,
            dbCheck
        }
    }
}