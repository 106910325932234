import React, { Fragment, useState, useEffect } from "react";
import ShoppingCancel from "./components/ShoppingCancel";
import { Typography, Skeleton } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import NotificationSnackbar from "../base/components/NotificationSnackbar";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ModalHistoryTracking from "./components/ModalHistoryTracking";
import {
  requestCancelOrderComplete,
  requestGetExchangeRate,
  requestGetReasonsReturn,
  requestCleanRefoundReducer,
} from "../../ReduxSaga/Actions/Repayment";
import { requestLoggUserActivity } from "../../ReduxSaga/Actions/General";
import { useHistory } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
import { capitalizeText } from "../../../Libraries/Utils/utils";

const DetailShopping = ({ isLanguageLoaded }) => {
  //Notistack
  const { enqueueSnackbar } = useSnackbar();

  /// TRANSLATION
  const { t, i18n } = useTranslation();
  /// REDUX
  let history = useHistory();
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.client = useSelector((state) => state.client);
  const orderId = useParams().idPedido;
  appReducers.repayment = useSelector((state) => state.repayment);

  //state
  const [statusOpc, setStatusOpc] = useState(4);
  //const [isOpenNotification, setIsOpenNotification] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);
  const [orderSelected, setOrderSelected] = useState({});
  const [isOpenSlideModal, setIsOpenSlideModal] = useState(false);
  //const [isObjectFakeReducer, setIsObjectFakeReducer] = useState({});
  //catalogos
  const [listExchangeRate, setListExchangeRate] = useState([]);
  const [listReasonsReturn, setListReasonsReturn] = useState([]);
  /// loading
  //const [isLoadingEnabled, setIsLoadingEnabled] = useState(true);

  ///USEEFFECTS
  useEffect(() => {
    if (appReducers?.client?.clientConfirmedOrders) {
      const orders = appReducers?.client?.clientConfirmedOrders.orders;
      setOrderSelected(orders.find((x) => x.idPedido == orderId));
      setOrderDetails(orders.find((x) => x.idPedido == orderId).detail);
      setStatusOpc(
        orders.find((x) => x.idPedido == orderId).statusPed == 0
          ? 1
          : orders.find((x) => x.idPedido == orderId).statusPed
      );
    } else {
      window.history.back();
    }
  }, [appReducers?.client?.clientConfirmedOrders]);

  //GETCATALOGO DE TIPO DE CAMBIO
  useEffect(() => {
    if (appReducers?.repayment?.exchangeRate) {
      if (appReducers.repayment.exchangeRate.code === 100) {
        setListExchangeRate(
          appReducers.repayment.exchangeRate.exchangeRateModels
        );
      }
    } else {
      dispatch(requestGetExchangeRate());
    }
  }, [appReducers?.repayment?.exchangeRate]);

  //GETCATALOGO DE RASONES DE CAMBIO
  useEffect(() => {
    if (appReducers?.repayment?.reasonsReturn) {
      if (appReducers.repayment.reasonsReturn.code === 100) {
        setListReasonsReturn(
          appReducers.repayment.reasonsReturn.reasonsReturnModels
        );
      }
    } else {
      dispatch(requestGetReasonsReturn());
    }
  }, [appReducers?.repayment?.reasonsReturn]);

  //CANCELACION COMPLETA
  useEffect(() => {
    if (appReducers?.repayment?.cancellationComplete) {
      if (appReducers.repayment.cancellationComplete.code === 100) {
        enqueueSnackbar(
          t("__profile_Detail_shopping_cancel_successful_label"),
          {
            variant: "success",
          }
        );
        //setIsOpenNotification(true);
        //se realizo la devolucion
        //REDIRECT A LA LISTA DE MIS COMPRAS
        setTimeout(() => {
          history.push("/profile/shopping-list");
        }, 3000);

        dispatch(
          requestLoggUserActivity(
            35,
            0,
            0,
            "",
            "Click en: CANCELACIÓN DE PEDIDO. Se realizo la cancelacion Completa-Inmediata del pedido: " +
              String(orderId)
          )
        );
      } else {
        if (appReducers.repayment.cancellationComplete.code === 200) {
          //El pedido ya fue cancelado
          enqueueSnackbar(
            t("__profile_Detail_shopping_cancel_successful_label"),
            {
              variant: "success",
            }
          );
          //setIsOpenNotification(true);
        } else if (appReducers.repayment.cancellationComplete.code === -200) {
          //El pedido ya fue Facurado no es posible. No es posible cancelarlo!
          enqueueSnackbar(
            t("__profile_Detail_shopping_cancel_successful_label"),
            {
              variant: "success",
            }
          );
          //setIsOpenNotification(true);
        } else if (appReducers.repayment.cancellationComplete.code === -300) {
          //No concuerda el numero del asesor con el pedido
          enqueueSnackbar(
            t("__profile_Detail_shopping_cancel_successful_label"),
            {
              variant: "success",
            }
          );
          // setIsOpenNotification(true);
        } else if (appReducers.repayment.cancellationComplete.code === -400) {
          //No es posible cancelar el pedido, por el limite de tiempo
          enqueueSnackbar(
            t("__profile_Detail_shopping_cancel_successful_label"),
            {
              variant: "success",
            }
          );
          //setIsOpenNotification(true);
        } else if (appReducers.repayment.cancellationComplete.code === -500) {
          //Error al crear la devolucion (contactar a sistemas)
          enqueueSnackbar(
            t("__profile_Detail_shopping_cancel_successful_label"),
            {
              variant: "success",
            }
          );
          //setIsOpenNotification(true);
        }
      }
      //CLEAN
      dispatch(requestCleanRefoundReducer());
    }
  }, [appReducers?.repayment?.cancellationComplete]);

  //// hooks
  const getShoppingHeaderStatus = (stepActive) => {
    switch (stepActive) {
      case 1:
        return (
          <div className="detail__shopping__container__headers">
            {i18n.language === "es" ? (
              <div className="shopping__spa__icon" />
            ) : (
              <div className="shopping__eng__icon " />
            )}

            {i18n.language === "es" ? (
              <div className="training__disabled__spa__icon" />
            ) : (
              <div className="training__disabled__eng__icon " />
            )}
            {i18n.language === "es" ? (
              <div className="ship__disabled__spa__icon  " />
            ) : (
              <div className="ship__disabled__eng__icon" />
            )}
            {i18n.language === "es" ? (
              <div className="delivered__disabled__spa__icon" />
            ) : (
              <div className="delivered__disabled__eng__icon" />
            )}
          </div>
        );
      case 2:
        return (
          <div className="detail__shopping__container__headers">
            {i18n.language === "es" ? (
              <div className="shopping__disabled__spa__icon" />
            ) : (
              <div className="shopping__disabled__eng__icon " />
            )}

            {i18n.language === "es" ? (
              <div className="training__spa__icon" />
            ) : (
              <div className="training__eng__icon " />
            )}
            {i18n.language === "es" ? (
              <div className="ship__disabled__spa__icon" />
            ) : (
              <div className="ship__disabled__eng__icon" />
            )}
            {i18n.language === "es" ? (
              <div className="delivered__disabled__spa__icon" />
            ) : (
              <div className="delivered__disabled__eng__icon" />
            )}
          </div>
        );
      case 3:
        return (
          <div className="detail__shopping__container__headers">
            {i18n.language === "es" ? (
              <div className="shopping__disabled__spa__icon" />
            ) : (
              <div className="shopping__disabled__eng__icon" />
            )}

            {i18n.language === "es" ? (
              <div className="training__disabled__spa__icon " />
            ) : (
              <div className="training__disabled__eng__icon" />
            )}
            {i18n.language === "es" ? (
              <div className="ship__spa__icon " />
            ) : (
              <div className="ship__eng__icon" />
            )}
            {i18n.language === "es" ? (
              <div className="delivered__disabled__spa__icon " />
            ) : (
              <div className="delivered__disabled__eng__icon " />
            )}
          </div>
        );
      case 4:
        return (
          <div className="detail__shopping__container__headers">
            {i18n.language === "es" ? (
              <div className="shopping__disabled__spa__icon" />
            ) : (
              <div className="shopping__disabled__eng__icon" />
            )}

            {i18n.language === "es" ? (
              <div className="training__disabled__spa__icon" />
            ) : (
              <div className="training__disabled__eng__icon" />
            )}
            {i18n.language === "es" ? (
              <div className="ship__disabled__spa__icon" />
            ) : (
              <div className="ship__disabled__eng__icon" />
            )}
            {i18n.language === "es" ? (
              <div className="delivered__spa__icon" />
            ) : (
              <div className="delivered__eng__icon" />
            )}
          </div>
        );
    }
  };
  const getShoppingInformationStatus = (stepActive) => {
    switch (stepActive) {
      case 1:
        return (
          <div className="detail__shopping__container__order__information__return">
            <Typography variant="caption" display="block" gutterBottom>
              {t("__profile_Detail_shopping_container_label")}
            </Typography>
            {orderSelected.itOnTime &&
            orderSelected.orderCancellationStatus === 0 ? ( //que no tenga ningun proceso de cancelacion previo
              <Typography
                className="detail__shopping__container__order__information__return__request"
                variant="button"
                display="block"
                gutterBottom
                onClick={handleCancelTotal}
              >
                {t("__profile_Detail_shopping_cancel_request_label")}
              </Typography>
            ) : // ya se esta procesando tu solucitud de cancelacion uno de nuestros
            orderSelected.itOnTime &&
              orderSelected.orderCancellationStatus === 1 ? ( //PENDIENTE
              <Typography
                className="detail__shopping__container__order__information__return__request"
                variant="button"
                display="block"
                gutterBottom
              >
                {t("__message_cancel_request_label")}
              </Typography>
            ) : (
              !orderSelected.itOnTime &&
              orderSelected.orderCancellationStatus > 0 && ( //que no tenga ningun proceso de cancelacion previo dentro de la hora
                <Typography
                  className="detail__shopping__container__order__information__return__request"
                  variant="button"
                  display="block"
                  gutterBottom
                >
                  {t("__message_cancel_request_label")}
                </Typography>
              )
            )}
          </div>
        );
      case 2:
        return (
          <div className="detail__shopping__container__order__information__return">
            <Typography variant="caption" display="block" gutterBottom>
              {t("__profile_Detail_shopping_order_text")}
            </Typography>
          </div>
        );
      case 3:
        return (
          <div className="detail__shopping__container__order__information__return">
            <Typography variant="caption" display="block" gutterBottom>
              {t("__profile_Detail_shopping_tracking_text")}
            </Typography>
            <Typography
              className="detail__shopping__container__order__information__return__request"
              onClick={handleOpenModalTraking}
              variant="button"
              display="block"
              gutterBottom
            >
              {t("__profile_Detail_shopping_order_tracking_text")}
            </Typography>
          </div>
        );
      case 4:
        return (
          <div className="detail__shopping__container__order__information__return">
            <Typography variant="caption" display="block" gutterBottom>
              {t("__profile_Detail_shopping_returns_request_text")}
            </Typography>
            <Typography
              className="detail__shopping__container__order__information__return__request"
              variant="button"
              display="block"
              gutterBottom
            >
              {orderSelected.orderCancellationStatus === 0 ? ( //SE [UEDE SOLICITAR PROCESO DE DEVOLUCION
                <Link
                  className="profile__link"
                  to={`/profile/shopping-list/repayment/${orderId}`}
                  onClick={() =>
                    dispatch(
                      requestLoggUserActivity(
                        54,
                        0,
                        0,
                        "",
                        "Click en: SOLICITAR UNA DEVOLUCION, Pedido: " +
                          String(orderId)
                      )
                    )
                  }
                >
                  {t("__profile_Detail_shopping_returns_request_label")}
                </Link>
              ) : orderSelected.orderCancellationStatus === 1 ? ( //YA SE SOLICITO UN PROCESO DE DEVOLUCION (PERO SE ENCUENTRA PENDIENTE)
                t("__message_cancel_request_label")
              ) : orderSelected.orderCancellationStatus === 2 ? ( //YA SE SOLICITO UN PROCESO DE DEVOLUCION  Y SE DIO UN RESOLUCION POSITIVA
                t("__message_return_favor_label")
              ) : (
                //YA SE SOLICITO UN PROCESO DE DEVOLUCION  Y SE DIO UN RESOLUCION NEGATIVA
                t("__message_return_request_not_return_label")
              )}
            </Typography>
          </div>
        );
      case 5:
        return (
          <ShoppingCancel
            setStatusOpc={setStatusOpc}
            handleClickCancellation={handleClickCancellation}
          />
        );
    }
  };
  const handleCancelTotal = () => {
    setStatusOpc(5);
    dispatch(
      requestLoggUserActivity(
        35,
        0,
        0,
        "",
        "Click en: SOLICITAR UNA CANCELACION "
      )
    );
  };

  const handleOpenModalTraking = () => {
    setIsOpenSlideModal(true);
  };

  const handleUpdateDeliveredStatus = () => {
    setStatusOpc(4);
  };

  const handleClickCancellation = () => {
    dispatch(requestCancelOrderComplete(orderId));
  };
  return (
    <div
      id="firstWrapperInBaseContainer"
      className="firstWrapperInBaseContainer"
    >
      {/* <BreadcrumbsNavigationComponet /> */}
      {statusOpc !== 5 && (
        <div
          className={`detail__shopping__container ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          <div
            className={`detail__shopping__container__title ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            {isLanguageLoaded ? (
              <Typography variant="h5" gutterBottom className="text__red">
                {t("__myAccount_Profile_Orders_Title")}
              </Typography>
            ) : (
              <Skeleton variant="rect" width={200} height={30} />
            )}
          </div>
          <Fragment>
            {/* <NotificationSnackbar
              isOpenNotification={isOpenNotification}
              setIsOpenNotification={setIsOpenNotification}
              message={t("__profile_Detail_shopping_cancel_successful_label")}
              status="success"
              icon="notification__icon__shopping__cancel"
            /> */}
          </Fragment>
          <Fragment>
            {orderSelected.trackingDetails && (
              <ModalHistoryTracking
                trackingDetails={orderSelected.trackingDetails}
                isOpenSlideModal={isOpenSlideModal}
                setIsOpenSlideModal={setIsOpenSlideModal}
                handleUpdateDeliveredStatus={handleUpdateDeliveredStatus}
              />
            )}
          </Fragment>
          {getShoppingHeaderStatus(statusOpc)}
          <div className="detail__shopping__container__order">
            <div className="detail__shopping__container__order__list">
              {orderDetails.length > 0 ? (
                <>
                  {orderDetails.map((det, index) => {
                    return (
                      det.typeLine !== "C" && (
                        <div
                          key={index}
                          className="detail__shopping__container__order__list__item"
                        >
                          <div>
                            <img
                              className="favorites__list__container__items__content__item__image"
                              src={det.imgProd}
                              alt={det.itemCode}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src =
                                  "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
                              }}
                            />
                          </div>
                          <div>
                            <Typography
                              variant="caption"
                              display="block"
                              gutterBottom
                            >
                              {i18n.language === "es"
                                ? capitalizeText(det.descEs)
                                : capitalizeText(det.descEn)}
                            </Typography>
                            <Typography
                              className="shopping__list__container__wrapper__item__price"
                              variant="caption"
                              display="block"
                              gutterBottom
                            >
                              ${parseFloat(det.price).toFixed(2)}
                            </Typography>
                          </div>
                          <div>
                            <Typography
                              variant="caption"
                              display="block"
                              gutterBottom
                            >
                              {det.cant} Pz
                            </Typography>
                          </div>
                        </div>
                      )
                    );
                  })}
                </>
              ) : (
                <>
                  {[0, 1, 2].map((itemX) => {
                    return (
                      <Skeleton
                        key={itemX}
                        width={"100%"}
                        height={100}
                        style={{ marginBottom: 5 }}
                      />
                    );
                  })}
                </>
              )}

              <Typography
                className="shopping__cancel__detail__prices"
                variant="caption"
                display="block"
                gutterBottom
              >
                <div className="detail__shopping__container__wrapper">
                  <div className="detail__shopping__container__wrapper__prices__subtitle">
                    {orderSelected.totalAmount ? (
                      t("__checkout_order_subtotal")
                    ) : (
                      <Skeleton
                        width={"100%"}
                        height={20}
                        style={{ marginBottom: 5 }}
                      />
                    )}
                  </div>
                  <div className="detail__shopping__container__wrapper__prices__amount">
                    {orderSelected.totalAmount ? (
                      <>${parseFloat(orderSelected.totalAmount).toFixed(2)}</>
                    ) : (
                      <Skeleton
                        width={"100%"}
                        height={20}
                        style={{ marginBottom: 5 }}
                      />
                    )}
                  </div>
                </div>
                <div className="detail__shopping__container__wrapper">
                  <div className="detail__shopping__container__wrapper__prices__subtitle">
                    {orderSelected.charges >= 0 ? (
                      t("__checkout_order_charges")
                    ) : (
                      <Skeleton
                        width={"100%"}
                        height={20}
                        style={{ marginBottom: 5 }}
                      />
                    )}
                  </div>
                  <div className="detail__shopping__container__wrapper__prices__amount">
                    {orderSelected.charges >= 0 ? (
                      <>${parseFloat(orderSelected.charges).toFixed(2)}</>
                    ) : (
                      <Skeleton
                        width={"100%"}
                        height={20}
                        style={{ marginBottom: 5 }}
                      />
                    )}
                  </div>
                </div>
                {orderSelected.importeimp && (
                  <div className="detail__shopping__container__wrapper">
                    <div className="detail__shopping__container__wrapper__prices__subtitle">
                      {orderSelected.importeimp >= 0 ? (
                        t("__checkout_order_taxes")
                      ) : (
                        <Skeleton
                          width={"100%"}
                          height={20}
                          style={{ marginBottom: 5 }}
                        />
                      )}
                    </div>
                    <div className="detail__shopping__container__wrapper__prices__amount">
                      {orderSelected.importeimp >= 0 ? (
                        <>${parseFloat(orderSelected.importeimp).toFixed(2)}</>
                      ) : (
                        <Skeleton
                          width={"100%"}
                          height={20}
                          style={{ marginBottom: 5 }}
                        />
                      )}
                    </div>
                  </div>
                )}

                <div className="detail__shopping__container__wrapper">
                  <div className="detail__shopping__container__wrapper__prices__subtitle">
                    {orderSelected.coupon >= 0 ? (
                      t("__checkout_order_credit")
                    ) : (
                      <Skeleton
                        width={"100%"}
                        height={20}
                        style={{ marginBottom: 5 }}
                      />
                    )}
                  </div>
                  <div className="detail__shopping__container__wrapper__prices__amount">
                    {orderSelected.coupon >= 0 ? (
                      <>${parseFloat(orderSelected.coupon).toFixed(2)}</>
                    ) : (
                      <Skeleton
                        width={"100%"}
                        height={20}
                        style={{ marginBottom: 5 }}
                      />
                    )}
                  </div>
                </div>
                <div className="detail__shopping__container__wrapper">
                  <div className="detail__shopping__container__wrapper__prices__subtitle__total ">
                    {orderSelected.totalAmount ? (
                      t("__checkout_order_total")
                    ) : (
                      <Skeleton
                        width={"100%"}
                        height={20}
                        style={{ marginBottom: 5 }}
                      />
                    )}
                  </div>
                  <div className="detail__shopping__container__wrapper__prices__amount__total">
                    {orderSelected.totalAmount ? (
                      <>
                        {" "}
                        $
                        {parseFloat(
                          orderSelected.totalAmount +
                            orderSelected.charges -
                            orderSelected.coupon
                        ).toFixed(2)}
                      </>
                    ) : (
                      <Skeleton
                        width={"100%"}
                        height={20}
                        style={{ marginBottom: 5 }}
                      />
                    )}
                  </div>
                </div>
              </Typography>
            </div>
            <div className="detail__shopping__container__order__information">
              <div className="detail__shopping__container__order__information__detail">
                <Typography
                  className="detail__shopping__container__order__information__detail__order"
                  variant="caption"
                  display="block"
                  gutterBottom
                >
                  {t("__checkout_order_label")} {orderSelected.idPedido}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  {t("__checkout_order_name_label")} {orderSelected.name}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  {t("__checkout_order_address_label")} {orderSelected.address},{" "}
                  {orderSelected.city}, {orderSelected.state}{" "}
                  {orderSelected.zip}, {orderSelected.country}
                </Typography>
                {statusOpc === 3 && (
                  <Typography variant="button" display="block" gutterBottom>
                    {t("__profile_Detail_shopping_tracking_number")}{" "}
                    {orderSelected.tracking}
                  </Typography>
                )}
              </div>
              {getShoppingInformationStatus(statusOpc)}
            </div>
          </div>
        </div>
      )}
      {statusOpc === 5 && (
        <ShoppingCancel
          setStatusOpc={setStatusOpc}
          order={orderSelected}
          handleClickCancellation={handleClickCancellation}
        />
      )}
    </div>
  );
};

export default DetailShopping;
