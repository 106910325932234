import React, { useEffect, Fragment } from "react";

import { Typography } from "@mui/material";
const Timer = ({
  setIsReturnActive,
  isStartCountDown,
  setIsStartCountDown,
  isCountDown,
  setIsCountDown,
}) => {
  const seconds = String(isCountDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(isCountDown / 60)).padStart(2, 0);
  useEffect(() => {
    let timerId;

    if (isStartCountDown) {
      setIsCountDown(60 * 5);
      timerId = setInterval(() => {
        setIsCountDown((isCountDown) => isCountDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [isStartCountDown]);

  useEffect(() => {
    if (isCountDown < 0 && isStartCountDown) {
      setIsReturnActive(true);
      //setIsStartCountDown(true);
      setIsCountDown(0);
      setIsStartCountDown(false);
    }
  }, [isCountDown, isStartCountDown]);

  return (
    <Fragment>
      <Typography variant="subtitle1" gutterBottom>
        {minutes}:{seconds}
      </Typography>
    </Fragment>
  );
};

export default Timer;
