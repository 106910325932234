import { Button } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { formatPrice } from "../../../../Libraries/Utils/utils";
import loaderBackgroundGray from "./../../../../assets/media/images/loaderBackgroundGray.svg";

const InitialCreditCardSVG = () => {
  return (
    <svg
      focusable="false"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 21"
    >
      <g fill="none" fillRule="evenodd">
        <g className="Icon-fill">
          <g transform="translate(0 2)">
            <path
              d="M26.58 19H2.42A2.4 2.4 0 0 1 0 16.62V2.38A2.4 2.4 0 0 1 2.42 0h24.16A2.4 2.4 0 0 1 29 2.38v14.25A2.4 2.4 0 0 1 26.58 19zM10 5.83c0-.46-.35-.83-.78-.83H3.78c-.43 0-.78.37-.78.83v3.34c0 .46.35.83.78.83h5.44c.43 0 .78-.37.78-.83V5.83z"
              opacity=".2"
            ></path>
            <path
              d="M25 15h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0H4c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1z"
              opacity=".3"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
const InvalidCreditCardSVG = () => {
  return (
    <svg
      focusable="false"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 21"
    >
      <g fill="none" fillRule="evenodd">
        <g id="error" className="Icon-fill">
          <g id="card" transform="translate(0 2)">
            <path
              id="shape"
              d="M21.68 0A6 6 0 1 0 29 9.47v7.15A2.4 2.4 0 0 1 26.58 19H2.42A2.4 2.4 0 0 1 0 16.62V2.38A2.4 2.4 0 0 1 2.42 0h19.26zM10 5.83c0-.46-.35-.83-.78-.83H3.78c-.43 0-.78.37-.78.83v3.34c0 .46.35.83.78.83h5.44c.43 0 .78-.37.78-.83V5.83z"
              opacity=".2"
            ></path>
            <path
              id="shape"
              d="M25 15h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0H4c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1z"
              opacity=".3"
            ></path>
          </g>
          <g id="shape" transform="translate(18)">
            <path d="M7 14A7 7 0 1 1 7 0a7 7 0 0 1 0 14zM6 3v4a1 1 0 0 0 2 0V3a1 1 0 0 0-2 0zm1 8.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
const CvcSVG = () => {
  return (
    <svg
      focusable="false"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 21"
    >
      <g fill="none" fillRule="evenodd">
        <g className="Icon-fill">
          <g transform="translate(0 2)">
            <path
              d="M21.68 0H2c-.92 0-2 1.06-2 2v15c0 .94 1.08 2 2 2h25c.92 0 2-1.06 2-2V9.47a5.98 5.98 0 0 1-3 1.45V11c0 .66-.36 1-1 1H3c-.64 0-1-.34-1-1v-1c0-.66.36-1 1-1h17.53a5.98 5.98 0 0 1 1.15-9z"
              opacity=".2"
            ></path>
            <path
              d="M19.34 3H0v3h19.08a6.04 6.04 0 0 1 .26-3z"
              opacity=".3"
            ></path>
          </g>
          <g transform="translate(18)">
            <path d="M7 14A7 7 0 1 1 7 0a7 7 0 0 1 0 14zM4.22 4.1h-.79l-1.93.98v1l1.53-.8V9.9h1.2V4.1zm2.3.8c.57 0 .97.32.97.78 0 .5-.47.85-1.15.85h-.3v.85h.36c.72 0 1.21.36 1.21.88 0 .5-.48.84-1.16.84-.5 0-1-.16-1.52-.47v1c.56.24 1.12.37 1.67.37 1.31 0 2.21-.67 2.21-1.64 0-.68-.42-1.23-1.12-1.45.6-.2.99-.73.99-1.33C8.68 4.64 7.85 4 6.65 4a4 4 0 0 0-1.57.34v.98c.48-.27.97-.42 1.44-.42zm4.32 2.18c.73 0 1.24.43 1.24.99 0 .59-.51 1-1.24 1-.44 0-.9-.14-1.37-.43v1.03c.49.22.99.33 1.48.33.26 0 .5-.04.73-.1.52-.85.82-1.83.82-2.88l-.02-.42a2.3 2.3 0 0 0-1.23-.32c-.18 0-.37.01-.57.04v-1.3h1.44a5.62 5.62 0 0 0-.46-.92H9.64v3.15c.4-.1.8-.17 1.2-.17z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

const CreditCardForm = ({
  t,
  orderData,
  handleStripeCreditCardPayment,
  requestSetLoggUserActivity,
  userFormData,
  isFiscalAddressVerified,
  isShipmentAddressVerified,
  showCheckoutMessage,
  withButton = true,
  creditCardData,
  setCreditCardData,
}) => {
  const dispatch = useDispatch();
  //Hooks
  const [creditCardNumber, setCreditCardNumber] = useState("");
  const [creditCardDate, setCreditCardDate] = useState("");
  const [creditCardCvc, setCreditCardCvc] = useState("");
  //
  const [creditCardNumberMaxLenght, setCreditCardNumberMaxLenght] =
    useState(16);
  const [ccLengthCVC, setCcLengthCVC] = useState(3);
  const [ccType, setCcType] = useState(undefined);
  const [errorType, setErrorType] = useState(undefined);
  const [errorCreditCardNumber, setErrorCreditCardNumber] = useState(true);
  const [errorCreditCardCvc, setErrorCreditCardCvc] = useState(true);
  const [errorCreditCardDate, setErrorCreditCardDate] = useState(true);

  //Handlers
  const showCreditCardError = (show, message, errorIn) => {
    if (show) {
      if (errorIn) {
        //cardnumber | cardate | cardcvc
        setErrorType(errorIn);
      }

      document
        .getElementById("credit__card__wrapper")
        .classList.add("is-invalid");
      document.getElementById("card__number").classList.add("is-invalid");
      document
        .getElementById("card__brand__icon__wrapper")
        .classList.add("is-invalid");
      //setCcType("INVALID");
      document
        .getElementById("credit__card__error__wrapper")
        .classList.add("active");
      if (message) {
        document.getElementById("credit__card__error__wrapper").innerText =
          message;
      }
    } else {
      document
        .getElementById("credit__card__wrapper")
        .classList.remove("is-invalid");
      document.getElementById("card__number").classList.remove("is-invalid");
      document
        .getElementById("card__brand__icon__wrapper")
        .classList.remove("is-invalid");
      setErrorType(undefined);
      document
        .getElementById("credit__card__error__wrapper")
        .classList.remove("active");
    }
  };

  const verifyCardTypeWithRegex = (number) => {
    if (number !== "" && number.length > 1) {
      const regexInitialAMEX = /^3[47]\d{0,13}$/;
      const regexCompleteAMEX = /^3[47]\d{13}$/;
      const regexInitialMasterCard = /^5[1-5]\d{0,14}$/;
      const regexCompleteMasterCard = /^5[1-5]\d{14}$/;
      const regexInitialVisa = /^4[0-9]\d{0,14}$/;
      const regexCompleteVisa = /^4\d{12}(\d{3})?$/;
      //American Express
      if (regexInitialAMEX.test(number)) {
        setCreditCardNumberMaxLenght(15);
        setCcLengthCVC(4);
        setCcType("AMEX");
        if (number.length === 15) {
          if (regexCompleteAMEX.test(number)) {
            document.getElementById("card__date").focus();
          }
        }
      }
      //Mastercard
      else if (regexInitialMasterCard.test(number)) {
        setCreditCardNumberMaxLenght(16);
        setCcLengthCVC(3);
        setCcType("MASTERCARD");
        if (number.length === 16) {
          if (regexCompleteMasterCard.test(number)) {
            document.getElementById("card__date").focus();
          }
        }
      }
      //Visa
      else if (regexInitialVisa.test(number)) {
        setCreditCardNumberMaxLenght(16);
        setCcLengthCVC(3);
        setCcType("VISA");
        if (number.length === 16) {
          if (regexCompleteVisa.test(number)) {
            document.getElementById("card__date").focus();
          }
        }
      }
      //Final
      if (number.length === creditCardNumberMaxLenght) {
        if (
          !(
            regexCompleteAMEX.test(number) ||
            regexCompleteMasterCard.test(number) ||
            regexCompleteVisa.test(number)
          )
        ) {
          setErrorCreditCardNumber(true);
          showCreditCardError(true, t("__general__card_invalid"), "cardnumber");
        } else {
          setErrorCreditCardNumber(false);
        }
      }
    } else {
      setErrorCreditCardNumber(true);
      setCreditCardNumberMaxLenght(16);
      setCcLengthCVC(3);
      setCcType(undefined);
    }
  };
  const dateIsInThePast = (date) => {
    //'date' is in format MM/YY
    let cardMonthInString = date.slice(0, 2);
    let cardMonth = parseInt(cardMonthInString.replace("0", ""), 10);
    let cardYear = parseInt(date.slice(3, 5), 10);
    //console.log("CARD DATA", cardMonth, cardYear);

    //Last day in month
    let lastDayInMonth = new Date(2020, cardMonth, 0).getDate();
    //console.log("lastDayInMonth", lastDayInMonth);

    //let selectedDate = new Date("2020-08-31");
    let selectedDateString = `20${cardYear}-${cardMonthInString}-${lastDayInMonth}`;
    let selectedDate = new Date(selectedDateString);
    let now = new Date();
    now.setHours(0, 0, 0, 0);
    return selectedDate < now;
  };

  const handleCardNumberChange = (e) => {
    if (!isNaN(e.target.value)) {
      if (setCreditCardData) {
        setCreditCardData({
          ...creditCardData,
          cardNumber: e.target.value,
        });
      } else {
        setCreditCardNumber(e.target.value);
      }
      showCreditCardError(false);
      if (e.target.value.length <= creditCardNumberMaxLenght) {
        verifyCardTypeWithRegex(e.target.value);
      }
      if (e.target.value.length !== creditCardNumberMaxLenght)
        setErrorCreditCardNumber(true);
      else setErrorCreditCardNumber(false);
    }
  };
  const handleCardDateChange = (e) => {
    const value = e.target.value;
    let formattedDate = value
      .replace(
        /[^0-9]/g,
        "" // To allow only numbers
      )
      .replace(
        /^([2-9])$/g,
        "0$1" // To handle 3 > 03
      )
      .replace(
        /^(1{1})([3-9]{1})$/g,
        "0$1/$2" // 13 > 01/3
      )
      .replace(
        /^0{1,}/g,
        "0" // To handle 00 > 0
      )
      .replace(
        /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g,
        "$1/$2" // To handle 113 > 11/3
      );
    if (setCreditCardData) {
      setCreditCardData({
        ...creditCardData,
        cardExpirationDate: formattedDate,
      });
    } else {
      setCreditCardDate(formattedDate);
    }

    if (formattedDate.length === 5) {
      //Validate id expire date of credit card is not in the past
      if (dateIsInThePast(value)) {
        setErrorCreditCardDate(true);
        showCreditCardError(
          true,
          t("__general__card_year__expiration"),
          "cardnumber"
        );
      } else {
        setErrorCreditCardDate(false);
        showCreditCardError(false);
        document.getElementById("card__cvc").focus();
      }
    }
  };
  const handleCarCvcChange = (e) => {
    if (setCreditCardData) {
      setCreditCardData({
        ...creditCardData,
        cardCvc: e.target.value,
      });
    } else {
      setCreditCardCvc(e.target.value);
    }
    if (e.target.value.length === ccLengthCVC) setErrorCreditCardCvc(false);
    else setErrorCreditCardCvc(true);
  };
  //Renders
  const renderCardIcon = () => {
    if (errorType) {
      switch (errorType) {
        case "cardnumber":
          return <InvalidCreditCardSVG />;
        case "cardcvc":
          return <CvcSVG />;
        default:
      }
    } else if (ccType) {
      switch (ccType) {
        case "AMEX":
          return (
            <img
              //src="//cdn.shopify.com/s/assets/payment_icons/american_express-2264c9b8b57b23b0b0831827e90cd7bcda2836adc42a912ebedf545dead35b20.svg"
              src="https://js.stripe.com/v3/fingerprinted/img/amex-a49b82f46c5cd6a96a6e418a6ca1717c.svg"
              alt="American Express"
            />
          );
        case "MASTERCARD":
          return (
            <img
              //src="//cdn.shopify.com/s/assets/payment_icons/master-173035bc8124581983d4efa50cf8626e8553c2b311353fbf67485f9c1a2b88d1.svg"
              src="https://js.stripe.com/v3/fingerprinted/img/mastercard-4d8844094130711885b5e41b28c9848f.svg"
              alt="Mastercard"
            />
          );
        case "VISA":
          return (
            <img
              //src="//cdn.shopify.com/s/assets/payment_icons/visa-319d545c6fd255c9aad5eeaad21fd6f7f7b4fdbdb1a35ce83b89cca12a187f00.svg"
              src="https://js.stripe.com/v3/fingerprinted/img/visa-365725566f9578a9589553aa9296d178.svg"
              alt="Visa"
            />
          );
        default:
      }
    } else {
      return <InitialCreditCardSVG />;
    }
  };

  //
  const validateDataOrder = () => {
    if (userFormData) {
      //debugger;
      //SHOW SUCCESS MESSAGE
      if (
        typeof userFormData.orderId === "undefined" ||
        userFormData.orderId <= 1
      ) {
        //erro en la orden
        //mostrar mensaje de Dirección correcta en 'fiscal'
        showCheckoutMessage(
          t("__ZUsa_NavBar_Error_Order_Info_No_Order"),
          "error"
        );
        return true;
      } else if (
        typeof userFormData.benefitType === "undefined" ||
        userFormData.benefitType < 0
      ) {
        //error al seleccionar el beneficio
        //mostrar mensaje de Dirección correcta en 'fiscal'
        showCheckoutMessage(
          t("__ZUsa_NavBar_Error_Order_Info_Benefit"),
          "error"
        );
        return true;
      } else if (
        !userFormData.fiscalCity ||
        userFormData.fiscalCity.length <= 0 ||
        !userFormData.fiscalState ||
        userFormData.fiscalState.length <= 0 ||
        !userFormData.fiscalStreet ||
        userFormData.fiscalStreet.length <= 0 ||
        !userFormData.fiscalZip ||
        userFormData.fiscalZip.length <= 0 ||
        !userFormData.shippCity ||
        userFormData.shippCity.length <= 0 ||
        !userFormData.shippState ||
        userFormData.shippState.length <= 0 ||
        !userFormData.shippStreet ||
        userFormData.shippStreet.length <= 0 ||
        !userFormData.shippZip ||
        userFormData.shippZip.length <= 0
      ) {
        //error en la direccion
        //mostrar mensaje de Dirección correcta en 'fiscal'
        showCheckoutMessage(
          t("__ZUsa_NavBar_Error_Order_Info_Address"),
          "error"
        );
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <div className="credit__card__component__container">
      <div id="credit__card__wrapper" className="credit__card__wrapper">
        <div
          id="card__brand__icon__wrapper"
          className="card__brand__icon__wrapper"
        >
          {renderCardIcon()}
        </div>

        <div className="credit__card__field__input__wrapper">
          <input
            id="card__number"
            type="text"
            inputMode="numeric"
            autoComplete="new-password"
            className="credit__card__input card__number"
            placeholder={t("__ZUsa_NavBar_OptionCart_Card_Number")}
            maxLength={creditCardNumberMaxLenght}
            value={
              creditCardData ? creditCardData.cardNumber : creditCardNumber
            }
            onChange={handleCardNumberChange}
          />
          <input
            id="card__date"
            type="text"
            inputMode="numeric"
            autoComplete="new-password"
            className="credit__card__input card__date"
            placeholder="MM / YY"
            maxLength={5}
            value={
              creditCardData
                ? creditCardData.cardExpirationDate
                : creditCardDate
            }
            onChange={handleCardDateChange}
            onKeyUp={(e) => {
              if (e.key === "Backspace") {
                setErrorCreditCardDate(true);
                if (setCreditCardData) {
                  setCreditCardData({
                    ...creditCardData,
                    cardExpirationDate: "",
                  });
                } else {
                  setCreditCardDate("");
                }
                document.getElementById("card__date").focus();
              }
            }}
          />
          <input
            id="card__cvc"
            type="text"
            inputMode="numeric"
            autoComplete="new-password"
            className="credit__card__input card__cvc"
            placeholder="CVC"
            maxLength={ccLengthCVC}
            onFocus={() => {
              setErrorType("cardcvc");
            }}
            onBlur={() => {
              setErrorType(undefined);
            }}
            onChange={handleCarCvcChange}
            value={creditCardData ? creditCardData.cardCvc : creditCardCvc}
          />
        </div>
      </div>

      <div
        id="credit__card__error__wrapper"
        className="credit__card__error__wrapper"
      ></div>
      {withButton && (
        <div
          id="credit__card__payment__button__wrapper"
          className="credit__card__payment__button__wrapper active"
        >
          <Button
            variant="contained"
            color="primary"
            disabled={
              errorCreditCardNumber || errorCreditCardCvc || errorCreditCardDate
              //false //desbloquea
              //true //bloquea
            }
            onClick={() => {
              //todochido

              if (!validateDataOrder()) {
                //dispatch(requestSetLoggUserActivity(26, 0, ``, `Click en pagar con tarjeta`)); // Registro logg
                document
                  .getElementById("credit__card__payment__button__wrapper")
                  .classList.remove("active");
                document
                  .getElementById("credit__card__loader__wrapper")
                  .classList.add("active");

                handleStripeCreditCardPayment(
                  creditCardNumber,
                  creditCardDate,
                  creditCardCvc
                );
              }
            }}
          >
            {t("__ZUsa_NavBar_OptionCart_Pay")}
            {formatPrice(orderData?.order?.importeii)}
          </Button>
        </div>
      )}

      <div
        id="credit__card__loader__wrapper"
        className="credit__card__loader__wrapper"
      >
        <img src={loaderBackgroundGray} alt="" />
        <span>{t("__ZUsa_Navbar_OptionCart_Paying_Msg")}</span>
      </div>
    </div>
  );
};

export default CreditCardForm;
