import React, { useState, useEffect } from "react";
import { Lock, Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";

const LockProduct = ({
  t,
  product,
  orderCurrent,
  actionButton,
}) => {  
  const [typeMessage,setTypeMessage] = useState(0);
  const [valueMessage,setValueMessage] = useState("");

  ////Reglas de Venta
  useEffect(() => {
    if (typeof product !== "undefined") {
      // //// prioridad 
      // //// 1) monto min
      // //// 2) sets maximo
      // if(product.minAmount > 0 ){
      //   if(!orderCurrent.lines || orderCurrent.lines.reduce((accumulator, object) => object.line.codart !== product.itemCode ? accumulator + object.line.montolinai : accumulator ,0) < product.minAmount ) {
      //     setTypeMessage(1);
      //     //
      //     setValueMessage(product.minAmount);
      //   } else if (product.maxSets > 0) {
      //     ////En multiple linea\\\\
      //     // const sum = orderCurrent.lines.reduce((accumulator, object) => {
      //     //   if (object.line.codart === product.itemCode){
      //     //     return accumulator + object.line.qtyped
      //     //   } else {
      //     //     return accumulator
      //     //   }
      //     // },0);
      //     // //debugger;
      //     // if(!orderCurrent.lines || sum >= product.maxSets ){
      //     //   typeMessage = 2
      //     // }

      //     ////En una linea\\\\
      //     //let sumv = orderCurrent.lines.reduce((accumulator, object) => object.line.codart === product.itemCode ? accumulator + object.line.qtyped : accumulator ,0)
      //     //debugger;
      //     if(!orderCurrent.lines || orderCurrent.lines.reduce((accumulator, object) => object.line.codart === product.itemCode ? accumulator + object.line.qtyped : accumulator ,0) >= product.maxSets ) {
      //       setTypeMessage(2);
      //       //
      //       setValueMessage(product.maxSets);
      //     }
      //   } else {
      //     setTypeMessage(0);
      //     //
      //     setValueMessage("");
      //   }
      // } else if(product.maxSets > 0) {
      
      if(product.maxSets > 0) {
        if(orderCurrent.lines && orderCurrent.lines.reduce((accumulator, object) => object.line.codart === product.itemCode ? accumulator + object.line.qtyped : accumulator ,0) >= product.maxSets ) {
          setTypeMessage(2);
          //
          setValueMessage(product.maxSets);
        } else {
          setTypeMessage(0);
          //
          setValueMessage("");
        }
      } else {
        setTypeMessage(0);
        //
        setValueMessage("");
      }
    }
  }, [
    orderCurrent,
    product,
  ]);


  return (
    <div className="lock__product__content">
      <div className="lock__product__content__wrapper">
        <div className="lock__product__content__wrapper__rect">
          <div className="lock__product__content__wrapper__circle">
            <Lock />
          </div>
          {/* <div className="lock__product__content__wrapper__rect__close">
            <IconButton onClick={() => actionButton() }>
              <Close />
            </IconButton>
          </div> */}
          <div className="lock__product__content__wrapper__rect__message">
            {
            typeMessage === 1 &&
              <span>{t("__message_products_minimum_amount_valid_label_p1")}              
              {valueMessage}              
              {t("__message_products_minimum_amount_valid_label_p2")}</span>
            }
            {
            typeMessage === 2 &&
              <span>{t("__message_products_maximum_quantity_valid_label_p1")}              
              {valueMessage}              
              {t("__message_products_maximum_quantity_valid_label_p2")}</span>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default LockProduct;
