import React from "react";
import { Button, IconButton } from "@mui/material";
import { isChrome, isMobile, isTablet } from "react-device-detect";

const TextFieldAndButtonNewMembers = ({
  labelName,
  placeholderInput,
  erroHelp,
  isActiveHelp,
  valueInput,
  typeInput,
  setValue,
  iconClass,
  handleEventButton,
  disabledButton,
  autoFocus,
  withRipple,
  isIconActive,
  labelButton,
  maxLength = 0,
  isRequire,
}) => {
  return (
    <div className="textfield__and__button__new__members inner">
      <label className={isRequire ? `text__required` : ""} htmlFor={labelName}>
        {labelName}
      </label>
      <div>
        <div className="textfield__button wrapper_input_erroHelp">
          <input
            autoComplete={isMobile && isChrome ? "off" : "new-password"}
            id={labelName}
            placeholder={placeholderInput !== "" ? placeholderInput : ""}
            type={typeInput}
            value={valueInput}
            aria-describedby="my-helper-text"
            onInput={setValue}
            autoFocus={autoFocus}
            maxLength={maxLength > 0 ? maxLength : 1000}
          />
          {isActiveHelp && (
            <span className="errorHelp" id="my-helper-text">
              {erroHelp}
            </span>
          )}
        </div>
        {isIconActive ? (
          <IconButton
            className={`${
              withRipple ? "with__ripple red__ripple" : ""
            } textfield__and__button__new__members iconButton ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
            onClick={handleEventButton}
            disabled={disabledButton}
          >
            <div className={`${iconClass}`} />
          </IconButton>
        ) : (
          <Button
            variant="contained"
            onClick={handleEventButton}
            disabled={disabledButton}
          >
            {labelButton}
          </Button>
        )}
      </div>
    </div>
  );
};

export default TextFieldAndButtonNewMembers;
