import GraphqlHandlerNoInterceptor from '../GraphqlHandler/noInterceptor'
import { ENVIROMENT_URL } from '../../React/ReduxSaga/Constants';

const route = ENVIROMENT_URL + 'apiV3/GeneralMicroservice/General'

class NabvarModule {
  static GetNavbarModule() {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      route,
      //'http://localhost:59622/api/ClientSession',
      `{
        getModules
        {
          code,
          message,
          navigationBar 
          {
            id,
            idModuleType,
            moduleName,
            items
            {
              id,
              idModule,
              name,
              nameRender,
              idParent,
              anchor,
              reference,
              orderRender
            }
          }
        }     
               
      }`,
      false
    );
    return result;
  }
}

export default NabvarModule