import {
  RESPONSE_REFERRER_EMAIL_VALIDATION,
  RESPONSE_REFERRER_CELLPHONE_VALIDATION,
  RESPONSE_REFERRER_NEW_REGISTER,
  RESPONSE_REFERRER_CODE,
  RESPONSE_REFERRER_CODE_SENDED_LOG,
  RESPONSE_REFERRER_LIST,
} from "../../Constants";

export default function referrer(state = {}, action) {
  switch (action.type) {
    case RESPONSE_REFERRER_EMAIL_VALIDATION: {
      const responseReferrerEmailValidation = action.payload.response;
      return { ...state, responseReferrerEmailValidation };
    }
    case RESPONSE_REFERRER_CELLPHONE_VALIDATION: {
      const responseReferrerCellPhoneValidation = action.payload.response;
      return { ...state, responseReferrerCellPhoneValidation };
    }
    case RESPONSE_REFERRER_NEW_REGISTER: {
      const responseNewRegister = action.payload.response;
      return { ...state, responseNewRegister };
    }
    case RESPONSE_REFERRER_CODE: {
      const responseGetReferrerCode = action.payload.response;
      return { ...state, responseGetReferrerCode };
    }
    case RESPONSE_REFERRER_CODE_SENDED_LOG: {
      const responseReferrerCodeLog = action.payload.response;
      return { ...state, responseReferrerCodeLog };
    }
    case RESPONSE_REFERRER_LIST: {
      const referrerListResponse = action.payload.response;
      return { ...state, referrerListResponse};
    }
    default:
      return { ...state };
  }
}
