import React, { Fragment, useEffect } from "react";
import { Drawer, Chip, Paper, Typography } from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";
import { useTranslation } from "react-i18next";
import Fedex from "../../../../assets/media/images/fedexLogo.png";
import moment from "moment";

const ModalHistoryTracking = ({
  isOpenSlideModal,
  setIsOpenSlideModal,
  trackingDetails,
  handleUpdateDeliveredStatus,
}) => {
  /// TRANSLATION
  const { t } = useTranslation();

  //Hooks

  const handleCloseSlideModal = () => {
    setIsOpenSlideModal(false);
  };

  useEffect(() => {
    if (
      trackingDetails.estimatedDeliveryDate &&
      trackingDetails.estimatedDeliveryDate === "DELIVERED"
    ) {
      handleUpdateDeliveredStatus();
    }
  }, []);

  return (
    <Fragment>
      <Drawer
        className="modal__slide__container"
        anchor="bottom"
        open={isOpenSlideModal}
      >
        <Paper elevation={3}>
          <div className="modal__slide__container__pill">
            <Chip clickable onClick={handleCloseSlideModal} />
          </div>

          <div className="modal__slide__container__wrapper">
            <div className="modal__history__traking__image">
              <img src={Fedex} alt="Logo Fedex" width="100" />
            </div>
            {trackingDetails.estimatedDeliveryDate &&
              trackingDetails.estimatedDeliveryDate === "DELIVERED" && (
                <div className="modal__history__traking__title text__black">
                  {" "}
                  {t(
                    "__profile_favorites_modal_history_tracking_pack_delivery"
                  )}
                </div>
              )}
            {trackingDetails.estimatedDeliveryDate &&
              trackingDetails.estimatedDeliveryDate !== "DELIVERED" && (
                <Fragment>
                  <div className="modal__history__traking__title text__black">
                    {t("__profile_favorites_modal_history_tracking")}
                  </div>
                  <div className="modal__history__traking__wrapper__left">
                    <br />
                    <Typography className="text__black">
                      {t("__profile_favorites_modal_history_tracking_delivery")}
                    </Typography>

                    <Typography className="text__black">
                      {moment(trackingDetails.estimatedDeliveryDate).format(
                        "dddd, MMMM Do YYYY"
                      )}
                    </Typography>
                  </div>
                  <div className="modal__history__traking__wrapper">
                    {trackingDetails.events.map((item, index) => {
                      return (
                        <Fragment key={index}>
                          <div className="modal__history__traking__wrapper__left"></div>
                          <div className="modal__history__traking__wrapper__right">
                            <Timeline align="alternate">
                              <Fragment>
                                <TimelineItem>
                                  <TimelineOppositeContent>
                                    <Typography className="text__black">
                                      {moment(item.date).format(
                                        "YYYY-MM-DD hh:mm a"
                                      )}
                                      <br />
                                      {item.event}
                                    </Typography>
                                  </TimelineOppositeContent>
                                  <TimelineSeparator>
                                    <TimelineDot />
                                    <TimelineConnector />
                                  </TimelineSeparator>
                                  <TimelineContent>
                                    <Typography className="text__black">
                                      {item.place}
                                    </Typography>
                                  </TimelineContent>
                                </TimelineItem>
                              </Fragment>
                            </Timeline>
                          </div>
                        </Fragment>
                      );
                    })}
                  </div>
                </Fragment>
              )}
          </div>
        </Paper>
      </Drawer>
    </Fragment>
  );
};

export default ModalHistoryTracking;
