import GraphqlHandlerNoInterceptor from "../GraphqlHandler/noInterceptor"
import { ENVIROMENT_URL } from '../../React/ReduxSaga/Constants';

const route = ENVIROMENT_URL + 'apiV3/ValidationMicroservice/Validation'

class Email {
  static EmailValidation(email, dbCheck) {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      route,
      //'http://localhost:59622/api/ClientSession',
      `(@autodeclare){
        validateEmail(email:$email, dbCheck:$dbCheck)
        {
          code,
          message
        }   
               
      }`,
      true,
      {
        email,
        dbCheck
      }
    );
    return result;
  }
}

export default Email