import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { isMobile, isTablet } from "react-device-detect";
//MUI
import { Button, Skeleton } from "@mui/material";
//Components
import Counter from "../../base/components/Counter";
//Actions
import {
  cleanCartGetGiftList,
  requestCartAddConfigurableGift,
  requestCartGetGiftList,
  cleanCartAddConfigurableGift,
  requestGetCartOrder,
} from "../../../ReduxSaga/Actions/Cart";
import { requestGetAdmissionKits } from "../../../ReduxSaga/Actions/Products";
import { requestLoggUserActivity } from "../../../ReduxSaga/Actions/General";
//Assets
import { ReactComponent as BagIcon } from "./../../../../assets/media/images/icons/bagIconWhiteAlt.svg";
import {
  capitalizeText,
  handleScrollToTop,
} from "../../../../Libraries/Utils/utils";

const Gifts = ({ countGiftSelect, isLanguageLoaded }) => {
  //Translations
  const { t, i18n } = useTranslation();

  //Notistack
  const { enqueueSnackbar } = useSnackbar();

  //React Router
  const history = useHistory();

  //React Redux
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.cart = useSelector((state) => state.cart);
  appReducers.general = useSelector((state) => state.general);
  appReducers.products = useSelector((state) => state.products);

  //State
  const [giftsSelectableCount, setGiftsSelectableCount] = useState(1);
  const [newListSelect, setNewListSelect] = useState([]);
  const [countSelectTotal, setCountSelectTotal] = useState(0);
  const [countUnSelectTotal, setCountUnSelectTotal] = useState(0);
  const [currentListSelect, setCurrentListSelect] = useState([]);
  const [isOpenSummary, setIsOpenSummary] = useState(false);
  const [summaryListSelect, setSummaryListSelect] = useState([]);
  const [isGuest, setIsGuest] = useState(true);
  const [benefitType, setBenefitType] = useState(5);
  const [infoCartOrder, setInfoCartOrder] = useState({});
  const [admissionKits, setAdmissionKits] = useState([]);
  const [cpMemberships, setCpMemberships] = useState([]);
  const [selectedsCount, setSelectedsCount] = useState(0);

  //Effects
  useEffect(() => {
    //console.log("newListSelect", newListSelect);
    let selecteds = [];
    newListSelect.forEach((item) => {
      item.forEach((item2) => {
        if (item2.selected) {
          selecteds.push(item2);
        }
      });
    });
    //console.log("selecteds", selecteds);
    setSelectedsCount(selecteds.length);
  }, [newListSelect]);

  //Lista de regalos
  useEffect(() => {
    dispatch(requestCartGetGiftList());
  }, []);
  //primera carga
  useEffect(() => {
    if (typeof appReducers?.general?.errorLoadFirstCharge !== "undefined") {
      if (
        !appReducers.general.errorLoadFirstCharge &&
        typeof appReducers?.general?.firstChargeModel !== "undefined"
      ) {
        const { isGuestUser } = appReducers.general.firstChargeModel;
        setIsGuest(isGuestUser);
      }
    }
  }, [
    appReducers?.general?.errorLoadFirstCharge,
    appReducers?.general?.firstChargeModel,
  ]);
  //ADMISSION KITS
  useEffect(() => {
    if (appReducers?.products?.admissionKitList) {
      const kits = appReducers.products.admissionKitList;
      setAdmissionKits(
        kits.admissionKitList.filter(
          (f) =>
            f.typePlan === 1 && f.idLang == (i18n.language === "es" ? 1 : 2)
        )
      );
      setCpMemberships(
        kits.admissionKitList.filter(
          (f) =>
            f.typePlan === 2 && f.idLang == (i18n.language === "es" ? 1 : 2)
        )
      );
    } else {
      dispatch(requestGetAdmissionKits());
    }
  }, [appReducers?.products?.admissionKitList, i18n.language]);
  //orden
  useEffect(() => {
    if (typeof appReducers?.cart?.errorCartOrder !== "undefined") {
      if (
        appReducers?.cart?.cartOrder &&
        !appReducers?.cart?.errorCartOrdererrorCartOrder
      ) {
        const order = appReducers.cart.cartOrder;
        setInfoCartOrder(order);
      }
    }
  }, [appReducers?.cart?.cartOrder, appReducers?.cart?.errorCartOrder]);
  //Benefit type
  useEffect(() => {
    if (infoCartOrder?.hasOwnProperty("code") && infoCartOrder?.code === 100) {
      if (infoCartOrder?.order && infoCartOrder?.order !== null) {
        if (infoCartOrder?.lines.length > 0 && admissionKits.length > 0) {
          if (
            infoCartOrder?.lines.find((l) =>
              admissionKits.find((kits) => kits.itemCode === l.line.codart)
            )
          )
            setBenefitType(1);
          else setBenefitType(5);
        } else if (
          infoCartOrder?.lines.length > 0 &&
          admissionKits.length > 0
        ) {
          if (
            infoCartOrder?.lines.find((l) =>
              cpMemberships.find((kits) => kits.itemCode === l.line.codart)
            )
          )
            setBenefitType(2);
          else setBenefitType(5);
        }
      }
    }
  }, [infoCartOrder, admissionKits, cpMemberships]);
  //lista de regalos
  useEffect(() => {
    if (
      appReducers?.cart?.cartGetGiftList &&
      !appReducers?.cart?.errorCartGetGiftList
    ) {
      //debugger;
      let giftList = appReducers?.cart?.cartGetGiftList;
      if (giftList.code === 100) {
        if (giftList.giftSelectable.length > 0) {
          let { giftSelectable } = giftList;

          let arrayOnlyKey = [];
          giftSelectable.map((item) => {
            item.selected = false;

            let key = {};

            key.idRegalo = item.idRegalo;
            key.idx = item.idx;

            arrayOnlyKey.push(key);
          });
          //eliminar duplicados
          let arrayOnlyKeyUnique = arrayOnlyKey.reduce((acc, current) => {
            const x = acc.find(
              (item) =>
                item.idx === current.idx && item.idRegalo === current.idRegalo
            );
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);
          //agruparlos en un estado por el tipo
          let arrayFinal = [];
          arrayOnlyKeyUnique.map((key) => {
            let arrayKeyEquals = [];
            arrayKeyEquals = giftSelectable.filter(
              (item) => item.idx === key.idx && item.idRegalo === key.idRegalo
            );
            arrayFinal.push(arrayKeyEquals);
          });
          //debugger;
          //ordernar Array
          arrayFinal
            .map((list) => list.filter((item) => item.selected === true).length)
            .reduce((a, b) => a + b, 0);
          //debugger;
          //agregar el idice al array
          let newArr = [...arrayFinal];
          newArr.map((elemt, idx) => {
            elemt.map((item) => {
              item.idxNewListSelect = idx;
            });
          });
          //debugger;
          //agregar el primer array del que no se haya escojido nada
          let isSelected = newArr.map((elemt) =>
            elemt.some((x) => x.selected === true)
          );
          let idxSelected = isSelected.findIndex((x) => x === false);
          //debugger;
          setNewListSelect(newArr);
          //debugger;
          setCurrentListSelect(newArr[idxSelected]);
        } else {
          setNewListSelect([]);
          setCurrentListSelect({});
        }
        dispatch(cleanCartGetGiftList());
        //dispatch(requestGetCartOrder());
      }
    }
  }, [appReducers?.cart.cartGetGiftList]);
  useEffect(() => {
    if (
      appReducers?.cart?.cartAddConfigurableGift &&
      !appReducers?.cart?.errorCartAddConfigurableGift
    ) {
      // debugger;
      let addConfigGift = appReducers?.cart?.cartAddConfigurableGift;
      if (addConfigGift.code === 100) {
        //accion que limpie el reducer
        dispatch(cleanCartAddConfigurableGift());
        dispatch(requestGetCartOrder());
        if (!isGuest && benefitType > 1) {
          history.replace("/checkout");
        } else {
          history.replace("/precheckout");
        }

        enqueueSnackbar(
          t("__gifts__message__gift__configured__success_label"),
          {
            variant: "success",
          }
        );
      }
    }
  }, [appReducers?.cart.cartAddConfigurableGift]);

  //Handlers
  const handlePostiveChange = (idx, itemCode) => {
    let newArr = [...newListSelect]; // copying the old datas array

    let newArrChilds = newArr[idx];

    newArrChilds.map((item) => {
      if (item.itemCodeGift === itemCode) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    });
    setNewListSelect(newArr);

    //click en el (+)
  };
  const handleNegativeChange = (idx, itemCode) => {
    let newArr = [...newListSelect]; // copying the old datas array

    let newArrChilds = newArr[idx];

    newArrChilds.map((item) => {
      if (item.itemCodeGift === itemCode) {
        if (item.selected === true) {
          item.selected = false;
        }
      } else {
        item.selected = false;
      }
    });
    setNewListSelect(newArr);

    //click en el (-)
  };
  const handleClickAddGift = () => {
    //agregar el idice al array
    let newArr = [...newListSelect];

    //agregar el primer array del que no se haya escojido nada
    let isSelected = newArr.map((elemt) =>
      elemt.some((x) => x.selected === true)
    );
    let idxSelected = isSelected.findIndex((x) => x === false);
    //debugger;
    if (idxSelected >= 0) {
      setSummaryListSelect([]);
      setCurrentListSelect(newArr[idxSelected]);
      setIsOpenSummary(false);
    } else {
      let arrayFinalGift = [];
      newArr.map((listGift, idx) => {
        let itemSelect = listGift.find((gift) => gift.selected);
        arrayFinalGift.push(itemSelect);

        dispatch(
          requestLoggUserActivity(
            84,
            0,
            0,
            itemSelect.itemCodeGift,
            "Se Seleccino el Articulo para el Listado de regalos seleccinados. Posicion Array: " +
              idx
          )
        );
      });
      setSummaryListSelect(arrayFinalGift);
      setCurrentListSelect([]);
      setIsOpenSummary(true);
    }
    //se agrego regalo correctamente
    enqueueSnackbar(t("__gifts__message__add__gift__success_label"), {
      variant: "success",
    });

    setTimeout(() => {
      handleScrollToTop();
    }, 100);
  };
  const handleSendGiftSelected = (parameters) => {
    let arrayFinalGift = [];
    parameters.map((listGift) => {
      let newObjGift = {};

      let itemSelect = listGift.find((gift) => gift.selected);

      newObjGift.idx = itemSelect.idx;
      newObjGift.idRegalo = itemSelect.idRegalo;
      newObjGift.itemCode = itemSelect.itemCodeGift;

      arrayFinalGift.push(newObjGift);
    });
    let resp = {
      cartGiftDetailsParameters: arrayFinalGift,
    };
    dispatch(requestCartAddConfigurableGift(resp));

    dispatch(
      requestLoggUserActivity(
        85,
        0,
        0,
        "",
        "Se Guarda la seleccion de regalos en el servidor"
      )
    );
  };

  return (
    <div
      id="firstWrapperInBaseContainer"
      className="firstWrapperInBaseContainer"
    >
      <div className="gifts__wrapper">
        <div className="gifts__wrapper__inner__wrapper">
          <div className="gifts__wrapper__inner__wrapper__gifts__counter">
            {isLanguageLoaded ? (
              <span>
                {`${t("__gifts__you__have__selected")} ${selectedsCount} ${t(
                  "__gifts__of"
                )} ${newListSelect.length} ${t("__general__gift")}${
                  newListSelect.length > 1 ? "s" : ""
                }`}
              </span>
            ) : (
              <Skeleton variant="rect" width={150} height={20} />
            )}

            {/* <span className="gifts__wrapper__inner__wrapper__gifts__counter__selecteds">{`${selectedsCount}`}</span>
            <span className="gifts__wrapper__inner__wrapper__gifts__counter__total">{`/ ${newListSelect.length}`}</span> */}
          </div>

          <div className="gifts__wrapper__inner__wrapper__title__wrapper">
            <span className="gifts__wrapper__inner__wrapper__title__wrapper__title">
              {isLanguageLoaded ? (
                t("__general__congratulations")
              ) : (
                <Skeleton variant="rect" width={150} height={18} />
              )}
            </span>
            {!isOpenSummary && currentListSelect.length > 0 ? (
              isLanguageLoaded ? (
                <span className="gifts__wrapper__inner__wrapper__title__wrapper__subtitle">
                  {/* TIENES 1 REGALO POR COMPRAR */}

                  {`
              ${t("__gifts__subtitle__part1")} ${giftsSelectableCount} 
              ${t("__general__gift")}${giftsSelectableCount > 1 ? "s" : ""} 
              ${
                currentListSelect[0].motiveGift === 1
                  ? t("__gifts__subtitle__part3")
                  : t("__gifts__subtitle__part2")
              }:
              `}
                </span>
              ) : (
                <Skeleton variant="rect" width={"50%"} height={18} />
              )
            ) : isLanguageLoaded ? (
              <span className="gifts__wrapper__inner__wrapper__title__wrapper__subtitle">
                {/* TIENES 1 REGALO POR MONTO */}

                {`
              ${t("__gifts__subtitle__part1")} ${summaryListSelect.length} 
              ${t("__general__gift")}${summaryListSelect.length > 1 ? "s" : ""} 
              ${t("__gifts__subtitle__part4")}:
              `}
              </span>
            ) : (
              <Skeleton variant="rect" width={150} height={18} />
            )}
          </div>

          {!isOpenSummary &&
            currentListSelect &&
            currentListSelect.length > 0 && (
              <div className="gifts__wrapper__inner__wrapper__product__or__amount__wrapper">
                {currentListSelect[0].motiveGift === 2 ? (
                  <div className="gifts__wrapper__inner__wrapper__product__or__amount__wrapper__product__item">
                    <div className="gifts__wrapper__inner__wrapper__product__or__amount__wrapper__product__item__image__wrapper">
                      <img
                        src={`https://www.websizusa.net/imgprod/fullsize/${currentListSelect[0].itemCode}.jpg`}
                        alt=""
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src =
                            "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
                        }}
                      />
                    </div>

                    <div className="gifts__wrapper__inner__wrapper__product__or__amount__wrapper__product__item__name">
                      <span>
                        {i18n.language === "es"
                          ? currentListSelect[0].nameEs
                          : currentListSelect[0].nameEn}
                      </span>
                    </div>
                    {/* 
                <div className="gifts__wrapper__inner__wrapper__product__or__amount__wrapper__product__item__qty">
                  <span>1 pz</span>
                </div>  
                */}
                  </div>
                ) : (
                  <div className="gifts__wrapper__inner__wrapper__product__or__amount__wrapper__amount__item">
                    {isLanguageLoaded ? (
                      <span>
                        {`$${currentListSelect[0].motiveGiftDesc} ${t(
                          "__gifts__amount__item__text"
                        )}`}
                      </span>
                    ) : (
                      <Skeleton variant="rect" width={"50%"} height={18} />
                    )}
                  </div>
                )}
              </div>
            )}

          <div className="gifts__wrapper__inner__wrapper__content__wrapper">
            {!isOpenSummary &&
            currentListSelect &&
            currentListSelect.length > 0 ? (
              <div className="gifts__wrapper__inner__wrapper__content__wrapper__gift__list__wrapper">
                <div className="gifts__wrapper__inner__wrapper__content__wrapper__gift__list__wrapper__title">
                  {isLanguageLoaded ? (
                    <span>
                      {/* Selecciona 1 producto del siguiente listado: */}
                      {`${t(
                        "__gifts__list__title__part1"
                      )} ${giftsSelectableCount} ${t("__general__product")}${
                        giftsSelectableCount > 1 ? "s" : ""
                      } ${t("__gifts__list__title__part2")}:`}
                    </span>
                  ) : (
                    <Skeleton variant="rect" width={"50%"} height={18} />
                  )}
                </div>

                {/*
                newListSelect.map((groupProduct, index) => {
                  return (
                    <div
                      // key={index}
                      // className="gifts__wrapper__inner__wrapper__content__wrapper__gift__list__wrapper__item"
                    >
                      {groupProduct.map((item) => {

                        return (
                          <div
                            key={index}
                            className="gifts__wrapper__inner__wrapper__content__wrapper__gift__list__wrapper__item"
                          >
                            <div className="gifts__wrapper__inner__wrapper__content__wrapper__gift__list__wrapper__item__image__wrapper">
                              <img
                                src={item.image}
                                alt="gift"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src =
                                    "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
                                }}
                              />
                            </div>
                            <div className="gifts__wrapper__inner__wrapper__content__wrapper__gift__list__wrapper__item__name__and__price">
                              <span>{i18n.language === "es"
                                ? item.nameGiftEs
                                : item.nameGiftEn}</span>
                              <span>$7.00</span>
                            </div>
                            <div className="gifts__wrapper__inner__wrapper__content__wrapper__gift__list__wrapper__item__counter">
                              <Counter withIcon={false} />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )
                })
                */}

                {currentListSelect.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="gifts__wrapper__inner__wrapper__content__wrapper__gift__list__wrapper__item"
                    >
                      <div className="gifts__wrapper__inner__wrapper__content__wrapper__gift__list__wrapper__item__image__wrapper">
                        <img
                          src={item.image}
                          alt="gift"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src =
                              "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
                          }}
                        />
                      </div>

                      <div className="gifts__wrapper__inner__wrapper__content__wrapper__gift__list__wrapper__item__name__and__price">
                        <span>
                          {i18n.language === "es"
                            ? capitalizeText(item.nameGiftEs)
                            : capitalizeText(item.nameGiftEn)}
                        </span>
                      </div>

                      <div className="gifts__wrapper__inner__wrapper__content__wrapper__gift__list__wrapper__item__counter">
                        <Counter
                          withIcon={false}
                          qtyOnOrder={item.selected === true ? 1 : 0}
                          handleQtyMinus={() =>
                            handleNegativeChange(
                              item.idxNewListSelect,
                              item.itemCodeGift
                            )
                          }
                          handleQtyAdd={() =>
                            handlePostiveChange(
                              item.idxNewListSelect,
                              item.itemCodeGift
                            )
                          }
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : isOpenSummary && currentListSelect.length === 0 ? (
              summaryListSelect.map((item, index) => {
                //debugger;
                return (
                  <div
                    key={index}
                    className="gifts__wrapper__inner__wrapper__content__wrapper__gift__list__single__items__wrapper"
                  >
                    <div className="gifts__wrapper__inner__wrapper__content__wrapper__gift__list__single__items__wrapper__single__item">
                      <div className="gifts__wrapper__inner__wrapper__content__wrapper__gift__list__single__items__wrapper__single__item__image__wrapper">
                        <img
                          src={item.image}
                          alt="gift"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src =
                              "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
                          }}
                        />
                      </div>

                      <div className="gifts__wrapper__inner__wrapper__content__wrapper__gift__list__single__items__wrapper__single__item__name__and__price">
                        <span>
                          {i18n.language === "es"
                            ? capitalizeText(item.nameGiftEs)
                            : capitalizeText(item.nameGiftEn)}
                        </span>
                      </div>

                      <div className="gifts__wrapper__inner__wrapper__content__wrapper__gift__list__single__items__wrapper__single__item__amount">
                        <span>1 pz</span>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <span>
                {isLanguageLoaded ? (
                  t("__general__wait__while__info__loads")
                ) : (
                  <Skeleton variant="rect" width={"50%"} height={18} />
                )}
              </span>
            )}

            {/* { 
            (isOpenSummary && currentListSelect && currentListSelect.length === 0) ? (
              
              summaryListSelect.map((item, index) => {

                <div key={index} className="gifts__wrapper__inner__wrapper__content__wrapper__gift__list__single__items__wrapper">
                  <div className="gifts__wrapper__inner__wrapper__content__wrapper__gift__list__single__items__wrapper__single__item">
                    <div className="gifts__wrapper__inner__wrapper__content__wrapper__gift__list__single__items__wrapper__single__item__image__wrapper">
                      <img
                        src={item.image}
                        alt="gift"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src =
                          "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
                        }}
                      />
                    </div>
                    
                    <div className="gifts__wrapper__inner__wrapper__content__wrapper__gift__list__single__items__wrapper__single__item__name__and__price">
                      <span>
                        {
                        i18n.language === "es"
                          ? item.nameGiftEs
                          : item.nameGiftEn
                        }
                      </span>
                    </div>
                  
                    <div className="gifts__wrapper__inner__wrapper__content__wrapper__gift__list__single__items__wrapper__single__item__amount">
                      <span>1 pz</span>
                    </div>
                  </div>
                </div>

              })          
            ) : (
              <span>Espera en lo que carga la informacion Detail</span>            
            )
            }  */}
          </div>

          <div className="gifts__wrapper__footer">
            {(currentListSelect.length > 0 || summaryListSelect.length > 0) && (
              <Button
                className="button__with__icon bg__dark__pink no__left__margin"
                disabled={
                  !isOpenSummary &&
                  !currentListSelect.some((x) => x.selected === true)
                }
                onClick={() => {
                  if (!isOpenSummary) {
                    handleClickAddGift();
                  } else {
                    handleSendGiftSelected(newListSelect);
                  }
                }}
              >
                <div className="button__with__icon__svg__wrapper dark__pink__ball">
                  <BagIcon />
                </div>
                <span className="button__with__icon__text">
                  {!isOpenSummary
                    ? isLanguageLoaded
                      ? t("__gifts__footer__button__text")
                      : ""
                    : isLanguageLoaded
                    ? t("__gifts__footer__complete__button__text")
                    : ""}
                </span>
              </Button>
            )}
          </div>
        </div>

        {/* <div className="gifts__wrapper__footer">
          {(currentListSelect.length > 0 || summaryListSelect.length > 0) && (
            <Button
              className="button__with__icon bg__dark__pink no__left__margin"
              disabled={
                !isOpenSummary &&
                !currentListSelect.some((x) => x.selected === true)
              }
              onClick={() => {
                if (!isOpenSummary) {
                  handleClickAddGift();
                } else {
                  handleSendGiftSelected(newListSelect);
                }
              }}
            >
              <div className="button__with__icon__svg__wrapper dark__pink__ball">
                <BagIcon />
              </div>
              <span className="button__with__icon__text">
                {!isOpenSummary
                  ? t("__gifts__footer__button__text")
                  : t("__gifts__footer__complete__button__text")}
              </span>
            </Button>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default Gifts;
