import React, { useEffect, useState } from "react";
import { Button, Typography, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { isMobile, isTablet } from "react-device-detect";
import ZcareTimeline from "./components/ZcareTimeline";
import ZcareProductSlideModal from "./components/ZcareProductSlideModal";
import {
  requestEntrepenourHomeModuleInfo,
  requestHomeModuleInfo,
} from "./../../ReduxSaga/Actions/Home";
import { requestGetZcareProduct } from "../../ReduxSaga/Actions/Products";
import { useDispatch, useSelector } from "react-redux";

const ZcareContainer = ({ isLanguageLoaded }) => {
  // useState
  const [isOpenModalCampaign, setIsOpenModalCampaign] = useState(false);
  const [zCareProducts, setZCareProducts] = useState([]);
  const [prodZcare, setProdZcare] = useState();
  const [resourcesZCModule, setResourcesZCModule] = useState([]);

  /// TRANSLATION
  const { t, i18n } = useTranslation();
  /// REDUX
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.products = useSelector((state) => state.products);
  appReducers.home = useSelector((state) => state.home);
  appReducers.general = useSelector((state) => state.general);

  //USEEFFECTS

  useEffect(() => {
    document.title = "Zermat USA :: ZCares";
  }, []);

  useEffect(() => {
    if (appReducers?.products.zCareProduct) {
      const zcarep = appReducers.products.zCareProduct;
      if (zcarep.code === 100) {
        //debugger
        setZCareProducts(zcarep.zCProducts);
        setProdZcare(
          zcarep.zCProducts.find(
            (w) => w.idLang === (i18n.language === "es" ? 1 : 2)
          )
        );
      }
    } else {
      dispatch(requestGetZcareProduct());
    }
  }, [appReducers?.products.zCareProduct]);

  useEffect(() => {
    if (appReducers?.home?.zCareModuleEs && appReducers?.home?.zCareModuleEn) {
      if (i18n.language === "es") {
        //espanol
        setResourcesZCModule(appReducers.home.zCareModuleEs);
      } else {
        //ingles
        setResourcesZCModule(appReducers.home.zCareModuleEn);
      }
    } else {
      if (typeof appReducers?.general?.errorLoadFirstCharge !== "undefined") {
        if (!appReducers.general.errorLoadFirstCharge) {
          const { idPage } = appReducers.general.firstChargeModel;
          if (idPage > 0) {
            dispatch(requestEntrepenourHomeModuleInfo());
          } else {
            dispatch(requestHomeModuleInfo());
          }
        }
      }
    }

    setProdZcare(
      zCareProducts.find((w) => w.idLang === (i18n.language === "es" ? 1 : 2))
    );
  }, [
    appReducers?.home?.zCareModuleEs,
    appReducers?.home?.zCareModuleEn,
    i18n.language,
  ]);

  //FUNCIONES

  const handleShoppingCampaign = () => {
    setIsOpenModalCampaign(true);
  };

  return (
    <div
      id="firstWrapperInBaseContainer"
      className="firstWrapperInBaseContainer"
    >
      {/* <BreadcrumbsNavigationComponet /> */}
      <div className="zcare__container">
        <div
          className={`zcare__container__logo ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          {/* <div className="zcare__container__logo__image" /> */}
          {resourcesZCModule &&
            resourcesZCModule.length > 0 &&
            resourcesZCModule.map((item) => {
              if (item.idMod === 10 && item.idModContent === 42) {
                if (!isMobile) {
                  {
                    return item.content.map((res, idx) => {
                      if (res.idModuleContentResourcesDetailType === 3) {
                        return <img key={idx} alt={res.name} src={res.image} />;
                      }
                    });
                  }
                } else {
                  return item.content.map((res, idx) => {
                    if (res.idModuleContentResourcesDetailType === 1) {
                      return <img key={idx} alt={res.name} src={res.image} />;
                    }
                  });
                }
              }
            })}
        </div>
        {/* <div className="zcare__container__description">
          <Typography variant="body1" gutterBottom>
            {isLanguageLoaded ? (
              t("__zcare_name_organization_description_one")
            ) : (
              <Skeleton variant="rect" width={"30%"} height={25} />
            )}

            <br />
          </Typography>
        </div> */}
        <div className="zcare__container__description">
          <Typography variant="body1" gutterBottom>
            {isLanguageLoaded ? (
              t("__zcare_name_organization_description_two")
            ) : (
              <Skeleton variant="rect" width={"90%"} height={25} />
            )}

            <br />
          </Typography>
        </div>
        <div className="zcare__container__description">
          <Typography variant="body1" gutterBottom>
            {isLanguageLoaded ? (
              t("__zcare_name_organization_description_three")
            ) : (
              <Skeleton variant="rect" width={"90%"} height={25} />
            )}

            <br />
          </Typography>
        </div>
        <div className="zcare__container__description">
          <Typography variant="body1" gutterBottom>
            {isLanguageLoaded ? (
              t("__zcare_name_organization_description_four")
            ) : (
              <Skeleton variant="rect" width={"90%"} height={25} />
            )}

            <br />
          </Typography>
        </div>

        <ZcareTimeline isLanguageLoaded={isLanguageLoaded} />

        {prodZcare && (
          <div className="zcare__container__campaign__active">
            <Button
              onClick={handleShoppingCampaign}
              className="button__with__icon bg__dark__pink no__left__margin"
            >
              <div className="button__with__icon__svg__wrapper dark__pink__ball">
                <div className="bandaidOutlineIcon" />
              </div>
              <span>{isLanguageLoaded ? t("__zcare_btn_help_us") : ""}</span>
            </Button>
            {/* <Button onClick={handleShoppingCampaign} variant="contained">
              {t("__zcare_btn_help_us")}
            </Button> */}
          </div>
        )}
        <ZcareProductSlideModal
          isOpenModalCampaign={isOpenModalCampaign}
          setIsOpenModalCampaign={setIsOpenModalCampaign}
          prodZcare={prodZcare}
        />
      </div>
    </div>
  );
};

export default ZcareContainer;
