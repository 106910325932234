import { call, put, takeLatest } from 'redux-saga/effects'
import {
    REQUEST_INSTAGRAM_POSTS,
    RESPONSE_INSTAGRAM_POSTS,
    DATA_FETCH_FAILED
} from '../../Constants'

import Facebook from '../../../../Libraries/Facebook'

function* requestInstagramPosts(){
    try {
        const result = yield call(Facebook.GetInstagramPosts);
        yield put({type: RESPONSE_INSTAGRAM_POSTS, payload: {response:result}});

    } catch (error) {
        console.error("load first Charge site error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}

export function* watchRequestInstagramPosts() {
    yield takeLatest(REQUEST_INSTAGRAM_POSTS, requestInstagramPosts);
}