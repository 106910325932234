import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import { useSnackbar } from "notistack";
//MUI
import {
  IconButton,
  ListItem,
  Button,
  ClickAwayListener,
  InputAdornment,
  Skeleton,
} from "@mui/material";
import {
  ArrowDropDown,
  Clear,
  Close,
  CloseTwoTone,
  Done,
  PhonelinkRingOutlined,
} from "@mui/icons-material";
//Utils
import {
  restrictToOnlyNumbers,
  truncateText,
  restrictToOnlyLetters,
  convertNameToInitials,
} from "../../../../Libraries/Utils/utils";
//Actions
import {
  requestClientLogOut,
  requestRegisterWebPageContact,
  cleanReducerWebPageContactReducer,
} from "./../../../ReduxSaga/Actions/Client";
import { requestLoggUserActivity } from "./../../../ReduxSaga/Actions/General";
import {
  requestTwilioCellPhoneValidation,
  cleanTwilioCellValidationReducer,
  requestTwilioCellPhoneCodeValidation,
  cleanTwilioCellCodeValidationReducer,
} from "./../../../ReduxSaga/Actions/Twilio";
//Assets
import AvatarPlaceholder from "./../../../../assets/media/images/avatarPlaceholder.jpg";
import WebpageAvatar from "./../../../../assets/media/images/webpageAvatar.png";
import { ReactComponent as ZermatLogoBlack } from "./../../../../assets/media/images/zermatLogoBlack.svg";
import { ReactComponent as LoginUserIcon } from "./../../../../assets/media/images/icons/userLoginIconAlt.svg";
//Assets Social Media
import { ReactComponent as Facebook } from "./../../../../assets/media/images/icons/facebookIcon.svg";
import { ReactComponent as Instagram } from "./../../../../assets/media/images/icons/instagramIcon.svg";
import { ReactComponent as Youtube } from "./../../../../assets/media/images/icons/youtubeIcon.svg";
import { ReactComponent as Pinterest } from "./../../../../assets/media/images/icons/pinterestIcon.svg";
import { ReactComponent as Whatsapp } from "./../../../../assets/media/images/icons/whatsappIcon.svg";
import { ReactComponent as BagIcon } from "./../../../../assets/media/images/icons/bagIcon.svg";
import { ReactComponent as BagActiveIcon } from "./../../../../assets/media/images/icons/bagActiveIcon.svg";
import { ReactComponent as HeartBlackOutlineIcon } from "./../../../../assets/media/images/icons/heartBlackOutlineIcon.svg";
import { ReactComponent as SearchIcon } from "./../../../../assets/media/images/icons/searchIcon.svg";
import { ReactComponent as SearchWhiteIcon } from "./../../../../assets/media/images/icons/searchIconAlt.svg";
import { ReactComponent as PrevArrowLanguageSwitcher } from "./../../../../assets/media/images/icons/prevArrowLanguageSwitcher.svg";
import { ReactComponent as NextArrowLanguageSwitcher } from "./../../../../assets/media/images/icons/nextArrowLanguageSwitcher.svg";
import { ReactComponent as GoBackArrow } from "./../../../../assets/media/images/icons/goBackArrow.svg";
import { ReactComponent as HamburguerMenuIcon } from "./../../../../assets/media/images/icons/hamburguerMenuIcon.svg";
import { ReactComponent as EnvelopeIcon } from "./../../../../assets/media/images/icons/envelopeIcon.svg";
import { ReactComponent as EnvelopeGrayIcon } from "./../../../../assets/media/images/icons/envelopeGrayIcon.svg";
import { resolve } from "@sentry/utils";
import { width } from "@mui/system";

//Constants and variables for timers
const minutesToWait = 1;
const secondsToWait = minutesToWait * 60;
//Timer for cellPhone
let intervalCellphone;
let cellphoneTimerSecondsCount = secondsToWait;

const Navbar = ({
  menuApp,
  infoCartOrder,
  handleGAEvent,
  setIsOpenFloatingCheckout,
  setIsOpenFloatingFavorites,
  isWebpage,
  handleResetInfoClient,
  setFloatingCheckoutIconIsClicked,
  isOpenEntrepreneurContactForm,
  setIsOpenEntrepreneurContactForm,
  isActiveSearch,
  setIsActiveSearch,
  isLanguageLoaded,
  isProfileImageLoaded,
  imageProfileUrl,
}) => {
  //Notistack
  const { enqueueSnackbar } = useSnackbar();

  //React Router
  const history = useHistory();
  const location = useLocation();
  //Translation
  const { t, i18n } = useTranslation();

  //Redux
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.products = useSelector((state) => state.products);
  appReducers.cart = useSelector((state) => state.cart);
  appReducers.home = useSelector((state) => state.home);
  appReducers.client = useSelector((state) => state.client);
  appReducers.twilio = useSelector((state) => state.twilio);
  appReducers.general = useSelector((state) => state.general);

  //State
  const [searchValue, setSearchValue] = useState("");
  // const [isActiveSearch, setIsActiveSearch] = useState(false);
  const [isSearchbarExpanded, setIsSearchbarExpanded] = useState(false);
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  const [categories, setCategories] = useState([]);
  const [isUserLogin, setIsUserLogin] = useState(false);
  const [productsInCart, setProductsInCart] = useState(0);
  const [userLogedInfo, setUserLogedInfo] = useState({});
  const [userId, setUserId] = useState(0);
  // const [isOpenEntrepreneurContactForm, setIsOpenEntrepreneurContactForm] =
  //   useState(false);

  //const [isWebpage, setIsWebpage] = useState(true);

  const [webpageInfo, setWebpageInfo] = useState({});
  const [isOpenProfileMenu, setIsOpenProfileMenu] = useState(false);
  const [products, setProducts] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const [filterProducts, setFilterProducts] = useState([]);
  const [name, setName] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [isValidCellPhone, setIsValidCellPhone] = useState(undefined);
  const [cellPhoneCode, setCellPhoneCode] = useState("");
  const [isValidCellPhoneCode, setIsValidCellPhoneCode] = useState(undefined);
  const [comment, setComment] = useState("");
  const [isContactRegistered, setIsContactRegistered] = useState(false);
  const [menuAdministration, setMenuAdministration] = useState([]);
  const [cellphoneTimerSeconds, setCellphoneTimerSeconds] = useState(
    cellphoneTimerSecondsCount
  );
  const [showProfileImage, setShowProfileImage] = useState(false);

  const [paddingLeftForSectionsSwiper, setPaddingLeftForSectionsSwiper] =
    useState(0);
  const [paddingLeftForCategoriesSwiper, setPaddingLeftForCategoriesSwiper] =
    useState(0);

  // useEffect(() => {
  //   console.log("products", products);
  // }, [products]);

  useEffect(() => {
    //console.log("CAMBIO EL IDIOMA", i18n.resolvedLanguage);
    handleCalculateSectionsSizes();
  }, [i18n.resolvedLanguage]);

  useEffect(() => {
    if (menuApp?.length > 0 && isDesktop) {
      //console.log("menuApp changes")
      handleCalculateSectionsSizes();
    }
  }, [menuApp]);

  useEffect(() => {
    if (categories?.length > 0 && isDesktop) {
      //console.log("categories changes")
      handleCalculateSectionsSizes();
    }
  }, [categories]);

  //Effects
  useEffect(() => {
    // console.log("isMobile", isMobile);
    // console.log("isTablet", isTablet);
  }, []);
  useEffect(() => {
    if (!appReducers?.products?.errorLoadAllCategories) {
      if (i18n.language === "en")
        setCategories(appReducers.products.categoriesEn);
      if (i18n.language === "es")
        setCategories(appReducers.products.categoriesEs);
    } else {
      //manejar pantalla de error
    }
  }, [
    appReducers?.products?.errorLoadAllCategories,
    i18n.language,
    appReducers.products.categoriesEs,
    appReducers.products.categoriesEn,
  ]);
  useEffect(() => {
    if (appReducers?.products?.errorLoadAllProducts !== undefined) {
      if (!appReducers.products.errorLoadAllProducts) {
        //debugger;
        if (i18n.language === "en") {
          setProducts(appReducers.products.productsEn);
          setPromotions(appReducers.products.promotionsEn);
        }

        if (i18n.language === "es") {
          setProducts(appReducers.products.productsEs);
          setPromotions(appReducers.products.promotionsEs);
        }
      }
    } else {
      //manejar pantalla de error
    }
  }, [
    appReducers?.products.errorLoadAllProducts,
    i18n.language,
    appReducers.products.productsEs,
    appReducers.products.productsEn,
    appReducers.products.promotionsEs,
    appReducers.products.promotionsEn,
  ]);
  useEffect(() => {
    if (
      appReducers?.general?.firstChargeModel &&
      appReducers?.general?.firstChargeModel?.hasOwnProperty("idLang")
    ) {
      setIsUserLogin(!appReducers?.general?.firstChargeModel.isGuestUser);
      setUserId(appReducers?.general?.firstChargeModel.idUser);
      setUserLogedInfo(appReducers?.general?.firstChargeModel);
      if (!appReducers?.general?.firstChargeModel.isGuestUser) {
        // downloadProfileImageValidation(
        //   appReducers?.general?.firstChargeModel.idUser
        // ).then(
        //   (r) => {
        //     setShowProfileImage(true);
        //   },
        //   (rj) => {
        //     setShowProfileImage(false);
        //   }
        // );
      }
    }
  }, [
    appReducers?.general.errorLoadFirstCharge,
    appReducers?.general.firstChargeModel,
  ]);
  useEffect(() => {
    if (infoCartOrder.hasOwnProperty("code") && infoCartOrder.code === 100) {
      const order = infoCartOrder;
      setProductsInCart(order.lines.length);
    }
  }, [infoCartOrder]);
  useEffect(() => {
    if (appReducers?.home?.entrepenourPageInfo) {
      //setIsWebpage(true)
      setWebpageInfo(appReducers.home.entrepenourPageInfo);
    } else {
      //setIsWebpage(false)
      setWebpageInfo({});
    }
  }, [appReducers?.home.entrepenourPageInfo]);
  useEffect(() => {
    if (typeof appReducers?.twilio?.errorTwilioCellValidation !== "undefined") {
      if (appReducers?.twilio?.errorTwilioCellValidation) {
        setIsValidCellPhone(false);
      } else {
        setIsValidCellPhone(true);
      }
      if (isOpenEntrepreneurContactForm)
        dispatch(cleanTwilioCellValidationReducer());
    }
  }, [appReducers?.twilio.errorTwilioCellValidation]);
  useEffect(() => {
    //console.log("Validación de código TWILIO", appReducers?.twilio);
    if (
      typeof appReducers?.twilio?.errorTwilioCellCodeValidation !== "undefined"
    ) {
      if (!appReducers?.twilio?.errorTwilioCellCodeValidation) {
        setIsValidCellPhoneCode(true);
      } else {
        setIsValidCellPhoneCode(false);
      }
      if (isOpenEntrepreneurContactForm)
        dispatch(cleanTwilioCellCodeValidationReducer());
    }
  }, [appReducers?.twilio?.errorTwilioCellCodeValidation]);
  useEffect(() => {
    if (
      typeof appReducers?.client?.errorWebPageContactRegister !== "undefined"
    ) {
      if (appReducers?.client?.errorWebPageContactRegister) {
        setIsContactRegistered(false);
        //validar el mesnaje de error
        setIsOpenEntrepreneurContactForm(false);
        enqueueSnackbar(t("__message_navbar_error_try_again_later_label"), {
          variant: "error",
        });
      } else {
        setIsContactRegistered(true);
        setIsOpenEntrepreneurContactForm(false);
        enqueueSnackbar(t("__message_navbar_send_message_sponsor_label"), {
          variant: "success",
        });
      }
      handleCleanState();
      dispatch(cleanReducerWebPageContactReducer());
    }
  }, [appReducers?.client?.errorWebPageContactRegister]);
  useEffect(() => {
    if (
      typeof appReducers?.client?.errorGetClientAdministrationMenu !==
      "undefined"
    ) {
      if (appReducers?.client?.errorGetClientAdministrationMenu) {
        //error
        //console.log("Error al cargar el menu de navegacion");
      } else {
        //console.log("test", appReducers.client.clientAdministrationMenu)
        if (i18n.language === "en")
          setMenuAdministration(
            appReducers.client.clientAdministrationMenu.filter(
              (item) => item.idLang === 2
            )
          );
        if (i18n.language === "es")
          setMenuAdministration(
            appReducers.client.clientAdministrationMenu.filter(
              (item) => item.idLang === 1
            )
          );
      }
    }
  }, [i18n.language, appReducers?.client?.errorGetClientAdministrationMenu]);
  useEffect(() => {
    if (cellphoneTimerSeconds === 0) {
      handleSwitchToTimerOrResend("resend");
    }
  }, [cellphoneTimerSeconds]);
  useEffect(() => {
    if (cellPhone.length < 10) {
      setIsValidCellPhone(undefined);
      setIsValidCellPhoneCode(undefined);
      setCellPhoneCode("");
    }
  }, [cellPhone]);

  useEffect(() => {
    if (!isSearchbarExpanded) {
      const searchbarInput = document.getElementById("searchbarInput");
      if (searchbarInput) {
        searchbarInput.blur();
      }
    }
  }, [isSearchbarExpanded]);

  useEffect(() => {
    handleSearch(searchValue);
  }, [searchValue]);

  //Log Effects
  // useEffect(() => {
  //   console.log("menuApp", menuApp);
  // }, [menuApp]);
  // useEffect(() => {
  //   console.log("categories", categories);
  // }, [categories]);

  //Handlers
  // const downloadProfileImageValidation = (uId) => {
  //   const url =
  //     process?.env?.REACT_APP_STAGE === "dev"
  //       ? `http://10.10.1.16:8082/imageProfile/image_profile${uId}.jpg?V=${Math.floor(
  //           Math.random() * 100
  //         )}`
  //       : `https://www.zermatusa.com/imageProfile/image_profile${uId}.jpg?V=${Math.floor(
  //           Math.random() * 100
  //         )}`;
  //   return new Promise((resolve, reject) => {
  //     var download = new Image();
  //     download.src = url;
  //     download.onload = () => {
  //       resolve(download);
  //     };
  //     download.onerror = function (err, msg) {
  //       reject("name");
  //     };
  //   });
  // };
  const handleSearch = (value) => {
    if (value.length > 0 || searchValue.length > 0) {
      //Scroll to top the Result Wrapper
      const searchResultWrapper = document.querySelector(
        ".navbar__header__searchbar__inner__wrapper__search__results"
      );
      if (searchResultWrapper) {
        searchResultWrapper.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }

      let filteredProduct = products.filter((items) => {
        let result =
          items.itemCode.toLowerCase().includes(searchValue.toLowerCase()) ||
          items.nameArticle.toLowerCase().includes(searchValue.toLowerCase()) ||
          items.descArticle.toLowerCase().includes(searchValue.toLowerCase()) ||
          items.ingArticle.toLowerCase().includes(searchValue.toLowerCase()) ||
          items.modeArticle.toLowerCase().includes(searchValue.toLowerCase()) ||
          items.keyWords.toLowerCase().includes(searchValue.toLowerCase());
        return result;
      });

      let filteredPromo = promotions.filter((items) => {
        let result =
          items.itemCode.toLowerCase().includes(searchValue.toLowerCase()) ||
          items.nameArticle.toLowerCase().includes(searchValue.toLowerCase()) ||
          items.descArticle.toLowerCase().includes(searchValue.toLowerCase()) ||
          items.ingArticle.toLowerCase().includes(searchValue.toLowerCase()) ||
          items.modeArticle.toLowerCase().includes(searchValue.toLowerCase()) ||
          items.keyWords.toLowerCase().includes(searchValue.toLowerCase());
        return result;
      });

      var ids = new Set(filteredProduct.map((d) => d.itemCode));
      let filteredData = [
        ...filteredProduct,
        ...filteredPromo.filter((d) => !ids.has(d.itemCode)),
      ];

      if (filteredData.length > 0) {
        setFilterProducts(filteredData);
        setIsActiveSearch(true);
        setIsSearchbarExpanded(true);
      } else {
        setFilterProducts([]);
        setIsActiveSearch(false);
      }
    } else {
      //console.log("vacio");
      setIsActiveSearch(false);
      // if (isMobile || isTablet) {
      //   setIsSearchbarExpanded(false);
      // }
    }

    // //console.log("handleSearch");
    // if (isMobile || isTablet) {
    //   if (!isSearchbarExpanded) {
    //     setIsSearchbarExpanded(true);
    //   }
    // } else {
    //   if (isSearchbarExpanded) {
    //     setIsSearchbarExpanded(false);
    //   }
    // }
  };
  const handleValidateCellPhoneContact = (cellPhone) => {
    dispatch(requestTwilioCellPhoneValidation(cellPhone, false));
  };
  const handleValidateCellPhoneCodeContact = (cellPhone, code) => {
    dispatch(requestTwilioCellPhoneCodeValidation(cellPhone, code));
  };
  const handleWebpageContactFormSubmit = () => {
    dispatch(requestRegisterWebPageContact(name, cellPhone, comment));
  };
  const handleCleanState = () => {
    setComment("");
    setCellPhone("");
    setName("");
  };
  const renderTimerInClockTime = (seconds) => {
    let min = Math.floor(seconds / 60);
    let sec = Math.ceil((seconds % (60 * 60)) % 60);
    if (min.toString().length === 1) min = `0${min}`;
    if (sec.toString().length === 1) sec = `0${sec}`;
    return `${min}:${sec}`;
  };
  const clearCellphoneTimer = () => {
    clearInterval(intervalCellphone);
    cellphoneTimerSecondsCount = secondsToWait;
    setCellphoneTimerSeconds(cellphoneTimerSecondsCount);
  };
  const startCellphoneTimer = () => {
    cellphoneTimerSecondsCount -= 1;
    setCellphoneTimerSeconds(cellphoneTimerSecondsCount);
    if (cellphoneTimerSecondsCount === 0) {
      clearCellphoneTimer();
    }
  };
  const handleSwitchToTimerOrResend = (targetNameToShow) => {
    const timerWrapper = document.getElementById(
      "webpageFormCellphoneTimerWrapper"
    );
    const resendWrapper = document.getElementById(
      "webpageFormCellphoneResendWrapper"
    );
    setIsValidCellPhoneCode(undefined);
    if (timerWrapper && resendWrapper) {
      if (targetNameToShow === "timer") {
        timerWrapper.classList.add("active");
        resendWrapper.classList.remove("active");
      } else if (targetNameToShow === "resend") {
        timerWrapper.classList.remove("active");
        resendWrapper.classList.add("active");
      }
    }
  };
  const handleCalculateSectionsSizes = () => {
    //console.log("handleCalculateSectionsSizes")
    //Calculation for "sections" Swiper
    const swiperNavbarSections = document.getElementById(
      "swiperNavbarSections"
    );
    const swiperWrapper = swiperNavbarSections.querySelector(".swiper-wrapper");
    //console.log("swiperWrapper", swiperWrapper);
    const slides = swiperWrapper.querySelectorAll(".swiper-slide");
    //console.log("slides", slides);

    let slidesWidthSum = 0;
    slides.forEach((slide) => {
      const slideWidth = slide.getBoundingClientRect().width + 30;
      //console.log("slideWidth", slideWidth);
      slidesWidthSum += slideWidth;
    });
    slidesWidthSum -= 30;

    const sectionsWidth = swiperNavbarSections.getBoundingClientRect().width;
    // console.log("sectionsWidth", sectionsWidth);
    // console.log("slidesWidthSum", slidesWidthSum);

    const freeSpace = sectionsWidth - slidesWidthSum;
    // console.log("freeSpace", freeSpace);
    const marginCalculation = freeSpace / 2 - 195.8 / 2;
    //console.log("marginCalculation", marginCalculation);
    setPaddingLeftForSectionsSwiper(
      marginCalculation >= 0 ? marginCalculation : 0
    );

    //Calculation for "categories" Swiper
    const categoriesSwiper = document.getElementById("categoriesSwiper");
    const swiperWrapperCategories =
      categoriesSwiper.querySelector(".swiper-wrapper");
    const slidesCategories =
      swiperWrapperCategories.querySelectorAll(".swiper-slide");

    let slidesCategoriesWidthSum = 0;
    slidesCategories.forEach((slide) => {
      const slideWidth = slide.getBoundingClientRect().width;
      //console.log("slide", slide, slideWidth);
      slidesCategoriesWidthSum += slideWidth + 30;
    });
    slidesCategoriesWidthSum -= 30;

    const categoriesWidth = categoriesSwiper.getBoundingClientRect().width;
    // console.log("categoriesWidth", categoriesWidth);
    // console.log("slidesCategoriesWidthSum", slidesCategoriesWidthSum);
    const categoriesFreeSpace = categoriesWidth - slidesCategoriesWidthSum;
    // console.log("categoriesFreeSpace", categoriesFreeSpace);
    const categoriesPaddingLeftCalculation = categoriesFreeSpace / 2;

    setPaddingLeftForCategoriesSwiper(
      categoriesPaddingLeftCalculation >= 0
        ? categoriesPaddingLeftCalculation
        : 0
    );
  };

  return (
    <div
      className={`navbar__v3 ${isMobile || isTablet ? "mobile" : ""} ${
        (isMobile || isTablet) && isWebpage ? "is__webPage" : ""
      }`}
    >
      {/* MOBILE MENU */}
      <div className={`mobile__menu ${isOpenMobileMenu ? "active" : ""}`}>
        <div className="mobile__menu__inner__wrapper">
          <div className="mobile__menu__inner__wrapper__close__button__wrapper">
            <IconButton
              onClick={() => {
                setIsOpenMobileMenu(false);
              }}
            >
              <Close />
            </IconButton>
          </div>

          <div className="mobile__menu__inner__wrapper__header">
            <div className="mobile__menu__inner__wrapper__header__logo">
              <ZermatLogoBlack />
            </div>
            <div className="mobile__menu__inner__wrapper__header__social__media">
              <Facebook
                onClick={() => {
                  window.open("https://www.facebook.com/zermatusa/", "_blank");
                }}
              />
              <Instagram
                onClick={() => {
                  window.open(
                    "https://www.instagram.com/zermatus/?hl=en",
                    "_blank"
                  );
                }}
              />
              <Youtube
                onClick={() => {
                  window.open(
                    "https://www.youtube.com/channel/UCNC80gWIoSGS8tO4oU_TkCg",
                    "_blank"
                  );
                }}
              />
              <Pinterest
                onClick={() => {
                  window.open(
                    "https://www.pinterest.com.mx/zermatusa/",
                    "_blank"
                  );
                }}
              />
              <Whatsapp
                onClick={() => {
                  window.open(
                    "https://api.whatsapp.com/send?phone=13468439702&text=Hola%20me%20interesa%20uno%20de%20sus%20productos",
                    "_blank"
                  );
                }}
              />
            </div>
          </div>
          <div className="mobile__menu__inner__wrapper__items__wrapper">
            {menuApp.length > 0 && isLanguageLoaded
              ? menuApp
                  .filter((m) => m.idParent === 0)
                  .sort((a, b) => a.orderRender - b.orderRender)
                  .map((item, index) => {
                    return (
                      <ListItem
                        key={index}
                        button
                        className="mobile__menu__inner__wrapper__items__wrapper__item"
                        onClick={() => {
                          let target = "";
                          if (item.idModule === 2 || item.idModule === 3) {
                            target = localStorage.getItem("ZUsaUrlPage")
                              ? `${item.anchor}/${localStorage.getItem(
                                  "ZUsaUrlPage"
                                )}`
                              : item.anchor;
                          } else {
                            target = item.anchor;
                          }

                          if (target.length > 0) {
                            setIsOpenMobileMenu(false);
                            history.push(target);
                          }
                        }}
                      >
                        <span>{t(item.reference)}</span>
                      </ListItem>
                    );
                  })
              : [0, 1, 2, 3, 4, 5, 6].map((item) => {
                  return (
                    <Skeleton
                      key={item}
                      variant="rect"
                      width={"95%"}
                      height={65}
                      style={{ margin: "20px 12px" }}
                    />
                  );
                })}
          </div>
        </div>
      </div>
      {/* END MOBILE MENU */}

      <div className="navbar__floating__bar">
        {!window.location.pathname.includes("/home") &&
        !window.location.pathname.includes("/mypage") ? (
          <div
            className={`return__button ${isMobile || isTablet ? "mobile" : ""}`}
            onClick={() => {
              dispatch(
                requestLoggUserActivity(
                  15,
                  0,
                  0,
                  "",
                  "Click en Regresar (Navegacion)"
                )
              );

              history.goBack();
            }}
          >
            <GoBackArrow />
            <span>
              {isLanguageLoaded ? (
                t("btn_text__back").toLowerCase()
              ) : (
                <Skeleton
                  variant="rect"
                  width={40}
                  height={15}
                  style={{ margin: "0 2px" }}
                />
              )}
            </span>
          </div>
        ) : (
          <span></span>
        )}

        <div className="language__switcher">
          <div className="prev">
            <PrevArrowLanguageSwitcher />
          </div>
          <Swiper
            id="languageSwiper"
            //initialSlide={i18n.language === "en" ? 1 : 0}
            slidesPerView={1}
            updateOnWindowResize
            centeredSlides
            loop
            navigation={{
              prevEl: ".prev",
              nextEl: ".next",
            }}
            onSlideChange={(swiper) => {
              //console.log("onSlideChange => index:", swiper.realIndex);
              // if (swiper.realIndex === 0) {
              //   if (i18n.language !== "es") {
              //     //console.log("Cambiar idioma a español");
              //     i18n.changeLanguage("es");
              //   }
              // } else {
              //   if (i18n.language !== "en") {
              //     //console.log("Cambiar idioma a inglés");
              //     i18n.changeLanguage("en");
              //   }
              // }
              if (swiper.realIndex === 0) {
                i18n.changeLanguage("en");
              } else if (swiper.realIndex === 1) {
                i18n.changeLanguage("es");
              }
            }}
            modules={[Navigation]}
          >
            <SwiperSlide>
              <span>
                {!isLanguageLoaded ? (
                  <Skeleton
                    variant="rect"
                    width={50}
                    height={20}
                    style={{ margin: "0 5px" }}
                  />
                ) : (
                  t("__general__navbar_language_english")
                )}
              </span>
            </SwiperSlide>
            <SwiperSlide>
              <span>
                {" "}
                {!isLanguageLoaded ? (
                  <Skeleton
                    variant="rect"
                    width={50}
                    height={20}
                    style={{ margin: "0 5px" }}
                  />
                ) : (
                  t("__general__navbar_language_spanish")
                )}
              </span>
            </SwiperSlide>
          </Swiper>

          <div className="next">
            <NextArrowLanguageSwitcher />
          </div>
        </div>
      </div>

      <div className={`navbar__header ${isMobile || isTablet ? "mobile" : ""}`}>
        {(isMobile || isTablet) && (
          <div className="navbar__header__hamburguer__menu">
            <HamburguerMenuIcon
              onClick={() => {
                setIsOpenMobileMenu(true);
              }}
            />
          </div>
        )}

        <ClickAwayListener
          onClickAway={() => {
            if (isActiveSearch) {
              setIsActiveSearch(false);
            }
          }}
        >
          <div className={"navbar__header__searchbar"}>
            <div
              className={`navbar__header__searchbar__inner__wrapper ${
                isMobile || isTablet ? "mobile" : ""
              } ${isSearchbarExpanded ? "expanded" : ""}`}
            >
              <input
                id="searchbarInput"
                //type="text"
                type="search"
                placeholder={
                  isLanguageLoaded
                    ? t("__ZUsa_NavBar_OptionCart_Search_Button")
                    : ""
                }
                value={searchValue}
                disabled={products.length <= 0 || promotions.length <= 0}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    if (isMobile || isTablet) {
                      const searchbarInput =
                        document.getElementById("searchbarInput");
                      if (searchbarInput) {
                        searchbarInput.blur();
                      }
                    }
                  }
                }}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  //handleSearch(e.target.value);
                }}
                onFocus={() => {
                  if (filterProducts.length > 0 && !isActiveSearch) {
                    setIsActiveSearch(true);
                  }
                }}
              />
              <div className="navbar__header__searchbar__inner__wrapper__button__wrapper">
                <IconButton
                  disableRipple={isDesktop}
                  //onClick={handleSearch}
                  onClick={() => {
                    if (isMobile || isTablet) {
                      if (products.length > 0 || promotions.length > 0) {
                        const searchbarInput =
                          document.getElementById("searchbarInput");
                        if (searchbarInput) {
                          searchbarInput.focus();
                        }
                        if (isSearchbarExpanded) {
                          setIsActiveSearch(false);
                        }
                        setIsSearchbarExpanded(!isSearchbarExpanded);
                      }
                    }
                  }}
                  style={{
                    cursor: isDesktop ? "auto" : "pointer",
                  }}
                >
                  {isSearchbarExpanded && (isMobile || isTablet) ? (
                    <CloseTwoTone />
                  ) : (
                    <SearchWhiteIcon />
                  )}
                </IconButton>
              </div>

              {/* SEARCH RESULTS WRAPPER */}
              <div
                className={`navbar__header__searchbar__inner__wrapper__search__results list ${
                  isMobile || isTablet ? "mobile" : ""
                } ${
                  filterProducts.length > 0 && isActiveSearch ? "active" : ""
                }`}
              >
                <div
                  // className={`navbar__header__searchbar__inner__wrapper__search__results list ${
                  //   isMobile || isTablet ? "mobile" : ""
                  // } ${
                  //   filterProducts.length > 0 && isActiveSearch ? "active" : ""
                  // }`}
                  className="list"
                >
                  {filterProducts.length > 0
                    ? filterProducts.map((item, index) => (
                        <ListItem
                          key={index}
                          button
                          className="navbar__header__searchbar__inner__wrapper__search__results__item"
                          onClick={() => {
                            const url = localStorage.getItem("ZUsaUrlPage")
                              ? `/product-detail/${localStorage.getItem(
                                  "ZUsaUrlPage"
                                )}/${item.itemCode}/${item.typeLine}`
                              : `/product-detail/${item.itemCode}/${item.typeLine}`;
                            setIsActiveSearch(false);
                            history.push(url);
                          }}
                        >
                          <div className="navbar__header__searchbar__inner__wrapper__search__results__item__image__wrapper">
                            <img
                              src={`https://www.websizusa.net/imgprod/fullsize/${item.itemCode}.jpg`}
                              alt=""
                            />
                          </div>
                          <div className="navbar__header__searchbar__inner__wrapper__search__results__item__texts__wrapper">
                            <span>{truncateText(item.nameArticle)}</span>
                            <span>
                              $
                              {item.promotionPrice > 0
                                ? item.promotionPrice
                                : item.regularPrice}
                            </span>
                          </div>
                        </ListItem>
                      ))
                    : [0, 1, 2].map((item) => {
                        return (
                          <Skeleton
                            key={item}
                            variant="rect"
                            width={"98%"}
                            height={30}
                            style={{ margin: "15px 5px" }}
                          />
                        );
                      })}
                </div>
              </div>
              {/* END SEARCH RESULTS WRAPPER */}
            </div>
          </div>
        </ClickAwayListener>

        <div
          className={`navbar__header__logo ${
            isMobile || isTablet ? "mobile" : ""
          } ${isSearchbarExpanded ? "expanded" : ""}`}
        >
          <ZermatLogoBlack
            onClick={() => {
              let target = "";
              if (localStorage.getItem("ZUsaUrlPage")) {
                target = `/mypage/${localStorage.getItem("ZUsaUrlPage")}`;
              } else {
                target = "/home";
              }
              if (target.length > 0) {
                history.push(target);
              }
            }}
          />
        </div>
        <div
          className={`navbar__header__actions ${
            isMobile || isTablet ? "mobile" : ""
          } ${(isMobile || isTablet) && isSearchbarExpanded ? "hidden" : ""}`}
        >
          <ClickAwayListener
            onClickAway={() => {
              setIsOpenProfileMenu(false);
            }}
          >
            <div
              className={`navbar__header__actions__login__wrapper ${
                isMobile || isTablet ? "mobile" : ""
              } ${location.pathname.includes("checkout") ? "isCheckout" : ""}`}
              onClick={() => {
                //isUserLogin ? history.push("/profile") : history.push("/login");
                if (!isUserLogin) {
                  history.push("/login");
                } else {
                  setIsOpenProfileMenu(!isOpenProfileMenu);
                }
              }}
            >
              <div className="navbar__header__actions__login__wrapper__text__wrapper">
                <span>
                  {isUserLogin ? (
                    isLanguageLoaded ? (
                      userLogedInfo?.nameClient.toLowerCase()
                    ) : (
                      <Skeleton variant="rect" width={100} height={20} />
                    )
                  ) : isLanguageLoaded ? (
                    t("__navBar_MenuApp_Text_LogIn_Link")
                  ) : (
                    <Skeleton variant="rect" width={50} height={20} />
                  )}
                </span>
              </div>

              {isUserLogin ? (
                <div
                  className={`navbar__header__actions__login__wrapper__avatar__wrapper ${
                    isProfileImageLoaded ? "" : "with__initials"
                  }`}
                >
                  {isProfileImageLoaded ? (
                    <img
                      // src={
                      //   process.env.REACT_APP_STAGE === "dev"
                      //     ? `http://10.10.1.16:8082/imageProfile/image_profile${userId}.jpg?V=${Math.floor(
                      //         Math.random() * 100
                      //       )}`
                      //     : `https://www.zermatusa.com/imageProfile/image_profile${userId}.jpg?V=${Math.floor(
                      //         Math.random() * 100
                      //       )}`
                      // }
                      src={imageProfileUrl}
                      alt=""
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = AvatarPlaceholder;
                      }}
                    />
                  ) : (
                    <span>
                      {isLanguageLoaded ? (
                        convertNameToInitials(userLogedInfo?.nameClient.trim())
                      ) : (
                        <Skeleton
                          variant="circle"
                          width={100}
                          height={25}
                          style={{ margin: "5px" }}
                        />
                      )}
                    </span>
                  )}
                </div>
              ) : (
                <div className="navbar__header__actions__login__wrapper__icon__wrapper">
                  <LoginUserIcon />
                </div>
              )}

              {/* USER OPTIONS ciclo */}
              <div
                className={`navbar__header__actions__login__wrapper__user__options__wrapper ${
                  isOpenProfileMenu ? "active" : ""
                }`}
              >
                {menuAdministration.map((element, index) => {
                  return (
                    <ListItem
                      key={index}
                      button
                      className="navbar__header__actions__login__wrapper__user__options__wrapper__item"
                      onClick={() => {
                        if (element.idModContent !== 31) {
                          history.push(element.anchorResources);
                        } else {
                          dispatch(requestClientLogOut());
                          dispatch(
                            requestLoggUserActivity(
                              25,
                              0,
                              0,
                              "",
                              "Click en Cerrar Session"
                            )
                          );
                          if (localStorage.getItem("ZUsaUrlPage")) {
                            history.replace(
                              `/mypage/${localStorage.getItem("ZUsaUrlPage")}`
                            );
                          } else {
                            history.replace("/home");
                          }
                          handleResetInfoClient();
                        }
                      }}
                    >
                      <span>{t(element.nameModConten)}</span>
                    </ListItem>
                  );
                })}

                {/* <ListItem
                  button
                  className="navbar__header__actions__login__wrapper__user__options__wrapper__item"
                  onClick={() => {
                    history.push("/profile/my-profile");
                  }}
                >
                  <span>{t("__general__my_profile")}</span>
                </ListItem>
                <ListItem
                  button
                  className="navbar__header__actions__login__wrapper__user__options__wrapper__item"
                  onClick={() => {
                    history.push("/profile/shopping-list");
                  }}
                >
                  <span>{t("__general__my_purchases")}</span>
                </ListItem>
                <ListItem
                  button
                  className="navbar__header__actions__login__wrapper__user__options__wrapper__item"
                  onClick={() => {
                    history.push("/profile/friends-referred");
                  }}
                >
                  <span>{t("__general__my_referrals")}</span>
                </ListItem>
                <ListItem
                  button
                  className="navbar__header__actions__login__wrapper__user__options__wrapper__item"
                  onClick={() => {
                    dispatch(requestClientLogOut());
                    dispatch(
                      requestLoggUserActivity(
                        25,
                        0,
                        0,
                        "",
                        "Click en Cerrar Session"
                      )
                    );
                    if (localStorage.getItem("ZUsaUrlPage")) {
                      history.replace(
                        `/mypage/${localStorage.getItem("ZUsaUrlPage")}`
                      );
                    } else {
                      history.replace("/home");
                    }
                  }}
                >
                  <span>{t("__general__logout")}</span>
                </ListItem> */}
              </div>
              {/* END USER OPTIONS */}
            </div>
          </ClickAwayListener>

          <div
            className={`navbar__header__actions__buttons__wrapper ${
              isMobile || isTablet ? "mobile" : ""
            }`}
          >
            {isUserLogin && (
              <IconButton
                onClick={() => {
                  //history.push("/profile/my-favorites-list");
                  setIsOpenFloatingFavorites(true);
                }}
              >
                <HeartBlackOutlineIcon />
              </IconButton>
            )}

            {productsInCart > 0 ? (
              <IconButton
                onClick={() => {
                  //history.push("/checkout");
                  //debugger;
                  if (
                    //!window.location.pathname.includes("/precheckout") &&
                    !window.location.pathname.includes("/checkout") &&
                    !window.location.pathname.includes("/gifts")
                  ) {
                    setIsOpenFloatingCheckout(true);
                    setFloatingCheckoutIconIsClicked(true);
                  }
                }}
              >
                <BagActiveIcon />
              </IconButton>
            ) : (
              <IconButton disableRipple>
                <BagIcon />
              </IconButton>
            )}
          </div>
        </div>
      </div>

      {/* SOCIAL MEDIA AND SECTIONS */}
      <div
        className={`navbar__menu__sections ${
          isMobile || isTablet ? "mobile" : ""
        }`}
      >
        {isWebpage && (
          <div className="navbar__menu__sections__entrepreneur__webpage__section">
            <div className="navbar__menu__sections__entrepreneur__webpage__section__inner__wrapper">
              <div className="navbar__menu__sections__entrepreneur__webpage__section__inner__wrapper__info__wrapper">
                <div className="navbar__menu__sections__entrepreneur__webpage__section__inner__wrapper__info__wrapper__image__wrapper">
                  <img
                    src={webpageInfo.imageProfile}
                    alt=""
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = AvatarPlaceholder;
                    }}
                  />
                </div>
                <div className="navbar__menu__sections__entrepreneur__webpage__section__inner__wrapper__info__wrapper__texts">
                  <span>{t("__webpage__floating__form__title")}</span>
                  <span>{webpageInfo.nameClient}</span>
                </div>
                <div
                  className={`navbar__menu__sections__entrepreneur__webpage__section__inner__wrapper__info__wrapper__icon ${
                    isOpenEntrepreneurContactForm ? "active" : ""
                  }`}
                >
                  <ArrowDropDown
                    onClick={() => {
                      setIsOpenEntrepreneurContactForm(
                        !isOpenEntrepreneurContactForm
                      );
                    }}
                  />
                </div>
              </div>
              <div
                className={`navbar__menu__sections__entrepreneur__webpage__section__inner__wrapper__floating__wrapper ${
                  isOpenEntrepreneurContactForm ? "active" : ""
                }`}
              >
                <div className="navbar__menu__sections__entrepreneur__webpage__section__inner__wrapper__floating__wrapper__social__media__wrapper">
                  {webpageInfo.facebook && webpageInfo.facebook.length > 0 && (
                    <Facebook
                      onClick={() => {
                        window.open(webpageInfo.facebook, "_blank");
                      }}
                    />
                  )}
                  {webpageInfo.instagram &&
                    webpageInfo.instagram.length > 0 && (
                      <Instagram
                        onClick={() => {
                          window.open(webpageInfo.instagram, "_blank");
                        }}
                      />
                    )}
                  {webpageInfo.youTube && webpageInfo.youTube.length > 0 && (
                    <Youtube
                      onClick={() => {
                        window.open(webpageInfo.youTube, "_blank");
                      }}
                    />
                  )}
                  {webpageInfo.pinterest &&
                    webpageInfo.pinterest.length > 0 && (
                      <Pinterest
                        onClick={() => {
                          window.open(webpageInfo.pinterest, "_blank");
                        }}
                      />
                    )}
                  {webpageInfo.cellPhone &&
                    webpageInfo.cellPhone.length > 0 && (
                      <Whatsapp
                        onClick={() => {
                          window.open(
                            `https://api.whatsapp.com/send?phone=1${webpageInfo.cellPhone}&text=Hola%20quiero%20recibir%20información`,
                            "_blank"
                          );
                        }}
                      />
                    )}
                </div>
                <div className="navbar__menu__sections__entrepreneur__webpage__section__inner__wrapper__floating__wrapper__dream">
                  <span className="navbar__menu__sections__entrepreneur__webpage__section__inner__wrapper__floating__wrapper__dream__title">
                    {t("__general__my__dream")}
                  </span>
                  <span className="navbar__menu__sections__entrepreneur__webpage__section__inner__wrapper__floating__wrapper__dream__body">
                    {webpageInfo.testimonial}
                  </span>
                </div>
                {/* SE COMENTA POR QUE REFRECA EL SITIO CADA QUE MANDA LA PETICION
                <form
                  className="navbar__menu__sections__entrepreneur__webpage__section__inner__wrapper__floating__wrapper__form"
                  //onSubmit={handleWebpageContactFormSubmit}
                > */}
                <div className="navbar__menu__sections__entrepreneur__webpage__section__inner__wrapper__floating__wrapper__form">
                  <div className="navbar__menu__sections__entrepreneur__webpage__section__inner__wrapper__floating__wrapper__form__item">
                    <label htmlFor="wpFormName">{t("__general__name")}</label>
                    <div className="navbar__menu__sections__entrepreneur__webpage__section__inner__wrapper__floating__wrapper__form__item__input__wrapper">
                      <input
                        id="wpFormName"
                        type="text"
                        onInput={(e) => {
                          setName(restrictToOnlyLetters(e));
                        }}
                        value={name}
                      />
                    </div>
                  </div>
                  <div className="navbar__menu__sections__entrepreneur__webpage__section__inner__wrapper__floating__wrapper__form__item">
                    <label htmlFor="wpFormPhone">{t("__general__phone")}</label>

                    <div className="navbar__menu__sections__entrepreneur__webpage__section__inner__wrapper__floating__wrapper__form__item__input__wrapper">
                      <input
                        id="wpFormPhone"
                        type="text"
                        value={cellPhone}
                        onInput={(e) => {
                          setCellPhone(restrictToOnlyNumbers(e).toString());
                        }}
                        maxLength={10}
                      />

                      {isValidCellPhone !== undefined ? (
                        <div
                          className={`navbar__menu__sections__entrepreneur__webpage__section__inner__wrapper__floating__wrapper__form__item__input__wrapper__status__wrapper ${
                            isValidCellPhone === true
                              ? "status__success"
                              : "status__error"
                          }`}
                        >
                          {isValidCellPhone === true ? <Done /> : <Clear />}
                        </div>
                      ) : (
                        <IconButton
                          disabled={cellPhone.length < 10}
                          className="with__ripple navbar__menu__sections__entrepreneur__webpage__section__inner__wrapper__floating__wrapper__form__item__input__wrapper__validate__phone__button"
                          onClick={() => {
                            //console.log("cellPhone", cellPhone, cellPhone.length)
                            if (cellPhone.length === 10) {
                              handleValidateCellPhoneContact(cellPhone);
                            }
                          }}
                        >
                          <PhonelinkRingOutlined />
                        </IconButton>
                      )}
                    </div>

                    {cellPhone.length >= 10 &&
                      isValidCellPhone &&
                      !isValidCellPhoneCode && (
                        <div className="navbar__menu__sections__entrepreneur__webpage__section__inner__wrapper__floating__wrapper__form__item__code__wrapper">
                          <span className="navbar__menu__sections__entrepreneur__webpage__section__inner__wrapper__floating__wrapper__form__item__code__wrapper__title">
                            {t("__general__enter__the__code__you__receive")}
                          </span>
                          <input
                            id="wpFormCodeCellPhone"
                            autoFocus={isValidCellPhone}
                            type="text"
                            value={cellPhoneCode}
                            onInput={(e) => {
                              setCellPhoneCode(e.target.value);
                            }}
                            maxLength={6}
                          />

                          {isValidCellPhoneCode === false && (
                            <div className="navbar__menu__sections__entrepreneur__webpage__section__inner__wrapper__floating__wrapper__form__item__code__wrapper__code__error__wrapper">
                              <span>{t("__general__not__valid__code")}</span>
                            </div>
                          )}

                          <div
                            id="webpageFormCellphoneTimerWrapper"
                            className="navbar__menu__sections__entrepreneur__webpage__section__inner__wrapper__floating__wrapper__form__item__code__wrapper__timer active"
                          >
                            <span>{t("__general__code__expires__in")}:</span>
                            <span>
                              {renderTimerInClockTime(cellphoneTimerSeconds)}
                            </span>
                          </div>
                          <div
                            id="webpageFormCellphoneResendWrapper"
                            className="navbar__menu__sections__entrepreneur__webpage__section__inner__wrapper__floating__wrapper__form__item__code__wrapper__resend__code"
                          >
                            <span
                              onClick={() => {
                                handleValidateCellPhoneContact(cellPhone);
                                handleSwitchToTimerOrResend("timer");
                              }}
                            >
                              {t("__general__resend__me__the__code")}
                            </span>
                          </div>
                          <Button
                            className="button__with__icon bg__dark__pink no__left__margin small"
                            disabled={cellPhoneCode.length < 6}
                            onClick={() =>
                              handleValidateCellPhoneCodeContact(
                                cellPhone,
                                cellPhoneCode
                              )
                            }
                          >
                            <div className="button__with__icon__svg__wrapper dark__pink__ball">
                              <BagIcon />
                            </div>
                            <span className="button__with__icon__text">
                              {t("__new_members_kits_form_validate_code_label")}
                            </span>
                          </Button>
                        </div>
                      )}
                  </div>
                  <div className="navbar__menu__sections__entrepreneur__webpage__section__inner__wrapper__floating__wrapper__form__item">
                    <label htmlFor="wpFormMessage">
                      {t("__general__message")}
                    </label>
                    <div className="navbar__menu__sections__entrepreneur__webpage__section__inner__wrapper__floating__wrapper__form__item__input__wrapper">
                      <textarea
                        id="wpFormMessage"
                        value={comment}
                        onInput={(e) => setComment(e.target.value)}
                      ></textarea>
                    </div>
                  </div>

                  <Button
                    type="submit"
                    className="button__with__icon"
                    disabled={
                      name.length <= 0 ||
                      cellPhone.length <= 0 ||
                      comment.length <= 0 ||
                      !isValidCellPhone ||
                      !setIsValidCellPhoneCode
                    }
                    onClick={handleWebpageContactFormSubmit}
                  >
                    <div className="button__with__icon__svg__wrapper dark__pink__ball">
                      {name.length <= 0 ||
                      cellPhone.length <= 0 ||
                      comment.length <= 0 ||
                      !isValidCellPhone ||
                      !setIsValidCellPhoneCode ? (
                        <EnvelopeGrayIcon />
                      ) : (
                        <EnvelopeIcon />
                      )}
                    </div>
                    <span className="button__with__icon__text">
                      {t("__general__send__message")}
                    </span>
                  </Button>
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        )}

        <div className="navbar__menu__sections__social__media__wrapper">
          <Facebook
            onClick={() => {
              window.open("https://www.facebook.com/zermatusa/", "_blank");
            }}
          />
          <Instagram
            onClick={() => {
              window.open(
                "https://www.instagram.com/zermatus/?hl=en",
                "_blank"
              );
            }}
          />
          <Youtube
            onClick={() => {
              window.open(
                "https://www.youtube.com/channel/UCNC80gWIoSGS8tO4oU_TkCg",
                "_blank"
              );
            }}
          />
          <Pinterest
            onClick={() => {
              window.open("https://www.pinterest.com.mx/zermatusa/", "_blank");
            }}
          />
          <Whatsapp
            onClick={() => {
              window.open(
                "https://api.whatsapp.com/send?phone=13468439702&text=Hola%20me%20interesa%20uno%20de%20sus%20productos",
                "_blank"
              );
            }}
          />
        </div>
        <div className="navbar__menu__sections__sections__wrapper">
          <Swiper
            id="swiperNavbarSections"
            spaceBetween={30}
            slidesPerView={"auto"}
            updateOnWindowResize
            //centerInsufficientSlides
            centerInsufficientSlides={
              paddingLeftForSectionsSwiper > 0 ? false : true
            }
            onResize={() => {
              if (isDesktop) {
                //console.log("onResize")
                handleCalculateSectionsSizes();
              }
            }}
            style={{
              paddingLeft: paddingLeftForSectionsSwiper,
            }}
          >
            {menuApp.length > 0 && isLanguageLoaded
              ? menuApp
                  .filter(
                    (m) =>
                      m.idParent === 0 &&
                      (isWebpage
                        ? m.reference !== "__navBar_MenuApp_Text_Contact_Us"
                        : true)
                  )
                  .sort((a, b) => a.orderRender - b.orderRender)
                  .map((item, index) => {
                    //console.log("item", item)
                    return (
                      <SwiperSlide
                        key={index}
                        onClick={() => {
                          let target = "";
                          if (item.idModule === 2 || item.idModule === 3) {
                            target = localStorage.getItem("ZUsaUrlPage")
                              ? `${item.anchor}/${localStorage.getItem(
                                  "ZUsaUrlPage"
                                )}`
                              : item.anchor;
                          } else {
                            target = item.anchor;
                          }

                          if (target.length > 0) {
                            history.push(target);
                          }
                        }}
                      >
                        <span
                          className={`${
                            window.location.pathname.includes(item.anchor)
                              ? "swiper-slide--isActive"
                              : ""
                          }`}
                        >
                          {t(item.reference)}
                        </span>
                      </SwiperSlide>
                    );
                  })
              : [0, 1, 2, 3, 4, 5].map((item) => {
                  return (
                    <SwiperSlide key={item}>
                      <Skeleton
                        variant="rect"
                        width={100}
                        height={25}
                        style={{ margin: "5px" }}
                      />
                    </SwiperSlide>
                  );
                })}
          </Swiper>
        </div>
      </div>
      {/* END SOCIAL MEDIA AND SECTIONS */}

      {/* CATEGORIES */}
      <div className="navbar__categories">
        <Swiper
          id="categoriesSwiper"
          spaceBetween={30}
          slidesPerView={"auto"}
          updateOnWindowResize
          //centerInsufficientSlides
          centerInsufficientSlides={
            paddingLeftForCategoriesSwiper > 0 ? false : true
          }
          style={{
            paddingLeft: paddingLeftForCategoriesSwiper,
          }}
        >
          {categories?.length > 0
            ? categories.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <span
                      onClick={() => {
                        if (item.idCategories) {
                          let target = `/categories/products/${item.idCategories}`;
                          history.push(target);
                        }
                      }}
                    >
                      {item.nameCategories}
                    </span>
                  </SwiperSlide>
                );
              })
            : [0, 1, 2, 3, 4, 5].map((navItem) => {
                return (
                  <SwiperSlide key={navItem}>
                    <Skeleton
                      variant="rect"
                      width={90}
                      height={20}
                      className="bg__gray__Skeleton"
                    />
                  </SwiperSlide>
                );
              })}
        </Swiper>
      </div>
      {/* END CATEGORIES */}
    </div>
  );
};

export default Navbar;
