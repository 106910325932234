import React, { Fragment, useState } from "react";
import { isMobile, isTablet } from "react-device-detect";
import { Skeleton } from "@mui/material";

const BenefitsNewMembers = ({ t, i18n, isLanguageLoaded }) => {
  const [arrayClone, setArrayClone] = useState([
    {
      id: 1,
      name: "imagen1",
      imageFrontEs:
        "https://www.websizusa.net/imgprod/zu/modules/14/87/3/Benefits_header_1_es.jpg",
      imageBackEs:
        " https://www.websizusa.net/imgprod/zu/modules/14/111/3/Benefits_detail_1_es.jpg",
      imageFrontEn:
        "https://www.websizusa.net/imgprod/zu/modules/14/88/3/Benefits_header_1_en.jpg",
      imageBackEn:
        "https://www.websizusa.net/imgprod/zu/modules/14/112/3/Benefits_detail_1_en.jpg",
      seeMore: false,
    },
    {
      id: 2,
      name: "imagen2",
      imageFrontEs:
        " https://www.websizusa.net/imgprod/zu/modules/14/89/3/Benefits_header_2_es.jpg",
      imageBackEs:
        "https://www.websizusa.net/imgprod/zu/modules/14/113/3/Benefits_detail_2_es.jpg",
      imageFrontEn:
        "https://www.websizusa.net/imgprod/zu/modules/14/90/3/Benefits_header_2_en.jpg",
      imageBackEn:
        "https://www.websizusa.net/imgprod/zu/modules/14/114/3/Benefits_detail_2_en.jpg",
      seeMore: false,
    },
    {
      id: 3,
      name: "imagen3",
      imageFrontEs:
        " https://www.websizusa.net/imgprod/zu/modules/14/91/3/Benefits_header_3_es.jpg",
      imageBackEs:
        " https://www.websizusa.net/imgprod/zu/modules/14/115/3/Benefits_detail_3_es.jpg",
      imageFrontEn:
        " https://www.websizusa.net/imgprod/zu/modules/14/92/3/Benefits_header_3_en.jpg",
      imageBackEn:
        "https://www.websizusa.net/imgprod/zu/modules/14/116/3/Benefits_detail_3_en.jpg",
      seeMore: false,
    },
  ]);

  ///handle

  return (
    <div
      className={`benefits__new__members__main ${
        isTablet ? "tablet" : isMobile ? "mobile" : ""
      }`}
    >
      {!isLanguageLoaded ? (
        <Skeleton
          variant="wave"
          width={isMobile ? "90%" : "90%"}
          height={30}
          style={{
            margin: 10,
          }}
        />
      ) : (
        <h1
          className={`benefits__new__members__main__title ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          {t("__benefits__new__members__title")}
        </h1>
      )}

      <div
        className={`benefits__new__members__main__wrapper ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }`}
      >
        {!isLanguageLoaded ? (
          <Fragment>
            {[0, 1, 2].map((item, index) => {
              return (
                <Skeleton
                  key={index}
                  variant="wave"
                  width={isMobile ? "70%" : 300}
                  height={isMobile ? 200 : 300}
                  style={{ margin: 10 }}
                />
              );
            })}
          </Fragment>
        ) : (
          <Fragment>
            {arrayClone?.map((item, index) => {
              return (
                <div
                  className={`benefits__new__members__main__wrapper__imagen ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  }`}
                  key={index}
                >
                  {!item.seeMore ? (
                    <img
                      alt={item.name}
                      src={
                        i18n.language === "es"
                          ? item.imageFrontEs
                          : item.imageFrontEn
                      }
                      onClick={() => {
                        let cloneArray = [...arrayClone];
                        const index = cloneArray.findIndex(
                          (i) => i.id === item.id
                        );
                        let cloneElement = cloneArray[index];
                        cloneElement.seeMore = !item.seeMore;
                        setArrayClone(cloneArray);
                      }}
                    />
                  ) : (
                    <img
                      alt={item.name}
                      src={
                        i18n.language === "es"
                          ? item.imageBackEs
                          : item.imageBackEn
                      }
                      onClick={() => {
                        let cloneArray = [...arrayClone];
                        const index = cloneArray.findIndex(
                          (i) => i.id === item.id
                        );
                        let cloneElement = cloneArray[index];
                        cloneElement.seeMore = !item.seeMore;
                        setArrayClone(cloneArray);
                      }}
                    />
                  )}
                </div>
              );
            })}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default BenefitsNewMembers;
