import {
    RESPONSE_TRPREX_PRODUCTS,
    RESPONSE_FAVORITES,
    RESPONSE_ALL_PRODUCTS,
    RESPONSE_ALL_CATEGORIES,
    RESPONSE_SET_PRODUCT_ON_FAVORITES,
    RESPONSE_GET_ZCARE_PRODUCT,
    RESPONSE_GET_SETUP_KIT_COMPONENTS,
    RESPONSE_GET_ADMISSION_KITS,
    RESPONSE_ALL_PRODUCTS_BY_LANG,
    RESPONSE_ADD_SET_CATEGORIES_REDUCER,
    RESPONSE_REMOVE_SET_CATEGORIES_REDUCER,
    RESPONSE_ALL_PRODUCTS_ASYNC,
    RESPONSE_ALL_CAREGORIES_ASYNC,
    RESPONSE_GET_BEAUTY_BOXES,
    RESPONSE_ALL_PRODUCT_FAMILIES_ASYNC
} from '../../Constants'


export default function products(state = {}, action) {
    switch (action.type) {
        case RESPONSE_TRPREX_PRODUCTS:
            {
                const responseTrPrEx = action.payload.response;
                if (responseTrPrEx.code === 100) {
                    let stateProducts = state;
                    let familyEs = stateProducts.familyEs; 
                    let familyEn = stateProducts.familyEn;

                    let tagOffers = stateProducts.tagOffers;

                    let imagesArticle = stateProducts.imagesArticle;

                    //// ESPAÑOL NOVEDADES \\\\
                    const newsEs = responseTrPrEx.news.filter(f => f.idLang === 1);
                    let newArrayNewEs=[];
                    newsEs.map((item) => {
                        item.familyProducts = [];
                        //item.familyProducts = familyEs.filter(x =>x.id === item.idFamily && x.itemCode !== item.itemCode ).sort((a, b) => a.orderRender - b.orderRender);
                        item.familyProducts = familyEs.filter(x =>x.id === item.idFamily ).sort((a, b) => a.orderRender - b.orderRender);

                        ////TAGS\\\\
                        item.tagsOffer = [];
                        item.tagsOffer = tagOffers.filter(x =>x.itemCode === item.itemCode).sort((a, b) => a.orderRender - b.orderRender);

                        ////IMAGES\\\\
                        item.imagesArticle = [];
                        item.imagesArticle = imagesArticle.filter(x =>x.itemCode === item.itemCode);
                    });

                    for(var i in newsEs) {
                        if (newsEs[i].idFamily > 0 ) {
                           // if( !newArrayNewEs.find( (elemnt) => elemnt.idFamily === newsEs[i].idFamily ) ) {
                                let allArticlesByFamliy = newsEs.filter((item) => { 
                                    if (item.idFamily === newsEs[i].idFamily)
                                    {
                                        item.orderRender = familyEs.find(( e ) => e.id === item.idFamily && e.itemCode === item.itemCode ).orderRender;
                                        item.valueType = familyEs.find(( e ) => e.id === item.idFamily && e.itemCode === item.itemCode ).valueType;

                                        return item
                                    }                                
                                });

                                allArticlesByFamliy.sort((a, b) => a.orderRender - b.orderRender);
                                let articleFinal = allArticlesByFamliy.find((item)=>item.itemCode === newsEs[i].itemCode);
                                
                                newArrayNewEs.push(articleFinal);
                                //newArrayNewEs.push(allArticlesByFamliy[0])
                           // }
                        } else {
                            newArrayNewEs.push(newsEs[i])
                        }
                    }
                    //// ESPAÑOL NOVEDADES \\\\

                    //// INGLES NOVEDADES \\\\
                    const newsEn = responseTrPrEx.news.filter(f => f.idLang === 2);
                    let newArrayNewEn=[];
                    newsEn.map((item) => {
                        item.familyProducts = [];
                        //item.familyProducts = familyEn.filter(x =>x.id === item.idFamily && x.itemCode !== item.itemCode ).sort((a, b) => a.orderRender - b.orderRender);
                        item.familyProducts = familyEn.filter(x =>x.id === item.idFamily ).sort((a, b) => a.orderRender - b.orderRender);

                        ////TAGS\\\\
                        item.tagsOffer = [];
                        item.tagsOffer = tagOffers.filter(x =>x.itemCode === item.itemCode).sort((a, b) => a.orderRender - b.orderRender);

                        ////IMAGES\\\\
                        item.imagesArticle = [];
                        item.imagesArticle = imagesArticle.filter(x =>x.itemCode === item.itemCode);
                    });
                    
                    for(var i in newsEn) {
                        if (newsEn[i].idFamily > 0 ) {
                            //if( !newArrayNewEn.find( (elemnt) => elemnt.idFamily === newsEn[i].idFamily ) ) {
                                let allArticlesByFamliy = newsEn.filter((item) => { 
                                    if (item.idFamily === newsEn[i].idFamily)
                                    {
                                        item.orderRender = familyEn.find(( e ) => e.id === item.idFamily && e.itemCode === item.itemCode ).orderRender;
                                        item.valueType = familyEn.find(( e ) => e.id === item.idFamily && e.itemCode === item.itemCode ).valueType;

                                        return item
                                    }                                
                                });

                                allArticlesByFamliy.sort((a, b) => a.orderRender - b.orderRender);
                                let articleFinal = allArticlesByFamliy.find((item)=>item.itemCode === newsEn[i].itemCode);

                                //newArrayNewEn.push(allArticlesByFamliy[0])
                                newArrayNewEn.push(articleFinal)
                            //}
                        } else {
                            newArrayNewEn.push(newsEn[i])
                        }
                    }
                    //// INGLES NOVEDADES \\\\

                    
                    
                    //// ESPAÑOL PROMOCIONES \\\\
                    const promotionsEs = responseTrPrEx.promotions.filter(f => f.idLang === 1);
                    let newArrayPromoEs=[];
                    promotionsEs.map((item) => {
                        item.familyProducts = [];
                        //item.familyProducts = familyEs.filter(x =>x.id === item.idFamily && x.itemCode !== item.itemCode ).sort((a, b) => a.orderRender - b.orderRender);
                        item.familyProducts = familyEs.filter(x =>x.id === item.idFamily ).sort((a, b) => a.orderRender - b.orderRender);

                        ////TAGS\\\\
                        item.tagsOffer = [];
                        item.tagsOffer = tagOffers.filter(x =>x.itemCode === item.itemCode).sort((a, b) => a.orderRender - b.orderRender);

                        ////IMAGES\\\\
                        item.imagesArticle = [];
                        item.imagesArticle = imagesArticle.filter(x =>x.itemCode === item.itemCode);
                    });
                    
                    for(var i in promotionsEs) {
                        if (promotionsEs[i].idFamily > 0 ) {
                            //if( !newArrayPromoEs.find( (elemnt) => elemnt.idFamily === promotionsEs[i].idFamily ) ) {
                                let allArticlesByFamliy = promotionsEs.filter((item) => { 
                                    if (item.idFamily === promotionsEs[i].idFamily)
                                    {
                                        item.orderRender = familyEs.find(( e ) => e.id === item.idFamily && e.itemCode === item.itemCode ).orderRender;
                                        item.valueType = familyEs.find(( e ) => e.id === item.idFamily && e.itemCode === item.itemCode ).valueType;

                                        return item
                                    }                                
                                });

                                allArticlesByFamliy.sort((a, b) => a.orderRender - b.orderRender);

                                //newArrayPromoEs.push(allArticlesByFamliy[0])
                                let articleFinal = allArticlesByFamliy.find((item)=>item.itemCode === promotionsEs[i].itemCode);
                                
                                newArrayPromoEs.push(articleFinal);
                            //}
                        } else {
                            newArrayPromoEs.push(promotionsEs[i])
                        }
                    }
                    //// ESPAÑOL PROMOCIONES \\\\

                    //// INGLES PROMOCIONES \\\\
                    const promotionsEn = responseTrPrEx.promotions.filter(f => f.idLang === 2);
                    let newArrayPromoEn=[];
                    promotionsEn.map((item) => {
                        item.familyProducts = [];
                        // item.familyProducts = familyEn.filter(x =>x.id === item.idFamily && x.itemCode !== item.itemCode ).sort((a, b) => a.orderRender - b.orderRender);
                        item.familyProducts = familyEn.filter(x =>x.id === item.idFamily ).sort((a, b) => a.orderRender - b.orderRender);

                        ////TAGS\\\\
                        item.tagsOffer = [];
                        item.tagsOffer = tagOffers.filter(x =>x.itemCode === item.itemCode).sort((a, b) => a.orderRender - b.orderRender);

                        ////IMAGES\\\\
                        item.imagesArticle = [];
                        item.imagesArticle = imagesArticle.filter(x =>x.itemCode === item.itemCode);
                    });

                    for(var i in promotionsEn) {
                        if (promotionsEn[i].idFamily > 0 ) {
                            //if( !newArrayPromoEn.find( (elemnt) => elemnt.idFamily === promotionsEn[i].idFamily ) ) {
                                let allArticlesByFamliy = promotionsEn.filter((item) => { 
                                    if (item.idFamily === promotionsEn[i].idFamily)
                                    {
                                        item.orderRender = familyEn.find(( e ) => e.id === item.idFamily && e.itemCode === item.itemCode ).orderRender;
                                        item.valueType = familyEn.find(( e ) => e.id === item.idFamily && e.itemCode === item.itemCode ).valueType;

                                        return item
                                    }                                
                                });

                                allArticlesByFamliy.sort((a, b) => a.orderRender - b.orderRender);

                                //newArrayPromoEn.push(allArticlesByFamliy[0])
                                let articleFinal = allArticlesByFamliy.find((item)=>item.itemCode === promotionsEn[i].itemCode);
                                
                                newArrayPromoEn.push(articleFinal);
                            //}
                        } else {
                            newArrayPromoEn.push(promotionsEn[i])
                        }
                    }
                    //// INGLES PROMOCIONES \\\\
                   
                    
                    return { ...state, errorLoadTrPrExProducts: false, promotionsEs: newArrayPromoEs, promotionsEn: newArrayPromoEn, newsEs: newArrayNewEs, newsEn: newArrayNewEn }
                }
                else {
                    return { ...state, errorLoadTrPrExProducts: true }
                }

            }
        case RESPONSE_FAVORITES:
            {
                const responseFavorites = action.payload.response;
                if (responseFavorites.code === 100) {
                    const favoritesEs = responseFavorites.favorites.filter(f => f.idLang === 1);
                    const favoritesEn = responseFavorites.favorites.filter(f => f.idLang === 2);
                    return { ...state, errorLoadfavorites: false, favoritesEs, favoritesEn }
                }
                else {
                    return { ...state, errorLoadfavorites: true }
                }


            }
        //YA NO SE USA\\
        case RESPONSE_ALL_PRODUCTS:
            {
                const responseProducts = action.payload.response;
                if (responseProducts.code === 100) {
                    try {
                        // const productsAllEs = responseProducts.languages.filter(l => l.id === 1)
                        // const productsAllEn = responseProducts.languages.filter(l => l.id === 2)
                        // // const categoriesEs = productsAllEs[0].categories.map(item => { return ({ idCategories: item.idCategories, idCategoriesType: item.idCategoriesType, nameCategories: item.nameCategories }) });
                        // // const categoriesEn = productsAllEn[0].categories.map(item => { return ({ idCategories: item.idCategories, idCategoriesType: item.idCategoriesType, nameCategories: item.nameCategories }) });
                        
                        let categoriesEs = []

                        let subCategoriesEsL1 = []
                        let subCategoriesEsL2 = []
                        let subCategoriesEsL3 = []
                        let subCategoriesEsL4 = []

                        let productsEs = []
                        // productsAllEs[0].categories.forEach(element => {
                        //     let idCategoryType = element.idCategoriesType
                        //     if (element.subCategories.length > 0) {
                        //         // subCategoriesEsL1 = [...subCategoriesEsL1, element.subCategories.map(sub1 => { return ({ idCategoryType, idSubCategories: sub1.idSubCategories, nameSubCategories: sub1.nameSubCategories }) })]
                        //         subCategoriesEsL1.push.apply(subCategoriesEsL1, element.subCategories.map(sub1 => { return ({ idCategoryType, idSubCategories: sub1.idSubCategories, nameSubCategories: sub1.nameSubCategories }) }))
                        //         element.subCategories.forEach(element2Es => {
                        //             let isSubCategoryLevel1 = element2Es.idSubCategories
                        //             if (element2Es.subCategoriesList.length > 0) {
                        //                 //subCategoriesEsL2 = [...subCategoriesEsL2, element2Es.subCategoriesList.map(sub2 => { return ({ isSubCategoryLevel1, idSubCategories: sub2.idSubCategories, nameSubCategories: sub2.nameSubCategories }) })]
                        //                 subCategoriesEsL2.push.apply(subCategoriesEsL2, element2Es.subCategoriesList.map(sub2 => { return ({ isSubCategoryLevel1, idSubCategories: sub2.idSubCategories, nameSubCategories: sub2.nameSubCategories }) }))
                        //                 element2Es.subCategoriesList.forEach(element3Es => {
                        //                     let isSubCategoryLevel2 = element3Es.idSubCategories
                        //                     if (element3Es.subCategoriesList.length > 0) {
                        //                         subCategoriesEsL3.push.apply(subCategoriesEsL3, element3Es.subCategoriesList.map(sub3 => { return ({ isSubCategoryLevel2, idSubCategories: sub3.idSubCategories, nameSubCategories: sub3.nameSubCategories }) }))
                        //                     } else if (element3Es.products && element3Es.products.length > 0) {
                        //                         productsEs.push.apply(productsEs, element3Es.products)
                        //                     }
                        //                 });
                        //             } else if (element2Es.products && element2Es.products.length > 0) {
                        //                 productsEs.push.apply(productsEs, element2Es.products)
                        //             }
                        //         })
                        //     } else if (element.products && element.products.length > 0) {
                        //         productsEs.push.apply(productsEs, element.products)
                        //     }
                        // });

                        let categoriesEn = []

                        let subCategoriesEnL1 = []
                        let subCategoriesEnL2 = []
                        let subCategoriesEnL3 = []
                        let subCategoriesEnL4 = []

                        let productsEn = []
                        // productsAllEn[0].categories.forEach(element => {
                        //     const idCategoryType = element.idCategoriesType
                        //     if (element.subCategories.length > 0) {
                        //         subCategoriesEnL1.push.apply(subCategoriesEnL1, element.subCategories.map(sub1 => { return ({ idCategoryType, idSubCategories: sub1.idSubCategories, nameSubCategories: sub1.nameSubCategories }) }))
                        //         element.subCategories.forEach(element2En => {
                        //             const isSubCategoryLevel1 = element2En.idSubCategories
                        //             if (element2En.subCategoriesList.length > 0) {
                        //                 subCategoriesEnL2.push.apply(subCategoriesEnL2, element2En.subCategoriesList.map(sub2 => { return ({ isSubCategoryLevel1, idSubCategories: sub2.idSubCategories, nameSubCategories: sub2.nameSubCategories }) }))
                        //                 element2En.subCategoriesList.forEach(element3En => {
                        //                     const isSubCategoryLevel2 = element3En.idSubCategories
                        //                     if (element3En.subCategoriesList.length > 0) {
                        //                         subCategoriesEnL3.push.apply(subCategoriesEnL3, element3En.subCategoriesList.map(sub3 => { return ({ isSubCategoryLevel2, idSubCategories: sub3.idSubCategories, nameSubCategories: sub3.nameSubCategories }) }))
                        //                     } else if (element3En.products && element3En.products.length > 0) {
                        //                         productsEn.push.apply(productsEn, element3En.products)
                        //                     }
                        //                 });
                        //             } else if (element2En.products && element2En.products.length > 0) {
                        //                 productsEn.push.apply(productsEn, element2En.products)
                        //             }
                        //         })
                        //     } else if (element.products && element.products.length > 0) {
                        //         productsEn.push.apply(productsEn, element.products)
                        //     }
                        // });

                        ////ARMADO DE ARTICULOS\\\\
                        responseProducts.languageComponentModels.map(elem => {
                            switch (elem.id) {
                                case 1:
                                        productsEs = elem.productDetailModels;
                        
                                        categoriesEs = elem.categorieModels;
                        
                                        subCategoriesEsL1 = elem.subCategoryModelLvl1;
                                        subCategoriesEsL2 = elem.subCategoryModelLvl2;
                                        subCategoriesEsL3 = elem.subCategoryModelLvl3;
                                        subCategoriesEsL4 = elem.subCategoryModelLvl4;
                                    break;
                                case 2:
                                        productsEn = elem.productDetailModels;
                        
                                        categoriesEn = elem.categorieModels;
                                        
                                        subCategoriesEnL1 = elem.subCategoryModelLvl1;
                                        subCategoriesEnL2 = elem.subCategoryModelLvl2;
                                        subCategoriesEnL3 = elem.subCategoryModelLvl3;
                                        subCategoriesEnL4 = elem.subCategoryModelLvl4;
                                    break;
                            }
                        });
                        ////categoriesEs, categoriesEn,
                        return { ...state, errorLoadAllProducts: false,categoriesEs, subCategoriesEsL1, subCategoriesEsL2, subCategoriesEsL3, subCategoriesEsL4, productsEs, categoriesEn, subCategoriesEnL1, subCategoriesEnL2, subCategoriesEnL3, subCategoriesEnL4, productsEn }
                    }
                    catch (error) {
                        console.error("error al armar reducer de productos y categorias", error)
                        return { ...state, errorLoadAllProducts: true }
                    }
                }
                else {
                    return { ...state, errorLoadAllProducts: true }
                }


            }
        //YA NO SE USA\\
        case RESPONSE_ALL_CATEGORIES:
            {
                const responseCategorieProducts = action.payload.response;
                const idLang = action.payload.idLang;
                if (responseCategorieProducts.code === 100) {
                    try {                        
                        let categoriesEs = [];
                        let subCategoriesEsL1 = []
                        let subCategoriesEsL2 = []
                        let subCategoriesEsL3 = []
                        
                        let categoriesEn = [];
                        let subCategoriesEnL1 = []
                        let subCategoriesEnL2 = []
                        let subCategoriesEnL3 = []

                        switch (idLang) {
                            case 1:
                                categoriesEs = responseCategorieProducts.categorieModels;
                        
                                subCategoriesEsL1 = responseCategorieProducts.subCategoryModelLvl1s;
                                subCategoriesEsL2 = responseCategorieProducts.subCategoryModelLvl2s;
                                subCategoriesEsL3 = responseCategorieProducts.subCategoryModelLvl3s;
                                
                                let statecatProdEn = state;
                                if (
                                    statecatProdEn && statecatProdEn?.categoriesEn && statecatProdEn.categoriesEn.length > 0 
                                ) {
                                    
                                    categoriesEn = statecatProdEn?.categoriesEn

                                    subCategoriesEnL1 = statecatProdEn?.subCategoriesEnL1
                                    subCategoriesEnL2 = statecatProdEn?.subCategoriesEnL2
                                    subCategoriesEnL3 = statecatProdEn?.subCategoriesEnL3
                                }
                                break;
                            case 2:

                                categoriesEn = responseCategorieProducts.categorieModels;
                                        
                                let statecatProdEs = state;
                                if (
                                    statecatProdEs && statecatProdEs?.categoriesEn && statecatProdEs.categoriesEn.length > 0 
                                ) {
                                    
                                    categoriesEs = statecatProdEs?.categoriesEs

                                    subCategoriesEsL1 = statecatProdEs?.subCategoriesEsL1
                                    subCategoriesEsL2 = statecatProdEs?.subCategoriesEsL2
                                    subCategoriesEsL3 = statecatProdEs?.subCategoriesEsL3
                                }


                                subCategoriesEnL1 = responseCategorieProducts.subCategoryModelLvl1s;
                                subCategoriesEnL2 = responseCategorieProducts.subCategoryModelLvl2s;
                                subCategoriesEnL3 = responseCategorieProducts.subCategoryModelLvl3s;


                                break;
                        }
                        return { ...state, errorLoadAllCategories: false, categoriesEn, subCategoriesEnL1, subCategoriesEnL2, subCategoriesEnL3,categoriesEs, subCategoriesEsL1, subCategoriesEsL2, subCategoriesEsL3 }
                        
                    }
                    catch (error) {
                        console.error("error al armar reducer de categorias", error)
                        return { ...state, errorLoadAllCategories: true }
                    }
                }
            }
        case RESPONSE_SET_PRODUCT_ON_FAVORITES:
            {
                const responseSetProductOnFavorites = action.payload.response;
                if (responseSetProductOnFavorites.code === 100) {
                    const { clientId, itemCode, typeLine, isFavorite } = action.payload.modelFavoriteSended;
                    let favoritesEs = [];
                    let favoritesEn = [];
                    if (isFavorite) {
                        const modelProductToAddEs = state.productsEs.find(p => p.itemCode === itemCode && p.typeLine === typeLine)
                        const modelProductToAddEn = state.productsEn.find(p => p.itemCode === itemCode && p.typeLine === typeLine)
                        if (modelProductToAddEs)
                            favoritesEs = [...state.favoritesEs, modelProductToAddEs]
                        if (modelProductToAddEn)
                            favoritesEn = [...state.favoritesEn, modelProductToAddEn]
                    }else{
                        favoritesEs = [...state.favoritesEs]
                        favoritesEn = [...state.favoritesEn]
                        
                        favoritesEs.splice(favoritesEs.findIndex(p => p.itemCode === itemCode && p.typeLine === typeLine), 1);
                        favoritesEn.splice(favoritesEn.findIndex(p => p.itemCode === itemCode && p.typeLine === typeLine), 1);
                    }

                    return { ...state, errorSetProductOnFavorites: false , favoritesEs , favoritesEn, isFavorite }
                } else {
                    return { ...state, errorSetProductOnFavorites: true  }
                }

            }        
        case RESPONSE_GET_ZCARE_PRODUCT:
            {
                const getZermatCareProduct = action.payload.response;
                if(getZermatCareProduct.code === 100 || getZermatCareProduct.code === 100){
                    return { ...state, zCareProduct: getZermatCareProduct}
                }else{
                    console.error("Error al solicitar los la validacion del banner", getZermatCareProduct)
                    return { ...state, errorZCareProduct: true}
                }
            }        
        case RESPONSE_GET_SETUP_KIT_COMPONENTS:
            {
                const kitSetupComponents = action.payload.response;
                if(kitSetupComponents.code === 100 || kitSetupComponents.code === 100){
                    return { ...state, kitSetupComponents: kitSetupComponents}
                }else{
                    console.error("Error al solicitar los componentes de un set configurable", kitSetupComponents)
                    return { ...state, errorKitSetupComponents: true}
                }
            }
        case RESPONSE_GET_ADMISSION_KITS:
            {
                const admissionKitList = action.payload.response;
                if(admissionKitList.code === 100 || admissionKitList.code === 100){
                    return { ...state, admissionKitList: admissionKitList}
                }else{
                    console.error("Error al solicitar los kits de ingreso", admissionKitList)
                    return { ...state, errorAdmissionKitList: true}
                }
            }
        case RESPONSE_GET_BEAUTY_BOXES:
            {
                const beautyBoxes = action.payload.response;
                if(beautyBoxes.code === 100 || beautyBoxes.code === 100){
                    return { ...state, beautyBoxes: beautyBoxes}
                }else{
                    console.error("Error al solicitar los kits de ingreso", beautyBoxes)
                    return { ...state, errorBeautyBoxesList: true}
                }
            }
        //YA NO SE USA \\
        case RESPONSE_ALL_PRODUCTS_BY_LANG:
            {
                const responseProducts = action.payload.response;
                const idLang = action.payload.idLang;
                if (responseProducts.code === 100) {
                    try {
                        let productsEs = [];

                        let productsEn = [];

                        ////ARMADO DE ARTICULOS\\\\
                        switch (idLang) {
                            case 1:
                                    productsEs = responseProducts.products

                                    let stateProdEn = state;
                                    if (
                                        stateProdEn && stateProdEn?.productsEn && stateProdEn?.productsEn.length > 0 
                                    ) {
                                        
                                        productsEn = stateProdEn?.productsEn
                                    }

                                break;
                            case 2:
                                    productsEn = responseProducts.products

                                    let stateProdEs = state;
                                    if (
                                        stateProdEs && stateProdEs?.productsEs && stateProdEs.productsEs.length > 0 
                                    ) {
                                        
                                        productsEs = stateProdEs?.productsEs
                                    }

                                break;
                        }
                        //categoriesEs, categoriesEn,
                        return { ...state, errorLoadAllProducts: false, productsEs, productsEn }
                        return { ...state, errorLoadAllProducts: false }
                    }
                    catch (error) {
                        console.error("error al armar reducer de productos y categorias", error)
                        return { ...state, errorLoadAllProducts: true }
                    }
                }
                else {
                    return { ...state, errorLoadAllProducts: true }
                }
            }
        case RESPONSE_ADD_SET_CATEGORIES_REDUCER:
            {
                let statecatProd = state;
                let categoriesEs = [];
                let categoriesEn = [];
                let objCatPromoEs = {};
                let objCatPromoEn = {};

                if (
                    statecatProd && 
                    statecatProd?.categoriesEs && 
                    statecatProd?.categoriesEn && 
                    statecatProd.categoriesEs.length > 0 && 
                    statecatProd.categoriesEn.length > 0 
                ) {
                    categoriesEs = statecatProd?.categoriesEs
                    categoriesEn = statecatProd?.categoriesEn

                    if (!categoriesEs.find((item) => item.idCategories === 7 && item.idCategoriesType === 13)) {
                        objCatPromoEs = {
                            idCategories: 7
                            ,idCategoriesType: 13
                            ,idLang: 1
                            ,nameCategories: "SETS"
                        }
                        categoriesEs.push(objCatPromoEs)
                    }

                    if (!categoriesEn.find((item) => item.idCategories === 7 && item.idCategoriesType === 14)) {
                        objCatPromoEn = {
                            idCategories: 7
                            ,idCategoriesType: 14
                            ,idLang: 2
                            ,nameCategories: "SETS"
                        }
                        categoriesEn.push(objCatPromoEn)
                    }                   

                    return { ...state,isModulePromotions: true ,  categoriesEn , categoriesEs }
                }                
            }
        case RESPONSE_REMOVE_SET_CATEGORIES_REDUCER:
            {
                let statecatProd = state;
                let categoriesEs = [];
                let categoriesEn = [];
                if (
                    statecatProd && 
                    statecatProd?.categoriesEs && 
                    statecatProd?.categoriesEn && 
                    statecatProd.categoriesEs.length > 0 && 
                    statecatProd.categoriesEn.length > 0 
                ) {
                    categoriesEs = statecatProd?.categoriesEs;
                    categoriesEn = statecatProd?.categoriesEn;

                    if (categoriesEs.find((item) => item.idCategories === 7 && item.idCategoriesType === 13)) {
                        categoriesEs = categoriesEs.filter((item) => { return item.idCategories !== 7 && item.idCategoriesType !== 13 })
                    }

                    if (categoriesEn.find((item) => item.idCategories === 7 && item.idCategoriesType === 14)) {
                        categoriesEn = categoriesEn.filter((item) => { return item.idCategories !== 7 && item.idCategoriesType !== 14 })
                    }
                    return { ...state,isModulePromotions: false ,  categoriesEn , categoriesEs }
                }                
            }

        case RESPONSE_ALL_PRODUCTS_ASYNC: 
            {
                const responseProducts = action.payload;
                if (responseProducts) {
                    try {
                        let stateProducts = state;

                        let tagOffers = stateProducts.tagOffers;
                        let imagesArticle = stateProducts.imagesArticle;

                        let familyEs = stateProducts.familyEs; 
                        let familyEn = stateProducts.familyEn;

                        let productsEs = responseProducts.prodEsp;
                        let productsEn = responseProducts.prodEng;


                        /* PROMOCIONES INI */
                        let promotionsEn = stateProducts.promotionsEn;
                        promotionsEn.map((item) => {

                            item.idCatProductsCategories = 7;
                            item.idCatProductsCategoriesType = 14;

                            productsEn.push(item)
                        });

                        let promotionsEs = stateProducts.promotionsEs; 
                        promotionsEs.map((item) => {
                            
                            item.idCatProductsCategories = 7;
                            item.idCatProductsCategoriesType = 13;

                            productsEs.push(item)
                        });
                        /* PROMOCIONES END */

                        /* NOVEDADES INI */
                        let newsEn = stateProducts.newsEn;
                        newsEn.map((item) => {

                            item.idCatProductsCategories = 11;
                            item.idCatProductsCategoriesType = 24;

                            productsEn.push(item)
                        });

                        let newsEs = stateProducts.newsEs; 
                        newsEs.map((item) => {
                            
                            item.idCatProductsCategories = 11;
                            item.idCatProductsCategoriesType = 23;

                            productsEs.push(item)
                        });
                        /* NOVEDADES END */

                        let coments = responseProducts.comments;
                        
                        //otras personas tambien compraron
                        let bought = responseProducts.bought;
                        //prod normal
                        bought.map((item) => {
                            //if (item.family !== 7 && item.family !== 8 ) {
                                item.prodEs = productsEs.find( x => x.itemCode === item.itemCode  )
                                item.prodEn = productsEn.find( x => x.itemCode === item.itemCode  )
                            //} 
                            // else {
                            //     item.prodEs = st.promotionsEs.find( x => x.itemCode === item.itemCode )
                            //     item.prodEn = st.promotionsEn.find( x => x.itemCode === item.itemCode )
                            // }
                        });
                        
                        //relacionados
                        let related = responseProducts.related;
                        related.map((item) => {
                            item.prodEs = productsEs.find( x => x.itemCode === item.itemCodeRelated )
                            item.prodEn = productsEn.find( x => x.itemCode === item.itemCodeRelated )
                        });

                        productsEs.map((item) => {
                            //comentarios
                            item.comentsAndRating = [];
                            item.comentsAndRating = coments.filter(x => x.itemCode === item.itemCode);
                            //otras personas tambien compraron
                            item.alsoBought = [];
                            item.alsoBought = bought.filter(x => x.family === item.idCatProductsCategories).map(({prodEs}) => prodEs);
                            //relacionados
                            item.relatedProducts = [];
                            item.relatedProducts = related.filter(x =>x.itemCode === item.itemCode).map(({prodEs}) => prodEs);
                            //novedades
                            item.isNewArticle = false
                            //item.isNewArticle = newsEs.some(  x => x.itemCode === item.itemCode );
                            // //Familia
                            item.familyProducts = [];
                            //item.familyProducts = familyEs.filter(x =>x.id === item.idFamily && x.itemCode !== item.itemCode ).sort((a, b) => a.orderRender - b.orderRender);
                            item.familyProducts = familyEs.filter(x =>x.id === item.idFamily).sort((a, b) => a.orderRender - b.orderRender);
                            ////TAGS\\\\
                            item.tagsOffer = [];
                            item.tagsOffer = tagOffers.filter(x =>x.itemCode === item.itemCode).sort((a, b) => a.orderRender - b.orderRender);
                            ////IMAGES\\\\
                            item.imagesArticle = [];
                            item.imagesArticle = imagesArticle.filter(x =>x.itemCode === item.itemCode);
                        });

                        productsEn.map((item) => {
                            //comentarios
                            item.comentsAndRating = [];
                            item.comentsAndRating = coments.filter(x => x.itemCode === item.itemCode);
                            //otras personas tambien compraron
                            item.alsoBought = [];
                            item.alsoBought = bought.filter(x => x.family === item.idCatProductsCategories).map(({prodEn}) => prodEn);
                            //relacionados
                            item.relatedProducts = [];
                            item.relatedProducts = related.filter(x =>x.itemCode === item.itemCode).map(({prodEn}) => prodEn);
                            //novedades
                            item.isNewArticle = false
                            //item.isNewArticle = newsEn.some(  x => x.itemCode === item.itemCode );
                            // //Familia
                            item.familyProducts = [];
                            //item.familyProducts = familyEn.filter(x =>x.id === item.idFamily && x.itemCode !== item.itemCode ).sort((a, b) => a.orderRender - b.orderRender);
                            item.familyProducts = familyEn.filter(x =>x.id === item.idFamily).sort((a, b) => a.orderRender - b.orderRender);
                            ////TAGS\\\\
                            item.tagsOffer = [];
                            item.tagsOffer = tagOffers.filter(x =>x.itemCode === item.itemCode).sort((a, b) => a.orderRender - b.orderRender);
                            ////IMAGES\\\\
                            item.imagesArticle = [];
                            item.imagesArticle = imagesArticle.filter(x =>x.itemCode === item.itemCode);
                        });

                        //// ESPAÑOL PRODUCTOS \\\\
                        let newArrayProductsEs=[];
                        for(var i in productsEs) {

                            productsEs[i].showFirstOnFamily = false;

                            if (productsEs[i].idFamily > 0 ) {

                                //if( !newArrayProductsEs.find( (elemnt) => elemnt.idFamily === productsEs[i].idFamily ) ) {
                                    
                                    let allArticlesByFamliy = productsEs.filter((item) => { 
                                        if (item.idFamily === productsEs[i].idFamily && productsEs[i].idCatProductsCategories === item.idCatProductsCategories ) /// hr se incluye idcategoria porque new es categoria en duro y si hay familia toma la original mas la fake de new y en new ya no se muestra porque agarra primero la chida
                                        {
                                            item.orderRender = familyEs.find(( e ) => e.id === item.idFamily && e.itemCode === item.itemCode ).orderRender;
                                            item.valueType = familyEs.find(( e ) => e.id === item.idFamily && e.itemCode === item.itemCode ).valueType;
    
                                            return item
                                        }                                
                                    });
    
                                    allArticlesByFamliy.sort((a, b) => a.orderRender - b.orderRender);
                                    //newArrayProductsEs.push(allArticlesByFamliy[0])

                                    let articleFinal = allArticlesByFamliy.find((item)=>item.itemCode === productsEs[i].itemCode);
                                    newArrayProductsEs.push(articleFinal);

                                //}
                            } else {
                                newArrayProductsEs.push(productsEs[i])
                            }
                        }
                        //// ESPAÑOL PRODUCTOS \\\\

                        //// INGLES PRODUCTOS \\\\
                        let newArrayProductsEn=[];
                        for(var i in productsEn) {
                            if (productsEn[i].idFamily > 0 ) {

                                //if( !newArrayProductsEn.find( (elemnt) => elemnt.idFamily === productsEn[i].idFamily ) ) {

                                    let allArticlesByFamliy = productsEn.filter((item) => { 
                                        if (item.idFamily === productsEn[i].idFamily  && productsEn[i].idCatProductsCategories === item.idCatProductsCategories) /// hr se incluye idcategoria porque new es categoria en duro y si hay familia toma la original mas la fake de new y en new ya no se muestra porque agarra primero la chida
                                        {
                                            item.orderRender = familyEn.find(( e ) => e.id === item.idFamily && e.itemCode === item.itemCode ).orderRender;
                                            item.valueType = familyEn.find(( e ) => e.id === item.idFamily && e.itemCode === item.itemCode ).valueType;

                                            return item
                                        }                                
                                    });

                                    allArticlesByFamliy.sort((a, b) => a.orderRender - b.orderRender);
                                    //newArrayProductsEn.push(allArticlesByFamliy[0])

                                    let articleFinal = allArticlesByFamliy.find((item)=>item.itemCode === productsEn[i].itemCode);
                                    newArrayProductsEn.push(articleFinal);

                                //}
                            } else {
                                newArrayProductsEn.push(productsEn[i])
                            }
                        }
                        //// INGLES PRODUCTOS \\\\

                        return { ...state, errorLoadAllProducts: false, productsEs: newArrayProductsEs, productsEn:newArrayProductsEn }
                    }
                    catch (error) {
                        console.error("error al armar reducer de productos y categorias", error)
                        return { ...state, errorLoadAllProducts: true }
                    }
                }
                else {
                    return { ...state, errorLoadAllProducts: true }
                }
            }

        case RESPONSE_ALL_CAREGORIES_ASYNC:
            {
                const responseCats = action.payload;
                if (responseCats) {
                    try {
                        let categoriesEs = [];
                        let subCategoriesEsL1 = []
                        let subCategoriesEsL2 = []
                        let subCategoriesEsL3 = []
                        let subCategoriesEsL4 = []
                        
                        let categoriesEn = [];
                        let subCategoriesEnL1 = []
                        let subCategoriesEnL2 = []
                        let subCategoriesEnL3 = []
                        let subCategoriesEnL4 = []

                        /////////////////////////////

                        categoriesEs = responseCats.catEsp.getAllCategoriesByLanguage.categorieModels;
                        
                        subCategoriesEsL1 = responseCats.catEsp.getAllCategoriesByLanguage.subCategoryModelLvl1s;
                        subCategoriesEsL2 = responseCats.catEsp.getAllCategoriesByLanguage.subCategoryModelLvl2s;
                        subCategoriesEsL3 = responseCats.catEsp.getAllCategoriesByLanguage.subCategoryModelLvl3s;
                        subCategoriesEsL4 = responseCats.catEsp.getAllCategoriesByLanguage.subCategoryModelLvl4s;

                        /////////////////////////////
                        categoriesEn = responseCats.catEng.getAllCategoriesByLanguage.categorieModels;
                        
                        subCategoriesEnL1 = responseCats.catEng.getAllCategoriesByLanguage.subCategoryModelLvl1s;
                        subCategoriesEnL2 = responseCats.catEng.getAllCategoriesByLanguage.subCategoryModelLvl2s;
                        subCategoriesEnL3 = responseCats.catEng.getAllCategoriesByLanguage.subCategoryModelLvl3s;
                        subCategoriesEnL4 = responseCats.catEng.getAllCategoriesByLanguage.subCategoryModelLvl4s;
                        	
                        return { ...state, errorLoadAllCategories: false, categoriesEn, subCategoriesEnL1, subCategoriesEnL2, subCategoriesEnL3, subCategoriesEnL4, categoriesEs, subCategoriesEsL1, subCategoriesEsL2, subCategoriesEsL3, subCategoriesEsL4 }

                    }
                    catch (error) {
                        console.error("error al armar reducer de productos y categorias", error)
                        return { ...state, errorLoadAllProducts: true }
                    }
                }
                else {
                    return { ...state, errorLoadAllProducts: true }
                }
            }

        case RESPONSE_ALL_PRODUCT_FAMILIES_ASYNC:
            {
                const responseFam = action.payload;
                if (responseFam) {
                    try {

                        let familyEs = responseFam.familyEsp; 
                        let familyEn = responseFam.familyEng; 

                        let tagOffers = responseFam.tagOffers; 
                        let imagesArticle = responseFam.imagesArticle; 

                        return { ...state, errorLoadAllCategories: false, familyEs, familyEn, tagOffers, imagesArticle }
                    }
                    catch (error) {
                        console.error("error al armar reducer de productos y categorias", error)
                        return { ...state, errorLoadAllProducts: true }
                    }
                }
                else {
                    return { ...state, errorLoadAllProducts: true }
                }
            }

        default:
            return { ...state }
    }
}