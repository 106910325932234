import {
  REQUEST_REFERRER_CELLPHONE_VALIDATION,
  REQUEST_REFERRER_EMAIL_VALIDATION,
  REQUEST_REFERRER_NEW_REGISTER,
  REQUEST_REFERRER_CODE,
  REQUEST_REFERRER_CODE_SENDED_LOG,
  REQUEST_REFERRER_LIST,
  CLEAN_REFERRER_SENDED_CODE_LOG,
  CLEAN_REFERRER_REDUCER,
} from "./../../Constants";

export const requestReferrerCellPhoneValidation = (cellPhone) => {
  return {
    type: REQUEST_REFERRER_CELLPHONE_VALIDATION,
    payload: { cellPhone },
  };
};

export const requestReferrerEmailValidation = (email) => {
  return {
    type: REQUEST_REFERRER_EMAIL_VALIDATION,
    payload: { email },
  };
};

export const requestReferrerNewRegistration = (model) => {
  let sessionId = 0;
  if (localStorage.getItem("ZUsaUInfo")) {
    sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
  }
  const referred = {
    sessionId,
    referrerCode: model.referrerCode,
    name: model.name,
    lastName: model.lastName,
    email: model.email,
    cellPhone: model.cellPhone,
    password: model.password,
  };

  return {
    type: REQUEST_REFERRER_NEW_REGISTER,
    payload: { referred },
  };
};

export const cleanReferrerReducer = () => {
  return {
    type: CLEAN_REFERRER_REDUCER,
    payload: {},
  };
};

export const requestReferrerCode = () => {
  let sessionId = 0;
  if (localStorage.getItem("ZUsaUInfo")) {
    sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
  }
  return {
    type: REQUEST_REFERRER_CODE,
    payload: {sessionId}
  }
}

export const requestReferrerCodeSendedLog = (referrerCode, email, cellPhone) => {
  let idSession = 0;
  if (localStorage.getItem("ZUsaUInfo")) {
    idSession = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
  }
  const sendedCodeModel = {
    referrerCode,
    idSession,
    email,
    cellPhone
  }
  return {
    type: REQUEST_REFERRER_CODE_SENDED_LOG,
    payload: { sendedCodeModel }
  }
}

export const cleanReferrerCodeSendedLogReducer = () => {
  return {
    type: CLEAN_REFERRER_SENDED_CODE_LOG,
    payload: {},
  };
};

export const requestReferrerList = (referrerCode) => {
  return {
    type: REQUEST_REFERRER_LIST,
    payload: { referrerCode },
  };
};

