import React, { useState } from "react";

const CheckboxComponent = ({
  valueCheck,
  handleCheck,
  labelCheckbox,
  valueChecked,
}) => {
  const [randomId] = useState(() => {
    return `cbox${Math.floor(Math.random() * 100)}`;
  });

  return (
    <div className="checkbox__component">
      <input
        type="checkbox"
        //id="cbox2"
        id={randomId}
        checked={valueChecked}
        value={valueCheck}
        onChange={handleCheck}
      />{" "}
      <label
        //htmlFor="cbox2"
        htmlFor={randomId}
      >
        {labelCheckbox}
      </label>
    </div>
  );
};

export default CheckboxComponent;
