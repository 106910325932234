import React, { Fragment, useState } from "react";
import {
  Drawer,
  Chip,
  Button,
  TextField,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ConfirmationNumber, AccountBalanceWallet } from "@mui/icons-material";
import LogoPaypal from "../../../../assets/media/images/logoPaypal.png";

const ModalCoupons = ({
  isOpenCouponsModal,
  handleCloseCouponsModal,
  couponList,
  handleRequestImmediateGains,
}) => {
  /// TRANSLATION
  const { t } = useTranslation();
  const [isEmailTextFieldActive, setIsEmailTextFieldActive] = useState(false);
  const [paypalEmail, setPaypalEmail] = useState("");

  const getTotalCuponsAmount = () => {
    if (couponList?.length > 0) {
      let sum = 0;
      couponList.forEach((item) => {
        sum = sum + item.amount;
      });
      return sum;
    } else return 0;
  };

  const handleEmailActived = () => {
    setIsEmailTextFieldActive(true);
  };

  return (
    <Fragment>
      <Drawer
        className="modal__slide__container"
        anchor="bottom"
        open={isOpenCouponsModal}
      >
        <Paper elevation={3}>
          <div className="modal__slide__container__pill">
            <Chip clickable onClick={handleCloseCouponsModal} />
          </div>
          <div className="modal__slide__container__wrapper">
            <Typography variant="h5">
              {t(
                "__myAccount_Profile_Information_My_Coupons_Modal_Title_Label"
              )}
            </Typography>
            <div className="modal__slide__container__wrapper__information">
              <div className="modal__slide__container__wrapper__information__left">
                <div>
                  <AccountBalanceWallet />
                  <Typography variant="body1">
                    {t(
                      "__myAccount_Profile_Information_My_Coupons_Modal_Total_Label"
                    )}
                  </Typography>

                  <Typography variant="body1">
                    {t(
                      "__myAccount_Profile_Information_My_Coupons_Modal_Money_Label"
                    ) + getTotalCuponsAmount().toFixed(2)}{" "}
                  </Typography>
                </div>
                <div className="modal__slide__container__wrapper__information__left__paypal__content">
                  {getTotalCuponsAmount() >= 50 && !isEmailTextFieldActive && (
                    <div
                      onClick={handleEmailActived}
                      className="modal__slide__container__wrapper__information__left__paypal"
                    >
                      <img src={LogoPaypal} />
                    </div>
                  )}
                  {isEmailTextFieldActive && (
                    <Fragment>
                      <TextField
                        required
                        label={t("__modals__my__cupons__email__label")}
                        helperText={t(
                          "__modals__my__cupons__email__help__label"
                        )}
                        type="email"
                        error={
                          paypalEmail.length <= 0 ||
                          paypalEmail.indexOf("@") === -1
                        }
                        value={paypalEmail}
                        onInput={(e) => setPaypalEmail(e.target.value)}
                      />
                      <Typography variant="caption" display="block">
                        {t("__modals__my__cupons__email__error__label")}
                      </Typography>
                      <div className="modal__slide__container__wrapper__information__left__button">
                        <Button
                          variant="contained"
                          disabled={
                            paypalEmail.length <= 0 ||
                            paypalEmail.indexOf("@") === -1
                          }
                          onClick={() => {
                            const amount = getTotalCuponsAmount();
                            handleRequestImmediateGains(paypalEmail, amount);
                          }}
                        >
                          {t("__modals__my__cupons__email__pay__label")}
                        </Button>
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
              <div className="modal__slide__container__wrapper__information__right">
                <div className="modal__slide__container__list__coupons">
                  <List>
                    {couponList?.map((coupon, index) => {
                      return (
                        <ListItem key={index}>
                          <ListItemAvatar>
                            <Avatar>
                              <ConfirmationNumber />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={coupon.name}
                            secondary={
                              t(
                                "__myAccount_Profile_Information_My_Coupons_Modal_Money_Label"
                              ) + coupon.amount.toFixed(2)
                            }
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </Drawer>
    </Fragment>
  );
};

export default ModalCoupons;
