import GraphqlHandler from "../GraphqlHandler";
import GraphqlHandlerNoInterceptor from "./../GraphqlHandler/noInterceptor";
import { ENVIROMENT_URL } from "../../React/ReduxSaga/Constants";

const route = ENVIROMENT_URL + "apiV3/ValidationMicroservice/Validation";

class Twilio {
  static CellPhoneValidation(cellphone, dbCheck, typePlan = 0) {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      route,
      //'https://localhost:44357/api/Validation',
      `(@autodeclare){
        validateCellPhone(cellphone:$cellphone, dbCheck:$dbCheck, typePlan:$typePlan)
        {
          code,
          message
        }     
               
      }`,
      true,
      {
        cellphone,
        dbCheck,
        typePlan,
      }
    );
    return result;
  }

  static CellPhoneCodeValidation(cellphone, code) {
    const result = GraphqlHandler.openRequestGraphQlQuery(
      route,
      //'http://localhost:59622/api/ClientSession',
      `(@autodeclare){
        validateCellPhoneCode(cellphone:$cellphone, code:$code)
        {
          code,
          message
        }     
               
      }`,
      true,
      {
        cellphone,
        code,
      }
    );
    return result;
  }
}

export default Twilio;
