import React from "react";
import { Button, Typography, Skeleton } from "@mui/material";
//import NotificationSnackbar from "../../base/components/NotificationSnackbar";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { capitalizeText } from "../../../../Libraries/Utils/utils";
// import { requestCancelOrderComplete } from "../../../ReduxSaga/Actions/Repayment";
// import { useDispatch, useSelector } from "react-redux";

const ShoppingCancel = ({ setStatusOpc, order, handleClickCancellation }) => {
  //Notistack
  const { enqueueSnackbar } = useSnackbar();

  //state
  // const [isOpenNotification, setIsOpenNotification] = useState(false);
  // const [isLoadingEnabled, setIsLoadingEnabled] = useState(true);
  // const [isObjectFakeReducer, setIsObjectFakeReducer] = useState({});

  /// TRANSLATION
  const { t, i18n } = useTranslation();

  // /////Reducer
  // const dispatch = useDispatch();

  // const appReducers = {};
  // appReducers.repayment = useSelector((state) => state.repayment);
  // /////hooks
  // const handleClickCancellation = () => {
  //   dispatch(requestCancelOrderComplete());
  // };

  // useEffect(() => {
  //   if (appReducers?.repayment?.objectFake) {
  //     setIsObjectFakeReducer(appReducers.repayment);
  //     console.log("ÿa estamos de regreso");
  //   }
  // }, [appReducers?.repayment?.objectFake]);

  // useEffect(() => {
  //   if (isObjectFakeReducer) {
  //     console.log("este es el objeto fake", isObjectFakeReducer);
  //   }
  // }, [isObjectFakeReducer]);
  return (
    <div>
      <div className="detail__shopping__container">
        {/* <Fragment>
          <NotificationSnackbar
            isOpenNotification={isOpenNotification}
            setIsOpenNotification={setIsOpenNotification}
            message={t("__profile_Detail_shopping_cancel_successful_label")}
            status="success"
            icon="shoppingBagBlock "
          />
        </Fragment> */}
        <div className="detail__shopping__container__title">
          <Typography variant="h5" gutterBottom>
            {t("__profile_shopping_cancel_title")}
          </Typography>
        </div>
        <div className="shopping__cancel__description">
          <Typography variant="caption" display="block" gutterBottom>
            {t("__profile_shopping_cancel_list_products")}
          </Typography>
        </div>
        <div className="detail__shopping__container__order">
          <div className="shopping__cancel__container__order__list">
            {order?.detail ? (
              <>
                {" "}
                {order.detail.map((item, index) => {
                  return (
                    item.typeLine !== "C" && (
                      <div
                        className="detail__shopping__container__order__list__item"
                        key={index}
                      >
                        <div>
                          <img
                            className="favorites__list__container__items__content__item__image"
                            src={item.imgProd}
                            alt={item.itemCode}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src =
                                "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
                            }}
                          />
                        </div>
                        <div>
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                          >
                            {i18n.language === "es"
                              ? capitalizeText(item.descEs)
                              : capitalizeText(item.descEn)}
                          </Typography>
                          <Typography
                            className="shopping__list__container__wrapper__item__price"
                            variant="caption"
                            display="block"
                            gutterBottom
                          >
                            ${parseFloat(item.price).toFixed(2)}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                          >
                            {item.cant} pz
                          </Typography>
                        </div>
                      </div>
                    )
                  );
                })}
              </>
            ) : (
              <>
                {[0, 1, 2].map((itemX) => {
                  return (
                    <Skeleton
                      key={itemX}
                      width={"100%"}
                      height={100}
                      style={{ marginBottom: 5 }}
                    />
                  );
                })}
              </>
            )}

            <Typography
              className="shopping__cancel__detail__prices"
              variant="caption"
              display="block"
              gutterBottom
            >
              <div className="detail__shopping__container__wrapper">
                <div className="detail__shopping__container__wrapper__prices__subtitle">
                  {t("__checkout_order_subtotal")}
                </div>
                <div className="detail__shopping__container__wrapper__prices__amount">
                  ${parseFloat(order.totalAmount).toFixed(2)}
                </div>
              </div>
              <div className="detail__shopping__container__wrapper">
                <div className="detail__shopping__container__wrapper__prices__subtitle">
                  {t("__checkout_order_charges")}
                </div>
                <div className="detail__shopping__container__wrapper__prices__amount">
                  ${parseFloat(order.charges).toFixed(2)}
                </div>
              </div>
              <div className="detail__shopping__container__wrapper">
                <div className="detail__shopping__container__wrapper__prices__subtitle">
                  {t("__checkout_order_taxes")}
                </div>
                <div className="detail__shopping__container__wrapper__prices__amount">
                  ${parseFloat(order.importeimp).toFixed(2)}
                </div>
              </div>
              <div className="detail__shopping__container__wrapper">
                <div className="detail__shopping__container__wrapper__prices__subtitle">
                  {t("__checkout_order_credit")}
                </div>
                <div className="detail__shopping__container__wrapper__prices__amount">
                  ${parseFloat(order.coupon).toFixed(2)}
                </div>
              </div>
              <div className="detail__shopping__container__wrapper">
                <div className="detail__shopping__container__wrapper__prices__subtitle__total">
                  {t("__checkout_order_total")}
                </div>
                <div className="detail__shopping__container__wrapper__prices__amount__total">
                  {parseFloat(
                    order.totalAmount + order.charges - order.coupon
                  ).toFixed(2)}
                </div>
              </div>
            </Typography>
          </div>
        </div>
      </div>
      <div className="shopping__cancel__buttons__wrapper">
        <div className="shopping__cancel__buttons__wrapper__right">
          <Button
            onClick={() => {
              setStatusOpc(1);
            }}
            variant="outlined"
          >
            {t("__kit_configuration_btn_back_Label")}
          </Button>
        </div>

        <div className="shopping__cancel__buttons__wrapper__left">
          <Button onClick={handleClickCancellation} variant="contained">
            {t("__profile_shopping_cancel_btn")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ShoppingCancel;
