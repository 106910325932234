import React, { useState, useEffect, Fragment } from "react";
import { useSnackbar } from "notistack";
import { isMobile, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useHistory, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//MUI
import { Button, Box, Typography, Skeleton } from "@mui/material";
import QRCode from "react-qr-code";
//COMPONENTS
import SummaryCheckout from "./../checkout/Components/SumaryCheckout";
///ACTIONS
import {
  requestGetCartClosedOrder,
  requestCleanCartReducer,
  requestGetCartOrder,
  cleanCartClosedOrder,
} from "./../../ReduxSaga/Actions/Cart";
import {
  requestCleanAddressReducer,
  requestClientInformation,
  requestClientLogOut,
  requestRegisterFromCheckoutFiscalAddressReducer,
  requestRegisterFromCheckoutReducer,
} from "./../../ReduxSaga/Actions/Client";
import { requestCleanPaymentReducer } from "../../ReduxSaga/Actions/Payment";

///UTILS
import { convertNameToInitials } from "./../../../Libraries/Utils/utils";
import { RouterPrompt } from "./../../../Libraries/Utils/UnsavedChangesWarningP";
////Assets
import AvatarPlaceholder from "./../../../assets/media/images/avatarPlaceholder.jpg";
import { ReactComponent as BoxOpenIcon } from "./../../../assets/media/images/icons/boxOpenIcon.svg";
import { ReactComponent as SurpriseIcon } from "./../../../assets/media/images/icons/surpriseIcon.svg";

const NewSummaryShoppingContainer = ({
  match,
  benefitType,
  guestInfoToCloseOrder,
  clientInfoToCloseOrder,
  admissionKits,
  cpMemberships,
  handleResetInfoClient,
  vCardWebPageEntreprenourData,
  isProfileImageLoaded,
  imageProfileUrl,
  isLanguageLoaded,
  vCardSponsorSelection,
  cleanVCardSponsorSelection,
  sponsorSelectionData,
}) => {
  //Notistack
  const { enqueueSnackbar } = useSnackbar();
  ///REDUX
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.cart = useSelector((state) => state.cart);
  appReducers.general = useSelector((state) => state.general);
  appReducers.client = useSelector((state) => state.client);
  appReducers.home = useSelector((state) => state.home);
  /// TRANSLATION
  const { t, i18n } = useTranslation();
  const history = useHistory();

  /// HOOKS
  const [isLoadingEnabled, setIsLoadingEnabled] = useState(true);
  //ORDER HOOKS
  const [orderLines, setOrderLines] = useState([]);
  const [order, setOrder] = useState({});
  const [orderCloseType, setOrderCloseType] = useState("");
  const [showProfileImage, setShowProfileImage] = useState(false);
  const [userId, setUserId] = useState(0);
  const [clientType, setClientType] = useState(0);
  const [isGuest, setIsGuest] = useState(false);
  const [isClickContinue, setIsClickContinue] = useState(false);
  const [entreprenourWebPageData, setEntreprenourWebPageData] = useState({});

  ///EFFECT
  ///EFFECT ON MATCH PARAMS FROM REACT ROUTER
  useEffect(() => {
    document.title = "Zermat USA :: Summary";
  }, []);

  useEffect(() => {
    //sessionId, benefitId

    if (
      match?.hasOwnProperty("params") &&
      match?.params?.hasOwnProperty("sessionId") &&
      match?.params?.hasOwnProperty("orderCloseType") &&
      typeof appReducers?.cart?.cartClosedOrder === "undefined"
    ) {
      const sessionIdParam = parseInt(match.params.sessionId);
      setOrderCloseType(match.params.orderCloseType);
      dispatch(requestGetCartClosedOrder(sessionIdParam));
    } else {
      setOrderCloseType(match.params.orderCloseType);
    }
  }, [match, appReducers?.cart.cartClosedOrder]);

  //Effect para obtener la orden cerrada
  useEffect(() => {
    if (typeof appReducers?.cart?.errorCartClosedOrder !== "undefined") {
      enqueueSnackbar("", { variant: "error" });
    } else {
      if (typeof appReducers?.cart?.cartClosedOrder !== "undefined") {
        const closed = appReducers.cart.cartClosedOrder;
        if (closed.code === 100) {
          setOrderLines(closed.lines);
          setOrder(closed.order);
        }
      }
    }
  }, [
    appReducers?.cart.cartClosedOrder,
    appReducers?.cart.errorCartClosedOrder,
  ]);

  useEffect(() => {
    if (typeof appReducers?.general?.errorLoadFirstCharge !== "undefined") {
      if (!appReducers.general.errorLoadFirstCharge) {
        const { idUser, isGuestUser } = appReducers.general.firstChargeModel;
        setUserId(idUser);
        setIsGuest(isGuestUser);
        // downloadProfileImageValidation(idUser).then(
        //   (r) => {
        //     setShowProfileImage(true);
        //   },
        //   (rj) => {
        //     setShowProfileImage(false);
        //   }
        // );
      }
    }
  }, [
    appReducers.general.errorLoadFirstCharge,
    appReducers.general.firstChargeModel,
  ]);

  useEffect(() => {
    if (order.hasOwnProperty("orderId") && orderLines.length > 0) {
      setIsLoadingEnabled(false);
    }
  }, [order, orderLines]);

  ///Effect login user
  useEffect(() => {
    if (typeof appReducers?.general?.errorLoadFirstCharge !== "undefined") {
      if (!appReducers.general.errorLoadFirstCharge) {
        if (
          typeof appReducers?.client?.errorLoadClientLogedInformation !==
            "undefined" &&
          !appReducers.client.errorLoadClientLogedInformation
        ) {
          setClientType(
            parseInt(appReducers.client.clientLogedinInformation.typeUser)
          );
        } else {
          if (orderLines.length > 0) {
            if (
              orderLines?.find((l) =>
                admissionKits.find((kits) => kits.itemCode === l.line.codart)
              )
            ) {
              setClientType(1);
            } else if (
              orderLines?.find((l) =>
                cpMemberships.find((kits) => kits.itemCode === l.line.codart)
              )
            ) {
              setClientType(2);
            } else {
              setClientType(5);
            }
          }
        }
      }
    }
  }, [
    appReducers?.general.errorLoadFirstCharge,
    appReducers?.general.firstChargeModel,
    appReducers?.client.errorLoadClientLogedInformation,
    appReducers?.client.clientLogedinInformation,
    orderLines,
  ]);

  useEffect(() => {
    if (
      typeof appReducers?.home?.errorLoadEntrepenourHomeModule !== "undefined"
    ) {
      if (
        !appReducers?.home?.errorLoadEntrepenourHomeModule &&
        appReducers?.home?.homeEntrepenourModuleEn
      ) {
        //debugger;
        setEntreprenourWebPageData(appReducers?.home?.entrepenourPageInfo);
      }
    }
  }, [
    appReducers?.home.errorLoadEntrepenourHomeModule,
    appReducers?.home.homeEntrepenourModuleEs,
    appReducers?.home.homeEntrepenourModuleEn,
  ]);

  // const orderCloseType = "guest";
  // const guestInfoToCloseOrder = {
  //   name: "Roberta",
  //   lastName: "Martinez",
  // };
  // const clientInfoToCloseOrder = {
  //   name: "Roberta",
  //   lastName: "Martinez",
  // };

  ///HANDLERS
  // const downloadProfileImageValidation = (uId) => {
  //   const url =
  //     process?.env?.REACT_APP_STAGE === "dev"
  //       ? `http://10.10.1.16:8082/imageProfile/image_profile${uId}.jpg?V=${Math.floor(
  //           Math.random() * 100
  //         )}`
  //       : `https://www.zermatusa.com/imageProfile/image_profile${uId}.jpg?V=${Math.floor(
  //           Math.random() * 100
  //         )}`;
  //   return new Promise((resolve, reject) => {
  //     var download = new Image();
  //     download.src = url;
  //     download.onload = () => {
  //       resolve(download);
  //     };
  //     download.onerror = function (err, msg) {
  //       reject("name");
  //     };
  //   });
  // };

  const handleFinishBuy = () => {
    dispatch(requestCleanCartReducer());
    dispatch(requestRegisterFromCheckoutReducer());
    dispatch(requestRegisterFromCheckoutFiscalAddressReducer());
    dispatch(requestCleanPaymentReducer());
    dispatch(requestCleanAddressReducer());

    //console.log("SI ENTRE, ES OTRA COSA");
    //dispatch(cleanCartClosedOrder());

    if (clientType > 0) {
      if (clientType === 1) {
        dispatch(requestClientLogOut());
        if (localStorage.getItem("ZUsaUrlPage")) {
          history.replace(`/mypage/${localStorage.getItem("ZUsaUrlPage")}`);
        } else {
          history.replace("/home");
        }
        cleanVCardSponsorSelection();
        //dispatch(requestClientLogOut());
      } else {
        if (!isGuest) dispatch(requestClientInformation());

        history.push("/categories");
      }
    } else {
      dispatch(requestGetCartOrder());
    }
    if (isGuest || clientType === 1) handleResetInfoClient();
  };

  return (
    <div
      id="firstWrapperInBaseContainer"
      className={`new__summary ${
        isTablet ? "tablet" : isMobile ? "mobile" : ""
      }`}
    >
      <RouterPrompt
        onBlock={!isClickContinue}
        title={t("__myAccount_Profile_Message_Alert_Title_Unsaved_Changes")}
        message={t("__summary__modal__blocker__message")}
        cancelText={t("btn_text_cancel")}
        okText={t("btn_text_continue_shop")}
        onOK={() => {
          handleFinishBuy();
          return true;
        }}
        onCancel={() => false}
      />
      <div
        className={`new__summary__container ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }`}
      >
        <div
          className={`new__summary__container__header ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          {isLanguageLoaded ? (
            <span>{t("__checkout_order_new_summary_label")}</span>
          ) : (
            <Skeleton variant="rect" width={"30%"} height={30} />
          )}
        </div>
        <div
          className={`new__summary__container__inner ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          <div
            className={`new__summary__container__inner__list ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            <Box
              className={`shopping__payment__container__boxMain__products ${
                isMobile && !isTablet ? "mobile" : isTablet ? "tablet" : ""
              }`}
            >
              <div
                className={`shopping__payment__container__boxMain__header ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                <div className="shopping__payment__container__boxMain__header__icon__wrapper">
                  <BoxOpenIcon />
                </div>
                {/* <div className="boxOpenIcon " /> */}

                <div className="shopping__payment__container__boxMain__header__inner__wrapper">
                  {isLanguageLoaded ? (
                    <Typography
                      className={`shopping__payment__container__boxMain__header__title ${
                        isMobile && !isTablet
                          ? "mobile"
                          : isTablet
                          ? "tablet"
                          : ""
                      }`}
                    >
                      {t("shopping__payment__headers__products")}
                    </Typography>
                  ) : (
                    <Skeleton variant="rect" width={"20%"} height={30} />
                  )}
                </div>
              </div>
              <div
                className={`shopping__payment__container__boxMain__products__list ${
                  isMobile && !isTablet ? "mobile" : isTablet ? "tablet" : ""
                }`}
              >
                {!isLoadingEnabled ? (
                  <SummaryCheckout
                    orderLines={orderLines.filter(
                      (l) => l.line.tipolinea !== "R"
                    )}
                    showPrice={true}
                  />
                ) : (
                  [0, 1, 2].map((itemX) => (
                    <Skeleton
                      key={itemX}
                      width={"100%"}
                      height={100}
                      style={{ marginBottom: 5 }}
                    />
                  ))
                )}
              </div>
              {orderLines.filter((l) => l.line.tipolinea === "R").length >
                0 && (
                <div>
                  <div
                    className={`shopping__payment__container__boxMain__header ${
                      isTablet ? "tablet" : isMobile ? "mobile" : ""
                    }`}
                  >
                    <div className="shopping__payment__container__boxMain__header__icon__wrapper">
                      <SurpriseIcon />
                    </div>
                    {/* <div className="surpriseIcon " /> */}
                    <div className="shopping__payment__container__boxMain__header__inner__wrapper">
                      {isLanguageLoaded ? (
                        <Typography
                          className={`shopping__payment__container__boxMain__header__title ${
                            isMobile && !isTablet
                              ? "mobile"
                              : isTablet
                              ? "tablet"
                              : ""
                          }`}
                        >
                          {t("shopping__payment__headers__my__gifts")}
                        </Typography>
                      ) : (
                        <Skeleton variant="rect" width={"20%"} height={30} />
                      )}
                    </div>
                  </div>
                  <div
                    className={`shopping__payment__container__boxMain__products__gifts ${
                      isMobile && !isTablet
                        ? "mobile"
                        : isTablet
                        ? "tablet"
                        : ""
                    }`}
                  >
                    {!isLoadingEnabled ? (
                      <SummaryCheckout
                        orderLines={orderLines.filter(
                          (l) => l.line.tipolinea === "R"
                        )}
                        showPrice={false}
                      />
                    ) : (
                      [0, 1, 2].map((itemX) => (
                        <Skeleton
                          key={itemX}
                          width={"100%"}
                          height={100}
                          style={{ marginBottom: 5 }}
                        />
                      ))
                    )}
                  </div>
                </div>
              )}
            </Box>
          </div>
          <div
            className={`new__summary__container__inner__info ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            <div>
              <div
                className={`new__summary__container__inner__info__header ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                <div className="ticketIcon" />
                {isLanguageLoaded ? (
                  <span>{t("__checkout_order_new_summary_info_label")}</span>
                ) : (
                  <Skeleton variant="rect" width={"20%"} height={30} />
                )}
              </div>
              <div
                className={`new__summary__container__inner__info__content ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                <div
                  className={`new__summary__container__inner__info__card ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  }`}
                >
                  <div
                    className={`new__summary__container__inner__info__card__user ${
                      isTablet ? "tablet" : isMobile ? "mobile" : ""
                    }`}
                  >
                    <div
                      className={`new__summary__container__inner__info__card__user__image ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                    >
                      <div
                        className={`circle__avatar__wrapper ${
                          isTablet ? "tablet" : isMobile ? "mobile" : ""
                        } ${!isProfileImageLoaded ? "with__initials" : ""}`}
                      >
                        {!isLoadingEnabled ? (
                          isProfileImageLoaded ? (
                            <img
                              // src={
                              //   process.env.REACT_APP_STAGE === "dev"
                              //     ? `http://10.10.1.16:8082/imageProfile/image_profile${userId}.jpg?V=${Math.floor(
                              //         Math.random() * 100
                              //       )}`
                              //     : `https://www.zermatusa.com/imageProfile/image_profile${userId}.jpg?V=${Math.floor(
                              //         Math.random() * 100
                              //       )}`
                              // }
                              src={imageProfileUrl}
                              alt=""
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = AvatarPlaceholder;
                              }}
                            />
                          ) : (
                            <span>
                              {convertNameToInitials(
                                orderCloseType === "guest"
                                  ? `${guestInfoToCloseOrder.name} ${guestInfoToCloseOrder.lastName}`
                                  : `${clientInfoToCloseOrder.name} ${clientInfoToCloseOrder.lastName}`
                              )}
                            </span>
                          )
                        ) : (
                          <Skeleton
                            variant="circle"
                            width={"100%"}
                            height={80}
                          />
                        )}
                      </div>
                      <div
                        className={`new__summary__container__inner__info__card__user__name ${
                          isTablet ? "tablet" : isMobile ? "mobile" : ""
                        }`}
                      >
                        {!isLoadingEnabled && isLanguageLoaded ? (
                          <>
                            {" "}
                            <span className="shopping__payment__user__info__title">
                              {orderCloseType === "guest"
                                ? `${guestInfoToCloseOrder.name} ${guestInfoToCloseOrder.lastName}`
                                : `${clientInfoToCloseOrder.name} ${clientInfoToCloseOrder.lastName}`}
                            </span>
                            <span className="shopping__payment__user__info__subtitle">
                              {orderCloseType === "guest"
                                ? t("__checkout_guest_label")
                                : orderCloseType === "finalClient"
                                ? t("__checkout_customer_final_label")
                                : orderCloseType === "preferredClient"
                                ? t("__checkout_favorite_customer_label")
                                : t("__checkout_entrepreneur_label")}
                            </span>
                            {clientType === 1 &&
                              (isLanguageLoaded ? (
                                <span>
                                  {`${t("__general__entrepreneur__number")}: ${
                                    clientInfoToCloseOrder.clientId
                                  }`}
                                </span>
                              ) : (
                                <Skeleton
                                  variant="rect"
                                  width={"30%"}
                                  height={30}
                                />
                              ))}
                          </>
                        ) : (
                          <>
                            <Skeleton
                              variant="rect"
                              width={"30%"}
                              height={20}
                              style={{ marginBottom: 5 }}
                            />
                            <Skeleton
                              variant="rect"
                              width={"30%"}
                              height={20}
                              style={{ marginBottom: 5 }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`new__summary__container__inner__info__card__ticket ${
                      isTablet ? "tablet" : isMobile ? "mobile" : ""
                    }`}
                  >
                    <div
                      className={`new__summary__container__inner__info__card__ticket__info ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                    >
                      {!isLoadingEnabled && isLanguageLoaded ? (
                        <span>
                          {t("__checkout_order_new_summary_info_order_label")}{" "}
                          {order.orderId}
                        </span>
                      ) : (
                        <Skeleton
                          variant="rect"
                          width={"50%"}
                          height={20}
                          style={{ marginBottom: 10 }}
                        />
                      )}
                      {!isLoadingEnabled && isLanguageLoaded ? (
                        <span>
                          {t("__checkout_order_new_summary_info_auth_label")}{" "}
                          {order.aprobacion}
                        </span>
                      ) : (
                        <Skeleton
                          variant="rect"
                          width={"50%"}
                          height={20}
                          style={{ marginBottom: 10 }}
                        />
                      )}
                      {!isLoadingEnabled && isLanguageLoaded ? (
                        <span>
                          {t("__checkout_order_new_summary_info_date_label")}{" "}
                          {order.fecpedido}
                        </span>
                      ) : (
                        <Skeleton
                          variant="rect"
                          width={"50%"}
                          height={20}
                          style={{ marginBottom: 10 }}
                        />
                      )}
                    </div>
                    <div
                      className={`new__summary__container__inner__info__card__ticket__address ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                    >
                      {!isLoadingEnabled && isLanguageLoaded ? (
                        <span>
                          {t("__checkout_order_new_summary_info_contact_label")}{" "}
                          {orderCloseType === "guest"
                            ? guestInfoToCloseOrder?.phone
                            : clientInfoToCloseOrder?.cellPhone}
                        </span>
                      ) : (
                        <Skeleton
                          variant="rect"
                          width={"50%"}
                          height={20}
                          style={{ marginBottom: 10 }}
                        />
                      )}
                      {!isLoadingEnabled && isLanguageLoaded ? (
                        <span>
                          {t(
                            "__checkout_order_new_summary_info_address_delivery_label"
                          )}{" "}
                          {orderCloseType === "guest"
                            ? `${guestInfoToCloseOrder?.streetS} ${guestInfoToCloseOrder?.suiteS} ${guestInfoToCloseOrder?.cityS}, ${guestInfoToCloseOrder?.stateS} ZIP: ${guestInfoToCloseOrder?.zipS}`
                            : `${clientInfoToCloseOrder?.shippStreet} ${clientInfoToCloseOrder?.shippSuite} ${clientInfoToCloseOrder?.shippCity}, ${clientInfoToCloseOrder?.shippState} ZIP: ${clientInfoToCloseOrder?.shippZip}`}
                        </span>
                      ) : (
                        <Skeleton
                          variant="rect"
                          width={"50%"}
                          height={20}
                          style={{ marginBottom: 10 }}
                        />
                      )}
                      {/* {!isLoadingEnabled ? (
                        <span>
                          {t(
                            "__checkout_order_new_summary_info_address_fiscal_label"
                          )}{" "}
                          2245 W Holcombe Blvd Houston, TX 77030
                        </span>
                      ) : (
                        <Skeleton
                          variant="rect"
                          width={"50%"}
                          height={20}
                          style={{ marginBottom: 10 }}
                        />
                      )} */}
                    </div>
                    <div
                      className={`new__summary__container__inner__info__card__ticket__date__delivery ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                    >
                      {!isLoadingEnabled && isLanguageLoaded ? (
                        <span>
                          {t(
                            "__checkout_order_new_summary_info_date_delivery_label"
                          )}
                        </span>
                      ) : (
                        <Skeleton
                          variant="rect"
                          width={"50%"}
                          height={20}
                          style={{ marginBottom: 10 }}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className={`new__summary__container__inner__info__card__prices ${
                      isTablet ? "tablet" : isMobile ? "mobile" : ""
                    }`}
                  >
                    <div className="checkout__detail__wrapper__right__prices">
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        <div className="checkout__detail__wrapper__item">
                          {!isLoadingEnabled && isLanguageLoaded ? (
                            <div className="checkout__detail__wrapper__item__left">
                              {t("__checkout_order_subtotal")}
                            </div>
                          ) : (
                            <Skeleton
                              variant="rect"
                              width={"50%"}
                              height={20}
                              style={{
                                margin: "0px 20px 10px 20px",
                              }}
                            />
                          )}
                          {!isLoadingEnabled && isLanguageLoaded ? (
                            <div className="checkout__detail__wrapper__item__right">
                              $
                              {parseFloat(order.subtotal.toString()).toFixed(2)}
                            </div>
                          ) : (
                            <Skeleton
                              variant="rect"
                              width={"50%"}
                              height={20}
                              style={{
                                margin: "0px 20px 10px 20px",
                              }}
                            />
                          )}
                        </div>

                        <div className="checkout__detail__wrapper__item">
                          {!isLoadingEnabled && isLanguageLoaded ? (
                            <div className="checkout__detail__wrapper__item__left">
                              {t("__checkout_order_charges")}
                            </div>
                          ) : (
                            <Skeleton
                              variant="rect"
                              width={"50%"}
                              height={20}
                              style={{
                                margin: "0px 20px 10px 20px",
                              }}
                            />
                          )}
                          {!isLoadingEnabled && isLanguageLoaded ? (
                            <div className="checkout__detail__wrapper__item__right">
                              ${parseFloat(order.cargos.toString()).toFixed(2)}
                            </div>
                          ) : (
                            <Skeleton
                              variant="rect"
                              width={"50%"}
                              height={20}
                              style={{
                                margin: "0px 20px 10px 20px",
                              }}
                            />
                          )}
                        </div>

                        <div className="checkout__detail__wrapper__item">
                          {!isLoadingEnabled && isLanguageLoaded ? (
                            <div className="checkout__detail__wrapper__item__left">
                              {t("__checkout_order_taxes")}
                            </div>
                          ) : (
                            <Skeleton
                              variant="rect"
                              width={"50%"}
                              height={20}
                              style={{
                                margin: "0px 20px 10px 20px",
                              }}
                            />
                          )}
                          {!isLoadingEnabled && isLanguageLoaded ? (
                            <div className="checkout__detail__wrapper__item__right">
                              $
                              {parseFloat(order.importeimp.toString()).toFixed(
                                2
                              )}
                            </div>
                          ) : (
                            <Skeleton
                              variant="rect"
                              width={"50%"}
                              height={20}
                              style={{
                                margin: "0px 20px 10px 20px",
                              }}
                            />
                          )}
                        </div>
                        <div className="checkout__detail__wrapper__item">
                          {!isLoadingEnabled && isLanguageLoaded ? (
                            <div className="checkout__detail__wrapper__item__left">
                              {t("__checkout_order_credit")}
                            </div>
                          ) : (
                            <Skeleton
                              variant="rect"
                              width={"50%"}
                              height={20}
                              style={{
                                margin: "0px 20px 10px 20px",
                              }}
                            />
                          )}
                          {!isLoadingEnabled && isLanguageLoaded ? (
                            <div className="checkout__detail__wrapper__item__right">
                              ${parseFloat(order.abonos.toString()).toFixed(2)}
                            </div>
                          ) : (
                            <Skeleton
                              variant="rect"
                              width={"50%"}
                              height={20}
                              style={{
                                margin: "0px 20px 10px 20px",
                              }}
                            />
                          )}
                        </div>

                        {order.importeii !== order.importeii2 &&
                          isLanguageLoaded && (
                            <Fragment>
                              <div className="checkout__detail__wrapper__item">
                                <div
                                  style={{
                                    fontWeight: "normal",
                                    color: "dimgray",
                                  }}
                                  className="checkout__detail__wrapper__item__left__total"
                                >
                                  {order.hasOwnProperty("orderId") &&
                                  isLanguageLoaded ? (
                                    t("__general__total__with__membership")
                                  ) : (
                                    <Skeleton
                                      variant="rect"
                                      width={"100%"}
                                      height={10}
                                      style={{ marginBottom: 5 }}
                                    />
                                  )}
                                </div>
                                <div
                                  className="checkout__detail__wrapper__item__right__total"
                                  style={{
                                    fontWeight: "normal",
                                    color: "dimgray",
                                  }}
                                >
                                  {order.hasOwnProperty("orderId") &&
                                  isLanguageLoaded ? (
                                    <>
                                      {" "}
                                      ${parseFloat(order.importeii2).toFixed(2)}
                                    </>
                                  ) : (
                                    <Skeleton
                                      variant="rect"
                                      width={
                                        isMobile && !isTablet ? 200 : "100%"
                                      }
                                      height={10}
                                      style={{ marginBottom: 5 }}
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="checkout__detail__wrapper__item">
                                <div
                                  style={{
                                    fontWeight: "normal",
                                    color: "orange",
                                  }}
                                  className="checkout__detail__wrapper__item__left__total"
                                >
                                  {order.hasOwnProperty("orderId") &&
                                  isLanguageLoaded ? (
                                    t("__general__yout__saved")
                                  ) : (
                                    <Skeleton
                                      variant="rect"
                                      width={"100%"}
                                      height={10}
                                      style={{ marginBottom: 5 }}
                                    />
                                  )}
                                </div>
                                <div
                                  className="checkout__detail__wrapper__item__right__total"
                                  style={{
                                    fontWeight: "normal",
                                    color: "orange",
                                  }}
                                >
                                  {order.hasOwnProperty("orderId") &&
                                  isLanguageLoaded ? (
                                    <>
                                      {" "}
                                      $
                                      {parseFloat(
                                        order.importeii2 - order.importeii
                                      ).toFixed(2)}
                                    </>
                                  ) : (
                                    <Skeleton
                                      variant="rect"
                                      width={
                                        isMobile && !isTablet ? 200 : "100%"
                                      }
                                      height={10}
                                      style={{ marginBottom: 5 }}
                                    />
                                  )}
                                </div>
                              </div>
                            </Fragment>
                          )}

                        <div className="checkout__detail__wrapper__item">
                          {!isLoadingEnabled && isLanguageLoaded ? (
                            <div className="checkout__detail__wrapper__item__left__total">
                              {t("__checkout_order_total")}
                            </div>
                          ) : (
                            <Skeleton
                              variant="rect"
                              width={"50%"}
                              height={20}
                              style={{
                                margin: "0px 20px 10px 20px",
                              }}
                            />
                          )}
                          {!isLoadingEnabled && isLanguageLoaded ? (
                            <div className="checkout__detail__wrapper__item__right__total">
                              $
                              {parseFloat(order.importeii.toString()).toFixed(
                                2
                              )}
                            </div>
                          ) : (
                            <Skeleton
                              variant="rect"
                              width={"50%"}
                              height={20}
                              style={{
                                margin: "0px 20px 10px 20px",
                              }}
                            />
                          )}
                        </div>
                      </Typography>
                    </div>
                  </div>

                  <div
                    className={`new__summary__container__inner__info__card__vcard__wrapper ${
                      isMobile || isTablet ? "mobile" : ""
                    }`}
                  >
                    {vCardWebPageEntreprenourData.length > 0 &&
                      isLanguageLoaded &&
                      entreprenourWebPageData.hasOwnProperty("nameClient") && (
                        <div className="new__summary__container__inner__info__card__vcard__wrapper__inner">
                          <div className="new__summary__container__inner__info__card__vcard__wrapper__inner__title">
                            <span>{t("__summary__vcard__main__title")}</span>
                          </div>

                          <div className="new__summary__container__inner__info__card__vcard__wrapper__inner__data">
                            <div className="new__summary__container__inner__info__card__vcard__wrapper__inner__data__image__wrapper">
                              <img
                                src={entreprenourWebPageData?.imageProfile}
                                alt=""
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = AvatarPlaceholder;
                                }}
                              />
                            </div>
                            <div className="new__summary__container__inner__info__card__vcard__wrapper__inner__data__user__info">
                              <span className="new__summary__container__inner__info__card__vcard__wrapper__inner__data__user__info__title">{`${t(
                                "__summary__vcard__title__hi"
                              )} ${entreprenourWebPageData?.nameClient}`}</span>
                              <span className="new__summary__container__inner__info__card__vcard__wrapper__inner__data__user__info__subtitle">
                                {t("__summary__vcard__text__pt1")}{" "}
                                {orderCloseType === "guest"
                                  ? `${guestInfoToCloseOrder.name}`
                                  : `${clientInfoToCloseOrder.name}`}{" "}
                                {t("__summary__vcard__text__pt2")}
                                <span className="text__in__red">
                                  {t("__summary__vcard__text__pt3")}
                                </span>
                                {t("__summary__vcard__text__pt4")}
                              </span>
                            </div>
                            <div className="new__summary__container__inner__info__card__vcard__wrapper__inner__data__qr__wrapper">
                              <QRCode
                                size={180}
                                value={vCardWebPageEntreprenourData}
                                viewBox={`0 0 180 180`}
                                bgColor={"transparent"}
                              />
                            </div>
                          </div>

                          {/* <div
                            style={{
                              height: "auto",
                              margin: "0 auto",
                              maxWidth: 180,
                              width: "100%",
                            }}
                          >
                            <QRCode
                              size={180}
                              style={{
                                height: "auto",
                                maxWidth: "100%",
                                width: "100%",
                              }}
                              value={vCardWebPageEntreprenourData}
                              viewBox={`0 0 180 180`}
                            />
                          </div>
                          {console.log(
                            "entreprenourWebPageData",
                            entreprenourWebPageData
                          )}
                          <div>
                            <p>{entreprenourWebPageData.nameClient}</p>
                            <p>Aqui el texto shingon de soy la mera mera XD.</p>
                          </div> */}
                        </div>
                      )}
                    {vCardSponsorSelection.length > 0 &&
                      sponsorSelectionData.hasOwnProperty("name") &&
                      isLanguageLoaded && (
                        <div className="new__summary__container__inner__info__card__vcard__wrapper__inner">
                          <div className="new__summary__container__inner__info__card__vcard__wrapper__inner__title">
                            <span>{t("__summary__vcard__main__title")}</span>
                          </div>

                          <div className="new__summary__container__inner__info__card__vcard__wrapper__inner__data">
                            <div className="new__summary__container__inner__info__card__vcard__wrapper__inner__data__image__wrapper">
                              <img
                                src={sponsorSelectionData?.imageProfile}
                                alt=""
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = AvatarPlaceholder;
                                }}
                              />
                            </div>
                            <div className="new__summary__container__inner__info__card__vcard__wrapper__inner__data__user__info">
                              <span className="new__summary__container__inner__info__card__vcard__wrapper__inner__data__user__info__title">{`${t(
                                "__summary__vcard__title__hi"
                              )} ${sponsorSelectionData?.name} ${
                                sponsorSelectionData?.lastName
                              }`}</span>
                              <span className="new__summary__container__inner__info__card__vcard__wrapper__inner__data__user__info__subtitle">
                                {t("__summary__vcard__text__pt1")}{" "}
                                {orderCloseType === "guest"
                                  ? `${guestInfoToCloseOrder.name}`
                                  : `${clientInfoToCloseOrder.name}`}{" "}
                                {t("__summary__vcard__text__pt2")}
                                <span className="text__in__red">
                                  {t("__summary__vcard__text__pt3")}
                                </span>
                                {t("__summary__vcard__text__pt4")}
                              </span>
                            </div>
                            <div className="new__summary__container__inner__info__card__vcard__wrapper__inner__data__qr__wrapper">
                              <QRCode
                                size={180}
                                value={vCardSponsorSelection}
                                viewBox={`0 0 180 180`}
                                bgColor={"transparent"}
                              />
                            </div>
                          </div>

                          {/* <div
                        style={{
                          height: "auto",
                          margin: "0 auto",
                          maxWidth: 180,
                          width: "100%",
                        }}
                      >
                        <QRCode
                          size={180}
                          style={{
                            height: "auto",
                            maxWidth: "100%",
                            width: "100%",
                          }}
                          value={vCardWebPageEntreprenourData}
                          viewBox={`0 0 180 180`}
                        />
                      </div>
                      {console.log(
                        "entreprenourWebPageData",
                        entreprenourWebPageData
                      )}
                      <div>
                        <p>{entreprenourWebPageData.nameClient}</p>
                        <p>Aqui el texto shingon de soy la mera mera XD.</p>
                      </div> */}
                        </div>
                      )}
                  </div>

                  {vCardSponsorSelection.length > 0 &&
                    sponsorSelectionData.hasOwnProperty("name") &&
                    isLanguageLoaded && (
                      <div className="new__summary__container__inner__info__card__mlm__instructions">
                        <span className="new__summary__container__inner__info__card__mlm__instructions__title">
                          {t("__summary__instructions__title")}
                        </span>
                        <span>{t("__summary__instructions__step1")}</span>
                        <span>{t("__summary__instructions__step2")}</span>
                        <span>
                          {t("__summary__instructions__step3")}
                          <a
                            href="https://www.websizusa.net/zermatbusiness"
                            target={"_blank"}
                            rel={"noreferrer"}
                          >
                            www.zermatbusiness.com
                          </a>
                        </span>
                        <span>{t("__summary__instructions__step4")}</span>
                      </div>
                    )}
                </div>
              </div>
              <div
                className={`new__summary__container__footer ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                <div
                  className={`new__summary__container__footer__warning ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  }`}
                >
                  {isLanguageLoaded ? (
                    <Fragment>
                      <span>
                        {t(
                          "__checkout_order_new_summary_info_warning_one_label"
                        )}
                      </span>
                      <span>
                        {t(
                          "__checkout_order_new_summary_info_warning_two_label"
                        )}
                      </span>
                    </Fragment>
                  ) : (
                    <Skeleton variant="rect" width={"90%"} height={150} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`new__summary__container__footer ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          <Button
            className="button__with__icon bg__dark__pink no__left__margin"
            onClick={() => {
              //setIsClickContinue(true);
              //handleFinishBuy();
              history.push("/categories");
            }}
          >
            <div className="button__with__icon__svg__wrapper dark__pink__ball">
              <div className="bagWhiteIcon" />
            </div>
            <span>{isLanguageLoaded ? t("btn_text_continue_shop") : ""}</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(NewSummaryShoppingContainer);
