import {
  CLEAN_PAYMENT_REDUCER,
  REQUEST_STRIPE_PAYMENT,
  REQUEST_STRIPE_PAYMENT2023,
} from "./../../Constants";

export const requestStripePayment = (
  receiptEmail,
  cardNumber,
  cardExpirationDate,
  cardCvc,
  orderId
) => {
  const model = {
    amount: 0,
    currency: "usd",
    paymentMethodsTypes: ["card"],
    receiptEmail,
    cardNumber,
    cardExpirationDate,
    cardCvc,
  };
  return {
    type: REQUEST_STRIPE_PAYMENT,
    payload: {
      model,
      orderId,
    },
  };
};

export const requestStripePayment2023 = (
  receiptEmail,
  cardNumber,
  cardExpirationDate,
  cardCvc,
  orderId,
  benefitType,
  evaluatept = true
) => {
  let sessionId = 0;
  if (localStorage.getItem("ZUsaUInfo")) {
    sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
  }
  const stripePaymentModel = {
    amount: 0,
    currency: "usd",
    paymentMethodsTypes: ["card"],
    receiptEmail,
    cardNumber,
    cardExpirationDate,
    cardCvc,
  };
  const closeOrderModel = {
    sessionId,
    evaluatept,
    benefitType,
  };
  return {
    type: REQUEST_STRIPE_PAYMENT2023,
    payload: {
      stripePaymentModel,
      orderId,
      closeOrderModel,
    },
  };
};

export const requestCleanPaymentReducer = () => {
  return {
    type: CLEAN_PAYMENT_REDUCER,
    payload: {},
  };
};
