import { Skeleton } from "@mui/material";
import React from "react";
import { isMobile, isTablet } from "react-device-detect";

const HeaderNewMembers = ({ backColor, t, isLanguageLoaded }) => {
  return (
    <div
      style={{
        backgroundColor: `${backColor}`,
        display: "flex",
        width: "100%",
        alignItems: "center",
      }}
      className={`header__new__member__main ${
        isTablet ? "tablet" : isMobile ? "mobile" : ""
      }`}
    >
      <div
        className={`header__new__member__main__wrapper ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }`}
      >
        <div
          className={`header__new__member__main__image ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          {!isLanguageLoaded ? (
            <Skeleton
              variant="rect"
              width={500}
              height={200}
              animation="wave"
            />
          ) : (
            <img src="https://www.websizusa.net/imgprod/zu/modules/14/83/3/Imagen_header_web.jpg" />
          )}
        </div>
        <div
          className={`header__new__member__main__message ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          {!isLanguageLoaded ? (
            <Skeleton
              variant="rounded"
              width={isMobile ? 320 : 410}
              height={30}
              style={{ marginBottom: 10 }}
              animation="wave"
            />
          ) : (
            <span
              className={`header__new__member__main__message__title ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              {t("__header__new__members__title")}
            </span>
          )}
          {!isLanguageLoaded ? (
            <Skeleton
              variant="rounded"
              width={isMobile ? 320 : 410}
              height={30}
              style={{ marginBottom: 10 }}
              animation="wave"
            />
          ) : (
            <span
              className={`header__new__member__main__message__subtitle ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              {t("__header__new__members__subtitle")}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderNewMembers;
