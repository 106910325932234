import {
  RESPONSE_STRIPE_PAYMENT,
  RESPONSE_STRIPE_PAYMENT2023,
} from "../../Constants";

export default function payment(state = {}, action) {
  switch (action.type) {
    case RESPONSE_STRIPE_PAYMENT: {
      const responseStripePayment = action.payload.response;
      if (responseStripePayment.code !== 0) {
        return { ...state, responseStripePayment: responseStripePayment };
      } else {
        console.error("error al logear al cliente", responseStripePayment);
        return { ...state, errorResponseStripePayment: true };
      }
    }
    case RESPONSE_STRIPE_PAYMENT2023: {
      const responseStripePayment = action.payload.response;
      if (responseStripePayment.code !== 0) {
        return { ...state, responseStripePayment: responseStripePayment };
      } else {
        console.error(
          "Error al hacer el pago con Stripe",
          responseStripePayment
        );
        return { ...state, errorResponseStripePayment: true };
      }
    }

    default:
      return { ...state };
  }
}
