import { call, put, takeLatest } from "redux-saga/effects";
import {
  REQUEST_CANCELLATION_COMPLETE,
  RESPONSE_CANCELLATION_COMPLETE,
  REQUEST_GET_REASONS_RETURN,
  RESPONSE_GET_REASONS_RETURN,
  REQUEST_GET_EXCHANGE_RATE,
  RESPONSE_GET_EXCHANGE_RATE,
  REQUEST_FULL_REFUND,
  RESPONSE_FULL_REFUND,
  REQUEST_PARTIAL_REFUND,
  RESPONSE_PARTIAL_REFUND,
  DATA_FETCH_FAILED,
} from "./../../Constants";
import RepaymentModule from '../../../../Libraries/Repayment'

function* requestCancellationComplete(data) {
  try {    
    const response = yield call(RepaymentModule.SetCompleteCancellation, data.payload.orderId, data.payload.sessionId);
    yield put({
      type: RESPONSE_CANCELLATION_COMPLETE,
      payload: { response: response.repayment_setCompleteCancellation },
    });
  } catch (error) {
    console.error("get admission kits product error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

function* requestGetReasonsReturn() {
  try {
    const response = yield call(RepaymentModule.GetReasonsReturn);
    yield put({
      type: RESPONSE_GET_REASONS_RETURN,
      payload: { response: response.repayment_GetReasonsReturn },
    });
  } catch (error) {
    console.error("get catalogo de las razon", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

function* requestGetExchangeRate() {
  try {
    const response = yield call(RepaymentModule.GetExchangeRate);
    yield put({
      type: RESPONSE_GET_EXCHANGE_RATE,
      payload: { response: response.repayment_GetExchangeRate },
    });
  } catch (error) {
    console.error("get catalogo de tipo de cambio", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

function* requestFullRefund(data) {
  try {
    const response = yield call(RepaymentModule.SetFullRefund, data.payload.orderId, data.payload.sessionId, data.payload.exchangeType, data.payload.refoundReason, data.payload.refoundComment);
    yield put({
      type: RESPONSE_FULL_REFUND,
      payload: { response: response.repayment_setFullRefund },
    });
  } catch (error) {
    console.error("get admission kits product error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

function* requestPartialRefund(data) {
  try {
    const response = yield call(RepaymentModule.SetPartialRefund, data.payload.orderId, data.payload.sessionId, data.payload.articlesReturnModel );
    yield put({
      type: RESPONSE_PARTIAL_REFUND,
      payload: { response: response.repayment_setPartialRefund },
    });
  } catch (error) {
    console.error("get admission kits product error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}


export function* watchRequestCancellationComplete() {
  yield takeLatest(REQUEST_CANCELLATION_COMPLETE, requestCancellationComplete);
}

export function* watchRequestGetReasonsReturn() {
  yield takeLatest(REQUEST_GET_REASONS_RETURN, requestGetReasonsReturn);
}

export function* watchRequestGetExchangeRate() {
  yield takeLatest(REQUEST_GET_EXCHANGE_RATE, requestGetExchangeRate);
}

export function* watchRequestFullRefund() {
  yield takeLatest(REQUEST_FULL_REFUND, requestFullRefund);
}

export function* watchRequestPartialRefund() {
  yield takeLatest(REQUEST_PARTIAL_REFUND, requestPartialRefund);
}