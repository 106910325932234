import { 
    RESPONSE_CANCELLATION_COMPLETE,
    RESPONSE_GET_REASONS_RETURN,
    RESPONSE_GET_EXCHANGE_RATE,
    RESPONSE_FULL_REFUND,
    RESPONSE_PARTIAL_REFUND

} from "../../Constants";

export default function repayment(state = {}, action) {
  switch (action.type) {

    case RESPONSE_GET_REASONS_RETURN:
      {
          const reasonsReturn = action.payload.response;
          if(reasonsReturn.code === 100){
              return { ...state, reasonsReturn: reasonsReturn,errorReasonsReturn: false}
          }else{
              console.error("Error al solicitar el cataogo de las razon para la devolucion", reasonsReturn)
              return { ...state, errorReasonsReturn: true}
          }
      }
    
    case RESPONSE_GET_EXCHANGE_RATE:
      {
        const exchangeRate = action.payload.response;
          if(exchangeRate.code === 100){
            return { ...state, exchangeRate: exchangeRate,errorExchangeRate: false}
          }else{
            console.error("Error al solicitar el cataogo de las razon para la devolucion", exchangeRate)
            return { ...state, errorExchangeRate: true}
          }
      }

    case RESPONSE_CANCELLATION_COMPLETE:
      {
        const cancellationComplete = action.payload.response;
        if (cancellationComplete.code === 100) {
          return {
            ...state,
            cancellationComplete: cancellationComplete,
            errorCancellationComplete: false,
          };
        } else {
          return { ...state, errorCancellationComplete: true };
        }
      }

    case RESPONSE_FULL_REFUND:
      {
        const fullRefund = action.payload.response;
        if (fullRefund.code === 100) {
          return {
            ...state,
            errorLoadrepayment: false, fullRefund: fullRefund
          };
        } else {
          return { ...state, errorLoadrepayment: true };
        }
      }

    case RESPONSE_PARTIAL_REFUND:
      {
        const partialRefund = action.payload.response;
        if (partialRefund.code === 100) {
          return {
            ...state,
            errorLoadrepayment: false, partialRefund: partialRefund
          };
        } else {
          return { ...state, errorLoadrepayment: true };
        }
      }

    default:
      return { ...state };
  }
}
