import { call, put, takeLatest } from "redux-saga/effects";
import {
  REQUEST_HOME_MODULE_INFO,
  RESPONSE_HOME_MODULE_INFO,
  REQUEST_ENTREPENOUR_HOME_MODULE_INFO,
  RESPONSE_ENTREPENOUR_HOME_MODULE_INFO,
  DATA_FETCH_FAILED,
  RESPONSE_CLIENT_PREFERENT_MODULE,
  RESPONSE_ENTERPRENOUR_MODULE,
  RESPONSE_ZCARE_MODULE
} from "../../Constants";
import HomeModule from "../../../../Libraries/Home";
import RepaymentModule from "../../../../Libraries/Repayment";

function* requestHomeModuleInfo() {
  try {
    const response = yield call(HomeModule.GetHomeModule);
    const responsePreferentClient = yield call(
      HomeModule.GetPreferentClientModule
    );
    const responseEnterprenour = yield call(HomeModule.GetEnterprenourZModule);
    const responseZcare = yield call(HomeModule.GetZCareModule);

    if (response && typeof response.getModules === "object") {
      yield put({
        type: RESPONSE_HOME_MODULE_INFO,
        payload: { response: response.getModules },
      });
    }

    if (
      responsePreferentClient &&
      typeof responsePreferentClient.getModules === "object"
    ) {
      yield put({
        type: RESPONSE_CLIENT_PREFERENT_MODULE,
        payload: { response: responsePreferentClient.getModules },
      });
    }

    if (
      responseEnterprenour &&
      typeof responseEnterprenour.getModules === "object"
    ) {
      yield put({
        type: RESPONSE_ENTERPRENOUR_MODULE,
        payload: { response: responseEnterprenour.getModules },
      });
    }

    if (
      responseZcare &&
      typeof responseZcare.getModules === "object"
    ) {
      yield put({
        type: RESPONSE_ZCARE_MODULE,
        payload: { response: responseZcare.getModules },
      });
    }
  } catch (error) {
    console.error("load home module error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}
function* requestEntrepenourHomeModuleInfo(data) {
  try {
    const response = yield call(
      HomeModule.GetEntrepenourHomeModule,
      data.payload.sessionId
    );
    const responsePreferentClient = yield call(
      HomeModule.GetPreferentClientModule
    );
    const responseEnterprenour = yield call(HomeModule.GetEnterprenourZModule);
    const responseZcare = yield call(HomeModule.GetZCareModule);

    if (response && typeof response.getEntrepenourModules === "object") {
      yield put({
        type: RESPONSE_ENTREPENOUR_HOME_MODULE_INFO,
        payload: { response: response.getEntrepenourModules },
      });
    }

    if (
      responsePreferentClient &&
      typeof responsePreferentClient.getModules === "object"
    ) {
      yield put({
        type: RESPONSE_CLIENT_PREFERENT_MODULE,
        payload: { response: responsePreferentClient.getModules },
      });
    }
    if (
      responseEnterprenour &&
      typeof responseEnterprenour.getModules === "object"
    ) {
      yield put({
        type: RESPONSE_ENTERPRENOUR_MODULE,
        payload: { response: responseEnterprenour.getModules },
      });
    }

    if (
      responseZcare &&
      typeof responseZcare.getModules === "object"
    ) {
      yield put({
        type: RESPONSE_ZCARE_MODULE,
        payload: { response: responseZcare.getModules },
      });
    }
  } catch (error) {
    console.error("load home module error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

export function* watchRequestHomeModuleInfo() {
  yield takeLatest(REQUEST_HOME_MODULE_INFO, requestHomeModuleInfo);
}
export function* watchRequestEntrepenourHomeModuleInfo() {
  yield takeLatest(
    REQUEST_ENTREPENOUR_HOME_MODULE_INFO,
    requestEntrepenourHomeModuleInfo
  );
}
