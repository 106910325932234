import { call, put, takeLatest, all } from 'redux-saga/effects'
import {
    REQUEST_TRPREX_PRODUCTS, 
    RESPONSE_TRPREX_PRODUCTS, 
    REQUEST_FAVORITES, 
    RESPONSE_FAVORITES, 
    REQUEST_ALL_PRODUCTS,
    RESPONSE_ALL_PRODUCTS,
    RESPONSE_ALL_CATEGORIES,
    DATA_FETCH_FAILED,
    RESPONSE_SET_PRODUCT_ON_FAVORITES,
    REQUEST_SET_PRODUCT_ON_FAVORITES,
    RESPONSE_GET_ZCARE_PRODUCT,
    REQUEST_GET_ZCARE_PRODUCT,
    RESPONSE_GET_SETUP_KIT_COMPONENTS,
    REQUEST_GET_SETUP_KIT_COMPONENTS,
    RESPONSE_GET_ADMISSION_KITS,
    REQUEST_GET_ADMISSION_KITS,
    REQUEST_ALL_PRODUCTS_BY_LANG,
    RESPONSE_ALL_PRODUCTS_BY_LANG,
    REQUEST_ADD_SET_CATEGORIES_REDUCER,
    RESPONSE_ADD_SET_CATEGORIES_REDUCER,
    REQUEST_REMOVE_SET_CATEGORIES_REDUCER,
    RESPONSE_REMOVE_SET_CATEGORIES_REDUCER,
    REQUEST_ALL_PRODUCTS_ASYNC,
    RESPONSE_ALL_PRODUCTS_ASYNC,
    REQUEST_ALL_CAREGORIES_ASYNC,
    RESPONSE_ALL_CAREGORIES_ASYNC,
    REQUEST_GET_BEAUTY_BOXES,
    RESPONSE_GET_BEAUTY_BOXES,
    REQUEST_ALL_PRODUCT_FAMILIES_ASYNC,
    RESPONSE_ALL_PRODUCT_FAMILIES_ASYNC,
} from '../../Constants'
import ProductsSections from '../../../../Libraries/Products'



function* requestTrPrExProducts(){
    try {
        const response = yield call(ProductsSections.GetPrTrExProducts);
        yield put({type: RESPONSE_TRPREX_PRODUCTS, payload: {response:response.getProductsSections}});

    } catch (error) {
        console.error("load products tr pr ex error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}
function* requestFavorites(data){
    try {
        const response = yield call(ProductsSections.GetFavorites,data.payload.clientId);
        yield put({type: RESPONSE_FAVORITES, payload: {response:response.getProductsSections}});

    } catch (error) {
        console.error("load favorites products error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}
function* requestAllProducts(){
    try {
        //const response = yield call(ProductsSections.GetAllProducts);
        // yield put({type: RESPONSE_ALL_PRODUCTS, payload: {response:response.getCategoriesAndProducts}});
        // yield put({type: RESPONSE_ALL_CATEGORIES, payload: {response:response.getCategoriesAndProducts}});

        const response = yield call(ProductsSections.GetAllProductsContent);
        yield put({type: RESPONSE_ALL_PRODUCTS, payload: {response:response.getAllProductsContent}});
    } catch (error) {
        console.error("load all products error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}
function* requestSetProductOnFavorite(data){
    try {
        const {clientId, itemCode, typeLine, isFavorite, withNonStock} = data.payload;
        const response = yield call(ProductsSections.SetProductOnFavorite, clientId, itemCode, typeLine, isFavorite, withNonStock);
        yield put({type: RESPONSE_SET_PRODUCT_ON_FAVORITES, payload: {response: response.setFavoriteProduct, modelFavoriteSended: data.payload }});
    } catch (error) {
        console.error("Set product on Favorites error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}
function* requestGetZcareProduct(){
    try {
        const response = yield call(ProductsSections.GetZCareProduct);
        yield put({type: RESPONSE_GET_ZCARE_PRODUCT, payload: {response: response.getZermatCareProduct }});
    } catch (error) {
        console.error("get zcare product error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}
function* requestGetSetupKitComponents(data){
    try {
        const response = yield call(ProductsSections.GetSetUpKitComponents, data.payload.itemCode);
        yield put({type: RESPONSE_GET_SETUP_KIT_COMPONENTS, payload: {response: response.getSetComponents }});
    } catch (error) {
        console.error("get components kit product error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}
function* requestGetAdmissionKits(){
    try {
        const response = yield call(ProductsSections.GetAdmissionKit);
        yield put({type: RESPONSE_GET_ADMISSION_KITS, payload: {response: response.getAdmissionKits }});
    } catch (error) {
        console.error("get admission kits product error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}
function* requestGetBeautyBoxes(){
    try {
        const response = yield call(ProductsSections.GetBeautyBoxes);
        yield put({type: RESPONSE_GET_BEAUTY_BOXES, payload: {response: response.getBeautyBoxes }});
    } catch (error) {
        console.error("get admission kits product error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}
function* requestAllProductsByLang(data){
    try {
        const responseAllCategories = yield call(ProductsSections.GetAllCategoriesByLanguage, data.payload.idLang);
        if (responseAllCategories && typeof responseAllCategories.getAllCategoriesByLanguage === 'object' ) {
            yield put({type: RESPONSE_ALL_CATEGORIES, payload: {response:responseAllCategories.getAllCategoriesByLanguage,idLang:data.payload.idLang}});
        }

        //const responseAllProducts = yield call(ProductsSections.GetAllProductsByLanguage, data.payload.idLang);        
        //  if (responseAllProducts && typeof responseAllProducts.getAllProductsByLanguage === 'object' ) {
        //      yield put({type: RESPONSE_ALL_PRODUCTS_BY_LANG, payload: {response:responseAllProducts.getAllProductsByLanguage,idLang:data.payload.idLang}});
        //  }        
    } catch (error) {
        console.error("load all products error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}
function* requestAddSetCategoriesReducer(){
    try {
        yield put({type: RESPONSE_ADD_SET_CATEGORIES_REDUCER})
    } catch (error) {
        console.error("load all products error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}
function* requestRemoveSetCategoriesReducer(){
    try {
        yield put({type: RESPONSE_REMOVE_SET_CATEGORIES_REDUCER})
    } catch (error) {
        console.error("load all products error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}

////TEST ASYNC\\\\
function* requestAllProductsAsync() {
    try {       

        const [prodEsp, prodEng, comments, related, bought] = yield all([
            call(ProductsSections.GetProductsByLangAsync,1),
            call(ProductsSections.GetProductsByLangAsync,2),
            call(ProductsSections.GetCommentsProductsAsync),
            call(ProductsSections.GetRelatedProductsAsync),
            call(ProductsSections.GetBoughtProductsAsync),
            
        ]);
        yield put({type: RESPONSE_ALL_PRODUCTS_ASYNC, payload : {prodEsp:prodEsp,prodEng:prodEng , comments: comments, related:related, bought:bought}});
    } catch (error) {
        console.error("load all products error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}
function* requestAllCaregoriesAsync() {
    try {
        const [catEsp, catEng] = yield all([
            call(ProductsSections.GetAllCategoriesByLanguage, 1),
            call(ProductsSections.GetAllCategoriesByLanguage, 2)
        ]);

        yield put({type: RESPONSE_ALL_CAREGORIES_ASYNC, payload : {catEsp:catEsp,catEng:catEng }});
    } catch (error) {
        console.error("load all products error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}

function* requestAllProductFamiliesAsync() {
    try {
        const [familyEsp,familyEng,tagOffers,imagesArticle] = yield all([
            call(ProductsSections.GetFamilyProductsAsync,1),
            call(ProductsSections.GetFamilyProductsAsync,2),
            //
            call(ProductsSections.GetOffersTagsAsync),
            call(ProductsSections.GetAllImageArticlesAsync),

        ]);
        yield put({type: RESPONSE_ALL_PRODUCT_FAMILIES_ASYNC, payload : { familyEsp:familyEsp, familyEng:familyEng, tagOffers:tagOffers, imagesArticle:imagesArticle }});
    } catch (error) {
        console.error("load all products error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}

export function* watchRequestTrPrExProducts() {
    yield takeLatest(REQUEST_TRPREX_PRODUCTS, requestTrPrExProducts);
}
export function* watchRequestFavorites() {
    yield takeLatest(REQUEST_FAVORITES, requestFavorites);
}
export function* watchRequestAllProducts() {
    yield takeLatest(REQUEST_ALL_PRODUCTS, requestAllProducts);
}
export function* watchRequestSetProductOnFavorites(){
    yield takeLatest(REQUEST_SET_PRODUCT_ON_FAVORITES, requestSetProductOnFavorite);
}
export function* watchRequestGetZcareProduct(){
    yield takeLatest(REQUEST_GET_ZCARE_PRODUCT, requestGetZcareProduct);
}
export function* watchRequestGetSetupKitComponents(){
    yield takeLatest(REQUEST_GET_SETUP_KIT_COMPONENTS, requestGetSetupKitComponents);
}
export function* watchRequestGetAdmissionKits(){
    yield takeLatest(REQUEST_GET_ADMISSION_KITS, requestGetAdmissionKits);
}
export function* watchRequestGetBeautyBoxes(){
    yield takeLatest(REQUEST_GET_BEAUTY_BOXES, requestGetBeautyBoxes);
}
export function* watchRequestAllProductsByLang(){
    yield takeLatest(REQUEST_ALL_PRODUCTS_BY_LANG, requestAllProductsByLang);
}
export function* watchRequestAddSetCategoriesReducer(){
    yield takeLatest(REQUEST_ADD_SET_CATEGORIES_REDUCER, requestAddSetCategoriesReducer);
}
export function* watchRequestRemoveSetCategoriesReducer(){
    yield takeLatest(REQUEST_REMOVE_SET_CATEGORIES_REDUCER, requestRemoveSetCategoriesReducer);
}
export function* watchRequestAllProductsAsync(){
    yield takeLatest(REQUEST_ALL_PRODUCTS_ASYNC, requestAllProductsAsync);
}
export function* watchRequestAllCaregoriesAsync(){
    yield takeLatest(REQUEST_ALL_CAREGORIES_ASYNC, requestAllCaregoriesAsync);
}
export function* watchRequestAllProductFamiliesAsync(){
    yield takeLatest(REQUEST_ALL_PRODUCT_FAMILIES_ASYNC, requestAllProductFamiliesAsync);
}