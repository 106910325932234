import { call, put, takeLatest } from 'redux-saga/effects'
import {
    REQUEST_REFERRER_CELLPHONE_VALIDATION,
    REQUEST_REFERRER_EMAIL_VALIDATION,
    RESPONSE_REFERRER_CELLPHONE_VALIDATION,
    RESPONSE_REFERRER_EMAIL_VALIDATION,
    REQUEST_REFERRER_NEW_REGISTER,
    RESPONSE_REFERRER_NEW_REGISTER,
    REQUEST_REFERRER_CODE,
    REQUEST_REFERRER_CODE_SENDED_LOG,
    RESPONSE_REFERRER_CODE,
    RESPONSE_REFERRER_CODE_SENDED_LOG,
    REQUEST_REFERRER_LIST,
    RESPONSE_REFERRER_LIST,
    DATA_FETCH_FAILED,
} from './../../Constants'
import Client from '../../../../Libraries/Client'

function* requestReferrerEmailValidation(data){
    try {
        const response = yield call(Client.ValidateEmailReferrer, data.payload.email);
        yield put({type: RESPONSE_REFERRER_EMAIL_VALIDATION, payload: {response:response.validateEmailReferred}});

    } catch (error) {
        console.error("referrer email validation error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}

function* requestReferrerCellPhoneValidation(data){
    try {
        const response = yield call(Client.ValidateCellPhoneReferrer, data.payload.cellPhone);
        yield put({type: RESPONSE_REFERRER_CELLPHONE_VALIDATION, payload: {response:response.validateCellPhoneReferred}});

    } catch (error) {
        console.error("referrer cellPhone validation error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}

function* requestReferrerNewRegister(data){
    try {
        const response = yield call(Client.RegisterNewReferrer, data.payload.referred);
        yield put({type: RESPONSE_REFERRER_NEW_REGISTER, payload: {response:response.addNewReferredClient}});

    } catch (error) {
        console.error("referrer registration error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}

function* requestReferrerCode(data){
    try {
        const response = yield call(Client.GetReferrerCode, data.payload.sessionId);
        yield put({type: RESPONSE_REFERRER_CODE, payload: {response:response.getReferrerCode}});

    } catch (error) {
        console.error("referrer get Code error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}

function* requestReferrerCodeSendedLog(data){
    try {
        const response = yield call(Client.LogReferrerCodeSended, data.payload.sendedCodeModel);
        yield put({type: RESPONSE_REFERRER_CODE_SENDED_LOG, payload: {response:response.insertReferrerCodeSended}});

    } catch (error) {
        console.error("referrer code sended log error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}

function* requestReferrerList(data){
    try {
        const response = yield call(Client.GetReferrerListByReferrerCode, data.payload.referrerCode);
        yield put({type: RESPONSE_REFERRER_LIST, payload: {response:response.getReferrersByCodeList}});

    } catch (error) {
        console.error("get referrer list error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}


export function* watchRequestReferrerEmailValidation() {
    yield takeLatest(REQUEST_REFERRER_EMAIL_VALIDATION, requestReferrerEmailValidation);
}

export function* watchRequestReferrerCellPhoneValidation() {
    yield takeLatest(REQUEST_REFERRER_CELLPHONE_VALIDATION, requestReferrerCellPhoneValidation);
}

export function* watchRequestReferrerNewRegister() {
    yield takeLatest(REQUEST_REFERRER_NEW_REGISTER, requestReferrerNewRegister);
}
export function* watchRequestReferrerCode() {
    yield takeLatest(REQUEST_REFERRER_CODE, requestReferrerCode);
}

export function* watchRequestReferrerCodeSendedLog() {
    yield takeLatest(REQUEST_REFERRER_CODE_SENDED_LOG, requestReferrerCodeSendedLog);
}

export function* watchRequestReferrerList(){
    yield takeLatest(REQUEST_REFERRER_LIST, requestReferrerList);
}
