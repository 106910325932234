import React, { Fragment, useState, useEffect } from "react";
import { isIOS, isMobile, isTablet } from "react-device-detect";
import {
  IconButton,
  Button,
  FormControl,
  Skeleton,
  Avatar,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import PayPalButton from "./../../../../Libraries/Utils/PayPalButton";
import {
  formatNumberWithCommas,
  formatPrice,
} from "./../../../../Libraries/Utils/utils";

import TextFieldNewMembers from "./TextFieldNewMembers";
import QRCode from "react-qr-code";

import paypalIcon from "../../../../assets/media/images/NewMembers/paypal_icon.png";
import creditIconEs from "../../../../assets/media/images/NewMembers/credit_icon_esp.png";
import creditIconEn from "../../../../assets/media/images/NewMembers/credit_icon_en.png";
import bagBlackIcon from "../../../../assets/media/images/NewMembers/bag_black_icon.png";
import thanksImage from "../../../../assets/media/images/NewMembers/floating_thanks_page.jpg";
import qrExample from "../../../../assets/media/images/NewMembers/qrExample.png";
import { useTranslation } from "react-i18next";

const InitialCreditCardSVG = () => {
  return (
    <svg
      focusable="false"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 21"
    >
      <g fill="none" fillRule="evenodd">
        <g className="Icon-fill">
          <g transform="translate(0 2)">
            <path
              d="M26.58 19H2.42A2.4 2.4 0 0 1 0 16.62V2.38A2.4 2.4 0 0 1 2.42 0h24.16A2.4 2.4 0 0 1 29 2.38v14.25A2.4 2.4 0 0 1 26.58 19zM10 5.83c0-.46-.35-.83-.78-.83H3.78c-.43 0-.78.37-.78.83v3.34c0 .46.35.83.78.83h5.44c.43 0 .78-.37.78-.83V5.83z"
              opacity=".2"
            ></path>
            <path
              d="M25 15h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0H4c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1z"
              opacity=".3"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
const InvalidCreditCardSVG = () => {
  return (
    <svg
      focusable="false"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 21"
    >
      <g fill="none" fillRule="evenodd">
        <g id="error" className="Icon-fill">
          <g id="card" transform="translate(0 2)">
            <path
              id="shape"
              d="M21.68 0A6 6 0 1 0 29 9.47v7.15A2.4 2.4 0 0 1 26.58 19H2.42A2.4 2.4 0 0 1 0 16.62V2.38A2.4 2.4 0 0 1 2.42 0h19.26zM10 5.83c0-.46-.35-.83-.78-.83H3.78c-.43 0-.78.37-.78.83v3.34c0 .46.35.83.78.83h5.44c.43 0 .78-.37.78-.83V5.83z"
              opacity=".2"
            ></path>
            <path
              id="shape"
              d="M25 15h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0H4c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1z"
              opacity=".3"
            ></path>
          </g>
          <g id="shape" transform="translate(18)">
            <path d="M7 14A7 7 0 1 1 7 0a7 7 0 0 1 0 14zM6 3v4a1 1 0 0 0 2 0V3a1 1 0 0 0-2 0zm1 8.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
const CvcSVG = () => {
  return (
    <svg
      focusable="false"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 21"
    >
      <g fill="none" fillRule="evenodd">
        <g className="Icon-fill">
          <g transform="translate(0 2)">
            <path
              d="M21.68 0H2c-.92 0-2 1.06-2 2v15c0 .94 1.08 2 2 2h25c.92 0 2-1.06 2-2V9.47a5.98 5.98 0 0 1-3 1.45V11c0 .66-.36 1-1 1H3c-.64 0-1-.34-1-1v-1c0-.66.36-1 1-1h17.53a5.98 5.98 0 0 1 1.15-9z"
              opacity=".2"
            ></path>
            <path
              d="M19.34 3H0v3h19.08a6.04 6.04 0 0 1 .26-3z"
              opacity=".3"
            ></path>
          </g>
          <g transform="translate(18)">
            <path d="M7 14A7 7 0 1 1 7 0a7 7 0 0 1 0 14zM4.22 4.1h-.79l-1.93.98v1l1.53-.8V9.9h1.2V4.1zm2.3.8c.57 0 .97.32.97.78 0 .5-.47.85-1.15.85h-.3v.85h.36c.72 0 1.21.36 1.21.88 0 .5-.48.84-1.16.84-.5 0-1-.16-1.52-.47v1c.56.24 1.12.37 1.67.37 1.31 0 2.21-.67 2.21-1.64 0-.68-.42-1.23-1.12-1.45.6-.2.99-.73.99-1.33C8.68 4.64 7.85 4 6.65 4a4 4 0 0 0-1.57.34v.98c.48-.27.97-.42 1.44-.42zm4.32 2.18c.73 0 1.24.43 1.24.99 0 .59-.51 1-1.24 1-.44 0-.9-.14-1.37-.43v1.03c.49.22.99.33 1.48.33.26 0 .5-.04.73-.1.52-.85.82-1.83.82-2.88l-.02-.42a2.3 2.3 0 0 0-1.23-.32c-.18 0-.37.01-.57.04v-1.3h1.44a5.62 5.62 0 0 0-.46-.92H9.64v3.15c.4-.1.8-.17 1.2-.17z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

const FloatingPayProcess = ({
  open,
  setIsActiveFlotingPay,
  wayToPay,
  setWayToPay,
  currentKitObject,
  setCurrentKitObject,
  cardData,
  setCardData,
  initialDataPreregistration,
  orderData,
  stepPay,
  setStepPay,
  handleRegiterNewEntreprenour,
  sponsorData,
  vCardWebPageEntreprenourData,
  paypalStatusPay,
  handleOrderCreatorPaypal,
  handleOrderAprovedPaypal,
}) => {
  //Hooks
  const [isLoadElement, setIsLoadElement] = useState(false);
  //
  const [creditCardNumberMaxLenght, setCreditCardNumberMaxLenght] =
    useState(16);
  const [ccLengthCVC, setCcLengthCVC] = useState(3);
  const [ccType, setCcType] = useState(undefined);
  // const [errorType, setErrorType] = useState(undefined);
  // const [errorCreditCardNumber, setErrorCreditCardNumber] = useState(true);
  // const [errorCreditCardCvc, setErrorCreditCardCvc] = useState(true);
  // const [errorCreditCardDate, setErrorCreditCardDate] = useState(true);

  //Translation
  const { t, i18n } = useTranslation();

  //EFFECTS
  //Effect on month and year card selectors
  useEffect(() => {
    if (cardData.cardMonth.length > 0 && cardData.cardYear.length > 0) {
      if (!dateIsInThePast(cardData.cardExipirationDate))
        document.getElementById("card__cvc").focus();
    }
  }, [cardData.cardMonth, cardData.cardYear]);

  //HELPERS
  const verifyCardTypeWithRegex = (number) => {
    if (number !== "" && number.length > 1) {
      const regexInitialAMEX = /^3[47]\d{0,13}$/;
      const regexCompleteAMEX = /^3[47]\d{13}$/;
      const regexInitialMasterCard = /^5[1-5]\d{0,14}$/;
      const regexCompleteMasterCard = /^5[1-5]\d{14}$/;
      const regexInitialVisa = /^4[0-9]\d{0,14}$/;
      const regexCompleteVisa = /^4\d{12}(\d{3})?$/;
      //American Express
      if (regexInitialAMEX.test(number)) {
        setCreditCardNumberMaxLenght(15);
        setCcLengthCVC(4);
        setCcType("AMEX");
        if (number.length === 15) {
          if (regexCompleteAMEX.test(number)) {
            document.getElementById("card__date__month").focus();
          }
        }
      }
      //Mastercard
      else if (regexInitialMasterCard.test(number)) {
        setCreditCardNumberMaxLenght(16);
        setCcLengthCVC(3);
        setCcType("MASTERCARD");
        if (number.length === 16) {
          if (regexCompleteMasterCard.test(number)) {
            document.getElementById("card__date__month").focus();
          }
        }
      }
      //Visa
      else if (regexInitialVisa.test(number)) {
        setCreditCardNumberMaxLenght(16);
        setCcLengthCVC(3);
        setCcType("VISA");
        if (number.length === 16) {
          if (regexCompleteVisa.test(number)) {
            document.getElementById("card__date__month").focus();
          }
        }
      }
    }
  };

  const dateIsInThePast = (date) => {
    //'date' is in format MM/YY
    let cardMonthInString = date.slice(0, 2);
    let cardMonth = parseInt(cardMonthInString.replace("0", ""), 10);
    let cardYear = parseInt(date.slice(3, 5), 10);
    //console.log("CARD DATA", cardMonth, cardYear);

    //Last day in month
    let lastDayInMonth = new Date(2020, cardMonth, 0).getDate();
    //console.log("lastDayInMonth", lastDayInMonth);

    //let selectedDate = new Date("2020-08-31");
    let selectedDateString = `20${cardYear}-${cardMonthInString}-${lastDayInMonth}`;
    let selectedDate = new Date(selectedDateString);
    let now = new Date();
    now.setHours(0, 0, 0, 0);
    return selectedDate < now;
  };
  ///HANDLERS
  const handleCardNumberChange = (e) => {
    if (!isNaN(e.target.value)) {
      setCardData({
        ...cardData,
        cardNumber: e.target.value,
      });

      verifyCardTypeWithRegex(e.target.value);
    }
  };
  const handleCardDateChange = (e) => {
    const value = e.target.value;
    //const value = `${cardData.cardMonth}/${cardData.cardYear}}`
    let formattedDate = value
      .replace(
        /[^0-9]/g,
        "" // To allow only numbers
      )
      .replace(
        /^([2-9])$/g,
        "0$1" // To handle 3 > 03
      )
      .replace(
        /^(1{1})([3-9]{1})$/g,
        "0$1/$2" // 13 > 01/3
      )
      .replace(
        /^0{1,}/g,
        "0" // To handle 00 > 0
      )
      .replace(
        /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g,
        "$1/$2" // To handle 113 > 11/3
      );

    setCardData({
      ...cardData,
      cardExipirationDate: formattedDate,
    });

    if (formattedDate.length === 5 && dateIsInThePast(value) === false) {
      document.getElementById("card__cvc").focus();
    }
  };

  const handleCarCvcChange = (e) => {
    setCardData({
      ...cardData,
      cardCvc: e.target.value,
    });
  };
  //Renders

  const renderYears = () => {
    let currentYear = new Date().getFullYear();
    const years = [];
    for (var x = 0; x <= 10; x++) {
      years.push(currentYear + x);
    }
    return years.map((year, index) => (
      <MenuItem
        key={index}
        selected={index === 0}
        value={year.toString().substring(2, 4)}
      >
        {year.toString().substring(2, 4)}
      </MenuItem>
    ));
  };
  // const renderCardIcon = () => {
  //   if (errorType) {
  //     switch (errorType) {
  //       case "cardnumber":
  //         return <InvalidCreditCardSVG />;
  //       case "cardcvc":
  //         return <CvcSVG />;
  //       default:
  //     }
  //   } else if (ccType) {
  //     switch (ccType) {
  //       case "AMEX":
  //         return (
  //           <img
  //             //src="//cdn.shopify.com/s/assets/payment_icons/american_express-2264c9b8b57b23b0b0831827e90cd7bcda2836adc42a912ebedf545dead35b20.svg"
  //             src="https://js.stripe.com/v3/fingerprinted/img/amex-a49b82f46c5cd6a96a6e418a6ca1717c.svg"
  //             alt="American Express"
  //           />
  //         );
  //       case "MASTERCARD":
  //         return (
  //           <img
  //             //src="//cdn.shopify.com/s/assets/payment_icons/master-173035bc8124581983d4efa50cf8626e8553c2b311353fbf67485f9c1a2b88d1.svg"
  //             src="https://js.stripe.com/v3/fingerprinted/img/mastercard-4d8844094130711885b5e41b28c9848f.svg"
  //             alt="Mastercard"
  //           />
  //         );
  //       case "VISA":
  //         return (
  //           <img
  //             //src="//cdn.shopify.com/s/assets/payment_icons/visa-319d545c6fd255c9aad5eeaad21fd6f7f7b4fdbdb1a35ce83b89cca12a187f00.svg"
  //             src="https://js.stripe.com/v3/fingerprinted/img/visa-365725566f9578a9589553aa9296d178.svg"
  //             alt="Visa"
  //           />
  //         );
  //       default:
  //     }
  //   } else {
  //     return <InitialCreditCardSVG />;
  //   }
  // };

  function renderStep(stepPay) {
    switch (stepPay) {
      case 0:
        return (
          <Fragment>
            <div
              className={`floating__pay__process__main__secure__pay ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              <div
                className={`floating__pay__process__main__secure__pay__image ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                <img
                  className={`${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  }`}
                  alt="image-floating"
                  src="https://www.websizusa.net/imgprod/zu/modules/14/107/3/Pay_es.jpg"
                />
              </div>
              <div
                className={`floating__pay__process__main__secure__pay__info ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                <div
                  className={`floating__pay__process__main__secure__pay__info__header ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  }`}
                >
                  <div>
                    <img src={bagBlackIcon} alt="icon" />
                  </div>
                  <span>{t("__pay__process__pay__safe")}</span>
                </div>
                <div className="mini__checkout__payment__summary">
                  <div className="mini__checkout__payment__summary__subtotal__wrapper">
                    <span>
                      {t("__pay__thanks__message__kit")} {currentKitObject.name}
                    </span>
                  </div>
                  <br />
                  <div className="mini__checkout__payment__summary__subtotal__wrapper">
                    <span>{t("__ZUsa_NavBar_Sub_Total")}</span>
                    <span>{formatPrice(orderData?.order?.subtotal)}</span>
                  </div>

                  <div className="mini__checkout__payment__summary__promo__code__and__taxes__wrapper">
                    <div className="mini__checkout__payment__summary__taxes">
                      <span>
                        {t("__ZUsa_NavBar_OptionCart_Taxes")} (
                        {formatNumberWithCommas(
                          orderData?.order?.porcimp?.toFixed(2)
                        )}
                        %)
                      </span>
                      <span>{formatPrice(orderData?.order?.importeimp)}</span>
                    </div>
                    {/* <div className="mini__checkout__payment__summary__taxes">
                      <span>{t("__ZUsa_NavBar_OptionCart_Charges")}</span>
                      <span>{formatPrice(orderData?.order?.cargos)}</span>
                    </div> */}
                    <div className=" mini__checkout__payment__summary__shipping">
                      <span>{t("_Zusa_NewMembers_Resume_Shipment")}</span>
                      <div className=" mini__checkout__payment__summary__shipping__prices">
                        <span className="crossedOutPrice">
                          {formatPrice(19.95)}
                        </span>
                        <span>{formatPrice(0)}</span>
                      </div>
                    </div>
                    <div className="mini__checkout__payment__summary__total__wrapper">
                      <span>{t("__ZUsa_NavBar_Total")}</span>
                      <span>{formatPrice(orderData?.order?.importeii)}</span>
                    </div>
                  </div>
                </div>
                <div
                  className={`floating__pay__process__main__secure__pay__info__payment ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  }`}
                >
                  <FormControl
                    className={` floating__pay__process__main__secure__pay__info__payment__radio ${
                      isTablet ? "tablet" : isMobile ? "mobile" : ""
                    }`}
                  >
                    <div className="floating__pay__process__main__secure__pay__info__payment__radio ">
                      <div className="floating__pay__process__main__secure__pay__info__payment__radio">
                        <input
                          type="radio"
                          id="credit"
                          name="payInvoice"
                          value="credit"
                          checked={wayToPay === "stripe"}
                          onChange={(e) => setWayToPay("stripe")}
                        />
                        <img
                          className={` floating__pay__process__main__secure__pay__info__payment__radio__image ${
                            isTablet ? "tablet" : isMobile ? "mobile" : ""
                          }`}
                          htmlFor="credit"
                          alt="credit"
                          src={
                            i18n.language === "es" ? creditIconEs : creditIconEn
                          }
                        />
                      </div>
                      <div
                        className={`floating__pay__process__main__secure__pay__info__payment__radio__image  ${
                          isTablet ? "tablet" : isMobile ? "mobile" : ""
                        }`}
                      >
                        <input
                          type="radio"
                          id="pagoPaypal"
                          name="payInvoice"
                          value="pagoPaypal"
                          checked={wayToPay === "paypal"}
                          onChange={(e) => setWayToPay("paypal")}
                        />
                        <img
                          htmlFor="pagoPaypal"
                          alt="pagoPaypal"
                          src={paypalIcon}
                        />
                      </div>
                    </div>
                  </FormControl>
                  {wayToPay === "stripe" && (
                    <div
                      className={`floating__pay__process__main__secure__pay__info__payment__fields ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                    >
                      {/* <TextFieldNewMembers
                      labelName={t("__pay__process__pay__credit__name")}
                      typeInput="text"
                      valueInput=""
                      erroHelp="Aqui va el error"
                      isActiveHelp={true}
                      setValue={(e) => {}}
                    /> */}

                      {/* {renderCardIcon()} */}
                      <TextFieldNewMembers
                        labelName={t("__pay__process__pay__credit__card")}
                        typeInput="text"
                        valueInput={cardData.cardNumber}
                        erroHelp={t("__general__card_invalid")}
                        isActiveHelp={
                          cardData.cardNumber.length !==
                          creditCardNumberMaxLenght
                        }
                        setValue={handleCardNumberChange}
                        maxLength={creditCardNumberMaxLenght}
                      />
                      <div
                        className={`floating__pay__process__main__secure__pay__info__payment__fields__card ${
                          isTablet ? "tablet" : isMobile ? "mobile" : ""
                        }`}
                      >
                        {/* <TextFieldNewMembers
                        id="card__date"
                        labelName={t("__pay__process__pay__expiration")}
                        typeInput="text"
                        valueInput={cardData.cardExipirationDate}
                        erroHelp={
                          cardData.cardExipirationDate.length !== 5
                            ? t("__general__card_year__expiration")
                            : dateIsInThePast(cardData.cardExipirationDate)
                            ? t("__general__card_year__expiration")
                            : ""
                        }
                        isActiveHelp={
                          cardData.cardExipirationDate.length !== 5 ||
                          dateIsInThePast(cardData.cardExipirationDate)
                        }
                        setValue={handleCardDateChange}
                        maxLength={5}
                      /> */}
                        <div
                          className={`mui__select__wrapper ${
                            isTablet ? "tablet" : isMobile ? "mobile" : ""
                          }`}
                        >
                          <FormControl
                            variant="standard"
                            fullWidth
                            error={cardData.cardMonth.length <= 0}
                          >
                            <label id="cardDateLabel">
                              {t("__pay__process__pay__expiration_month")}
                            </label>

                            <Select
                              id="card__date__month"
                              labelId="cardDateLabel"
                              value={cardData.cardMonth}
                              onChange={(e) => {
                                setCardData({
                                  ...cardData,
                                  cardMonth: e.target.value,
                                  cardExipirationDate:
                                    cardData.cardYear.length > 0
                                      ? `${e.target.value}/${cardData.cardYear}`
                                      : "",
                                });
                              }}
                              error={cardData.cardMonth.length <= 0}
                            >
                              <MenuItem value={"01"}>01</MenuItem>
                              <MenuItem value={"02"}>02</MenuItem>
                              <MenuItem value={"03"}>03</MenuItem>
                              <MenuItem value={"04"}>04</MenuItem>
                              <MenuItem value={"05"}>05</MenuItem>
                              <MenuItem value={"06"}>06</MenuItem>
                              <MenuItem value={"07"}>07</MenuItem>
                              <MenuItem value={"08"}>08</MenuItem>
                              <MenuItem value={"09"}>09</MenuItem>
                              <MenuItem value={"10"}>10</MenuItem>
                              <MenuItem value={"11"}>11</MenuItem>
                              <MenuItem value={"12"}>12</MenuItem>
                            </Select>
                            {cardData.cardMonth.length === 0 && (
                              <span id="my-helper-text">
                                {t(
                                  "__pay__process__pay__expiration_month_error"
                                )}
                              </span>
                            )}
                          </FormControl>
                        </div>
                        <div
                          className={`mui__select__wrapper ${
                            isTablet ? "tablet" : isMobile ? "mobile" : ""
                          }`}
                        >
                          <FormControl
                            variant="standard"
                            fullWidth
                            error={cardData.cardYear.length <= 0}
                          >
                            <label id="cardDateLabelYear">
                              {t("__pay__process__pay__expiration_year")}
                            </label>
                            <Select
                              labelId="cardDateLabelYear"
                              value={cardData.cardYear}
                              onChange={(e) => {
                                setCardData({
                                  ...cardData,
                                  cardYear: e.target.value,
                                  cardExipirationDate:
                                    cardData.cardMonth.length > 0
                                      ? `${cardData.cardMonth}/${e.target.value}`
                                      : "",
                                });
                              }}
                              error={cardData.cardYear.length <= 0}
                            >
                              {renderYears()}
                            </Select>
                            {cardData.cardYear.length === 0 && (
                              <span id="my-helper-text">
                                {t(
                                  "__pay__process__pay__expiration_year_error"
                                )}
                              </span>
                            )}
                          </FormControl>
                        </div>
                      </div>
                      <TextFieldNewMembers
                        id="card__cvc"
                        labelName={t("__pay__process__pay__code")}
                        typeInput="text"
                        valueInput={cardData.cardCvc}
                        // erroHelp="Completa el código de seguradad de tu tarjeta"
                        // isActiveHelp={cardData.cardCvc.length !== ccLengthCVC}
                        setValue={handleCarCvcChange}
                        maxLength={ccLengthCVC}
                      />
                    </div>
                  )}
                  {wayToPay === "paypal" && (
                    <div
                      className={`floating__pay__process__main__secure__pay__info__payment__fields ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                    >
                      <PayPalButton
                        userFormData={initialDataPreregistration}
                        paypalStatusPay={paypalStatusPay}
                        mode="sandbox"
                        createOrder={handleOrderCreatorPaypal}
                        onApprove={handleOrderAprovedPaypal}
                        onCancel={(data) => {
                          console.error("onCalcelPaypal", data);
                        }}
                        onError={(error) => {
                          console.error("onErrorPaypal", error);
                        }}
                      />
                    </div>
                  )}
                  <div
                    className={`floating__pay__process__main__secure__pay__info__payment__footer ${
                      isTablet ? "tablet" : isMobile ? "mobile" : ""
                    }`}
                  >
                    {isLoadElement ? (
                      <Skeleton variant="wave" width={250} height={40} />
                    ) : (
                      <Button
                        disabled={
                          cardData.cardNumber.length !==
                            creditCardNumberMaxLenght ||
                          cardData.cardExipirationDate.length !== 5 ||
                          dateIsInThePast(cardData.cardExipirationDate) ||
                          cardData.cardCvc.length !== ccLengthCVC
                        }
                        onClick={() => {
                          handleRegiterNewEntreprenour();
                        }}
                        className={`form__new__members__main__form__wrapper__fields__main__register__button ${
                          isTablet ? "tablet" : isMobile ? "mobile" : ""
                        }`}
                      >
                        {t("__pay__process__button__pay")}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        );

      case 1:
        return (
          <Fragment>
            <div
              className={`floating__thanks__main ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              <div
                className={`floating__thanks__main__info ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                <div
                  className={`floating__thanks__main__info__imagen ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  }`}
                >
                  <div
                    className={`floating__thanks__main__info__imagen__content ${
                      isTablet ? "tablet" : isMobile ? "mobile" : ""
                    }`}
                  >
                    <img
                      alt="thanks_imagen"
                      src="https://www.websizusa.net/imgprod/zu/modules/14/110/3/Resume_pay_en.jpg"
                    />
                  </div>
                  <div
                    className={`floating__thanks__main__info__imagen__btn ${
                      isTablet ? "tablet" : isMobile ? "mobile" : ""
                    }`}
                  >
                    <Button
                      className=""
                      onClick={() => {
                        // window.open(
                        //   "https://www.websizusa.net/ZermatBusiness/#/Login",
                        //   "_blank"
                        // );
                        window.open(
                          "https://zermatusa-3972069.hs-sites.com/folder-nuevo-ingreso",
                          "_blank"
                        );

                        window.location.reload();
                      }}
                    >
                      {t("__pay__thanks__zbusiness")}
                    </Button>
                  </div>
                </div>
                <div
                  className={`floating__thanks__main__info__purchase ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  }`}
                >
                  <span
                    className={`floating__thanks__main__info__purchase__first__message ${
                      isTablet ? "tablet" : isMobile ? "mobile" : ""
                    }`}
                  >
                    {t("__pay__thanks__message__one")}
                  </span>

                  <span
                    className={`floating__thanks__main__info__purchase__second__message ${
                      isTablet ? "tablet" : isMobile ? "mobile" : ""
                    }`}
                  >
                    {t("__pay__thanks__message__two")}
                    <span
                      className={`floating__thanks__main__info__purchase__three__message ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                    >
                      {t("__pay__thanks__message__three")}
                    </span>
                  </span>

                  <span
                    className={`floating__thanks__main__info__purchase__four__message ${
                      isTablet ? "tablet" : isMobile ? "mobile" : ""
                    }`}
                  >
                    {t("__pay__thanks__message__four")}
                  </span>
                  <hr
                    className={`floating__thanks__main__info__line ${
                      isTablet ? "tablet" : isMobile ? "mobile" : ""
                    }`}
                  />
                  <div
                    className={`floating__thanks__main__info__purchase__user ${
                      isTablet ? "tablet" : isMobile ? "mobile" : ""
                    }`}
                  >
                    <div
                      className={`floating__thanks__main__info__purchase__user__data ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                    >
                      <small>{t("__pay__thanks__message__user")}</small>
                      <span>{`${initialDataPreregistration.name} ${initialDataPreregistration.lastname}`}</span>
                    </div>
                    <div
                      className={`floating__thanks__main__info__purchase__user__data ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                    >
                      <small>{t("__pay__thanks__message__kit")}</small>
                      <span className="special__kit">
                        {currentKitObject.name}
                      </span>
                    </div>
                    <div
                      className={`floating__thanks__main__info__purchase__user__data ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                    >
                      <small>{t("__pay__thanks__message__order")}</small>
                      <span>{orderData.order.orderId}</span>
                    </div>
                    {/* <div
                      className={`floating__thanks__main__info__purchase__user__data ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                    >
                      <small>{t("__pay__thanks__message__no__auth")}</small>
                      <span>pm_1MrNbMLdfQiqLoXf9P2z6nRh</span>
                    </div> */}
                    <div
                      className={`floating__thanks__main__info__purchase__user__data ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                    >
                      <small>
                        {t("__pay__thanks__message__shipping_date")}
                      </small>
                      <span>{orderData.order.fecpedido}</span>
                    </div>
                  </div>
                  <hr
                    className={`floating__thanks__main__info__line ${
                      isTablet ? "tablet" : isMobile ? "mobile" : ""
                    }`}
                  />
                  <div
                    className={`floating__thanks__main__info__purchase__user ${
                      isTablet ? "tablet" : isMobile ? "mobile" : ""
                    }`}
                  >
                    <div
                      className={`floating__thanks__main__info__purchase__user__data ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                    >
                      <small>
                        {t("__pay__thanks__message__phone__contact")}
                      </small>
                      <span>{initialDataPreregistration.cellphone}</span>
                    </div>
                    <div
                      className={`floating__thanks__main__info__purchase__user__data ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                    >
                      <small>{t("__pay__thanks__message__address")}</small>
                      <span>
                        {`${initialDataPreregistration.streetShipping} ${initialDataPreregistration.suiteShipping} ${initialDataPreregistration.cityShipping}, ${initialDataPreregistration.stateShipping} ZIP: ${initialDataPreregistration.zipShipping}`}
                      </span>
                    </div>

                    <hr
                      className={`floating__thanks__main__info__line ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                    />

                    <div
                      className={`floating__thanks__main__info__purchase__user__data ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                    >
                      <small>
                        {t("__pay__thanks__message__delivery__warning")}
                      </small>
                      <span>
                        {t(
                          "__pay__thanks__message__delivery__warning__response"
                        )}
                      </span>
                    </div>
                  </div>
                  <div
                    className={`floating__thanks__main__info__purchase__prices ${
                      isTablet ? "tablet" : isMobile ? "mobile" : ""
                    }`}
                  >
                    <div
                      className={`floating__thanks__main__info__purchase__prices__description ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                    >
                      <span>{t("__pay__thanks__message_subtotal")}</span>
                      {/* <span>{t("__pay__thanks__message__charge")}</span> */}
                      <span>{t("__pay__thanks__message__delivery__cost")}</span>
                      <span>{t("__ZUsa_NavBar_OptionCart_Taxes")}</span>
                      <span className="total__price">
                        {t("__pay__thanks__message__delivery__total")}
                      </span>
                    </div>
                    <div
                      className={`floating__thanks__main__info__purchase__prices__money ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                    >
                      <span>{formatPrice(orderData?.order?.subtotal)}</span>
                      {/* <span>{formatPrice(orderData?.order?.cargos)}</span> */}
                      <span>{formatPrice(0)}</span>
                      <span>{formatPrice(orderData?.order?.importeimp)}</span>
                      <span className="total__price">
                        {formatPrice(orderData?.order?.importeii)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        );
    }
  }

  return (
    <div
      className={`floating__pay__process ${stepPay === 0 ? "pay" : "thanks"} ${
        open ? "active" : ""
      } ${isIOS ? "is__iOS" : ""} `}
    >
      <div className="floating__pay__process__header">
        {stepPay === 0 && (
          <div className="floating__pay__process__header__close__wrapper">
            <IconButton
              onClick={() => {
                if (stepPay === 1) setStepPay(0);
                setIsActiveFlotingPay(false);
              }}
            >
              <Close />
            </IconButton>
          </div>
        )}
      </div>

      <div
        className={`floating__pay__process__main ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }`}
      >
        {renderStep(stepPay)}
      </div>
      {stepPay === 1 && (
        <div
          className={`floating__thanks__main__footer ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          <div
            className={`floating__thanks__main__footer__sponsor ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            <div
              className={`floating__thanks__main__footer__sponsor__content ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              <div
                className={`floating__thanks__main__footer__sponsor__content__image ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                <img
                  alt="sponsor"
                  src={sponsorData.imageProfile}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = thanksImage;
                  }}
                />
              </div>
              <div
                className={`floating__thanks__main__footer__sponsor__content__info ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                <div>
                  {" "}
                  <span>{t("__pay__thanks__footer__sponsor")}</span>
                  <span className="text__bolder">
                    {t("__pay__thanks__footer__sponsor__hellos")} Soy{" "}
                    {sponsorData.nameClient}
                  </span>
                </div>
                {!isMobile && (
                  <span>
                    {t("__pay__thanks__footer__sponsor__welcome")}{" "}
                    {`${initialDataPreregistration.name} ${initialDataPreregistration.lastname}`}
                    ,{t("__pay__thanks__footer__sponsor__guide")}
                    {t("__pay__thanks__footer__sponsor__question")}
                    <br />
                    {t("__pay__thanks__footer__sponsor__touch")}
                  </span>
                )}

                <span>
                  {" "}
                  {t("__pay__thanks__footer__sponsor__phone")}{" "}
                  {sponsorData.cellPhone}{" "}
                </span>
                <span>
                  {" "}
                  {t("__pay__thanks__footer__sponsor__email")}{" "}
                  {sponsorData.email}{" "}
                </span>

                <div></div>
              </div>
            </div>
          </div>
          <div
            className={`floating__thanks__main__footer__qr ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            <QRCode
              size={180}
              value={vCardWebPageEntreprenourData}
              viewBox={`0 0 180 180`}
              bgColor={"transparent"}
            />
            {/* <img alt="QrCode" src={qrExample} /> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default FloatingPayProcess;
