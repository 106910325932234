import React, { Fragment, useRef, useState, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import AvatarPlaceholder from "../../../assets/media/images/avatarPlaceholder.jpg";
import { IconButton, Button } from "@mui/material";
import { PhotoCamera, Save, SettingsBackupRestore } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { convertNameToInitials } from "../../../Libraries/Utils/utils";
// import NotificationSnackbar from "../base/components/NotificationSnackbar";

const ImageProfile = ({
  handleUploadImage,
  isProfileImageLoaded,
  imageProfileUrl,
  name,
}) => {
  //Variables
  let fileUrl = undefined;

  //References
  let imageFileInput = useRef(null);

  //Initial states
  const initialImageState = {
    src: null,
    crop: {
      unit: "%",
      width: 30,
      aspect: 16 / 9,
    },
  };

  /// TRANSLATION
  const { t } = useTranslation();
  //Hooks
  const TO_RADIANS = Math.PI / 180;

  const [image, setImage] = useState(initialImageState);
  const [imageRef, setImageRef] = useState(undefined);
  const [isActivedEdit, setIsActivedEdit] = useState(false);
  // const [isOpenNotification, setIsOpenNotification] = useState(false);
  const [userId, setUserId] = useState(0);
  const [imageB64, setImageB64] = useState("");

  ///EFFECTS
  useEffect(() => {
    if (localStorage.getItem("ZUsaUInfo")) {
      setUserId(JSON.parse(localStorage.getItem("ZUsaUInfo")).idUser);
    }
  }, [localStorage.getItem("ZUsaUInfo")]);

  //Handlers
  const onSelectFile = (e) => {
    //console.log("onSelectFile");
    if (e.target.files && e.target.files.length > 0) {
      const maxFileSize = 5120000; //500KB
      const file = e.target.files[0];
      if (file.size < maxFileSize) {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          const updated = {
            ...image,
            src: reader.result,
          };
          setImage(updated);
        });
        reader.readAsDataURL(file);
        //console.log("All OK");
      } else {
        console.error("File exceed the limit size");
      }
    }
  };
  const onImageLoaded = (image) => {
    setImageRef(image.target);
  };
  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };
  const onCropChange = (crop) => {
    const updated = {
      ...image,
      crop,
    };
    setImage(updated);
  };
  const makeClientCrop = async (crop) => {
    if (imageRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef,
        crop,
        "newFile.jpeg"
      );
      const updated = {
        ...image,
        croppedImageUrl,
      };
      setImage(updated);
    }
  };
  const getCroppedImg = (image, crop, fileName) => {
    const imageCanvas = document.createElement("img");
    imageCanvas.setAttribute("id", "imagenCropper");
    imageCanvas.setAttribute("src", image.src);
    const canvas = document.createElement("canvas");
    const scale = 1;
    const rotate = 0;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = "high";

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    const rotateRads = rotate * TO_RADIANS;
    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;

    ctx.save();
    ctx.translate(-cropX, -cropY);
    ctx.translate(centerX, centerY);
    ctx.rotate(rotateRads);
    ctx.scale(scale, scale);
    ctx.translate(-centerX, -centerY);
    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight
    );

    const base64Image = canvas.toDataURL();
    setImageB64(base64Image);

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Failed to create blob");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(fileUrl);
        fileUrl = URL.createObjectURL(blob);
        resolve(fileUrl);
      }, "image/jpeg");
    });
  };
  const uploadToServerCroppedImage = () => {
    //1) Reset image state
    setImage({
      ...image,
      src: null,
    });

    //2) Convert to FILE the BLOB of image to upload to server
    // console.log("croppedImageUrl", image.croppedImageUrl);
    const blob = image.croppedImageUrl;
    const imageName = `image_name.jpg`;
    const fileImage = new File([blob], imageName, { type: "image/jpeg" });
    // console.log("fileImage", fileImage);

    //3) Trigger REDUX action to upload to server
    //props.requestUploadImageDispatch(fileImage);
    handleUploadImage(imageB64, `image_profile${userId}.jpg`);
    setIsActivedEdit(false);
  };

  return (
    <Fragment>
      {/* <NotificationSnackbar
        isOpenNotification={isOpenNotification}
        setIsOpenNotification={setIsOpenNotification}
        message={t("__notification_successful")}
        status="success"
        icon="Check"
      /> */}

      <div className="user__profile__wrapper__image__content">
        <div className="user__profile__wrapper__image__content__up">
          {/* PREVIEW */}
          <div
            className={`circle__avatar__wrapper big__size ${
              !isProfileImageLoaded ? "with__initials" : ""
            }`}
          >
            {isProfileImageLoaded ? (
              <img
                id="previewImage"
                alt="Crop"
                src={imageProfileUrl}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = AvatarPlaceholder;
                }}
              />
            ) : (
              <span>{convertNameToInitials(name)}</span>
            )}
          </div>
        </div>
        <div className="user__profile__wrapper__image__content__down">
          {!isActivedEdit && (
            <Fragment>
              <label htmlFor="icon-button-file">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={() => {
                    imageFileInput.current.value = "";
                    imageFileInput.current.click();
                    setIsActivedEdit(true);
                  }}
                >
                  <PhotoCamera />
                </IconButton>
              </label>
            </Fragment>
          )}
        </div>
      </div>
      {/* INPUT FILE FOR SELECT IMAGE */}
      <input
        ref={imageFileInput}
        style={{ display: "none" }}
        accept="image/*"
        id="icon-button-file"
        type="file"
        onChange={onSelectFile}
      />
      <div className="cropper__selector">
        <div className="cropper__selector__wrapper">
          {image.src && (
            <ReactCrop
              crop={image.crop}
              onChange={onCropChange}
              onComplete={onCropComplete}
              aspect={image.crop.aspect}
            >
              <img
                alt="Crop me"
                src={image.src}
                style={{ width: "100%", height: "auto", overflow: "hidden" }}
                onLoad={onImageLoaded}
              />
            </ReactCrop>
          )}
        </div>
      </div>

      <div className="user__profile__wrapper__buttons">
        {isActivedEdit && (
          <Fragment>
            <Button
              onClick={uploadToServerCroppedImage}
              className="button__with__icon bg__dark__pink no__left__margin user__profile__wrapper__buttons__save"
            >
              <div className="button__with__icon__svg__wrapper dark__pink__ball">
                <Save className="text__white" />
              </div>
              <span>{t("btn_text_save")}</span>
            </Button>
            <Button
              onClick={() => {
                setImage(initialImageState);
                imageFileInput.current.value = "";
                setIsActivedEdit(false);
              }}
              className="button__with__icon bg__dark__pink no__left__margin user__profile__wrapper__buttons__restore"
            >
              <div className="button__with__icon__svg__wrapper dark__pink__ball">
                <SettingsBackupRestore className="text__white" />
              </div>
              <span>{t("btn_text_refresh")}</span>
            </Button>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default ImageProfile;
