import React, { Fragment, useState, useEffect } from "react";
import {
  Drawer,
  Button,
  Chip,
  CircularProgress,
  Paper,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import {
  regexIsCellphoneValid,
  regexIsEmailAddressValid,
  restrictToOnlyLettersForEmail,
  restrictToOnlyNumbers,
} from "./../../../../Libraries/Utils/regex";

const SlideReferredFriends = ({
  isOpenSlideModal,
  setIsOpenSlideModal,
  referrerCode,
  dispatch,
  requestReferrerCodeSendedLog,
  cleanReferrerCodeSendedLogReducer,
  appReducers,
  requestLoggUserActivity,
}) => {
  //Notistack
  const { enqueueSnackbar } = useSnackbar();

  /// TRANSLATION
  const { t } = useTranslation();

  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  // const [messageNotification, setMessageNotification] = useState("");
  // const [isOpenNotification, setIsOpenNotification] = useState(true);
  const [email, setEmail] = useState("");
  const [cellPhone, setCellPhone] = useState("");

  useEffect(() => {
    if (typeof appReducers?.referrer?.responseReferrerCodeLog !== "undefined") {
      setLoading(false);
      if (appReducers.referrer.responseReferrerCodeLog.code === 100) {
        enqueueSnackbar(t("__message_send_code_referred_label"), {
          variant: "success",
        });
        // setIsOpenNotification(true);
        // setMessageNotification();
        dispatch(cleanReferrerCodeSendedLogReducer());
        //looggg del referido
        dispatch(
          requestLoggUserActivity(
            55,
            0,
            0,
            "",
            "Se registro Referido de Manera Correcta. Via: " +
              value +
              ". Contacto: " +
              (value === "email" ? email : cellPhone)
          )
        );
      } else {
        switch (appReducers.referrer.responseReferrerCodeLog.code) {
          case -100:
            enqueueSnackbar(t("__message_something_bad_label"), {
              variant: "error",
            });
            //setMessageNotification(t("__message_something_bad_label"));
            break;
          case -200:
            enqueueSnackbar(t("__message_phone_provider_not_number_label"), {
              variant: "error",
            });
            // setMessageNotification(
            //   t("__message_phone_provider_not_number_label")
            // );
            break;
          case -300:
            enqueueSnackbar(t("__message_something_bad_label"), {
              variant: "error",
            });
            // setMessageNotification(t("__message_something_bad_label"));
            break;
          case -400:
            enqueueSnackbar(t("__message_something_bad_label"), {
              variant: "error",
            });
            //setMessageNotification(t("__message_something_bad_label"));
            break;
          case -500:
            enqueueSnackbar(t("__message_invalid_email_provider_label"), {
              variant: "error",
            });
            // setMessageNotification(t("__message_invalid_email_provider_label"));
            break;
          case -600:
            enqueueSnackbar(t("__message_something_bad_label"), {
              variant: "error",
            });
            //setMessageNotification(t("__message_something_bad_label"));
            break;
          default:
            break;
        }
        // setIsOpenNotification(true);
      }
      setEmail("");
      setCellPhone("");
    }
  }, [appReducers?.referrer?.responseReferrerCodeLog]);

  const handleButtonClick = () => {
    setLoading(true);
    dispatch(requestReferrerCodeSendedLog(referrerCode, email, cellPhone));
  };
  const handleChange = (event) => {
    setValue(event.target.value);
    if (event.target.value === "email") setCellPhone("");
    else setEmail("");
  };
  const handleCloseSlideModal = () => {
    setIsOpenSlideModal(false);
    //limpiar los estados
    setValue("");
  };

  return (
    <Fragment>
      <Drawer
        className="modal__slide__container"
        anchor="bottom"
        open={isOpenSlideModal}
      >
        <Paper elevation={3}>
          <div className="modal__slide__container__pill">
            <Chip clickable onClick={handleCloseSlideModal} />
          </div>
          {/* 
          <NotificationSnackbar
            isOpenNotification={isOpenNotification}
            setIsOpenNotification={setIsOpenNotification}
            message={messageNotification}
            status="success"
            icon="Check"
          /> */}
          <div className="modal__slide__container__wrapper">
            <Typography variant="h5" gutterBottom>
              {t("__profile_Friends_Referred_title")}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {t("__profile_Friends_Referred_select_contact")}
            </Typography>
            <div className="modal__slide__container__wrapper__options">
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="email"
                  control={<Radio />}
                  label={t(
                    "__profile_Friends_Referred_select_contact_form_email"
                  )}
                />
                <FormControlLabel
                  value="sms"
                  control={<Radio />}
                  label={t(
                    "__profile_Friends_Referred_select_contact_form_sms"
                  )}
                />
              </RadioGroup>
            </div>
            <div className="modal__slide__container__wrapper__inputs">
              {value && value === "email" && (
                <TextField
                  error={email.length <= 0 || !regexIsEmailAddressValid(email)}
                  helperText={
                    email.length <= 0
                      ? t("__forms_empty_field_error_message")
                      : !regexIsEmailAddressValid(email) &&
                        t("__message_invalid_email_label")
                  }
                  onInput={(e) => {
                    setEmail(restrictToOnlyLettersForEmail(e));
                  }}
                  value={email}
                  label={t(
                    "__profile_Friends_Referred_select_contact_form_email"
                  )}
                  type="email"
                />
              )}
              {value && value === "sms" && (
                <TextField
                  error={
                    cellPhone.length <= 0 || !regexIsCellphoneValid(cellPhone)
                  }
                  helperText={
                    cellPhone.length <= 0
                      ? t("__forms_empty_field_error_message")
                      : !regexIsCellphoneValid(cellPhone) &&
                        t("__message_phone_invalid_code_label")
                  }
                  onInput={(e) => {
                    setCellPhone(restrictToOnlyNumbers(e));
                  }}
                  inputProps={{
                    maxLength: 10,
                  }}
                  value={cellPhone}
                  label={t(
                    "__profile_Friends_Referred_select_contact_form_sms"
                  )}
                  type="tel"
                />
              )}
            </div>
            <Button
              variant="contained"
              disabled={
                loading ||
                (value === "sms" && cellPhone.length !== 10) ||
                (value === "email" &&
                  (email.length <= 0 || !regexIsEmailAddressValid(email))) ||
                value === ""
              }
              onClick={handleButtonClick}
            >
              {t("btn_text_send")}
            </Button>
            {loading && <CircularProgress size={24} />}
          </div>
        </Paper>
      </Drawer>
    </Fragment>
  );
};

export default SlideReferredFriends;
