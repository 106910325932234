import React, { Component } from "react";
//Assets
import { ReactComponent as ErrorPlaceholder } from "./../../../assets/media/images/errorPlaceholder.svg";

export default class ErrorBoundry extends Component {
  state = { hasError: false };

  componentDidCatch(error, info) {
    console.error("Error semantico componentes de react var error", error);
    console.error("Error semantico componentes de react var info", info);
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error__boundary">
          <div className="error__boundary__image__wrapper">
            <ErrorPlaceholder />
          </div>
          <span className="error__boundary__title">
            Something went wrong / Algo salió mal{" "}
          </span>
          <span className="error__boundary__subtitle">
            Try to reload the page / Intenta recargar la página
          </span>
        </div>
      );
    }
    return this.props.children;
  }
}
