import React, { Fragment, useState } from "react";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import { Skeleton } from "@mui/material";
//components
import FloatingKitInfo from "./FloatingKitInfo";

const ShowKitEnabled = ({ i18n, isLanguageLoaded }) => {
  const [isActiveFlotingKitInfo, setIsActiveFlotingKitInfo] = useState(false);
  const [imageKit, setImageKit] = useState("");

  const kitArray = [
    {
      id: 1,
      name: "Kit1",
      imgSrcEn:
        "https://www.websizusa.net/imgprod/zu/modules/14/96/3/Trendy_mix_kit_en.jpg",
      imgSrcEs:
        "https://www.websizusa.net/imgprod/zu/modules/14/95/3/Trendy_mix_kit_es.jpg",
      imgInfoPcEn:
        "https://www.websizusa.net/imgprod/zu/modules/14/102/3/Trendy_mix_kit_details_en.jpg",
      imgInfoPcEs:
        "https://www.websizusa.net/imgprod/zu/modules/14/101/3/Trendy_mix_kit_details_es.jpg",
      imgInfoMobileEn:
        "https://www.websizusa.net/imgprod/zu/modules/14/102/1/Trendy_mix_kit_details_en.jpg",
      imgInfoMobileEs:
        "https://www.websizusa.net/imgprod/zu/modules/14/101/1/Trendy_mix_kit_details_es.jpg",
      imgMobileEn:
        "https://www.websizusa.net/imgprod/zu/modules/14/96/1/Trendy_mix_kit_sm_en.jpg",
      imgMobileEs:
        "https://www.websizusa.net/imgprod/zu/modules/14/95/1/Trendy_mix_kit_sm_es.jpg",
    },
    {
      id: 2,
      name: "Kit2",
      imgSrcEn:
        "https://www.websizusa.net/imgprod/zu/modules/14/98/3/Just_for_all_en.jpg",
      imgSrcEs:
        "https://www.websizusa.net/imgprod/zu/modules/14/97/3/Just_for_all_es.jpg",
      imgInfoPcEn:
        "https://www.websizusa.net/imgprod/zu/modules/14/104/3/Just_for_all_details_en.jpg",
      imgInfoPcEs:
        "https://www.websizusa.net/imgprod/zu/modules/14/103/3/Just_for_all_details_es.jpg",
      imgInfoMobileEn:
        "https://www.websizusa.net/imgprod/zu/modules/14/104/1/Just_for_all_details_en.jpg",
      imgInfoMobileEs:
        " https://www.websizusa.net/imgprod/zu/modules/14/103/1/Just_for_all_details_es.jpg",
      imgMobileEn:
        " https://www.websizusa.net/imgprod/zu/modules/14/98/1/Just_for_all_details_sm_en.jpg",
      imgMobileEs:
        "https://www.websizusa.net/imgprod/zu/modules/14/97/1/Just_for_all_details_sm_es.jpg",
    },
    {
      id: 3,
      name: "Kit3",
      imgSrcEn:
        "https://www.websizusa.net/imgprod/zu/modules/14/100/3/Elixir_kit_en.jpg",
      imgSrcEs:
        "https://www.websizusa.net/imgprod/zu/modules/14/99/3/Elixir_kit_es.jpg",
      imgInfoPcEn:
        "https://www.websizusa.net/imgprod/zu/modules/14/106/3/Elixir_kit_details_en.jpg",
      imgInfoPcEs:
        "https://www.websizusa.net/imgprod/zu/modules/14/105/3/Elixir_kit_details_es.jpg",
      imgInfoMobileEn:
        "https://www.websizusa.net/imgprod/zu/modules/14/106/1/Elixir_kit_details_en.jpg",
      imgInfoMobileEs:
        "https://www.websizusa.net/imgprod/zu/modules/14/105/1/Elixir_kit_details_es.jpg",
      imgMobileEn:
        "https://www.websizusa.net/imgprod/zu/modules/14/100/1/Elixir_kit_details_sm_en.jpg",
      imgMobileEs:
        "https://www.websizusa.net/imgprod/zu/modules/14/99/1/Elixir_kit_details_sm_es.jpg",
    },
  ];

  return (
    <div
      className={`show__kit__enabled__main ${
        isTablet ? "tablet" : isMobile ? "mobile" : ""
      }`}
    >
      <FloatingKitInfo
        open={isActiveFlotingKitInfo}
        setIsActiveFlotingKitInfo={setIsActiveFlotingKitInfo}
        imageKit={imageKit}
      />
      <div
        className={`show__kit__enabled__main__wrapper__header ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }`}
      >
        {!isLanguageLoaded ? (
          <Skeleton
            variant="wave"
            width={"95%"}
            height={40}
            style={{ marginBottom: 10 }}
          />
        ) : (
          <img
            src={
              i18n.language === "es"
                ? " https://www.websizusa.net/imgprod/zu/modules/14/93/3/Show_kits_steps_es.jpg"
                : "https://www.websizusa.net/imgprod/zu/modules/14/94/3/Show_kits_steps_en.jpg"
            }
          />
        )}
      </div>
      <div
        className={`show__kit__enabled__main__wrapper__imagenes ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }`}
      >
        {!isLanguageLoaded ? (
          <Fragment>
            {[0, 1, 2].map((item, index) => {
              return (
                <Skeleton
                  key={index}
                  variant="wave"
                  width={isMobile ? "90%" : "95%"}
                  height={isMobile ? 200 : 300}
                  style={{ margin: 10 }}
                />
              );
            })}
          </Fragment>
        ) : (
          <Fragment>
            {kitArray.map((item, index) => {
              return (
                <div
                  className={`show__kit__enabled__main__wrapper__imagenes__item ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  }`}
                  key={index}
                >
                  <img
                    className={`show__kit__enabled__main__wrapper__imagenes__img ${
                      isTablet ? "tablet" : isMobile ? "mobile" : ""
                    }`}
                    key={index}
                    src={
                      isMobile
                        ? i18n.language === "es"
                          ? item.imgMobileEs
                          : item.imgMobileEn
                        : i18n.language === "es"
                        ? item.imgSrcEs
                        : item.imgSrcEn
                    }
                    onClick={() => {
                      setImageKit(
                        i18n.language === "es"
                          ? isMobile
                            ? item.imgInfoMobileEs
                            : item.imgInfoPcEs
                          : !isMobile
                          ? item.imgInfoPcEn
                          : item.imgInfoMobileEn
                      );
                      setIsActiveFlotingKitInfo(true);
                    }}
                  />
                </div>
              );
            })}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default ShowKitEnabled;
