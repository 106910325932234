import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { requestGetClientConfirmedOrders } from "./../../ReduxSaga/Actions/Client";
import { requestLoggUserActivity } from "./../../ReduxSaga/Actions/General";
import moment from "moment";
import { List, ListItem, Typography, Skeleton } from "@mui/material";
import { isMobile, isTablet } from "react-device-detect";

const ShoppingContainer = ({ isLanguageLoaded }) => {
  /// TRANSLATION
  const { t } = useTranslation();
  /// REDUX
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.client = useSelector((state) => state.client);

  // STATES ################################################################################################################

  const [confirmedOrders, setConfirmedOrders] = useState([]);

  // USEEFFECT ################################################################################################################
  useEffect(() => {
    dispatch(requestGetClientConfirmedOrders());
  }, []);

  useEffect(() => {
    if (appReducers?.client?.clientConfirmedOrders) {
      const orders = appReducers?.client?.clientConfirmedOrders.orders;
      setConfirmedOrders(orders);
    }
  }, [appReducers?.client?.clientConfirmedOrders]);

  // FUNCIONES ################################################################################################################

  return (
    <div
      id="firstWrapperInBaseContainer"
      className="firstWrapperInBaseContainer"
    >
      {/* <BreadcrumbsNavigationComponet /> */}
      <div className="shopping__list__container">
        <div className="shopping__list__container__title">
          {isLanguageLoaded ? (
            <>
              {" "}
              <Typography variant="h6" gutterBottom className="text__red">
                {t("__myAccount_Profile_Orders_Title")}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {t("__myAccount_Profile_Orders_Subtitle")}
              </Typography>
            </>
          ) : (
            <Skeleton variant="rect" width={200} height={30} />
          )}
        </div>
        {confirmedOrders && confirmedOrders.length === 0 ? (
          <Fragment>
            <div className="kit__configuration__container__empty">
              <div>
                {isLanguageLoaded ? (
                  <Typography variant="h6" gutterBottom>
                    {t("__myAccount_Profile_Nothing_Orders_Title")}
                  </Typography>
                ) : (
                  <Skeleton variant="rect" width={200} height={30} />
                )}
              </div>
              <br />
              <div className="kit__configuration__container__box__open" />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            {confirmedOrders &&
              confirmedOrders.map((order, index) => {
                return (
                  <div
                    key={index}
                    className="shopping__list__container__wrapper"
                  >
                    <List>
                      <div className="shopping__list__container__wrapper__item">
                        <ListItem>
                          <div className="shopping__list__container__wrapper__item__image">
                            <img
                              className="favorites__list__container__items__content__item__image"
                              src={order.imgPedido}
                              alt={order.idPedido}
                            />
                          </div>
                          <div
                            className={`shopping__list__container__wrapper__item__details ${
                              isTablet ? "tablet" : isMobile ? "mobile" : ""
                            }`}
                          >
                            <div className="shopping__list__container__wrapper__item__detail__up">
                              <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                              >
                                {moment(order.fecPedido).format("DD-MM-YYYY")}
                              </Typography>
                              <Typography
                                className="shopping__list__container__wrapper__item__price"
                                variant="caption"
                                display="block"
                                gutterBottom
                              >
                                ${parseFloat(order.totalAmount).toFixed(2)}
                              </Typography>
                            </div>
                            <div className="shopping__list__container__wrapper__item__detail__down">
                              <Link
                                to={`/profile/shopping-list/detail/${order.idPedido}`}
                                onClick={() =>
                                  dispatch(
                                    requestLoggUserActivity(
                                      34,
                                      0,
                                      0,
                                      "",
                                      "Click en Ver Detalles Orden: " +
                                        String(order.idPedido)
                                    )
                                  )
                                }
                              >
                                {isLanguageLoaded ? (
                                  <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                  >
                                    {t("__proucts_see_detail_label")}
                                  </Typography>
                                ) : (
                                  <Skeleton
                                    variant="rect"
                                    width={110}
                                    height={20}
                                  />
                                )}
                              </Link>
                            </div>
                          </div>
                        </ListItem>
                      </div>
                    </List>
                  </div>
                );
              })}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default ShoppingContainer;
