import React, { useState, useEffect, Fragment } from "react";
import { isDesktop, isIOS, isMobile, isTablet } from "react-device-detect";
import { Swiper, SwiperSlide } from "swiper/react";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  capitalizeText,
  formatPrice,
  percentageUtility,
} from "../../../../Libraries/Utils/utils";
import { useSnackbar } from "notistack";
import nothingHere from "../../../../assets/media/images/nothingHere.png";
import ButtonComponent from "../../base/components/ButtonComponent";
import CardPersonalizedFragance from "./CardPersonalizedFragance";
import { ReactComponent as WarningIcon } from "../../../../assets/media/images/icons/warningRedIcon.svg";

/// ACTIONS
import {
  requestCarCurrentOrder,
  requestGetCartOrder,
  cleanCartOrderOperations,
  requestCartOrderAddMembership,
  requestCleanCartReducerAddMembership,
} from "./../../../ReduxSaga/Actions/Cart";
import {
  requestBuildCustomLabelImagesOnServer,
  requestCustomLabelsConfigurationByDetail,
  requestCustomLabelsConfigurationByPromotion,
  requestUpdateCustomLabelConfigurationByDetail,
  cleanReducerUpdateCustomLabelsConfigurationByDetail,
  cleanReducerConfigurationCustomLabelByPromo,
} from "./../../../ReduxSaga/Actions/CustomLabels";
import { requestLoggUserActivity } from "./../../../ReduxSaga/Actions/General";

import { ReactComponent as DiscountIcon } from "./../../../../assets/media/images/icons/discountIcon.svg";

const PersonalizedFragrances = (props) => {
  //Notistack
  const { enqueueSnackbar } = useSnackbar();
  //TRANSLATION
  const { t, i18n } = useTranslation();
  //NAVIGATION
  const history = useHistory();
  /// REDUX
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.products = useSelector((state) => state.products);
  appReducers.cart = useSelector((state) => state.cart);
  appReducers.customLabels = useSelector((state) => state.customLabels);
  appReducers.general = useSelector((state) => state.general);

  ///HOOKS
  const [dataNotification, setDataNotification] = useState({});
  const [isLoadingEnabled, setIsLoadingEnabled] = useState(true);
  const [numberProduct, setNumberProduct] = useState(1);
  const [arrayFragances, setArrayFragances] = useState([]);
  const [arrayFragancesControl, setArrayFragancesControl] = useState([]);
  const [product, setProduct] = useState({});
  const [buildImages, setBuildImages] = useState(false);
  const [modifyNumberProductPermited, setModifyNumberProductPermited] =
    useState(true);
  const [dataModfyLabelList, setDataModfyLabelList] = useState({});

  const [typeUser, setTypeUser] = useState("");

  ///EFFECTS

  useEffect(() => {}, []);

  useEffect(() => {
    if (
      props?.match?.hasOwnProperty("params") &&
      props?.match?.params?.hasOwnProperty("itemCode")
    ) {
      const itemCode = props?.match.params.itemCode;

      dispatch(cleanReducerConfigurationCustomLabelByPromo());
      if (arrayFragances.length > 0) {
        setArrayFragances([]);
        setArrayFragancesControl([]);
      }

      if (
        typeof appReducers?.products?.errorLoadAllProducts !== "undefined" &&
        typeof appReducers?.products?.errorLoadTrPrExProducts !== "undefined"
      )
        if (
          !appReducers?.products?.errorLoadAllProducts &&
          !appReducers?.products?.errorLoadTrPrExProducts &&
          itemCode.length > 0
        ) {
          if (i18n.language === "en") {
            if (
              appReducers.products.promotionsEn.find(
                (p) => p.itemCode === itemCode
              )
            ) {
              setProduct(
                appReducers.products.promotionsEn.find(
                  (p) => p.itemCode === itemCode
                )
              );
              dispatch(requestCustomLabelsConfigurationByPromotion(itemCode));
            } else if (
              appReducers.products.productsEn.find(
                (p) => p.itemCode === itemCode
              )
            ) {
              setProduct(
                appReducers.products.productsEn.find(
                  (p) => p.itemCode === itemCode
                )
              );
            }
          } else {
            if (
              appReducers.products.promotionsEs.find(
                (p) => p.itemCode === itemCode
              )
            ) {
              setProduct(
                appReducers.products.promotionsEs.find(
                  (p) => p.itemCode === itemCode
                )
              );
              dispatch(requestCustomLabelsConfigurationByPromotion(itemCode));
            } else if (
              appReducers.products.productsEs.find(
                (p) => p.itemCode === itemCode
              )
            ) {
              setProduct(
                appReducers.products.productsEs.find(
                  (p) => p.itemCode === itemCode
                )
              );
            }
          }
        }
    }
  }, [
    props?.match,
    i18n.language,
    appReducers?.products?.errorLoadAllProducts,
    appReducers?.products?.errorLoadTrPrExProducts,
    appReducers?.products?.productsEs,
    appReducers?.products?.productsEn,
    appReducers?.products?.promotionsEs,
    appReducers?.products?.promotionsEn,
  ]);

  useEffect(() => {
    if (typeof appReducers?.general?.errorLoadFirstCharge !== "undefined") {
      if (!appReducers.general.errorLoadFirstCharge) {
        //debugger;
        //userLogeado
        const { typeUser } = appReducers.general.firstChargeModel;

        if (typeUser === "2") {
          props.setTypePlan("desc");
        }
        setTypeUser(typeUser);
      }
    }
  }, [
    appReducers?.general.errorLoadFirstCharge,
    appReducers?.general.firstChargeModel,
  ]);

  useEffect(() => {
    if (
      props?.match?.hasOwnProperty("params") &&
      props?.match?.params?.hasOwnProperty("orderId") &&
      props?.match?.params?.hasOwnProperty("orderDetailId")
    ) {
      const orderId = parseInt(props?.match.params.orderId);
      const orderDetailId = parseInt(props?.match.params.orderDetailId);
      if (
        typeof appReducers?.products?.errorLoadAllProducts !== "undefined" &&
        typeof appReducers?.products?.errorLoadTrPrExProducts !== "undefined" &&
        props?.infoCartOrder?.hasOwnProperty("code") &&
        props?.infoCartOrder?.code === 100
      ) {
        const details = props?.infoCartOrder?.lines;
        const itemCode = details.find(
          (d) => d.line.idpedidodet === orderDetailId
        ).line.codart;
        const qtyPed = details.find((d) => d.line.idpedidodet === orderDetailId)
          .line.qtyped;
        setNumberProduct(qtyPed);
        setModifyNumberProductPermited(false);
        setDataModfyLabelList({ orderId, orderDetailId });
        if (
          typeof appReducers?.customLabels
            ?.responseCustomLabelsConfigurationByDetail === "undefined"
        )
          dispatch(
            requestCustomLabelsConfigurationByDetail(orderId, orderDetailId)
          );
        if (
          !appReducers?.products?.errorLoadAllProducts &&
          !appReducers?.products?.errorLoadTrPrExProducts &&
          itemCode.length > 0
        ) {
          if (i18n.language === "en") {
            if (
              appReducers.products.promotionsEn.find(
                (p) => p.itemCode === itemCode
              )
            ) {
              setProduct(
                appReducers.products.promotionsEn.find(
                  (p) => p.itemCode === itemCode
                )
              );
            } else if (
              appReducers.products.productsEn.find(
                (p) => p.itemCode === itemCode
              )
            ) {
              setProduct(
                appReducers.products.productsEn.find(
                  (p) => p.itemCode === itemCode
                )
              );
            }
          } else {
            if (
              appReducers.products.promotionsEs.find(
                (p) => p.itemCode === itemCode
              )
            ) {
              setProduct(
                appReducers.products.promotionsEs.find(
                  (p) => p.itemCode === itemCode
                )
              );
            } else if (
              appReducers.products.productsEs.find(
                (p) => p.itemCode === itemCode
              )
            ) {
              setProduct(
                appReducers.products.productsEs.find(
                  (p) => p.itemCode === itemCode
                )
              );
            }
          }
        }
      }
    }
  }, [
    props?.match,
    i18n.language,
    props?.infoCartOrder,
    appReducers?.products?.errorLoadAllProducts,
    appReducers?.products?.errorLoadTrPrExProducts,
    appReducers?.products?.productsEs,
    appReducers?.products?.productsEn,
    appReducers?.products?.promotionsEs,
    appReducers?.products?.promotionsEn,
    appReducers?.customLabels?.responseCustomLabelsConfigurationByDetail,
  ]);

  useEffect(() => {
    const swiperPF = document.getElementById("swiperPersonalizedFragrances");
    if (swiperPF) {
      if (numberProduct >= 2) {
        swiperPF.swiper.slideTo(numberProduct - 1);
      } else {
        swiperPF.swiper.slideTo(0);
      }
    }
  }, [numberProduct]);

  useEffect(() => {
    if (product.hasOwnProperty("itemCode")) {
      setIsLoadingEnabled(false);
    } else {
      setIsLoadingEnabled(true);
    }
  }, [product]);

  useEffect(() => {
    if (
      arrayFragances.length < numberProduct &&
      numberProduct > 0 &&
      product.hasOwnProperty("itemCode")
    ) {
      //console.log("product", product)

      const clone = [...arrayFragances];
      clone.push({
        id: arrayFragances.length + 1,
        itemCode: product?.itemCode,
        image: `https://www.websizusa.net/imgprod/fullsize/${product?.itemCode}_.jpg`,
        label: "",
        template: "",
        isSaved: false,
      });
      setArrayFragancesControl(clone);
      setArrayFragances(clone);
      // arrayFragances.push(product);
    }
  }, [numberProduct, product, arrayFragances]);

  //SE AGREGO UN KIT FIJO
  useEffect(() => {
    if (appReducers?.cart?.cartAddMembership) {
      const AddMembership = appReducers.cart.cartAddMembership;
      if (AddMembership.code === 100) {
        //limpiar el reducer
        dispatch(requestCleanCartReducerAddMembership());

        //traer la nueva orden
        dispatch(requestGetCartOrder());
      }
    }
  }, [appReducers?.cart?.cartAddMembership]);

  useEffect(() => {
    if (
      typeof appReducers?.cart?.cartCurrentOrder !== "undefined" &&
      !props.isOpenFloatingCheckout
    ) {
      switch (appReducers?.cart?.cartCurrentOrder?.code) {
        case 100:
          enqueueSnackbar(t("__message_success_product_add_cart_label"), {
            variant: "success",
          });
          if (
            typeUser !== "2" &&
            typeof appReducers?.cart?.cartOrder !== "undefined"
          ) {
            const { lines } = appReducers.cart.cartOrder;
            let isAddMembership = lines.some(
              (item) => item.line.codart === props.cpMemberships[0].itemCode
            );
            if (props.typePlan === "desc" && !isAddMembership) {
              //si no tiene membresia en la orden debe agregarla
              if (props.cpMemberships.length > 0) {
                dispatch(
                  requestCartOrderAddMembership(
                    -1,
                    0,
                    2,
                    0,
                    props.cpMemberships[0].itemCode
                  )
                );
              } else {
                dispatch(requestCartOrderAddMembership(-1, 0, 2, 0, "02110"));
              }
            }
          }
          //dispatch(cleanCartOrderOperations());
          dispatch(requestGetCartOrder());
          ////////////////////////////////////
          //dispatch(requestGetCartOrder());
          setBuildImages(true);
          dispatch(cleanCartOrderOperations());
          break;
        case -100:
          enqueueSnackbar(t("__message_beauty_boxes_exeded_product_label"), {
            variant: "warning",
          });
          break;
        case -200:
          enqueueSnackbar(
            t("__checkout_order_loading_error_custumer_badge_configuration"),
            {
              variant: "warning",
            }
          );
          break;
        case -300:
          enqueueSnackbar(
            t("__checkout_order_loading_error_custumer_badge_configuration"),
            {
              variant: "warning",
            }
          );
          break;
      }
    }
  }, [appReducers?.cart?.cartCurrentOrder]);

  useEffect(() => {
    if (
      buildImages &&
      props?.infoCartOrder?.hasOwnProperty("code") &&
      props?.infoCartOrder?.code === 100
    ) {
      if (props?.infoCartOrder?.order && props?.infoCartOrder?.order !== null) {
        const orderId = props?.infoCartOrder?.order?.orderId;
        dispatch(requestBuildCustomLabelImagesOnServer(orderId));
        setArrayFragances(arrayFragancesControl);
        setNumberProduct(1);
        setBuildImages(false);
        history.goBack();
      }
    }
  }, [buildImages, props?.infoCartOrder]);

  useEffect(() => {
    if (
      typeof appReducers?.customLabels
        ?.responseCustomLabelsConfigurationByDetail !== "undefined"
    ) {
      if (
        appReducers.customLabels.responseCustomLabelsConfigurationByDetail
          .code === 100
      ) {
        setArrayFragances(
          appReducers.customLabels.responseCustomLabelsConfigurationByDetail
            .customLabelList
        );
      } else {
        enqueueSnackbar(
          t("__checkout_order_loading_error_custumer_badge_configuration"),
          {
            variant: "warning",
          }
        );
      }
    }
  }, [appReducers?.customLabels?.responseCustomLabelsConfigurationByDetail]);
  useEffect(() => {
    if (
      typeof appReducers?.customLabels
        ?.responseCustomLabelsConfigurationByPromo !== "undefined"
    ) {
      if (
        appReducers.customLabels.responseCustomLabelsConfigurationByPromo
          .code === 100
      ) {
        setArrayFragances(
          appReducers.customLabels.responseCustomLabelsConfigurationByPromo
            .customLabelList
        );
        setArrayFragancesControl(
          appReducers.customLabels.responseCustomLabelsConfigurationByPromo
            .customLabelList
        );
      } else {
        enqueueSnackbar(
          t("__checkout_order_loading_error_custumer_badge_configuration"),
          {
            variant: "warning",
          }
        );
      }
    }
  }, [appReducers?.customLabels?.responseCustomLabelsConfigurationByPromo]);
  useEffect(() => {
    if (
      typeof appReducers?.customLabels?.responseUpdateCustomLabelsByDetail !==
      "undefined"
    ) {
      if (
        appReducers.customLabels.responseUpdateCustomLabelsByDetail.code === 100
      ) {
        enqueueSnackbar(t("__message_success_product_add_cart_label"), {
          variant: "success",
        });
        setBuildImages(true);
        const { orderId, orderDetailId } = dataModfyLabelList;
        dispatch(
          requestCustomLabelsConfigurationByDetail(orderId, orderDetailId)
        );
      } else {
        enqueueSnackbar(
          t("__checkout_order_loading_error_custumer_badge_configuration"),
          {
            variant: "warning",
          }
        );
      }
    }
  }, [appReducers?.customLabels?.responseUpdateCustomLabelsByDetail]);

  ///HANDLERS

  const handleAddProduct = () => {
    if (numberProduct >= 0) {
      setNumberProduct(numberProduct + 1);
      const clone = [...arrayFragances];
      const cloneControl = [...arrayFragancesControl];
      cloneControl.forEach((item) => {
        clone.push({ ...item, id: clone.length + 1 });
      });
      setArrayFragances(clone);
    } else {
      setNumberProduct(0);
    }
    //debugger;
    dispatch(
      requestLoggUserActivity(
        81,
        0,
        0,
        product.itemCode,
        "Se incremento en 1 Configuracion del mismo Set (Click en '+')"
      )
    );
  };
  const handleRemoveProduct = () => {
    if (numberProduct - 1 > 0) {
      setNumberProduct(numberProduct - 1);
      let clone = [...arrayFragances];
      const totalLength = clone.length;
      const evaLength = arrayFragancesControl.length;
      //const removalLastIndex = totalLength - evaLength
      clone = clone.slice(0, totalLength - evaLength);

      setArrayFragances(clone);
    }
    //debugger;
    dispatch(
      requestLoggUserActivity(
        81,
        0,
        0,
        product.itemCode,
        "Se elimino la Configuracion del Set (Click en '-')"
      )
    );
  };
  const handleAddToOrder = () => {
    const labels = arrayFragances.map((i) => ({
      id: i.id,
      itemCode: i.itemCode,
      label: i.label,
      template: i.template,
    }));
    const parameters = {
      idPedidoDet: 1,
      idpromo: 1,
      itemCode: product.itemCode,
      opc: 22,
      pedidosD: [],
      quantity: numberProduct,
      type: product.typeLine,
      withAssign: 0,
    };
    dispatch(requestCarCurrentOrder(parameters, "add", labels));

    dispatch(
      requestLoggUserActivity(
        81,
        0,
        0,
        product.itemCode,
        "Se Envia al Servidor Las fragancias personalizadas."
      )
    );
  };
  const handleModifyLabelList = () => {
    //requestUpdateCustomLabelConfigurationByDetail
    if (dataModfyLabelList.hasOwnProperty("orderId")) {
      const { orderId, orderDetailId } = dataModfyLabelList;
      dispatch(
        requestUpdateCustomLabelConfigurationByDetail(
          orderId,
          orderDetailId,
          arrayFragances
        )
      );
    }
  };

  return (
    <div
      id="firstWrapperInBaseContainer"
      className={`firstWrapperInBaseContainer personalizes__fragrance ${
        isTablet ? "tablet" : isMobile ? "mobile" : ""
      }`}
    >
      <div
        className={`personalizes__fragrance__header ${
          isTablet
            ? "tablet"
            : isMobile
            ? "mobile"
            : !isLoadingEnabled
            ? ""
            : "skeletonActive"
        }`}
      >
        {isLoadingEnabled ? (
          <Skeleton
            animation="wave"
            variant="rect"
            width={"20%"}
            height={200}
          />
        ) : (
          <div
            className={`personalizes__fragrance__header__instruccions ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            <div>
              <span className="personalizes__fragrance__header__title">
                {t("__personalized__fragances_title")}
              </span>
              <ul className="personalizes__fragrance__header__list">
                <li className="personalizes__fragrance__header__item">
                  {t("__personalized__fragances_list_item_one")}
                </li>
                <li className="personalizes__fragrance__header__item">
                  {t("__personalized__fragances_list_item_two")}
                </li>
                <li className="personalizes__fragrance__header__item ">
                  {" "}
                  {t("__personalized__fragances_list_item_three")}
                </li>
                <li className="personalizes__fragrance__header__item text__special">
                  {" "}
                  {t("__personalized__fragances_list_item_warning")}
                </li>
              </ul>
            </div>
          </div>
        )}
        {isLoadingEnabled ? (
          <Skeleton
            animation="wave"
            variant="rect"
            width={"20%"}
            height={200}
          />
        ) : (
          <div
            className={`personalizes__fragrance__header__product__info ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            <div className="personalizes__fragrance__header__product__info__inner">
              <div className="personalizes__fragrance__header__product__info__inner__image">
                {product.nameArticle ? (
                  <img
                    alt={`${
                      product.nameArticle
                    } - ${product.descArticle.substring(0, 38)}...`}
                    title={`${
                      product.nameArticle
                    } - ${product.descArticle.substring(0, 38)}...`}
                    src={product?.image}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src =
                        "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
                    }}
                  />
                ) : (
                  <Skeleton variant="rect" width={10} height={"50%"} />
                )}
              </div>
              <div
                className={`personalizes__fragrance__header__product__info__inner__description ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                {product?.nameArticle ? (
                  <span> {capitalizeText(product.nameArticle)}</span>
                ) : (
                  <Skeleton variant="rect" width={"95%"} height={10} />
                )}

                {product?.nameArticle ? (
                  <span>
                    {" "}
                    {product?.regularPrice > 0 &&
                    product?.regularPrice > product?.promotionPrice &&
                    (product?.typeLine === "PD" ||
                      // product?.typeLine === "P" ||
                      // product?.typeLine === "FV" ||
                      // product?.typeLine === "VV" ||
                      // product?.typeLine === "VF" ||
                      product?.typeLine === "3x2") ? (
                      <Fragment>
                        <div className="product__price__with__discount__wrapper">
                          <em className="product__price__promo__normal left__aligned">
                            {formatPrice(product?.regularPrice)}
                          </em>
                          <div className="product__price__with__discount__wrapper__inner">
                            <span>{`- ${percentageUtility(
                              Math.floor(
                                100 -
                                  (product.promotionPrice * 100) /
                                    product.regularPrice
                              )
                            )}`}</span>
                            <DiscountIcon />
                          </div>
                        </div>
                        <em className="product__price__promo left__aligned">
                          {formatPrice(product?.promotionPrice)}
                        </em>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <em className="product__price__normal left__aligned">
                          {formatPrice(product?.regularPrice)}
                        </em>
                      </Fragment>
                    )}
                  </span>
                ) : (
                  <Skeleton variant="rect" width={"95%"} height={10} />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className={`personalizes__fragrance__content ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }`}
      >
        {isLoadingEnabled ? (
          <Skeleton variant="rect" width={"90%"} height={"60vh"} />
        ) : (
          <div className="personalizes__fragrance__content__inner">
            <div className="personalizes__fragrance__content__inner__header">
              <div
                className={`personalizes__fragrance__content__inner__header__title ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                {t("__personalized__fragances_subtitle")}
                {props?.infoCartOrder &&
                  props?.infoCartOrder.lines &&
                  numberProduct &&
                  product &&
                  product.maxSets > 0 &&
                  props?.infoCartOrder.lines.reduce(
                    (accumulator, object) =>
                      object.line.codart === product.itemCode
                        ? accumulator + object.line.qtyped
                        : accumulator,
                    0
                  ) +
                    numberProduct +
                    1 >
                    product.maxSets && (
                    <span
                      className={`personalizes__fragrance__content__inner__header__title__message__warning ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                    >
                      <WarningIcon />
                      {`${t(
                        "__configurables_warning_information_cant_max_pt1"
                      )} ${product.maxSets} ${t(
                        "__configurables_warning_information_cant_max_pt2"
                      )}`}
                    </span>
                  )}
              </div>
              <div className="personalizes__fragrance__content__inner__header__action">
                {modifyNumberProductPermited ? (
                  <ButtonComponent
                    countButton={true}
                    handleActionPrimary={handleRemoveProduct}
                    handleActionSecondary={handleAddProduct}
                    disabledSecundary={
                      props?.infoCartOrder &&
                      props?.infoCartOrder.lines &&
                      numberProduct &&
                      product &&
                      product.maxSets > 0 &&
                      props?.infoCartOrder.lines.reduce(
                        (accumulator, object) =>
                          object.line.codart === product.itemCode
                            ? accumulator + object.line.qtyped
                            : accumulator,
                        0
                      ) +
                        numberProduct +
                        1 >
                        product.maxSets
                    }
                    valueCount={numberProduct}
                    iconClass={
                      numberProduct > 0 ? "bagSmallActiveIcon" : "bagIcon"
                    }
                    textButton=""
                    leftAling={true}
                    ///Personalizar
                  />
                ) : (
                  <ButtonComponent
                    disabledPrimary
                    countButton={false}
                    valueCount={numberProduct}
                    iconClass={"bagIcon"}
                    textButton={numberProduct.toString()}
                    leftAling={true}
                    ///Personalizar
                  />
                )}
              </div>
            </div>

            <div
              className={`personalizes__fragrance__content__inner__cards ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              {arrayFragances?.length > 0 ? (
                <Swiper
                  id="swiperPersonalizedFragrances"
                  slidesPerView={4}
                  spaceBetween={20}
                  initialSlide={0}
                  pagination={{
                    clickable: true,
                  }}
                  calculateheight="true"
                  breakpoints={{
                    320: {
                      slidesPerGroup: 1,
                      slidesPerView: 1,
                      centeredSlides: true,
                      spaceBetween: 30,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 30,
                    },
                    1080: {
                      slidesPerView: 3,
                    },
                    1920: {
                      slidesPerView: 5,
                    },
                  }}
                >
                  {" "}
                  {arrayFragances.map((item, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <CardPersonalizedFragance
                          item={item}
                          listFragancesCustomer={arrayFragances}
                          setCustomLabelList={setArrayFragances}
                          dispatch={dispatch}
                          requestLoggUserActivity={requestLoggUserActivity}
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              ) : (
                <div className="personalizes__fragrance__content__empty">
                  <div>
                    <img src={nothingHere} alt="nothing" />
                  </div>
                  <div>{t("__personalized__fragances_nothing")}</div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="personalizes__fragrance__footer">
        {isLoadingEnabled ? (
          <Skeleton variant="rect" width={200} height={20} />
        ) : (
          <ButtonComponent
            countButton={false}
            disabledPrimary={
              arrayFragances.filter((a) => !a.isSaved).length > 0
            }
            handleActionPrimary={() => {
              //debugger;
              let infoCartOrder = props?.infoCartOrder;

              let CountInOrder = infoCartOrder.lines.reduce(
                (accumulator, object) =>
                  object.line.codart === product.itemCode
                    ? accumulator + object.line.qtyped
                    : accumulator,
                0
              );
              let CountConfig = numberProduct;

              if (
                product.maxSets &&
                CountInOrder + CountConfig > product.maxSets
              ) {
                let e = product.maxSets - CountInOrder;
                enqueueSnackbar(t("__general_warning_information_cant_max"), {
                  variant: "warning",
                });
              } else {
                if (modifyNumberProductPermited) {
                  handleAddToOrder();
                } else {
                  handleModifyLabelList();
                }
              }
            }}
            iconClass={
              arrayFragances.filter((a) => !a.isSaved).length > 0
                ? "bagGrayIcon"
                : "bagWhiteIcon"
            }
            textButton={t("btn_text_continue_shop")}
            leftAling={true}
            ///Personalizar
          />
        )}
      </div>
    </div>
  );
};

export default withRouter(PersonalizedFragrances);
