import {
    RESPONSE_FOOTER_MODULE_INFO
} from '../../Constants'


export default function footer(state = {}, action) {
    switch (action.type) {
        case RESPONSE_FOOTER_MODULE_INFO:
            {
                const responseFooterModule = action.payload.response;
                if(responseFooterModule.code === 100)
                {
                    const footerModuleEs = responseFooterModule.footer.sections.filter(item => item.idLang === 1)
                    const footerModuleEn = responseFooterModule.footer.sections.filter(item => item.idLang === 2)
                    return { ...state, errorLoadFooterModule: false, footerModuleEs, footerModuleEn}
                }
                else
                {
                    return { ...state, errorLoadFooterModule: true }
                }
                
                
            }
        default:
            return { ...state }
    }
}