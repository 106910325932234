import {
    RESPONSE_INSTAGRAM_POSTS
} from './../../Constants'

export default function facebookAPI(state = {}, action) {
    switch (action.type) {

        case RESPONSE_INSTAGRAM_POSTS:
            {
                var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

                const responseFacebookAPI = action.payload.response
                let responseInstagramPosts = [];
                let responseInstagramProfile = {};

                if (responseFacebookAPI.length > 0) {
                    responseFacebookAPI.map((element) => {
                        if (element?.instagram_business_accounts)
                        {
                            if(element.instagram_business_accounts.data.length > 0 )
                            {
                                element.instagram_business_accounts.data.map((posts)=>{
                                    if(posts.media.data.length > 0) {
                                        
                                        posts.media.data.map((post,idx) => {

                                            var date = (new Date(post.timestamp)).toLocaleDateString("en-US", options)
                                            post.dateFormat = date;

                                            responseInstagramPosts.push(post)
                                        })
                                        
                                        //var date = new Date(responseInstagramPosts[0].timestamp)                                        
                                        //console.log(date.toLocaleDateString("en-US", options))
                                        
                                        responseInstagramProfile.profile_picture_url = posts.profile_picture_url
                                        responseInstagramProfile.media_count = posts.media_count
                                        responseInstagramProfile.username = posts.username
                                        responseInstagramProfile.biography = posts.biography
                                    }
                                })                                
                            }
                        }                            
                    })
                }

                if(responseInstagramPosts.length>0){
                    return { ...state, instagramPosts: responseInstagramPosts, instagramProfile:responseInstagramProfile, errorInstagramPosts: false }
                }else{
                    console.error("error",responseFacebookAPI )
                    return { ...state, errorInstagramPosts: true }
                }

            }
        default:
            return { ...state }
    }
}