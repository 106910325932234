import {
    RESPONSE_NAVBAR_MODULE_INFO
} from '../../Constants'


export default function navbar(state = {}, action) {
    switch (action.type) {
        case RESPONSE_NAVBAR_MODULE_INFO:
            {
                const responseNavbarModule = action.payload.response;
                if(responseNavbarModule.code === 100)
                {
                    const navigationBar = responseNavbarModule.navigationBar;
                    return { ...state, errorLoadNavBarModule: false, navigationBar}
                }
                else
                {
                    return { ...state, errorLoadNavBarModule: true }
                }
                
                
            }
        default:
            return { ...state }
    }
}