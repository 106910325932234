import { call, put, takeLatest, all } from "redux-saga/effects";
import {
  REQUEST_CLIENT_LOGIN,
  RESPONSE_CLIENT_LOGIN,
  REQUEST_REGISTER_NEW_CLIENT,
  RESPONSE_REGISTER_NEW_CLIENT,
  REQUEST_CLIENT_INFORMATION,
  RESPONSE_CLIENT_INFORMATION,
  REQUEST_UPDATE_CLIENT_INFORMATION,
  RESPONSE_UPDATE_CLIENT_INFORMATION,
  REQUEST_WEBPAGE_CONTACT_REGISTER,
  RESPONSE_WEBPAGE_CONTACT_REGISTER,
  REQUEST_EMAIL_FROM_RETARGETING_PROGRAM,
  RESPONSE_EMAIL_FROM_RETARGETING_PROGRAM,
  REQUEST_CLIENT_ADMINISTRATION_MENU,
  RESPONSE_CLIENT_ADMINISTRATION_MENU,
  REQUEST_CLIENT_LOGOUT,
  DATA_FETCH_FAILED,
  RESPONSE_PASSWORD_RECOVERY,
  REQUEST_PASSWORD_RECOVERY,
  RESPONSE_VALIDATE_PASSWORD_RECOVERY_CODE,
  REQUEST_VALIDATE_PASSWORD_RECOVERY_CODE,
  REQUEST_RESET_USER_PASSWORD,
  RESPONSE_RESET_USER_PASSWORD,
  RESPONSE_GET_CLIENT_CONFIRMED_ORDERS,
  REQUEST_GET_CLIENT_CONFIRMED_ORDERS,
  RESPONSE_VALIDATE_INITIAL_BANNER,
  REQUEST_VALIDATE_INITIAL_BANNER,
  RESPONSE_VALIDATE_IDTYPE,
  REQUEST_VALIDATE_IDTYPE,
  RESPONSE_UPDATE_DATA_FROM_CHECKOUT,
  REQUEST_UPDATE_DATA_FROM_CHECKOUT,
  RESPONSE_INSERT_FISCAL_ADDRESS,
  REQUEST_INSERT_FISCAL_ADDRESS,
  RESPONSE_VALIDATE_OWNER_EMAIL,
  REQUEST_VALIDATE_OWNER_EMAIL,
  RESPONSE_VALIDATE_OWNER_PHONE,
  REQUEST_VALIDATE_OWNER_PHONE,
  REQUEST_GET_DATA_SPONSOR_BY_ID,
  RESPONSE_GET_DATA_SPONSOR_BY_ID,
  REQUEST_GET_DATA_SPONSOR_BY_NAME,
  RESPONSE_GET_DATA_SPONSOR_BY_NAME,
  REQUEST_GET_DATA_SPONSOR_BY_CITY,
  RESPONSE_GET_DATA_SPONSOR_BY_CITY,
  REQUEST_ASSIGN_SPONSOR,
  RESPONSE_ASSIGN_SPONSOR,
  REQUEST_UPDATE_SHIPPING_ADDRESS,
  RESPONSE_UPDATE_SHIPPING_ADDRESS,
  REQUEST_CLIENT_UPLOAD_IMAGE_PROFILE,
  RESPONSE_CLIENT_UPLOAD_IMAGE_PROFILE,
  REQUEST_CLIENT_REGISTER_QUESTION_SUPPORT,
  RESPONSE_CLIENT_REGISTER_QUESTION_SUPPORT,
  REQUEST_GET_DATA_SPONSOR_AUTOMATIC,
  RESPONSE_GET_DATA_SPONSOR_AUTOMATIC,
  REQUEST_ASSIGN_SPONSOR_AUTOMATIC,
  RESPONSE_ASSIGN_SPONSOR_AUTOMATIC,
  REQUEST_CLIENT_IMMEDIATE_GAINS,
  RESPONSE_CLIENT_IMMEDIATE_GAINS,
  REQUEST_LOGG_USER_ACTIVITY,
  RESPONSE_LOGG_USER_ACTIVITY,
  REQUEST_CLIENT_GUEST_INFORMATION_EMAIL,
  RESPONSE_CLIENT_GUEST_INFORMATION_EMAIL,
  REQUEST_NEWSLETTER_SUSCRIBE_EVALUATION,
  RESPONSE_NEWSLETTER_SUSCRIBE_EVALUATION,
  RESPONSE_FIRST_CHARGE_SITE_RELOAD,
  REQUEST_NEWMEMBER_CLOSE_ACTUAL_SESSION,
  REQUEST_USER_DATA_VALIDATION,
  RESPONSE_USER_DATA_VALIDATION,
  /////
  REQUEST_REGISTRATION_LOGIN_DATA,
  RESPONSE_REGISTRATION_LOGIN_DATA,
  REQUEST_NEW_REGISTRATION_CHECKOUT_DATA,
  RESPONSE_NEW_REGISTRATION_CHECKOUT_DATA,
  REQUEST_UPDATE_ADDRESS_CHECKOUT_DATA,
  RESPONSE_UPDATE_ADDRESS_CHECKOUT_DATA,
} from "../../Constants";
import Client from "../../../../Libraries/Client";
import { requestFirstChargeSite } from "./../../Actions/General";

function* requestClienLogin(data) {
  try {
    const response = yield call(Client.ClientLogin, data.payload.user);
    yield put({
      type: RESPONSE_CLIENT_LOGIN,
      payload: { response: response.userLogin },
    });
    if (response.userLogin.response.code === 100)
      yield put({
        type: RESPONSE_FIRST_CHARGE_SITE_RELOAD,
        payload: { firstChargeModel: response.userLogin.userData },
      });
  } catch (error) {
    console.error("client login error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

function* requestRegisterNewClient(data) {
  try {
    const response = yield call(
      Client.NewClientRegister,
      data.payload.newRegister
    );
    yield put({
      type: RESPONSE_REGISTER_NEW_CLIENT,
      payload: { response: response.userNewRegister },
    });
    if (response.userLogin.response.code === 100)
      yield put({
        type: RESPONSE_FIRST_CHARGE_SITE_RELOAD,
        payload: { firstChargeModel: response.userLogin.userData },
      });
  } catch (error) {
    console.error("client register new client error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

function* requestClientInformation(data) {
  try {
    // const response = yield call(Client.GetClientInformation, data.payload.sessionId)
    // yield put({ type: RESPONSE_CLIENT_INFORMATION, payload: { response: response.getUserInformation } })

    const [clientInformation, membershipInformation] = yield all([
      call(Client.GetClientInformation, data.payload.sessionId),
      call(Client.GetMembershipInformation, data.payload.sessionId),
    ]);
    yield put({
      type: RESPONSE_CLIENT_INFORMATION,
      payload: {
        response: clientInformation.getUserInformation,
        membershipInformation: membershipInformation.getMembershipInformation,
      },
    });
  } catch (error) {
    console.error("getting user information error", error.statusText);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

function* requestUpdateClientInformation(data) {
  try {
    const response = yield call(
      Client.UpdateClientPersonalInformation,
      data.payload.model
    );
    yield put({
      type: RESPONSE_UPDATE_CLIENT_INFORMATION,
      payload: { response: response.updateUserData },
    });
  } catch (error) {
    console.error("client update personal information error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

function* requestRegisterWebPageContact(data) {
  try {
    const response = yield call(
      Client.WebPageContactRegister,
      data.payload.sessionId,
      data.payload.name,
      data.payload.cellPhone,
      data.payload.comment
    );
    yield put({
      type: RESPONSE_WEBPAGE_CONTACT_REGISTER,
      payload: { response: response.sendPendingContact },
    });
  } catch (error) {
    console.error("client contact webpage register error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

function* requestClientAdministrationMenu() {
  try {
    const response = yield call(Client.GetMenuClientAdministration);
    yield put({
      type: RESPONSE_CLIENT_ADMINISTRATION_MENU,
      payload: { response: response.getDataAdministratorMenu },
    });
  } catch (error) {
    console.error("client administration menu get error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}
function* requestClientLogOut(data) {
  try {
    const sessionId = data?.payload?.modelFirstCharge?.sessionId;
    if (sessionId > 0) {
      const responseCloseSessionOnServer = yield call(
        Client.CloseSessionOnServer,
        sessionId
      );
    }
    data.payload.modelFirstCharge.sessionId = 0;
    data.payload.modelFirstCharge.userId = "";

    yield put(requestFirstChargeSite(data.payload.modelFirstCharge));
  } catch (error) {
    console.error("client log out error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

function* requestClientLogOutAndChargeNewMember(data) {
  try {
    const sessionId = data?.payload?.modelFirstCharge?.sessionId;
    if (sessionId > 0) {
      const responseCloseSessionOnServer = yield call(
        Client.CloseSessionOnServer,
        sessionId
      );
    }
    data.payload.modelFirstCharge.sessionId = 0;
    data.payload.modelFirstCharge.userId = "";

    yield put(requestFirstChargeSite(data.payload.modelFirstCharge));
  } catch (error) {
    console.error("client log out error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

function* requestEmailForRetargetingProgram(data) {
  try {
    const response = yield call(
      Client.SendEmailFromRetargetingProgram,
      data.payload.sessionId,
      data.payload.email
    );
    yield put({
      type: RESPONSE_EMAIL_FROM_RETARGETING_PROGRAM,
      payload: { response: response.sendEmailFinalClient },
    });
  } catch (error) {
    console.error("send email from retargeting program error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

function* requestPasswordRecovery(data) {
  try {
    const response = yield call(
      Client.RecoveryUserPassword,
      data.payload.recoveryType,
      data.payload.cellPhone,
      data.payload.email
    );
    yield put({
      type: RESPONSE_PASSWORD_RECOVERY,
      payload: { response: response.userPasswordRecovery },
    });
  } catch (error) {
    console.error("request password recovery program error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

function* requestValidationPasswordRecoveryCode(data) {
  try {
    const response = yield call(
      Client.ValidationRecoveryPasswordCode,
      data.payload.code
    );
    yield put({
      type: RESPONSE_VALIDATE_PASSWORD_RECOVERY_CODE,
      payload: { response: response.userRecoveryCodeValidation },
    });
  } catch (error) {
    console.error("validate password recovery code program error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

function* requestResetUserPassword(data) {
  try {
    const response = yield call(
      Client.ResetUserPassword,
      data.payload.clientId,
      data.payload.password
    );
    yield put({
      type: RESPONSE_RESET_USER_PASSWORD,
      payload: { response: response.userResetPassword },
    });
  } catch (error) {
    console.error("reset password program error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

function* requestClientConfirmedOrders(data) {
  try {
    const response = yield call(
      Client.GetClientConfirmedOrders,
      data.payload.sessionId
    );
    yield put({
      type: RESPONSE_GET_CLIENT_CONFIRMED_ORDERS,
      payload: { response: response.getUserConfirmedOrders },
    });
  } catch (error) {
    console.error("request confirmed order program error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

function* requestValidateInitialBanner(data) {
  try {
    const response = yield call(
      Client.ValidateInitialBannerFlag,
      data.payload.sessionId
    );
    yield put({
      type: RESPONSE_VALIDATE_INITIAL_BANNER,
      payload: { response: response.validateBannerFlag },
    });
  } catch (error) {
    console.error("request show initail banner program error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}
function* requestValidateIdentificationType(data) {
  try {
    const response = yield call(
      Client.ValidateIdentificationType,
      data.payload.clientId,
      data.payload.idType,
      data.payload.idNumber
    );
    yield put({
      type: RESPONSE_VALIDATE_IDTYPE,
      payload: { response: response.validateIdentificationType },
    });
  } catch (error) {
    console.error("request validate id type program error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}
function* requestUpdateDateFromCheckout(data) {
  try {
    const response = yield call(
      Client.UpdateDataFromCheckoutForm,
      data.payload.model
    );
    yield put({
      type: RESPONSE_UPDATE_DATA_FROM_CHECKOUT,
      payload: { response: response.updateCheckoutData },
    });
    if (response.updateCheckoutData.response.code === 100)
      yield put({
        type: RESPONSE_FIRST_CHARGE_SITE_RELOAD,
        payload: { firstChargeModel: response.updateCheckoutData.userData },
      });
  } catch (error) {
    console.error("request validate id type program error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}
function* requestInsertFiscalAddress(data) {
  try {
    const response = yield call(
      Client.InsertFiscalAddress,
      data.payload.clientId,
      data.payload.street,
      data.payload.suite,
      data.payload.zip,
      data.payload.state,
      data.payload.city
    );
    yield put({
      type: RESPONSE_INSERT_FISCAL_ADDRESS,
      payload: { response: response.insertFiscalAddress },
    });
  } catch (error) {
    console.error("request insert fiscal address program error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}
function* requestValidateOwnerEmail(data) {
  try {
    const response = yield call(
      Client.ValidateEmailOwner,
      data.payload.sessionId,
      data.payload.email
    );
    yield put({
      type: RESPONSE_VALIDATE_OWNER_EMAIL,
      payload: { response: response.validateOwnerEmail },
    });
  } catch (error) {
    console.error("request validate owner email program error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}
function* requestValidateOwnerPhone(data) {
  try {
    const response = yield call(
      Client.ValidatePhoneOwner,
      data.payload.sessionId,
      data.payload.phone
    );
    yield put({
      type: RESPONSE_VALIDATE_OWNER_PHONE,
      payload: { response: response.validateOwnerPhone },
    });
  } catch (error) {
    console.error("request validate owner phone program error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}
function* requestGetDataSponsorById(data) {
  try {
    const response = yield call(
      Client.GetDataSponsorById,
      data.payload.sponsorId
    );
    yield put({
      type: RESPONSE_GET_DATA_SPONSOR_BY_ID,
      payload: { response: response.getDataSponsorById },
    });
  } catch (error) {
    console.error("request validate owner phone program error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}
function* requestGetDataSponsorByName(data) {
  try {
    const response = yield call(
      Client.GetDataSponsorByName,
      data.payload.name,
      data.payload.lastName
    );
    yield put({
      type: RESPONSE_GET_DATA_SPONSOR_BY_NAME,
      payload: { response: response.getDataSponsorByName },
    });
  } catch (error) {
    console.error("request validate owner phone program error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}
function* requestGetDataSponsorByCity(data) {
  try {
    const response = yield call(
      Client.GetDataSponsorByCity,
      data.payload.idState,
      data.payload.city
    );
    yield put({
      type: RESPONSE_GET_DATA_SPONSOR_BY_CITY,
      payload: { response: response.getDataSponsorByCity },
    });
  } catch (error) {
    console.error("request validate owner phone program error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}
function* requestAssignSponsor(data) {
  try {
    const response = yield call(
      Client.AssignSponsor,
      data.payload.sponsorId,
      data.payload.sessionId
    );
    yield put({
      type: RESPONSE_ASSIGN_SPONSOR,
      payload: { response: response.assignSponsor },
    });
  } catch (error) {
    console.error("request validate owner phone program error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}
function* requestUpdateShippingAddress(data) {
  try {
    const response = yield call(
      Client.UpdateShippingAddress,
      data.payload.sessionId,
      data.payload.street,
      data.payload.suite,
      data.payload.zip,
      data.payload.state,
      data.payload.city
    );
    yield put({
      type: RESPONSE_UPDATE_SHIPPING_ADDRESS,
      payload: { response: response.updateShippingAddress },
    });
  } catch (error) {
    console.error("request insert fiscal address program error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

function* requestUploadImage(data) {
  try {
    const response = yield call(
      Client.UploadImageProfile,
      data.payload.data,
      data.payload.fileName
    );
    yield put({
      type: RESPONSE_CLIENT_UPLOAD_IMAGE_PROFILE,
      payload: { response: response.uploadImageProfile },
    });
  } catch (error) {
    console.error("client upload image error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

function* requestRegisterSupportQuestion(data) {
  try {
    const response = yield call(
      Client.RegisterSupportQuestion,
      data.payload.model
    );
    yield put({
      type: RESPONSE_CLIENT_REGISTER_QUESTION_SUPPORT,
      payload: { response: response.registerSupportQuestion },
    });
  } catch (error) {
    console.error("client register support question error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

function* requestImmediateGains(data) {
  try {
    const response = yield call(
      Client.RequestImmediateGains,
      data.payload.model
    );
    yield put({
      type: RESPONSE_CLIENT_IMMEDIATE_GAINS,
      payload: { response: response.requestImmediateGains },
    });
  } catch (error) {
    console.error("client register immediate gains error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

function* requestGetDataSponsorAutomatic() {
  try {
    const response = yield call(Client.GetDataSponsorAutomatic);
    yield put({
      type: RESPONSE_GET_DATA_SPONSOR_AUTOMATIC,
      payload: { response: response.getDataSponsorAutomatic },
    });
  } catch (error) {
    console.error("request validate owner phone program error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}
function* requestAssignSponsorAutomatic(data) {
  try {
    const response = yield call(
      Client.AssignSponsorAutomatic,
      data.payload.sponsorId,
      data.payload.sessionId
    );
    yield put({
      type: RESPONSE_ASSIGN_SPONSOR_AUTOMATIC,
      payload: { response: response.assignSponsor },
    });
  } catch (error) {
    console.error("request validate owner phone program error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}
function* requestLoggUserActivity(data) {
  try {
    const response = yield call(
      Client.LoggUserActivity,
      data.payload.sessionId,
      data.payload.topicItemId,
      data.payload.idSubCategory,
      data.payload.idCategory,
      data.payload.productSku,
      data.payload.aditionalInfo
    );
    yield put({
      type: RESPONSE_LOGG_USER_ACTIVITY,
      payload: { response: response.loggUserActivity },
    });
  } catch (error) {
    console.error("request validate owner phone program error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}
function* requestClientGuestInformationEmail(data) {
  try {
    const response = yield call(
      Client.GetClientGuestInfoByEmail,
      data.payload.email
    );
    yield put({
      type: RESPONSE_CLIENT_GUEST_INFORMATION_EMAIL,
      payload: { response: response.getClientGuestInfoByEmail },
    });
  } catch (error) {
    console.error("request validate owner phone program error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}
function* requestNewsLetterSubscriptionEvaluation(data) {
  try {
    const response = yield call(
      Client.VerifyNewsLetterSubscription,
      data.payload.email
    );
    yield put({
      type: RESPONSE_NEWSLETTER_SUSCRIBE_EVALUATION,
      payload: { response: response.requestVerificationNewsLetterSubscription },
    });
  } catch (error) {
    console.error("request verification newsletter suscription", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}
function* requestDataValidationUser(data) {
  try {
    const response = yield call(
      Client.GetUserDataValidation,
      data.payload.model
    );
    yield put({
      type: RESPONSE_USER_DATA_VALIDATION,
      payload: { response: response.validateUserData },
    });
  } catch (error) {
    console.error("request verification USER DATA", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

//New
function* requestRegistrationLoginData(data) {
  try {
    const response = yield call(
      Client.NewRegistrationLoginData,
      data.payload.model
    );
    yield put({
      type: RESPONSE_REGISTRATION_LOGIN_DATA,
      payload: { response: response.newRegistrationLoginData },
    });
  } catch (error) {
    console.error("Error requesting registrationLoginData", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}
function* requestNewRegistrationCheckoutData(data) {
  try {
    const response = yield call(
      Client.NewRegistrationCheckoutData,
      data.payload.model
    );
    yield put({
      type: RESPONSE_NEW_REGISTRATION_CHECKOUT_DATA,
      payload: { response: response.newRegistrationCheckoutData },
    });
  } catch (error) {
    console.error("Error requesting registrationCheckoutData", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}
function* requestUpdateAddressCheckoutData(data) {
  try {
    const response = yield call(
      Client.UpdateAddressCheckoutData,
      data.payload.model
    );
    yield put({
      type: RESPONSE_UPDATE_ADDRESS_CHECKOUT_DATA,
      payload: { response: response.updateAddressCheckoutData },
    });
  } catch (error) {
    console.error("Error requesting updateAddressCheckoutData", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

// Watchers
export function* watchRequestClienLogin() {
  yield takeLatest(REQUEST_CLIENT_LOGIN, requestClienLogin);
}
export function* watchRequestRegisterNewClient() {
  yield takeLatest(REQUEST_REGISTER_NEW_CLIENT, requestRegisterNewClient);
}
export function* watchRequestClientInformation() {
  yield takeLatest(REQUEST_CLIENT_INFORMATION, requestClientInformation);
}
export function* watchRequestUpdateClientInformation() {
  yield takeLatest(
    REQUEST_UPDATE_CLIENT_INFORMATION,
    requestUpdateClientInformation
  );
}
export function* watchRequestRegisterWebPageContact() {
  yield takeLatest(
    REQUEST_WEBPAGE_CONTACT_REGISTER,
    requestRegisterWebPageContact
  );
}
export function* watchRequestClientAdministrationMenu() {
  yield takeLatest(
    REQUEST_CLIENT_ADMINISTRATION_MENU,
    requestClientAdministrationMenu
  );
}
export function* watchRequestClientLogOut() {
  yield takeLatest(REQUEST_CLIENT_LOGOUT, requestClientLogOut);
}
export function* watchRequestClientLogOutAndChargeNewMember() {
  yield takeLatest(
    REQUEST_NEWMEMBER_CLOSE_ACTUAL_SESSION,
    requestClientLogOutAndChargeNewMember
  );
}
export function* watchRequestEmailForRetargetingProgram() {
  yield takeLatest(
    REQUEST_EMAIL_FROM_RETARGETING_PROGRAM,
    requestEmailForRetargetingProgram
  );
}
export function* watchRequestPasswordRecovery() {
  yield takeLatest(REQUEST_PASSWORD_RECOVERY, requestPasswordRecovery);
}
export function* watchRequestValidationPasswordRecoveryCode() {
  yield takeLatest(
    REQUEST_VALIDATE_PASSWORD_RECOVERY_CODE,
    requestValidationPasswordRecoveryCode
  );
}
export function* watchRequestResetUserPassword() {
  yield takeLatest(REQUEST_RESET_USER_PASSWORD, requestResetUserPassword);
}
export function* watchRequestClientConfirmedOrders() {
  yield takeLatest(
    REQUEST_GET_CLIENT_CONFIRMED_ORDERS,
    requestClientConfirmedOrders
  );
}
export function* watchRequestValidateInitialBanner() {
  yield takeLatest(
    REQUEST_VALIDATE_INITIAL_BANNER,
    requestValidateInitialBanner
  );
}
export function* watchRequestValidateIdentificationType() {
  yield takeLatest(REQUEST_VALIDATE_IDTYPE, requestValidateIdentificationType);
}
export function* watchRequestUpdateDateFromCheckout() {
  yield takeLatest(
    REQUEST_UPDATE_DATA_FROM_CHECKOUT,
    requestUpdateDateFromCheckout
  );
}
export function* watchRequestInsertFiscalAddress() {
  yield takeLatest(REQUEST_INSERT_FISCAL_ADDRESS, requestInsertFiscalAddress);
}
export function* watchRequestValidateOwnerEmail() {
  yield takeLatest(REQUEST_VALIDATE_OWNER_EMAIL, requestValidateOwnerEmail);
}
export function* watchRequestValidateOwnerPhone() {
  yield takeLatest(REQUEST_VALIDATE_OWNER_PHONE, requestValidateOwnerPhone);
}
export function* watchRequestGetDataSponsorById() {
  yield takeLatest(REQUEST_GET_DATA_SPONSOR_BY_ID, requestGetDataSponsorById);
}
export function* watchRequestGetDataSponsorByName() {
  yield takeLatest(
    REQUEST_GET_DATA_SPONSOR_BY_NAME,
    requestGetDataSponsorByName
  );
}
export function* watchRequestGetDataSponsorByCity() {
  yield takeLatest(
    REQUEST_GET_DATA_SPONSOR_BY_CITY,
    requestGetDataSponsorByCity
  );
}
export function* watchRequestAssignSponsor() {
  yield takeLatest(REQUEST_ASSIGN_SPONSOR, requestAssignSponsor);
}
export function* watchRequestUpdateShippingAddress() {
  yield takeLatest(
    REQUEST_UPDATE_SHIPPING_ADDRESS,
    requestUpdateShippingAddress
  );
}
export function* watchRequestUploadImage() {
  yield takeLatest(REQUEST_CLIENT_UPLOAD_IMAGE_PROFILE, requestUploadImage);
}
export function* watchRequestRegisterSupportQuestion() {
  yield takeLatest(
    REQUEST_CLIENT_REGISTER_QUESTION_SUPPORT,
    requestRegisterSupportQuestion
  );
}
export function* watchRequestImmediateGains() {
  yield takeLatest(REQUEST_CLIENT_IMMEDIATE_GAINS, requestImmediateGains);
}
export function* watchRequestGetDataSponsorAutomatic() {
  yield takeLatest(
    REQUEST_GET_DATA_SPONSOR_AUTOMATIC,
    requestGetDataSponsorAutomatic
  );
}
export function* watchRequestAssignSponsorAutomatic() {
  yield takeLatest(
    REQUEST_ASSIGN_SPONSOR_AUTOMATIC,
    requestAssignSponsorAutomatic
  );
}
export function* watchRequestLoggUserActivity() {
  yield takeLatest(REQUEST_LOGG_USER_ACTIVITY, requestLoggUserActivity);
}
export function* watchRequestClientGuestInformationEmail() {
  yield takeLatest(
    REQUEST_CLIENT_GUEST_INFORMATION_EMAIL,
    requestClientGuestInformationEmail
  );
}
export function* watchRequestNewsLetterSubscriptionEvaluation() {
  yield takeLatest(
    REQUEST_NEWSLETTER_SUSCRIBE_EVALUATION,
    requestNewsLetterSubscriptionEvaluation
  );
}
export function* watchRequestDataValidationUser() {
  yield takeLatest(REQUEST_USER_DATA_VALIDATION, requestDataValidationUser);
}
/////
export function* watchRequestRegistrationLoginData() {
  yield takeLatest(
    REQUEST_REGISTRATION_LOGIN_DATA,
    requestRegistrationLoginData
  );
}
export function* watchRequestNewRegistrationCheckoutData() {
  yield takeLatest(
    REQUEST_NEW_REGISTRATION_CHECKOUT_DATA,
    requestNewRegistrationCheckoutData
  );
}
export function* watchRequestUpdateAddressCheckoutData() {
  yield takeLatest(
    REQUEST_UPDATE_ADDRESS_CHECKOUT_DATA,
    requestUpdateAddressCheckoutData
  );
}
