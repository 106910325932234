import { call, put, takeLatest } from 'redux-saga/effects'
import {
    REQUEST_CLIENT_ADDRESS_VALIDATION,
    RESPONSE_CLIENT_ADDRESS_VALIDATION,
    DATA_FETCH_FAILED,
} from './../../Constants'
import Address from '../../../../Libraries/Address'

function* requestClientAddressValidation(data){
    try {
        const response = yield call(Address.AddressValidation,data.payload.jsonAddress, data.payload.type, data.payload.dbCheck);
        yield put({type: RESPONSE_CLIENT_ADDRESS_VALIDATION, payload: {response:response.clientSession_AddressValidate, type: data.payload.type}});

    } catch (error) {
        console.error("client address validation", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}


export function* watchRequestClientAddressValidation() {
    yield takeLatest(REQUEST_CLIENT_ADDRESS_VALIDATION, requestClientAddressValidation);
}

