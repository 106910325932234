import React from "react";
import { Typography, Button } from "@mui/material";
import { SettingsBackupRestore } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import ModalConfirmRepayment from "../components/ModalConfirmRepayment";
import ItemReturnRepayment from "./ItemReturnRepayment";
import { isMobile, isTablet } from "react-device-detect";

const ListRepayment = ({
  isAllOrder,
  setContenShow,
  finalArrayReturnRender,
  orderSelected,

  isOpenNotification,
  setIsOpenNotification,
  isOpenModalConfirm,
  setIsOpenModalConfirm,

  sendPetitionRefund,

  setFullCancellationType,
  setReasonCompleteCancellation,
}) => {
  /// TRANSLATION
  const { t } = useTranslation();
  ///// hooks
  const handleModalConfirm = () => {
    setIsOpenModalConfirm(true);
  };

  return (
    <div
      id="firstWrapperInBaseContainer"
      className="firstWrapperInBaseContainer"
    >
      {isOpenModalConfirm && (
        <ModalConfirmRepayment
          isOpenModalConfirm={isOpenModalConfirm}
          setIsOpenModalConfirm={setIsOpenModalConfirm}
          setContenShow={setContenShow}
          setIsOpenNotification={setIsOpenNotification}
          sendPetitionRefund={sendPetitionRefund}
        />
      )}
      <div className=" return__repayment__container">
        <div className="detail__shopping__container__title">
          <Typography variant="h6" gutterBottom>
            {t("__profile_Detail_shopping_list_return_title")}
          </Typography>
        </div>

        <div className="return__repayment__container__order__detail__wrapper">
          <div className="return__repayment__container__order__detail__wrapper__left">
            <Typography variant="caption" display="block" gutterBottom>
              {t("__profile_Detail_list_number_order_text")}
              {orderSelected.idPedido}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              {t("__profile_Detail_list_date_order_text")}
              {orderSelected.fecPedido}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              {t("__profile_Detail_list_paymethod_order_text")} VISA
            </Typography>
          </div>
          <div className="return__repayment__container__order__detail__wrapper__right">
            {/* EN ESTE PUNTO AUN NO SE GENERA FOLIO 
            <Typography variant="caption" display="block" gutterBottom>
              <em>{t("__profile_Detail_list_return_order_text")} XXXXXX</em>
            </Typography> 
            */}
            <Typography variant="caption" display="block" gutterBottom>
              {t("__profile_repayment_quantity_label")}
              {finalArrayReturnRender
                .filter((item) => item.cancellationType === 2)
                .reduce((e, i) => e + i.quantity, 0)}{" "}
              pz
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              {t("__profile_Detail_list_amount_order_text")}$
              {finalArrayReturnRender
                .filter((item) => item.cancellationType === 1)
                .reduce((e, i) => e + i.amount, 0)
                .toFixed(2)}
            </Typography>
          </div>
        </div>
        {finalArrayReturnRender && (
          <div
            className={`return__repayment__container__order__detail__wrapper__products ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            <div className="return__repayment__container__order__detail__wrapper__products__repayment">
              <Typography variant="h6" gutterBottom>
                {t("__profile_repayment_repayment_label")}
              </Typography>
              <div className="return__repayment__container__order__detail__wrapper__products__repayment__list">
                <ItemReturnRepayment
                  finalArrayReturnRender={finalArrayReturnRender.filter(
                    (item) => item.cancellationType === 1
                  )}
                />
              </div>
            </div>
            <div className="return__repayment__container__order__detail__wrapper__products__remplace">
              <Typography variant="h6" gutterBottom>
                {t("__profile_repayment_replacement_label")}
              </Typography>
              <div className="return__repayment__container__order__detail__wrapper__products__repayment__list">
                <ItemReturnRepayment
                  finalArrayReturnRender={finalArrayReturnRender.filter(
                    (item) => item.cancellationType === 2
                  )}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="return__repayment__container__wrapper">
        <div className="return__repayment__container__wrapper__back">
          <Button
            onClick={() => {
              setContenShow("");
              setReasonCompleteCancellation(0);
              setFullCancellationType(0);
            }}
            className="button__with__icon bg__pink no__left__margin"
          >
            <div className="button__with__icon__svg__wrapper pink__ball">
              <SettingsBackupRestore />
            </div>
            <span className="text__red">
              {t("__kit_configuration_btn_back_Label")}
            </span>
          </Button>
        </div>
        <div className="return__repayment__container__wrapper__btn">
          <Button
            onClick={handleModalConfirm}
            className="button__with__icon bg__dark__pink no__left__margin"
          >
            <div className="button__with__icon__svg__wrapper dark__pink__ball">
              <div className="airplaneWhiteIcon" />
            </div>
            <span> {t("btn_text_send")}</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ListRepayment;
