import React, { useState, useEffect } from "react";
import { isMobile, isTablet } from "react-device-detect";
import {
  Button,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { Check, Clear } from "@mui/icons-material";
////// components
import CheckboxComponent from "../../../base/components/CheckboxComponent";
import ImageProfile from "../../../Tools/ImageProfileLoader";
import { ReactComponent as NotResultFound } from "../../../../../assets/media/images/errorPlaceholder.svg";
import { useTranslation } from "react-i18next";
import {
  requestCleanGetDataSponsorsAutomaticReducer,
  requestCleanGetDataSponsorsListReducer,
  requestGetDataSponsorAutomatic,
  requestGetDataSponsorByCity,
  requestGetDataSponsorById,
  requestGetDataSponsorByName,
} from "../../../../ReduxSaga/Actions/Client";
import { useDispatch, useSelector } from "react-redux";
import { requestStatesAndCities } from "../../../../ReduxSaga/Actions/General";
import { useSnackbar } from "notistack";
import {
  restrictToOnlyLettersAndNumbers,
  restrictToOnlyNumbers,
} from "../../../../../Libraries/Utils/regex";

const AssignEntrepreneur = ({
  buildVCardSponsorSelection,
  sponsorInformation,
  setSponsorInformation,
}) => {
  //Notistack
  const { enqueueSnackbar } = useSnackbar();

  //Initial data
  // const sponsorInitialInformation = {
  //   name: "",
  //   noEnterprising: 0,
  //   avatar: "",
  //   city: "",
  //   state: "",
  // };

  //Redux
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.email = useSelector((state) => state.email);
  appReducers.twilio = useSelector((state) => state.twilio);
  appReducers.client = useSelector((state) => state.client);
  appReducers.general = useSelector((state) => state.general);
  appReducers.home = useSelector((state) => state.home);

  //Translation
  const { t, i18n } = useTranslation();

  //// sponsor
  const [sponsorOption, setSponsorOption] = useState("");
  const [searchSponsor, setSearchSponsor] = useState("");
  const [searchSponsorStates, setSearchSponsorState] = useState(false);
  const [searchSponsorNames, setSearchSponsorNames] = useState(false);
  const [isSponsorFinish, setIsSponsorFinish] = useState(false);
  const [finishSponsorProcess, setFinishSponsorProcess] = useState(false);
  // const [sponsorInformation, setSponsorInformation] = useState(
  //   sponsorInitialInformation
  // );
  //////////////
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [sponsorStateSearch, setSponsorStateSearch] = useState(0);
  const [sponsorCitySearch, setSponsorCitySearch] = useState("");
  const [sponsorName, setSponsorName] = useState("");
  const [sponsorLastName, setSponsorLastName] = useState("");
  const [sponsorId, setSponsorId] = useState(0);
  const [sponsorResult, setSponsorResult] = useState([]);
  ///////////////////////////

  //Fake data
  const isVerifyData = true;
  const fakeSponsorList = [
    {
      id: 1,
      code: "tx",
      name: "Teresa",
      lastName: "Morales",
      imageProfile:
        "https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      state: "Texas",
      city: "Dallas",
    },
    {
      id: 2,
      code: "tx",
      name: "Catalina",
      lastName: "Ortiz",
      imageProfile:
        "https://images.unsplash.com/photo-1533689476487-034f57831a58?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTM0fHx1c2VyfGVufDB8fDB8fHww",
      state: "Texas",
      city: "Houston",
    },
    {
      id: 3,
      code: "tx",
      name: "Martina",
      lastName: "Villaseñor",
      imageProfile:
        "https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fHVzZXJ8ZW58MHx8MHx8fDA%3D",
      state: "Texas",
      city: "Texas city",
    },
  ];

  //Effects
  //EFFECT GET SPONSOR AUTOMATIC ASSIGNATION BY ZERMAT
  useEffect(() => {
    if (
      typeof appReducers?.client?.errorAssignedSponsorsAutomatic !== "undefined"
    ) {
      enqueueSnackbar(t("__login_New_Account_Error_search_sponsor_label"), {
        variant: "error",
      });
    } else {
      console.log(
        "assignedSponsorsAutomatic",
        appReducers?.client?.assignedSponsorsAutomatic
      );
      if (appReducers?.client?.assignedSponsorsAutomatic) {
        let respSponsors = appReducers.client.assignedSponsorsAutomatic;
        if (respSponsors.assignedSponsors.length > 0) {
          let respSponsData = respSponsors.assignedSponsors[0];
          console.log("SPONSOR AUTO", respSponsData);
          setSponsorInformation({
            name: respSponsData.name + " " + respSponsData.lastName,
            noEnterprising: respSponsData.entreprenourId,
            avatar: respSponsData.imageProfile,
            city: respSponsData.city,
            state: respSponsData.state,
          });
          buildVCardSponsorSelection(respSponsData);
          setFinishSponsorProcess(true);
          //limpiar el reducer
          dispatch(requestCleanGetDataSponsorsAutomaticReducer());
        } else {
          enqueueSnackbar(
            t("__login_New_Account_Error_no_find_sponsor_near_label"),
            { variant: "warning" }
          );
        }
      }
    }
  }, [
    appReducers?.client?.assignedSponsorsAutomatic,
    appReducers?.client?.errorAssignedSponsorsAutomatic,
  ]);
  ///EFFECT STATES AND CITIES
  useEffect(() => {
    if (typeof appReducers?.general?.errorLoadStatesAndCities !== "undefined") {
      if (!appReducers?.general?.errorLoadStatesAndCities) {
        if (
          appReducers?.general?.statesAndCities &&
          appReducers?.general?.statesAndCities.length > 0
        ) {
          setStates(appReducers?.general?.statesAndCities);
          setSponsorStateSearch(
            appReducers?.general?.statesAndCities[0]?.idState
          );
        }
      }
    } else {
      dispatch(requestStatesAndCities());
    }
  }, [
    appReducers?.general?.errorLoadStatesAndCities,
    appReducers?.general?.statesAndCities,
  ]);
  //Efecto al buscar sponsors
  useEffect(() => {
    if (sponsorStateSearch > 0 && states.length > 0) {
      const citiesResult = states
        .find((s) => s.idState === sponsorStateSearch)
        .cities.map((item) => ({ city: item }));
      setCities(citiesResult);
      setSponsorCitySearch(citiesResult[0].city);
    } else {
      setCities([]);
    }
  }, [sponsorStateSearch, states]);
  //Efecto que regresa la busqueda del sponsor
  useEffect(() => {
    if (appReducers?.client?.assignedSponsors) {
      let respSponsors = appReducers.client.assignedSponsors;
      if (respSponsors.code === 100) {
        console.log("SPONSORS", respSponsors.assignedSponsors);
        setSponsorResult(respSponsors.assignedSponsors);
        //limpiar el reducer
        dispatch(requestCleanGetDataSponsorsListReducer());
      }
    }
  }, [appReducers?.client?.assignedSponsors]);

  useEffect(() => {
    console.log("sponsorInformation", sponsorInformation);
  }, [sponsorInformation]);

  //Handlers
  const handleSponsorSelect = () => {
    setSponsorOption("select");
    setSearchSponsor("");
    setFinishSponsorProcess(false);
    setSponsorId(0);
    setSponsorName("");
    setSponsorLastName("");
  };

  const handleSponsorAuto = () => {
    setSponsorOption("automatic");
    setSearchSponsor("");
    setFinishSponsorProcess(false);
    setSponsorId(0);
    setSponsorName("");
    setSponsorLastName("");
  };

  const handleSearchSponsorChange = (event) => {
    setSearchSponsor(event.target.value);
    if (event.target.value === "states") {
      //setSearchSponsorState(true);
      //setSearchSponsorNames(false);
      setSponsorName("");
      setSponsorId(0);
    }
    if (event.target.value === "names") {
      //setSearchSponsorState(false);
      //setSearchSponsorNames(true);
    }
  };

  const handleFinishSponsor = (sponsor = {}) => {
    //setIsSponsorFinish(true);
    if (sponsorOption === "automatic") {
      dispatch(requestGetDataSponsorAutomatic());
    } else {
      //debugger
      setSponsorInformation({
        name: sponsor.name + " " + sponsor.lastName,
        noEnterprising: sponsor.entreprenourId,
        avatar: sponsor.imageProfile,
        city: sponsor.city,
        state: sponsor.state,
      });
      buildVCardSponsorSelection(sponsor);
      setFinishSponsorProcess(true);
    }
  };

  return (
    <div className="assign__entrepreneur">
      <div className="assign__entrepreneur__container">
        {/* escoje al patrocinador */}
        <div className="assign__entrepreneur__container__choose">
          <div className="assign__entrepreneur__container__choose__header">
            <div className="zRedCircleIcon" />{" "}
            <span>{t("_Zusa_NewMembers_Your_Sponsor")}</span>
          </div>
          <div
            className={`assign__entrepreneur__container__choose__options ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            <div
              onClick={handleSponsorSelect}
              className={`assign__entrepreneur__container__choose__options__button ${
                sponsorOption === "select" ? "active" : ""
              } ${isTablet ? "tablet" : isMobile ? "mobile" : ""}`}
            >
              <div className="searchRedIcon" />
              <span>
                {t("__checkout_order_slide_enterprising_modal_find_enter")}
              </span>
            </div>
            <div
              onClick={handleSponsorAuto}
              className={`assign__entrepreneur__container__choose__options__button ${
                sponsorOption === "automatic" ? "active" : ""
              } ${isTablet ? "tablet" : isMobile ? "mobile" : ""}`}
            >
              <div className="zRedIcon" />
              <span>
                {t("__checkout_order_slide_enterprising_modal_find_zermat")}
              </span>
            </div>
          </div>
        </div>
        <hr />

        {sponsorOption === "automatic" ? (
          <div className="assign__entrepreneur__container__result">
            {finishSponsorProcess ? (
              <div className="finish__process">
                <div className="finish__process__header">
                  <div className="zRedCircleIcon" />
                  <span>
                    {t(
                      "__checkout_order_slide_enterprising_modal_find_congrats"
                    )}
                  </span>
                </div>
                <div className="finish__process__info">
                  <div className="info__sponsor__left">
                    <ImageProfile
                      route={sponsorInformation.avatar}
                      name={sponsorInformation.name}
                    />
                  </div>
                  <div className="info__sponsor__right">
                    <span>{sponsorInformation.name}</span>
                    <span>
                      {sponsorInformation.city} {sponsorInformation.state}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={`finish__process ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                <div
                  className={`finish__process__header ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  }`}
                >
                  <div className="zRedCircleIcon" />
                  <span>
                    {t("__checkout_order_slide_enterprising_modal_find_zermat")}
                  </span>
                </div>
                <div className={`finish__process__info `}>
                  <div className="info__sponsor__left">
                    <span>
                      {t(
                        "__checkout_order_slide_enterprising_modal_find_zermat_subtitle"
                      )}
                    </span>
                  </div>

                  <div className={`info__sponsor__right`}>
                    <Button
                      onClick={() => handleFinishSponsor()}
                      className="button__with__icon  no__left__margin bg__gray"
                    >
                      <div className="button__with__icon__svg__wrapper dark__pink__ball">
                        <div className="searchIcon" />
                      </div>
                      <span>
                        {t(
                          "__checkout_order_slide_enterprising_modal_find_searh"
                        )}
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          sponsorOption === "select" && (
            <>
              {/* BUSQUEDA DE PATROCINADOR*/}
              <div className="assign__entrepreneur__container__search">
                <div
                  className={`assign__entrepreneur__container__search__header ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  }`}
                >
                  <div className="leftSection">
                    <div className="searchRedIcon" />{" "}
                    <span>
                      {t(
                        "__checkout_order_slide_enterprising_modal_find_title"
                      )}
                    </span>
                  </div>
                  <div
                    className={`rightSection ${
                      isTablet ? "tablet" : isMobile ? "mobile" : ""
                    }`}
                  >
                    <Button
                      style={{ minWidth: 300, maxWidth: 300 }}
                      className="button__with__icon bg__pink no__left__margin"
                      disabled={
                        searchSponsor.length <= 0 ||
                        (searchSponsor === "states" &&
                          (sponsorStateSearch.length <= 0 ||
                            sponsorCitySearch.length <= 0)) ||
                        (searchSponsor === "names" &&
                          sponsorId <= 0 &&
                          sponsorName.length <= 0)
                      }
                      onClick={() => {
                        if (searchSponsor.length > 0) {
                          if (searchSponsor === "states") {
                            dispatch(
                              requestGetDataSponsorByCity(
                                sponsorStateSearch,
                                sponsorCitySearch
                              )
                            );
                          }
                          if (searchSponsor === "names") {
                            if (sponsorName.length > 0) {
                              dispatch(
                                requestGetDataSponsorByName(
                                  sponsorName,
                                  sponsorLastName
                                )
                              );
                            } else if (sponsorId > 0) {
                              dispatch(requestGetDataSponsorById(sponsorId));
                            }
                          }
                        }
                      }}
                    >
                      <div className="button__with__icon__svg__wrapper dark__pink__ball">
                        <div className="searchIcon" />
                      </div>
                      <span>
                        {t(
                          "__checkout_order_slide_enterprising_modal_find_enter"
                        )}
                      </span>
                    </Button>
                  </div>
                </div>
                <div
                  className={`assign__entrepreneur__container__search__filters__options ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  }`}
                >
                  <div
                    className={`stateCheck ${
                      searchSponsor === "states" ? "active" : ""
                    }`}
                  >
                    <CheckboxComponent
                      className="shopping"
                      valueChecked={searchSponsor === "states"}
                      valueCheck="states"
                      labelCheckbox={t("__login_New_Account_Search_State")}
                      handleCheck={handleSearchSponsorChange}
                    />
                  </div>
                  <div
                    className={`namesCheck ${
                      searchSponsor === "names" ? "active" : ""
                    }`}
                  >
                    <CheckboxComponent
                      className="shopping"
                      valueChecked={searchSponsor === "names"}
                      valueCheck="names"
                      labelCheckbox={t("__login_New_Account_Search_Name_Id")}
                      handleCheck={handleSearchSponsorChange}
                    />
                  </div>
                </div>
              </div>

              {/* filtros para la busqueda*/}
              <div
                className={`assign__entrepreneur__container__filters ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                {/* filters by State */}
                {searchSponsor === "states" && (
                  <div
                    className={`assign__entrepreneur__container__filters__states ${
                      isTablet ? "tablet" : isMobile ? "mobile" : ""
                    }`}
                  >
                    <FormControl
                      className={`statesSelect ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                      variant="outlined"
                    >
                      <Select
                        labelId="state"
                        id="select"
                        required
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        value={sponsorStateSearch}
                        error={false}
                        //className={isAddresShippingVerify ? "isOK" : ""}
                        onChange={(e) => {
                          setSponsorStateSearch(parseInt(e.target.value));
                        }}
                      >
                        <MenuItem selected disabled value="">
                          <em>
                            {t(
                              "__checkout_order_steps_pay_register_shipping_state"
                            )}
                          </em>
                        </MenuItem>
                        {states.map((item) => {
                          return (
                            <MenuItem key={item.idState} value={item.idState}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <FormControl
                      className={`citiesSelect ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                      variant="outlined"
                    >
                      <Select
                        labelId="state"
                        id="select"
                        required
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        value={sponsorCitySearch}
                        error={false}
                        //className={isAddresShippingVerify ? "isOK" : ""}
                        onChange={(e) => {
                          setSponsorCitySearch(e.target.value);
                        }}
                      >
                        <MenuItem selected disabled value="">
                          <em>
                            {t(
                              "__checkout_order_steps_pay_register_shipping_city"
                            )}
                          </em>
                        </MenuItem>
                        {cities.map((item, index) => {
                          //console.log("city obj", item);
                          return (
                            <MenuItem key={index} value={item.city}>
                              {item.city}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                )}

                {/* filters by name */}
                {searchSponsor === "names" && (
                  <div
                    className={`assign__entrepreneur__container__filters__names ${
                      isTablet ? "tablet" : isMobile ? "mobile" : ""
                    }`}
                  >
                    <FormControl
                      variant="outlined"
                      className={`nameFilter ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                    >
                      <OutlinedInput
                        id="username"
                        placeholder={t("__login_Create_Account_Txt_Name_Label")}
                        //size="small"
                        value={sponsorName}
                        endAdornment={
                          <InputAdornment position="end">
                            {sponsorName.length <= 0 ? (
                              <Clear className="errorIcon" />
                            ) : (
                              <Check className="successIcon" />
                            )}
                          </InputAdornment>
                        }
                        aria-describedby="username"
                        inputProps={{
                          "aria-label": "username",
                        }}
                        onChange={(e) => {
                          setSponsorName(restrictToOnlyLettersAndNumbers(e));
                        }}
                      />
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      className={`idFilter ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                    >
                      <OutlinedInput
                        id="idUser"
                        placeholder={t("ID")}
                        //size="small"
                        value={sponsorId}
                        endAdornment={
                          <InputAdornment position="end">
                            {sponsorId <= 0 ? (
                              <Clear className="errorIcon" />
                            ) : (
                              <Check className="successIcon" />
                            )}
                          </InputAdornment>
                        }
                        aria-describedby="idUser"
                        inputProps={{
                          "aria-label": "idUser",
                        }}
                        onChange={(e) => {
                          setSponsorId(restrictToOnlyNumbers(e));
                        }}
                      />
                    </FormControl>
                  </div>
                )}
              </div>

              <div className="assign__entrepreneur__container__result">
                <div className="background__gray__light">
                  {/* rESULTADOS DE LA BUSQUEDA*/}
                  {!finishSponsorProcess && (
                    <div className="list__entrepreneurs">
                      {sponsorResult.map((item, index) => {
                        return (
                          <div key={index} className="sponsor__card">
                            <div className="sponsor__card__content">
                              <div className="sponsor__card__content__image">
                                <div className="sponsor__card__content__image__inner">
                                  {" "}
                                  <ImageProfile
                                    route={item.imageProfile}
                                    name={
                                      item.name.trim() +
                                      " " +
                                      item.lastName.trim()
                                    }
                                  />
                                </div>
                              </div>
                              <div className="sponsor__card__content__info">
                                <span>{item.name + " " + item.lastName}</span>
                                <span>{item.state + ", " + item.city}</span>
                              </div>
                              <div className="sponsor__card__content__action">
                                <Button
                                  onClick={() => handleFinishSponsor(item)}
                                  className="button__with__icon bg__dark__pink no__left__margin"
                                >
                                  <div className="button__with__icon__svg__wrapper dark__pink__ball">
                                    <div className="userOutlineWhiteIcon" />
                                  </div>
                                  <span>
                                    {t(
                                      "__checkout_order_slide_enterprising_modal_find_select"
                                    )}
                                  </span>
                                </Button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}

                  {/* no se encontraron  */}

                  {sponsorResult.length === 0 && (
                    <div className="assign__entrepreneur__container__result">
                      <div className="results__not__Found">
                        <NotResultFound />
                        <span className="error__boundary__title">
                          {t("__login_New_Account_Search_No_Found_One_label")}
                        </span>
                        <span className="error__boundary__subtitle">
                          {t("__login_New_Account_Search_No_Found_Two_label")}
                        </span>
                      </div>
                    </div>
                  )}
                  {/* mostrar emprendedor asignado*/}

                  {finishSponsorProcess && (
                    <div className="finish__process">
                      <div className="finish__process__header">
                        <div className="zRedCircleIcon" />
                        <span>
                          {t(
                            "__checkout_order_slide_enterprising_modal_find_congrats"
                          )}
                        </span>
                      </div>
                      <div className="finish__process__info">
                        <div className="info__sponsor__left">
                          <ImageProfile
                            route={sponsorInformation.avatar}
                            name={sponsorInformation.name}
                          />
                        </div>
                        <div className="info__sponsor__right">
                          <span>{sponsorInformation.name}</span>
                          <span>
                            {sponsorInformation.city} {sponsorInformation.state}
                          </span>
                        </div>
                      </div>

                      {/* <div className="finish__process__social__media">
                        <div>
                          <div className="facebookIcon" />
                        </div>
                        <div>
                          <div className="instagramIcon" />
                        </div>
                        <div>
                          <div className="youtubeIcon" />
                        </div>
                        <div>
                          <div className="pinterestIcon" />
                        </div>
                        <div>
                          <div className="whatsappIcon" />
                        </div>
                      </div>
                      <div className="finish__process__dream">
                        <span>
                          {t(
                            "__checkout_order_slide_enterprising_modal_find_my_dream"
                          )}
                        </span>
                        <span>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat.
                        </span>
                      </div> */}
                    </div>
                  )}
                </div>
              </div>
            </>
          )
        )}
      </div>
    </div>
  );
};

export default AssignEntrepreneur;
