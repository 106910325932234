import React, { useEffect, useState } from "react";
import { Button, Skeleton } from "@mui/material";
import { Link } from "react-router-dom";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { requestLoggUserActivity } from "./../../ReduxSaga/Actions/General";
import { requestRemoveSetCategoriesReducer } from "./../../ReduxSaga/Actions/Products";
// import { ArrowDropDown, ArrowDropUp, ArrowRight } from "@material-ui/icons";
// import BreadcrumbsNavigationComponet from "../navigation/components/BreadcrumbsNavigationComponet";

const ProductsContainer = (props) => {
  /// TRANSLATION
  const { i18n } = useTranslation();
  /// REDUX
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.products = useSelector((state) => state.products);

  ///HOOKS
  const [categories, setCategories] = useState([]);

  ///EFECTS
  useEffect(() => {
    document.title = "Zermat USA :: Product Categories";
    //console.log(props);
    if (
      appReducers?.products?.isModulePromotions &&
      appReducers?.products?.categoriesEs &&
      appReducers?.products?.categoriesEn &&
      appReducers?.products?.categoriesEs.length > 0 &&
      appReducers?.products?.categoriesEn.length > 0
    ) {
      dispatch(requestRemoveSetCategoriesReducer());
    }
  }, []);

  useEffect(() => {
    if (!appReducers?.products?.errorLoadAllCategories) {
      if (i18n.language === "en")
        setCategories(appReducers.products.categoriesEn);
      if (i18n.language === "es")
        setCategories(appReducers.products.categoriesEs);
    } else {
      //manejar pantalla de error
    }
  }, [
    appReducers?.products?.errorLoadAllCategories,
    i18n.language,
    appReducers.products.categoriesEs,
    appReducers.products.categoriesEn,
  ]);
  ///HANDLERS

  return (
    <div id="firstWrapperInBaseContainer">
      {/* <BreadcrumbsNavigationComponet /> */}
      <div
        className={`new__categories__products__container ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }`}
      >
        {categories?.length > 0
          ? categories.map((item, index) => {
              return (
                <div
                  className={`new__categories__products__container__item ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  }`}
                  key={index}
                >
                  <div
                    className={`new__categories__products__container__item__button ${
                      isTablet ? "tablet" : isMobile ? "mobile" : ""
                    }`}
                  >
                    <Button variant="outlined">
                      <Link
                        to={`/categories/products/${item.idCategories}`}
                        onClick={() => {
                          dispatch(
                            requestLoggUserActivity(
                              66,
                              0,
                              item.idCategories,
                              "",
                              "Click en la Categoria: " + item.nameCategories
                            )
                          );
                          props?.handleGAEvent(
                            "Navigation",
                            "Usuario Navega a La Categoría De " +
                              item.nameCategories
                          );
                        }}
                      >
                        {item.nameCategories}{" "}
                      </Link>
                    </Button>
                  </div>
                  <Link
                    to={`/categories/products/${item.idCategories}`}
                    onClick={() => {
                      dispatch(
                        requestLoggUserActivity(
                          66,
                          0,
                          item.idCategories,
                          "",
                          "Click en la Categoria: " + item.nameCategories
                        )
                      );
                      props?.handleGAEvent(
                        "Navigation",
                        "Usuario Navega a La Categoría De " +
                          item.nameCategories
                      );
                    }}
                  >
                    <div className="new__categories__products__container__item__imagen">
                      <img
                        src={
                          isMobile
                            ? `https://www.websizusa.net/imgprod/zu/products/categories/Category_${item.idCategories}_sm.jpg`
                            : `https://www.websizusa.net/imgprod/zu/products/categories/Category_${item.idCategories}_lg.jpg`
                        }
                        alt={item.nameCategories}
                      />
                    </div>
                  </Link>
                </div>
              );
            })
          : [0, 1, 2, 3, 4, 5, 6, 7, 8].map((item) => {
              return (
                <Skeleton
                  key={item}
                  className="item"
                  variant="rect"
                  width={isDesktop ? "30%" : isTablet ? "30%" : "90%"}
                  height={isDesktop ? 430 : isTablet ? 150 : 315}
                  style={{ margin: "10px" }}
                />
              );
            })}
      </div>
    </div>
  );
};

export default ProductsContainer;
