export const regexIsEmailAddressValid = (email) => {
  if (email.length > 0) {
    const regularExpression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(email);
  } else {
    return true;
  }

};

export const regexIsCellphoneValid = (cellphone) => {
  if (cellphone.length > 0) {
    const regularExpression = /^\d{10}$/;
    return regularExpression.test(cellphone);
  }else{
    return true;
  }
};

export const restrictToOnlyNumbers = (e, length = 10) => {
  const regularExpression = new RegExp(`^[0-9]{${length}}$`, "gi");
  if (regularExpression.test(e.target.value)) {
    return e.target.value;
  } else {
    if (Number.isNaN(parseInt(e.target.value))) {
      return (e.target.value = "");
    }
    return (e.target.value = parseInt(e.target.value.slice(0, length)));
  }
};

export const restrictToOnlyNumbersTwo = (e, length = 10) => {
  const regularExpression = new RegExp(`^[0-9]{${length}}$`, "gi");
  if (regularExpression.test(e.target.value)) {
    return e.target.value;
  } else {
    const regularExpression2 = new RegExp(`^[0-9]{${e.target.value.length}}$`, "gi");
    if (!regularExpression2.test(e.target.value)) {
      return (e.target.value = "");
    }
    return (e.target.value = e.target.value.slice(0, length));
  }
};

export const restrictToOnlyLetters = (e) => {
  const regularExpression = new RegExp(`^[a-zA-Z\\s]+$`, "g");
  if (regularExpression.test(e.target.value)) {
    return e.target.value;
  } else {
    return (e.target.value = e.target.value.substring(0, e.target.value.length - 1));
  }
};


export const restrictToOnlyLettersAndNumbers = (e) => {
  const regularExpression = new RegExp(`^[a-zA-Z0-9\\s]+$`, "g");
  if (regularExpression.test(e.target.value)) {
    return e.target.value;
  } else {
    return (e.target.value = e.target.value.substring(0, e.target.value.length - 1));
  }
};

export const restrictToOnlyLettersForEmail = (e) => {
  const regularExpression = new RegExp(`^[a-zA-Z0-9_\\.\\@\\-]+$`, "g");
  if (regularExpression.test(e.target.value)) {
    return e.target.value;
  } else {
    return (e.target.value = e.target.value.substring(0, e.target.value.length - 1));
  }
};

export const areOnlyLetters = (value) => {
  const regularExpression = /^[A-Za-z\s]+$/;
  return regularExpression.test(value);
};

export const isStrongPassword = value => {
  const regularExpression = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/
  ///^
  //(?=.*\d)    should contain at least one digit
  //(?=.*[a-z]) should contain at least one lower case
  //(?=.*[A-Z]) should contain at least one upper case
  //[a-zA-Z0-9]{8,}  should contain at least 8 from the mentioned characters
  //$/
  return regularExpression.test(value);
}
