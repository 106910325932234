import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Skeleton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  requestReferrerCodeSendedLog,
  cleanReferrerCodeSendedLogReducer,
} from "../../../ReduxSaga/Actions/Referrer";
import { requestLoggUserActivity } from "../../../ReduxSaga/Actions/General";
import {
  regexIsCellphoneValid,
  regexIsEmailAddressValid,
  restrictToOnlyLettersForEmail,
  restrictToOnlyNumbers,
} from "./../../../../Libraries/Utils/regex";

const NewReferredFriends = (props) => {
  //Notistack
  const { enqueueSnackbar } = useSnackbar();
  /// Navigation
  let params = useParams();
  /// TRANSLATION
  const { t } = useTranslation();
  ///REDUX
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.referrer = useSelector((state) => state.referrer);
  /// states
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  // const [messageNotification, setMessageNotification] = useState("");
  // const [isOpenNotification, setIsOpenNotification] = useState(true);
  const [email, setEmail] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [referrerCode, setReferrerCode] = useState("");

  useEffect(() => {
    if (typeof appReducers?.referrer?.responseReferrerCodeLog !== "undefined") {
      setLoading(false);
      if (appReducers.referrer.responseReferrerCodeLog.code === 100) {
        enqueueSnackbar(t("__message_send_code_referred_label"), {
          variant: "success",
        });
        // setIsOpenNotification(true);
        // setMessageNotification(t("__message_send_code_referred_label"));
        dispatch(cleanReferrerCodeSendedLogReducer());
        //looggg del referido
        dispatch(
          requestLoggUserActivity(
            55,
            0,
            0,
            "",
            "Se registro Referido de Manera Correcta. Via: " +
              value +
              ". Contacto: " +
              (value === "email" ? email : cellPhone)
          )
        );
      } else {
        switch (appReducers.referrer.responseReferrerCodeLog.code) {
          case -100:
            enqueueSnackbar(t("__message_something_bad_label"), {
              variant: "error",
            });
            // setMessageNotification(t("__message_something_bad_label"));
            break;
          case -200:
            enqueueSnackbar(t("__message_phone_provider_not_number_label"), {
              variant: "error",
            });
            // setMessageNotification(
            //   t("__message_phone_provider_not_number_label")
            // );
            break;
          case -300:
            enqueueSnackbar(t("__message_something_bad_label"), {
              variant: "error",
            });
            // setMessageNotification(t("__message_something_bad_label"));
            break;
          case -400:
            enqueueSnackbar(t("__message_something_bad_label"), {
              variant: "error",
            });
            //setMessageNotification(t("__message_something_bad_label"));
            break;
          case -500:
            enqueueSnackbar(t("__message_invalid_email_provider_label"), {
              variant: "error",
            });
            //setMessageNotification(t("__message_invalid_email_provider_label"));
            break;
          case -600:
            enqueueSnackbar(t("__message_something_bad_label"), {
              variant: "error",
            });
            //setMessageNotification(t("__message_something_bad_label"));
            break;
          default:
            break;
        }
        //setIsOpenNotification(true);
      }
      setEmail("");
      setCellPhone("");
    }
  }, [appReducers?.referrer?.responseReferrerCodeLog]);

  useEffect(() => {
    if (params?.referrerCode) {
      setReferrerCode(params.referrerCode);
    }
  }, [params]);

  const handleButtonClick = () => {
    setLoading(true);
    dispatch(requestReferrerCodeSendedLog(referrerCode, email, cellPhone));
  };
  const handleChange = (event) => {
    setValue(event.target.value);
    if (event.target.value === "email") setCellPhone("");
    else setEmail("");
  };

  return (
    <Fragment>
      {/* <NotificationSnackbar
        isOpenNotification={isOpenNotification}
        setIsOpenNotification={setIsOpenNotification}
        message={messageNotification}
        status="success"
        icon="Check"
      /> */}
      <div className="modal__slide__container__wrapper">
        {props.isLanguageLoaded ? (
          <Typography variant="h5" gutterBottom className="text__red">
            {t("__profile_Friends_Referred_title")}
          </Typography>
        ) : (
          <Skeleton variant="rect" width={"20%"} height={30} />
        )}

        <div className="modal__slide__container__wrapper__options">
          <RadioGroup
            aria-label="medio"
            name="medio1"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value="email"
              control={<Radio />}
              label={
                props.isLanguageLoaded
                  ? t("__profile_Friends_Referred_select_contact_form_email")
                  : ""
              }
            />
            <FormControlLabel
              value="sms"
              control={<Radio />}
              label={
                props.isLanguageLoaded
                  ? t("__profile_Friends_Referred_select_contact_form_sms")
                  : ""
              }
            />
          </RadioGroup>
        </div>
        <div className="modal__slide__container__wrapper__inputs">
          {value && value === "email" && (
            <TextField
              fullWidth
              error={email.length <= 0 || !regexIsEmailAddressValid(email)}
              helperText={
                email.length <= 0
                  ? props.isLanguageLoaded
                    ? t("__forms_empty_field_error_message")
                    : ""
                  : !regexIsEmailAddressValid(email) && props.isLanguageLoaded
                  ? t("__message_invalid_email_label")
                  : ""
              }
              onInput={(e) => {
                setEmail(restrictToOnlyLettersForEmail(e));
              }}
              value={email}
              label={
                props.isLanguageLoaded
                  ? t("__profile_Friends_Referred_select_contact_form_email")
                  : ""
              }
              type="email"
            />
          )}
          {value && value === "sms" && (
            <TextField
              error={cellPhone.length <= 0 || !regexIsCellphoneValid(cellPhone)}
              helperText={
                cellPhone.length <= 0
                  ? props.isLanguageLoaded
                    ? t("__forms_empty_field_error_message")
                    : ""
                  : !regexIsCellphoneValid(cellPhone) && props.isLanguageLoaded
                  ? t("__message_phone_invalid_code_label")
                  : ""
              }
              onInput={(e) => {
                setCellPhone(restrictToOnlyNumbers(e));
              }}
              inputProps={{
                maxLength: 10,
              }}
              value={cellPhone}
              label={
                props.isLanguageLoaded
                  ? t("__profile_Friends_Referred_select_contact_form_sms")
                  : ""
              }
              type="tel"
            />
          )}
        </div>

        <Button
          disabled={
            loading ||
            (value === "sms" && cellPhone.length !== 10) ||
            (value === "email" &&
              (email.length <= 0 || !regexIsEmailAddressValid(email))) ||
            value === ""
          }
          onClick={handleButtonClick}
          className="button__with__icon bg__dark__pink no__left__margin"
        >
          <div className="button__with__icon__svg__wrapper dark__pink__ball">
            <div className="airplaneIcon" />
          </div>
          <span>{props.isLanguageLoaded ? t("btn_text_send") : ""}</span>
        </Button>

        {/* <Button
          variant="contained"
          disabled={
            loading ||
            (value === "sms" && cellPhone.length !== 10) ||
            (value === "email" &&
              (email.length <= 0 || !regexIsEmailAddressValid(email))) ||
            value === ""
          }
          onClick={handleButtonClick}
        >
          {t("btn_text_send")}
        </Button> */}
        {loading && <CircularProgress size={24} />}
      </div>
    </Fragment>
  );
};

export default NewReferredFriends;
