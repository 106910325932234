import { call, put, takeLatest } from "redux-saga/effects";
import {
  DATA_FETCH_FAILED,
  REQUEST_STRIPE_PAYMENT,
  REQUEST_STRIPE_PAYMENT2023,
  RESPONSE_FIRST_CHARGE_SITE_RELOAD,
  RESPONSE_STRIPE_PAYMENT,
  RESPONSE_STRIPE_PAYMENT2023,
} from "../../Constants";
import Payment from "../../../../Libraries/Payment";

function* requestStripePayment(data) {
  try {
    const response = yield call(
      Payment.StripeCreditCardPayment,
      data.payload.model,
      data.payload.orderId
    );
    yield put({
      type: RESPONSE_STRIPE_PAYMENT,
      payload: { response: response.payment_StripRequest_Payment },
    });
  } catch (error) {
    console.error("payment stripe ex error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

function* requestStripePayment2023(data) {
  try {
    const response = yield call(
      Payment.StripePayment2023,
      data.payload.stripePaymentModel,
      data.payload.orderId,
      data.payload.closeOrderModel
    );
    yield put({
      type: RESPONSE_STRIPE_PAYMENT2023,
      payload: { response: response.payment_StripRequest_Payment_2023 },
    });

    console.log("requestStripePayment2023 response", response);
  } catch (error) {
    console.error("Error requesting PaymentStripeRequestPayment", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

//Watchers
export function* watchRequestStripePayment() {
  yield takeLatest(REQUEST_STRIPE_PAYMENT, requestStripePayment);
}
export function* watchRequestStripePayment2023() {
  yield takeLatest(REQUEST_STRIPE_PAYMENT2023, requestStripePayment2023);
}
