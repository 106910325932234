import React from "react";

export const ConfettiSVG = () => {
  return (
    <div id="container">
      <svg
        id="#confetti__container"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="2481.385"
        height="1299.336"
        viewBox="0 0 2481.385 1299.336"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="-4.047"
            y1="1.686"
            x2="-4.057"
            y2="1.708"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#b3862d" />
            <stop offset="0.201" stopColor="#c49d35" />
            <stop offset="0.237" stopColor="#c9a33e" />
            <stop offset="0.429" stopColor="#c18f2c" />
            <stop offset="0.43" stopColor="#c18f2c" />
            <stop offset="0.493" stopColor="#d9b449" />
            <stop offset="0.553" stopColor="#e9d06f" />
            <stop offset="0.609" stopColor="#f5e489" />
            <stop offset="0.659" stopColor="#fcf19b" />
            <stop offset="0.697" stopColor="#fef5a3" />
            <stop offset="0.743" stopColor="#fcf19c" />
            <stop offset="0.793" stopColor="#f3e48d" />
            <stop offset="0.845" stopColor="#e6d178" />
            <stop offset="0.899" stopColor="#d4b458" />
            <stop offset="0.954" stopColor="#bb902f" />
            <stop offset="1" stopColor="#a46e29" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-2"
            x1="-2.036"
            y1="19.23"
            x2="-2.041"
            y2="19.254"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-3"
            x1="6.193"
            y1="9.078"
            x2="6.203"
            y2="9.027"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#8d5823" />
            <stop offset="0.085" stopColor="#905b24" />
            <stop offset="0.177" stopColor="#996828" />
            <stop offset="0.271" stopColor="#a97c2c" />
            <stop offset="0.367" stopColor="#bf9a3f" />
            <stop offset="0.463" stopColor="#dbc05e" />
            <stop offset="0.543" stopColor="#f7e67e" />
            <stop offset="0.606" stopColor="#f4e078" />
            <stop offset="0.685" stopColor="#e9d26a" />
            <stop offset="0.772" stopColor="#d8b851" />
            <stop offset="0.831" stopColor="#c9a33e" />
            <stop offset="0.864" stopColor="#c19a37" />
            <stop offset="0.916" stopColor="#b2852d" />
            <stop offset="0.979" stopColor="#976427" />
            <stop offset="1" stopColor="#8d5823" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-4"
            x1="5.227"
            y1="9.437"
            x2="5.231"
            y2="9.405"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#ffeeae" />
            <stop offset="0.193" stopColor="#ead182" />
            <stop offset="0.443" stopColor="#d3af4f" />
            <stop offset="0.566" stopColor="#c9a33e" />
            <stop offset="0.661" stopColor="#ceaa44" />
            <stop offset="0.807" stopColor="#dcc058" />
            <stop offset="0.985" stopColor="#f4e27a" />
            <stop offset="1" stopColor="#f7e67e" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-5"
            x1="22.922"
            y1="-0.248"
            x2="22.932"
            y2="-0.298"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-6"
            x1="20.939"
            y1="-0.221"
            x2="20.942"
            y2="-0.253"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-7"
            x1="-10.125"
            y1="4.593"
            x2="-10.111"
            y2="4.624"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#f7e67e" />
            <stop offset="0.034" stopColor="#f3e079" />
            <stop offset="0.079" stopColor="#e6d06c" />
            <stop offset="0.13" stopColor="#d1b456" />
            <stop offset="0.186" stopColor="#b48d3a" />
            <stop offset="0.245" stopColor="#8f5e25" />
            <stop offset="0.262" stopColor="#834f20" />
            <stop offset="0.476" stopColor="#ba872c" />
            <stop offset="0.504" stopColor="#c18f2c" />
            <stop offset="0.708" stopColor="#e6c95b" />
            <stop offset="0.802" stopColor="#f7e67e" />
            <stop offset="0.836" stopColor="#f4e177" />
            <stop offset="0.88" stopColor="#ecd367" />
            <stop offset="0.93" stopColor="#ddbb4b" />
            <stop offset="0.983" stopColor="#c99a2c" />
            <stop offset="1" stopColor="#c18f2c" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-8"
            x1="10.654"
            y1="22.674"
            x2="10.668"
            y2="22.704"
            xlinkHref="#linear-gradient-7"
          />
          <linearGradient
            id="linear-gradient-9"
            x1="-32.863"
            y1="5.907"
            x2="-32.842"
            y2="5.879"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-10"
            x1="-16.364"
            y1="21.697"
            x2="-16.361"
            y2="21.63"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-11"
            x1="-73.439"
            y1="5.736"
            x2="-73.418"
            y2="5.708"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-12"
            x1="-39.085"
            y1="21.365"
            x2="-39.082"
            y2="21.299"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-13"
            x1="-18.747"
            y1="7.761"
            x2="-18.747"
            y2="7.779"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#a46e29" />
            <stop offset="0.012" stopColor="#ac792b" />
            <stop offset="0.057" stopColor="#c69e3a" />
            <stop offset="0.102" stopColor="#dbbe57" />
            <stop offset="0.145" stopColor="#ebd46d" />
            <stop offset="0.186" stopColor="#f4e179" />
            <stop offset="0.224" stopColor="#f7e67e" />
            <stop offset="0.274" stopColor="#f4e078" />
            <stop offset="0.339" stopColor="#e8d169" />
            <stop offset="0.411" stopColor="#d6b650" />
            <stop offset="0.454" stopColor="#c9a33e" />
            <stop offset="0.571" stopColor="#c18f2c" />
            <stop offset="0.69" stopColor="#dcb95b" />
            <stop offset="0.838" stopColor="#fff1b6" />
            <stop offset="0.859" stopColor="#fbecaf" />
            <stop offset="0.885" stopColor="#f4e2a0" />
            <stop offset="0.915" stopColor="#e9d085" />
            <stop offset="0.947" stopColor="#d7b85f" />
            <stop offset="0.981" stopColor="#c09731" />
            <stop offset="1" stopColor="#b3862d" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-14"
            x1="-27.901"
            y1="7.844"
            x2="-27.901"
            y2="7.862"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-15"
            x1="-2.504"
            y1="20.174"
            x2="-2.504"
            y2="20.21"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-16"
            x1="9.633"
            y1="8.605"
            x2="9.633"
            y2="8.64"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-17"
            x1="0.36"
            y1="-0.274"
            x2="0.358"
            y2="-0.265"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-18"
            x1="0.929"
            y1="4.574"
            x2="0.929"
            y2="4.599"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-19"
            x1="-19.878"
            y1="24.36"
            x2="-19.884"
            y2="24.399"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-20"
            x1="-44.102"
            y1="24.85"
            x2="-44.109"
            y2="24.889"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-21"
            x1="-2.866"
            y1="28.286"
            x2="-2.849"
            y2="28.249"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-22"
            x1="-3.423"
            y1="25.397"
            x2="-3.414"
            y2="25.376"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-23"
            x1="-21.057"
            y1="17.417"
            x2="-21.057"
            y2="17.5"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-24"
            x1="-31.18"
            y1="20.782"
            x2="-31.18"
            y2="20.865"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-25"
            x1="-7.363"
            y1="8.911"
            x2="-7.363"
            y2="8.923"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-26"
            x1="-14.932"
            y1="7.136"
            x2="-14.932"
            y2="7.149"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-27"
            x1="-5.374"
            y1="34.284"
            x2="-5.374"
            y2="34.367"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-28"
            x1="-17.558"
            y1="10.784"
            x2="-17.558"
            y2="10.802"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#f7e67e" />
            <stop offset="0.003" stopColor="#f4e37b" />
            <stop offset="0.05" stopColor="#d4b04b" />
            <stop offset="0.091" stopColor="#b98b2d" />
            <stop offset="0.124" stopColor="#a9752a" />
            <stop offset="0.144" stopColor="#a46e29" />
            <stop offset="0.179" stopColor="#a9742a" />
            <stop offset="0.23" stopColor="#b7872d" />
            <stop offset="0.292" stopColor="#cea944" />
            <stop offset="0.361" stopColor="#eed770" />
            <stop offset="0.381" stopColor="#f7e67e" />
            <stop offset="0.518" stopColor="#f4e078" />
            <stop offset="0.687" stopColor="#e9d26a" />
            <stop offset="0.873" stopColor="#d8b851" />
            <stop offset="1" stopColor="#c9a33e" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-29"
            x1="18.713"
            y1="6.074"
            x2="18.713"
            y2="6.092"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-30"
            x1="2.956"
            y1="6.1"
            x2="2.956"
            y2="6.119"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-31"
            x1="-9.158"
            y1="22.554"
            x2="-9.158"
            y2="22.602"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-32"
            x1="1.879"
            y1="5.251"
            x2="1.88"
            y2="5.269"
            xlinkHref="#linear-gradient-7"
          />
          <linearGradient
            id="linear-gradient-33"
            x1="-0.08"
            y1="2.188"
            x2="-0.063"
            y2="2.198"
            xlinkHref="#linear-gradient-7"
          />
          <linearGradient
            id="linear-gradient-34"
            x1="12.057"
            y1="8.337"
            x2="12.074"
            y2="8.346"
            xlinkHref="#linear-gradient-7"
          />
          <linearGradient
            id="linear-gradient-35"
            x1="2.947"
            y1="6.616"
            x2="2.945"
            y2="6.624"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-36"
            x1="-8.043"
            y1="2.274"
            x2="-8.045"
            y2="2.283"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-37"
            x1="-10.859"
            y1="15.761"
            x2="-10.854"
            y2="15.734"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-38"
            x1="-19.576"
            y1="9.235"
            x2="-19.569"
            y2="9.23"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-39"
            x1="-17.755"
            y1="5.669"
            x2="-17.751"
            y2="5.641"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-40"
            x1="-32.081"
            y1="3.736"
            x2="-32.074"
            y2="3.731"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-41"
            x1="1.432"
            y1="6.423"
            x2="1.435"
            y2="6.412"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-42"
            x1="0.671"
            y1="6.555"
            x2="0.673"
            y2="6.548"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-43"
            x1="-10.588"
            y1="-0.012"
            x2="-10.585"
            y2="-0.022"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-44"
            x1="-10.617"
            y1="-0.012"
            x2="-10.616"
            y2="-0.019"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-45"
            x1="4.82"
            y1="0.267"
            x2="4.823"
            y2="0.256"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-46"
            x1="3.853"
            y1="0.272"
            x2="3.854"
            y2="0.265"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-47"
            x1="-2.144"
            y1="-1.33"
            x2="-2.14"
            y2="-1.345"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-48"
            x1="-1.205"
            y1="-1.429"
            x2="-1.203"
            y2="-1.44"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-49"
            x1="9.342"
            y1="4.894"
            x2="9.346"
            y2="4.879"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-50"
            x1="9.296"
            y1="5.082"
            x2="9.297"
            y2="5.071"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-51"
            x1="1.548"
            y1="0.248"
            x2="1.556"
            y2="0.231"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-52"
            x1="1.825"
            y1="0.634"
            x2="1.827"
            y2="0.606"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-53"
            x1="8.758"
            y1="9.325"
            x2="8.766"
            y2="9.308"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-54"
            x1="6.38"
            y1="15.239"
            x2="6.381"
            y2="15.211"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-55"
            x1="-28.424"
            y1="7.92"
            x2="-28.416"
            y2="7.903"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-56"
            x1="-17.109"
            y1="12.978"
            x2="-17.107"
            y2="12.95"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-57"
            x1="-5.182"
            y1="-0.12"
            x2="-5.182"
            y2="-0.114"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-58"
            x1="-4.777"
            y1="2.83"
            x2="-4.777"
            y2="2.836"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-59"
            x1="-1.874"
            y1="4.901"
            x2="-1.874"
            y2="4.907"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-60"
            x1="-2.228"
            y1="-0.262"
            x2="-2.228"
            y2="-0.258"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-61"
            x1="0.987"
            y1="-0.032"
            x2="0.987"
            y2="-0.028"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-62"
            x1="-5.083"
            y1="2.502"
            x2="-5.083"
            y2="2.505"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-63"
            x1="3.036"
            y1="3.119"
            x2="3.036"
            y2="3.123"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-64"
            x1="-4.182"
            y1="4.406"
            x2="-4.182"
            y2="4.413"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-65"
            x1="-5.94"
            y1="5.084"
            x2="-5.94"
            y2="5.09"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-66"
            x1="0.899"
            y1="3.352"
            x2="0.899"
            y2="3.356"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0.626" stopColor="#f7e67e" />
            <stop offset="0.683" stopColor="#ddc15a" />
            <stop offset="0.731" stopColor="#ceab44" />
            <stop offset="0.76" stopColor="#c9a33e" />
            <stop offset="0.895" stopColor="#f7e67e" />
            <stop offset="0.918" stopColor="#f4e078" />
            <stop offset="0.947" stopColor="#e9d26a" />
            <stop offset="0.978" stopColor="#d8b851" />
            <stop offset="1" stopColor="#c9a33e" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-67"
            x1="2.806"
            y1="1.084"
            x2="2.806"
            y2="1.088"
            xlinkHref="#linear-gradient-66"
          />
          <linearGradient
            id="linear-gradient-68"
            x1="-4.893"
            y1="2.897"
            x2="-4.893"
            y2="2.901"
            xlinkHref="#linear-gradient-66"
          />
          <linearGradient
            id="linear-gradient-69"
            x1="-5.852"
            y1="-0.089"
            x2="-5.852"
            y2="-0.085"
            xlinkHref="#linear-gradient-66"
          />
          <linearGradient
            id="linear-gradient-70"
            x1="-8.385"
            y1="12.196"
            x2="-8.382"
            y2="12.23"
            xlinkHref="#linear-gradient-7"
          />
          <linearGradient
            id="linear-gradient-71"
            x1="-13.574"
            y1="14.97"
            x2="-13.571"
            y2="15.004"
            xlinkHref="#linear-gradient-7"
          />
          <linearGradient
            id="linear-gradient-72"
            x1="0.626"
            y1="4.995"
            x2="0.624"
            y2="5.006"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-73"
            x1="6.038"
            y1="7.528"
            x2="6.036"
            y2="7.54"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-74"
            x1="-13.097"
            y1="6.49"
            x2="-13.099"
            y2="6.511"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-75"
            x1="-0.748"
            y1="8.292"
            x2="-0.749"
            y2="8.305"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-76"
            x1="3.414"
            y1="-0.944"
            x2="3.412"
            y2="-0.927"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-77"
            x1="-20.55"
            y1="17.564"
            x2="-20.543"
            y2="17.526"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-78"
            x1="-43.395"
            y1="10.023"
            x2="-43.383"
            y2="10.016"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-79"
            x1="15.587"
            y1="27.385"
            x2="15.594"
            y2="27.347"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-80"
            x1="27.633"
            y1="15.529"
            x2="27.644"
            y2="15.522"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-81"
            x1="-31.276"
            y1="27.976"
            x2="-31.276"
            y2="28.032"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-82"
            x1="-6.128"
            y1="22.887"
            x2="-6.128"
            y2="22.942"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-83"
            x1="-67.729"
            y1="56.556"
            x2="-67.743"
            y2="56.628"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-84"
            x1="-21.197"
            y1="30.63"
            x2="-21.21"
            y2="30.702"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-85"
            x1="-67.321"
            y1="5.988"
            x2="-67.335"
            y2="6.06"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-86"
            x1="-75.659"
            y1="32.888"
            x2="-75.673"
            y2="32.96"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-87"
            x1="-24.98"
            y1="50.501"
            x2="-25"
            y2="50.603"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-88"
            x1="-121.392"
            y1="61.823"
            x2="-121.412"
            y2="61.924"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-89"
            x1="2.771"
            y1="17.656"
            x2="2.751"
            y2="17.757"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-90"
            x1="16.831"
            y1="35.849"
            x2="16.819"
            y2="35.937"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-91"
            x1="-50.27"
            y1="29.053"
            x2="-50.248"
            y2="29.021"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-92"
            x1="-50.915"
            y1="27.927"
            x2="-50.905"
            y2="27.91"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-93"
            x1="-10.333"
            y1="4.195"
            x2="-10.333"
            y2="4.253"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-94"
            x1="-32.614"
            y1="-13.495"
            x2="-32.625"
            y2="-13.374"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-95"
            x1="-12.846"
            y1="9.841"
            x2="-12.834"
            y2="9.769"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-96"
            x1="-21.418"
            y1="4.34"
            x2="-21.407"
            y2="4.32"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-97"
            x1="-71.927"
            y1="30.776"
            x2="-71.915"
            y2="30.703"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-98"
            x1="-107.501"
            y1="18.481"
            x2="-107.49"
            y2="18.461"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-99"
            x1="-43.272"
            y1="57.503"
            x2="-43.26"
            y2="57.43"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-100"
            x1="-65.66"
            y1="36.781"
            x2="-65.649"
            y2="36.76"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-101"
            x1="5.61"
            y1="4.178"
            x2="5.604"
            y2="4.19"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-102"
            x1="-9.283"
            y1="11.597"
            x2="-9.275"
            y2="11.613"
            xlinkHref="#linear-gradient-7"
          />
          <linearGradient
            id="linear-gradient-103"
            x1="-23.524"
            y1="9.363"
            x2="-23.516"
            y2="9.38"
            xlinkHref="#linear-gradient-7"
          />
          <linearGradient
            id="linear-gradient-104"
            x1="-10.485"
            y1="17.703"
            x2="-10.471"
            y2="17.684"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-105"
            x1="-5.795"
            y1="35.517"
            x2="-5.793"
            y2="35.473"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-106"
            x1="-46.859"
            y1="21.243"
            x2="-46.845"
            y2="21.225"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-107"
            x1="-26.11"
            y1="42.49"
            x2="-26.108"
            y2="42.446"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-108"
            x1="3.467"
            y1="13.889"
            x2="3.471"
            y2="13.867"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-109"
            x1="7.4"
            y1="7.658"
            x2="7.407"
            y2="7.653"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-110"
            x1="-23.501"
            y1="55.848"
            x2="-23.461"
            y2="55.793"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-111"
            x1="-9.573"
            y1="135.977"
            x2="-9.568"
            y2="135.849"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-112"
            x1="1.726"
            y1="56.916"
            x2="1.767"
            y2="56.861"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-113"
            x1="4.278"
            y1="135.429"
            x2="4.284"
            y2="135.3"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-114"
            x1="-131.48"
            y1="26.761"
            x2="-131.422"
            y2="26.683"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-115"
            x1="-66.367"
            y1="126.813"
            x2="-66.359"
            y2="126.629"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-116"
            x1="1.266"
            y1="15.588"
            x2="1.266"
            y2="15.628"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-117"
            x1="-5.403"
            y1="-2.769"
            x2="-5.403"
            y2="-2.721"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-118"
            x1="-24.614"
            y1="-0.786"
            x2="-24.614"
            y2="-0.745"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-119"
            x1="-24.383"
            y1="8.348"
            x2="-24.383"
            y2="8.376"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-120"
            x1="-47.447"
            y1="9.234"
            x2="-47.447"
            y2="9.263"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-121"
            x1="0.677"
            y1="40.969"
            x2="0.717"
            y2="40.915"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-122"
            x1="3.99"
            y1="121.721"
            x2="3.996"
            y2="121.593"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-123"
            x1="4.05"
            y1="83.206"
            x2="4.05"
            y2="83.394"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-124"
            x1="-33.926"
            y1="38.672"
            x2="-33.945"
            y2="38.811"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-125"
            x1="-84.586"
            y1="64.196"
            x2="-84.605"
            y2="64.335"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-126"
            x1="-38.758"
            y1="22.723"
            x2="-38.758"
            y2="22.788"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-127"
            x1="3.568"
            y1="5.624"
            x2="3.568"
            y2="5.688"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-128"
            x1="-44.999"
            y1="138.066"
            x2="-44.999"
            y2="138.366"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-129"
            x1="2.729"
            y1="81.454"
            x2="2.729"
            y2="81.758"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-130"
            x1="-96.005"
            y1="43.285"
            x2="-96.024"
            y2="43.53"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-131"
            x1="-21.824"
            y1="49.047"
            x2="-21.824"
            y2="49.187"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-132"
            x1="-68.211"
            y1="53.796"
            x2="-68.211"
            y2="53.942"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-133"
            x1="-17.942"
            y1="-4.471"
            x2="-17.96"
            y2="-4.174"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-134"
            x1="-19.977"
            y1="15.555"
            x2="-19.977"
            y2="15.71"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-135"
            x1="-20.089"
            y1="55.614"
            x2="-20.089"
            y2="55.799"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-136"
            x1="-47.262"
            y1="100.105"
            x2="-47.262"
            y2="100.292"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-137"
            x1="-4.848"
            y1="27.144"
            x2="-4.848"
            y2="27.188"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-138"
            x1="-66.598"
            y1="31.722"
            x2="-66.558"
            y2="31.667"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-139"
            x1="-33.473"
            y1="94.177"
            x2="-33.468"
            y2="94.049"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-140"
            x1="-15.339"
            y1="23.536"
            x2="-15.339"
            y2="23.622"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-141"
            x1="-73.257"
            y1="11.086"
            x2="-73.257"
            y2="11.13"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-142"
            x1="-53.891"
            y1="13.436"
            x2="-53.874"
            y2="13.398"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-143"
            x1="-56.551"
            y1="11.667"
            x2="-56.543"
            y2="11.647"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-144"
            x1="-126.114"
            y1="23.223"
            x2="-126.073"
            y2="23.168"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-145"
            x1="-66.263"
            y1="82.196"
            x2="-66.257"
            y2="82.069"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-146"
            x1="-62.341"
            y1="55.714"
            x2="-62.341"
            y2="55.901"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-147"
            x1="-121.024"
            y1="6.965"
            x2="-121.024"
            y2="7.008"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-148"
            x1="-52.871"
            y1="45.874"
            x2="-52.883"
            y2="45.96"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-149"
            x1="-57.906"
            y1="35.638"
            x2="-57.918"
            y2="35.723"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-150"
            x1="-16.563"
            y1="23.292"
            x2="-16.582"
            y2="23.431"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-151"
            x1="-104.221"
            y1="70.077"
            x2="-104.24"
            y2="70.216"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-152"
            x1="-57.636"
            y1="35.417"
            x2="-57.652"
            y2="35.661"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-153"
            x1="-47.303"
            y1="75.677"
            x2="-47.303"
            y2="75.818"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-154"
            x1="-50.154"
            y1="31.715"
            x2="-50.172"
            y2="31.95"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-155"
            x1="-18.069"
            y1="36.117"
            x2="-18.087"
            y2="36.362"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-156"
            x1="-15.618"
            y1="16.753"
            x2="-15.63"
            y2="16.838"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-157"
            x1="-43.082"
            y1="38.164"
            x2="-43.099"
            y2="38.285"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-158"
            x1="-58.926"
            y1="1.945"
            x2="-58.945"
            y2="2.084"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-159"
            x1="-70.062"
            y1="86.193"
            x2="-70.062"
            y2="86.499"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-160"
            x1="-85.78"
            y1="115.429"
            x2="-85.78"
            y2="115.734"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-161"
            x1="-70.902"
            y1="-5.321"
            x2="-70.919"
            y2="-5.199"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-162"
            x1="-109.796"
            y1="38.178"
            x2="-109.757"
            y2="38.129"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-163"
            x1="-61.801"
            y1="91.666"
            x2="-61.794"
            y2="91.574"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-164"
            x1="16.818"
            y1="8.684"
            x2="16.806"
            y2="8.769"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-165"
            x1="4.149"
            y1="16.598"
            x2="4.188"
            y2="16.55"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-166"
            x1="6.612"
            y1="71.779"
            x2="6.619"
            y2="71.687"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-167"
            x1="-96.331"
            y1="30.441"
            x2="-96.331"
            y2="30.5"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-168"
            x1="12.282"
            y1="14.402"
            x2="12.282"
            y2="14.461"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-169"
            x1="-22.89"
            y1="20.327"
            x2="-22.89"
            y2="20.369"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-170"
            x1="-94.261"
            y1="32.747"
            x2="-94.261"
            y2="32.81"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-171"
            x1="-4.199"
            y1="14.638"
            x2="-4.199"
            y2="14.685"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-172"
            x1="-73.884"
            y1="7.007"
            x2="-73.884"
            y2="7.074"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-173"
            x1="-23.399"
            y1="50.066"
            x2="-23.358"
            y2="50.011"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-174"
            x1="-9.574"
            y1="124.568"
            x2="-9.569"
            y2="124.441"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-175"
            x1="-9.174"
            y1="28.357"
            x2="-9.174"
            y2="28.424"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-176"
            x1="-4.785"
            y1="39.632"
            x2="-4.77"
            y2="39.55"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-177"
            x1="-4.688"
            y1="41.428"
            x2="-4.682"
            y2="41.376"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-178"
            x1="-34.601"
            y1="-1.334"
            x2="-34.601"
            y2="-1.32"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-179"
            x1="-74.631"
            y1="-0.769"
            x2="-74.631"
            y2="-0.702"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-180"
            x1="-39.326"
            y1="0.746"
            x2="-39.315"
            y2="0.689"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-181"
            x1="-37.389"
            y1="1.087"
            x2="-37.385"
            y2="1.051"
            xlinkHref="#linear-gradient-4"
          />
          <linearGradient
            id="linear-gradient-182"
            x1="-7.194"
            y1="15.631"
            x2="-7.182"
            y2="15.658"
            xlinkHref="#linear-gradient-7"
          />
          <linearGradient
            id="linear-gradient-183"
            x1="-39.476"
            y1="21.369"
            x2="-39.464"
            y2="21.395"
            xlinkHref="#linear-gradient-7"
          />
          <linearGradient
            id="linear-gradient-184"
            x1="13.129"
            y1="22.613"
            x2="13.141"
            y2="22.639"
            xlinkHref="#linear-gradient-7"
          />
          <linearGradient
            id="linear-gradient-185"
            x1="-20.566"
            y1="1.17"
            x2="-20.566"
            y2="1.197"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-186"
            x1="-44.964"
            y1="0.006"
            x2="-44.964"
            y2="0.033"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-187"
            x1="-23.355"
            y1="19.647"
            x2="-23.362"
            y2="19.686"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-188"
            x1="-29.778"
            y1="27.224"
            x2="-29.787"
            y2="27.282"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-189"
            x1="-103.186"
            y1="5.839"
            x2="-103.186"
            y2="5.88"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-190"
            x1="-9.984"
            y1="1.937"
            x2="-9.984"
            y2="1.978"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-191"
            x1="-70.518"
            y1="24.917"
            x2="-70.518"
            y2="24.96"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-192"
            x1="-27.928"
            y1="14.222"
            x2="-27.928"
            y2="14.24"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-193"
            x1="-17.411"
            y1="20.468"
            x2="-17.411"
            y2="20.508"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-194"
            x1="-57.676"
            y1="33.011"
            x2="-57.676"
            y2="33.052"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-195"
            x1="-37.276"
            y1="3.791"
            x2="-37.258"
            y2="3.83"
            xlinkHref="#linear-gradient-7"
          />
          <linearGradient
            id="linear-gradient-196"
            x1="-9.727"
            y1="23.524"
            x2="-9.732"
            y2="23.558"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-197"
            x1="-31.645"
            y1="18.209"
            x2="-31.65"
            y2="18.243"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-198"
            x1="4.317"
            y1="9.903"
            x2="4.312"
            y2="9.937"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-199"
            x1="12.33"
            y1="21.015"
            x2="12.316"
            y2="21.087"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-200"
            x1="-26.902"
            y1="21.525"
            x2="-26.902"
            y2="21.576"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-201"
            x1="-19.774"
            y1="8.951"
            x2="-19.788"
            y2="9.023"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-202"
            x1="-7.669"
            y1="8.045"
            x2="-7.669"
            y2="8.07"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-203"
            x1="-8.485"
            y1="12.811"
            x2="-8.485"
            y2="12.859"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-204"
            x1="-26.455"
            y1="37.457"
            x2="-26.455"
            y2="37.525"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-205"
            x1="-54.714"
            y1="81.589"
            x2="-54.714"
            y2="81.781"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-206"
            x1="-68"
            y1="51.259"
            x2="-68.013"
            y2="51.335"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-207"
            x1="2.754"
            y1="1.55"
            x2="2.752"
            y2="1.559"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-208"
            x1="5.377"
            y1="4.194"
            x2="5.375"
            y2="4.202"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-209"
            x1="-22.511"
            y1="3.45"
            x2="-22.519"
            y2="3.467"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-210"
            x1="-3.145"
            y1="17.971"
            x2="-3.145"
            y2="18.037"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-211"
            x1="11.889"
            y1="31.152"
            x2="11.889"
            y2="31.218"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-212"
            x1="-14.911"
            y1="0.125"
            x2="-14.911"
            y2="0.14"
            xlinkHref="#linear-gradient-13"
          />
          <linearGradient
            id="linear-gradient-213"
            x1="-8.344"
            y1="0.402"
            x2="-8.344"
            y2="0.411"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-214"
            x1="-30.816"
            y1="2.029"
            x2="-30.816"
            y2="2.038"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-215"
            x1="14.793"
            y1="5.915"
            x2="14.793"
            y2="5.925"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-216"
            x1="-0.623"
            y1="1.023"
            x2="-0.623"
            y2="1.031"
            xlinkHref="#linear-gradient-28"
          />
          <linearGradient
            id="linear-gradient-217"
            x1="-12.764"
            y1="-2.296"
            x2="-12.759"
            y2="-2.32"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-218"
            x1="-25.573"
            y1="-1.37"
            x2="-25.564"
            y2="-1.376"
            xlinkHref="#linear-gradient-4"
          />
        </defs>
        <g id="Confetti" transform="translate(-9.302 0.5)">
          <path
            id="Path_3643"
            d="M909.8,953.9c-13.1-10.6-14.4-26.5-3.1-38.7l16.5-3.9c-13.1,14-11.9,32.2,2.8,44.2Z"
            fill="url(#linear-gradient)"
          />
          <path
            id="Path_3644"
            d="M931.5,150.2c1.8,16.7-8.6,28.8-25.2,29.5l-14.4-8.9c19.1-.6,31.1-14.3,29.2-33.1C924.5,141.9,928,146.1,931.5,150.2Z"
            fill="url(#linear-gradient-2)"
          />
          <g id="Group_544" transform="translate(636.58 816.261)">
            <path id="Path_3645" d="M9.2-5.1,9-5H8.9Z" fill="#1d262d" />
            <path
              id="Path_3646"
              d="M8.1-4.8a7.512,7.512,0,0,0,2.6-.5c-.6.1-1.2.3-1.9.4A1.483,1.483,0,0,1,8.1-4.8Z"
              fill="#1d262d"
            />
            <path
              id="Path_3647"
              d="M15.8-9.1a16.344,16.344,0,0,1-5.1-3A16.55,16.55,0,0,1,5-24.6c0,.2-17,3.6-17,3.7A16.562,16.562,0,0,0,4.3-4.3a6.15,6.15,0,0,0,1.4-.1,20.023,20.023,0,0,0,2.4-.4A8.144,8.144,0,0,0,9-5,16.368,16.368,0,0,0,15.8-9.1Z"
              fill="url(#linear-gradient-3)"
            />
            <path
              id="Path_3648"
              d="M10.7-5.3c4-.8,8.1-1.7,12.1-2.6-1.4.3-3.5.7-6.3,1.3C14-6,12.1-5.6,10.7-5.3Z"
              fill="#1d262d"
            />
            <path
              id="Path_3649"
              d="M20.7-20.8A16.585,16.585,0,0,1,15.8-9.1,16.721,16.721,0,0,1,8.9-5l.2-.1L8.9-5H9a8.144,8.144,0,0,1-.9.2c.2,0,.5-.1.7-.1.6-.1,1.3-.3,1.9-.4,1.4-.3,3.3-.7,5.7-1.3,2.8-.6,4.9-1,6.3-1.3a3.553,3.553,0,0,1,1-.2c.3-.1.6-.1.9-.2a18.448,18.448,0,0,0,8.7-4.9,16.422,16.422,0,0,0,4.4-11.2C37.7-24.5,20.7-20.9,20.7-20.8ZM24-8.3h0Z"
              fill="url(#linear-gradient-4)"
            />
          </g>
          <g id="Group_575" transform="translate(171.528 1005.575)">
            <path id="Path_3645-2" d="M9.2-5.1,9-5H8.9Z" fill="#1d262d" />
            <path
              id="Path_3646-2"
              d="M8.1-4.8a7.512,7.512,0,0,0,2.6-.5c-.6.1-1.2.3-1.9.4A1.483,1.483,0,0,1,8.1-4.8Z"
              fill="#1d262d"
            />
            <path
              id="Path_3647-2"
              d="M15.8-9.1a16.344,16.344,0,0,1-5.1-3A16.55,16.55,0,0,1,5-24.6c0,.2-17,3.6-17,3.7A16.562,16.562,0,0,0,4.3-4.3a6.15,6.15,0,0,0,1.4-.1,20.023,20.023,0,0,0,2.4-.4A8.144,8.144,0,0,0,9-5,16.368,16.368,0,0,0,15.8-9.1Z"
              fill="url(#linear-gradient-5)"
            />
            <path
              id="Path_3648-2"
              d="M10.7-5.3c4-.8,8.1-1.7,12.1-2.6-1.4.3-3.5.7-6.3,1.3C14-6,12.1-5.6,10.7-5.3Z"
              fill="#1d262d"
            />
            <path
              id="Path_3649-2"
              d="M20.7-20.8A16.585,16.585,0,0,1,15.8-9.1,16.721,16.721,0,0,1,8.9-5l.2-.1L8.9-5H9a8.144,8.144,0,0,1-.9.2c.2,0,.5-.1.7-.1.6-.1,1.3-.3,1.9-.4,1.4-.3,3.3-.7,5.7-1.3,2.8-.6,4.9-1,6.3-1.3a3.553,3.553,0,0,1,1-.2c.3-.1.6-.1.9-.2a18.448,18.448,0,0,0,8.7-4.9,16.422,16.422,0,0,0,4.4-11.2C37.7-24.5,20.7-20.9,20.7-20.8ZM24-8.3h0Z"
              fill="url(#linear-gradient-6)"
            />
          </g>
          <path
            id="Path_3650"
            d="M1152.3,881.4a40.765,40.765,0,0,0-24.6,31l6.5,1.9a28.609,28.609,0,0,1,22.8-16.4Z"
            fill="url(#linear-gradient-7)"
          />
          <path
            id="Path_3651"
            d="M544.4,288.7a40.765,40.765,0,0,0-24.6,31l6.5,1.9a28.758,28.758,0,0,1,22.8-16.4C547.6,299.6,546,294.1,544.4,288.7Z"
            fill="url(#linear-gradient-8)"
          />
          <g id="Group_545" transform="translate(1318.776 911.836)">
            <path id="Path_3652" d="M19.4-14.1l.2.1h0Z" fill="#1d262d" />
            <path
              id="Path_3653"
              d="M20.3-13.6c.3.1-.4-.3-2-1.3a13.029,13.029,0,0,1,1.5,1A2.186,2.186,0,0,0,20.3-13.6Z"
              fill="#1d262d"
            />
            <path
              id="Path_3654"
              d="M12.4-15.8a13.9,13.9,0,0,1,1.4,5.3A15.559,15.559,0,0,1,9.4,1.4C9.5,1.2,22.8,10,22.9,10A15.029,15.029,0,0,0,23-11.3a9.016,9.016,0,0,0-1-.9,20.279,20.279,0,0,0-1.8-1.3,7.762,7.762,0,0,1-.7-.4A13.5,13.5,0,0,0,12.4-15.8Z"
              fill="url(#linear-gradient-9)"
            />
            <path
              id="Path_3655"
              d="M18.3-14.9C15.1-17,11.9-19,8.7-21.1c1.1.7,2.8,1.8,5,3.3C15.6-16.6,17.2-15.6,18.3-14.9Z"
              fill="#1d262d"
            />
            <path
              id="Path_3656"
              d="M1.6-11.4a16.2,16.2,0,0,1,10.8-4.5,15.075,15.075,0,0,1,7.2,1.8l-.2-.1.2.1h0a3.217,3.217,0,0,1,.7.4,1,1,0,0,1-.5-.4,13.029,13.029,0,0,1-1.5-1c-1.1-.7-2.6-1.7-4.6-2.9-2.2-1.5-3.9-2.6-5-3.3-.3-.2-.5-.3-.8-.5-.2-.1-.5-.3-.7-.4a16.653,16.653,0,0,0-8.9-2.5,15.433,15.433,0,0,0-10.1,4.5C-11.9-20,1.5-11.3,1.6-11.4Zm6-10.3.2.2-.1-.1-.1-.1Z"
              fill="url(#linear-gradient-10)"
            />
          </g>
          <g id="Group_581" transform="translate(2048.14 916.244)">
            <path id="Path_3652-2" d="M19.4-14.1l.2.1h0Z" fill="#1d262d" />
            <path
              id="Path_3653-2"
              d="M20.3-13.6c.3.1-.4-.3-2-1.3a13.029,13.029,0,0,1,1.5,1A2.186,2.186,0,0,0,20.3-13.6Z"
              fill="#1d262d"
            />
            <path
              id="Path_3654-2"
              d="M12.4-15.8a13.9,13.9,0,0,1,1.4,5.3A15.559,15.559,0,0,1,9.4,1.4C9.5,1.2,22.8,10,22.9,10A15.029,15.029,0,0,0,23-11.3a9.016,9.016,0,0,0-1-.9,20.279,20.279,0,0,0-1.8-1.3,7.762,7.762,0,0,1-.7-.4A13.5,13.5,0,0,0,12.4-15.8Z"
              fill="url(#linear-gradient-11)"
            />
            <path
              id="Path_3655-2"
              d="M18.3-14.9C15.1-17,11.9-19,8.7-21.1c1.1.7,2.8,1.8,5,3.3C15.6-16.6,17.2-15.6,18.3-14.9Z"
              fill="#1d262d"
            />
            <path
              id="Path_3656-2"
              d="M1.6-11.4a16.2,16.2,0,0,1,10.8-4.5,15.075,15.075,0,0,1,7.2,1.8l-.2-.1.2.1h0a3.217,3.217,0,0,1,.7.4,1,1,0,0,1-.5-.4,13.029,13.029,0,0,1-1.5-1c-1.1-.7-2.6-1.7-4.6-2.9-2.2-1.5-3.9-2.6-5-3.3-.3-.2-.5-.3-.8-.5-.2-.1-.5-.3-.7-.4a16.653,16.653,0,0,0-8.9-2.5,15.433,15.433,0,0,0-10.1,4.5C-11.9-20,1.5-11.3,1.6-11.4Zm6-10.3.2.2-.1-.1-.1-.1Z"
              fill="url(#linear-gradient-12)"
            />
          </g>
          <path
            id="Path_3657"
            d="M1701.4,595.9c2-1.4,6.6-4.8,8.6-6.3-14.7-11.2-24.9-29.2-27.5-50.2-6.6,5.6-11.5,9.9-18,15.5C1671.6,571.3,1682.1,588.4,1701.4,595.9Z"
            fill="url(#linear-gradient-13)"
          />
          <path
            id="Path_3911"
            d="M2117.9,591.2c2-1.4,6.6-4.8,8.6-6.3-14.7-11.2-24.9-29.2-27.5-50.2-6.5,5.6-11.5,9.9-18,15.5C2088.1,566.7,2098.6,583.8,2117.9,591.2Z"
            fill="url(#linear-gradient-14)"
          />
          <path
            id="Path_3658"
            d="M962,235.4c-1-2.3-3.2-7.5-4.2-9.8,18.3-2.1,36.3-12.4,49.3-29.2,2.8,8.1,4.8,14.4,7.6,22.5C1000.1,229.5,982.4,238.9,962,235.4Z"
            fill="url(#linear-gradient-15)"
          />
          <path
            id="Path_3898"
            d="M271.4,695.5c-1-2.3-3.2-7.5-4.2-9.8,18.3-2.1,36.3-12.4,49.3-29.2,2.8,8.1,4.8,14.4,7.6,22.5C309.6,689.6,291.8,698.9,271.4,695.5Z"
            fill="url(#linear-gradient-16)"
          />
          <path
            id="Path_3659"
            d="M891.6,1012.2c4.9,45.3-23.2,78.1-68.2,79.9l-39-24c51.8-1.7,84.3-38.7,79.1-89.7C872.8,989.7,882.2,1000.9,891.6,1012.2Z"
            fill="url(#linear-gradient-17)"
          />
          <path
            id="Path_3660"
            d="M799.6,864.9c1.4-1,4.6-3.4,6-4.4-10.3-7.8-17.4-20.4-19.2-35.2-4.6,3.9-8,6.9-12.6,10.8C778.8,847.7,786.1,859.7,799.6,864.9Z"
            fill="url(#linear-gradient-18)"
          />
          <path
            id="Path_3661"
            d="M1397.1,263.3c11.7-1.3,20.2,6,20.7,17.7l-6.2,10.1c-.4-13.4-10-21.8-23.2-20.5C1391.2,268.1,1394.1,265.7,1397.1,263.3Z"
            fill="url(#linear-gradient-19)"
          />
          <path
            id="Path_3916"
            d="M2109.3,249.6c11.7-1.3,20.2,6,20.7,17.7l-6.2,10.1c-.4-13.4-10-21.8-23.2-20.5C2103.5,254.5,2106.4,252,2109.3,249.6Z"
            fill="url(#linear-gradient-20)"
          />
          <g id="Group_546" transform="translate(885.277 277.176)">
            <path id="Path_3662" d="M-11.3-7.2l-.1-.2h0Z" fill="#1d262d" />
            <path
              id="Path_3663"
              d="M-11.6-8c-.1-.3.1.4.5,2.1a12.437,12.437,0,0,0-.4-1.6A.749.749,0,0,0-11.6-8Z"
              fill="#1d262d"
            />
            <path
              id="Path_3664"
              d="M-7.3-1.6A12.745,12.745,0,0,1-4.2-5.8,18.3,18.3,0,0,1,8.6-10.3c-.2,0-3.4-14.2-3.6-14.2-6.6-.1-14.1,3.2-16.4,9.8a11.914,11.914,0,0,0-.6,4.6c.1.7.2,1.4.3,2,0,.1.1.3.2.7A15.5,15.5,0,0,0-7.3-1.6Z"
              fill="url(#linear-gradient-21)"
            />
            <path
              id="Path_3665"
              d="M-11.1-5.9C-10.3-2.5-9.5.9-8.6,4.2-8.8,3-9.2,1.3-9.8-1.1-10.3-3.1-10.8-4.8-11.1-5.9Z"
              fill="#1d262d"
            />
            <path
              id="Path_3666"
              d="M4.6,2.7A18.707,18.707,0,0,1-7.3-1.6a12.62,12.62,0,0,1-4.1-5.8l.1.2-.1-.2h0c0-.3-.1-.6-.2-.6,0,.2.1.4.1.6a12.437,12.437,0,0,0,.4,1.6c.3,1.2.7,2.8,1.3,4.8.5,2.4,1,4.1,1.2,5.3.1.3.1.6.2.9.1.2.1.5.2.7a14.212,14.212,0,0,0,4.9,7.3A18.758,18.758,0,0,0,8,17C8.2,16.9,4.8,2.7,4.6,2.7ZM-8.2,5.2v0Z"
              fill="url(#linear-gradient-22)"
            />
          </g>
          <path
            id="Path_3667"
            d="M1648,700.4c-.3,1.7-.9,5.7-1.2,7.4,12.8-1.7,26.8,2.1,38.5,11.3.5-6,.8-10.6,1.3-16.6C1674.9,697.8,1661.3,694.6,1648,700.4Z"
            fill="url(#linear-gradient-23)"
          />
          <path
            id="Path_3912"
            d="M2050.9,626.1c-.3,1.7-.9,5.7-1.2,7.4,12.8-1.7,26.8,2.1,38.5,11.3.5-6,.8-10.6,1.3-16.6C2077.8,623.5,2064.1,620.2,2050.9,626.1Z"
            fill="url(#linear-gradient-24)"
          />
          <path
            id="Path_3668"
            d="M1470.6,183.8c-3,3-9.5,10.1-12.4,13.1,28,14.1,50.5,41,61.3,75.3,9.2-11.3,16.1-20.1,25.3-31.4C1527.9,215.5,1505.1,190.2,1470.6,183.8Z"
            fill="url(#linear-gradient-25)"
          />
          <path
            id="Path_3908"
            d="M2126.1,340.7c-3,3-9.5,10.1-12.4,13.1,28,14.1,50.5,41,61.3,75.3,9.2-11.3,16.1-20.1,25.3-31.4C2183.4,372.4,2160.6,347.2,2126.1,340.7Z"
            fill="url(#linear-gradient-26)"
          />
          <path
            id="Path_3669"
            d="M1023.8,282.3c-.3,1.7-.9,5.7-1.2,7.4,12.8-1.7,26.8,2.1,38.5,11.3.5-6,.8-10.6,1.3-16.6C1050.7,279.7,1037,276.5,1023.8,282.3Z"
            fill="url(#linear-gradient-27)"
          />
          <path
            id="Path_3670"
            d="M1225,397.8l-17.7-.8a69.8,69.8,0,0,0,0-55.6c5.9-.3,11.8-.5,17.7-.8A72.836,72.836,0,0,1,1225,397.8Z"
            fill="url(#linear-gradient-28)"
          />
          <path
            id="Path_3899"
            d="M369.4,667.2l-17.7-.8a69.8,69.8,0,0,0,0-55.6c5.9-.3,11.8-.5,17.7-.8A71.988,71.988,0,0,1,369.4,667.2Z"
            fill="url(#linear-gradient-29)"
          />
          <path
            id="Path_3671"
            d="M624.3,650.2c-4.2-6.1-8.3-12.2-12.5-18.3a87.655,87.655,0,0,0,55.8-41.8l14,17.1A89.616,89.616,0,0,1,624.3,650.2Z"
            fill="url(#linear-gradient-30)"
          />
          <path
            id="Path_3672"
            d="M1361.8,233.2c-1.6-5.7-3.1-11.4-4.7-17a70.319,70.319,0,0,0,52.9-17.1c2.1,5.5,4.1,11,6.2,16.6A71.941,71.941,0,0,1,1361.8,233.2Z"
            fill="url(#linear-gradient-31)"
          />
          <path
            id="Path_3673"
            d="M650,528.7c-75.5,13.2-150.9-12.6-200.2-67.2,7.9-9.2,15.9-18.4,23.8-27.6,47,28.5,103.7,30.8,150,7.3Q636.8,485,650,528.7Z"
            fill="url(#linear-gradient-32)"
          />
          <path
            id="Path_3674"
            d="M844,850.2c11.7-29,7.8-61.2-9.8-85.5-4.3,2.4-8.7,4.8-13,7.2,7.4,21.2,3.5,44-9.8,60.4C822.2,838.2,833.1,844.2,844,850.2Z"
            fill="url(#linear-gradient-33)"
          />
          <path
            id="Path_3900"
            d="M367.2,324.5c11.7-29,7.8-61.2-9.8-85.5-4.3,2.4-8.7,4.8-13,7.1,7.4,21.2,3.5,44-9.8,60.4C345.4,312.5,356.3,318.5,367.2,324.5Z"
            fill="url(#linear-gradient-34)"
          />
          <path
            id="Path_3675"
            d="M621.8,152.7c16.1,44.5-4.3,84.6-49.3,97.4l-45.2-14.7c51.9-14.4,75.6-59.7,57.8-110Z"
            fill="url(#linear-gradient-35)"
          />
          <path
            id="Path_3676"
            d="M1834.8,717.7c5.1,47.1-24.1,81.2-70.9,83l-40.5-24.9c53.8-1.8,87.5-40.2,82.2-93.2Z"
            fill="url(#linear-gradient-36)"
          />
          <g id="Group_547" transform="translate(1556.406 199.136)">
            <path
              id="Path_3677"
              d="M2.8,57.2c.1.2.1.4.2.5v.1Z"
              fill="#1d262d"
            />
            <path
              id="Path_3678"
              d="M3.5,59.6c.1.8-.6-1.1-2.2-5.5.5,1.3,1.1,2.7,1.6,4Z"
              fill="#1d262d"
            />
            <path
              id="Path_3679"
              d="M3.6,39a39.828,39.828,0,0,0,14.2,4.8c13.4,2,27.1-1.7,38.3-8.6-.5.3,14.2,36.6,14,36.7-16,9.8-39.2,13.5-55.5,2a30.889,30.889,0,0,1-8.7-9.3,45.9,45.9,0,0,1-2.4-5c-.1-.3-.2-.9-.5-1.8A32.529,32.529,0,0,1,3.6,39Z"
              fill="url(#linear-gradient-37)"
            />
            <path
              id="Path_3680"
              d="M1.3,54.1Q-3.8,41.2-8.6,28c1.2,3,3,7.5,5.4,13.6C-1.3,47,.2,51.1,1.3,54.1Z"
              fill="#1d262d"
            />
            <path
              id="Path_3681"
              d="M11.8-24.5C2.1-18.4-5.9-9.8-9.7.5c-3,7.9-2.8,15.6-.3,23.4.2.6.4,1.3.6,1.9.3.7.5,1.5.8,2.2,1.2,3,3,7.5,5.4,13.6q2.85,8.1,4.5,12.6c.5,1.3,1.1,2.7,1.6,4,.2.5.4,1,.6,1.4-.1-.3-.2-.9-.5-1.8v.1l-.2-.7c.1.2.1.4.2.5A31.409,31.409,0,0,1,3.6,39c3.5-10.8,12-20.4,22.1-26.7C26,12,11.8-24.5,11.8-24.5ZM-9.3,25.6l.1.3-.3-.6C-9.4,25.4-9.3,25.5-9.3,25.6Z"
              fill="url(#linear-gradient-38)"
            />
          </g>
          <g id="Group_577" transform="translate(2026.826 661.647)">
            <path
              id="Path_3677-2"
              d="M2.8,57.2c.1.2.1.4.2.5v.1Z"
              fill="#1d262d"
            />
            <path
              id="Path_3678-2"
              d="M3.5,59.6c.1.8-.6-1.1-2.2-5.5.5,1.3,1.1,2.7,1.6,4Z"
              fill="#1d262d"
            />
            <path
              id="Path_3679-2"
              d="M3.6,39a39.828,39.828,0,0,0,14.2,4.8c13.4,2,27.1-1.7,38.3-8.6-.5.3,14.2,36.6,14,36.7-16,9.8-39.2,13.5-55.5,2a30.889,30.889,0,0,1-8.7-9.3,45.9,45.9,0,0,1-2.4-5c-.1-.3-.2-.9-.5-1.8A32.529,32.529,0,0,1,3.6,39Z"
              fill="url(#linear-gradient-39)"
            />
            <path
              id="Path_3680-2"
              d="M1.3,54.1Q-3.8,41.2-8.6,28c1.2,3,3,7.5,5.4,13.6C-1.3,47,.2,51.1,1.3,54.1Z"
              fill="#1d262d"
            />
            <path
              id="Path_3681-2"
              d="M11.8-24.5C2.1-18.4-5.9-9.8-9.7.5c-3,7.9-2.8,15.6-.3,23.4.2.6.4,1.3.6,1.9.3.7.5,1.5.8,2.2,1.2,3,3,7.5,5.4,13.6q2.85,8.1,4.5,12.6c.5,1.3,1.1,2.7,1.6,4,.2.5.4,1,.6,1.4-.1-.3-.2-.9-.5-1.8v.1l-.2-.7c.1.2.1.4.2.5A31.409,31.409,0,0,1,3.6,39c3.5-10.8,12-20.4,22.1-26.7C26,12,11.8-24.5,11.8-24.5ZM-9.3,25.6l.1.3-.3-.6C-9.4,25.4-9.3,25.5-9.3,25.6Z"
              fill="url(#linear-gradient-40)"
            />
          </g>
          <g id="Group_548" transform="translate(685.967 386.845)">
            <path
              id="Path_3682"
              d="M66.6,64.1l-.9.3c-.1,0-.1.1-.2.1Z"
              fill="#1d262d"
            />
            <path
              id="Path_3683"
              d="M62.7,65.5c-1.2.5,2-.3,9.5-2.4-2.3.6-4.7,1.2-7,1.8C64.4,65.1,63.6,65.3,62.7,65.5Z"
              fill="#1d262d"
            />
            <path
              id="Path_3684"
              d="M91.4,45.9A58.95,58.95,0,0,1,72.5,32.4C58.1,17.2,51.5-3.8,51.3-24.5c0,.8-63.2,16.4-63.2,16.9C-11.7,22,3.2,55.1,33.2,64.9a49.149,49.149,0,0,0,20.5,2.2,70.8,70.8,0,0,0,9-1.6,23.764,23.764,0,0,1,3-1.1A59.51,59.51,0,0,0,91.4,45.9Z"
              fill="url(#linear-gradient-41)"
            />
            <path
              id="Path_3685"
              d="M72.3,63.1c15-3.8,30-7.8,45-12.1-5.2,1.3-13.1,3.3-23.6,5.9C84.5,59.7,77.4,61.7,72.3,63.1Z"
              fill="#1d262d"
            />
            <path
              id="Path_3686"
              d="M109.6-7.6c-.2,18.9-6.2,38.8-18.2,53.4A60.02,60.02,0,0,1,65.8,64.4l.9-.3-1.1.4c.1,0,.1-.1.2-.1a23.764,23.764,0,0,0-3,1.1c.8-.2,1.7-.4,2.5-.6,2.3-.6,4.7-1.2,7-1.8,5.1-1.4,12.2-3.5,21.4-6.1,10.5-2.6,18.4-4.6,23.6-5.9,1.3-.4,2.5-.7,3.8-1.1a29.924,29.924,0,0,0,3.2-1c13-4.3,23.9-11.3,32.3-22.5,11-14.6,16-32.8,16.2-51C172.8-24.5,109.6-8.1,109.6-7.6Zm12.2,57.1-1.2.2c.2,0,.4-.1.6-.1s.4-.1.6-.1Z"
              fill="url(#linear-gradient-42)"
            />
          </g>
          <g id="Group_580" transform="translate(1927.657 977.899)">
            <path
              id="Path_3682-2"
              d="M66.6,64.1l-.9.3c-.1,0-.1.1-.2.1Z"
              fill="#1d262d"
            />
            <path
              id="Path_3683-2"
              d="M62.7,65.5c-1.2.5,2-.3,9.5-2.4-2.3.6-4.7,1.2-7,1.8C64.4,65.1,63.6,65.3,62.7,65.5Z"
              fill="#1d262d"
            />
            <path
              id="Path_3684-2"
              d="M91.4,45.9A58.95,58.95,0,0,1,72.5,32.4C58.1,17.2,51.5-3.8,51.3-24.5c0,.8-63.2,16.4-63.2,16.9C-11.7,22,3.2,55.1,33.2,64.9a49.149,49.149,0,0,0,20.5,2.2,70.8,70.8,0,0,0,9-1.6,23.764,23.764,0,0,1,3-1.1A59.51,59.51,0,0,0,91.4,45.9Z"
              fill="url(#linear-gradient-43)"
            />
            <path
              id="Path_3685-2"
              d="M72.3,63.1c15-3.8,30-7.8,45-12.1-5.2,1.3-13.1,3.3-23.6,5.9C84.5,59.7,77.4,61.7,72.3,63.1Z"
              fill="#1d262d"
            />
            <path
              id="Path_3686-2"
              d="M109.6-7.6c-.2,18.9-6.2,38.8-18.2,53.4A60.02,60.02,0,0,1,65.8,64.4l.9-.3-1.1.4c.1,0,.1-.1.2-.1a23.764,23.764,0,0,0-3,1.1c.8-.2,1.7-.4,2.5-.6,2.3-.6,4.7-1.2,7-1.8,5.1-1.4,12.2-3.5,21.4-6.1,10.5-2.6,18.4-4.6,23.6-5.9,1.3-.4,2.5-.7,3.8-1.1a29.924,29.924,0,0,0,3.2-1c13-4.3,23.9-11.3,32.3-22.5,11-14.6,16-32.8,16.2-51C172.8-24.5,109.6-8.1,109.6-7.6Zm12.2,57.1-1.2.2c.2,0,.4-.1.6-.1s.4-.1.6-.1Z"
              fill="url(#linear-gradient-44)"
            />
          </g>
          <g id="Group_572" transform="translate(336.025 952.314)">
            <path
              id="Path_3682-3"
              d="M66.6,64.1l-.9.3c-.1,0-.1.1-.2.1Z"
              fill="#1d262d"
            />
            <path
              id="Path_3683-3"
              d="M62.7,65.5c-1.2.5,2-.3,9.5-2.4-2.3.6-4.7,1.2-7,1.8C64.4,65.1,63.6,65.3,62.7,65.5Z"
              fill="#1d262d"
            />
            <path
              id="Path_3684-3"
              d="M91.4,45.9A58.95,58.95,0,0,1,72.5,32.4C58.1,17.2,51.5-3.8,51.3-24.5c0,.8-63.2,16.4-63.2,16.9C-11.7,22,3.2,55.1,33.2,64.9a49.149,49.149,0,0,0,20.5,2.2,70.8,70.8,0,0,0,9-1.6,23.764,23.764,0,0,1,3-1.1A59.51,59.51,0,0,0,91.4,45.9Z"
              fill="url(#linear-gradient-45)"
            />
            <path
              id="Path_3685-3"
              d="M72.3,63.1c15-3.8,30-7.8,45-12.1-5.2,1.3-13.1,3.3-23.6,5.9C84.5,59.7,77.4,61.7,72.3,63.1Z"
              fill="#1d262d"
            />
            <path
              id="Path_3686-3"
              d="M109.6-7.6c-.2,18.9-6.2,38.8-18.2,53.4A60.02,60.02,0,0,1,65.8,64.4l.9-.3-1.1.4c.1,0,.1-.1.2-.1a23.764,23.764,0,0,0-3,1.1c.8-.2,1.7-.4,2.5-.6,2.3-.6,4.7-1.2,7-1.8,5.1-1.4,12.2-3.5,21.4-6.1,10.5-2.6,18.4-4.6,23.6-5.9,1.3-.4,2.5-.7,3.8-1.1a29.924,29.924,0,0,0,3.2-1c13-4.3,23.9-11.3,32.3-22.5,11-14.6,16-32.8,16.2-51C172.8-24.5,109.6-8.1,109.6-7.6Zm12.2,57.1-1.2.2c.2,0,.4-.1.6-.1s.4-.1.6-.1Z"
              fill="url(#linear-gradient-46)"
            />
          </g>
          <g id="Group_549" transform="translate(928.598 1066.236)">
            <path
              id="Path_3687"
              d="M65.7,39.4c.2.1.4.1.6.2.1,0,.1,0,.2.1Z"
              fill="#1d262d"
            />
            <path
              id="Path_3688"
              d="M68.5,40.4c.9.4-1.4-.2-6.9-1.6L66.7,40A11.626,11.626,0,0,1,68.5,40.4Z"
              fill="#1d262d"
            />
            <path
              id="Path_3689"
              d="M47.6,26.7A42.39,42.39,0,0,0,61,16.7C71.1,5.5,75.6-9.6,75.4-24.5c0,.6,45.7,10.8,45.7,11.2.2,21.3-10,45.3-31.4,52.8A36.6,36.6,0,0,1,75,41.4a46.306,46.306,0,0,1-6.5-1,19.686,19.686,0,0,0-2.2-.7A44.345,44.345,0,0,1,47.6,26.7Z"
              fill="url(#linear-gradient-47)"
            />
            <path
              id="Path_3690"
              d="M61.6,38.8c-10.9-2.5-21.7-5.2-32.5-8,3.8.9,9.5,2.2,17,3.9C52.8,36.5,58,37.8,61.6,38.8Z"
              fill="#1d262d"
            />
            <path
              id="Path_3691"
              d="M-11.9-22.6c.4,13,4.3,26,12.4,36.4,6.3,8,14.2,12.8,23.6,15.7.8.2,1.5.5,2.3.7.9.2,1.8.5,2.7.7,3.8.9,9.5,2.2,17,3.9,6.6,1.8,11.8,3.1,15.5,4.1l5.1,1.2c.6.1,1.2.3,1.8.4a19.686,19.686,0,0,0-2.2-.7c.1,0,.1,0,.2.1l-.8-.3c.2.1.4.1.6.2a42.326,42.326,0,0,1-18.7-13C38.8,16.3,34.2,2.2,33.8-11.4,33.7-11.8-11.9-22.6-11.9-22.6ZM26.3,29.8l.4.1-.8-.1Z"
              fill="url(#linear-gradient-48)"
            />
          </g>
          <g id="Group_573" transform="translate(84.354 655.402)">
            <path
              id="Path_3687-2"
              d="M65.7,39.4c.2.1.4.1.6.2.1,0,.1,0,.2.1Z"
              fill="#1d262d"
            />
            <path
              id="Path_3688-2"
              d="M68.5,40.4c.9.4-1.4-.2-6.9-1.6L66.7,40A11.626,11.626,0,0,1,68.5,40.4Z"
              fill="#1d262d"
            />
            <path
              id="Path_3689-2"
              d="M47.6,26.7A42.39,42.39,0,0,0,61,16.7C71.1,5.5,75.6-9.6,75.4-24.5c0,.6,45.7,10.8,45.7,11.2.2,21.3-10,45.3-31.4,52.8A36.6,36.6,0,0,1,75,41.4a46.306,46.306,0,0,1-6.5-1,19.686,19.686,0,0,0-2.2-.7A44.345,44.345,0,0,1,47.6,26.7Z"
              fill="url(#linear-gradient-49)"
            />
            <path
              id="Path_3690-2"
              d="M61.6,38.8c-10.9-2.5-21.7-5.2-32.5-8,3.8.9,9.5,2.2,17,3.9C52.8,36.5,58,37.8,61.6,38.8Z"
              fill="#1d262d"
            />
            <path
              id="Path_3691-2"
              d="M-11.9-22.6c.4,13,4.3,26,12.4,36.4,6.3,8,14.2,12.8,23.6,15.7.8.2,1.5.5,2.3.7.9.2,1.8.5,2.7.7,3.8.9,9.5,2.2,17,3.9,6.6,1.8,11.8,3.1,15.5,4.1l5.1,1.2c.6.1,1.2.3,1.8.4a19.686,19.686,0,0,0-2.2-.7c.1,0,.1,0,.2.1l-.8-.3c.2.1.4.1.6.2a42.326,42.326,0,0,1-18.7-13C38.8,16.3,34.2,2.2,33.8-11.4,33.7-11.8-11.9-22.6-11.9-22.6ZM26.3,29.8l.4.1-.8-.1Z"
              fill="url(#linear-gradient-50)"
            />
          </g>
          <g id="Group_550" transform="translate(686.092 967.191)">
            <path id="Path_3692" d="M65-21.4h0Z" fill="#1d262d" />
            <path
              id="Path_3693"
              d="M67.4-21.4c.8-.1-1.2-.2-5.8-.6,1.4.1,2.8.3,4.3.4C66.4-21.5,66.9-21.5,67.4-21.4Z"
              fill="#1d262d"
            />
            <path
              id="Path_3694"
              d="M47.5-16.2A35.978,35.978,0,0,1,55.4-4.9c5,11.4,4.5,24.4.5,35.9.2-.5,38.5,3.5,38.6,3.2,5.8-16.6,4.1-38-10.7-49.4a30.335,30.335,0,0,0-11-5.3,48.892,48.892,0,0,0-5.3-.9H65.6A35.614,35.614,0,0,0,47.5-16.2Z"
              fill="url(#linear-gradient-51)"
            />
            <path
              id="Path_3695"
              d="M61.6-22c-9.2-.9-18.3-1.6-27.5-2.2,3.2.3,8,.8,14.3,1.4C54.1-22.5,58.5-22.2,61.6-22Z"
              fill="#1d262d"
            />
            <path
              id="Path_3696"
              d="M26.7,10C30.6-.5,37.9-10.4,47.5-16.2a35.539,35.539,0,0,1,18-5.2h1.9c-.5-.1-1-.1-1.5-.2-1.4-.1-2.8-.3-4.3-.4-3.1-.2-7.5-.5-13.2-.8-6.4-.6-11.2-1.1-14.3-1.4-.8-.1-1.6-.1-2.3-.2-.7,0-1.3-.1-2-.1A37.174,37.174,0,0,0,7.3-18.4c-9,6-15.5,15.1-19.2,25.2C-11.9,6.8,26.6,10.3,26.7,10Zm4.6-34.3.7.1h-.3c-.2,0-.3-.1-.4-.1Z"
              fill="url(#linear-gradient-52)"
            />
          </g>
          <g id="Group_571" transform="translate(324.906 462.364)">
            <path id="Path_3692-2" d="M65-21.4h0Z" fill="#1d262d" />
            <path
              id="Path_3693-2"
              d="M67.4-21.4c.8-.1-1.2-.2-5.8-.6,1.4.1,2.8.3,4.3.4C66.4-21.5,66.9-21.5,67.4-21.4Z"
              fill="#1d262d"
            />
            <path
              id="Path_3693-2"
              d="M47.5-16.2A35.978,35.978,0,0,1,55.4-4.9c5,11.4,4.5,24.4.5,35.9.2-.5,38.5,3.5,38.6,3.2,5.8-16.6,4.1-38-10.7-49.4a30.335,30.335,0,0,0-11-5.3,48.892,48.892,0,0,0-5.3-.9H65.6A35.614,35.614,0,0,0,47.5-16.2Z"
              fill="url(#linear-gradient-53)"
            />
            <path
              id="Path_3695-2"
              d="M61.6-22c-9.2-.9-18.3-1.6-27.5-2.2,3.2.3,8,.8,14.3,1.4C54.1-22.5,58.5-22.2,61.6-22Z"
              fill="#1d262d"
            />
            <path
              id="Path_3696-2"
              d="M26.7,10C30.6-.5,37.9-10.4,47.5-16.2a35.539,35.539,0,0,1,18-5.2h1.9c-.5-.1-1-.1-1.5-.2-1.4-.1-2.8-.3-4.3-.4-3.1-.2-7.5-.5-13.2-.8-6.4-.6-11.2-1.1-14.3-1.4-.8-.1-1.6-.1-2.3-.2-.7,0-1.3-.1-2-.1A37.174,37.174,0,0,0,7.3-18.4c-9,6-15.5,15.1-19.2,25.2C-11.9,6.8,26.6,10.3,26.7,10Zm4.6-34.3.7.1h-.3c-.2,0-.3-.1-.4-.1Z"
              fill="url(#linear-gradient-54)"
            />
          </g>
          <g id="Group_578" transform="translate(2187.542 540.527)">
            <path id="Path_3692-3" d="M65-21.4h0Z" fill="#1d262d" />
            <path
              id="Path_3693-3"
              d="M67.4-21.4c.8-.1-1.2-.2-5.8-.6,1.4.1,2.8.3,4.3.4C66.4-21.5,66.9-21.5,67.4-21.4Z"
              fill="#1d262d"
            />
            <path
              id="Path_3694-3"
              d="M47.5-16.2A35.978,35.978,0,0,1,55.4-4.9c5,11.4,4.5,24.4.5,35.9.2-.5,38.5,3.5,38.6,3.2,5.8-16.6,4.1-38-10.7-49.4a30.335,30.335,0,0,0-11-5.3,48.892,48.892,0,0,0-5.3-.9H65.6A35.614,35.614,0,0,0,47.5-16.2Z"
              fill="url(#linear-gradient-55)"
            />
            <path
              id="Path_3695-3"
              d="M61.6-22c-9.2-.9-18.3-1.6-27.5-2.2,3.2.3,8,.8,14.3,1.4C54.1-22.5,58.5-22.2,61.6-22Z"
              fill="#1d262d"
            />
            <path
              id="Path_3696-3"
              d="M26.7,10C30.6-.5,37.9-10.4,47.5-16.2a35.539,35.539,0,0,1,18-5.2h1.9c-.5-.1-1-.1-1.5-.2-1.4-.1-2.8-.3-4.3-.4-3.1-.2-7.5-.5-13.2-.8-6.4-.6-11.2-1.1-14.3-1.4-.8-.1-1.6-.1-2.3-.2-.7,0-1.3-.1-2-.1A37.174,37.174,0,0,0,7.3-18.4c-9,6-15.5,15.1-19.2,25.2C-11.9,6.8,26.6,10.3,26.7,10Zm4.6-34.3.7.1h-.3c-.2,0-.3-.1-.4-.1Z"
              fill="url(#linear-gradient-56)"
            />
          </g>
          <g id="Group_551" transform="translate(1762.595 734.469)">
            <g id="Rectangle_149">
              <rect
                id="image"
                width="342.47"
                height="372.744"
                transform="translate(-11.927 -24.498)"
                fill="none"
              />
            </g>
          </g>
          <g id="Group_593" transform="translate(1251.213 658.321)">
            <g id="Rectangle_149-2">
              <rect
                id="image_2_"
                width="342.47"
                height="372.744"
                transform="translate(-11.927 -24.498)"
                fill="none"
              />
            </g>
          </g>
          <g id="Group_579" transform="translate(2160.144 587.457)">
            <g id="Rectangle_149-3">
              <rect
                id="image_3_"
                width="342.47"
                height="372.744"
                transform="translate(-11.927 -24.498)"
                fill="none"
              />
            </g>
          </g>
          <g id="Group_576" transform="translate(21.229 950.59)">
            <g id="Rectangle_149-4">
              <rect
                id="image_4_"
                width="342.47"
                height="372.744"
                transform="translate(-11.927 -24.498)"
                fill="none"
              />
            </g>
          </g>
          <path
            id="Path_3697"
            d="M1698.9,1159.6c6.5-4.6,21.1-15.5,27.6-20.1-47.1-35.8-79.8-93.5-88.1-161-21,17.9-36.8,31.8-57.8,49.7C1603.4,1081,1637,1135.8,1698.9,1159.6Z"
            fill="url(#linear-gradient-57)"
          />
          <path
            id="Path_3698"
            d="M1809.2,602.4l-42.4-49.7a258.938,258.938,0,0,0,150.4-140l46.5,45.8A265.808,265.808,0,0,1,1809.2,602.4Z"
            fill="url(#linear-gradient-58)"
          />
          <path
            id="Path_3699"
            d="M1094.5,268.9l-44.6-24.8a216.564,216.564,0,0,0,74.3-148.8l46.6,20.7A222.534,222.534,0,0,1,1094.5,268.9Z"
            fill="url(#linear-gradient-59)"
          />
          <path
            id="Path_3700"
            d="M1321.1,1292.8l-70.3-39.1A341.089,341.089,0,0,0,1368,1019l73.5,32.7A350.727,350.727,0,0,1,1321.1,1292.8Z"
            fill="url(#linear-gradient-60)"
          />
          <path
            id="Path_3701"
            d="M562.2,1016q35.1-30,70.3-60a366.418,366.418,0,0,0,198.2,213l-64.9,65.9A377.145,377.145,0,0,1,562.2,1016Z"
            fill="url(#linear-gradient-61)"
          />
          <path
            id="Path_3904"
            d="M2192,309.3q35.1-30,70.3-60a366.419,366.419,0,0,0,198.2,213l-64.9,65.9A376.529,376.529,0,0,1,2192,309.3Z"
            fill="url(#linear-gradient-62)"
          />
          <path
            id="Path_3903"
            d="M12.1,137q35.1-30,70.3-60A366.419,366.419,0,0,0,280.6,290l-64.9,65.9A376.266,376.266,0,0,1,12.1,137Z"
            fill="url(#linear-gradient-63)"
          />
          <path
            id="Path_3702"
            d="M2023.6,120.9c12.1,21.3,24.1,42.7,36.2,64a291.317,291.317,0,0,0-196.4,122.4c-13.8-20.2-27.7-40.4-41.5-60.7A299.562,299.562,0,0,1,2023.6,120.9Z"
            fill="url(#linear-gradient-64)"
          />
          <path
            id="Path_3703"
            d="M1261.3,292.1l-49.7-2.2a197.325,197.325,0,0,0,0-156.6l49.7-2.2A202.928,202.928,0,0,1,1261.3,292.1Z"
            fill="url(#linear-gradient-65)"
          />
          <path
            id="Path_3704"
            d="M617.1,46.6Q654.6,23.05,692.2-.5A351.181,351.181,0,0,0,850.4,229.2l-70.8,53.4A360.546,360.546,0,0,1,617.1,46.6Z"
            fill="url(#linear-gradient-66)"
          />
          <path
            id="Path_3895"
            d="M172.1,688.7q37.5-23.55,75.1-47.1A351.181,351.181,0,0,0,405.4,871.3l-70.8,53.4A360.309,360.309,0,0,1,172.1,688.7Z"
            fill="url(#linear-gradient-67)"
          />
          <path
            id="Path_3909"
            d="M1968.3,175.4q37.5-23.55,75.1-47.1A351.18,351.18,0,0,0,2201.6,358l-70.8,53.4A360.308,360.308,0,0,1,1968.3,175.4Z"
            fill="url(#linear-gradient-68)"
          />
          <path
            id="Path_3905"
            d="M2192,1020.8q37.5-23.55,75.1-47.1a351.181,351.181,0,0,0,158.2,229.7l-70.8,53.4A360.309,360.309,0,0,1,2192,1020.8Z"
            fill="url(#linear-gradient-69)"
          />
          <path
            id="Path_3705"
            d="M1706.7,308.4c39.9-7,79.7,6.6,105.8,35.5l-12.6,14.6c-24.8-15.1-54.8-16.3-79.2-3.8Z"
            fill="url(#linear-gradient-70)"
          />
          <path
            id="Path_3906"
            d="M2255.7,164.4c39.9-7,79.7,6.6,105.8,35.5l-12.6,14.6c-24.8-15.1-54.8-16.3-79.2-3.8Z"
            fill="url(#linear-gradient-71)"
          />
          <path
            id="Path_3706"
            d="M764.5,569.3c-4-36.6,18.8-63.1,55.1-64.5,10.5,6.5,21,12.9,31.4,19.4-41.8,1.4-68,31.2-63.8,72.4Z"
            fill="url(#linear-gradient-72)"
          />
          <path
            id="Path_3894"
            d="M293.9,336.7c-4-36.6,18.8-63.1,55.1-64.5,10.5,6.5,21,12.9,31.4,19.4-41.8,1.4-68,31.2-63.8,72.4Z"
            fill="url(#linear-gradient-73)"
          />
          <path
            id="Path_3707"
            d="M1895.3,578.2c27.4-13.9,54.7-4,66.6,23.9-1.9,10.1-3.9,20.2-5.8,30.2-13.4-32.2-44.5-43.8-75.3-28.4C1885.5,595.4,1890.4,586.8,1895.3,578.2Z"
            fill="url(#linear-gradient-74)"
          />
          <path
            id="Path_3708"
            d="M991.3,281.2c-40.7,12.7-75.9-7.2-85.7-48.2,5-13.4,10-26.7,15-40.1,10.9,47.3,50.7,70.4,96.7,56.4C1008.7,259.9,1000,270.5,991.3,281.2Z"
            fill="url(#linear-gradient-75)"
          />
          <path
            id="Path_3709"
            d="M532.8,1111.4c-36.4,22.3-75.4,11.6-94.9-25.8,1.6-14.2,3.2-28.4,4.7-42.6,22.2,43.1,66.4,55.8,107.5,31Z"
            fill="url(#linear-gradient-76)"
          />
          <g id="Group_552" transform="translate(1633.106 510.347)">
            <path id="Path_3710" d="M25.4,23.8l-.2.3v.1Z" fill="#1d262d" />
            <path
              id="Path_3711"
              d="M24.6,25.1c-.2.4.5-.6,2.1-3.1-.5.8-1,1.5-1.5,2.3A5.547,5.547,0,0,1,24.6,25.1Z"
              fill="#1d262d"
            />
            <path
              id="Path_3712"
              d="M28.2,13.1a21.048,21.048,0,0,1-8.2,2A23.521,23.521,0,0,1,1.8,8C2,8.1-12,28.5-11.9,28.7a23.464,23.464,0,0,0,28.2,4.2,22.67,22.67,0,0,0,6.1-5,18.6,18.6,0,0,0,2.1-2.8c.1-.2.3-.5.6-1A22.835,22.835,0,0,0,28.2,13.1Z"
              fill="url(#linear-gradient-77)"
            />
            <path
              id="Path_3713"
              d="M26.7,22.1c3.3-4.9,6.6-9.8,9.8-14.7-1.1,1.7-2.9,4.2-5.2,7.6C29.3,18,27.8,20.4,26.7,22.1Z"
              fill="#1d262d"
            />
            <path
              id="Path_3714"
              d="M21.6-3.8a23.974,23.974,0,0,1,6.6,16.9,23.228,23.228,0,0,1-3,11l.2-.3-.2.4v-.1c-.3.5-.5.9-.6,1,.2-.3.4-.5.6-.8.5-.8,1-1.5,1.5-2.3,1.1-1.7,2.6-4.1,4.6-7.1,2.3-3.4,4.1-5.9,5.2-7.6A14.8,14.8,0,0,0,37.3,6c.2-.4.5-.7.7-1.1A25.472,25.472,0,0,0,42-8.8a23.975,23.975,0,0,0-6.6-15.8C35.3-24.5,21.4-3.9,21.6-3.8ZM37.4,5.8l-.3.4.1-.2Z"
              fill="url(#linear-gradient-78)"
            />
          </g>
          <g id="Group_574" transform="translate(183.986 236.675)">
            <path id="Path_3710-2" d="M25.4,23.8l-.2.3v.1Z" fill="#1d262d" />
            <path
              id="Path_3711-2"
              d="M24.6,25.1c-.2.4.5-.6,2.1-3.1-.5.8-1,1.5-1.5,2.3A5.547,5.547,0,0,1,24.6,25.1Z"
              fill="#1d262d"
            />
            <path
              id="Path_3712-2"
              d="M28.2,13.1a21.048,21.048,0,0,1-8.2,2A23.521,23.521,0,0,1,1.8,8C2,8.1-12,28.5-11.9,28.7a23.464,23.464,0,0,0,28.2,4.2,22.67,22.67,0,0,0,6.1-5,18.6,18.6,0,0,0,2.1-2.8c.1-.2.3-.5.6-1A22.835,22.835,0,0,0,28.2,13.1Z"
              fill="url(#linear-gradient-79)"
            />
            <path
              id="Path_3713-2"
              d="M26.7,22.1c3.3-4.9,6.6-9.8,9.8-14.7-1.1,1.7-2.9,4.2-5.2,7.6C29.3,18,27.8,20.4,26.7,22.1Z"
              fill="#1d262d"
            />
            <path
              id="Path_3714-2"
              d="M21.6-3.8a23.974,23.974,0,0,1,6.6,16.9,23.228,23.228,0,0,1-3,11l.2-.3-.2.4v-.1c-.3.5-.5.9-.6,1,.2-.3.4-.5.6-.8.5-.8,1-1.5,1.5-2.3,1.1-1.7,2.6-4.1,4.6-7.1,2.3-3.4,4.1-5.9,5.2-7.6A14.8,14.8,0,0,0,37.3,6c.2-.4.5-.7.7-1.1A25.472,25.472,0,0,0,42-8.8a23.975,23.975,0,0,0-6.6-15.8C35.3-24.5,21.4-3.9,21.6-3.8ZM37.4,5.8l-.3.4.1-.2Z"
              fill="url(#linear-gradient-80)"
            />
          </g>
          <path
            id="Path_3715"
            d="M1665.6,415c.7,1,2.4,3.2,3.1,4.2,5.5-7.2,14.3-12.2,24.6-13.5-2.7-3.2-4.9-5.6-7.6-8.8C1677.6,400.4,1669.2,405.5,1665.6,415Z"
            fill="url(#linear-gradient-81)"
          />
          <path
            id="Path_3716"
            d="M969,505.4c.7,1,2.4,3.2,3.1,4.2,5.5-7.2,14.3-12.2,24.6-13.5-2.7-3.2-4.9-5.6-7.6-8.8C981,490.8,972.6,495.9,969,505.4Z"
            fill="url(#linear-gradient-82)"
          />
          <path
            id="Path_3717"
            d="M1714.5,119.3c.6,5.7-2.9,9.9-8.7,10.1l-4.9-3c6.6-.2,10.7-4.9,10-11.4Z"
            fill="url(#linear-gradient-83)"
          />
          <path
            id="Path_3718"
            d="M1083.9,463.9c.6,5.7-2.9,9.9-8.7,10.1-1.6-1-3.3-2-4.9-3,6.6-.2,10.7-4.9,10-11.4C1081.5,461.1,1082.7,462.5,1083.9,463.9Z"
            fill="url(#linear-gradient-84)"
          />
          <path
            id="Path_3719"
            d="M1707.6,854.8c.6,5.7-2.9,9.9-8.7,10.1-1.6-1-3.3-2-4.9-3,6.6-.2,10.7-4.9,10-11.4Z"
            fill="url(#linear-gradient-85)"
          />
          <path
            id="Path_3720"
            d="M1821.2,469.2c.6,5.7-2.9,9.9-8.7,10.1l-4.9-3c6.6-.2,10.7-4.9,10-11.4Z"
            fill="url(#linear-gradient-86)"
          />
          <path
            id="Path_3721"
            d="M1012.7,367.1a6.02,6.02,0,0,1-4.9,7,4.868,4.868,0,0,1-1.2.1c-1.2-.7-2.3-1.4-3.5-2.1,4.6-.2,7.5-3.4,7-8Z"
            fill="url(#linear-gradient-87)"
          />
          <path
            id="Path_3722"
            d="M1919.8,325.8a6.021,6.021,0,0,1-6.2-5.8,4.87,4.87,0,0,1,.1-1.2c.9-1,1.7-2.1,2.6-3.1-.5,4.6,2.3,7.9,6.9,8.1C1922,324.5,1920.9,325.2,1919.8,325.8Z"
            fill="url(#linear-gradient-88)"
          />
          <path
            id="Path_3723"
            d="M736,728.4a6.021,6.021,0,0,1-6.2-5.8,4.87,4.87,0,0,1,.1-1.2c.9-1,1.7-2.1,2.6-3.1-.5,4.6,2.3,7.9,6.9,8.1C738.2,727.1,737.1,727.8,736,728.4Z"
            fill="url(#linear-gradient-89)"
          />
          <path
            id="Path_3724"
            d="M550.7,407.1c-5.6,1.2-10.2-1.9-11-7.5l2.5-5.2c.9,6.5,6,10.1,12.4,8.8Q552.65,405,550.7,407.1Z"
            fill="url(#linear-gradient-90)"
          />
          <g id="Group_553" transform="translate(1616.841 337.981)">
            <path id="Path_3725" d="M4.4-.5l.1.2h0Z" fill="#1d262d" />
            <path
              id="Path_3726"
              d="M4.6.3a7.789,7.789,0,0,0-.4-2.1c.1.5.2,1.1.3,1.6Z"
              fill="#1d262d"
            />
            <path
              id="Path_3727"
              d="M1-6.1A14.254,14.254,0,0,1-1.5-1.8,14.042,14.042,0,0,1-12,3c.2,0,3,14.3,3.1,14.3A13.6,13.6,0,0,0,4.9,3.7V2.5A7.8,7.8,0,0,0,4.6.3c0-.1-.1-.3-.2-.7A13.377,13.377,0,0,0,1-6.1Z"
              fill="url(#linear-gradient-91)"
            />
            <path
              id="Path_3728"
              d="M4.2-1.8C3.5-5.2,2.8-8.6,2-12c.2,1.2.6,3,1.1,5.3C3.6-4.6,3.9-3,4.2-1.8Z"
              fill="#1d262d"
            />
            <path
              id="Path_3729"
              d="M-8.8-10.2A13.854,13.854,0,0,1,1-6.1,13.139,13.139,0,0,1,4.4-.3V-.5l.1.2h0c.1.3.1.5.2.6L4.5-.2c-.1-.6-.2-1.1-.3-1.6C3.9-3,3.6-4.6,3.1-6.6,2.6-9,2.2-10.8,2-12c-.1-.3-.1-.6-.2-.9-.1-.2-.1-.5-.2-.7a14.794,14.794,0,0,0-4.1-7.3,14.236,14.236,0,0,0-9.4-3.7C-11.9-24.5-8.9-10.2-8.8-10.2ZM1.7-13v0Z"
              fill="url(#linear-gradient-92)"
            />
          </g>
          <path
            id="Path_3730"
            d="M1081.4,915.2c-.6,1-2,3.4-2.7,4.5,8.8,2.2,16.8,8.4,22,17.4,1.9-3.8,3.3-6.7,5.1-10.4C1099.6,920.5,1091.6,914.8,1081.4,915.2Z"
            fill="url(#linear-gradient-93)"
          />
          <path
            id="Path_3731"
            d="M1294.3,1071.5c-5,2.8-10.3,1.2-12.7-4l.9-5.7c2.8,6,8.7,7.9,14.4,4.8Z"
            fill="url(#linear-gradient-94)"
          />
          <g id="Group_554" transform="translate(1034.434 1006.97)">
            <path id="Path_3732" d="M6-4.2,5.8-4h0Z" fill="#1d262d" />
            <path
              id="Path_3733"
              d="M5.4-3.7c-.2.2.3-.2,1.5-1l-1.1.8Z"
              fill="#1d262d"
            />
            <path
              id="Path_3734"
              d="M9.2-8.6a11.28,11.28,0,0,1-4.1-.5A11.438,11.438,0,0,1-2-15.6c.1.1-10,7-9.9,7.1a11.393,11.393,0,0,0,15,6.1c.3-.1.6-.3.9-.4l1.5-.9A2.736,2.736,0,0,1,6-4.1,12.3,12.3,0,0,0,9.2-8.6Z"
              fill="url(#linear-gradient-95)"
            />
            <path
              id="Path_3735"
              d="M6.9-4.7C9.3-6.3,11.6-8,14-9.7c-.8.6-2.1,1.4-3.7,2.6C8.8-6.1,7.7-5.3,6.9-4.7Z"
              fill="#1d262d"
            />
            <path
              id="Path_3736"
              d="M9.1-17.4a11.907,11.907,0,0,1,.1,8.9A12.216,12.216,0,0,1,5.8-4L6-4.2,5.8-4h0c-.2.1-.3.3-.4.3L5.8-4l1.1-.8q1.2-.9,3.3-2.4c1.7-1.1,2.9-2,3.7-2.6l.6-.4c.2-.1.3-.3.5-.4a11.768,11.768,0,0,0,4.2-5.6,11.123,11.123,0,0,0-.3-8.4C19-24.5,9.1-17.5,9.1-17.4Zm5.5,7.1-.2.1.2-.1Z"
              fill="url(#linear-gradient-96)"
            />
          </g>
          <g id="Group_582" transform="translate(2281.076 711.434)">
            <path id="Path_3732-2" d="M6-4.2,5.8-4h0Z" fill="#1d262d" />
            <path
              id="Path_3733-2"
              d="M5.4-3.7c-.2.2.3-.2,1.5-1l-1.1.8Z"
              fill="#1d262d"
            />
            <path
              id="Path_3734-2"
              d="M9.2-8.6a11.28,11.28,0,0,1-4.1-.5A11.438,11.438,0,0,1-2-15.6c.1.1-10,7-9.9,7.1a11.393,11.393,0,0,0,15,6.1c.3-.1.6-.3.9-.4l1.5-.9A2.736,2.736,0,0,1,6-4.1,12.3,12.3,0,0,0,9.2-8.6Z"
              fill="url(#linear-gradient-97)"
            />
            <path
              id="Path_3735-2"
              d="M6.9-4.7C9.3-6.3,11.6-8,14-9.7c-.8.6-2.1,1.4-3.7,2.6C8.8-6.1,7.7-5.3,6.9-4.7Z"
              fill="#1d262d"
            />
            <path
              id="Path_3736-2"
              d="M9.1-17.4a11.907,11.907,0,0,1,.1,8.9A12.216,12.216,0,0,1,5.8-4L6-4.2,5.8-4h0c-.2.1-.3.3-.4.3L5.8-4l1.1-.8q1.2-.9,3.3-2.4c1.7-1.1,2.9-2,3.7-2.6l.6-.4c.2-.1.3-.3.5-.4a11.768,11.768,0,0,0,4.2-5.6,11.123,11.123,0,0,0-.3-8.4C19-24.5,9.1-17.5,9.1-17.4Zm5.5,7.1-.2.1.2-.1Z"
              fill="url(#linear-gradient-98)"
            />
          </g>
          <g id="Group_555" transform="translate(1682.078 310.909)">
            <path id="Path_3737" d="M6-4.2,5.8-4h0Z" fill="#1d262d" />
            <path
              id="Path_3738"
              d="M5.4-3.7c-.2.2.3-.2,1.5-1a7.632,7.632,0,0,0-1.1.8Z"
              fill="#1d262d"
            />
            <path
              id="Path_3739"
              d="M9.2-8.6a11.28,11.28,0,0,1-4.1-.5A11.438,11.438,0,0,1-2-15.6c.1.1-10,7-9.9,7.1A11.315,11.315,0,0,0,3-2.4c.3-.1.6-.3.9-.4l1.5-.9c.1,0,.2-.2.4-.3A12.842,12.842,0,0,0,9.2-8.6Z"
              fill="url(#linear-gradient-99)"
            />
            <path
              id="Path_3740"
              d="M6.9-4.7C9.3-6.3,11.6-8,14-9.7c-.8.6-2.1,1.4-3.7,2.6C8.8-6.1,7.7-5.3,6.9-4.7Z"
              fill="#1d262d"
            />
            <path
              id="Path_3741"
              d="M9.1-17.4a11.907,11.907,0,0,1,.1,8.9A13.408,13.408,0,0,1,5.8-4L6-4.2,5.8-4h0c-.2.1-.3.3-.4.3L5.8-4c.4-.3.7-.5,1.1-.8q1.2-.9,3.3-2.4c1.7-1.1,2.9-2,3.7-2.6l.6-.4a1.756,1.756,0,0,0,.5-.4,11.768,11.768,0,0,0,4.2-5.6,11.123,11.123,0,0,0-.3-8.4C19-24.5,9.1-17.5,9.1-17.4Zm5.5,7.1-.2.1.2-.1Z"
              fill="url(#linear-gradient-100)"
            />
          </g>
          <path
            id="Path_3742"
            d="M566.8,618.4c24,19.3,26.2,48.3,5.6,70.7l-30,7.1c23.9-25.5,21.7-58.7-5.1-80.5C547.1,616.6,556.9,617.5,566.8,618.4Z"
            fill="url(#linear-gradient-101)"
          />
          <path
            id="Path_3743"
            d="M1327.4,337.3c24.1-10.3,40.9-31.7,44.8-56.5-4-1.2-8-2.3-11.9-3.5-8.2,16.9-24,28.1-41.6,30Z"
            fill="url(#linear-gradient-102)"
          />
          <path
            id="Path_3910"
            d="M2089.3,471.3c24.1-10.3,40.9-31.7,44.8-56.5-4-1.2-8-2.3-11.9-3.5-8.2,16.9-24,28.1-41.6,30Q2084.95,456.45,2089.3,471.3Z"
            fill="url(#linear-gradient-103)"
          />
          <g id="Group_556" transform="translate(1092.374 297.03)">
            <path id="Path_3744" d="M.2,12.1l-.3-.2H-.2Z" fill="#1d262d" />
            <path
              id="Path_3745"
              d="M-1.1,11.3c-.4-.2.6.5,3.1,2-.8-.5-1.5-1-2.3-1.5A3.508,3.508,0,0,1-1.1,11.3Z"
              fill="#1d262d"
            />
            <path
              id="Path_3746"
              d="M10.8,14.7a22.05,22.05,0,0,1-2.1-8,23.116,23.116,0,0,1,6.7-18C15.3-11.1-5-24.6-5.1-24.5-11.5-18-14.7-6.8-8.8,3.3a22.23,22.23,0,0,0,5,6c.9.7,1.8,1.4,2.8,2.1.2.1.5.3,1,.6A23.664,23.664,0,0,0,10.8,14.7Z"
              fill="url(#linear-gradient-104)"
            />
            <path
              id="Path_3747"
              d="M2,13.4c4.8,3.2,9.7,6.3,14.6,9.4-1.7-1.1-4.2-2.8-7.6-5C6,15.9,3.6,14.4,2,13.4Z"
              fill="#1d262d"
            />
            <path
              id="Path_3748"
              d="M27.3,7.9a24.092,24.092,0,0,1-16.5,6.8A23.253,23.253,0,0,1-.1,11.9l.3.2-.4-.2h.1c-.5-.3-.9-.5-1-.6a3.508,3.508,0,0,0,.8.5c.7.5,1.5,1,2.3,1.5,1.7,1.1,4,2.5,7,4.4,3.4,2.2,5.9,3.9,7.6,5,.4.3.8.5,1.2.8.4.2.7.4,1.1.6a25,25,0,0,0,13.5,3.7A23.473,23.473,0,0,0,47.8,21C47.8,21.1,27.4,7.8,27.3,7.9ZM18.1,23.6l-.4-.3.2.1Z"
              fill="url(#linear-gradient-105)"
            />
          </g>
          <g id="Group_583" transform="translate(2085.77 158.226)">
            <path id="Path_3744-2" d="M.2,12.1l-.3-.2H-.2Z" fill="#1d262d" />
            <path
              id="Path_3745-2"
              d="M-1.1,11.3c-.4-.2.6.5,3.1,2-.8-.5-1.5-1-2.3-1.5A3.508,3.508,0,0,1-1.1,11.3Z"
              fill="#1d262d"
            />
            <path
              id="Path_3746-2"
              d="M10.8,14.7a22.05,22.05,0,0,1-2.1-8,23.116,23.116,0,0,1,6.7-18C15.3-11.1-5-24.6-5.1-24.5-11.5-18-14.7-6.8-8.8,3.3a22.23,22.23,0,0,0,5,6c.9.7,1.8,1.4,2.8,2.1.2.1.5.3,1,.6A23.664,23.664,0,0,0,10.8,14.7Z"
              fill="url(#linear-gradient-106)"
            />
            <path
              id="Path_3747-2"
              d="M2,13.4c4.8,3.2,9.7,6.3,14.6,9.4-1.7-1.1-4.2-2.8-7.6-5C6,15.9,3.6,14.4,2,13.4Z"
              fill="#1d262d"
            />
            <path
              id="Path_3748-2"
              d="M27.3,7.9a24.092,24.092,0,0,1-16.5,6.8A23.253,23.253,0,0,1-.1,11.9l.3.2-.4-.2h.1c-.5-.3-.9-.5-1-.6a3.508,3.508,0,0,0,.8.5c.7.5,1.5,1,2.3,1.5,1.7,1.1,4,2.5,7,4.4,3.4,2.2,5.9,3.9,7.6,5,.4.3.8.5,1.2.8.4.2.7.4,1.1.6a25,25,0,0,0,13.5,3.7A23.473,23.473,0,0,0,47.8,21C47.8,21.1,27.4,7.8,27.3,7.9ZM18.1,23.6l-.4-.3.2.1Z"
              fill="url(#linear-gradient-107)"
            />
          </g>
          <g id="Group_557" transform="translate(586.346 299.985)">
            <path
              id="Path_3749"
              d="M10.8-7.3c.1-.2.2-.3.3-.5a.1.1,0,0,1,.1-.1A2.092,2.092,0,0,0,10.8-7.3Z"
              fill="#1d262d"
            />
            <path
              id="Path_3750"
              d="M11.9-9.5c.3-.7-.7.9-3,4.9.7-1.2,1.5-2.4,2.2-3.6A9.129,9.129,0,0,1,11.9-9.5Z"
              fill="#1d262d"
            />
            <path
              id="Path_3751"
              d="M7.8,10.4a34.147,34.147,0,0,1,13.4-2c12.2.6,23.5,6.7,32,15.3-.3-.4,19.9-32.6,19.7-32.8C60.8-21.4,41.1-29.3,24.5-21.2A29.779,29.779,0,0,0,15-13.8a42.478,42.478,0,0,0-3.1,4.3c-.1.3-.4.8-.8,1.7A35.451,35.451,0,0,0,7.8,10.4Z"
              fill="url(#linear-gradient-108)"
            />
            <path
              id="Path_3752"
              d="M8.9-4.6C4.1,3.1-.5,10.9-5.1,18.7-3.5,16-1,12,2.4,6.6,5.1,1.8,7.3-2,8.9-4.6Z"
              fill="#1d262d"
            />
            <path
              id="Path_3753"
              d="M21.9,40.2c-7.6-8-13.1-18.8-14.1-29.8A35.451,35.451,0,0,1,11.1-7.8c-.1.2-.2.3-.3.5l.3-.6a.1.1,0,0,1-.1.1c.4-.9.7-1.4.8-1.7-.3.4-.5.9-.8,1.3L8.9-4.6C7.3-1.9,5.1,1.8,2.4,6.6-1,12-3.5,16-5.1,18.7c-.4.7-.8,1.3-1.2,2-.3.6-.6,1.1-.9,1.7A37.1,37.1,0,0,0-11.6,45C-10.3,55.6-5,65.3,2.3,73,2.2,72.9,22.1,40.4,21.9,40.2Zm-28.3-19,.4-.6-.2.3C-6.2,21-6.3,21.1-6.4,21.2Z"
              fill="url(#linear-gradient-109)"
            />
          </g>
          <g id="Group_558" transform="translate(919.841 346.795)">
            <path id="Path_3754" d="M-7.7-11.9l-.1-.1h0Z" fill="#1d262d" />
            <path
              id="Path_3755"
              d="M-8.2-12.1c-.1-.1.2.1,1.1.7-.3-.2-.5-.3-.8-.5Z"
              fill="#1d262d"
            />
            <path
              id="Path_3756"
              d="M-4.1-11a6.812,6.812,0,0,1-.7-2.8,7.827,7.827,0,0,1,2.3-6.1c-.1.1-7.1-4.6-7.1-4.6a8,8,0,0,0-.1,11.2l.5.5a3.592,3.592,0,0,0,1,.7c.1,0,.2.1.4.2A7.786,7.786,0,0,0-4.1-11Z"
              fill="url(#linear-gradient-110)"
            />
            <path
              id="Path_3757"
              d="M-7.1-11.4c1.7,1.1,3.4,2.2,5,3.2-.6-.4-1.5-1-2.6-1.7C-5.7-10.6-6.6-11.1-7.1-11.4Z"
              fill="#1d262d"
            />
            <path
              id="Path_3758"
              d="M1.6-13.3A7.928,7.928,0,0,1-4.1-11a7.457,7.457,0,0,1-3.8-1l.1.1-.1-.1h0c-.1,0-.2-.1-.3-.1l.3.2c.3.2.5.3.8.5q.9.6,2.4,1.5c1.2.8,2,1.3,2.6,1.7a1.38,1.38,0,0,1,.4.3c.1.1.2.1.4.2A8.544,8.544,0,0,0,3.4-6.4,8.042,8.042,0,0,0,8.7-8.7C8.7-8.8,1.7-13.4,1.6-13.3ZM-1.5-7.9-1.7-8l.2.1Z"
              fill="url(#linear-gradient-111)"
            />
          </g>
          <g id="Group_559" transform="translate(688.2 325.775)">
            <path id="Path_3759" d="M-7.7-11.9l-.1-.1h0Z" fill="#1d262d" />
            <path
              id="Path_3760"
              d="M-8.2-12.1c-.1-.1.2.1,1.1.7l-.8-.5Z"
              fill="#1d262d"
            />
            <path
              id="Path_3761"
              d="M-4.1-11a6.812,6.812,0,0,1-.7-2.8,7.827,7.827,0,0,1,2.3-6.1c-.1.1-7.1-4.6-7.1-4.5a8,8,0,0,0-.1,11.2l.5.5a3.592,3.592,0,0,0,1,.7c.1,0,.2.1.4.2A9.093,9.093,0,0,0-4.1-11Z"
              fill="url(#linear-gradient-112)"
            />
            <path
              id="Path_3762"
              d="M-7.1-11.4c1.7,1.1,3.4,2.2,5,3.2-.6-.4-1.5-1-2.6-1.7C-5.7-10.6-6.6-11.1-7.1-11.4Z"
              fill="#1d262d"
            />
            <path
              id="Path_3763"
              d="M1.6-13.3A7.928,7.928,0,0,1-4.1-11a7.457,7.457,0,0,1-3.8-1l.1.1-.1-.1h0c-.1,0-.2-.1-.3-.1l.3.2.8.5q.9.6,2.4,1.5c1.2.8,2,1.3,2.6,1.7l.4.3c.1.1.2.1.4.2A8.544,8.544,0,0,0,3.4-6.4,8.042,8.042,0,0,0,8.7-8.7C8.7-8.8,1.7-13.4,1.6-13.3ZM-1.6-7.9-1.7-8l.1.1Z"
              fill="url(#linear-gradient-113)"
            />
          </g>
          <g id="Group_560" transform="translate(1495.117 934.238)">
            <path id="Path_3764" d="M-9-15.7Z" fill="#1d262d" />
            <path
              id="Path_3765"
              d="M-9.3-15.8c-.1,0,.1.1.7.5l-.5-.4Z"
              fill="#1d262d"
            />
            <path
              id="Path_3766"
              d="M-6.4-15a4.486,4.486,0,0,1-.5-1.9,6.015,6.015,0,0,1,1.6-4.4s-4.9-3.2-5-3.2a5.46,5.46,0,0,0-.9,6.7A5.263,5.263,0,0,0-10-16.4a4.233,4.233,0,0,0,.7.5.349.349,0,0,0,.2.1A5.029,5.029,0,0,0-6.4-15Z"
              fill="url(#linear-gradient-114)"
            />
            <path
              id="Path_3767"
              d="M-8.6-15.4c1.2.8,2.3,1.5,3.5,2.3q-.6-.45-1.8-1.2A17.089,17.089,0,0,1-8.6-15.4Z"
              fill="#1d262d"
            />
            <path
              id="Path_3768"
              d="M-2.5-16.7a5.946,5.946,0,0,1-4,1.6,4.845,4.845,0,0,1-2.6-.7H-9l-.1-.1h0a.31.31,0,0,0-.2-.1l.2.1.5.4c.4.3,1,.6,1.7,1.1q1.2.75,1.8,1.2c.1.1.2.1.3.2s.2.1.3.2a6.294,6.294,0,0,0,3.3.9,5.685,5.685,0,0,0,3.7-1.6S-2.4-16.7-2.5-16.7Zm-2.2,3.8Z"
              fill="url(#linear-gradient-115)"
            />
          </g>
          <path
            id="Path_3769"
            d="M765.8,637.1c-.9.6-2.9,2.1-3.8,2.8,6.5,4.9,11,12.9,12.1,22.1,2.9-2.5,5.1-4.4,7.9-6.8C778.9,648,774.3,640.4,765.8,637.1Z"
            fill="url(#linear-gradient-116)"
          />
          <path
            id="Path_3770"
            d="M1139.9,1116.7c-1.6-5.7-3.1-11.4-4.7-17a70.319,70.319,0,0,0,52.9-17.1c2.1,5.5,4.1,11,6.2,16.6A72.472,72.472,0,0,1,1139.9,1116.7Z"
            fill="url(#linear-gradient-117)"
          />
          <path
            id="Path_3771"
            d="M1283.4,1090.1c-.9.6-2.9,2.1-3.8,2.8,6.5,4.9,11,12.9,12.1,22.1,2.9-2.5,5.1-4.4,7.9-6.8C1296.5,1101,1291.9,1093.4,1283.4,1090.1Z"
            fill="url(#linear-gradient-118)"
          />
          <path
            id="Path_3772"
            d="M1528.1,684.5c3.1-2,6.2-4.1,9.3-6.1a43.8,43.8,0,0,0,20.5,28.4l-8.7,6.9A45.388,45.388,0,0,1,1528.1,684.5Z"
            fill="url(#linear-gradient-119)"
          />
          <path
            id="Path_3913"
            d="M2215.4,653.2c3.1-2,6.2-4.1,9.3-6.1a43.805,43.805,0,0,0,20.5,28.4l-8.7,6.9A45.635,45.635,0,0,1,2215.4,653.2Z"
            fill="url(#linear-gradient-120)"
          />
          <g id="Group_561" transform="translate(686.558 523.959)">
            <path id="Path_3773" d="M4.6-19l.1.1h0Z" fill="#1d262d" />
            <path
              id="Path_3773"
              d="M5.1-18.7A10.18,10.18,0,0,0,4-19.4c.3.2.5.3.8.5Z"
              fill="#1d262d"
            />
            <path
              id="Path_3775"
              d="M.9-19.9a6.812,6.812,0,0,1,.7,2.8A8.261,8.261,0,0,1-.7-10.8c.1-.1,7.1,4.6,7.1,4.6a8.027,8.027,0,0,0,.1-11.3A1.205,1.205,0,0,0,6-18a3.592,3.592,0,0,0-1-.7c-.1,0-.2-.1-.4-.2A6.414,6.414,0,0,0,.9-19.9Z"
              fill="url(#linear-gradient-121)"
            />
            <path
              id="Path_3776"
              d="M4-19.4l-5.1-3.3c.6.4,1.5,1,2.6,1.7S3.4-19.8,4-19.4Z"
              fill="#1d262d"
            />
            <path
              id="Path_3777"
              d="M-4.8-17.6A8.657,8.657,0,0,1,.9-20a7.457,7.457,0,0,1,3.8,1H4.6l.1.1h0c.2.1.3.2.4.2l-.3-.2c-.3-.2-.5-.3-.8-.5q-.9-.6-2.4-1.5C.4-21.7-.5-22.3-1-22.6l-.5-.4c-.1-.1-.2-.1-.4-.2a8.544,8.544,0,0,0-4.7-1.3A8.129,8.129,0,0,0-12-22.1C-11.9-22.1-4.8-17.5-4.8-17.6ZM-1.6-23l.1.1-.1-.1Z"
              fill="url(#linear-gradient-122)"
            />
          </g>
          <path
            id="Path_3778"
            d="M710.2,288.5c-.1.8-.4,2.5-.5,3.3a23.031,23.031,0,0,1,17.1,5c.2-2.7.3-4.7.6-7.4C722.1,287.3,716.1,285.9,710.2,288.5Z"
            fill="url(#linear-gradient-123)"
          />
          <path
            id="Path_3779"
            d="M1076.9,593.9a5.431,5.431,0,0,1-6.7-3.7,3.554,3.554,0,0,1-.2-1c.5-1.1,1-2.2,1.6-3.3a6.166,6.166,0,0,0,6.6,5.7,5.016,5.016,0,0,0,1.2-.2Z"
            fill="url(#linear-gradient-124)"
          />
          <path
            id="Path_3780"
            d="M1549.6,407a5.431,5.431,0,0,1-6.7-3.7,3.554,3.554,0,0,1-.2-1c.5-1.1,1-2.2,1.6-3.3a6.166,6.166,0,0,0,6.6,5.7,5.018,5.018,0,0,0,1.2-.2Z"
            fill="url(#linear-gradient-125)"
          />
          <path
            id="Path_3781"
            d="M1252,751.8c-.6.4-1.8,1.3-2.4,1.7a20.459,20.459,0,0,1,7.6,13.8c1.8-1.5,3.2-2.7,5-4.3C1260.2,758.6,1257.3,753.9,1252,751.8Z"
            fill="url(#linear-gradient-126)"
          />
          <path
            id="Path_3782"
            d="M718.7,1027.5c-.6.4-1.8,1.3-2.4,1.7a20.46,20.46,0,0,1,7.6,13.8c1.8-1.5,3.2-2.7,5-4.3C726.9,1034.2,724,1029.5,718.7,1027.5Z"
            fill="url(#linear-gradient-127)"
          />
          <path
            id="Path_3783"
            d="M1242.5,474c-.1.5-.2,1.6-.3,2a14.379,14.379,0,0,1,10.6,3.1c.1-1.7.2-2.9.3-4.6C1249.9,473.2,1246.2,472.3,1242.5,474Z"
            fill="url(#linear-gradient-128)"
          />
          <path
            id="Path_3784"
            d="M722,795.6c-.1.5-.2,1.6-.3,2a14.752,14.752,0,0,1,10.7,3.1c.1-1.7.2-2.9.3-4.6C729.4,794.9,725.7,794,722,795.6Z"
            fill="url(#linear-gradient-129)"
          />
          <path
            id="Path_3785"
            d="M1652.4,641.2a5.38,5.38,0,0,1,7.4-1.9,5.553,5.553,0,0,1,.8.6l1.1,3.4a6.164,6.164,0,0,0-8.7.4,3.589,3.589,0,0,0-.7,1C1652.3,643.6,1652.4,642.4,1652.4,641.2Z"
            fill="url(#linear-gradient-130)"
          />
          <path
            id="Path_3786"
            d="M1307.1,521c.2-1,.6-3.2.8-4.2a29.361,29.361,0,0,1-21.6-6.9c-.4,3.4-.6,6-1,9.4C1291.9,522,1299.6,524.1,1307.1,521Z"
            fill="url(#linear-gradient-131)"
          />
          <path
            id="Path_3787"
            d="M1520.3,807.7c-.3-.4-.9-1.3-1.2-1.7a14.555,14.555,0,0,1-9.9,5.1c1,1.3,1.9,2.3,2.9,3.6C1515.4,813.3,1518.7,811.4,1520.3,807.7Z"
            fill="url(#linear-gradient-132)"
          />
          <path
            id="Path_3788"
            d="M912.3,1085.3a5.305,5.305,0,0,1-7.5,1c-.3-.2-.5-.5-.8-.7-.2-1.2-.4-2.4-.7-3.6a6.062,6.062,0,0,0,8.6.7,3.451,3.451,0,0,0,.8-.9Z"
            fill="url(#linear-gradient-133)"
          />
          <path
            id="Path_3789"
            d="M1090,1109.2c-.2.7-.7,2.1-.9,2.8a19.757,19.757,0,0,1,14.2,6.7c.5-2.3.9-4.1,1.5-6.4C1100.6,1109.8,1095.5,1107.7,1090,1109.2Z"
            fill="url(#linear-gradient-134)"
          />
          <path
            id="Path_3790"
            d="M981.4,974.6l.9,1.8a14.3,14.3,0,0,1,10.4-3.8c-.9-1.4-1.6-2.5-2.4-3.9C987,969.6,983.4,971.1,981.4,974.6Z"
            fill="url(#linear-gradient-135)"
          />
          <path
            id="Path_3791"
            d="M1618.4,208.2c-.1.8-.4,2.5-.5,3.3a23.031,23.031,0,0,1,17.1,5c.2-2.7.3-4.7.6-7.4C1630.3,207,1624.2,205.6,1618.4,208.2Z"
            fill="url(#linear-gradient-136)"
          />
          <path
            id="Path_3792"
            d="M786.4,335.7c2.3.1,4.7.2,7,.3a27.66,27.66,0,0,0,0,22.2c-2.3.1-4.7.2-7,.3A28.275,28.275,0,0,1,786.4,335.7Z"
            fill="url(#linear-gradient-137)"
          />
          <g id="Group_562" transform="translate(1317.885 689.231)">
            <path id="Path_3793" d="M-7.7-11.9l-.1-.1h0Z" fill="#1d262d" />
            <path
              id="Path_3794"
              d="M-8.2-12.1c-.1-.1.2.1,1.1.7l-.8-.5Z"
              fill="#1d262d"
            />
            <path
              id="Path_3795"
              d="M-4.1-11a6.812,6.812,0,0,1-.7-2.8,7.827,7.827,0,0,1,2.3-6.1c-.1.1-7.1-4.6-7.1-4.6a8,8,0,0,0-.1,11.2l.5.5a3.592,3.592,0,0,0,1,.7c.1,0,.2.1.4.2A7.786,7.786,0,0,0-4.1-11Z"
              fill="url(#linear-gradient-138)"
            />
            <path
              id="Path_3796"
              d="M-7.1-11.4c1.7,1.1,3.4,2.2,5,3.2-.6-.4-1.5-1-2.6-1.7C-5.7-10.6-6.6-11.1-7.1-11.4Z"
              fill="#1d262d"
            />
            <path
              id="Path_3797"
              d="M1.6-13.3A7.928,7.928,0,0,1-4.1-11a7.457,7.457,0,0,1-3.8-1l.1.1-.1-.1h0c-.1,0-.2-.1-.3-.1l.3.2.8.5q.9.6,2.4,1.5c1.2.8,2,1.3,2.6,1.7a1.38,1.38,0,0,1,.4.3c.1.1.2.1.4.2A8.544,8.544,0,0,0,3.4-6.4,8.042,8.042,0,0,0,8.7-8.7C8.7-8.8,1.7-13.4,1.6-13.3ZM-1.6-7.9-1.7-8l.1.1Z"
              fill="url(#linear-gradient-139)"
            />
          </g>
          <path
            id="Path_3798"
            d="M1398.1,570.3c-.3,1.7-.8,5.5-1.1,7.1,12.4-1.7,25.9,2.1,37.2,10.9.5-5.8.8-10.2,1.2-16.1C1424.1,567.8,1410.9,564.6,1398.1,570.3Z"
            fill="url(#linear-gradient-140)"
          />
          <path
            id="Path_3799"
            d="M1431.2,737l7.1.3a27.66,27.66,0,0,0,0,22.2c-2.3.1-4.7.2-7.1.3A29.111,29.111,0,0,1,1431.2,737Z"
            fill="url(#linear-gradient-141)"
          />
          <g id="Group_563" transform="translate(1911.454 681.901)">
            <path id="Path_3800" d="M-11.3-7.2l-.1-.2h0Z" fill="#1d262d" />
            <path
              id="Path_3801"
              d="M-11.6-8c-.1-.3.1.4.5,2.1a12.437,12.437,0,0,0-.4-1.6A.749.749,0,0,0-11.6-8Z"
              fill="#1d262d"
            />
            <path
              id="Path_3802"
              d="M-7.3-1.6A12.745,12.745,0,0,1-4.2-5.8,18.3,18.3,0,0,1,8.6-10.3c-.2,0-3.4-14.2-3.6-14.2-6.6-.1-14.1,3.2-16.4,9.8a11.914,11.914,0,0,0-.6,4.6c.1.7.2,1.4.3,2,0,.1.1.3.2.7A15.5,15.5,0,0,0-7.3-1.6Z"
              fill="url(#linear-gradient-142)"
            />
            <path
              id="Path_3803"
              d="M-11.1-5.9C-10.3-2.5-9.5.9-8.6,4.2-8.8,3-9.2,1.3-9.8-1.1-10.3-3.1-10.8-4.8-11.1-5.9Z"
              fill="#1d262d"
            />
            <path
              id="Path_3804"
              d="M4.6,2.7A18.707,18.707,0,0,1-7.3-1.6a12.62,12.62,0,0,1-4.1-5.8l.1.2-.1-.2h0c0-.3-.1-.6-.2-.6,0,.2.1.4.1.6a12.437,12.437,0,0,0,.4,1.6c.3,1.2.7,2.8,1.3,4.8.5,2.4,1,4.1,1.2,5.3.1.3.1.6.2.9.1.2.1.5.2.7a13.635,13.635,0,0,0,4.9,7.3A18.831,18.831,0,0,0,8.1,17C8.2,16.9,4.8,2.7,4.6,2.7ZM-8.2,5.2v0Z"
              fill="url(#linear-gradient-143)"
            />
          </g>
          <g id="Group_564" transform="translate(1865.305 833.753)">
            <path id="Path_3805" d="M-7.7-11.9h0l-.2-.1Z" fill="#1d262d" />
            <path
              id="Path_3806"
              d="M-8.2-12.1c-.1-.1.2.1,1.1.7-.3-.2-.5-.3-.8-.5C-8-12-8.1-12.1-8.2-12.1Z"
              fill="#1d262d"
            />
            <path
              id="Path_3807"
              d="M-4.1-11a6.812,6.812,0,0,1-.7-2.8,7.827,7.827,0,0,1,2.3-6.1c-.1.1-7.1-4.6-7.1-4.5a8,8,0,0,0-.1,11.2l.5.5a3.592,3.592,0,0,0,1,.7c.1,0,.2.1.4.2A9.093,9.093,0,0,0-4.1-11Z"
              fill="url(#linear-gradient-144)"
            />
            <path
              id="Path_3808"
              d="M-7.1-11.4c1.7,1.1,3.4,2.2,5,3.2-.6-.4-1.5-1-2.6-1.7C-5.7-10.6-6.6-11.1-7.1-11.4Z"
              fill="#1d262d"
            />
            <path
              id="Path_3809"
              d="M1.6-13.3A7.928,7.928,0,0,1-4.1-11a7.457,7.457,0,0,1-3.8-1l.1.1-.1-.1h0c-.1,0-.2-.1-.3-.1.1.1.2.1.3.2.3.2.5.3.8.5q.9.6,2.4,1.5c1.2.8,2,1.3,2.6,1.7l.4.3c.1.1.2.1.4.2A8.544,8.544,0,0,0,3.4-6.4,8.042,8.042,0,0,0,8.7-8.7C8.7-8.8,1.7-13.4,1.6-13.3ZM-1.5-7.9-1.7-8l.2.1Z"
              fill="url(#linear-gradient-145)"
            />
          </g>
          <path
            id="Path_3810"
            d="M1885.3,727c-.1.8-.4,2.5-.5,3.3a23.031,23.031,0,0,1,17.1,5c.2-2.7.3-4.7.6-7.4C1897.2,725.8,1891.1,724.4,1885.3,727Z"
            fill="url(#linear-gradient-146)"
          />
          <path
            id="Path_3811"
            d="M1881.4,840c2.4.1,4.7.2,7.1.3a27.66,27.66,0,0,0,0,22.2l-7.1.3A29.111,29.111,0,0,1,1881.4,840Z"
            fill="url(#linear-gradient-147)"
          />
          <path
            id="Path_3812"
            d="M1564.4,316.7c.6-5.7,5-9.1,10.6-8.1l4.2,4c-6.5-1.2-11.5,2.5-12.2,9C1566.1,320,1565.2,318.3,1564.4,316.7Z"
            fill="url(#linear-gradient-148)"
          />
          <path
            id="Path_3813"
            d="M1636.5,466.4c.6-5.8,5-9.1,10.6-8.1l4.2,4c-6.5-1.2-11.5,2.5-12.2,9C1638.2,469.7,1637.3,468,1636.5,466.4Z"
            fill="url(#linear-gradient-149)"
          />
          <path
            id="Path_3814"
            d="M913,724.1a5.431,5.431,0,0,1-6.7-3.7,3.554,3.554,0,0,1-.2-1l1.6-3.3a6.166,6.166,0,0,0,6.6,5.7,5.018,5.018,0,0,0,1.2-.2Z"
            fill="url(#linear-gradient-150)"
          />
          <path
            id="Path_3815"
            d="M1731.7,374.7a5.431,5.431,0,0,1-6.7-3.7,3.554,3.554,0,0,1-.2-1c.5-1.1,1-2.2,1.6-3.3a6.166,6.166,0,0,0,6.6,5.7,5.018,5.018,0,0,0,1.2-.2Z"
            fill="url(#linear-gradient-151)"
          />
          <path
            id="Path_3816"
            d="M1370.8,680.8a6.011,6.011,0,0,1,8.5-.3c.3.3.5.6.8.9.1,1.3.2,2.7.4,4-2.9-3.6-7.2-3.9-10.6-.8C1370.2,683.4,1370.5,682.1,1370.8,680.8Z"
            fill="url(#linear-gradient-152)"
          />
          <path
            id="Path_3817"
            d="M1277.9,529.8c-.3-.4-.9-1.3-1.2-1.7a14.346,14.346,0,0,1-9.7,5.3c1.1,1.3,1.9,2.2,3,3.5C1273.1,535.6,1276.4,533.6,1277.9,529.8Z"
            fill="url(#linear-gradient-153)"
          />
          <path
            id="Path_3818"
            d="M1217.7,740.6a5.3,5.3,0,0,1,7.3-2.1l.9.6c.4,1.1.8,2.3,1.2,3.4a6.137,6.137,0,0,0-8.6.6,3.589,3.589,0,0,0-.7,1Z"
            fill="url(#linear-gradient-154)"
          />
          <path
            id="Path_3819"
            d="M903,830.7a5.38,5.38,0,0,1,7.4-1.9,5.553,5.553,0,0,1,.8.6c.4,1.1.7,2.3,1.1,3.4a6.164,6.164,0,0,0-8.7.4,3.592,3.592,0,0,0-.7,1C903,833,903,831.8,903,830.7Z"
            fill="url(#linear-gradient-155)"
          />
          <path
            id="Path_3820"
            d="M999.9,783.6c.6-5.7,5-9.1,10.6-8.1,1.4,1.3,2.8,2.7,4.2,4-6.5-1.2-11.5,2.5-12.2,9Z"
            fill="url(#linear-gradient-156)"
          />
          <path
            id="Path_3821"
            d="M1196.6,627.4a6.048,6.048,0,0,1,6.3-5.8,5.016,5.016,0,0,1,1.2.2l2.9,2.8c-4.5-.8-8,1.8-8.5,6.3Z"
            fill="url(#linear-gradient-157)"
          />
          <path
            id="Path_3822"
            d="M1296.5,995a5.431,5.431,0,0,1-6.7-3.7,3.554,3.554,0,0,1-.2-1c.5-1.1,1-2.2,1.6-3.3a6.166,6.166,0,0,0,6.6,5.7,5.016,5.016,0,0,0,1.2-.2Z"
            fill="url(#linear-gradient-158)"
          />
          <path
            id="Path_3823"
            d="M1515.7,921.2c-.1.5-.2,1.6-.3,2a14.379,14.379,0,0,1,10.6,3.1c.1-1.7.2-2.9.3-4.6C1523.1,920.5,1519.3,919.6,1515.7,921.2Z"
            fill="url(#linear-gradient-159)"
          />
          <path
            id="Path_3824"
            d="M1695.6,743.4c-.1.5-.2,1.6-.3,2a14.751,14.751,0,0,1,10.7,3.1c.1-1.7.2-2.9.3-4.6C1703.1,742.7,1699.3,741.8,1695.6,743.4Z"
            fill="url(#linear-gradient-160)"
          />
          <path
            id="Path_3825"
            d="M1475.9,1095.6a6.048,6.048,0,0,1,6.3-5.8,5.019,5.019,0,0,1,1.2.2l2.9,2.8c-4.5-.8-8,1.8-8.5,6.3C1477.1,1097.9,1476.5,1096.8,1475.9,1095.6Z"
            fill="url(#linear-gradient-161)"
          />
          <g id="Group_565" transform="translate(1726.654 592.225)">
            <path id="Path_3826" d="M3.5-16.7h0l.1.1Z" fill="#1d262d" />
            <path id="Path_3826" d="M3.9-16.4,3-17.3l.7.7Z" fill="#1d262d" />
            <path
              id="Path_3828"
              d="M.1-18.4a6.621,6.621,0,0,1,.1,2.8A7.865,7.865,0,0,1-3.4-10c.1-.1,5.9,6,6,5.9A7.674,7.674,0,0,0,5-14.9c-.1-.2-.3-.4-.4-.6a6.47,6.47,0,0,0-.8-.9l-.3-.3A7.805,7.805,0,0,0,.1-18.4Z"
              fill="url(#linear-gradient-162)"
            />
            <path
              id="Path_3829"
              d="M3-17.3C1.6-18.7.2-20.1-1.3-21.5L.9-19.3C1.8-18.4,2.5-17.7,3-17.3Z"
              fill="#1d262d"
            />
            <path
              id="Path_3830"
              d="M-6-17.3A8.66,8.66,0,0,1,.1-18.4a8.8,8.8,0,0,1,3.5,1.7l-.1-.1.1.1h0q.3.15.3.3l-.2-.2a2.336,2.336,0,0,1-.7-.7c-.5-.5-1.2-1.1-2.1-2l-2.2-2.2-.4-.4-.3-.3a8.863,8.863,0,0,0-4.3-2.2A8.168,8.168,0,0,0-12-23.2C-11.9-23.2-6-17.2-6-17.3Zm4.3-4.6.1.1-.1-.1Z"
              fill="url(#linear-gradient-163)"
            />
          </g>
          <path
            id="Path_3831"
            d="M513.5,930.3c.6-5.8,5-9.1,10.6-8.1,1.4,1.3,2.8,2.7,4.2,4-6.5-1.2-11.5,2.5-12.2,9C515.2,933.6,514.4,931.9,513.5,930.3Z"
            fill="url(#linear-gradient-164)"
          />
          <g id="Group_566" transform="translate(616.866 912.956)">
            <path id="Path_3832" d="M3.5-16.7h0l.1.1Z" fill="#1d262d" />
            <path
              id="Path_3833"
              d="M3.9-16.4,3-17.3l.7.7A.215.215,0,0,0,3.9-16.4Z"
              fill="#1d262d"
            />
            <path
              id="Path_3834"
              d="M.1-18.4a7.033,7.033,0,0,1,.1,2.9A7.865,7.865,0,0,1-3.4-9.9c.1,0,5.9,6,6,5.9A7.808,7.808,0,0,0,5-14.9c-.1-.2-.3-.4-.4-.6a6.47,6.47,0,0,0-.8-.9l-.3-.3A7.805,7.805,0,0,0,.1-18.4Z"
              fill="url(#linear-gradient-165)"
            />
            <path
              id="Path_3835"
              d="M3-17.3C1.6-18.7.2-20.1-1.3-21.5L.9-19.3C1.8-18.4,2.5-17.7,3-17.3Z"
              fill="#1d262d"
            />
            <path
              id="Path_3836"
              d="M-6-17.3A8.66,8.66,0,0,1,.1-18.4a8.8,8.8,0,0,1,3.5,1.7l-.1-.1.1.1h0q.3.15.3.3l-.2-.2a2.336,2.336,0,0,1-.7-.7c-.5-.5-1.2-1.1-2.1-2l-2.2-2.2-.4-.4-.3-.3a8.863,8.863,0,0,0-4.3-2.2A8.168,8.168,0,0,0-12-23.2C-11.9-23.2-6-17.2-6-17.3Zm4.3-4.6.1.1-.1-.1Z"
              fill="url(#linear-gradient-166)"
            />
          </g>
          <path
            id="Path_3837"
            d="M1562,482.8l-4.8-1.3a20,20,0,0,0,3.3-15.2c1.6.3,3.2.6,4.9.8A19.91,19.91,0,0,1,1562,482.8Z"
            fill="url(#linear-gradient-167)"
          />
          <path
            id="Path_3838"
            d="M628.2,735.8l-4.8-1.3a20,20,0,0,0,3.3-15.2l4.9.8A20,20,0,0,1,628.2,735.8Z"
            fill="url(#linear-gradient-168)"
          />
          <path
            id="Path_3839"
            d="M1177.6,507.6l-6.4,3a28.222,28.222,0,0,0-10.3-19.7c2-1.2,4.1-2.4,6.1-3.5A28.689,28.689,0,0,1,1177.6,507.6Z"
            fill="url(#linear-gradient-169)"
          />
          <path
            id="Path_3840"
            d="M1345.2,438l4.9.3a19.438,19.438,0,0,0-.4,15.5l-4.9.1A19.578,19.578,0,0,1,1345.2,438Z"
            fill="url(#linear-gradient-170)"
          />
          <path
            id="Path_3841"
            d="M869.9,647.1l5.7-4.2a28.193,28.193,0,0,0,14.1,17.4l-5.3,4.7A29.151,29.151,0,0,1,869.9,647.1Z"
            fill="url(#linear-gradient-171)"
          />
          <path
            id="Path_3842"
            d="M1787.1,941l4-2.9a19.754,19.754,0,0,0,9.8,12.1l-3.7,3.3A20.922,20.922,0,0,1,1787.1,941Z"
            fill="url(#linear-gradient-172)"
          />
          <g id="Group_567" transform="translate(919.52 428.565)">
            <path id="Path_3843" d="M-7.7-11.9l-.1-.1h0Z" fill="#1d262d" />
            <path
              id="Path_3844"
              d="M-8.2-12.1c-.1-.1.2.1,1.1.7-.3-.2-.5-.3-.8-.5C-8-12-8.1-12.1-8.2-12.1Z"
              fill="#1d262d"
            />
            <path
              id="Path_3845"
              d="M-4.1-11a6.812,6.812,0,0,1-.7-2.8,7.827,7.827,0,0,1,2.3-6.1c-.1.1-7.1-4.6-7.1-4.5a8,8,0,0,0-.1,11.2l.5.5a3.592,3.592,0,0,0,1,.7c.1,0,.2.1.4.2A9.093,9.093,0,0,0-4.1-11Z"
              fill="url(#linear-gradient-173)"
            />
            <path
              id="Path_3846"
              d="M-7.1-11.4c1.7,1.1,3.4,2.2,5,3.2-.6-.4-1.5-1-2.6-1.7C-5.7-10.6-6.6-11.1-7.1-11.4Z"
              fill="#1d262d"
            />
            <path
              id="Path_3847"
              d="M1.6-13.3A7.928,7.928,0,0,1-4.1-11a7.457,7.457,0,0,1-3.8-1l.1.1-.1-.1h0c-.1,0-.2-.1-.3-.1.1.1.2.1.3.2.3.2.5.3.8.5q.9.6,2.4,1.5c1.2.8,2,1.3,2.6,1.7l.4.3c.1.1.2.1.4.2A8.544,8.544,0,0,0,3.4-6.4,8.042,8.042,0,0,0,8.7-8.7C8.7-8.8,1.7-13.4,1.6-13.3ZM-1.6-7.9-1.7-8l.1.1Z"
              fill="url(#linear-gradient-174)"
            />
          </g>
          <path
            id="Path_3848"
            d="M894.1,527.8c1.3-1,2.6-2,4-2.9a19.754,19.754,0,0,0,9.8,12.1l-3.7,3.3A20.047,20.047,0,0,1,894.1,527.8Z"
            fill="url(#linear-gradient-175)"
          />
          <g id="Group_568" transform="translate(852.102 595.194)">
            <path id="Path_3849" d="M1.4-12.3h0Z" fill="#1d262d" />
            <path
              id="Path_3850"
              d="M.7-12.1c.5-.1,1.1-.2,1.6-.3a5.019,5.019,0,0,1-1.2.2Z"
              fill="#1d262d"
            />
            <path
              id="Path_3851"
              d="M5.5-14.8a9.379,9.379,0,0,1-3.2-1.9,10.281,10.281,0,0,1-3.6-7.8c0,.1-10.7,2.3-10.7,2.3A10.2,10.2,0,0,0-1.8-11.9h.9a7.719,7.719,0,0,0,1.5-.2c.1,0,.2-.1.5-.2A8.789,8.789,0,0,0,5.5-14.8Z"
              fill="url(#linear-gradient-176)"
            />
            <path
              id="Path_3852"
              d="M2.3-12.4c2.5-.5,5.1-1.1,7.6-1.7-.9.2-2.2.5-4,.8Q3.65-12.7,2.3-12.4Z"
              fill="#1d262d"
            />
            <path
              id="Path_3853"
              d="M8.6-22.2a10.366,10.366,0,0,1-3.1,7.4,10.594,10.594,0,0,1-4.3,2.6h.2l-.2.1h0c-.3.1-.4.1-.5.2l.4-.1a5.019,5.019,0,0,1,1.2-.2c.9-.2,2.1-.5,3.6-.8,1.8-.4,3.1-.6,4-.8l.6-.1A4.331,4.331,0,0,0,11-14a11.616,11.616,0,0,0,5.5-3.1,10.077,10.077,0,0,0,2.7-7C19.3-24.5,8.6-22.2,8.6-22.2Zm2.1,7.9h0Z"
              fill="url(#linear-gradient-177)"
            />
          </g>
          <path
            id="Path_3854"
            d="M1866.9,1068.9l22.9,1a90.39,90.39,0,0,0,0,72.1l-22.9,1A93.543,93.543,0,0,1,1866.9,1068.9Z"
            fill="url(#linear-gradient-178)"
          />
          <path
            id="Path_3855"
            d="M1797.4,1076.9l4-2.9a19.429,19.429,0,0,0,9.8,12.1c-1.2,1.1-2.5,2.2-3.7,3.3A20.371,20.371,0,0,1,1797.4,1076.9Z"
            fill="url(#linear-gradient-179)"
          />
          <g id="Group_569" transform="translate(1773.742 1044.549)">
            <path id="Path_3856" d="M7.1-7h0Z" fill="#1d262d" />
            <path
              id="Path_3857"
              d="M6.1-6.8a8.519,8.519,0,0,0,2.3-.4c-.5.1-1.1.2-1.7.3A1.268,1.268,0,0,1,6.1-6.8Z"
              fill="#1d262d"
            />
            <path
              id="Path_3858"
              d="M13-10.6a14.294,14.294,0,0,1-4.6-2.7A14.948,14.948,0,0,1,3.3-24.5c0,.2-15.3,3.2-15.3,3.3A14.745,14.745,0,0,0,2.7-6.4H4c.7-.1,1.5-.2,2.2-.3.1,0,.3-.1.7-.2A15.9,15.9,0,0,0,13-10.6Z"
              fill="url(#linear-gradient-180)"
            />
            <path
              id="Path_3859"
              d="M8.4-7.2C12-8,15.7-8.7,19.3-9.6q-1.95.45-5.7,1.2C11.4-7.9,9.7-7.5,8.4-7.2Z"
              fill="#1d262d"
            />
            <path
              id="Path_3860"
              d="M17.4-21.2A14.929,14.929,0,0,1,13-10.7,15.119,15.119,0,0,1,6.8-7h.1c-.4.1-.7.2-.8.2.2,0,.4-.1.6-.1.6-.1,1.2-.2,1.7-.3,1.2-.3,3-.7,5.2-1.2q3.75-.75,5.7-1.2c.3-.1.6-.1.9-.2s.5-.1.8-.2a15.616,15.616,0,0,0,7.8-4.4,14.768,14.768,0,0,0,3.9-10C32.7-24.5,17.4-21.3,17.4-21.2Zm3,11.3h0Z"
              fill="url(#linear-gradient-181)"
            />
          </g>
          <path
            id="Path_3861"
            d="M1088.5,400.1a46.677,46.677,0,0,0-28.2,35.5l7.5,2.2a33.417,33.417,0,0,1,26.2-18.9C1092.2,412.7,1090.3,406.4,1088.5,400.1Z"
            fill="url(#linear-gradient-182)"
          />
          <path
            id="Path_3914"
            d="M2176.4,183.8a46.677,46.677,0,0,0-28.2,35.5l7.5,2.2a33.417,33.417,0,0,1,26.2-18.9C2180.1,196.3,2178.3,190.1,2176.4,183.8Z"
            fill="url(#linear-gradient-183)"
          />
          <path
            id="Path_3896"
            d="M403.6,136.9a46.677,46.677,0,0,0-28.2,35.5l7.5,2.2a33.417,33.417,0,0,1,26.2-18.9C407.2,149.5,405.4,143.2,403.6,136.9Z"
            fill="url(#linear-gradient-184)"
          />
          <path
            id="Path_3862"
            d="M1443.9,954.6c-1.4,1-4.5,3.3-5.9,4.3,10,7.6,16.9,19.8,18.7,34.1,4.4-3.8,7.8-6.7,12.2-10.5C1464.1,971.2,1457,959.6,1443.9,954.6Z"
            fill="url(#linear-gradient-185)"
          />
          <path
            id="Path_3915"
            d="M2197.8,999.3c-1.4,1-4.5,3.3-5.9,4.3,10,7.6,16.9,19.8,18.7,34.1,4.4-3.8,7.8-6.7,12.2-10.5C2218,1016,2210.9,1004.4,2197.8,999.3Z"
            fill="url(#linear-gradient-186)"
          />
          <path
            id="Path_3863"
            d="M1499.9,412c-11.5,1.2-19.8-5.9-20.2-17.3l6.1-9.9c.4,13.1,9.8,21.3,22.7,20Z"
            fill="url(#linear-gradient-187)"
          />
          <path
            id="Path_3864"
            d="M1407.1,410.3c-8,.9-13.7-4.1-14.1-12,1.4-2.3,2.8-4.6,4.2-6.8.3,9.1,6.8,14.8,15.8,13.9C1411.1,407,1409.1,408.7,1407.1,410.3Z"
            fill="url(#linear-gradient-188)"
          />
          <path
            id="Path_3865"
            d="M1799.5,882.6l-7.6-.3a29.845,29.845,0,0,0,0-23.8c2.5-.1,5-.2,7.6-.3A30.459,30.459,0,0,1,1799.5,882.6Z"
            fill="url(#linear-gradient-189)"
          />
          <path
            id="Path_3866"
            d="M853.5,977.8l-7.6-.3a29.846,29.846,0,0,0,0-23.8l7.6-.3A30.459,30.459,0,0,1,853.5,977.8Z"
            fill="url(#linear-gradient-190)"
          />
          <path
            id="Path_3867"
            d="M1794.5,400.4l6,2.9a26.4,26.4,0,0,0-8.2,19.2l-6.2-2.3A26.983,26.983,0,0,1,1794.5,400.4Z"
            fill="url(#linear-gradient-191)"
          />
          <path
            id="Path_3868"
            d="M1767.4,262.9l-14.7-6.6a63.93,63.93,0,0,0,18.7-47c5.1,1.8,10.1,3.5,15.2,5.3A65.348,65.348,0,0,1,1767.4,262.9Z"
            fill="url(#linear-gradient-192)"
          />
          <path
            id="Path_3869"
            d="M1748.4,280.1c-2-5-4.1-9.9-6.1-14.8a63.955,63.955,0,0,0,45.9-21.3l7.4,14.3A66.179,66.179,0,0,1,1748.4,280.1Z"
            fill="url(#linear-gradient-193)"
          />
          <path
            id="Path_3870"
            d="M1331.7,219.6l-7.5-.3a29.845,29.845,0,0,0,0-23.8l7.5-.3A30.459,30.459,0,0,1,1331.7,219.6Z"
            fill="url(#linear-gradient-194)"
          />
          <path
            id="Path_3871"
            d="M1671.7,944.3a31.833,31.833,0,0,0,19.2-24.1l-5.1-1.5a22.351,22.351,0,0,1-17.8,12.8C1669.2,935.8,1670.5,940,1671.7,944.3Z"
            fill="url(#linear-gradient-195)"
          />
          <path
            id="Path_3872"
            d="M1143.7,205c13.5-1.5,23.2,6.9,23.8,20.3-2.4,3.9-4.8,7.7-7.1,11.6-.5-15.4-11.5-25-26.7-23.5C1137,210.5,1140.4,207.7,1143.7,205Z"
            fill="url(#linear-gradient-196)"
          />
          <path
            id="Path_3873"
            d="M1886.7,362.5c13.5-1.5,23.2,6.9,23.8,20.3l-7.1,11.6c-.5-15.4-11.5-25-26.7-23.5C1880,368,1883.4,365.3,1886.7,362.5Z"
            fill="url(#linear-gradient-197)"
          />
          <path
            id="Path_3874"
            d="M666.5,657c13.5-1.5,23.2,6.9,23.8,20.3-2.4,3.9-4.8,7.7-7.1,11.6-.5-15.4-11.5-25-26.7-23.5Z"
            fill="url(#linear-gradient-198)"
          />
          <path
            id="Path_3875"
            d="M629.8,580.3c.6,5.7-2.9,9.9-8.7,10.1-1.6-1-3.3-2-4.9-3,6.6-.2,10.7-4.9,10-11.4Z"
            fill="url(#linear-gradient-199)"
          />
          <path
            id="Path_3876"
            d="M1739.4,497.3c-.8-1.2-2.6-4-3.4-5.2-7,8.2-17.8,13.5-30.1,14.4,3,4,5.4,7,8.4,11C1724.2,513.8,1734.5,508.3,1739.4,497.3Z"
            fill="url(#linear-gradient-200)"
          />
          <path
            id="Path_3877"
            d="M1064.1,778.4c.6,5.7-2.9,9.9-8.7,10.1l-4.9-3c6.6-.2,10.7-4.9,10-11.4C1061.8,775.5,1063,777,1064.1,778.4Z"
            fill="url(#linear-gradient-201)"
          />
          <path
            id="Path_3878"
            d="M1001.3,664l10.4,5a45.369,45.369,0,0,0-14.2,33.2l-10.7-4.1A46.883,46.883,0,0,1,1001.3,664Z"
            fill="url(#linear-gradient-202)"
          />
          <path
            id="Path_3879"
            d="M1141.8,729.6c-.8-1.3-2.7-4.2-3.6-5.5-7.4,8.6-18.8,14.3-31.8,15.1,3.2,4.2,5.7,7.4,8.9,11.6C1125.8,747,1136.6,741.2,1141.8,729.6Z"
            fill="url(#linear-gradient-203)"
          />
          <path
            id="Path_3880"
            d="M1493.7,355.7c-.6-.9-2-3-2.6-4-5.3,6.2-13.5,10.2-22.8,10.8,2.3,3,4.1,5.3,6.4,8.3C1482.2,368.2,1490,364,1493.7,355.7Z"
            fill="url(#linear-gradient-204)"
          />
          <path
            id="Path_3881"
            d="M1680.8,229.6c.5-1.6,1-3.1,1.5-4.7a19.273,19.273,0,0,0,15,4.1l-1.1,4.8A20.264,20.264,0,0,1,1680.8,229.6Z"
            fill="url(#linear-gradient-205)"
          />
          <path
            id="Path_3882"
            d="M1747.6,213.2c-5.8.3-9.7-3.5-9.6-9.2l3.3-4.8c-.2,6.6,4.3,10.9,10.8,10.7Z"
            fill="url(#linear-gradient-206)"
          />
          <path
            id="Path_3883"
            d="M493.7,758.9c53.5-5.8,92.2,27.4,94.3,80.5-9.4,15.3-18.9,30.7-28.3,46-2-61.1-45.7-99.5-105.9-93.4Q473.75,775.5,493.7,758.9Z"
            fill="url(#linear-gradient-207)"
          />
          <path
            id="Path_3897"
            d="M141.7,422.8c53.5-5.8,92.2,27.4,94.3,80.5-9.4,15.3-18.9,30.7-28.3,46-2-61.1-45.7-99.5-105.9-93.4Q121.75,439.4,141.7,422.8Z"
            fill="url(#linear-gradient-208)"
          />
          <path
            id="Path_3884"
            d="M1592.5,781.4c16.7,13.5,18.3,33.8,3.9,49.4l-21,5c16.7-17.8,15.2-41-3.5-56.3C1578.8,780.1,1585.6,780.7,1592.5,781.4Z"
            fill="url(#linear-gradient-209)"
          />
          <path
            id="Path_3885"
            d="M1022.1,565.6c.4-2.2,1.1-7.2,1.5-9.4-16.4,2.2-34.2-2.7-49.1-14.4-.6,7.7-1,13.5-1.6,21.2C987.7,569,1005.2,573.1,1022.1,565.6Z"
            fill="url(#linear-gradient-210)"
          />
          <path
            id="Path_3901"
            d="M259.9,198.4c.4-2.2,1.1-7.2,1.5-9.4-16.4,2.2-34.2-2.7-49.1-14.4-.6,7.7-1,13.5-1.6,21.2C225.6,201.8,243,205.9,259.9,198.4Z"
            fill="url(#linear-gradient-211)"
          />
          <path
            id="Path_3886"
            d="M1695.6,1032.9c2.6,1.8,8.8,5.7,11.4,7.5,6.7-22.6,23.6-42.9,47.6-55.5-9.6-5.3-17-9.2-26.6-14.5C1711.4,986.3,1695.7,1006.5,1695.6,1032.9Z"
            fill="url(#linear-gradient-212)"
          />
          <path
            id="Path_3887"
            d="M1180,930.1l32.2,1.4a127.9,127.9,0,0,0,0,101.5c-10.7.5-21.5.9-32.2,1.4A131.308,131.308,0,0,1,1180,930.1Z"
            fill="url(#linear-gradient-213)"
          />
          <path
            id="Path_3907"
            d="M2146.3,760.4l32.2,1.4a127.9,127.9,0,0,0,0,101.5c-10.7.5-21.5.9-32.2,1.4A131.307,131.307,0,0,1,2146.3,760.4Z"
            fill="url(#linear-gradient-214)"
          />
          <path
            id="Path_3902"
            d="M185.1,355l32.2,1.4a127.9,127.9,0,0,0,0,101.5c-10.7.5-21.5.9-32.2,1.4A131.308,131.308,0,0,1,185.1,355Z"
            fill="url(#linear-gradient-215)"
          />
          <path
            id="Path_3888"
            d="M1056.3,927.9c-11.9,10.9-23.8,21.7-35.6,32.6A191.253,191.253,0,0,0,913.5,852.9c10.9-11.8,21.8-23.7,32.7-35.5A196.422,196.422,0,0,1,1056.3,927.9Z"
            fill="url(#linear-gradient-216)"
          />
          <g id="Group_570" transform="translate(1565.274 1094.19)">
            <path
              id="Path_3889"
              d="M14.4-12.6c.1-.1.2-.3.3-.4a.1.1,0,0,1,.1-.1Z"
              fill="#1d262d"
            />
            <path
              id="Path_3890"
              d="M15.6-14.4q.45-.9-3.3,3.9c.8-1,1.6-1.9,2.4-2.9A9.016,9.016,0,0,0,15.6-14.4Z"
              fill="#1d262d"
            />
            <path
              id="Path_3891"
              d="M9.6,2.6a30.805,30.805,0,0,1,12.1-.1c10.7,2,19.9,8.8,26.2,17.4-.3-.3,21.5-26.1,21.4-26.3C60.2-18.8,43.8-28.1,28.2-23.1a26.152,26.152,0,0,0-9.3,5.3,35.142,35.142,0,0,0-3.3,3.4,12.834,12.834,0,0,1-.9,1.4A29.849,29.849,0,0,0,9.6,2.6Z"
              fill="url(#linear-gradient-217)"
            />
            <path
              id="Path_3892"
              d="M12.3-10.5C7.2-4.3,2.1,2-2.9,8.3c1.8-2.2,4.5-5.4,8.1-9.7C8.3-5.3,10.6-8.3,12.3-10.5Z"
              fill="#1d262d"
            />
            <path
              id="Path_3893"
              d="M18.2,30.6c-5.7-8-9.2-18.2-8.6-28A30.768,30.768,0,0,1,14.7-13c-.1.1-.2.3-.3.4l.3-.5a.1.1,0,0,1-.1.1,12.834,12.834,0,0,0,.9-1.4c-.3.3-.6.7-.9,1-.8,1-1.6,1.9-2.4,2.9C10.5-8.3,8.1-5.3,5.1-1.4c-3.5,4.3-6.2,7.5-8,9.7A18.258,18.258,0,0,1-4.2,9.9a12.133,12.133,0,0,0-1,1.4,32.926,32.926,0,0,0-6.7,19.3c-.2,9.5,3.3,18.7,8.7,26.3C-3.2,57,18.4,30.8,18.2,30.6ZM-4.3,10.3l.4-.4-.2.2c-.1.1-.1.2-.2.2Z"
              fill="url(#linear-gradient-218)"
            />
          </g>
          <rect
            id="Rectangle_150"
            width="1419.1"
            height="946"
            transform="translate(521.7 182.1)"
            fill="none"
          />
        </g>
      </svg>
    </div>
  );
};

export default ConfettiSVG;
