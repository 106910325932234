import React, { useState, useEffect, Fragment } from "react";
import {
  FormControlLabel,
  Checkbox,
  Link,
  Skeleton,
  Rating,
} from "@mui/material";
import { StarBorder } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
import ReactImageMagnify from "react-image-magnify";
import { useSnackbar } from "notistack";
import { Navigation, Pagination, EffectCoverflow } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
//Actions
import {
  requestSetProductOnFavorite,
  requestFavorites,
  //cleanSetFavoritesReducer,
  requestGetAdmissionKits,
} from "../../ReduxSaga/Actions/Products";
import {
  requestCarCurrentOrder,
  requestGetCartOrder,
  requestCartOrderAddMembership,
  requestCleanCartReducerAddMembership,
  cleanCartOrderOperations,
} from "../../ReduxSaga/Actions/Cart";
import { requestLoggUserActivity } from "../../ReduxSaga/Actions/General";
//Components
import NotificationSnackbar from "../base/components/NotificationSnackbar";
import NoticeInStockModal from "./components/NoticeInStockModal";
import {
  capitalizeText,
  formatPrice,
  truncateText,
  percentageUtility,
} from "../../../Libraries/Utils/utils";
import CustomerBadge from "../base/components/CustomerBadge";
import BlockProduct from "../base/components/BlockProduct";
import VariantDetailComponent from "./components/VariantDetailComponent";
import ProductsSections from "../base/components/ProductsSections";
import ButtonComponent from "../base/components/ButtonComponent";
//Assets
import { ReactComponent as HeartOutlineIcon } from "./../../../assets/media/images/icons/heartOutlineIcon.svg";
import { ReactComponent as HeartFillIcon } from "./../../../assets/media/images/icons/heartFillIcon.svg";
import { ReactComponent as DiscountIcon } from "./../../../assets/media/images/icons/discountIcon.svg";

const ultraFakeTags = [
  {
    id: 1,
    tagName: "3 X 2",
    color: "#e56",
  },
  {
    id: 2,
    tagName: "MOM SET",
    color: "#fa5",
  },
  {
    id: 2,
    tagName: "MOM SET",
    color: "#fdd",
  },
  {
    id: 2,
    tagName: "MOM SET",
    color: "#a3e",
  },
  {
    id: 2,
    tagName: "MOM SET",
    color: "#c3d",
  },
];

// SwiperCore.use([Navigation, Pagination, Autoplay, EffectCoverflow]);

const DetailProductContainer = (props) => {
  //NAVIGATION
  const history = useHistory();
  //Notistack
  const { enqueueSnackbar } = useSnackbar();
  /// TRANSLATION
  const { t, i18n } = useTranslation();
  /// REDUX
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.products = useSelector((state) => state.products);
  appReducers.cart = useSelector((state) => state.cart);
  appReducers.general = useSelector((state) => state.general);
  ///HOOKS
  const [dataNotification, setDataNotification] = useState({
    isOpen: false,
    type: "",
    message: "",
    icon: "",
  });
  const [value, setValue] = useState(0);
  //const [isSharedActive, setIsSharedActive] = useState(false);
  const [isOpenNotification, setIsOpenNotification] = useState(false);
  const [isProductInStock, setIsProductInStock] = useState(false);
  const [showBtnFavorites, setShowBtnFavorites] = useState(false);
  const [isOnFavorites, setIsOnFavorites] = useState(false);
  const [itemCode, setItemCode] = useState("");
  const [typeLine, setTypeLine] = useState("");
  const [product, setProductDetail] = useState({});
  const [pendNotification, setPendNotification] = useState(false);
  const [goToCheckOut, setGoToCheckOut] = useState(false);
  const [isUserLogedIn, setIsUserLogedIn] = useState(false);
  //const [isAddFavorite, setIsAddFavorite] = useState(false);

  //const [isColorArrayEnabled, setIsColorArrayEnabled] = useState(true);

  //const [isActiveFragance, setIsActiveFragance] = useState(false);
  const [isLoadingEnable, setIsLoadingEnable] = useState(true);

  const [ratingProduct, setRatingProduct] = useState(0);
  const [showFavoriteIcon, setShowFavoriteIcon] = useState(false);

  //const [typePlan, setTypePlan] = useState("regular");
  const [typeUser, setTypeUser] = useState("");

  ///Membresia
  const [cpMemberships, setCpMemberships] = useState([]);
  const [infoCartOrder, setInfoCartOrder] = useState({});
  const [lineInOrderMembership, setLineInOrderMembership] = useState({});

  ///Producto
  const [isAddBag, setIsAddBag] = useState(false);
  const [numberProduct, setNumberProduct] = useState(0);
  const [orderDetailId, setOrderDetailId] = useState(0);
  const [lineTypeOrderDetail, setLineTypeOrderDetail] = useState("");

  const [typeModifyOrderNotification, setTypeModifyOrderNotification] =
    useState("");

  const [imageSwiperActive, setImageSwiperActive] = useState({});

  const [typeMessage, setTypeMessage] = useState(0);
  const [valueMessage, setValueMessage] = useState("");

  //const colorDuos = "#120E17";
  // const fakeImages = [
  //   {
  //     id: 1,
  //     urlPath: "https://www.websizusa.net/imgprod/fullsize/83234.jpg",
  //     nameProduct: "Name 1",
  //   },
  //   {
  //     id: 2,
  //     urlPath: "https://www.websizusa.net/imgprod/fullsize/82556.jpg",
  //     nameProduct: "Name 2",
  //   },
  //   {
  //     id: 3,
  //     urlPath: "https://www.websizusa.net/imgprod/fullsize/83234.jpg",
  //     nameProduct: "Name 3",
  //   },
  //   {
  //     id: 4,
  //     urlPath: "https://www.websizusa.net/imgprod/fullsize/82556.jpg",
  //     nameProduct: "Name 4",
  //   },
  // ];

  // const colorArticleModels = [
  //   {
  //     anchorArticle: "31125/N",
  //     codeColor: "#620E17",
  //     nameColor: "Jerez",
  //   },
  //   {
  //     anchorArticle: "31128/N",
  //     codeColor: "#6E2F2F",
  //     nameColor: "Chocolate",
  //   },
  //   {
  //     anchorArticle: "31152/N",
  //     codeColor: "#8C3F37",
  //     nameColor: "Bronce",
  //   },
  //   {
  //     anchorArticle: "31153/N",
  //     codeColor: "#98293D",
  //     nameColor: "Suspiro",
  //   },
  //   {
  //     anchorArticle: "31159/N",
  //     codeColor: "#652D34",
  //     nameColor: "Borgona",
  //   },
  //   {
  //     anchorArticle: "31215/N",
  //     codeColor: "#D55189",
  //     nameColor: "Zambala",
  //   },
  //   {
  //     anchorArticle: "31267/N",
  //     codeColor: "#63224A",
  //     nameColor: "Tinto",
  //   },
  // ];
  // const [test, settest] = useState(undefined);

  ///EFFECTS
  useEffect(() => {
    document.getElementById("detail__product").scrollIntoView();
  }, []);

  //Effect on Params React Routing
  useEffect(() => {
    if (
      props?.match?.hasOwnProperty("params") &&
      props?.match?.params?.hasOwnProperty("itemCode") &&
      props?.match?.params?.hasOwnProperty("typeLine")
    ) {
      // if (props?.match?.params?.hasOwnProperty("webPage"))
      //   alert("trae pagina web");
      const itemCode = props?.match.params.itemCode;
      const typeLine = props?.match.params.typeLine;
      setItemCode(itemCode);
      setTypeLine(typeLine);
    }
  }, [props?.match]);

  //Effect on all Products
  useEffect(() => {
    if (
      !appReducers?.products?.errorLoadAllProducts &&
      !appReducers?.products?.errorLoadTrPrExProducts &&
      itemCode.length > 0 &&
      typeLine.length > 0
    ) {
      if (i18n.language === "en") {
        if (typeLine === "N") {
          if (
            appReducers?.products?.productsEn?.find(
              (p) => p.itemCode === itemCode && p.typeLine === typeLine
            )
          ) {
            setProductDetail(
              appReducers?.products?.productsEn?.find(
                (p) => p.itemCode === itemCode && p.typeLine === typeLine
              )
            );

            setIsLoadingEnable(false);
          }
        } else {
          if (
            appReducers?.products?.promotionsEn?.find(
              (p) => p.itemCode === itemCode && p.typeLine === typeLine
            )
          )
            setProductDetail(
              appReducers?.products?.promotionsEn?.find(
                (p) => p.itemCode === itemCode && p.typeLine === typeLine
              )
            );

          setIsLoadingEnable(false);
        }
      } else if (i18n.language === "es") {
        if (typeLine === "N") {
          if (
            appReducers?.products?.productsEs?.find(
              (p) => p.itemCode === itemCode && p.typeLine === typeLine
            )
          )
            setProductDetail(
              appReducers?.products?.productsEs?.find(
                (p) => p.itemCode === itemCode && p.typeLine === typeLine
              )
            );

          setIsLoadingEnable(false);
        } else {
          if (
            appReducers?.products?.promotionsEs?.find(
              (p) => p.itemCode === itemCode && p.typeLine === typeLine
            )
          )
            setProductDetail(
              appReducers?.products?.promotionsEs?.find(
                (p) => p.itemCode === itemCode && p.typeLine === typeLine
              )
            );

          setIsLoadingEnable(false);
        }
      }
    }
  }, [
    appReducers?.products?.errorLoadAllProducts,
    i18n.language,
    itemCode,
    typeLine,
    appReducers.products.productsEs,
    appReducers.products.productsEn,
    appReducers.products.promotionsEs,
    appReducers.products.promotionsEn,
  ]);

  //Effect on load Favorites
  useEffect(() => {
    if (
      localStorage.getItem("ZUsaUInfo") &&
      !JSON.parse(localStorage.getItem("ZUsaUInfo")).isGuestUser &&
      itemCode.length > 0 &&
      typeLine.length > 0
    ) {
      //debugger;
      if (typeLine === "N") {
        setShowBtnFavorites(true);
      }
      if (
        !appReducers?.products?.errorLoadfavorites &&
        !appReducers?.products?.favoritesEn
      ) {
        dispatch(requestFavorites());
      } else {
        if (!appReducers?.products?.errorLoadfavorites) {
          if (i18n.language === "en") {
            if (
              appReducers?.products?.favoritesEn.find(
                (f) => f.itemCode === itemCode && f.typeLine === typeLine
              )
            )
              setIsOnFavorites(true);
            else setIsOnFavorites(false);
          }
          if (i18n.language === "es") {
            if (
              appReducers?.products?.favoritesEs.find(
                (f) => f.itemCode === itemCode && f.typeLine === typeLine
              )
            )
              setIsOnFavorites(true);
            else setIsOnFavorites(false);
          }
        }
      }
    }
    if (JSON.parse(localStorage.getItem("ZUsaUInfo"))?.isGuestUser) {
      setIsUserLogedIn(false);
    } else {
      setIsUserLogedIn(true);
    }
  }, [
    itemCode,
    typeLine,
    i18n.language,
    appReducers.products.errorLoadfavorites,
    appReducers.products.favoritesEn,
    appReducers.products.favoritesEs,
    localStorage.getItem("ZUsaUInfo"),
  ]);

  useEffect(() => {
    if (
      appReducers?.cart?.cartCurrentOrder?.code &&
      appReducers?.cart?.cartCurrentOrder?.code === 100
      //&& pendNotification
    ) {
      if (typeUser !== "2") {
        const { lines } = appReducers.cart.cartOrder;
        let isAddMembership = lines.some(
          (item) => item.line.codart === cpMemberships[0].itemCode
        );
        if (props.typePlan === "desc" && !isAddMembership) {
          //si no tiene membresia en la orden debe agregarla
          if (cpMemberships.length > 0) {
            dispatch(
              requestCartOrderAddMembership(
                -1,
                0,
                2,
                0,
                cpMemberships[0].itemCode
              )
            );
          } else {
            dispatch(requestCartOrderAddMembership(-1, 0, 2, 0, "02110"));
          }
        }
      }

      dispatch(requestGetCartOrder());
      dispatch(cleanCartOrderOperations());

      const typeOp = appReducers?.cart?.cartOpType;
      switch (typeOp) {
        case "add":
          enqueueSnackbar(t("__message_success_product_add_cart_label"), {
            variant: "success",
          });
          break;
        case "modify":
          enqueueSnackbar(t("__message_success_product_modified_cart_label"), {
            variant: "success",
          });
          break;
        case "delete":
          enqueueSnackbar(t("__message_success_product_deleted_cart_label"), {
            variant: "warning",
          });
          break;
      }

      // switch(typeModifyOrderNotification){
      // case "BAG":
      //   enqueueSnackbar(
      //     t("__message_success_product_add_cart_label"),
      //     {
      //       variant: "success",
      //     }
      //   );
      //   break;
      // case "ADD":
      //   enqueueSnackbar(
      //     "Agregaste un producto a tu orden",
      //     {
      //       variant: "success",
      //     }
      //   );
      //   break;
      // case "REM":
      //   enqueueSnackbar(
      //     "Eliminaste un producto de tu orden",
      //     {
      //       variant: "success",
      //     }
      //   );
      //   break;
      // default:
      //   enqueueSnackbar(
      //     t("_Zusa_Item_Detail_was") + " "+ t("_Zusa_Item_Detail_modified") + " "+ t("_Zusa_Item_Detail_correclty"),
      //     {
      //       variant: "success",
      //     }
      //   );
      //   break;
      // }

      // setDataNotification({
      //   isOpen: true,
      //   type: "success",
      //   message: t(
      //     "__product_Detail_Module_Transaction_IsOnFavorites_Success_Message"
      //   ),
      // });
      // setDataNotification({
      //   isOpen: true,
      //   type: "success",
      //   message: t("__message_success_product_add_cart_label"),
      // });
      //setPendNotification(false);
      // setGoToCheckOut(false);
      // if (goToCheckOut) {
      //   setTimeout(() => {
      //     history.push("../../../checkout");
      //   }, 500);
      //   //accion de que se envio el articulo y te mando al checkout
      //   dispatch(
      //     requestLoggUserActivity(
      //       14,
      //       0,
      //       0,
      //       product?.itemCode,
      //       "Click en Comprar Ahora"
      //     )
      //   );
      // } else {
      //accion de que se envio el articulo
      //}
      dispatch(
        requestLoggUserActivity(
          14,
          0,
          0,
          product?.itemCode,
          "Click en Agregar al Carrito"
        )
      );

      props?.handleGAEvent(
        "Navigation",
        "Usuario Añade Un Producto... a Su Carrito Sku: " + product?.itemCode
      );
    } else if (
      appReducers?.cart?.cartCurrentOrder?.code &&
      appReducers?.cart?.cartCurrentOrder?.code === 110 &&
      pendNotification
    ) {
      //debugger;
      // setDataNotification({
      //   isOpen: true,
      //   type: "warning",
      //   message: t("__message_beauty_boxes_exeded_product_label"),
      // });
      //setPendNotification(false);
    }
  }, [appReducers?.cart.cartCurrentOrder]);

  useEffect(() => {
    if (product) {
      if (product?.comentsAndRating && product?.comentsAndRating.length > 0) {
        let sumRating = 0;
        let promRating = 0;
        product?.comentsAndRating.forEach((x) => {
          sumRating += x.rating;
        });

        promRating = sumRating / product.comentsAndRating.length;

        if (promRating < 3) {
          setRatingProduct(3.5);
        } else {
          setRatingProduct(promRating);
        }
      } else {
        setRatingProduct(4.5);
      }

      //console.log("product", product);
      if (product.relatedProducts) {
        setIsLoadingEnable(false);
      }

      if (product.alsoBought) {
        setIsLoadingEnable(false);
      }

      if (product.imagesArticle && product.imagesArticle.length > 0) {
        setImageSwiperActive(product.imagesArticle[0]);
      }
    }
  }, [product]);

  useEffect(() => {
    if (typeof appReducers?.general?.errorLoadFirstCharge !== "undefined") {
      if (!appReducers.general.errorLoadFirstCharge) {
        //debugger;
        const { isGuestUser } = appReducers.general.firstChargeModel;
        if (isGuestUser === false) setShowFavoriteIcon(true);
        //debugger;
        //userLogeado
        const { typeUser } = appReducers.general.firstChargeModel;

        if (typeUser === "2") {
          props.setTypePlan("desc");
        } else {
          props.setTypePlan("regular");
        }
        setTypeUser(typeUser);
      }
    }
  }, [
    appReducers?.general.errorLoadFirstCharge,
    appReducers?.general.firstChargeModel,
  ]);

  //ADMISSION KITS
  useEffect(() => {
    if (appReducers?.products?.admissionKitList) {
      const kits = appReducers.products.admissionKitList;
      setCpMemberships(
        kits.admissionKitList.filter(
          (f) =>
            f.typePlan === 2 && f.idLang === (i18n.language === "es" ? 1 : 2)
        )
      );
    } else {
      dispatch(requestGetAdmissionKits());
    }
  }, [appReducers?.products.admissionKitList, i18n.language]);

  //use efeects
  useEffect(() => {
    if (appReducers?.cart?.cartAddMembership) {
      const respAddMemship = appReducers.cart?.cartAddMembership;
      if (respAddMemship.code === 100) {
        //limpiar el reducer
        dispatch(requestCleanCartReducerAddMembership());

        //notificacion de que se agrego la membresia
        dispatch(requestGetCartOrder());
      }
    }
  }, [appReducers?.cart.cartAddMembership]);

  useEffect(() => {
    if (typeof appReducers?.cart?.errorCartOrder !== "undefined") {
      if (
        appReducers?.cart?.cartOrder &&
        !appReducers?.cart?.errorCartOrdererrorCartOrder &&
        cpMemberships.length > 0
      ) {
        //debugger;
        const order = appReducers.cart.cartOrder;
        const { lines } = order;

        ///*** BUSQUEDA DEL PRODUCTO EN LA ORDEN ***///
        if (order?.lines.find((d) => d.line.codart === product.itemCode)) {
          setIsAddBag(true);
          setNumberProduct(
            order?.lines.find((d) => d.line.codart === product.itemCode).line
              .qtyped
          );
          setOrderDetailId(
            order?.lines.find((d) => d.line.codart === product.itemCode).line
              .idpedidodet
          );
          setLineTypeOrderDetail(
            order?.lines.find((d) => d.line.codart === product.itemCode).line
              .tipolinea
          );
        } else {
          setIsAddBag(false);
          setNumberProduct(1);
        }
        ///*** BUSQUEDA DEL PRODUCTO EN LA ORDEN ***///

        ///*** BUSQUEDA DE MEMBRESIA EN LA ORDEN ***///
        let isAddMembership = lines.some(
          (item) => item.line.codart === cpMemberships[0].itemCode
        );
        if (typeUser !== "2") {
          if (isAddMembership) {
            props.setTypePlan("desc");

            let lineInOrderMembership = lines.find(
              (item) => item.line.codart === cpMemberships[0].itemCode
            ).line;

            setLineInOrderMembership(lineInOrderMembership);
          } else {
            props.setTypePlan("regular");

            setLineInOrderMembership({});
          }
        }

        ///*** BUSQUEDA DE MEMBRESIA EN LA ORDEN ***///
        setInfoCartOrder(order);
      } else {
        if (
          appReducers?.cart?.cartOrder &&
          !appReducers?.cart?.errorCartOrdererrorCartOrder
        ) {
          const order = appReducers.cart.cartOrder;
          ///*** BUSQUEDA DEL PRODUCTO EN LA ORDEN ***///
          if (order?.lines.find((d) => d.line.codart === product.itemCode)) {
            setIsAddBag(true);
            setNumberProduct(
              order?.lines.find((d) => d.line.codart === product.itemCode).line
                .qtyped
            );
          } else {
            setIsAddBag(false);
            setNumberProduct(1);
          }
        }
      }
    }
  }, [appReducers?.cart.cartOrder, appReducers?.cart.errorCartOrder, product]);

  useEffect(() => {
    if (typeof appReducers?.cart?.errorCartOrder !== "undefined") {
      //debugger;
      if (
        appReducers?.cart?.cartOrder &&
        !appReducers?.cart?.errorCartOrdererrorCartOrder &&
        product
      ) {
        ////evaluar el monto que ya viene en la orden
        //// prioridad
        //// 1) monto min
        //// 2) sets maximo
        const orderCurrent = appReducers?.cart?.cartOrder;
        if (product.minAmount > 0) {
          if (
            !orderCurrent.lines ||
            orderCurrent.lines.reduce(
              (accumulator, object) =>
                object.line.codart !== product.itemCode
                  ? accumulator + object.line.montolinai
                  : accumulator,
              0
            ) < product.minAmount
          ) {
            setTypeMessage(1);
            //
            setValueMessage(product.minAmount);
          } else if (product.maxSets > 0) {
            if (
              orderCurrent.lines &&
              orderCurrent.lines.reduce(
                (accumulator, object) =>
                  object.line.codart === product.itemCode
                    ? accumulator + object.line.qtyped
                    : accumulator,
                0
              ) >= product.maxSets
            ) {
              setTypeMessage(2);
              //
              setValueMessage(product.maxSets);
            } else {
              setTypeMessage(0);
              //
              setValueMessage("");
            }
          } else {
            setTypeMessage(0);
            //
            setValueMessage("");
          }
        } else if (product.maxSets > 0) {
          if (
            orderCurrent.lines &&
            orderCurrent.lines.reduce(
              (accumulator, object) =>
                object.line.codart === product.itemCode
                  ? accumulator + object.line.qtyped
                  : accumulator,
              0
            ) >= product.maxSets
          ) {
            setTypeMessage(2);
            //
            setValueMessage(product.maxSets);
          } else {
            setTypeMessage(0);
            //
            setValueMessage("");
          }
        } else {
          setTypeMessage(0);
          //
          setValueMessage("");
        }
      }
    }
  }, [
    appReducers?.cart?.cartOrder,
    appReducers?.cart?.errorCartOrder,
    product,
  ]);

  // useEffect(() => {
  //   //debugger;
  //   if(product) {
  //     settest(product.isLabelConfig)
  //   }
  // },[ product ])

  ///HANDLERS
  // const handleCopyLink = () => {
  //   try {
  //     const el = document.createElement("input");
  //     el.value = window.location.href;
  //     document.body.appendChild(el);
  //     el.select();
  //     document.execCommand("copy");
  //     document.body.removeChild(el);
  //     setDataNotification({
  //       isOpen: true,
  //       type: "success",
  //       message: t(
  //         "__product_Detail_Module_Transaction_IsOnFavorites_Success_Message"
  //       ),
  //     });
  //     enqueueSnackbar(t("__message_success_product_add_cart_label"), {
  //       variant: "success",
  //     });
  //     // setDataNotification({
  //     //   isOpen: true,
  //     //   type: "success",
  //     //   message: t("__message_link_copy_label"),
  //     // });
  //   } catch (err) {
  //     setDataNotification({
  //       isOpen: true,
  //       type: "success",
  //       message: t(
  //         "__product_Detail_Module_Transaction_IsOnFavorites_Success_Message"
  //       ),
  //     });
  //     // setDataNotification({
  //     //   isOpen: true,
  //     //   type: "error",
  //     //   message: t("__message_failed_label"),
  //     // });
  //   }
  // };
  // const handleChange = (event, newValue) => {
  //   switch (newValue) {
  //     case 0:
  //       dispatch(
  //         requestLoggUserActivity(
  //           14,
  //           0,
  //           0,
  //           product?.itemCode,
  //           "Click en Descripcion"
  //         )
  //       );
  //       break;
  //     case 1:
  //       dispatch(
  //         requestLoggUserActivity(
  //           14,
  //           0,
  //           0,
  //           product?.itemCode,
  //           "Click en Modo de Uso"
  //         )
  //       );
  //       break;
  //     case 2:
  //       dispatch(
  //         requestLoggUserActivity(
  //           14,
  //           0,
  //           0,
  //           product?.itemCode,
  //           "Click en Ingredientes"
  //         )
  //       );
  //       break;
  //     default:
  //       dispatch(
  //         requestLoggUserActivity(
  //           14,
  //           0,
  //           0,
  //           product?.itemCode,
  //           "Click en Descripcion"
  //         )
  //       );
  //       break;
  //   }

  //   setValue(newValue);
  // };
  // const handleButtonClick = () => {
  //   const parameters = {
  //     idPedidoDet: 1,
  //     idpromo: 1,
  //     itemCode: product?.itemCode,
  //     opc: 0,
  //     pedidosD: [],
  //     quantity: 1,
  //     type: product.typeLine,
  //     withAssign: 0,
  //   };
  //   dispatch(requestCarCurrentOrder(parameters));
  //   setIsOpenNotification(true);
  //   setPendNotification(true);
  //   setGoToCheckOut(false);
  // };
  // const handleBuyNowClick = () => {
  //   const parameters = {
  //     idPedidoDet: 1,
  //     idpromo: 1,
  //     itemCode: product?.itemCode,
  //     opc: 0,
  //     pedidosD: [],
  //     quantity: 1,
  //     type: product.typeLine,
  //     withAssign: 0,
  //   };
  //   dispatch(requestCarCurrentOrder(parameters));
  //   setGoToCheckOut(true);
  //   setPendNotification(true);
  // };
  // const handleNoticeInStock = () => {
  //   //setIsProductInStock(true);
  //   setIsOnFavorites(true);
  //   dispatch(requestSetProductOnFavorite(itemCode, typeLine, true, true));
  // };
  // const handleSetProductOnFavorite = (e) => {
  //   setIsOnFavorites(e.target.checked);
  //   dispatch(
  //     requestSetProductOnFavorite(itemCode, typeLine, e.target.checked, false)
  //   );
  // };

  const handleChangeFavorite = (e) => {
    if (product.hasOwnProperty("itemCode")) {
      dispatch(
        requestSetProductOnFavorite(
          product.itemCode,
          product.typeLine,
          e.target.checked,
          false
        )
      );
      dispatch(
        requestLoggUserActivity(
          39,
          0,
          0,
          "",
          `Click ${
            e.target.checked ? "agrega a favoritos" : "quita de favoritos"
          } (NewProductCard) ${product.itemCode}`
        )
      );
    }
  };

  const handleChangeTypePlan = (event) => {
    // //si es desc
    // if (event.target.value === "desc" && typeUser === "5") {
    //   //agregar membresia
    //   debugger;
    //   if (cpMemberships.length > 0) {
    //     dispatch(
    //       requestCartOrderAddMembership(-1, 0, 2, 0, cpMemberships[0].itemCode)
    //     );
    //   } else {
    //     dispatch(requestCartOrderAddMembership(-1, 0, 2, 0, "02110"));
    //   }
    // } else {
    //   //quitar la membresia
    //   //debugger;
    //   const parameters = {
    //     idPedidoDet: lineInOrderMembership.idpedidodet,
    //     idpromo: 0,
    //     itemCode: lineInOrderMembership.codart,
    //     opc: 4,
    //     pedidosD: [],
    //     quantity: 0,
    //     type: lineInOrderMembership.tipolinea,
    //     withAssign: 0,
    //   };
    //   dispatch(requestCarCurrentOrder(parameters));
    // }
    props.setTypePlan(event.target.value);
  };

  const handleAddProduct = () => {
    //revisar qsi ya es Cliente prefrido o no
    //revisar si escojio el suscribe
    //revisar si no lleva membresia
    //agregar membresia mas el articulo
    // debugger;
    // if ( typeUser !== "2" ) {

    //   const { lines } = appReducers.cart.cartOrder;

    //   let isAddMembership = lines.some(
    //     (item) => item.line.codart === cpMemberships[0].itemCode
    //   );

    // if(typePlan === "desc" && !isAddMembership) {
    //   //si no tiene membresia en la orden debe agregarla
    //   if (cpMemberships.length > 0) {
    //     dispatch(
    //       requestCartOrderAddMembership(-1, 0, 2, 0, cpMemberships[0].itemCode)
    //     );
    //   } else {
    //     dispatch(requestCartOrderAddMembership(-1, 0, 2, 0, "02110"));
    //   }
    // }
    // }
    // debugger;

    if (product.hasOwnProperty("itemCode")) {
      const parameters = {
        idPedidoDet: 1,
        idpromo: 1,
        itemCode: product?.itemCode,
        opc: 0,
        pedidosD: [],
        quantity: numberProduct,
        type: product.typeLine,
        withAssign: 0,
      };
      dispatch(requestCarCurrentOrder(parameters, "add"));
      dispatch(
        requestLoggUserActivity(
          14,
          0,
          0,
          product?.itemCode,
          "Click en Agregar al Carrito"
        )
      );
      setTypeModifyOrderNotification("BAG");
    }
  };

  const handleQtyMinus = () => {
    if (product.hasOwnProperty("itemCode")) {
      const parameters = {
        idPedidoDet: orderDetailId,
        idpromo: 0,
        itemCode: product?.itemCode,
        opc: 5,
        pedidosD: [],
        quantity: -1,
        type: lineTypeOrderDetail,
        withAssign: 0,
      };
      dispatch(requestCarCurrentOrder(parameters, "delete"));
      dispatch(
        requestLoggUserActivity(
          10,
          0,
          0,
          product?.itemCode,
          "Se decremento la cantidad en 1 del producto"
        )
      );
      setTypeModifyOrderNotification("REM");
    }
  };

  const handleQtyAdd = () => {
    if (product.hasOwnProperty("itemCode")) {
      const parameters = {
        idPedidoDet: orderDetailId,
        idpromo: 0,
        itemCode: product?.itemCode,
        opc: 5,
        pedidosD: [],
        quantity: 1,
        type: lineTypeOrderDetail,
        withAssign: 0,
      };
      dispatch(requestCarCurrentOrder(parameters, "add"));
      dispatch(
        requestLoggUserActivity(
          9,
          0,
          0,
          product?.itemCode,
          "Se incrementó la cantidad en 1 del producto"
        )
      );
      setTypeModifyOrderNotification("ADD");
    }
  };

  const handlePreferiCustomer = () => {
    const url = localStorage.getItem("ZUsaUrlPage")
      ? `/favoriteCustomer/${localStorage.getItem("ZUsaUrlPage")}`
      : "/favoriteCustomer";
    history.push(url);
  };
  // const handleRemoveProduct = () => {
  //   //debugger;
  //   setNumberProduct(numberProduct - 1);
  // };

  // const handleGoPErsonalizated = (itemCode) => {
  //   history.push(`/personalized-fragance/it/${itemCode}`);
  // };

  // const handleClickImageSwiper = (item) => {
  //   setImageSwiperActive(item);
  // };

  return (
    <Fragment>
      <div
        id="detail__product"
        className={`firstWrapperInBaseContainer detail__product ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }`}
      >
        {/* {console.log(product)} */}
        {/* <NotificationSnackbar
          isOpenNotification={dataNotification.isOpen}
          setIsOpenNotification={setDataNotification}
          message={dataNotification.message}
          status={dataNotification.type}
          icon={dataNotification.icon}
        /> */}
        <NoticeInStockModal
          isProductInStock={isProductInStock}
          setIsProductInStock={setIsProductInStock}
        />

        <div
          className={`detail__product__container ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          <div
            className={`detail__product__container__inner ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }  `}
          >
            {!isMobile ? (
              <div style={{ display: "flex" }}>
                {product.imagesArticle && product.imagesArticle.length > 0 && (
                  <>
                    {!isLoadingEnable && props.isLanguageLoaded ? (
                      <div className="detail__product__container__inner__imagen__swiper">
                        <Swiper
                          // pagination={{
                          //   clickable: false,
                          // }}
                          className="mySwiper"
                          slidesPerView={4}
                          spaceBetween={4}
                          direction="vertical"
                          modules={[Pagination, Navigation, EffectCoverflow]}
                        >
                          {/* {product.nameArticle} */}
                          {!isLoadingEnable ? (
                            product.imagesArticle.map((item, index) => {
                              return (
                                <SwiperSlide
                                  key={index}
                                  className="swiper__slide__thumbnail"
                                >
                                  <img
                                    alt={`${item.itemCode} `}
                                    title={`${product.nameArticle} `}
                                    src={`${item.url}/${item.fileName}`}
                                    onClick={() => {
                                      //handleClickImageSwiper(item);
                                      setImageSwiperActive(item);
                                    }}
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src =
                                        "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
                                    }}
                                  />
                                </SwiperSlide>
                              );
                            })
                          ) : (
                            <SwiperSlide>
                              <Skeleton
                                variant="rect"
                                width={"100%"}
                                height={350}
                              />
                            </SwiperSlide>
                          )}
                        </Swiper>
                      </div>
                    ) : (
                      <Skeleton
                        variant="rect"
                        width={"60%"}
                        height={100}
                        style={{ margin: "20px 0" }}
                      />
                    )}
                  </>
                )}

                <div
                  className={`detail__product__container__inner__imagen ${
                    product.nameArticle ? "" : "skeletonImageActive"
                  }`}
                >
                  {product?.tagsOffer && product.tagsOffer.length > 0 && (
                    <div className="floating__tags__wrapper">
                      {product.tagsOffer.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="floating__tags__wrapper__item"
                            style={{
                              background: item.hexColor,
                            }}
                          >
                            <span>{item.nameTag}</span>
                          </div>
                        );
                      })}
                    </div>
                  )}

                  {product.stock < 0 && <BlockProduct />}
                  {product.isLabelConfig && (
                    <div
                      className={`product__badge ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }  `}
                    >
                      <CustomerBadge isDetailShow={false} />
                    </div>
                  )}

                  {/* product.nameArticle */}
                  {product.nameArticle ? (
                    // <img
                    //   alt={`${product.nameArticle} `}
                    //   title={`${product.nameArticle} `}
                    //   src={
                    //     product.imagesArticle &&
                    //     product.imagesArticle.length > 0
                    //       ? `${imageSwiperActive.url}/${imageSwiperActive.fileName}`
                    //       : product?.image
                    //   }
                    // />

                    <ReactImageMagnify
                      enlargedImageStyle={{ background: "#fff" }}
                      {...{
                        smallImage: {
                          alt: product.nameArticle,
                          isFluidWidth: false,
                          width: 350,
                          height: 350,

                          src:
                            product.imagesArticle &&
                            product.imagesArticle.length > 0
                              ? `${imageSwiperActive.url}/${imageSwiperActive.fileName}`
                              : product?.image,
                        },
                        largeImage: {
                          src:
                            product.imagesArticle &&
                            product.imagesArticle.length > 0
                              ? `${imageSwiperActive.url}/${imageSwiperActive.fileName}`
                              : product?.image,

                          width: 800,
                          height: 800,
                        },
                        shouldUsePositiveSpaceLens: true,
                      }}
                    />
                  ) : (
                    <Skeleton variant="rect" width={"100%"} height={350} />
                  )}
                </div>
              </div>
            ) : (
              <Swiper
                slidesPerView={1}
                pagination={{
                  clickable: true,
                }}
                centeredSlides={true}
                style={{ marginBottom: 8, width: "100%" }}
                modules={[Pagination, Navigation, EffectCoverflow]}
                // breakpoints={{
                //   // 320: {
                //   //   slidesPerView: 1,
                //   // },
                //   768: {
                //     //slidesPerView: 1,
                //     //centeredSlides: true,
                //     centeredSlidesBounds: true,
                //   },
                //   800: {
                //     //slidesPerView: 1,
                //     //centeredSlides: true,
                //     centeredSlidesBounds: true,
                //   },
                // }}
              >
                {product?.tagsOffer && product.tagsOffer.length > 0 && (
                  <div
                    className={`floating__tags__wrapper ${
                      isMobile || isTablet ? "mobile" : ""
                    }`}
                  >
                    {product.tagsOffer.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="floating__tags__wrapper__item"
                          style={{
                            background: item.hexColor,
                          }}
                        >
                          <span>{item.nameTag}</span>
                        </div>
                      );
                    })}
                  </div>
                )}

                {/* product.nameArticle  */}

                {!isLoadingEnable && props.isLanguageLoaded ? (
                  product.imagesArticle && product.imagesArticle.length > 0 ? (
                    product?.imagesArticle?.map((item, index) => {
                      return (
                        <SwiperSlide className="swiper__slide__mobile">
                          {product.stock < 0 && <BlockProduct />}
                          {product.isLabelConfig && (
                            <div
                              className={`product__badge ${
                                isTablet ? "tablet" : isMobile ? "mobile" : ""
                              }  `}
                            >
                              <CustomerBadge isDetailShow={false} />
                            </div>
                          )}
                          <img
                            alt={`${product.nameArticle} `}
                            title={`${product.nameArticle} `}
                            //src={product?.image}
                            src={`${item.url}/${item.fileName}`}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src =
                                "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
                            }}
                          />
                        </SwiperSlide>
                      );
                    })
                  ) : (
                    <SwiperSlide className="swiper__slide__mobile">
                      {product.stock < 0 && <BlockProduct />}
                      {product.isLabelConfig && (
                        <div
                          className={`product__badge ${
                            isTablet ? "tablet" : isMobile ? "mobile" : ""
                          }  `}
                        >
                          <CustomerBadge isDetailShow={false} />
                        </div>
                      )}
                      <img
                        alt={`${product.nameArticle} `}
                        title={`${product.nameArticle} `}
                        //src={product?.image}
                        src={product?.image}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src =
                            "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
                        }}
                      />
                    </SwiperSlide>
                  )
                ) : (
                  <SwiperSlide>
                    <Skeleton
                      variant="rect"
                      width={"90%"}
                      height={400}
                      style={{ marginLeft: "15px", marginBottom: "10px;" }}
                    />
                  </SwiperSlide>
                )}
              </Swiper>
            )}

            <div
              className={`detail__product__container__inner__information ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }  `}
            >
              <div
                className={`detail__info ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }  `}
              >
                <div
                  className={`detail__info__description ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  }  `}
                >
                  {/* product?.nameArticle */}
                  {!isLoadingEnable && props.isLanguageLoaded ? (
                    <span>
                      {product?.nameArticle
                        ? truncateText(
                            capitalizeText(product?.nameArticle),
                            100
                          )
                        : ""}
                    </span>
                  ) : (
                    <Skeleton variant="rect" width={"95%"} height={50} />
                  )}
                  {/* product?.nameArticle */}
                  {!isLoadingEnable && props.isLanguageLoaded ? (
                    <span>
                      {product.regularPrice &&
                      product.regularPrice > product.promotionPrice &&
                      (product?.typeLine === "PD" ||
                        product?.typeLine === "P" ||
                        product?.typeLine === "FV" ||
                        product?.typeLine === "VV" ||
                        product?.typeLine === "VF" ||
                        product?.typeLine === "3x2") ? (
                        <Fragment>
                          <div className="product__price__with__discount__wrapper">
                            <em className="product__price__promo__normal left__aligned">
                              {formatPrice(product?.regularPrice)}
                            </em>
                            {/* <em className="product__price__promo left__aligned">
                            {formatPrice(product?.promotionPrice)}
                          </em> */}
                            <div className="product__price__with__discount__wrapper__inner">
                              <span>{` ${percentageUtility(
                                Math.floor(
                                  100 -
                                    (product.promotionPrice * 100) /
                                      product.regularPrice
                                )
                              )}`}</span>
                              <DiscountIcon />
                            </div>
                          </div>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <em className="product__price__normal left__aligned">
                            {formatPrice(product?.regularPrice)}
                          </em>
                        </Fragment>
                      )}
                    </span>
                  ) : (
                    <Skeleton variant="rect" width={"95%"} height={50} />
                  )}
                </div>
                <div className="detail__info__fav">
                  {showFavoriteIcon && (
                    <div className="detail__favorite">
                      {!isLoadingEnable && props.isLanguageLoaded ? (
                        <FormControlLabel
                          onChange={handleChangeFavorite}
                          control={
                            <Checkbox
                              icon={<HeartOutlineIcon />}
                              checkedIcon={<HeartFillIcon />}
                            />
                          }
                        />
                      ) : (
                        <Skeleton width={"100%"} height={50} />
                      )}
                    </div>
                  )}
                  {/* <div className="detail__ranking">
                    {!isLoadingEnable ? (
                      <>
                        {" "}
                        <Rating
                          name="simple-controlled"
                          size="small"
                          value={ratingProduct}
                          emptyIcon={<StarBorder fontSize="inherit" />}
                          // onChange={(event, newValue) => {
                          //   setValue(newValue);
                          // }}
                          readOnly
                        />
                        <span className="detail__ranking__number">
                          {ratingProduct}
                        </span>
                      </>
                    ) : (
                      <Skeleton variant="rect" height={50} width={"100%"} />
                    )}
                  </div> */}
                </div>
              </div>

              <div className="second__price__and__rating">
                {!isLoadingEnable && props.isLanguageLoaded && (
                  <Fragment>
                    {(product?.typeLine === "PD" ||
                      product?.typeLine === "P" ||
                      product?.typeLine === "FV" ||
                      product?.typeLine === "VV" ||
                      product?.typeLine === "VF" ||
                      product?.typeLine === "3x2") && (
                      <em className="product__price__promo left__aligned">
                        {formatPrice(product?.promotionPrice)}
                      </em>
                    )}
                  </Fragment>
                )}

                <div className="detail__ranking">
                  {!isLoadingEnable && props.isLanguageLoaded ? (
                    <>
                      <Rating
                        name="simple-controlled"
                        size="small"
                        value={ratingProduct}
                        emptyIcon={<StarBorder fontSize="inherit" />}
                        // onChange={(event, newValue) => {
                        //   setValue(newValue);
                        // }}
                        readOnly
                      />
                      <span className="detail__ranking__number">
                        {ratingProduct}
                      </span>
                    </>
                  ) : (
                    <Skeleton variant="rect" height={50} width={"100%"} />
                  )}
                </div>
              </div>

              {product?.idFamily > 0 && (
                <div className="detail__variants">
                  {!isLoadingEnable && props.isLanguageLoaded ? (
                    <VariantDetailComponent
                      isColor={product.valueType === 1}
                      isSkuVariant={product.valueType === 2}
                      arrayFake={product.familyProducts}
                    />
                  ) : (
                    <Skeleton variant="rect" height={100} width={"100%"} />
                  )}
                </div>
              )}

              <div className="detail__product__container__inner__tabs__prices">
                {!isLoadingEnable && props.isLanguageLoaded ? (
                  <div className="tabs__prices">
                    <div
                      className={`tabs__price__normal ${
                        props.typePlan === "regular" ? "Active" : "Desactive"
                      }`}
                    >
                      <div className="tabs__price__normal__inner">
                        <input
                          type="radio"
                          checked={props.typePlan === "regular"}
                          onChange={(event) => {
                            if (typeUser !== "2") {
                              handleChangeTypePlan(event);
                            }
                          }}
                          value="regular"
                          id="regular"
                        />
                        <label htmlFor="regular">
                          {t("__product_Detail_Regular_Price__label__text")}
                        </label>
                      </div>
                    </div>

                    <div
                      className={`tabs__price__special ${
                        props.typePlan === "desc" ? "Active" : "Desactive"
                      }`}
                    >
                      <div>
                        <input
                          type="radio"
                          checked={props.typePlan === "desc"}
                          onChange={handleChangeTypePlan}
                          value="desc"
                          id="desc"
                        />
                        <label htmlFor="desc">
                          {" "}
                          {t(
                            "__product_Detail_Special_Price__label__text"
                          )}{" "}
                          <span className="text__special text__right">
                            - 20%
                          </span>
                          {t(
                            "__product_Detail_Special_Price__Two__label__text"
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Skeleton variant="rect" width={"100%"} height={100} />
                )}

                <div className="tabs__container">
                  {!isLoadingEnable && props.isLanguageLoaded ? (
                    <>
                      {props.typePlan === "desc" &&
                      !isLoadingEnable &&
                      props.isLanguageLoaded ? (
                        <>
                          <div className="tabs__container__description">
                            {t("__product_Detail_Special_Tab_One_label__text")}{" "}
                            <Link
                              className="text__with__pointer"
                              onClick={handlePreferiCustomer}
                            >
                              <span className="text__special">
                                {t(
                                  "__product_Detail_Special_Tab_Two_label__text"
                                )}
                              </span>
                            </Link>
                            {t(
                              "__product_Detail_Special_Tab_Three_label__text"
                            )}
                            <br />
                            <div className="warning__lock">
                              {/* mensaje dinamico */}
                              {/* Obten este kit por $50 compra minima para
                              Configurar */}
                              {typeMessage === 1 &&
                                t(
                                  "__message_products_minimum_amount_valid_label_p1"
                                ) +
                                  valueMessage +
                                  t(
                                    "__message_products_minimum_amount_valid_label_p2"
                                  )}
                              <Link
                                onClick={() => {
                                  history.push("/categories");
                                }}
                                className="text__with__pointer"
                              >
                                <span> Lo quiero...</span>
                              </Link>
                            </div>
                          </div>
                          {/* <div className="tabs__container__action"> */}
                          {/* <span className="product__price__promo">
                              {formatPrice(
                                product.regularPrice -
                                  (product.regularPrice * 20) / 100
                              )}
                            </span> */}

                          {/* <div>
                              {product.typeLine === "FV" ||
                                product.typeLine === "VV" ||
                                (product.typeLine === "VF" && (
                                  <ButtonComponent
                                    countButton={false}
                                    handleActionPrimary={() => {}}
                                    handleActionSecondary={() => {}}
                                    valueCount=""
                                    iconClass="bagWhiteIcon"
                                    textButton={t("btn_cart_set_up_new")}
                                    leftAling={true}
                                  />
                                ))}
                              {product.isLabelConfig &&
                                product.typeLine === "N" && (
                                  <ButtonComponent
                                    countButton={false}
                                    handleActionPrimary={() => {
                                      handleGoPErsonalizated(product.itemCode)
                                    }}
                                    handleActionSecondary={() => {}}
                                    valueCount={numberProduct}
                                    iconClass="bagWhiteIcon"
                                    textButton={t("btn_customer_new")}
                                    leftAling={true}
                                    ///Personalizar
                                  />
                                )}

                              {product.typeLine === "PD" ||
                                product.typeLine === "N" ||
                                (product.typeLine === "P" && (
                                  <ButtonComponent
                                    countButton={true}
                                    handleActionPrimary={handleRemoveProduct}
                                    handleActionSecondary={handleAddProduct}
                                    valueCount={numberProduct}
                                    iconClass="bagSmallActiveIcon"
                                    textButton=""
                                    leftAling={true}
                                  />
                                ))}
                            </div> */}
                          {/* </div> */}
                        </>
                      ) : (
                        <>
                          <div className="tabs__container__description">
                            {t("__product_Detail_Regular_Tab__label__text")}
                            <br /> {/* mensaje dinamico */}
                            <div className="warning__lock">
                              {
                                typeMessage === 1 &&
                                  //t("__message_products_minimum_amount_valid_label_p1")
                                  "OBTENLO POR $" +
                                    valueMessage +
                                    " DE COMPRA MINIMA EN CUALQUIERA DE NUESTROS PRODUCTOS. "
                                //t("__message_products_minimum_amount_valid_label_p2")
                              }
                              {
                                typeMessage === 2 &&
                                  //t("__message_products_maximum_quantity_valid_label_p1")
                                  "LO SENTIMOS. SOLO PUEDES ADQUIRIR " +
                                    valueMessage +
                                    " PIEZAS DE ESTE ARTICULO. "
                                //t("__message_products_maximum_quantity_valid_label_p2")
                              }
                              <Link
                                onClick={() => {
                                  history.push("/categories");
                                }}
                                className="text__with__pointer"
                              >
                                {typeMessage > 0 && (
                                  <span>IR A PRODUCTOS...</span>
                                )}
                              </Link>
                            </div>
                          </div>
                          {/* <div className="tabs__container__action">
                            <span className="product__price__promo">
                              {formatPrice(product.regularPrice)}
                            </span>
                          </div> */}
                        </>
                      )}

                      {/* MODIFICADO */}
                      <div className="tabs__container__action">
                        <span className="product__price__promo">
                          {!isLoadingEnable &&
                          props.isLanguageLoaded &&
                          props.typePlan === "desc"
                            ? formatPrice(
                                product?.typeLine !== "PD" &&
                                  product?.typeLine !== "3x2"
                                  ? product.regularPrice -
                                      (product.regularPrice * 20) / 100
                                  : product.promotionPrice -
                                      (product.promotionPrice * 20) / 100
                              )
                            : formatPrice(
                                product?.typeLine !== "PD" &&
                                  product?.typeLine !== "3x2"
                                  ? product.regularPrice
                                  : product.promotionPrice
                              )}
                        </span>
                        {product.typeLine !== "P" &&
                          product.typeLine !== "PD" &&
                          product.typeLine !== "N" &&
                          !product.isLabelConfig &&
                          product.stock > 0 && (
                            <ButtonComponent
                              countButton={false}
                              handleActionPrimary={() => {
                                ////regla de cantidad Maxima de Sets
                                if (
                                  product.maxSets > 0 &&
                                  infoCartOrder.lines &&
                                  infoCartOrder.lines.reduce(
                                    (accumulator, object) =>
                                      object.line.codart === product.itemCode
                                        ? accumulator + object.line.qtyped
                                        : accumulator,
                                    0
                                  ) >= product.maxSets
                                ) {
                                  //notificar
                                  dispatch(
                                    requestLoggUserActivity(
                                      14,
                                      product?.subCat3 > 0
                                        ? product?.subCat3
                                        : product?.subCat2 > 0
                                        ? product?.subCat2
                                        : product?.subCat1 > 0
                                        ? product?.subCat1
                                        : product?.idCatProductsCategoriesType,
                                      product?.idCatProductsCategoriesType,
                                      product?.itemCode,
                                      "Click en Agregar al Carrito. Se reedirecciona a categorias por regla de Monto cantidad Maxima de Sets "
                                    )
                                  );
                                  enqueueSnackbar(
                                    t("__general_warning_information_cant_max"),
                                    {
                                      variant: "warning",
                                    }
                                  );
                                } else {
                                  history.push(
                                    `/kit-config/${product.itemCode}/${product.typeLine}`
                                  );
                                }
                              }}
                              handleActionSecondary={() => {}}
                              valueCount=""
                              iconClass="bagWhiteIcon"
                              textButton={t("btn_cart_set_up_new")}
                              leftAling={true}
                            />
                          )}

                        {!isAddBag &&
                        (product.typeLine === "N" ||
                          product.typeLine === "PD" ||
                          product.typeLine === "P") &&
                        product.stock > 0 &&
                        !product.isLabelConfig ? (
                          <>
                            <ButtonComponent
                              countButton={false}
                              handleActionPrimary={() => {
                                ////regla de cantidad Maxima de Sets
                                if (
                                  product.maxSets > 0 &&
                                  infoCartOrder.lines &&
                                  infoCartOrder.lines.reduce(
                                    (accumulator, object) =>
                                      object.line.codart === product.itemCode
                                        ? accumulator + object.line.qtyped
                                        : accumulator,
                                    0
                                  ) >= product.maxSets
                                ) {
                                  //notificar
                                  dispatch(
                                    requestLoggUserActivity(
                                      14,
                                      product?.subCat3 > 0
                                        ? product?.subCat3
                                        : product?.subCat2 > 0
                                        ? product?.subCat2
                                        : product?.subCat1 > 0
                                        ? product?.subCat1
                                        : product?.idCatProductsCategoriesType,
                                      product?.idCatProductsCategoriesType,
                                      product?.itemCode,
                                      "Click en Agregar al Carrito. Se reedirecciona a categorias por regla de Monto cantidad Maxima de Sets "
                                    )
                                  );
                                  enqueueSnackbar(
                                    t("__general_warning_information_cant_max"),
                                    {
                                      variant: "warning",
                                    }
                                  );
                                } else {
                                  handleAddProduct();
                                }
                              }}
                              valueCount=""
                              iconClass="bagWhiteIcon"
                              textButton={t("btn_cart_add_new")}
                              leftAling={true}
                            />
                          </>
                        ) : (
                          !isAddBag &&
                          product.isLabelConfig &&
                          product.stock > 0 &&
                          (product.typeLine === "N" ||
                            product.typeLine === "PD" ||
                            product.typeLine === "P") && (
                            <>
                              <ButtonComponent
                                countButton={false}
                                handleActionPrimary={() => {
                                  ////regla de cantidad Maxima de Sets
                                  if (
                                    product.maxSets > 0 &&
                                    infoCartOrder.lines &&
                                    infoCartOrder.lines.reduce(
                                      (accumulator, object) =>
                                        object.line.codart === product.itemCode
                                          ? accumulator + object.line.qtyped
                                          : accumulator,
                                      0
                                    ) >= product.maxSets
                                  ) {
                                    //notificar
                                    dispatch(
                                      requestLoggUserActivity(
                                        14,
                                        product?.subCat3 > 0
                                          ? product?.subCat3
                                          : product?.subCat2 > 0
                                          ? product?.subCat2
                                          : product?.subCat1 > 0
                                          ? product?.subCat1
                                          : product?.idCatProductsCategoriesType,
                                        product?.idCatProductsCategoriesType,
                                        product?.itemCode,
                                        "Click en Agregar al Carrito. Se reedirecciona a categorias por regla de Monto cantidad Maxima de Sets "
                                      )
                                    );
                                    enqueueSnackbar(
                                      t(
                                        "__general_warning_information_cant_max"
                                      ),
                                      {
                                        variant: "warning",
                                      }
                                    );
                                  } else {
                                    history.push(
                                      `/personalized-fragance/it/${product.itemCode}`
                                    );
                                  }
                                }}
                                valueCount=""
                                iconClass="bagWhiteIcon"
                                textButton={t("btn_customer_new")}
                                leftAling={true}
                                ///Personalizar
                              />
                            </>
                          )
                        )}

                        {isAddBag &&
                        (product.typeLine === "N" ||
                          product.typeLine === "PD" ||
                          product.typeLine === "P") &&
                        product.stock > 0 &&
                        !product.isLabelConfig ? (
                          <>
                            <ButtonComponent
                              countButton={true}
                              handleActionPrimary={handleQtyMinus}
                              handleActionSecondary={() => {
                                ////regla de cantidad Maxima de Sets
                                if (
                                  product.maxSets > 0 &&
                                  infoCartOrder.lines &&
                                  infoCartOrder.lines.reduce(
                                    (accumulator, object) =>
                                      object.line.codart === product.itemCode
                                        ? accumulator + object.line.qtyped
                                        : accumulator,
                                    0
                                  ) >= product.maxSets
                                ) {
                                  //notificar
                                  dispatch(
                                    requestLoggUserActivity(
                                      14,
                                      product?.subCat3 > 0
                                        ? product?.subCat3
                                        : product?.subCat2 > 0
                                        ? product?.subCat2
                                        : product?.subCat1 > 0
                                        ? product?.subCat1
                                        : product?.idCatProductsCategoriesType,
                                      product?.idCatProductsCategoriesType,
                                      product?.itemCode,
                                      "Click en Agregar al Carrito. Se reedirecciona a categorias por regla de Monto cantidad Maxima de Sets "
                                    )
                                  );
                                  enqueueSnackbar(
                                    t("__general_warning_information_cant_max"),
                                    {
                                      variant: "warning",
                                    }
                                  );
                                } else {
                                  handleQtyAdd();
                                }
                              }}
                              valueCount={numberProduct}
                              iconClass="bagWhiteIcon"
                              textButton=""
                              leftAling={true}
                            />
                          </>
                        ) : (
                          isAddBag &&
                          product.isLabelConfig &&
                          product.stock > 0 &&
                          (product.typeLine === "N" ||
                            product.typeLine === "PD" ||
                            product.typeLine === "P") && (
                            <>
                              <ButtonComponent
                                countButton={false}
                                handleActionPrimary={() => {
                                  ////regla de cantidad Maxima de Sets
                                  if (
                                    product.maxSets > 0 &&
                                    infoCartOrder.lines &&
                                    infoCartOrder.lines.reduce(
                                      (accumulator, object) =>
                                        object.line.codart === product.itemCode
                                          ? accumulator + object.line.qtyped
                                          : accumulator,
                                      0
                                    ) >= product.maxSets
                                  ) {
                                    //notificar
                                    dispatch(
                                      requestLoggUserActivity(
                                        14,
                                        product?.subCat3 > 0
                                          ? product?.subCat3
                                          : product?.subCat2 > 0
                                          ? product?.subCat2
                                          : product?.subCat1 > 0
                                          ? product?.subCat1
                                          : product?.idCatProductsCategoriesType,
                                        product?.idCatProductsCategoriesType,
                                        product?.itemCode,
                                        "Click en Agregar al Carrito. Se reedirecciona a categorias por regla de Monto cantidad Maxima de Sets "
                                      )
                                    );
                                    enqueueSnackbar(
                                      t(
                                        "__general_warning_information_cant_max"
                                      ),
                                      {
                                        variant: "warning",
                                      }
                                    );
                                  } else {
                                    history.push(
                                      `/personalized-fragance/it/${product.itemCode}`
                                    );
                                  }
                                }}
                                handleActionSecondary={() => {}}
                                valueCount=""
                                iconClass="bagWhiteIcon"
                                textButton={t("btn_customer_new")}
                                leftAling={true}
                                ///Personalizar
                              />
                            </>
                          )
                        )}
                      </div>

                      {/* END MODIFICADO */}
                    </>
                  ) : (
                    <Skeleton variant="rect" width={"90%"} height={20} />
                  )}
                </div>
              </div>

              {/* AQUI ESTABA EL BOTON */}
            </div>
          </div>
          <div className="detail__product__container__inner__detail__info">
            <div className="product__description__info">
              <span>
                {/* product?.descArticle */}
                {!isLoadingEnable && props.isLanguageLoaded ? (
                  t("__product_Detail_Module_Tabs_Description")
                ) : (
                  <Skeleton variant="rect" width={"50%"} height={40} />
                )}{" "}
              </span>
              <div>
                {!isLoadingEnable && props.isLanguageLoaded ? (
                  product?.descArticle
                ) : (
                  <Skeleton variant="rect" width={"90%"} height={80} />
                )}{" "}
              </div>
            </div>
            {/* product?.modeArticle ? */}
            <div className="product__use__info">
              <span>
                {!isLoadingEnable && props.isLanguageLoaded ? (
                  t("__product_Detail_Module_Tabs_Use")
                ) : (
                  <Skeleton variant="rect" width={"50%"} height={40} />
                )}{" "}
              </span>
              <div>
                {!isLoadingEnable && props.isLanguageLoaded ? (
                  product?.modeArticle
                ) : (
                  <Skeleton variant="rect" width={"90%"} height={80} />
                )}{" "}
              </div>
            </div>
            {/* product?.ingArticle */}
            <div className="product__ingredients__info">
              <span>
                {!isLoadingEnable && props.isLanguageLoaded ? (
                  t("__product_Detail_Module_Tabs_Ingredients")
                ) : (
                  <Skeleton variant="rect" width={"50%"} height={40} />
                )}{" "}
              </span>
              <div>
                {!isLoadingEnable && props.isLanguageLoaded ? (
                  product?.ingArticle
                ) : (
                  <Skeleton variant="rect" width={"90%"} height={80} />
                )}{" "}
              </div>
            </div>
          </div>
          <div className="detail__product__container__carousel">
            {product?.relatedProducts?.length > 0 &&
            !isLoadingEnable &&
            props.isLanguageLoaded ? (
              <ProductsSections
                t={t}
                backgroundClass="LeftGray"
                titleSection={t(
                  "__product_Detail_Module_List_Suggestions_Title"
                )}
                ListProducts={product?.relatedProducts}
                links={""}
                isLoadingEnable={false}
                clientFavorites={props.clientFavorites}
                isLanguageLoaded={props.isLanguageLoaded}
                enqueueSnackbar={enqueueSnackbar}
              />
            ) : product?.relatedProducts?.length === 0 &&
              !isLoadingEnable &&
              props.isLanguageLoaded ? (
              <Fragment></Fragment>
            ) : (
              <Skeleton
                variant="rect"
                width={"95%"}
                height={200}
                style={{ margin: "20px 0" }}
              />
            )}
            {product?.alsoBought?.length > 0 &&
            !isLoadingEnable &&
            props.isLanguageLoaded ? (
              <ProductsSections
                t={t}
                backgroundClass="RightGray"
                titleSection={t(
                  "__product_Detail_Module_List_Other_Products_Title"
                )}
                ListProducts={product?.alsoBought}
                links={""}
                isLoadingEnable={false}
                clientFavorites={props.clientFavorites}
                isLanguageLoaded={props.isLanguageLoaded}
                enqueueSnackbar={enqueueSnackbar}
              />
            ) : product?.alsoBought?.length === 0 &&
              !isLoadingEnable &&
              props.isLanguageLoaded ? (
              <Fragment></Fragment>
            ) : (
              <Skeleton
                variant="rect"
                width={"95%"}
                height={200}
                style={{ margin: "20px 0" }}
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(DetailProductContainer);
