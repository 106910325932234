import React, { Fragment } from "react";

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import { Room, Close } from "@mui/icons-material";
import ExclamationIcon from "../../../../assets/media/images/exclamation.svg";
import { useTranslation } from "react-i18next";

const ModalValidationAdress = ({
  isOpenValidationModal,
  setIsOpenValidationModal,
  addressListSugestion,
  handlerSelectAddressFromSugestion,
}) => {
  /// TRANSLATION
  const { t } = useTranslation();

  const handleCloseValidationModal = (o, reason) => {
    if (reason !== "backdropClick") setIsOpenValidationModal(false);
  };
  return (
    <Fragment>
      <Dialog
        disableEscapeKeyDown
        className="modal__validation"
        open={isOpenValidationModal}
        onClose={handleCloseValidationModal}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle
          className="modal__validation__icon__close"
          id="alert-dialog-title"
        >
          <IconButton
            onClick={handleCloseValidationModal}
            aria-label="close"
            color="primary"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogTitle
          className="modal__validation__title__modal"
          id="alert-dialog-title"
        >
          <Paper className="modal__validation__title">
            <div className="modal__validation__title__wrapper">
              <div className="modal__validation__title__wrapper__left">
                <Typography variant="subtitle1" gutterBottom>
                  {t("__modal_validation_address_title")}
                </Typography>
              </div>
              <div className="modal__validation__title__wrapper__rigth">
                <img src={ExclamationIcon} alt="exclamacion" />
              </div>
            </div>
          </Paper>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="subtitle1" gutterBottom>
              {t("__modal_validation_address_select_title")}
            </Typography>

            <div className="modal__validation__list__addresses">
              <List component="nav" aria-label="main mailbox folders">
                {addressListSugestion &&
                  addressListSugestion.map((item, index) => (
                    <Fragment key={index}>
                      <ListItem
                        button
                        onClick={() =>
                          handlerSelectAddressFromSugestion(
                            item.address1,
                            item.suite,
                            item.state,
                            item.city,
                            item.zip
                          )
                        }
                      >
                        <ListItemIcon>
                          <Room />
                        </ListItemIcon>
                        <ListItemText
                          primary={`${item.address1} ${item.suite}`}
                          secondary={`${item.city}, ${item.state} ${item.zip}`}
                        />
                      </ListItem>
                      <Divider />
                    </Fragment>
                  ))}
              </List>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default ModalValidationAdress;
