import React, { Fragment } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

export const ModalProductBanch = ({
  isOpenProductBatch,
  setIsOpenProductBatch,
}) => {
  /// TRANSLATION
  const { t } = useTranslation();
  //// hOOKS
  const handleCloseModalProductBatch = () => {
    setIsOpenProductBatch(false);
  };

  return (
    <Fragment>
      <Dialog
        className="modal__confirm__repayment__content"
        open={isOpenProductBatch}
        onClose={handleCloseModalProductBatch}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="modal__confirm__repayment__content__title"
        >
          <IconButton onClick={handleCloseModalProductBatch} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <div className="modal__confirm__repayment__content__icon">
            <div className="product__batch__icon" />
          </div>
          <DialogContentText className="modal__confirm__repayment__content__text">
            <Typography variant="h6" gutterBottom>
              {t("__modal__product__batch__title_label")}
            </Typography>
            <Typography variant="body2" component={"p"} gutterBottom>
              {t("__modal__product__batch__text1_label")}
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
