import {
  CLEAN_CART_REDUCER_COMPLETE,
  CLEAN_REQUEST_CLOSE_ORDER,
  REQUEST_CART_ADD_MEMBERSHIP,
  REQUEST_CART_CANCEL_ORDER,
  REQUEST_CART_COUPON_LIST,
  REQUEST_CART_CURRENT_ORDER,
  REQUEST_CART_TAX_ORDER,
  REQUEST_CART_GET_CLOSED_ORDER,
  REQUEST_CART_GET_ORDER,
  REQUEST_CART_ORDER_CHARGES,
  REQUEST_CLOSE_CART_ORDER,
  REQUEST_CART_INVENTORY_ASSYGN,
  CLEAN_CART_ORDER_TAX_CALCULATION,
  CLEAN_CART_ORDER_INVENTORY_ASSIGN,
  CLEAN_CART_ORDER_ADD_MEMBERSHIP,
  CLEAN_CART_CANCEL_ORDER,
  CLEAN_NEW_MEMBERS_REDUCER,
  CLEAN_RESPONSE_CART_ORDER_CHARGES_REDUCER,
  REQUEST_CLOSE_CART_ORDER_GUEST,
  REQUEST_CART_GET_GIFT_LIST,
  CLEAN_CART_GET_GIFT_LIST,
  REQUEST_CART_ADD_CONFIGURABLE_GIFT,
  CLEAN_CART_ADD_CONFIGURABLE_GIFT,
  REQUEST_CART_ORDER_CLOSE_INFO_CLI,
  CLEAN_CART_ORDER_CLOSE_INFO_CLI,
  CLEAN_CART_ORDER_OPERATION,
  CLEAN_CART_CLOSED_ORDER,
} from "./../../Constants";

export const requestCarCurrentOrder = (
  parameters,
  opType = "add",
  labels = []
) => {
  /// consulta a tocayo si asi lo dejamos por sentry
  const isExits = JSON.parse(localStorage.getItem("ZUsaUInfo"));
  if (isExits) {
    const userId = JSON.parse(localStorage.getItem("ZUsaUInfo")).idGuestUser;
    const sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
    parameters.userId = userId;
    parameters.sessionId = sessionId;
    parameters.labels = labels;
    if (parameters?.pedidosD.length > 0) {
      parameters.pedidosD = parameters.pedidosD.map((item) => {
        delete item?.isHeader;
        return item;
      });
    }
    return {
      type: REQUEST_CART_CURRENT_ORDER,
      payload: {
        parameters,
        opType,
      },
    };
  } else {
    return {
      type: "",
      payload: {},
    };
  }
};
export const requestCarCalculateOrderTax = (parameters) => {
  const userId = JSON.parse(localStorage.getItem("ZUsaUInfo")).idGuestUser;
  const sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
  parameters.labels = [];
  parameters.userId = userId;
  parameters.sessionId = sessionId;
  return {
    type: REQUEST_CART_TAX_ORDER,
    payload: {
      parameters,
    },
  };
};
export const requestCartAssignInventory = (parameters) => {
  const userId = JSON.parse(localStorage.getItem("ZUsaUInfo")).idGuestUser;
  const sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
  parameters.labels = [];
  parameters.userId = userId;
  parameters.sessionId = sessionId;
  return {
    type: REQUEST_CART_INVENTORY_ASSYGN,
    payload: {
      parameters,
    },
  };
};
export const cleanCartInventoryAssingReducer = () => {
  return {
    type: CLEAN_CART_ORDER_INVENTORY_ASSIGN,
    payload: {},
  };
};
export const cleanReducerOrderTaxesCalculation = () => {
  return {
    type: CLEAN_CART_ORDER_TAX_CALCULATION,
    payload: {},
  };
};

export const requestGetCartOrder = () => {
  let userId = "";
  let sessionId = 0;

  if (localStorage.getItem("ZUsaUGuestId")) {
    userId = localStorage.getItem("ZUsaUGuestId");
  }
  if (localStorage.getItem("ZUsaUInfo")) {
    sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
    userId = JSON.parse(localStorage.getItem("ZUsaUInfo")).idGuestUser;
  }
  //const userId = JSON.parse(localStorage.getItem("ZUsaUInfo")).idGuestUser
  //const sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId
  return {
    type: REQUEST_CART_GET_ORDER,
    payload: {
      userId,
      sessionId,
    },
  };
};

export const requestGetCartCouponList = () => {
  const sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
  return {
    type: REQUEST_CART_COUPON_LIST,
    payload: {
      sessionId,
    },
  };
};
export const requestCartCancelOrder = () => {
  const sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
  return {
    type: REQUEST_CART_CANCEL_ORDER,
    payload: {
      sessionId,
    },
  };
};
export const requestCartOrderCharges = (opc, orderId) => {
  return {
    type: REQUEST_CART_ORDER_CHARGES,
    payload: {
      opc,
      orderId,
    },
  };
};
export const requestCartOrderAddMembership = (
  idPedidoDet,
  idpromo,
  planType,
  withAssign,
  itemCode
) => {
  const sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
  const userGuid = JSON.parse(localStorage.getItem("ZUsaUInfo")).idGuestUser;
  return {
    type: REQUEST_CART_ADD_MEMBERSHIP,
    payload: {
      idPedidoDet,
      idpromo,
      planType,
      sessionId,
      userGuid,
      withAssign,
      itemCode,
    },
  };
};
export const requestCloseCartOrder = (benefitType, evaluatePt = true) => {
  const sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
  return {
    type: REQUEST_CLOSE_CART_ORDER,
    payload: {
      sessionId,
      benefitType,
      evaluatePt,
    },
  };
};

export const requestGetCartClosedOrder = (sessionId) => {
  // revisarlo con Hramirez
  const isExits = JSON.parse(localStorage.getItem("ZUsaUInfo"));
  if (isExits) {
    const userId = JSON.parse(localStorage.getItem("ZUsaUInfo")).idGuestUser;
    return {
      type: REQUEST_CART_GET_CLOSED_ORDER,
      payload: {
        userId,
        sessionId,
      },
    };
  } else {
    return {
      type: "",
      payload: {},
    };
  }
};

export const requestCleanCloseOrderReducer = () => {
  return {
    type: CLEAN_REQUEST_CLOSE_ORDER,
    payload: {},
  };
};

export const requestCleanCartReducer = () => {
  return {
    type: CLEAN_CART_REDUCER_COMPLETE,
    payload: {},
  };
};

export const requestCleanCartReducerAddMembership = () => {
  return {
    type: CLEAN_CART_ORDER_ADD_MEMBERSHIP,
    payload: {},
  };
};

export const requestCleanCartReducerCancelOrder = () => {
  return {
    type: CLEAN_CART_CANCEL_ORDER,
    payload: {},
  };
};

export const requestCleanCartNewMembersReducers = () => {
  return {
    type: CLEAN_NEW_MEMBERS_REDUCER,
    payload: {},
  };
};

export const cleanReducerCartOrderCharges = () => {
  return {
    type: CLEAN_RESPONSE_CART_ORDER_CHARGES_REDUCER,
    payload: {},
  };
};

export const requestCloseCartOrderGuest = (benefitType, parameters) => {
  const sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
  return {
    type: REQUEST_CLOSE_CART_ORDER_GUEST,
    payload: {
      sessionId,
      benefitType,
      parameters,
    },
  };
};

export const requestCartGetGiftList = () => {
  const sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
  return {
    type: REQUEST_CART_GET_GIFT_LIST,
    payload: {
      sessionId,
    },
  };
};

export const cleanCartGetGiftList = () => {
  return {
    type: CLEAN_CART_GET_GIFT_LIST,
    payload: {},
  };
};

export const requestCartAddConfigurableGift = (parameters) => {
  const sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
  return {
    type: REQUEST_CART_ADD_CONFIGURABLE_GIFT,
    payload: {
      sessionId,
      parameters,
    },
  };
};

export const cleanCartAddConfigurableGift = () => {
  return {
    type: CLEAN_CART_ADD_CONFIGURABLE_GIFT,
    payload: {},
  };
};

export const requestCartOrderCloseInfoCli = (parameters) => {
  return {
    type: REQUEST_CART_ORDER_CLOSE_INFO_CLI,
    payload: {
      parameters,
    },
  };
};

export const cleanCartOrderCloseInfoCli = () => {
  return {
    type: CLEAN_CART_ORDER_CLOSE_INFO_CLI,
    payload: {},
  };
};

export const cleanCartOrderOperations = () => {
  return {
    type: CLEAN_CART_ORDER_OPERATION,
    payload: {},
  };
};

export const cleanCartClosedOrder = () => {

  console.log("cleanCartClosedOrder desde ACTION")


  return {
    type: CLEAN_CART_CLOSED_ORDER,
    payload: {},
  };
};
