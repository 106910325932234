import React from "react";
//MUI
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { GetPropertyValue } from "./../../../../Libraries/Utils/utils";

const SelectComponent = ({
  labelName,
  listOptions,
  textOption,
  valueOption,
  value,
  onChange = () => {},
  textIsObject = false,
  valueIsObject = false,
  isPersonalized = false,
}) => {
  return (
    <div className="mui__select__wrapper">
      <FormControl variant="standard" fullWidth>
        <InputLabel id={`${labelName}-${Math.floor(Math.random() * 100)}`}>
          {labelName}
        </InputLabel>
        <Select
          labelId={`${labelName}-${Math.floor(Math.random() * 100)}`}
          style={{ maxHeight: 36 }}
          //id="select"
          //required
          fullWidth
          //label={t("__checkout_order_steps_pay_register_shipping_state")}
          value={value}
          onChange={onChange}
          //error={shippState.length === 0}
          //className={isShippmentAddressValidated ? "isOK" : ""}
        >
          {listOptions.map((item, index) => {
            if (isPersonalized) {
              const val = valueIsObject
                ? item
                : GetPropertyValue(item, valueOption);
              const txt = textIsObject
                ? item
                : GetPropertyValue(item, textOption);
              return (
                <MenuItem key={index} value={val}>
                  {txt}
                </MenuItem>
              );
            } else {
              return (
                <MenuItem key={index} value={index}>
                  {item.nameOpc}
                </MenuItem>
              );
            }
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectComponent;
