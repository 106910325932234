import React, { useEffect } from "react";
import { Typography, Skeleton } from "@mui/material";
import { isMobile, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";
//// images
import modern from "../../../assets/media/images/aboutUs/QuienesModernaMovil.jpg";
import versatile from "../../../assets/media/images/aboutUs/QuienesVersatilMovil.jpg";

const AboutsUsContainter = ({ isLanguageLoaded }) => {
  /// TRANSLATION
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "Zermat USA :: About Us";
  }, []);

  return (
    <div
      id="firstWrapperInBaseContainer"
      className="firstWrapperInBaseContainer without__paddingTop"
    >
      <div className="about__us__container">
        <div className="about__us__container__header">
          <iframe
            src="https://www.youtube.com/embed/X9HeNdrYqLc"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>

        <div className="about__us__container__zermat">
          <div className="about__us__container__zermat__usa">
            <Typography className="text__red" variant="h6" gutterBottom>
              {isLanguageLoaded ? (
                t("__aboutUs_main_title")
              ) : (
                <Skeleton variant="rect" width={"10%"} height={30} />
              )}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {isLanguageLoaded ? (
                t("__aboutUs_main_history_text1")
              ) : (
                <Skeleton variant="rect" width={"95%"} height={50} />
              )}{" "}
            </Typography>
          </div>
        </div>
      </div>
      <div className="about__us__container__zermat__ads" />

      <div className="full__video__wrapper">
        <iframe
          src="https://www.youtube.com/embed/ijfMtAu67JU?si=FF9iBa2JGFWXPgf3"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen=""
        ></iframe>
      </div>

      {isMobile && (
        <div className="about__us__container__zermat__versatile">
          <div className="about__us__container__zermat__attributes">
            <div className="about__us__container__zermat__attributes__title">
              <Typography variant="h6" gutterBottom>
                <em>
                  {isLanguageLoaded ? (
                    t("__aboutUs_main_attributes_success_title")
                  ) : (
                    <Skeleton variant="rect" width={"97%"} height={30} />
                  )}{" "}
                </em>
              </Typography>
              <Typography variant="body1" gutterBottom>
                {isLanguageLoaded ? (
                  t("__aboutUs_main_attributes_success_description")
                ) : (
                  <Skeleton variant="rect" width={"97%"} height={30} />
                )}
              </Typography>
            </div>
            <div>
              <div className="about__us__container__zermat__attributes__wrapper">
                <div
                  className={`about__us__container__zermat__attributes__wrapper__left`}
                >
                  <img src={modern} alt="honestidad" />
                </div>
                <div className="about__us__container__zermat__attributes__wrapper__right">
                  <div
                    className={`about__us__container__zermat__attributes__wrapper__texts ${
                      isLanguageLoaded ? "" : "skeletonActive"
                    }`}
                  >
                    <Typography variant="body1" gutterBottom>
                      <em>
                        {" "}
                        {isLanguageLoaded ? (
                          t("__aboutUs_main_attributes_modern_title")
                        ) : (
                          <Skeleton
                            variant="rect"
                            width={isTablet ? "300%" : "180%"}
                            height={30}
                          />
                        )}{" "}
                      </em>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {isLanguageLoaded ? (
                        t("__aboutUs_main_attributes_modern_description")
                      ) : (
                        <Skeleton
                          variant="rect"
                          width={isTablet ? "300%" : "180%"}
                          height={30}
                        />
                      )}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <div className="about__us__container__zermat__attributes__wrapper">
              <div
                className={`about__us__container__zermat__attributes__wrapper__left`}
              >
                <div
                  className={`about__us__container__zermat__attributes__wrapper__texts ${
                    isLanguageLoaded ? "" : "skeletonActive"
                  }`}
                >
                  <Typography variant="body1" gutterBottom>
                    <em>
                      {" "}
                      {isLanguageLoaded ? (
                        t("__aboutUs_main_attributes_versatile_title")
                      ) : (
                        <Skeleton
                          variant="rect"
                          width={isTablet ? "300%" : "180%"}
                          height={30}
                        />
                      )}{" "}
                    </em>
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {isLanguageLoaded ? (
                      t("__aboutUs_main_attributes_versatile_description")
                    ) : (
                      <Skeleton
                        variant="rect"
                        width={isTablet ? "300%" : "180%"}
                        height={30}
                      />
                    )}
                  </Typography>
                </div>
              </div>
              <div className="about__us__container__zermat__attributes__wrapper__right">
                <img src={versatile} alt="versatil" />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="about__us__container__party__zermat__wrapper">
        <div
          className={
            isMobile
              ? "about__us__container__party__zermat__wrapper__left__isIOS"
              : "about__us__container__party__zermat__wrapper__left"
          }
        >
          <div className="about__us__container__zermat__ads2" />
          <div className="about__us__container__zermat__mission">
            {isLanguageLoaded ? (
              <Typography variant="body1" gutterBottom>
                <em>{t("__aboutUs_main_mision_title")}</em>{" "}
                {t("__aboutUs_main_mision_description")}
              </Typography>
            ) : (
              <Skeleton variant="rect" width={"95%"} height={30} />
            )}
          </div>
          <div className="about__us__container__zermat__view">
            {isLanguageLoaded ? (
              <Typography variant="body1" gutterBottom>
                <em>{t("__aboutUs_main_vision_title")}</em>{" "}
                {t("__aboutUs_main_vision_description")}
              </Typography>
            ) : (
              <Skeleton variant="rect" width={"95%"} height={50} />
            )}
          </div>
        </div>
        <div className="about__us__container__party__zermat__wrapper__right">
          <div className="about__us__container__zermat__ads3" />
          <div className="about__us__container__zermat__recognitions">
            {isLanguageLoaded ? (
              <>
                <Typography variant="body1" gutterBottom className="text__red">
                  {t("__aboutUs_main_acknowledgment_title")}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {t("__aboutUs_main_acknowledgment1")}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {t("__aboutUs_main_acknowledgment2")}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {t("__aboutUs_main_acknowledgment3")}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {t("__aboutUs_main_acknowledgment4")}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {t("__aboutUs_main_acknowledgment5")}
                </Typography>
              </>
            ) : (
              [0, 1, 2, 3, 4].map((item) => {
                return (
                  <Skeleton
                    key={item}
                    variant="rect"
                    width={"95%"}
                    height={30}
                    style={{ marginBottom: 5 }}
                  />
                );
              })
            )}
          </div>
        </div>
      </div>

      <div className="about__us__container__header">
        <iframe
          src="https://www.youtube.com/embed/3ZJq7Y6lzM4"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </div>
  );
};

export default AboutsUsContainter;
