import React, { useRef, useEffect, Fragment } from "react";
import ReactDOM from "react-dom";
import scriptLoader from "react-async-script-loader";
const client = {
  production:
    "AeRcF0cFJBLxRBJR_eSu9egByLf3UmnkmXDdyA7GVaF_AzL_gf5WdzFu85bvLzPnwSVorqQUnDIU5r3B",
  //sandbox: "AcG7PKq54CVKNt8zliS6_GJpNBWx_vCLh3bUqkAJyYQjkQ6Sl0STgjR10W8DaiHncnWm-yCbvutJ2kDm"
  sandbox:
    "AcG7PKq54CVKNt8zliS6_GJpNBWx_vCLh3bUqkAJyYQjkQ6Sl0STgjR10W8DaiHncnWm-yCbvutJ2kDm",
};
const clientId =
  !process.env.NODE_ENV ||
  process.env.NODE_ENV === "development" ||
  (process.env.NODE_ENV === "production" &&
    (process.env.REACT_APP_STAGE === "dev" ||
      process.env.REACT_APP_STAGE === "qa"))
    ? client.sandbox
    : client.production;
//let ButtonPaypal = null;
function PayPalButton({
  mode,
  createOrder,
  onApprove,
  onCancel,
  onError,
  isScriptLoaded,
  isScriptLoadSucceed,
  userFormData,
  paypalStatusPay,
}) {
  // clientId = mode === 'sandbox' ? client.sandbox : client.production;
  const paypalRef = useRef(null);

  useEffect(() => {
    window.React = React;
    window.ReactDOM = ReactDOM;
  }, []);
  useEffect(() => {
    if (
      isScriptLoaded &&
      isScriptLoadSucceed &&
      paypalStatusPay !== "COMPLETED"
    ) {
      // PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
      //console.log("useDataPay", process.env.REACT_APP_STAGE);
      //debugger
      paypalRef.current.innerHTML = "";
      window.paypal
        .Buttons({
          createOrder,
          onApprove,
          onCancel,
          onError,
        })
        .render(paypalRef.current);
    }
  }, [isScriptLoaded, isScriptLoadSucceed, userFormData]);

  return (
    <Fragment>
      {isScriptLoaded && isScriptLoadSucceed && (
        <div id="paypalDivButton" ref={paypalRef}>
          {/* <PayPalButton
                    createOrder={createOrder}
                    onApprove={onApprove}
                    onCancel={onCancel}
                    onError={onError}
                /> */}
        </div>
      )}
    </Fragment>
  );
}

export default scriptLoader([
  "https://www.paypal.com/sdk/js?client-id=" +
    clientId +
    "&disable-funding=card",
])(PayPalButton);
