import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
//MUI
import { Tooltip } from "@mui/material";
//Utils
import { truncateText } from "../../../../Libraries/Utils/utils";
import { isMobile, isTablet } from "react-device-detect";
import { useEffect } from "react";

const VariantDetailComponent = ({
  isColor,
  isSkuVariant = false,
  arrayFake,
  itemOnClick,
  activeId,
}) => {
  //React Router
  const history = useHistory();
  const location = useLocation();

  //State
  const [activeItemCode, setActiveItemCode] = useState("");

  //Effects
  useEffect(() => {
    //console.log("location VARIANT", location);
    const pathname = location.pathname;
    const pathnameArray = pathname.split("/");
    //console.log("pathnameArray", pathnameArray)
    const itemCode = pathnameArray.at(-2);
    //console.log("itemCode", itemCode)
    if (itemCode) {
      //console.log("itemCode", itemCode, typeof itemCode)
      setActiveItemCode(itemCode);
    }
  }, [location]);

  //Handlers
  const handleClickVariant = (idItem, item) => {
    if (itemOnClick) {
      //Remove "Active" class to all variants and set "Active" to clicked variant
      const variants = document.querySelectorAll(".variant__detail");
      variants.forEach((variant) => {
        //console.log("variant", variant);
        const variantId = variant.id.replace("item_", "");
        if (variantId === idItem.toString()) {
          variant.classList.remove("Desactive");
          variant.classList.add("Active");
        } else {
          variant.classList.remove("Active");
          variant.classList.add("Desactive");
        }
      });

      return itemOnClick(item);
    } else {
      const destination = localStorage.getItem("ZUsaUrlPage")
        ? `/product-detail/${localStorage.getItem("ZUsaUrlPage")}/${
            item.anchorArticle
          }`
        : `/product-detail/${item.anchorArticle}`;

      history.push(destination);
    }
  };

  return (
    <div
      className={`varints__container ${isMobile || isTablet ? "mobile" : ""}`}
    >
      {isColor ? (
        <>
          {arrayFake.map((item, index) => {
            //console.log("item COLOR VARIANT", item);
            return (
              <div
                key={index}
                id={`item_${index}`}
                //className={`variant__detail Desactive`}
                className={`variant__detail ${
                  activeItemCode === item.itemCode ? "Active" : "Desactive"
                }`}
                onClick={() => {
                  handleClickVariant(index, item);
                }}
              >
                <div>
                  <Tooltip title={item.valueDescription} placement="top-end">
                    <div
                      style={{
                        backgroundColor: item.value,
                        borderRadius: 5,
                        border: `solid 1px ${item.value}`,
                        width: 22,
                        height: 25,
                        display: "flex",
                        flexDirection: "column",
                        margin: 2,
                      }}
                    />
                  </Tooltip>
                </div>
                <div>{truncateText(item.valueDescription)}</div>
              </div>
            );
          })}
        </>
      ) : isSkuVariant ? (
        <>
          {arrayFake.map((item, index) => {
            //console.log("item NORMAL VARIANT", item);
            return (
              <div
                key={index}
                id={`item_${index}`}
                //className={`variant__detail Desactive`}
                className={`variant__detail ${
                  activeId
                    ? activeId === item.id
                      ? "Active"
                      : "Desactive"
                    : activeItemCode
                    ? activeItemCode === item.itemCode
                      ? "Active"
                      : "Desactive"
                    : "Desactive"
                }`}
                onClick={() => {
                  handleClickVariant(index, item);
                }}
              >
                <div>
                  {" "}
                  <Tooltip title={item.valueDescription} placement="top-end">
                    <div
                      style={{
                        width: 40,
                        height: 40,
                        display: "flex",
                        flexDirection: "column",
                        marginRight: 2,
                      }}
                    >
                      <img
                        className="product__variants__image"
                        src={item.value}
                        alt=""
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src =
                            "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
                        }}
                      />
                    </div>
                  </Tooltip>
                </div>
                <div>{truncateText(item.valueDescription)}</div>
              </div>
            );
          })}
        </>
      ) : (
        <>
          {arrayFake.map((item, index) => {
            //console.log("item NORMAL VARIANT", item);
            return (
              <div
                key={index}
                id={`item_${index}`}
                //className={`variant__detail Desactive`}
                className={`variant__detail ${
                  activeId
                    ? activeId === item.id
                      ? "Active"
                      : "Desactive"
                    : activeItemCode
                    ? activeItemCode === item.itemCode
                      ? "Active"
                      : "Desactive"
                    : "Desactive"
                }`}
                onClick={() => {
                  handleClickVariant(index, item);
                }}
              >
                <div>
                  {" "}
                  <Tooltip title={item.nameProduct} placement="top-end">
                    <div
                      style={{
                        width: 40,
                        height: 40,
                        display: "flex",
                        flexDirection: "column",
                        marginRight: 2,
                      }}
                    >
                      <img
                        className="product__variants__image"
                        src={item.urlPath}
                        alt=""
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src =
                            "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
                        }}
                      />
                    </div>
                  </Tooltip>
                </div>
                <div>{truncateText(item.nameProduct)}</div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default VariantDetailComponent;
