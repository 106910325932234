import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
//import NotificationSnackbar from "../base/components/NotificationSnackbar";
import { useTranslation } from "react-i18next";
import SendIcon from "@mui/icons-material/Send";
import { useParams, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  regexIsEmailAddressValid,
  regexIsCellphoneValid,
  restrictToOnlyLetters,
  restrictToOnlyLettersForEmail,
  restrictToOnlyNumbers,
} from "./../../../Libraries/Utils/regex";
///ACTIONS
import {
  requestReferrerCellPhoneValidation,
  requestReferrerEmailValidation,
  requestReferrerNewRegistration,
  cleanReferrerReducer,
} from "./../../ReduxSaga/Actions/Referrer";
import TextFieldComponent from "../base/components/TextFieldComponent";
import TextFieldAndButtonComponent from "../base/components/TextFieldAndButtonComponent";

const ReferredContainer = () => {
  //Notistack
  const { enqueueSnackbar } = useSnackbar();

  ///param
  const { referredCode } = useParams();
  let history = useHistory();
  ///REDUX
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.referrer = useSelector((state) => state.referrer);
  ///INITIALSTATE
  const initialFormData = {
    referrerCode: "",
    name: "",
    lastName: "",
    email: "",
    cellPhone: "",
    password: "",
    passwordConfirmation: "",
  };

  // const [isOpenNotification, setIsOpenNotification] = useState(false);
  // const [messageNotification, setmessageNotification] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [isRegisterActive, setIsRegisterActive] = useState(false);
  const [isSharedActive, setIsSharedActive] = useState(false);
  const [formData, setformData] = useState(initialFormData);
  const [isCellPhoneValidated, setIsCellPhoneValidated] = useState(false);
  const [isEmailValidated, setIsEmailValidated] = useState(false);
  /// TRANSLATION
  const { t, i18n } = useTranslation();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  /// useEffect

  useEffect(() => {
    switch (activeStep) {
      case 0:
        setIsRegisterActive(true);
        setIsSharedActive(false);
        break;
      case 1:
        setIsRegisterActive(false);
        setIsSharedActive(true);
        break;
      default:
        setActiveStep(0);
        break;
    }
  }, [activeStep]);

  useEffect(() => {
    if (referredCode) {
      setformData({ ...formData, referrerCode: referredCode });
    }
  }, [referredCode]);

  useEffect(() => {
    if (
      typeof appReducers?.referrer?.responseReferrerCellPhoneValidation !==
      "undefined"
    ) {
      if (
        appReducers.referrer.responseReferrerCellPhoneValidation.code === 100
      ) {
        setIsCellPhoneValidated(true);
        enqueueSnackbar(t("__message_valid_phone_label"), {
          variant: "success",
        });
        // setIsOpenNotification(true);
        // setmessageNotification(t("__message_valid_phone_label"));
      } else {
        setIsCellPhoneValidated(false);
        // setIsOpenNotification(true);
        switch (appReducers.referrer.responseReferrerCellPhoneValidation.code) {
          case -100:
            enqueueSnackbar(t("__message_valid_register_already_phone_label"), {
              variant: "error",
            });
            // setmessageNotification(
            //   t("__message_valid_register_already_phone_label")
            // );
            break;
          case -500:
            enqueueSnackbar(t("__message_error_valid_register_phone_label"), {
              variant: "error",
            });
            // setmessageNotification(
            //   t("__message_error_valid_register_phone_label")
            // );
            break;
          default:
            break;
        }
      }
    }
  }, [appReducers?.referrer?.responseReferrerCellPhoneValidation]);

  useEffect(() => {
    if (
      typeof appReducers?.referrer?.responseReferrerEmailValidation !==
      "undefined"
    ) {
      if (appReducers.referrer.responseReferrerEmailValidation.code === 100) {
        setIsEmailValidated(true);
        enqueueSnackbar(t("__message_valid_email_label"), {
          variant: "success",
        });
        // setIsOpenNotification(true);
        // setmessageNotification(t("__message_valid_email_label"));
      } else {
        setIsEmailValidated(false);
        //setIsOpenNotification(true);
        switch (appReducers.referrer.responseReferrerEmailValidation.code) {
          case -100:
            enqueueSnackbar(t("__message_valid_register_already_email_label"), {
              variant: "error",
            });
            // setmessageNotification(
            //   t("__message_valid_register_already_email_label")
            // );
            break;
          case -200:
            enqueueSnackbar(t("__message_invalid_email_label"), {
              variant: "error",
            });
            // setmessageNotification(t("__message_invalid_email_label"));
            break;
          case -500:
            enqueueSnackbar(t("__message_error_valid_register_email_label"), {
              variant: "error",
            });
            // setmessageNotification(
            //   t("__message_error_valid_register_email_label")
            // );
            break;
          default:
            break;
        }
      }
    }
  }, [appReducers?.referrer?.responseReferrerEmailValidation]);

  useEffect(() => {
    if (typeof appReducers?.referrer?.responseNewRegister !== "undefined") {
      if (appReducers.referrer.responseNewRegister.code === 100) {
        enqueueSnackbar(t("__message_successfully_register_label"), {
          variant: "success",
        });
        // setmessageNotification(t("__message_successfully_register_label"));
        // setIsOpenNotification(true);
        dispatch(cleanReferrerReducer());
        setActiveStep(0);
        setformData({
          referrerCode: "",
          name: "",
          lastName: "",
          email: "",
          cellPhone: "",
          password: "",
          passwordConfirmation: "",
        });
        //history.push("/");
        if (localStorage.getItem("ZUsaUrlPage")) {
          history.push(`/mypage/${localStorage.getItem("ZUsaUrlPage")}`);
        } else {
          history.push("/home");
        }
      } else {
        enqueueSnackbar(t("__message_error_when_try_register_label"), {
          variant: "success",
        });
        // setmessageNotification(t("__message_error_when_try_register_label"));
        // setIsOpenNotification(true);
      }
    }
  }, [appReducers?.referrer?.responseNewRegister]);

  ///HANDLERS
  const GetCellPhoneValidation = () => {
    dispatch(requestReferrerCellPhoneValidation(formData.cellPhone));
  };
  const GetEmailPhoneValidation = () => {
    dispatch(requestReferrerEmailValidation(formData.email));
  };
  const requestNewRegistration = () => {
    dispatch(requestReferrerNewRegistration(formData));
  };
  ///RENDERS

  function getHeaderStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <Fragment>
            {isRegisterActive ? (
              <Fragment>
                <div className="slide__content__header__step__icon">
                  {i18n.language === "es" ? (
                    <div className="register__spa__icon" />
                  ) : (
                    <div className="register__eng__icon" />
                  )}
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div className="slide__content__header__step__icon">
                  {i18n.language === "es" ? (
                    <div className="register__spa__disabled__icon" />
                  ) : (
                    <div className="register__eng__disabled__icon" />
                  )}
                </div>
              </Fragment>
            )}
            {isSharedActive ? (
              <Fragment>
                <div className="slide__content__header__step__icon">
                  {i18n.language === "es" ? (
                    <div className="shared__circle__referred__spa__enabled" />
                  ) : (
                    <div className="shared__circle__referred__eng__enabled" />
                  )}
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div className="slide__content__header__step__icon">
                  {i18n.language === "es" ? (
                    <div className="shared__circle__referred__spa__disabled " />
                  ) : (
                    <div className="shared__circle__referred__eng__disabled" />
                  )}
                </div>
              </Fragment>
            )}
          </Fragment>
        );
      case 1:
        return (
          <Fragment>
            {isRegisterActive ? (
              <Fragment>
                <div className="slide__content__header__step__icon">
                  {i18n.language === "es" ? (
                    <div className="register__spa__icon" />
                  ) : (
                    <div className="register__eng__icon" />
                  )}
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div className="slide__content__header__step__icon">
                  {i18n.language === "es" ? (
                    <div className="register__spa__disabled__icon" />
                  ) : (
                    <div className="register__eng__disabled__icon" />
                  )}
                </div>
              </Fragment>
            )}
            {isSharedActive ? (
              <Fragment>
                <div className="slide__content__header__step__icon">
                  {i18n.language === "es" ? (
                    <div className="shared__icon " />
                  ) : (
                    <div className="shared__icon" />
                  )}{" "}
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div className="slide__content__header__step__icon">
                  {i18n.language === "es" ? (
                    <div className="address__circle__spa__disabled__icon " />
                  ) : (
                    <div className="address__circle__eng__disabled__icon" />
                  )}
                </div>
              </Fragment>
            )}
          </Fragment>
        );
    }
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <Fragment>
            <div className="referred__content__form">
              <TextFieldComponent
                labelName={t("__login_Create_Account_Txt_Name_Label")}
                typeInput="text"
                erroHelp={formData.name.length === 0 && t("__empty_field")}
                isActiveHelp={formData.name.length === 0}
                textareaEnabled={false}
                setValue={(e) => {
                  setformData({ ...formData, name: restrictToOnlyLetters(e) });
                }}
                onInput={(e) =>
                  setformData({ ...formData, name: restrictToOnlyLetters(e) })
                }
              />
              <TextFieldComponent
                labelName={t("__login_Create_Account_Txt_LastName_Label")}
                typeInput="text"
                erroHelp={formData.lastName.length === 0 && t("__empty_field")}
                isActiveHelp={formData.lastName.length === 0}
                textareaEnabled={false}
                setValue={(e) => {
                  setformData({
                    ...formData,
                    lastName: restrictToOnlyLetters(e),
                  });
                }}
                onInput={(e) =>
                  setformData({
                    ...formData,
                    lastName: restrictToOnlyLetters(e),
                  })
                }
              />
              <TextFieldAndButtonComponent
                labelName={t("__steps_form_enterprising_phone_text")}
                placeholderInput={t("__steps_form_enterprising_phone_text")}
                erroHelp={
                  formData.cellPhone.length === 0
                    ? t("__empty_field")
                    : !isCellPhoneValidated && t("__field_invalid")
                }
                isActiveHelp={
                  formData.cellPhone.length === 0 || !isCellPhoneValidated
                }
                typeInput="text"
                setValue={(e) => {
                  setformData({
                    ...formData,
                    cellPhone: restrictToOnlyNumbers(e),
                  });
                }}
                iconClass={
                  formData.cellPhone.length !== 10
                    ? "cellphoneWhiteIcon "
                    : "cellphoneOutlineRed "
                }
                disabledButton={formData.cellPhone.length !== 10}
                onInput={(e) =>
                  setformData({
                    ...formData,
                    cellPhone: restrictToOnlyNumbers(e),
                  })
                }
                handleEventButton={() => {
                  if (regexIsCellphoneValid(formData.cellPhone)) {
                    GetCellPhoneValidation();
                  } else {
                    setIsCellPhoneValidated(false);
                  }
                }}
              />

              <TextFieldComponent
                labelName={t(
                  "__home_Entrepenour_Info_WebsPage_Doubt_Email_Text"
                )}
                typeInput="email"
                erroHelp={
                  formData.email.length === 0
                    ? t("__empty_field")
                    : !isEmailValidated && t("__field_invalid")
                }
                isActiveHelp={formData.email.length === 0 || !isEmailValidated}
                textareaEnabled={false}
                setValue={(e) => {
                  setformData({
                    ...formData,
                    email: restrictToOnlyLettersForEmail(e),
                  });
                }}
                onInput={(e) =>
                  setformData({
                    ...formData,
                    email: restrictToOnlyLettersForEmail(e),
                  })
                }
                onBlur={() => {
                  if (regexIsEmailAddressValid(formData.email)) {
                    GetEmailPhoneValidation();
                  } else {
                    setIsEmailValidated(false);
                  }
                }}
              />
              <TextFieldComponent
                labelName={t("__login_Txt_Password_Label")}
                typeInput="password"
                erroHelp={formData.password.length === 0 && t("__empty_field")}
                isActiveHelp={formData.password.length === 0}
                textareaEnabled={false}
                setValue={(e) => {
                  setformData({ ...formData, password: e.target.value.trim() });
                }}
                onInput={(e) =>
                  setformData({ ...formData, password: e.target.value.trim() })
                }
              />
              <TextFieldComponent
                labelName={t(
                  "__login_Create_Account_Txt_ConfirmPassword_Label"
                )}
                typeInput="password"
                erroHelp={
                  formData.passwordConfirmation.length === 0
                    ? t("__empty_field")
                    : formData.password.trim() !==
                        formData.passwordConfirmation.trim() &&
                      t("__field_no_match")
                }
                isActiveHelp={
                  formData.passwordConfirmation.length === 0 ||
                  formData.password.trim() !==
                    formData.passwordConfirmation.trim()
                }
                textareaEnabled={false}
                setValue={(e) => {
                  setformData({
                    ...formData,
                    passwordConfirmation: e.target.value.trim(),
                  });
                }}
                onInput={(e) =>
                  setformData({
                    ...formData,
                    passwordConfirmation: e.target.value.trim(),
                  })
                }
              />
            </div>

            <div className="steps__form__enterprising__buttons__next">
              <Button
                onClick={() => handleNext()}
                disabled={
                  formData.name.length === 0 ||
                  formData.lastName.length === 0 ||
                  formData.cellPhone.length === 0 ||
                  !isCellPhoneValidated ||
                  formData.email.length === 0 ||
                  !isEmailValidated ||
                  formData.password.length === 0 ||
                  formData.passwordConfirmation.length === 0 ||
                  formData.password.trim() !==
                    formData.passwordConfirmation.trim()
                }
                className="button__with__icon bg__dark__pink no__left__margin"
              >
                <div className="button__with__icon__svg__wrapper dark__pink__ball">
                  <SendIcon />
                </div>
                <span>
                  {t("__login_Create_Account_Btn_Next_Text").toUpperCase()}
                </span>
              </Button>
            </div>
          </Fragment>
        );
      case 1:
        return (
          <Fragment>
            <div className="slide__content__wrapper__text">
              <Typography variant="subtitle1" gutterBottom>
                {t("__slider_refer_friend")}
              </Typography>
            </div>
            <div className="slide__content__wrapper__text">
              <Typography variant="subtitle1" gutterBottom>
                {t("__slider_refer_friend_share")}
                <em> {t("__slider_refer_friend_pages")} </em>
              </Typography>
            </div>
            <div className="slide__content__wrapper__text">
              <Typography variant="subtitle1" gutterBottom>
                {t("__slider_refer_friend_share1")}
              </Typography>
            </div>
            <div className="slide__content__wrapper__text">
              <Typography variant="subtitle1" gutterBottom>
                {t("__slider_refer_friend_share2")}
              </Typography>
            </div>
            {/* <div className="slide__content__wrapper__social__media">
              <div className="facebook__icon__small" />
              <div className="whatsapp__icon__small" />
              <div className="pinterest__icon__small" />
              <div className="instagram__icon__small " />
            </div> */}

            <div className="steps__form__enterprising__buttons__next">
              <Button
                onClick={() => requestNewRegistration()}
                className="button__with__icon bg__dark__pink no__left__margin"
              >
                <div className="button__with__icon__svg__wrapper dark__pink__ball">
                  <SendIcon />
                </div>
                <span>
                  {t("__login_Create_Account_Btn_Next_Text").toUpperCase()}
                </span>
              </Button>
            </div>
          </Fragment>
        );
    }
  }
  return (
    <div className="referred__content">
      <div className="firstWrapperInBaseContainer ">
        <div className="slide__content__wrapper__text">
          <Typography variant="subtitle1" gutterBottom>
            {t("__slider_refer_friend_share3")}
          </Typography>
        </div>

        <div className="slide__content__header__step">
          {getHeaderStepContent(activeStep)}
        </div>
        <Fragment> {getStepContent(activeStep)}</Fragment>
        <Fragment>
          {/* <NotificationSnackbar
            isOpenNotification={isOpenNotification}
            setIsOpenNotification={setIsOpenNotification}
            message={messageNotification}
          /> */}
        </Fragment>
      </div>
    </div>
  );
};

export default ReferredContainer;
