import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import SlideCarousel from "./components/SlideCarousel";
import ProductsCarousel from "../products/components/ProductsCarousel";
import AdBanners from "./components/AdBanners";
import CustomerSupport from "../navigation/components/Footer/CustomerSupport";
import ProductsSections from "../base/components/ProductsSections";
import { useHistory, useLocation } from "react-router-dom";
import {
  requestHomeModuleInfo,
  requestEntrepenourHomeModuleInfo,
} from "./../../ReduxSaga/Actions/Home";
import {
  requestEmailFromRetargetingProgram,
  requestValidateInitialBanner,
  requestCleanReducerRetargetingProgram,
} from "./../../ReduxSaga/Actions/Client";

const HomeContainer = ({
  clientFavorites,
  match,
  benefitType,
  isLanguageLoaded,
}) => {
  //Notistack
  const { enqueueSnackbar } = useSnackbar();
  //NAVIGATION
  const history = useHistory();
  const location = useLocation();
  /// TRANSLATION
  const { t, i18n } = useTranslation();
  /// REDUX
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.home = useSelector((state) => state.home);
  appReducers.products = useSelector((state) => state.products);
  appReducers.general = useSelector((state) => state.general);
  appReducers.twilio = useSelector((state) => state.twilio);
  appReducers.client = useSelector((state) => state.client);
  appReducers.cart = useSelector((state) => state.cart);
  appReducers.facebookAPI = useSelector((state) => state.facebookAPI);

  /// HOOKS
  // const [openRetargetingProgramModal, setOpenRetargetingProgramModal] =
  //   useState(false);
  const [isEnterprisignActive, setIsEnterprisingActive] = useState(false);
  const [homeModuleData, setHomeModuleData] = useState([]);
  const [entrepenourPageInfo, setEntrepenourPageInfo] = useState({});
  const [promotionsData, setPromotionsData] = useState([]);
  const [trendsData, setTrendsData] = useState([]);

  const [newsData, setNewsData] = useState([]);

  const [showBanner, setShowBanner] = useState(false);
  const [instagramPosts, setInstagramPosts] = useState([]);
  const [instagramProfile, setInstagramProfile] = useState({});
  const [isLoadingEnable, setIsLoadingEnable] = useState(true);

  /// EFFECTS
  useEffect(() => {
    document.title = "Zermat USA :: Home";
  }, []);

  useEffect(() => {
    //console.log("location desde el HomeContainer", location);
    if (!isLoadingEnable && location) {
      if (location.pathname.includes("contactus")) {
        const customerSupportContainer = document.querySelector(
          ".customer__support__container"
        );
        if (customerSupportContainer) {
          setTimeout(() => {
            customerSupportContainer.scrollIntoView({
              behavior: "smooth",
            });
          }, 500);
        }
      }
    }
  }, [isLoadingEnable, location]);

  //HOME MODULE DATA
  useEffect(() => {
    if (
      !appReducers?.general?.errorLoadFirstCharge &&
      typeof appReducers?.general?.firstChargeModel !== "undefined"
    ) {
      if (appReducers.general.firstChargeModel.idPage <= 0) {
        if (
          !appReducers?.home?.errorLoadHomeModule &&
          !appReducers?.home?.homeModuleEn
        ) {
          dispatch(requestHomeModuleInfo());
        } else {
          setIsEnterprisingActive(false);
          setEntrepenourPageInfo({});
          if (!appReducers?.home?.errorLoadHomeModule) {
            if (i18n.language === "en")
              setHomeModuleData(appReducers?.home?.homeModuleEn);
            if (i18n.language === "es")
              setHomeModuleData(appReducers?.home?.homeModuleEs);
          }
        }
      } else {
        if (
          !appReducers?.home?.errorLoadEntrepenourHomeModule &&
          appReducers?.home?.homeEntrepenourModuleEn
        ) {
          //debugger;
          setIsEnterprisingActive(true);
          setEntrepenourPageInfo(appReducers?.home?.entrepenourPageInfo);
          if (i18n.language === "en")
            setHomeModuleData(appReducers?.home?.homeEntrepenourModuleEn);
          if (i18n.language === "es")
            setHomeModuleData(appReducers?.home?.homeEntrepenourModuleEs);
        }
      }
    }
  }, [
    i18n.language,
    appReducers?.home?.errorLoadHomeModule,
    appReducers?.home?.homeModuleEs,
    appReducers?.home?.homeModuleEn,
    appReducers?.home?.errorLoadEntrepenourHomeModule,
    appReducers?.home?.homeEntrepenourModuleEs,
    appReducers?.home?.homeEntrepenourModuleEn,
    appReducers?.home?.entrepenourPageInfo,
    appReducers?.general?.errorLoadFirstCharge,
    appReducers?.general?.firstChargeModel,
  ]);

  useEffect(() => {
    if (
      !appReducers?.home?.homeEntrepenourModuleEs &&
      !appReducers?.home?.homeEntrepenourModuleEn &&
      appReducers?.home?.entrepenourPageInfo &&
      typeof appReducers?.general?.errorLoadFirstCharge !== "undefined"
    ) {
      if (!appReducers.general.errorLoadFirstCharge) {
        const { idPage } = appReducers.general.firstChargeModel;
        if (idPage > 0) {
          dispatch(requestEntrepenourHomeModuleInfo());
        }
      }
    }
  }, [
    appReducers?.general?.errorLoadFirstCharge,
    appReducers?.general?.firstChargeModel,
    appReducers?.home?.homeEntrepenourModuleEs,
    appReducers?.home?.homeEntrepenourModuleEn,
    appReducers?.home?.entrepenourPageInfo,
  ]);

  // check de banner
  useEffect(() => {
    setShowBanner(false);
    if (appReducers?.client?.validateInitialBannerFlag) {
      const banner = appReducers?.client?.validateInitialBannerFlag;
      if (banner.code === 100) {
        setShowBanner(banner.showBanner);
      }
    } else {
      dispatch(requestValidateInitialBanner());
    }
  }, [appReducers?.client?.validateInitialBannerFlag]);

  //PRODUCTS LOAD
  useEffect(() => {
    if (
      !appReducers?.products?.errorLoadTrPrExProducts &&
      appReducers?.products?.newsEn
    ) {
      if (i18n.language === "en") {
        setNewsData(appReducers?.products?.newsEn);
      }
      if (i18n.language === "es") {
        setNewsData(appReducers?.products?.newsEs);
      }
    }
  }, [
    appReducers?.products?.errorLoadTrPrExProducts,
    i18n.language,
    appReducers?.products?.newsEs,
    appReducers?.products?.newsEn,
  ]);
  ///effect promotion data
  useEffect(() => {
    if (
      !appReducers?.products?.errorLoadTrPrExProducts &&
      appReducers?.products?.promotionsEn
    ) {
      if (i18n.language === "en") {
        if (benefitType === 5)
          setPromotionsData(
            appReducers?.products?.promotionsEn.filter(
              (p) => p.typePlan === benefitType
            )
          );
        else setPromotionsData(appReducers?.products?.promotionsEn);
      }
      if (i18n.language === "es") {
        if (benefitType === 5)
          setPromotionsData(
            appReducers?.products?.promotionsEs.filter(
              (p) => p.typePlan === benefitType
            )
          );
        else setPromotionsData(appReducers?.products?.promotionsEs);
      }
    }
  }, [
    appReducers?.products?.errorLoadTrPrExProducts,
    i18n.language,
    appReducers?.products?.promotionsEs,
    appReducers?.products?.promotionsEn,
    benefitType,
  ]);

  //EFFECT SKELETON
  useEffect(() => {
    if (typeof appReducers?.general?.errorLoadFirstCharge !== "undefined") {
      if (
        !appReducers.general.errorLoadFirstCharge &&
        typeof appReducers?.general?.firstChargeModel !== "undefined"
      ) {
        if (appReducers.general.firstChargeModel.idPage <= 0) {
          if (
            typeof appReducers?.home?.errorLoadHomeModule !== "undefined" &&
            appReducers?.home?.errorLoadHomeModule === false
          ) {
            if (
              typeof appReducers?.products?.errorLoadTrPrExProducts !==
                "undefined" &&
              appReducers?.products?.errorLoadTrPrExProducts === false
            ) {
              setIsLoadingEnable(false);
            }
          }
        } else {
          if (
            typeof appReducers?.home?.errorLoadEntrepenourHomeModule !==
              "undefined" &&
            appReducers?.home?.errorLoadEntrepenourHomeModule === false
          ) {
            if (
              typeof appReducers?.products?.errorLoadTrPrExProducts !==
                "undefined" &&
              appReducers?.products?.errorLoadTrPrExProducts === false
            ) {
              setIsLoadingEnable(false);
            }
          }
        }
      }
    }
  }, [
    appReducers?.home?.errorLoadHomeModule,
    appReducers?.home?.errorLoadEntrepenourHomeModule,
    appReducers?.general?.errorLoadFirstCharge,
    appReducers?.general?.firstChargeModel,
    appReducers?.products?.errorLoadTrPrExProducts,
  ]);

  //INSTAGRAM POSTS
  useEffect(() => {
    if (appReducers?.facebookAPI?.instagramPosts) {
      if (appReducers.facebookAPI.instagramPosts.length > 0) {
        setInstagramPosts(appReducers.facebookAPI.instagramPosts);
        setInstagramProfile(appReducers.facebookAPI.instagramProfile);
      }
    }
  }, [appReducers?.facebookAPI?.instagramPosts]);

  ///HANDLERS
  const generateQr = () => {};

  return (
    <div
      id="firstWrapperInBaseContainer"
      className="firstWrapperInBaseContainer__home"
    >
      {/* {showBanner && (
        <RetargetingProgramModal
          openRetargetingProgramModal={openRetargetingProgramModal}
          setOpenRetargetingProgramModal={setOpenRetargetingProgramModal}
          clientReducer={appReducers.client}
          requestEmailFromRetargetingProgram={
            requestEmailFromRetargetingProgram
          }
          requestCleanReducerRetargetingProgram={
            requestCleanReducerRetargetingProgram
          }
          actionDispatcher={dispatch}
          t={t}
          history={history}
        />
      )} */}

      <div className="banner__main">
        {/* {console.log("homeModuleData", homeModuleData.filter(
            (f) => f.idModContent === 1 || f.idModContent === 19
          ))} */}
        <SlideCarousel
          sliderData={homeModuleData.filter(
            (f) => f.idModContent === 1 || f.idModContent === 19
          )}
        />
      </div>

      {homeModuleData.filter((f) => f.idModContentResType === 4).length > 0 &&
        homeModuleData
          .filter(
            (f) =>
              f.idModContentResType === 4 &&
              (f.idModContent === 3 || f.idModContent === 21)
          )
          .map((item, index) => (
            <ProductsSections
              key={index}
              isLoadingEnable={isLoadingEnable}
              t={t}
              backgroundClass="LeftGray"
              titleSection={item.nameModConten}
              ListProducts={promotionsData}
              links={item.anchorResources}
              clientFavorites={clientFavorites}
              routeNavigation={"/categories/products/7"}
              isLanguageLoaded={isLanguageLoaded}
              withExtraButton={true}

              enqueueSnackbar={enqueueSnackbar}
            />
          ))}

      {/*  caroucel del productos
      <div className="products__content">
        {homeModuleData.filter((f) => f.idModContentResType === 4).length > 0 &&
          homeModuleData
            .filter((f) => f.idModContentResType === 4)
            .sort((a, b) => a.orderRender - b.orderRender)
            .map((item, index) => (
              <ProductsCarousel
                key={index}
                title={item.nameModConten}
                ListProducts={
                  item.anchorResources === "/trends"
                    ? trendsData
                    : promotionsData
                }
                links={item.anchorResources}
              />
            ))}
      </div> */}
      <AdBanners
        isLoadingEnable={isLoadingEnable}
        t={t}
        isEntrepenourHome={isEnterprisignActive}
        commercials={homeModuleData.filter((f) => f.idModContentType === 8)}
        isLanguageLoaded={isLanguageLoaded}
      />

      {homeModuleData.filter((f) => f.idModContentResType === 4).length > 0 &&
        homeModuleData
          .filter(
            (f) =>
              f.idModContentResType === 4 &&
              (f.idModContent === 37 || f.idModContent === 40)
          )
          .map((item, index) => (
            <ProductsSections
              key={index}
              isLoadingEnable={isLoadingEnable}
              t={t}
              backgroundClass="RightGray"
              titleSection={item.nameModConten}
              ListProducts={newsData}
              links={item.anchorResources}
              clientFavorites={clientFavorites}
              routeNavigation={"/categories/products/11"}
              isLanguageLoaded={isLanguageLoaded}
              withExtraButton={true}

              enqueueSnackbar={enqueueSnackbar}
            />
          ))}

      {/* Redes sociales */}
      {/* {!isEnterprisignActive && (
        <SocialMedia
          className="social__media__content"
          instagramPosts={instagramPosts}
          instagramProfile={instagramProfile}
        />
      )} */}

      {!isEnterprisignActive && (
        <CustomerSupport
          enqueueSnackbar={enqueueSnackbar}
          isLanguageLoaded={isLanguageLoaded}
        />
      )}

      {/* {isEnterprisignActive && (
        <EnterprisingInformation
          entrepenourPageInfo={entrepenourPageInfo}
          dispatch={dispatch}
          requestTwilioCellPhoneValidation={requestTwilioCellPhoneValidation}
          requestTwilioCellPhoneCodeValidation={
            requestTwilioCellPhoneCodeValidation
          }
          requestRegisterWebPageContact={requestRegisterWebPageContact}
          cleanReducerWebPageContactReducer={cleanReducerWebPageContactReducer}
          twilioReducer={appReducers.twilio}
          clientReducer={appReducers.client}
          t={t}
        />
      )} */}
    </div>
  );
};

export default HomeContainer;
