import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { Button, Link, Skeleton, Rating } from "@mui/material";
import { StarBorder } from "@mui/icons-material";
import VariantDetailComponent from "../products/components/VariantDetailComponent";
import { requestGetBeautyBoxes } from "./../../ReduxSaga/Actions/Products";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isMobile, isTablet } from "react-device-detect";
import {
  requestCartOrderAddMembership,
  requestGetCartOrder,
  requestCleanCartReducerAddMembership,
} from "./../../ReduxSaga/Actions/Cart";
import { requestCarCurrentOrder } from "../../ReduxSaga/Actions/Cart";
import {
  requestEntrepenourHomeModuleInfo,
  requestHomeModuleInfo,
} from "./../../ReduxSaga/Actions/Home";
// import  {
//   Navigation,
//   Pagination,
//   Autoplay,
//   EffectCoverflow,
// } from "swiper";
// import "swiper/swiper-bundle.css";
import { capitalizeText, formatPrice } from "../../../Libraries/Utils/utils";
// import LockProduct from "../base/components/LockProduct";

// SwiperCore.use([Navigation, Pagination, Autoplay, EffectCoverflow]);
// SwiperCore.use([Navigation, Pagination, Autoplay, EffectCoverflow]);

const NewFavoriteCustomerContainer = ({
  cpMemberships,
  infoCartOrder,
  benefitType,
  isLanguageLoaded,
}) => {
  //Notistack
  const { enqueueSnackbar } = useSnackbar();

  /// TRANSLATION
  const { t, i18n } = useTranslation();
  // REDUX
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.products = useSelector((state) => state.products);
  appReducers.home = useSelector((state) => state.home);
  appReducers.general = useSelector((state) => state.general);
  appReducers.cart = useSelector((state) => state.cart);

  ///HOOKS
  const [resourcesCPModule, setResourcesCPModule] = useState([]);
  const [isLoadingEnable, setIsLoadingEnable] = useState(true);
  // show las variantes
  const [showVariant, setShowVariant] = useState(false);
  ////// modal slide
  const [beautyBoxList, setBeautyBoxList] = useState([]);
  const [dataNotification, setDataNotification] = useState({
    isOpen: false,
    type: "",
    message: "",
    icon: "",
  });

  const [membershipSelected, setMembershipSelected] = useState({});

  //USEEFFECTS

  useEffect(() => {
    document.title = "Zermat USA :: Preferred Client";
    document.getElementById("firstWrapperInBaseContainer").scrollIntoView();
  }, []);

  useEffect(() => {
    if (cpMemberships.length) {
      setIsLoadingEnable(false);
      if (!membershipSelected.hasOwnProperty("itemCode")) {
        const membership = cpMemberships[0];
        membership.IsMembership = true;
        setMembershipSelected(membership);
      }
    } else {
      setIsLoadingEnable(true);
    }
  }, [cpMemberships, membershipSelected]);

  useEffect(() => {
    if (!showVariant && cpMemberships.length > 0) {
      const membership = cpMemberships[0];
      membership.IsMembership = true;
      setMembershipSelected(membership);
    }
  }, [showVariant, cpMemberships]);

  //EFFECT ON ORDER LINES
  useEffect(() => {
    if (infoCartOrder?.hasOwnProperty("code") && infoCartOrder?.code === 100) {
      if (infoCartOrder?.order && infoCartOrder?.order !== null) {
        if (infoCartOrder?.lines.length > 0)
          if (
            infoCartOrder?.lines.filter((l) =>
              cpMemberships.find((cp) => cp.itemCode === l.line.codart)
            ).length > 0
          )
            setShowVariant(true);
          else setShowVariant(false);
        else {
          if (benefitType === 2) setShowVariant(true);
          else setShowVariant(false);
        }
      } else {
        if (benefitType === 2) setShowVariant(true);
        else setShowVariant(false);
      }
    } else {
      if (benefitType === 2) setShowVariant(true);
      else setShowVariant(false);
    }
  }, [infoCartOrder, benefitType]);

  useEffect(() => {
    if (appReducers?.products?.beautyBoxes) {
      const beautyBoxes = appReducers.products.beautyBoxes;
      setBeautyBoxList(
        beautyBoxes.admissionKitList.filter(
          (f) => f.idLang == (i18n.language === "es" ? 1 : 2)
        )
      );
    } else {
      dispatch(requestGetBeautyBoxes());
    }
  }, [appReducers?.products?.beautyBoxes, i18n.language]);
  useEffect(() => {
    if (appReducers?.cart?.cartAddMembership) {
      const respAddMemship = appReducers.cart?.cartAddMembership;
      if (respAddMemship.code === 100) {
        enqueueSnackbar(t("__message_success_product_add_cart_label"), {
          variant: "success",
        });
        dispatch(requestGetCartOrder());
      } else {
        enqueueSnackbar(respAddMemship.message, {
          variant: "error",
        });
      }
      dispatch(requestCleanCartReducerAddMembership());
    }
  }, [appReducers?.cart?.cartAddMembership]);

  useEffect(() => {
    if (
      appReducers?.home?.preferredCustomerModuleEs &&
      appReducers?.home?.preferredCustomerModuleEn
    ) {
      if (i18n.language === "es") {
        //espanol
        setResourcesCPModule(appReducers.home.preferredCustomerModuleEs);
      } else {
        //ingles
        setResourcesCPModule(appReducers.home.preferredCustomerModuleEn);
      }
    } else {
      if (typeof appReducers?.general?.errorLoadFirstCharge !== "undefined") {
        if (!appReducers.general.errorLoadFirstCharge) {
          const { idPage } = appReducers.general.firstChargeModel;
          if (idPage > 0) {
            dispatch(requestEntrepenourHomeModuleInfo());
          } else {
            dispatch(requestHomeModuleInfo());
          }
        }
      }
    }
  }, [
    appReducers?.home?.preferredCustomerModuleEs,
    appReducers?.home?.preferredCustomerModuleEn,
    appReducers.general.errorLoadFirstCharge,
    appReducers.general.firstChargeModel,
    i18n.language,
  ]);

  /// HANDLERS

  const handleAddBeautyBox = () => {
    const parameters = {
      idPedidoDet: 1,
      idpromo: 1,
      itemCode: membershipSelected?.itemCode,
      opc: 0,
      pedidosD: [],
      quantity: 1,
      type: membershipSelected.typeLine,
      withAssign: 0,
    };
    dispatch(requestCarCurrentOrder(parameters));
  };
  const handleAddMembership = () => {
    if (benefitType !== 2)
      dispatch(
        requestCartOrderAddMembership(-1, 0, 2, 0, membershipSelected.itemCode)
      );
    else
      enqueueSnackbar(t("__message_already_membership_label"), {
        variant: "warning",
      });
  };

  return (
    <div
      id="firstWrapperInBaseContainer"
      className={`firstWrapperInBaseContainer section LeftTopGray new__favorite__customer ${
        isTablet ? "tablet" : isMobile ? "mobile" : ""
      }`}
    >
      <div
        className={`new__favorite__customer__banner ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }`}
      >
        <div
          className={`new__favorite__customer__banner__image ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          {isLoadingEnable ? (
            <Skeleton
              variant="rect"
              width={"100%"}
              height={isTablet ? 100 : isMobile ? 50 : 280}
            />
          ) : (
            <>
              {resourcesCPModule &&
                resourcesCPModule.length > 0 &&
                resourcesCPModule.map((item) => {
                  if (item.idMod === 2 && item.idModContent === 32) {
                    if (!isMobile) {
                      {
                        return item.content.map((res, idx) => {
                          if (res.idModuleContentResourcesDetailType === 3) {
                            return (
                              <img key={idx} alt={res.name} src={res.image} />
                            );
                          }
                        });
                      }
                    } else {
                      return item.content.map((res, idx) => {
                        if (res.idModuleContentResourcesDetailType === 1) {
                          return (
                            <img key={idx} alt={res.name} src={res.image} />
                          );
                        }
                      });
                    }
                  }
                })}
            </>
          )}
        </div>
      </div>
      <div
        className={`new__favorite__customer__content ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }`}
      >
        <div
          className={`new__favorite__customer__content__info ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          {!isLoadingEnable ? (
            <>
              {" "}
              {resourcesCPModule &&
                resourcesCPModule.length > 0 &&
                resourcesCPModule.map((item) => {
                  if (item.idMod === 2 && item.idModContent === 33) {
                    return item.valueResources;
                  }
                })}
            </>
          ) : (
            <Skeleton variant="rect" width={"100%"} height={100} />
          )}
          <div
            className={`new__favorite__customer__content__info__list ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            {isLanguageLoaded ? (
              <ul>
                <li>{t("__favorite_customer_item_one_header_benefit")}</li>
                <li>{t("__favorite_customer_item_two_header_benefit")}</li>
                <li>{t("__favorite_customer_item_three_header_benefit")}</li>
                <li>{t("__favorite_customer_item_four_header_benefit")}</li>
                <li>{t("__favorite_customer_item_five_header_benefit")}</li>
              </ul>
            ) : (
              <ul style={{ width: "100%" }}>
                {[0, 1, 2, 3, 4].map((item) => {
                  return (
                    <li>
                      {" "}
                      <Skeleton
                        key={item}
                        variant="rect"
                        width={"30%"}
                        height={20}
                        style={{ marginBottom: "3px" }}
                      />
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          <div
            className={`new__favorite__customer__content__info__motto ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            {isLanguageLoaded ? (
              t("__favorite_customer_motto")
            ) : (
              <Skeleton variant="rect" width={"30%"} height={20} />
            )}
          </div>
        </div>
        <div
          className={`new__favorite__customer__content__product ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          <div
            className={`new__favorite__customer__content__product__program ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            {!isLoadingEnable ? (
              <>
                <div className="price__red">
                  {isLanguageLoaded ? (
                    t("__favorite_customer_program")
                  ) : (
                    <Skeleton variant="rect" width={"30%"} height={20} />
                  )}
                </div>
                <div
                  className={`new__favorite__customer__content__product__program__list ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  }`}
                >
                  {isLanguageLoaded ? (
                    <ul>
                      <li>{t("__favorite_customer_program_list_one")}</li>
                      <li>{t("__favorite_customer_program_list_two")}</li>
                      <li> {t("__favorite_customer_program_list_three")}</li>
                    </ul>
                  ) : (
                    <ul style={{ width: "100%" }}>
                      {[0, 1, 2].map((item) => {
                        return (
                          <li>
                            {" "}
                            <Skeleton
                              key={item}
                              variant="rect"
                              width={"30%"}
                              height={20}
                              style={{ marginBottom: "3px" }}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </>
            ) : (
              <Skeleton
                variant="rect"
                width={"90%"}
                height={isTablet ? 100 : isMobile ? 50 : 200}
                style={{ marginBottom: 20, marginTop: 20 }}
              />
            )}
          </div>
          <div
            className={`new__favorite__customer__content__product__imagen ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            {/* {cpMemberships.map((kit, index) => {
              return (

              );
            })} */}
            {/* <LockProduct /> */}
            {membershipSelected.hasOwnProperty("itemCode") && (
              <div
                className={`new__favorite__customer__content__product__imagen__content ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                <div
                  className={`new__favorite__customer__content__product__imagen__content__img ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  }`}
                >
                  {!isLoadingEnable ? (
                    <img
                      alt="set"
                      src={membershipSelected.image}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src =
                          "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
                      }}
                    />
                  ) : (
                    <Skeleton
                      variant="rect"
                      width={"100%"}
                      height={isTablet ? 400 : isMobile ? 250 : 400}
                      style={{ marginBottom: 10 }}
                    />
                  )}
                </div>
                {/* <div>
                    <div
                      className={`new__favorite__customer__content__product__imagen__content__swiper ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                    >
                      {!isMobile && !isTablet && (
                        <Swiper
                          pagination={{
                            clickable: false,
                          }}
                          className="mySwiper"
                          slidesPerView={6}
                          spaceBetween={0}
                        >
                          {!isLoadingEnable ? (
                            [0, 1, 2, 3, 4, 5].map((index) => {
                              return (
                                <SwiperSlide key={index}>
                                  <img alt="set" src={kit.image} />
                                </SwiperSlide>
                              );
                            })
                          ) : (
                            <SwiperSlide>
                              <Skeleton
                                variant="rect"
                                width={"90%"}
                                height={400}
                              />
                            </SwiperSlide>
                          )}
                        </Swiper>
                      )}
                    </div>
                  </div> */}
              </div>
            )}
          </div>
          <div
            className={`new__favorite__customer__content__product__description ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            <div
              className={`new__favorite__customer__content__product__description__content ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              {/* <div
                className={`new__favorite__customer__content__product__description__content__favorite ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                {" "}
                {!isLoadingEnable ? (
                  <>
                    <FormControlLabel
                      onChange={handleChangeFavorite}
                      control={
                        <Checkbox
                          icon={<div className="heartOutlineIcon" />}
                          checkedIcon={<div className="heartFillIcon" />}
                        />
                      }
                    />
                  </>
                ) : (
                  <Skeleton width={"100%"} height={50} />
                )}{" "}
              </div> */}
              <div
                className={`new__favorite__customer__content__product__description__content__info ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                {/* {admissionKits.map((kit, index) => {
                  return (
                 
                  );
                })} */}
                {membershipSelected.hasOwnProperty("itemCode") && (
                  <div
                    className={`new__favorite__customer__content__product__description__content__info_gral ${
                      isTablet ? "tablet" : isMobile ? "mobile" : ""
                    }`}
                  >
                    {!isLoadingEnable ? (
                      <>
                        <div
                          className={`new__favorite__customer__content__product__description__content__info_one ${
                            isTablet ? "tablet" : isMobile ? "mobile" : ""
                          }`}
                        >
                          <div>
                            <div>
                              {capitalizeText(membershipSelected.nameArticle)}
                            </div>
                            <div className="price__red">
                              {formatPrice(membershipSelected.promotionPrice)}
                            </div>
                          </div>
                        </div>
                        <div
                          className={`new__favorite__customer__content__product__description__content__info_two ${
                            isTablet ? "tablet" : isMobile ? "mobile" : ""
                          }`}
                        >
                          {" "}
                          <div className="detail__ranking">
                            {!isLoadingEnable ? (
                              <>
                                {" "}
                                <Rating
                                  name="simple-controlled"
                                  size="small"
                                  value={5}
                                  emptyIcon={<StarBorder fontSize="inherit" />}
                                  // onChange={(event, newValue) => {
                                  //   setValue(newValue);
                                  // }}
                                  readOnly
                                />
                                <span className="detail__ranking__number">
                                  4.5
                                </span>
                              </>
                            ) : (
                              <Skeleton
                                variant="rect"
                                height={50}
                                width={"100%"}
                                style={{ marginBottom: 10 }}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <Skeleton
                        variant="rect"
                        width={"100%"}
                        height={50}
                        style={{ marginTop: 30, marginBottom: 20 }}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
            <div
              className={`new__favorite__customer__content__product__variants ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              {!showVariant && (
                <div className="blockVariants">
                  {isLanguageLoaded ? (
                    <span>{t("__favorite_customer_block_variants")}</span>
                  ) : (
                    ""
                  )}
                </div>
              )}

              <div>
                {!isLoadingEnable ? (
                  <VariantDetailComponent
                    itemOnClick={(item) => {
                      const beautyBox = beautyBoxList.find(
                        (i) => i.itemCode === item.id
                      );
                      beautyBox.IsMembership = false;
                      setMembershipSelected(beautyBox);
                    }}
                    isColor={false}
                    arrayFake={beautyBoxList.map((i) => ({
                      id: i.itemCode,
                      urlPath: i.image,
                      nameProduct: capitalizeText(i.nameArticle),
                    }))}
                  />
                ) : (
                  <Skeleton
                    variant="rect"
                    width={"90%"}
                    height={200}
                    style={{ marginTop: 30, marginBottom: 20 }}
                  />
                )}
              </div>
            </div>
            <div
              className={`new__favorite__customer__content__product__description__content__actions ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              {!isLoadingEnable ? (
                <>
                  {" "}
                  <Button
                    className="button__with__icon no__left__margin bg__dark__pink"
                    onClick={() => {
                      if (membershipSelected.IsMembership) {
                        handleAddMembership();
                      } else {
                        handleAddBeautyBox();
                      }
                    }}
                  >
                    <div className="button__with__icon__svg__wrapper melondark__pink__ball__ball">
                      <div className="bagWhiteIcon" />
                    </div>
                    <span>
                      {!showVariant
                        ? isLanguageLoaded
                          ? t("btn_add_membership")
                          : ""
                        : isLanguageLoaded
                        ? t("btn_cart_add_new")
                        : ""}
                    </span>
                  </Button>
                </>
              ) : (
                <Skeleton
                  variant="rect"
                  width={"50%"}
                  height={30}
                  style={{ marginTop: 10, marginLeft: 20, marginBottom: 20 }}
                />
              )}
            </div>
            <div
              className={`new__favorite__customer__content__benefits ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              {!isLoadingEnable ? (
                <>
                  <span>
                    {isLanguageLoaded ? (
                      t("__favorite_customer_benefit_label")
                    ) : (
                      <Skeleton
                        variant="rect"
                        width={"50%"}
                        height={30}
                        style={{ marginBottom: 10 }}
                      />
                    )}
                  </span>
                  <div
                    className={`new__favorite__customer__content__benefits__inner ${
                      isTablet ? "tablet" : isMobile ? "mobile" : ""
                    }`}
                  >
                    <span>
                      {isLanguageLoaded ? (
                        t("__favorite_customer_program_all_benefit")
                      ) : (
                        <Skeleton
                          variant="rect"
                          width={"50%"}
                          height={30}
                          style={{ marginBottom: 10 }}
                        />
                      )}
                    </span>
                    <div>
                      <Link
                        onClick={() => {
                          window.open(
                            "https://3972069.hs-sites.com/beauty-box",
                            "_blank"
                          );
                        }}
                      >
                        https://3972069.hs-sites.com/beauty-box.
                      </Link>
                    </div>
                  </div>
                </>
              ) : (
                <Skeleton
                  variant="rect"
                  width={"95%"}
                  height={200}
                  style={{ marginBottom: 20 }}
                />
              )}
            </div>
            <div
              className={`new__favorite__customer__content__benefits ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              {!isLoadingEnable ? (
                <>
                  {/* <span>{t("__favorite_customer_products_label")}</span>
                  <span>{membershipSelected.descArticle}</span> */}
                  {/* <List>
                    {resourcesCPModule &&
                      resourcesCPModule.length > 0 &&
                      resourcesCPModule.map((item) => {
                        if (item.idMod === 2 && item.idModContent === 34) {
                          {
                            return benefitsList(item.valueResources);
                          }
                        }
                      })}
                  </List> */}
                </>
              ) : (
                <Skeleton
                  variant="rect"
                  width={"95%"}
                  height={200}
                  style={{ marginBottom: 20, marginTop: 20 }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFavoriteCustomerContainer;
