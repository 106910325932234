import {
    RESPONSE_CLIENT_ADDRESS_VALIDATION
} from './../../Constants'


export default function address(state = {}, action) {
    switch (action.type) {
        case RESPONSE_CLIENT_ADDRESS_VALIDATION:
            {
                const responseClientAddressValidation = action.payload.response
                const typeAddress = action.payload.type
                if(responseClientAddressValidation.code === 100){
                    return { ...state, errorClientAddressValidation: false, typeAddress }
                }else{
                    console.error("error al validar la direccion del cliente",responseClientAddressValidation )
                    if(responseClientAddressValidation?.addressListSugestion?.length > 0)
                    {
                        const addressSugestion = responseClientAddressValidation?.addressListSugestion
                        return { ...state, errorClientAddressValidation: true, typeAddress, addressSugestion  }
                    }
                    else
                        return { ...state, errorClientAddressValidation: true, typeAddress, addressSugestion: []  }
                }
            }
        default:
            return { ...state }
    }

}