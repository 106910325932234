import React, { useEffect } from "react";
import { Typography, Skeleton } from "@mui/material";
import { isMobile, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";

const PrivacyPolicesConatiner = ({ isLanguageLoaded }) => {
  /// TRANSLATION
  const { t } = useTranslation();
  useEffect(() => {
    document.title = "Zermat USA :: Privacy Polices";
  }, []);
  return (
    <div
      id="firstWrapperInBaseContainer"
      className="firstWrapperInBaseContainer"
    >
      <div className="polices__privacy__container">
        <div className={`polices__privacy__container__title`}>
          {isLanguageLoaded ? (
            <Typography variant="h6" gutterBottom className="text__red">
              {t("__privacyPolices_title_Txt")}
            </Typography>
          ) : (
            <Skeleton
              variant="rect"
              width={isMobile ? "95%" : "30%"}
              height={30}
              style={{ margin: "5px" }}
            />
          )}
        </div>
        <div>
          {isLanguageLoaded ? (
            <Typography variant="body1" gutterBottom>
              {t("__privacyPolices_paragraph1_Txt")}{" "}
              <em>
                <a
                  rel="zermat USA"
                  target="_blank"
                  href="https://www.zermatusa.com/"
                >
                  {t("__privacyPolices_page_Txt")}
                </a>
              </em>
              {t("__privacyPolices_paragraph1_2_Txt")}{" "}
              <em>{t("__privacyPolices_paragraph1_3_Txt")}</em>{" "}
              {t("__privacyPolices_paragraph1_4_Txt")}
            </Typography>
          ) : (
            <Skeleton
              variant="rect"
              width={"95%"}
              height={150}
              style={{ margin: "5px" }}
            />
          )}

          <br />
          <br />
          {isLanguageLoaded ? (
            <Typography variant="body1" gutterBottom>
              {t("__privacyPolices_paragraph2_Txt")}
            </Typography>
          ) : (
            <Skeleton
              variant="rect"
              width={"95%"}
              height={100}
              style={{ margin: "5px" }}
            />
          )}

          <br />
          <br />
          {isLanguageLoaded ? (
            <Typography variant="body1" gutterBottom>
              {t("__privacyPolices_paragraph3_Txt")}{" "}
              <em>{t("__privacyPolices_name_company_Txt")}</em>
              {t("__privacyPolices_paragraph3_1_Txt")}{" "}
              <em>{t("__privacyPolices_name_company_Txt")}</em>
              {t("__privacyPolices_paragraph3_2_Txt")}{" "}
              <em>{t("__privacyPolices_name_company_Txt")}</em>
              {t("__privacyPolices_paragraph3_3_Txt")}
            </Typography>
          ) : (
            <Skeleton
              variant="rect"
              width={"95%"}
              height={150}
              style={{ margin: "5px" }}
            />
          )}
        </div>
        <div
          className={`polices__privacy__container__paragraph__special ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          {isLanguageLoaded ? (
            <Typography variant="body2" gutterBottom>
              <em>{t("__privacyPolices_name_company_2_Txt")} </em>{" "}
              {t("__privacyPolices_paragraph4_Txt")}{" "}
              <em>{t("__privacyPolices_paragraph4_1_Txt")}</em>
              {t("__privacyPolices_paragraph4_2_Txt")}
            </Typography>
          ) : (
            <Skeleton
              variant="rect"
              width={"95%"}
              height={100}
              style={{ margin: "5px" }}
            />
          )}
        </div>
        <div>
          {isLanguageLoaded ? (
            <Typography variant="body1" gutterBottom>
              <em>{t("__privacyPolices_paragraph5_Txt")}</em> &nbsp;{" "}
              {t("__privacyPolices_paragraph5_1_Txt")}
            </Typography>
          ) : (
            <Skeleton
              variant="rect"
              width={"95%"}
              height={100}
              style={{ margin: "5px" }}
            />
          )}
        </div>
        <div
          className={`polices__privacy__container__paragraph__special ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          {isLanguageLoaded ? (
            <ul>
              <li>
                <em> {t("__privacyPolices_paragraph5_1_1_Txt")} </em>
                <br /> {t("__privacyPolices_paragraph5_1_2_Txt")}
              </li>
              <li>
                <em>{t("__privacyPolices_paragraph5_2_1_Txt")}</em>
                <br /> {t("__privacyPolices_paragraph5_2_2_Txt")}
              </li>
            </ul>
          ) : (
            <Skeleton
              variant="rect"
              width={"95%"}
              height={150}
              style={{ margin: "5px" }}
            />
          )}
        </div>
        <div>
          {isLanguageLoaded ? (
            <Typography variant="body1" gutterBottom>
              <em>{t("__privacyPolices_paragraph6_Txt")}</em> &nbsp;{" "}
              {t("__privacyPolices_paragraph6_1_Txt")}
            </Typography>
          ) : (
            <Skeleton
              variant="rect"
              width={"95%"}
              height={80}
              style={{ margin: "5px" }}
            />
          )}
        </div>
        <div
          className={`polices__privacy__container__paragraph__special ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          {isLanguageLoaded ? (
            <ul>
              <li>
                <em> {t("__privacyPolices_paragraph6_1_1_Txt")} </em>
                {t("__privacyPolices_paragraph6_1_2_Txt")}
              </li>
            </ul>
          ) : (
            <Skeleton
              variant="rect"
              width={"95%"}
              height={80}
              style={{ margin: "5px" }}
            />
          )}
        </div>
        <div>
          {isLanguageLoaded ? (
            <>
              <Typography
                className="text__black"
                variant={"body1"}
                gutterBottom
              >
                {t("__privacyPolices_paragraph7_title_Txt")}
              </Typography>
              <Typography variant={"body1"} gutterBottom>
                {t("__privacyPolices_paragraph7")}
              </Typography>
            </>
          ) : (
            <Skeleton
              variant="rect"
              width={"95%"}
              height={80}
              style={{ margin: "5px" }}
            />
          )}
        </div>

        <div>
          {isLanguageLoaded ? (
            <>
              {" "}
              <Typography
                className="text__black"
                variant={"body1"}
                gutterBottom
              >
                {t("__privacyPolices_paragraph8_title_Txt")}
              </Typography>
              <Typography variant={"body1"} gutterBottom>
                {t("__privacyPolices_paragraph8")}
              </Typography>
            </>
          ) : (
            <Skeleton
              variant="rect"
              width={"95%"}
              height={80}
              style={{ margin: "5px" }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicesConatiner;
