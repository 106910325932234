import React, { Fragment, useState } from "react";
import {
  Drawer,
  Button,
  Chip,
  Paper,
  Typography,
  Skeleton,
} from "@mui/material";
import { LocalAtm } from "@mui/icons-material";
import { useSnackbar } from "notistack";
//import NotificationSnackbar from "../../base/components/NotificationSnackbar";
import { useTranslation } from "react-i18next";
import { capitalizeText } from "../../../../Libraries/Utils/utils";

const ZcareProductSlideModal = ({
  isOpenModalCampaign,
  setIsOpenModalCampaign,
  prodZcare,
  props,
}) => {
  //Notistack
  const { enqueueSnackbar } = useSnackbar();

  ///states
  //const [isOpenNotification, setIsOpenNotification] = useState(false);
  const [isLoadingEnabled, setisLoadingEnabled] = useState(false);
  /// TRANSLATION
  const { t } = useTranslation();

  //useEffects

  ///// funciones

  const handleAddProduct = () => {
    enqueueSnackbar(t("__favorite_customer_text"), {
      variant: "success",
    });
  };

  const handleSendCheckout = () => {
    window.location.replace("/checkout");
  };

  return (
    <Fragment>
      <Drawer
        className="zcare__modal__container"
        anchor="bottom"
        open={isOpenModalCampaign}
      >
        <Paper elevation={3}>
          <div className="modal__slide__container__pill">
            <Chip
              clickable
              onClick={() => {
                setIsOpenModalCampaign(false);
              }}
            />
          </div>
          <div className="zcare__modal__container__wrapper">
            <Paper className="zcare__modal__container__wrapper__mark">
              <div className="zcare__modal__container__wrapper__mark__inner__info">
                <div className="bandaidOutlineIcon" />
                {t("__zcare_modal_title")}
              </div>
            </Paper>
            {/* <NotificationSnackbar
              isOpenNotification={isOpenNotification}
              setIsOpenNotification={setIsOpenNotification}
              message={t("__favorite_customer_text")}
              status="success"
              icon="Check"
            /> */}
            <div className="zcare__modal__container__wrapper__content">
              <div className="zcare__modal__container__wrapper__content__title">
                {!isLoadingEnabled ? (
                  <Typography variant="body1" gutterBottom>
                    “Latinas contra el Cáncer”
                  </Typography>
                ) : (
                  <Skeleton
                    variant="rect"
                    width={"40%"}
                    height={40}
                    style={{ marginBottom: 5 }}
                  />
                )}
              </div>
              <div className="zcare__modal__container__wrapper__content__description">
                {!isLoadingEnabled ? (
                  <Typography variant="body2" gutterBottom>
                    En solidaridad a las mujeres Latinas y con el propósito de
                    ser un instrumento de apoyo para la noble causa de mujeres
                    que luchan una batalla contra el cáncer, Z Cares unió
                    fuerzas para realizar una donación.
                  </Typography>
                ) : (
                  <Skeleton
                    variant="rect"
                    width={"80%"}
                    height={50}
                    style={{ marginBottom: 5 }}
                  />
                )}
              </div>
              {prodZcare && (
                <div className="zcare__modal__container__wrapper__content__product">
                  <div className="zcare__modal__container__wrapper__content__product__image">
                    {prodZcare.image ? (
                      <img
                        alt="product"
                        src={prodZcare.image}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src =
                            "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
                        }}
                      />
                    ) : (
                      <Skeleton
                        variant="rect"
                        width={"18%"}
                        height={180}
                        style={{ marginBottom: 5 }}
                      />
                    )}
                  </div>
                  <div className="zcare__modal__container__wrapper__content__product__description">
                    {prodZcare.nameArticle ? (
                      <Typography variant="body2" gutterBottom>
                        {capitalizeText(prodZcare.nameArticle)}
                      </Typography>
                    ) : (
                      <Skeleton
                        variant="rect"
                        width={"30%"}
                        height={15}
                        style={{ marginBottom: 5 }}
                      />
                    )}
                    {prodZcare.regularPrice ? (
                      <Typography variant="body2" gutterBottom>
                        ${parseFloat(prodZcare.regularPrice).toFixed(2)}
                      </Typography>
                    ) : (
                      <Skeleton
                        variant="rect"
                        width={"30%"}
                        height={15}
                        style={{ marginBottom: 5 }}
                      />
                    )}
                  </div>
                  {/* <div className="zcare__modal__container__wrapper__content__product__description">
                    <div className="slide__content__wrapper__social__media">
                      <Typography variant="button" display="block" gutterBottom>
                        <div className="facebook__icon__small" />
                      </Typography>
                      <Typography variant="button" display="block" gutterBottom>
                        <div className="whatsapp__icon__small" />
                      </Typography>
                      <Typography variant="button" display="block" gutterBottom>
                        <div className="instagram__icon__small" />
                      </Typography>
                      <Typography variant="button" display="block" gutterBottom>
                        <div className="pinterest__icon__small" />
                      </Typography>
                    </div>
                  </div> */}
                </div>
              )}
              <div className="zcare__modal__container__wrapper__content__buttons">
                <div className="zcare__modal__container__wrapper__content__buttons__left">
                  <Button
                    onClick={handleAddProduct}
                    className="button__with__icon bg__dark__pink no__left__margin"
                  >
                    <div className="button__with__icon__svg__wrapper dark__pink__ball">
                      <div className="bagWhiteIcon" />
                    </div>
                    <span>{t("__zcare_btn_add")}</span>
                  </Button>
                </div>
                <div className="zcare__modal__container__wrapper__content__buttons__right">
                  <Button
                    onClick={handleSendCheckout}
                    className="button__with__icon bg__dark__pink no__left__margin"
                  >
                    <div className="button__with__icon__svg__wrapper dark__pink__ball">
                      <LocalAtm />
                    </div>
                    <span>{t("__zcare_btn_pay")}</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </Drawer>
      {/* <NotificationSnackbar /> */}
    </Fragment>
  );
};

export default ZcareProductSlideModal;
