import GraphqlHandlerNoInterceptor from '../GraphqlHandler/noInterceptor'
import { ENVIROMENT_URL } from '../../React/ReduxSaga/Constants';

const route = ENVIROMENT_URL + 'apiV3/GeneralMicroservice/General'

class FooterModule {
  static GetFooterModule() {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      route,
      //'http://localhost:59622/api/ClientSession',
      `{
              getModules
                {
                  code,
                  message,
                  footer
                  {
                    id,
                    idModuleType,
                    moduleName,
                    sections
                    {
                      anchorResources,
                      idLang,
                      idMod,
                      idModContent,
                      idModContentRes,
                      idModContentType,
                      idModContentResType
                      nameModConten,
                      orderRender,
                      valueResources,
                      content
                      {
                        id,
                        anchor,
                        idLang,
                        idModuleContentResources,
                        idModuleContentResourcesDetailType,
                        image,
                        itemCode,
                        name,
                        price,
                        value
                      }
                    }
                  }
                } 
              }`,
      false
    );
    return result;
  }
}

export default FooterModule