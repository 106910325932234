import {
    RESPONSE_HOME_MODULE_INFO,
    RESPONSE_ENTREPENOUR_HOME_MODULE_INFO,
    RESPONSE_CLIENT_PREFERENT_MODULE,
    RESPONSE_ENTERPRENOUR_MODULE,
    RESPONSE_ZCARE_MODULE
} from '../../Constants'


export default function home(state = {}, action) {
    switch (action.type) {
        case RESPONSE_HOME_MODULE_INFO:
            {
                const responseHomeModule = action.payload.response;
                if (responseHomeModule.code === 100) {
                    const homeModuleEs = responseHomeModule.home.sections.filter(item => item.idLang === 1)
                    const homeModuleEn = responseHomeModule.home.sections.filter(item => item.idLang === 2)
                    return { ...state, errorLoadHomeModule: false, homeModuleEs, homeModuleEn }
                }
                else {
                    return { ...state, errorLoadHomeModule: true }
                }


            }
        case RESPONSE_ENTREPENOUR_HOME_MODULE_INFO:
            {
                const responseEntrepenourHomeModule = action.payload.response;
                if (responseEntrepenourHomeModule.code === 100) {
                    const homeEntrepenourModuleEs = responseEntrepenourHomeModule.entrepenourHome.sections.filter(item => item.idLang === 1)
                    const homeEntrepenourModuleEn = responseEntrepenourHomeModule.entrepenourHome.sections.filter(item => item.idLang === 2)
                    const entrepenourPageInfo = responseEntrepenourHomeModule.pageOwner;
                    return { ...state, errorLoadEntrepenourHomeModule: false, homeEntrepenourModuleEs, homeEntrepenourModuleEn, entrepenourPageInfo }
                }
                else {
                    return { ...state, errorLoadEntrepenourHomeModule: true }
                }


            }

        case RESPONSE_CLIENT_PREFERENT_MODULE:
            {
                const responsePreferentClient = action.payload.response;
                if (responsePreferentClient.code === 100) {
                    const preferredCustomerModuleEs = responsePreferentClient.preferredCustomer.sections.filter(item => item.idLang === 1)
                    const preferredCustomerModuleEn = responsePreferentClient.preferredCustomer.sections.filter(item => item.idLang === 2)
                    return { ...state, errorLoadEntrepenourHomeModule: false, preferredCustomerModuleEs, preferredCustomerModuleEn }
                }
                else {
                    return { ...state, errorLoadEntrepenourHomeModule: true }
                }
            } 

        case RESPONSE_ENTERPRENOUR_MODULE:
            {
                const responseEnterprenour = action.payload.response;
                if (responseEnterprenour.code === 100) {
                    const enterprenourModuleEs = responseEnterprenour.getStartedZermat.sections.filter(item => item.idLang === 1)
                    const enterprenourModuleEn = responseEnterprenour.getStartedZermat.sections.filter(item => item.idLang === 2)
                    return { ...state, errorLoadEntrepenourHomeModule: false, enterprenourModuleEs, enterprenourModuleEn }
                }
                else {
                    return { ...state, errorLoadEntrepenourHomeModule: true }
                }
            } 
        
        case RESPONSE_ZCARE_MODULE:
            {
                const responseZcare = action.payload.response;
                if (responseZcare.code === 100) {
                    const zCareModuleEs = responseZcare.zCare.sections.filter(item => item.idLang === 1)
                    const zCareModuleEn = responseZcare.zCare.sections.filter(item => item.idLang === 2)
                    return { ...state, errorLoadEntrepenourHomeModule: false, zCareModuleEs, zCareModuleEn }
                }
                else {
                    return { ...state, errorLoadEntrepenourHomeModule: true }
                }
            }

        default:
            return { ...state }
    }
}