import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Fab, Typography, Tooltip, Skeleton } from "@mui/material";
import { withStyles } from "@mui/styles";
import NotificationSnackbar from "../base/components/NotificationSnackbar";
import SlideReferredFriends from "./components/SlideReferredFriends";
import { isMobile, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
///ACTIONS
import {
  requestReferrerCode,
  requestReferrerCodeSendedLog,
  cleanReferrerCodeSendedLogReducer,
  requestReferrerList,
} from "./../../ReduxSaga/Actions/Referrer";
import { requestLoggUserActivity } from "./../../ReduxSaga/Actions/General";
import { useHistory } from "react-router-dom";

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#ffcdcf",
    color: "#ff0043",
    maxWidth: 350,
    fontSize: "12px",
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const FriendsReferredContainer = ({ isLanguageLoaded }) => {
  //Notistack
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();
  /// TRANSLATION
  const { t, i18n } = useTranslation();
  ///REDUX
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.referrer = useSelector((state) => state.referrer);

  // hooks
  const [isCopySuccess, setIsCopySuccess] = useState(false);
  const [referrerCode, setReferrerCode] = useState("");
  const [isOpenNotification, setIsOpenNotification] = useState(false);
  const [messageNotification, setMessageNotification] = useState("");
  const [isOpenSlideModal, setIsOpenSlideModal] = useState(false);
  const [referrersList, setReferrersList] = useState([]);

  //// effects
  //DIDMOUNT
  useEffect(() => {
    dispatch(requestReferrerCode());
  }, []);

  useEffect(() => {
    if (isCopySuccess) {
      enqueueSnackbar(t("__message_link_copy_label"), {
        variant: "success",
      });
    }
    //setIsOpenNotification(isCopySuccess);
    //setMessageNotification(isCopySuccess ? t("__message_link_copy_label") : "");
  }, [isCopySuccess]);

  useEffect(() => {
    if (typeof appReducers?.referrer?.responseGetReferrerCode !== "undefined") {
      if (appReducers.referrer.responseGetReferrerCode.code === 100) {
        const rCode = appReducers.referrer.responseGetReferrerCode.message;
        setReferrerCode(rCode);
        dispatch(requestReferrerList(rCode));
      } else {
        enqueueSnackbar(t("__message_code_error_referred_label"), {
          variant: "error",
        });
        // setIsOpenNotification(true);
        // setMessageNotification(t("__message_code_error_referred_label"));
      }
    }
  }, [appReducers?.referrer?.responseGetReferrerCode]);

  useEffect(() => {
    if (typeof appReducers?.referrer?.referrerListResponse !== "undefined") {
      if (appReducers.referrer.referrerListResponse.code === 100) {
        setReferrersList(appReducers.referrer.referrerListResponse.referrers);
      } else {
        enqueueSnackbar(t("__message_code_error_referred_list_label"), {
          variant: "error",
        });
        // setIsOpenNotification(true);
        // setMessageNotification(t("__message_code_error_referred_list_label"));
      }
    }
  }, [appReducers?.referrer?.referrerListResponse]);

  ///handlers
  const copyToClipBoard = (text) => {
    try {
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      textArea.setSelectionRange(0, 99999);

      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      // console.log("Fallback: Copying text command was " + msg);
      setIsCopySuccess(true);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
      setIsCopySuccess(false);
    } finally {
      document.body.removeChild(textArea);
    }
  };

  // const copyToClipBoard = async (copyMe) => {
  //   try {
  //     console.log("referrer code", copyMe);
  //     await navigator.clipboard.writeText(copyMe);
  //     setIsCopySuccess(true);
  //   } catch (err) {
  //     console.error("error en la copia del link de referidos", err);
  //     setIsCopySuccess(false);
  //   }
  // };

  const handleClickSlideModal = () => {
    history.push(`/newReferred/${referrerCode}`);

    dispatch(
      requestLoggUserActivity(55, 0, 0, "", "Click en Agregar un Referido")
    );
  };

  return (
    <div className="friends__referred">
      {/* <BreadcrumbsNavigationComponet /> */}
      {/* <NotificationSnackbar
        isOpenNotification={isOpenNotification}
        setIsOpenNotification={setIsOpenNotification}
        message={messageNotification}
        status="success"
        icon="Check"
      /> */}
      {/* <div className="friends__referred__title">
        <Typography variant="h6" gutterBottom>
          {t("__myAccount_Profile_Referrals_Title")}
        </Typography>
      </div> */}
      <div className="friends__referred__title">
        {isLanguageLoaded ? (
          <Typography className="text__red" variant="subtitle1" gutterBottom>
            {t("__myAccount_Profile_Referrals_Subtitle")}
          </Typography>
        ) : (
          <Skeleton variant="rect" width={"20%"} height={30} />
        )}
      </div>
      <div className="friends__referred__container">
        <div className="friends__referred__container__pending">
          <div className="friends__referred__container__pending__title">
            <Button className="button__with__icon bg__dark__pink no__left__margin">
              <div className="button__with__icon__svg__wrapper dark__pink__ball">
                <div className="friends__referred__container__buttons__register" />
              </div>
              <span>
                {" "}
                {isLanguageLoaded ? (
                  t("__myAccount_Profile_Referrals_Pending_Label")
                ) : (
                  <Skeleton variant="rect" width={"20%"} height={30} />
                )}{" "}
              </span>
            </Button>
          </div>
          {referrersList
            .filter((r) => !r.isRegistered)
            .map((item, index) => (
              <div
                key={index}
                className="friends__referred__container__pending__item"
              >
                <Typography variant="body2" gutterBottom>
                  {item.name}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {item.dateAudit}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {isLanguageLoaded ? (
                    t("__message_referred_pending coupon_label")
                  ) : (
                    <Skeleton variant="rect" width={"20%"} height={30} />
                  )}
                </Typography>
              </div>
            ))}
        </div>
        <div className="friends__referred__container__actived">
          <div className="friends__referred__container__actived__title">
            <Button className="button__with__icon bg__dark__pink no__left__margin">
              <div className="button__with__icon__svg__wrapper dark__pink__ball">
                <div className="friends__referred__container__buttons__register" />
              </div>
              <span>
                {" "}
                {isLanguageLoaded ? (
                  t("__myAccount_Profile_Referrals_Registered_Label")
                ) : (
                  <Skeleton variant="rect" width={"20%"} height={30} />
                )}
              </span>
            </Button>
          </div>
          {referrersList
            .filter((r) => r.isRegistered)
            .map((item, index) => (
              <div
                key={index}
                className="friends__referred__container__actived__item"
              >
                <Typography variant="body2" gutterBottom>
                  {item.name}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {item.dateAudit}
                </Typography>
                {isLanguageLoaded ? (
                  <Typography variant="body2" gutterBottom>
                    {t("__message_referred_registered_label")}
                  </Typography>
                ) : (
                  <Skeleton variant="rect" width={"20%"} height={30} />
                )}
              </div>
            ))}
        </div>
        {referrerCode.length > 0 && (
          <div className="friends__referred__container__buttons">
            <div className="friends__referred__container__buttons__item friends__referred__container__buttons__fab__shared">
              <StyledTooltip
                title={
                  isLanguageLoaded
                    ? t("__myAccount_Profile_Referrals_Shared_Label")
                    : ""
                }
                aria-label="shared"
              >
                <Fab
                  onClick={() =>
                    copyToClipBoard(
                      process.env.REACT_APP_STAGE === "dev"
                        ? `http://10.10.1.16:8082/referred/${referrerCode}`
                        : `https://www.zermatusa.com/referred/${referrerCode}`
                    )
                  }
                  aria-label="shared"
                >
                  <div className="friends__referred__container__buttons__shared" />
                </Fab>
              </StyledTooltip>
            </div>
            <div className="friends__referred__container__buttons__item friends__referred__container__buttons__fab__referes">
              <StyledTooltip
                title={
                  isLanguageLoaded
                    ? t("__myAccount_Profile_Referrals_Add_Label")
                    : ""
                }
                aria-label="add"
              >
                <Fab onClick={handleClickSlideModal} aria-label="register">
                  <div className="friends__referred__container__buttons__register" />
                </Fab>
              </StyledTooltip>
            </div>
          </div>
        )}
      </div>
      {isOpenNotification && (
        <SlideReferredFriends
          isOpenSlideModal={isOpenSlideModal}
          setIsOpenSlideModal={setIsOpenSlideModal}
          referrerCode={referrerCode}
          dispatch={dispatch}
          requestReferrerCodeSendedLog={requestReferrerCodeSendedLog}
          cleanReferrerCodeSendedLogReducer={cleanReferrerCodeSendedLogReducer}
          appReducers={appReducers}
          requestLoggUserActivity={requestLoggUserActivity}
        />
      )}
    </div>
  );
};

export default FriendsReferredContainer;
