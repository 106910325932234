import {
    REQUEST_TRPREX_PRODUCTS, 
    REQUEST_FAVORITES,
    REQUEST_ALL_PRODUCTS,
    REQUEST_SET_PRODUCT_ON_FAVORITES,
    REQUEST_GET_ZCARE_PRODUCT,
    REQUEST_GET_SETUP_KIT_COMPONENTS,
    REQUEST_GET_ADMISSION_KITS,
    CLEAN_SET_FAVORITES_REDUCER,
    REQUEST_ALL_PRODUCTS_BY_LANG,
    REQUEST_ADD_SET_CATEGORIES_REDUCER,
    REQUEST_REMOVE_SET_CATEGORIES_REDUCER,
    REQUEST_ALL_PRODUCTS_ASYNC,
    REQUEST_ALL_CAREGORIES_ASYNC,
    REQUEST_GET_BEAUTY_BOXES,
    REQUEST_ALL_PRODUCT_FAMILIES_ASYNC,
    CLEAN_GET_SETUP_KIT_COMPONENTS,
} from './../../Constants'



export const requestTrPrEx = () => {
    return {
        type: REQUEST_TRPREX_PRODUCTS,
        payload: {}
    }
}

export const requestFavorites = () => {
    let clientId = 0
    if(localStorage.getItem("ZUsaUInfo")){
        clientId = JSON.parse(localStorage.getItem("ZUsaUInfo")).idClient
    } 
    return {
        type: REQUEST_FAVORITES,
        payload: {clientId}
    }
}

export const requestAllProducts = () => {
    return {
        type: REQUEST_ALL_PRODUCTS,
        payload: {}
    }
}

export const requestSetProductOnFavorite = (itemCode, typeLine, isFavorite, withNonStock) => {
    let clientId = 0
    if(localStorage.getItem("ZUsaUInfo")){
        clientId = JSON.parse(localStorage.getItem("ZUsaUInfo")).idClient
    } 
    return {
        type: REQUEST_SET_PRODUCT_ON_FAVORITES,
        payload: {
            clientId, itemCode, typeLine, isFavorite, withNonStock
        }
    }
}

export const requestGetZcareProduct = () => {
    return {
        type: REQUEST_GET_ZCARE_PRODUCT,
        payload: {}
    }
}

export const requestGetSetupKitComponents = (itemCode) => {
    return {
        type: REQUEST_GET_SETUP_KIT_COMPONENTS,
        payload: {
            itemCode
        }
    }
}
export const requestGetAdmissionKits = () => {
    return {
        type: REQUEST_GET_ADMISSION_KITS,
        payload: {
        }
    }
}

export const requestGetBeautyBoxes = () => {
    return {
        type: REQUEST_GET_BEAUTY_BOXES,
        payload: {
        }
    }
}

export const cleanSetFavoritesReducer = () => {
    return {
        type: CLEAN_SET_FAVORITES_REDUCER,
        payload: {}
    }
}

///
export const requestAllProductsByLang = (idLang) => {
    return {
        type: REQUEST_ALL_PRODUCTS_BY_LANG,
        payload: {
            idLang
        }
    }
}

///accion para editar el reducer de las promos
export const requestAddSetCategoriesReducer = () => {
    return {
        type: REQUEST_ADD_SET_CATEGORIES_REDUCER,
        payload: {}
    }
}
///accion para editar el reducer de las promos
export const requestRemoveSetCategoriesReducer = () => {
    return {
        type: REQUEST_REMOVE_SET_CATEGORIES_REDUCER,
        payload: {}
    }
}

////ASYNC CALL FIRST CHARGE\\\\
export const requestAllProductsAsync = () => {
    return {
        type: REQUEST_ALL_PRODUCTS_ASYNC,
        payload: {}
    }
}
export const requestAllCaregoriesAsync = () => {
    return {
        type: REQUEST_ALL_CAREGORIES_ASYNC,
        payload: {}
    }
}
export const requestAllProductFamiliesAsync = () => {
    return {
        type: REQUEST_ALL_PRODUCT_FAMILIES_ASYNC,
        payload: {}
    }
}

export const cleanGetSetupKitComponents = () => {
    return {
        type:  CLEAN_GET_SETUP_KIT_COMPONENTS,
        payload: {}
    }
}