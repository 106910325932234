import { 
    REACT_APP_FACEBOOK_ENVIROMENT_URL,
    REACT_APP_FACEBOOK_VERSION_API,
    REACT_APP_FACEBOOK_ACCESS_TOKEN
} from '../../React/ReduxSaga/Constants';

class fbApi {

    static GetInstagramPosts() {
        return fetch(`${REACT_APP_FACEBOOK_ENVIROMENT_URL}${REACT_APP_FACEBOOK_VERSION_API}me/businesses?fields=instagram_accounts,instagram_business_accounts{media_count,media.limit(10){media_url,media_type,media_product_type,like_count,timestamp,permalink,caption,owner,username,comments_count},profile_picture_url,username,biography}&access_token=${REACT_APP_FACEBOOK_ACCESS_TOKEN}`)
            .then((response) => response.json())
            .then((json) => {
                return json.data;
            })
            .catch((error) => {
                console.error(error);
            });
    }

}

export default fbApi