import React, { useEffect, useState } from "react";
import { Typography, Link as LinkOverride } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { requestClientAdministrationMenu } from "./../../ReduxSaga/Actions/Client";
import { requestLoggUserActivity } from "./../../ReduxSaga/Actions/General";
import { requestClientLogOut } from "./../../ReduxSaga/Actions/Client";

const ProfileContainer = (props) => {
  //Notistack
  const { enqueueSnackbar } = useSnackbar();

  /// HISTORY
  const history = useHistory();
  /// TRANSLATION
  const { t, i18n } = useTranslation();
  /// REDUX
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.client = useSelector((state) => state.client);

  ///HOOKS
  // const [dataNotification, setDataNotification] = useState({
  //   isOpen: false,
  //   type: "",
  //   message: "",
  //   icon: "",
  // });
  const [userLogedInfo, setUserLogedInfo] = useState({});
  const [clientAministrationMenu, setClientAministrationMenu] = useState([]);

  ///EFFECTS
  useEffect(() => {
    if (localStorage.getItem("ZUsaUInfo")) {
      setUserLogedInfo(JSON.parse(localStorage.getItem("ZUsaUInfo")));
    }
  }, [localStorage.getItem("ZUsaUInfo")]);

  useEffect(() => {
    if (
      typeof appReducers?.client?.errorGetClientAdministrationMenu ===
      "undefined"
    ) {
      //EJECUTAR ESTE CUANDO SE LOGGE
      dispatch(requestClientAdministrationMenu());
    } else {
      if (!appReducers?.client?.errorGetClientAdministrationMenu) {
        if (i18n.language === "en")
          setClientAministrationMenu(
            appReducers?.client?.clientAdministrationMenu
              .filter((m) => m.idLang === 2)
              .sort((a, b) => a.orderRender - b.orderRender)
          );

        if (i18n.language === "es")
          setClientAministrationMenu(
            appReducers?.client?.clientAdministrationMenu
              .filter((m) => m.idLang === 1)
              .sort((a, b) => a.orderRender - b.orderRender)
          );
      } else {
        //error no se cargó el menú debido a una falla en el servicio que lo obtiene
        enqueueSnackbar(t("__myAccount_Load_Client_Menu_Error_Message"), {
          variant: "error",
        });
        // setDataNotification({
        //   isOpen: true,
        //   type: "error",
        //   message: t("__myAccount_Load_Client_Menu_Error_Message"),
        // });
      }
    }
  }, [
    appReducers?.client?.errorGetClientAdministrationMenu,
    appReducers?.client?.clientAdministrationMenu,
    i18n.language,
  ]);

  return (
    <div className="firstWrapperInBaseContainer profile">
      {/* <BreadcrumbsNavigationComponet /> */}
      {/* <NotificationSnackbar
        isOpenNotification={dataNotification.isOpen}
        setIsOpenNotification={setDataNotification}
        message={dataNotification.message}
        status={dataNotification.type}
      /> */}
      {userLogedInfo.hasOwnProperty("isGuestUser") &&
        !userLogedInfo.isGuestUser && (
          <div className="profile__container">
            <div>
              <Typography
                className="profile__container__title"
                variant="h6"
                gutterBottom
              >
                {t("__myAccount_Module_Title")}
              </Typography>
              <Typography
                className="profile__container__subtitle"
                variant="subtitle1"
                gutterBottom
              >
                {`${t("__myAccount_Favorites_Module_SubTitle")} ${
                  userLogedInfo.nameClient
                }`}
              </Typography>
            </div>

            <div className="profile__container__content">
              {clientAministrationMenu?.length > 0 &&
                clientAministrationMenu.map((menu, index) => (
                  <div
                    className="profile__container__content__item"
                    key={index}
                  >
                    {menu.idModContent === 31 ? (
                      <LinkOverride
                        className="profile__link"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(requestClientLogOut());
                          dispatch(
                            requestLoggUserActivity(
                              25,
                              0,
                              0,
                              "",
                              "Click en Cerrar Session"
                            )
                          );
                          //history.replace("/");
                          if (localStorage.getItem("ZUsaUrlPage")) {
                            history.replace(
                              `/mypage/${localStorage.getItem("ZUsaUrlPage")}`
                            );
                          } else {
                            history.replace("/home");
                          }
                        }}
                      >
                        <div className="profile__container__content__item__left">
                          <div className={menu.image} />
                        </div>
                        <div className="profile__container__content__item__rigth">
                          <Typography
                            variant="body2"
                            className="profile__container__content__item__title"
                            gutterBottom
                          >
                            {menu.nameModConten}
                          </Typography>
                          <Typography
                            className="profile__container__content__item__description"
                            variant="caption"
                            display="block"
                            gutterBottom
                          >
                            {menu.descriptionModConten}
                          </Typography>
                        </div>
                      </LinkOverride>
                    ) : (
                      <Link
                        className="profile__link"
                        to={menu.anchorResources}
                        onClick={() => {
                          dispatch(
                            requestLoggUserActivity(
                              menu.idModContent === 27
                                ? 51 //actualizar informacion
                                : menu.idModContent === 28
                                ? 48 //listado de pedidos
                                : menu.idModContent === 29
                                ? 49 //listados de favoritos
                                : menu.idModContent === 30
                                ? 50 //listado de referidos
                                : 0,
                              0,
                              0,
                              "",
                              "Click en: " +
                                (menu.idModContent === 27
                                  ? "Mis Datos" //actualizar informacion
                                  : menu.idModContent === 28
                                  ? "My shopping" //listado de pedidos
                                  : menu.idModContent === 29
                                  ? "My favorites" //listados de favoritos
                                  : menu.idModContent === 30
                                  ? "Referidos registrados" //listado de referidos
                                  : "")
                            )
                          );

                          props?.handleGAEvent(
                            "User",
                            "Usuario Navega A " + menu.nameModConten
                          );
                        }}
                      >
                        <div className="profile__container__content__item__left">
                          <div className={menu.image} />
                        </div>
                        <div className="profile__container__content__item__rigth">
                          <Typography
                            variant="body2"
                            className="profile__container__content__item__title"
                            gutterBottom
                          >
                            {menu.nameModConten}
                          </Typography>
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                            className="profile__container__content__item__description"
                          >
                            {menu.descriptionModConten}
                          </Typography>
                        </div>
                      </Link>
                    )}
                  </div>
                ))}

              {/* <div className="profile__container__content__item">
              <Link className="profile__link" to="/profile/shopping-list">
                <div className="profile__container__content__item__left">
                  <div className="profile__container__content__icons__shop" />
                </div>
                <div className="profile__container__content__item__rigth">
                  <Typography variant="body2" gutterBottom>
                    MIS COMPRAS
                  </Typography>
                  <Typography variant="caption" display="block" gutterBottom>
                    Consulta tus compras y haz seguimiento de tus pedidos.
                  </Typography>
                </div>
              </Link>
            </div>
            <div className="profile__container__content__item">
              <Link className="profile__link" to="/profile/my-favorites-list">
                <div className="profile__container__content__item__left">
                  <div className="profile__container__content__icons__favorite" />
                </div>
                <div className="profile__container__content__item__rigth">
                  <Link to={"/profile/my-favorites-list"}>
                    <Typography variant="body2" gutterBottom>
                      MIS FAVORITOS
                    </Typography>
                  </Link>
                  <Typography variant="caption" display="block" gutterBottom>
                    Consulta los productos que más has amado.
                  </Typography>
                </div>
              </Link>
            </div>
            <div className="profile__container__content__item">
              <Link className="profile__link" to="/profile/friends-referred">
                <div className="profile__container__content__item__left">
                  <div className="profile__container__content__icons__refer" />
                </div>
                <div className="profile__container__content__item__rigth">
                  <Typography variant="body2" gutterBottom>
                    AMIGOS REGISTRADOS
                  </Typography>
                  <Typography variant="caption" display="block" gutterBottom>
                    Consulta y comparte tus experiencias en Zermat.
                  </Typography>
                </div>
              </Link>
            </div>
            <div className="profile__container__content__item"> 
              <Link className="profile__link" to="/">
                <div className="profile__container__content__item__left">
                  <div className="profile__container__content__icons__logout" />
                </div>
                <div className="profile__container__content__item__rigth">
                  <Typography variant="body2" gutterBottom>
                    CERRAR SESIÓN
                  </Typography>
                </div>
                <div></div>
              </Link>
            </div>*/}
            </div>
          </div>
        )}
    </div>
  );
};

export default ProfileContainer;
