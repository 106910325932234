import GraphqlHandler from "../GraphqlHandler";
import { ENVIROMENT_URL } from "../../React/ReduxSaga/Constants";

const route = ENVIROMENT_URL + "apiV3/PaymentMicroservice/Payment";
const routePaypal = ENVIROMENT_URL + "apiV3/PaymentMicroservice/Paypal";
//const routePaypal = 'http://localhost:62976/Paypal';
class Payment {
  static CreateOrderOnPaypal = (orderId) => {
    const headers = new Headers();
    headers.append("content-type", "application/json");
    headers.append("Authorization", localStorage.getItem("ZUsa.User"));
    headers.append(
      "SessionAuth",
      JSON.parse(localStorage.getItem("ZUsa.UserInfo"))?.sessionId
        ? JSON.parse(localStorage.getItem("ZUsa.UserInfo")).sessionId
        : 0
    );
    return fetch(`${routePaypal}?idOrder=${orderId}`, {
      method: "GET",
      headers: headers,
    });
  };
  static AproveOrderOnPaypal = (model) => {
    let sessionId = 0;
    if (localStorage.getItem("ZUsaUInfo")) {
      sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
    }
    const headers = new Headers();
    headers.append("content-type", "application/json");
    headers.append("Authorization", localStorage.getItem("ZUsa.User"));
    headers.append("SessionAuth", sessionId);
    model.sessionId = sessionId;
    return fetch(routePaypal, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(model),
    });
  };
  static StripeCreditCardPayment = (stripePaymentModel, orderId) => {
    const result = GraphqlHandler.openRequestGraphQlMutation(
      route,
      //'https://localhost:44325/Payment',
      `(@autodeclare){
                payment_StripRequest_Payment(stripePaymentModel:$stripePaymentModel, orderId:$orderId){
                    code
                    message
                    responseModel
                }
            }`,
      true,
      {
        "stripePaymentModel!StripeRequestPaymentType": stripePaymentModel,
        "orderId!Int": orderId,
      }
    );
    return result;
  };
  //2023
  static StripePayment2023 = (stripePaymentModel, orderId, closeOrderModel) => {
    const result = GraphqlHandler.openRequestGraphQlMutation(
      route,
      //'https://localhost:44325/Payment',
      `(@autodeclare){
            payment_StripRequest_Payment_2023(stripePaymentModel:$stripePaymentModel, orderId:$orderId, closeOrderModel:$closeOrderModel){
                code
                message
                responseModel
                user {
                    email
                    idClient
                    idGuestUser
                    idLang
                    idPage
                    idUser
                    isGuestUser
                    isOlder
                    nameClient
                    sessionId
                    sponsorId
                    typeUser
                }
            }
        }`,
      true,
      {
        "stripePaymentModel!StripeRequestPaymentType": stripePaymentModel,
        "orderId!Int": orderId,
        "closeOrderModel!CloseOrderRequestType": closeOrderModel,
      }
    );
    return result;
  };
}

export default Payment;
