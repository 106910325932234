import GraphqlHandlerNoInterceptor from '../GraphqlHandler/noInterceptor'
import { ENVIROMENT_URL } from '../../React/ReduxSaga/Constants';

const route = ENVIROMENT_URL + 'apiV3/GeneralMicroservice/General'

class HomeModule {
  static GetHomeModule() {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      route,
      //'http://localhost:59622/api/ClientSession',
      `{
              getModules
                {
                  code,
                  message,
                  home
                      {
                        id,
                        idModuleType,
                        moduleName,
                        sections
                        {
                          anchorResources,
                          idLang,
                          idMod,
                          idModContent,
                          idModContentRes,
                          idModContentType,
                          idModContentResType
                          nameModConten,
                          orderRender,
                          valueResources,
                          content
                          {
                            id,
                            anchor,
                            idLang,
                            idModuleContentResources,
                            idModuleContentResourcesDetailType,
                            image,
                            itemCode,
                            name,
                            price,
                            value
                          }
                        }
                      }
                } 
              }`,
      false
    );
    return result;
  }
  static GetEntrepenourHomeModule = sessionId => {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      route,
      `
      (@autodeclare){
        getEntrepenourModules(sessionId:$sessionId)
          {
            code
            message
            pageOwner
              {
                cellPhone,
                email,
                imageProfile,
                facebook,
                idClient,
                idClubPR,
                idClubVIP,
                nameClient,
                nameClubPR,
                nameClubVIP,
                testimonial,
                twitter,
                youTube,
                experiences
                {
                  idTypeExperience,
                  imageExperience,
                  descriptionExperience
                }
              },
              entrepenourHome
              {
                id,
                idModuleType,
                moduleName,
                sections
                {
                  anchorResources,
                  idLang,
                  idMod,
                  idModContent,
                  idModContentRes,
                  idModContentType,
                  idModContentResType
                  nameModConten,
                  orderRender,
                  valueResources,
                  content
                  {
                    id,
                    anchor,
                    idLang,
                    idModuleContentResources,
                    idModuleContentResourcesDetailType,
                    image,
                    itemCode,
                    name,
                    price,
                    value
                  }
                }
              }
          }
        }
      `,
      true,
      {
        "sessionId!Int": sessionId
      }
    );
    return result;
  }
  //cliente Preferido Modulo
  static GetPreferentClientModule() {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      route,
      //'http://localhost:59622/api/ClientSession',
      `{
        getModules{
          code
          message    
          preferredCustomer{
            id,
            idModuleType,
            moduleName,
            sections
            {
              anchorResources,
              idLang,
              idMod,
              idModContent,
              idModContentRes,
              idModContentType,
              idModContentResType
              nameModConten,
              orderRender,
              valueResources,
              content
              {
                id,
                anchor,
                idLang,
                idModuleContentResources,
                idModuleContentResourcesDetailType,
                image,
                itemCode,
                name,
                price,
                value
              }
            }
          }
        }
      }
      `,
      false
    );
    return result;
  }

  //Emprende Modulo
  static GetEnterprenourZModule() {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      route,
      //'http://localhost:59622/api/ClientSession',
      `{
        getModules{
          code
          message    
          getStartedZermat{
            id,
            idModuleType,
            moduleName,
            sections
            {
              anchorResources,
              idLang,
              idMod,
              idModContent,
              idModContentRes,
              idModContentType,
              idModContentResType
              nameModConten,
              orderRender,
              valueResources,
              content
              {
                id,
                anchor,
                idLang,
                idModuleContentResources,
                idModuleContentResourcesDetailType,
                image,
                itemCode,
                name,
                price,
                value
              }
            }
          }
        } 
      }`,
      false
    );
    return result;
  }

  //ZCare Modulo
  static GetZCareModule() {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      route,
      //'http://localhost:59622/api/ClientSession',
      `{
        getModules{
          code
          message    
          zCare{
            id,
            idModuleType,
            moduleName,
            sections
            {
              anchorResources,
              idLang,
              idMod,
              idModContent,
              idModContentRes,
              idModContentType,
              idModContentResType
              nameModConten,
              orderRender,
              valueResources,
              content
              {
                id,
                anchor,
                idLang,
                idModuleContentResources,
                idModuleContentResourcesDetailType,
                image,
                itemCode,
                name,
                price,
                value
              }
            }
          }
        } 
      }`,
      false
    );
    return result;
  }
}

export default HomeModule