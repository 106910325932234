import React, { useState } from "react";
import PayScreen from "../../../../assets/media/images/NewMembers/screenPay.png";
import ThanksScreen from "../../../../assets/media/images/NewMembers/screenThanks.png";

const PayKitNewMembers = ({
  setIsPayShow,
}) => {
  const [isPay, setIsPay] = useState(false);
  return (
    <div>
      {!isPay ? (
        <img
          onClick={() => {
            setIsPay(true);
          }}
          src={PayScreen}
        />
      ) : (
        <img
          onClick={() => {
            setIsPayShow(false);
          }}
          src={ThanksScreen}
        />
      )}
    </div>
  );
};

export default PayKitNewMembers;
