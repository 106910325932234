import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import {ENVIROMENT_URL} from "./../../React/ReduxSaga/Constants"
// import translationEN from "../../resources/locale-en_US.json";
// import translationES from "../../resources/locale-es_MX.json";

// const resources = {
//   en: {
//     translation: translationEN,
//   },
//   es: {
//     translation: translationES,
//   },
// };

// i18n
//   .use(initReactI18next) // passes i18n down to react-i18next
//   .init({
//     resources,
//     lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
//     // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
//     // if you're using a language detector, do not define the lng option
//     debug: false,
//     react: {
//       wait: true,
//       withRef: false,
//       bindI18n: "languageChanged loaded",
//       bindStore: "added removed",
//       nsMode: "default",
//     },
//     interpolation: {
//       escapeValue: false, // react already safes from xss
//     },
//   });

const options = {
  loadPath: `${ENVIROMENT_URL}apiV3/GeneralMicroservice/{{ns}}/{{lng}}`,
  //loadPath: "https://www.websizusa.net/apiV3/GeneralMicroservice/{{ns}}/{{lng}}",
  crossDomain: true,
  requestOptions: {
    cache: "no-store",
  },
  //reloadInterval: 1000
};

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    lng: "en",
    fallbackLng: false,
    preload: ["es"],
    ns: "Translation",
    backend: options,
    debug: false,
    react: {
      wait: true,
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
