import GraphqlHandlerNoInterceptor from "../GraphqlHandler/noInterceptor";
import { ENVIROMENT_URL } from '../../React/ReduxSaga/Constants';

const route = ENVIROMENT_URL + 'apiV3/ValidationMicroservice/Validation'

class Address {
  static AddressValidation(jsonAddress, type, dbCheck) {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      route,
      //'http://localhost:59622/api/ClientSession',
      `(@autodeclare){
        clientSession_AddressValidate(jsonAddress:$jsonAddress,type:$type ,dbCheck:$dbCheck)
        {
            code,
            message
            addressListSugestion
            {
              address1,
              address2,
              city,
              state,
              suite,
              zip
            }
        }     
               
      }`,
      true,
      {
        "jsonAddress": JSON.stringify(jsonAddress),
        type,
        dbCheck
      }
    );
    return result;
  }
}

export default Address