import React from "react";
import { IconButton } from "@mui/material";
import { isMobile, isTablet } from "react-device-detect";
const TextFieldAndButtonComponent = ({
  labelName,
  placeholderInput,
  erroHelp,
  isActiveHelp,
  valueInput,
  typeInput,
  setValue,
  iconClass,
  handleEventButton,
  disabledButton,
  autoFocus,
  withRipple,
}) => {
  return (
    <div className="textfield__button inner">
      <label htmlFor={labelName}>{labelName}</label>
      <div>
        <div className="textfield__button wrapper_input_erroHelp">
          <input
            id={labelName}
            placeholder={placeholderInput !== "" ? placeholderInput : ""}
            type={typeInput}
            value={valueInput}
            aria-describedby="my-helper-text"
            onInput={setValue}
            autoFocus={autoFocus}
          />
          {isActiveHelp && (
            <span className="errorHelp" id="my-helper-text">
              {erroHelp}
            </span>
          )}
        </div>

        <IconButton
          className={`${
            withRipple ? "with__ripple red__ripple" : ""
          } textfield__button iconButton ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
          onClick={handleEventButton}
          disabled={disabledButton}
        >
          <div className={`${iconClass}`} />
        </IconButton>
      </div>
    </div>
  );
};

export default TextFieldAndButtonComponent;
