import { RESPONSE_EMAIL_VALIDATION } from "./../../Constants";

export default function email(state = {}, action) {
  switch (action.type) {
    case RESPONSE_EMAIL_VALIDATION: {
      const responseEmailValidation = action.payload.response;
      const code = responseEmailValidation.code;
      if (code === 100) {
        return { ...state, errorEmailValidation: false, code };
      } else {
        console.error(
          "error al validar el Correo Electrónico",
          responseEmailValidation
        );
        return { ...state, errorEmailValidation: true, code };
      }
    }
    default:
      return { ...state };
  }
}
