import React, { Fragment } from "react";
import {
  Typography,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
} from "@mui/material";
import { Room, Error, Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { isMobile, isTablet } from "react-device-detect";

const AddressValidation = ({
  setIsOpenValidationModal,
  addressListSugestion,
  handlerSelectAddressFromSugestion,
  open,
  type,
}) => {
  /// TRANSLATION
  const { t } = useTranslation();

  const handleCloseValidationModal = (o, reason) => {
    if (reason !== "backdropClick") setIsOpenValidationModal(false);
  };
  return (
    <div
      className={`shopping__payment__container__boxMain__address__validation right ${
        open ? "expanded" : ""
      } ${isTablet ? "tablet" : isMobile ? "mobile" : ""}`}
    >
      <Paper
        className={`shopping__payment__container__boxMain__address__validation__contentMain ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }`}
      >
        <div
          className={`shopping__payment__container__boxMain__address__validation__contentMain__title ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          <div className="modal__validation__title__wrapper__left">
            <Typography variant="subtitle1" gutterBottom>
              {t("__modal_validation_address_title")}
            </Typography>
          </div>
          <div className="modal__validation__title__wrapper__rigth">
            <IconButton
              onClick={() => {
                setIsOpenValidationModal(false);
              }}
            >
              <Close />
            </IconButton>
          </div>
        </div>
      </Paper>
      <div className="modal__validation__list__addresses">
        <List component="nav" aria-label="main mailbox folders">
          {addressListSugestion &&
            addressListSugestion.map((item, index) => (
              <Fragment key={index}>
                <ListItem
                  button
                  onClick={() =>
                    handlerSelectAddressFromSugestion(
                      item.address1,
                      item.suite,
                      item.state,
                      item.city,
                      item.zip,
                      type
                    )
                  }
                >
                  <ListItemIcon>
                    <Room />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${item.address1} ${item.suite}`}
                    secondary={`${item.city}, ${item.state} ${item.zip}`}
                  />
                </ListItem>
                <Divider />
              </Fragment>
            ))}
        </List>
      </div>
    </div>
  );
};

export default AddressValidation;
