import React, { Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, IconButton, Typography, Paper } from "@mui/material";
import { Close } from "@mui/icons-material";
import ConfettiSVG from "./ConfettiSVG";

const ModalCongratulations = ({
  isOpenCongratulations,
  setIsOpenCongratulations,
  title,
  message,
  isButtonActive,
  textButton,
  navigationRoute,
  image,
  navigationClose,
  requestLoggUserActivity,
  dispatch,
}) => {
  const history = useHistory();

  const handleCloseCongratulationsModal = () => {
    setIsOpenCongratulations(false);
  };
  return (
    <Fragment>
      <div className="modal__congratulations__confetti">
        {isOpenCongratulations && <ConfettiSVG />}

        <Paper
          elevation={3}
          className="modal__congratulations__confetti__content"
        >
          <div className="modal__congratulations__confetti__header">
            {navigationClose ? (
              <Close
                onClick={() => {
                  //debugger;
                  //// V1 ////
                  ////history.replace(navigationClose);

                  //// V2 ////
                  if (localStorage.getItem("ZUsaUrlPage")) {
                    history.replace(
                      `/mypage/${localStorage.getItem("ZUsaUrlPage")}`
                    );
                  } else {
                    history.replace("/home");
                  }
                }}
              />
            ) : (
              <IconButton
                onClick={handleCloseCongratulationsModal}
                aria-label="close"
                color="primary"
              >
                <Close />
              </IconButton>
            )}
          </div>
          <div className="modal__congratulations__confetti__information">
            <div>{image && <img src={image} alt="icon" />}</div>
            <div className="modal__congratulations__confetti__information__message">
              <Typography variant="h5" component={"p"} gutterBottom>
                {title}
              </Typography>
              <Typography component={"span"} variant="body2" gutterBottom>
                {message}
              </Typography>
            </div>
            <div>
              {isButtonActive && (
                <Button
                  className="button__style"
                  variant="contained"
                  onClick={() => {
                    //// V1 ////
                    // if (navigationRoute) {
                    //   history.replace(navigationRoute);
                    // } else {
                    //   history.replace("/");
                    // }

                    //// V2 ////
                    if (localStorage.getItem("ZUsaUrlPage")) {
                      history.replace(
                        `/mypage/${localStorage.getItem("ZUsaUrlPage")}`
                      );
                    } else {
                      history.replace("/home");
                    }

                    //dispatch(requestLoggUserActivity(3,0,0,"","Usuario registrado de forma Exitosa"));
                  }}
                >
                  {textButton}
                </Button>
              )}
            </div>
          </div>
        </Paper>
      </div>
    </Fragment>
  );
};

export default ModalCongratulations;
