import React from "react";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as ErrorPlaceholder } from "./../../../../assets/media/images/errorPlaceholder.svg";

const NotFoundComponent = ({ isLanguageLoaded }) => {
  //Translation
  const { t } = useTranslation();
  return (
    <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__placeholder">
      <ErrorPlaceholder />
      <span className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__placeholder__title">
        {isLanguageLoaded ? (
          t("configurables__placeholder__title")
        ) : (
          <Skeleton variant="rect" width={"40%"} height={30} />
        )}
      </span>
      <span className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__placeholder__subtitle">
        {isLanguageLoaded ? (
          t("configurables__placeholder__subtitle")
        ) : (
          <Skeleton variant="rect" width={"40%"} height={30} />
        )}
      </span>
    </div>
  );
};

export default NotFoundComponent;
