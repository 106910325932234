import { call, put, takeLatest } from 'redux-saga/effects'
import {
    REQUEST_EMAIL_VALIDATION,
    RESPONSE_EMAIL_VALIDATION,
    DATA_FETCH_FAILED,
} from './../../Constants'
import Email from '../../../../Libraries/Email'

function* requestEmailValidation(data){
    try {
        const response = yield call(Email.EmailValidation,data.payload.email, data.payload.dbCheck);
        yield put({type: RESPONSE_EMAIL_VALIDATION, payload: {response:response.validateEmail}});

    } catch (error) {
        console.error("client address validation", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}


export function* watchRequestEmailValidation() {
    yield takeLatest(REQUEST_EMAIL_VALIDATION, requestEmailValidation);
}

