import {
    RESPONSE_NEWMEMBER_REGISTER_NEW_PROSPECT,
    RESPONSE_NEWMEMBER__GET_REGISTERED_NEW_PROSPECT,
} from './../../Constants'


export default function newMember(state = {}, action) {
    switch (action.type) {
        case RESPONSE_NEWMEMBER_REGISTER_NEW_PROSPECT:
            {
                const responseRegistration = action.payload.response            
                const code = responseRegistration.code
                if(code !== 100){
                    console.error("error al registrar el prospecto del formulario de nuevos miembros",responseRegistration )
                }
                return { ...state, responseRegistration }
            }
        case RESPONSE_NEWMEMBER__GET_REGISTERED_NEW_PROSPECT:
            {
                const responseGetRegistration = action.payload.response            
                const code = responseGetRegistration.code
                if(code !== 100){
                    console.error("error al obtener los datos del recien registrado en el formulario de nuevos miembros",responseGetRegistration )
                }
                return { ...state, responseGetRegistration }
            }
        default:
            return { ...state }
    }

}