import React, { Fragment } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Error, Block } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import ExclamationIcon from "../../../../assets/media/images/ModalIcons/exclamation.svg";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const ModalConfirmRepayment = ({
  isOpenModalConfirm,
  setIsOpenModalConfirm,
  setContenShow,
  setIsOpenNotification,

  sendPetitionRefund,
}) => {
  /// TRANSLATION
  const { t } = useTranslation();

  //REDUX
  let history = useHistory();

  const handleCloseModal = () => {
    setIsOpenModalConfirm(false);
  };

  return (
    <Fragment>
      <Dialog
        className="modal__confirm__repayment__content"
        open={isOpenModalConfirm}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="modal__confirm__repayment__content__title"
        >
          <IconButton
            onClick={handleCloseModal}
            aria-label="close"
            color="primary"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <DialogContentText className="modal__confirm__repayment__content__text">
            <Error className="redIcon mediumIcon" />
            <Typography
              className="message__repayment "
              variant="subtitle1"
              gutterBottom
            >
              {t("__profile_favorites_modal_confirm_repayment")}
            </Typography>
            <Typography
              className="message__repayment"
              variant="body2"
              component={"p"}
              gutterBottom
            >
              {t("__profile_favorites_modal_return_repayment")}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="modal__confirm__repayment__content__buttons">
          <Button
            onClick={handleCloseModal}
            className="button__with__icon bg__pink no__left__margin"
          >
            <div className="button__with__icon__svg__wrapper pink__ball">
              <Block className="redIcon" />
            </div>
            <span className="text__red"> {t("btn_text_cancel")}</span>
          </Button>

          <Button
            onClick={() => {
              //ENVIO DE LA PETICION
              sendPetitionRefund();

              //NOTIFICACION DE LA RESPUESTA DE LA DEVOLUCION
              setIsOpenNotification(true);

              //REDIRECT A LA LISTA DE MIS COMPRAS
              setTimeout(() => {
                history.push("/profile/shopping-list");
              }, 3000);
            }}
            className="button__with__icon bg__dark__pink no__left__margin"
          >
            <div className="button__with__icon__svg__wrapper dark__pink__ball">
              <div className="airplaneWhiteIcon" />
            </div>
            <span> {t("__profile_favorites_modal_accept_repayment")}</span>
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ModalConfirmRepayment;
