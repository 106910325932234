import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TextFieldComponent from "../../../base/components/TextFieldComponent";
import ButtonComponent from "../../../base/components/ButtonComponent";
import {
  regexIsCellphoneValid,
  regexIsEmailAddressValid,
  restrictToOnlyLettersForEmail,
  restrictToOnlyNumbersTwo,
} from "./../../../../../Libraries/Utils/regex";
///ACTIONS
import {
  requestRegisterSupportQuestion,
  requestCleanSupportQuestionReducer,
} from "./../../../../ReduxSaga/Actions/Client";

import { isTablet, isMobile } from "react-device-detect";
import { Skeleton } from "@mui/material";
const CustomerSupport = ({ enqueueSnackbar, isLanguageLoaded }) => {
  /// TRANSLATION
  const { t } = useTranslation();
  /// REDUX
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.client = useSelector((state) => state.client);
  ///states
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [question, setQuestion] = useState("");

  ////EFFECTS
  useEffect(() => {
    document.title = "Zermat USA :: Customer Support";
  }, []);

  useEffect(() => {
    if (
      typeof appReducers?.client?.registerSupportQuestionResponse !==
      "undefined"
    ) {
      if (appReducers?.client?.registerSupportQuestionResponse.code === 100) {
        enqueueSnackbar(t("__customer_support_message_notification_label"), {
          variant: "success",
        });
        dispatch(requestCleanSupportQuestionReducer());
        setName("");
        setEmail("");
        setCellPhone("");
        setQuestion("");
      } else {
        switch (appReducers?.client?.registerSupportQuestionResponse.code) {
          case -100:
            enqueueSnackbar(
              t("__customer_support_message_email_no_valid_label"),
              {
                variant: "error",
              }
            );
            break;
          case -500:
            enqueueSnackbar(t("__customer_support_message_erro_quest_label"), {
              variant: "error",
            });
            break;
          default:
            break;
        }
      }
    }
  }, [appReducers?.client?.registerSupportQuestionResponse]);

  //// handlers
  const handleSendEmail = () => {
    dispatch(requestRegisterSupportQuestion(name, email, cellPhone, question));
  };
  return (
    <Fragment>
      {/* <BreadcrumbsNavigationComponet /> */}
      <div
        className={`customer__support__container ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }  `}
      >
        <div
          className={`customer__support__container__card ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }  `}
        >
          <div
            className={`customer__support__container__card__contact ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }  `}
          >
            <div className="contact__title">
              {isLanguageLoaded ? (
                t("__customer_support_card_information_title")
              ) : (
                <Skeleton variant="rect" width={"95%"} height={30} />
              )}
            </div>
            <div className="contact__subtitle">
              {isLanguageLoaded ? (
                t("__customer_support_card_information_subtitle")
              ) : (
                <Skeleton variant="rect" width={"95%"} height={30} />
              )}
            </div>

            <form className="contact__form">
              <TextFieldComponent
                labelName={
                  isLanguageLoaded
                    ? t("__customer_support_form_name_label")
                    : ""
                }
                typeInput="text"
                valueInput={name}
                erroHelp={
                  isLanguageLoaded ? t("__forms_empty_field_error_message") : ""
                }
                isActiveHelp={name.length > 0 ? false : true}
                textareaEnabled={false}
                setValue={(e) => {
                  setName(e.target.value);
                }}
              />
              <TextFieldComponent
                labelName={
                  isLanguageLoaded
                    ? t("__customer_support_form_email_label")
                    : ""
                }
                typeInput="email"
                valueInput={email}
                erroHelp={
                  !regexIsEmailAddressValid(email)
                    ? isLanguageLoaded
                      ? t("__forms_email_validation_error_zerobounce_message")
                      : ""
                    : isLanguageLoaded
                    ? t("__forms_empty_field_error_message")
                    : ""
                }
                isActiveHelp={
                  email.length <= 0 || !regexIsEmailAddressValid(email)
                    ? true
                    : false
                }
                textareaEnabled={false}
                setValue={(e) => {
                  setEmail(restrictToOnlyLettersForEmail(e));
                }}
              />
              <TextFieldComponent
                labelName={
                  isLanguageLoaded
                    ? t("__customer_support_form_cell_label")
                    : ""
                }
                typeInput="tel"
                valueInput={cellPhone}
                erroHelp={
                  !regexIsCellphoneValid(cellPhone)
                    ? isLanguageLoaded
                      ? t("__forms_field_cellPhone_validation_js_error_message")
                      : ""
                    : isLanguageLoaded
                    ? t("__forms_empty_field_error_message")
                    : ""
                }
                isActiveHelp={
                  cellPhone.length <= 0 || !regexIsCellphoneValid(cellPhone)
                }
                textareaEnabled={false}
                setValue={(e) => {
                  setCellPhone(restrictToOnlyNumbersTwo(e));
                }}
              />
              <TextFieldComponent
                labelName={
                  isLanguageLoaded
                    ? t("__customer_support_form_message_label")
                    : ""
                }
                typeInput="text"
                valueInput={question}
                erroHelp={
                  isLanguageLoaded ? t("__forms_empty_field_error_message") : ""
                }
                isActiveHelp={question.length <= 0 ? true : false}
                textareaEnabled={true}
                // setValue={(e) => {
                //   setQuestion(e.target.value);
                // }}
                setValue={(e) => {
                  e.target.value === " "
                    ? e.target.value.trimStart()
                    : setQuestion(e.target.value);
                }}
              />

              <ButtonComponent
                disabledPrimary={
                  email.length <= 0 ||
                  !regexIsEmailAddressValid(email) ||
                  cellPhone.length <= 0 ||
                  !regexIsCellphoneValid(cellPhone) ||
                  question.length <= 0 ||
                  name.length <= 0
                }
                countButton={false}
                handleActionPrimary={handleSendEmail} //handleSendEmail
                handleActionSecondary={() => {}}
                valueCount=""
                iconClass={
                  email.length <= 0 ||
                  !regexIsEmailAddressValid(email) ||
                  cellPhone.length <= 0 ||
                  !regexIsCellphoneValid(cellPhone) ||
                  question.length <= 0 ||
                  name.length <= 0
                    ? "envelopeGrayIcon"
                    : "envelopeIcon"
                }
                textButton={
                  isLanguageLoaded ? t("__customer_support_form_btn_label") : ""
                }
                leftAling={true}
              />
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CustomerSupport;
