import React from "react";
import facebookIcon from "../../../../assets/media/images/NewMembers/facebook_icon.jpg";
import instagramIcon from "../../../../assets/media/images/NewMembers/instagram_icon.jpg";
import pinterestkIcon from "../../../../assets/media/images/NewMembers/pinterest_icon.jpg";
import tiktokIcon from "../../../../assets/media/images/NewMembers/tiktok_icon.jpg";
import youtubeIcon from "../../../../assets/media/images/NewMembers/youtube_icon.jpg";
import { isMobile, isTablet } from "react-device-detect";

const FooterNewMembers = ({ t, isLanguageLoaded }) => {
  return (
    <div
      className={`footer__new__members ${
        isTablet ? "tablet" : isMobile ? "mobile" : ""
      }`}
    >
      <span>{t("__new__members__footer__follow__zermat")}</span>
      <div className="footer__new__members__social__media">
        <a
          href="https://www.instagram.com/zermatus/?hl=en"
          rel="instagram"
          target="_blank"
        >
          <img alt="instagram" src={instagramIcon} />
        </a>
        <a
          href="https://www.tiktok.com/@zermatusa_official?_t=8b2MZyyX54c&_r=1"
          rel="tiktok"
          target="_blank"
        >
          <img alt="tiktok" src={tiktokIcon} />
        </a>
        <a
          href="https://www.facebook.com/zermatusa/"
          rel="facebook"
          target="_blank"
        >
          <img alt="facebook" src={facebookIcon} />
        </a>
        <a
          href="https://www.youtube.com/channel/UCNC80gWIoSGS8tO4oU_TkCg"
          rel="youtube"
          target="_blank"
        >
          <img alt="youtube" src={youtubeIcon} />
        </a>
        <a
          href="https://www.pinterest.com.mx/zermatusa/"
          rel="pinterest"
          target="_blank"
        >
          <img alt="youtube" src={pinterestkIcon} />
        </a>
      </div>
    </div>
  );
};

export default FooterNewMembers;
