import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { isTablet, isMobile } from "react-device-detect";
//MUI
import { Skeleton } from "@mui/material";
import { ArrowDropDown, ArrowDropUp, ArrowRight } from "@mui/icons-material";
//Components
import NewProductCard from "./NewProductCard";

const ProductsSections = ({
  backgroundClass,
  titleSection,
  ListProducts,
  t,
  isLoadingEnable,
  clientFavorites = [],
  routeNavigation,
  isLanguageLoaded,
  withExtraButton,

  enqueueSnackbar,
}) => {
  //React Router
  const history = useHistory();

  //State
  const [isShowMore, setIsShowMore] = useState(false);
  const [products, setProducts] = useState([]);

  //Handlers
  const handleShowMore = () => {
    setIsShowMore(!isShowMore);
  };

  const handleRouteNavigation = () => {
    history.push(routeNavigation);
  };

  useEffect(() => {
    if (ListProducts.length > 0) {
      const productsResult = ListProducts.filter((p) => {
        if (typeof p !== "undefined" && p.hasOwnProperty("idFamily")) {
          return p.idFamily <= 0;
        } else {
          return 1 === 0;
        }
      });
      const withFamilyProducts = [];
      const prodEvaWithFamily = ListProducts.filter((p) => {
        if (typeof p !== "undefined" && p.hasOwnProperty("idFamily")) {
          return p.idFamily > 0;
        } else {
          return 1 === 0;
        }
      });

      prodEvaWithFamily.forEach((item) => {
        if (
          !withFamilyProducts.find(
            (elemnt) => elemnt.idFamily === item.idFamily
          )
        ) {
          prodEvaWithFamily.sort((a, b) => a.orderRender - b.orderRender);

          const articleFinal = prodEvaWithFamily.find(
            (a) => a.itemCode === item.itemCode
          );
          withFamilyProducts.push(articleFinal);
          productsResult.push(articleFinal);
        }
      });
      setProducts(productsResult);
    }
  }, [ListProducts]);

  return (
    <div className={`section ${backgroundClass}`}>
      {isLoadingEnable ? (
        <div className="section__title">
          <Skeleton width={"30%"} height={50} />
          <Skeleton width={"20%"} height={50} />
        </div>
      ) : (
        <div className="section__title">
          <span>{titleSection}</span>

          {withExtraButton && (
            <div
              className={`section__arrow inner ${
                isShowMore ? `Active` : `Desactive`
              } ${isLoadingEnable ? "skeletonActive" : ""}`}
              onClick={handleShowMore}
            >
              {!isShowMore
                ? t("__home_section_product_see_more")
                : t("__home_section_product_see_less")}{" "}
              <div className="showMore arrowIcon">
                <ArrowDropUp
                  className={`icon--red ${
                    !isShowMore ? "is__collapsed" : ""
                  }`}
                />
              </div>
            </div>
          )}
        </div>
      )}

      {/* <div className="showMore">
        <div
          className={`section__arrow inner ${
            isShowMore ? `Active` : `Desactive`
          } ${isLoadingEnable ? "skeletonActive" : ""}`}
          onClick={handleShowMore}
        >
          {isLoadingEnable ? (
            <Skeleton width={"100%"} height={40} />
          ) : (
            <>
              {!isShowMore
                ? t("__home_section_product_see_more")
                : t("__home_section_product_see_less")}{" "}
              <div className="showMore arrowIcon">
                <ArrowDropUp
                  className={`icon--orange ${
                    !isShowMore ? "is__collapsed" : ""
                  }`}
                />
              </div>
            </>
          )}
        </div>
      </div> */}

      {isLoadingEnable ? (
        <div className="products__section__content__skeletons">
          <div className="products__section__content__skeletons__cards">
            {isMobile && !isTablet ? (
              <Skeleton width={300} height={300} variant="rect" />
            ) : isTablet ? (
              [0, 1, 2].map((item) => {
                return (
                  <Skeleton
                    key={item}
                    width={300}
                    height={300}
                    variant="rect"
                    stye={{ margin: "0 20px" }}
                  />
                );
              })
            ) : (
              [0, 1, 2, 3, 4].map((item) => {
                return (
                  <Skeleton
                    key={item}
                    width={300}
                    height={300}
                    variant="rect"
                    stye={{ margin: "0 20px" }}
                  />
                );
              })
            )}
          </div>
        </div>
      ) : (
        <Fragment>
          <div
            className={`products__section__main ${
              isShowMore ? `Active` : `Desactive`
            }`}
          >
            {!isShowMore ? (
              <>
                {products &&
                  products
                    .filter((p) => typeof p !== "undefined" && p !== null)
                    .slice(0, isTablet ? 3 : isMobile ? 1 : 5)
                    .map((product, index) => {
                      return (
                        <NewProductCard
                          t={t}
                          key={index}
                          product={product}
                          isLoadingEnable={isLoadingEnable}
                          clientFavorites={clientFavorites}
                          isLanguageLoaded={isLanguageLoaded}

                          enqueueSnackbar={enqueueSnackbar}
                        />
                      );
                    })}
              </>
            ) : (
              <>
                {products &&
                  products
                    .filter((p) => typeof p !== "undefined" && p !== null)
                    .slice(0, 15)
                    .map((product, index) => {
                      return (
                        <NewProductCard
                          t={t}
                          key={index}
                          product={product}
                          isLoadingEnable={isLoadingEnable}
                          clientFavorites={clientFavorites}
                          isLanguageLoaded={isLanguageLoaded}

                          enqueueSnackbar={enqueueSnackbar}
                        />
                      );
                    })}
              </>
            )}
          </div>
          {isShowMore && (
            <div className="remaining__products">
              <div
                className="remaining__products__wrapper"
                onClick={handleRouteNavigation}
              >
                {t("__home_section_product_see_more_products")}
                <ArrowRight className="icon--red" />{" "}
                <ArrowRight className="icon--red" />{" "}
                <ArrowRight className="icon--red" />
              </div>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default ProductsSections;
