import React, { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Navigation, Pagination, Autoplay, EffectCoverflow } from "swiper";
import { Error } from "@mui/icons-material";
import { Typography, InputAdornment } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { formatNumberWithCommas } from "./../../../../Libraries/Utils/utils";
import CustomerBadge from "../../base/components/CustomerBadge";
import { requestLoggUserActivity } from "../../../ReduxSaga/Actions/General";
import { useTranslation } from "react-i18next";

//SwiperCore.use([Navigation, Pagination, Autoplay, EffectCoverflow]);

const ProductsCarousel = ({ title, ListProducts, links }) => {
  useEffect(() => {
    //console.log("ListProducts", ListProducts);
  }, [ListProducts]);

  /// TRANSLATION
  const { t } = useTranslation();
  /// REDUX
  const dispatch = useDispatch();
  //console.log(ListProducts);
  return (
    <Fragment>
      {links.length > 4 ? (
        <Link
          className="navbar__content__link"
          to={links}
          onClick={() =>
            dispatch(
              requestLoggUserActivity(
                14,
                0,
                0,
                "",
                "Click en: " +
                  (links === "/trends"
                    ? "TENDENCIAS"
                    : links === "/promotions"
                    ? "PROMOCIONES"
                    : "")
              )
            )
          }
        >
          <h2>{title}</h2>
        </Link>
      ) : (
        <div className="navbar__content__link">
          <h2>{title}</h2>
        </div>
      )}
      <div className="products__carousel">
        {ListProducts?.length > 4 ? (
          <Swiper
            effect={"fade"}
            slidesPerView={4}
            spaceBetween={ListProducts?.length > 4 ? 6 : 70}
            slidesPerGroup={4}
            loop={ListProducts?.length > 4 ? true : false}
            //loopFillGroupWithBlank={false}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            // autoplay={{
            //   delay: 7000,
            //   disableOnInteraction: false,
            // }}
            autoplay={
              ListProducts?.length > 4
                ? { delay: 7000, disableOnInteraction: false }
                : false
            }
            breakpoints={{
              320: {
                slidesPerGroup: 1,
                slidesPerView: 1,
                spaceBetween: 10,
              },
              375: {
                slidesPerGroup: 1,
                slidesPerView: 1,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 3,
              },
              1025: {
                slidesPerView: 4,
              },
            }}
            modules={[Autoplay, Pagination, Navigation, EffectCoverflow]}
          >
            {ListProducts &&
              ListProducts.filter(
                (p) => typeof p !== "undefined" && p !== null
              ).map((product, index) => {
                return (
                  <SwiperSlide key={index}>
                    <Link
                      to={
                        localStorage.getItem("ZUsaUrlPage")
                          ? `/product-detail/${localStorage.getItem(
                              "ZUsaUrlPage"
                            )}/${product.itemCode}/${product.typeLine}`
                          : `/product-detail/${product.itemCode}/${product.typeLine}`
                      }
                      onClick={() =>
                        dispatch(
                          requestLoggUserActivity(
                            14,
                            product?.subCat3 > 0
                              ? product?.subCat3
                              : product?.subCat2 > 0
                              ? product?.subCat2
                              : product?.subCat1 > 0
                              ? product?.subCat1
                              : product?.idCatProductsCategoriesType,
                            product?.idCatProductsCategoriesType,
                            product?.itemCode,
                            "Click en el Articulo: " + product?.nameArticle
                          )
                        )
                      }
                    >
                      <div className="products__carousel__product">
                        {product.isLabelConfig && (
                          <CustomerBadge isDetailShow={false} />
                        )}

                        {!product.available && (
                          <div
                            id="soldOut"
                            className="detail__product__sold__out__carousel"
                          >
                            <div className="detail__product__sold__out__carousel__icon">
                              <InputAdornment position="end">
                                <Error />
                              </InputAdornment>
                            </div>
                            <div className="detail__product__sold__out__carousel__badge">
                              <div className="detail__product__sold__out__carousel__badge__motto">
                                <Typography>
                                  {t("__product_Detail_Module_Soldout_Text")}
                                </Typography>
                              </div>
                            </div>
                          </div>
                        )}

                        <img
                          className="products__carousel__product__image"
                          title={`${
                            product.nameArticle
                          } - ${product.descArticle.substring(0, 38)}...`}
                          alt={`${
                            product.nameArticle
                          } - ${product.descArticle.substring(0, 38)}...`}
                          src={product.image}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src =
                              "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
                          }}
                        />
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                          className="products__carousel__product__name"
                        >
                          {product.nameArticle}
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                          className="products__carousel__product__price"
                        >
                          {product.typeLine === "PD" ? (
                            //||
                            // product.typeLine === "P" ||
                            // product.typeLine === "FV" ||
                            // product.typeLine === "VV" ||
                            // product.typeLine === "VF"
                            <Fragment>
                              <em className="products__list__container__item__descripcion__price__promo">
                                ${formatNumberWithCommas(product.regularPrice)}
                              </em>
                              <em>
                                $
                                {formatNumberWithCommas(product.promotionPrice)}
                              </em>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <em className="products__list__container__item__descripcion__price__normal">
                                ${formatNumberWithCommas(product.regularPrice)}
                              </em>
                            </Fragment>
                          )}
                        </Typography>
                      </div>
                    </Link>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        ) : (
          <span>{""}</span>
        )}
      </div>
    </Fragment>
  );
};

export default ProductsCarousel;
