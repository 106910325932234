import React, { Fragment, useState } from "react";
import { Typography } from "@mui/material";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  Skeleton,
} from "@mui/lab";
import { useTranslation } from "react-i18next";
import latinasImage from "../../../../assets/media/images/zcare/ZCluchaMovil.jpg";
import reforestacion from "../../../../assets/media/images/zcare/ZCreforestarMovil.jpg";
import foodbank from "../../../../assets/media/images/zcare/ZCfoodMovil.jpg";
import toyZcare from "../../../../assets/media/images/zcare/ZCtoyMovil.jpg";
//PC
import latinasImagePC from "../../../../assets/media/images/zcare/ZClatinasPC.jpg";
import reforestacionPC from "../../../../assets/media/images/zcare/ZCforestPC.jpg";
import foodbankPC from "../../../../assets/media/images/zcare/ZCfoodBankPC.jpg";
import toyZcarePC from "../../../../assets/media/images/zcare/ZCtoyPC.jpg";

const ZcareTimeline = ({ isLanguageLoaded }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  /// TRANSLATION
  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Fragment>
      <div
        className={`zcare__container__achievements__title ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }`}
      >
        <Typography
          className="text__red"
          variant="h6"
          gutterBottom
          //style={{ width: isMobile ? "30%" : "50%" }}
        >
          {isLanguageLoaded ? (
            t("__zcare_timeline_title")
          ) : (
            <Skeleton
              variant="rect"
              width={"100%"}
              height={25}
              style={{ margin: "5px" }}
            />
          )}
        </Typography>
      </div>
      <div
        className={`zcare__container__achievements__timeline ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }`}
      >
        <Timeline
          align={isDesktop && !isMobile ? "left" : "alternate"}
          className={isDesktop && !isMobile ? "timeline" : ""}
        >
          <TimelineItem>
            <TimelineSeparator>
              <a href="#forest">
                <TimelineDot>
                  <div
                    className={
                      isDesktop && !isMobile
                        ? "zcare__container__achievements__timeline__icon__forest timeline__timelineIcon"
                        : "zcare__container__achievements__timeline__icon__forest"
                    }
                  />
                </TimelineDot>
              </a>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent
              className={
                isDesktop && !isMobile
                  ? "timeline__timelineContentContainer"
                  : ""
              }
            >
              <Typography
                variant="h6"
                component="h1"
                className={
                  isDesktop && !isMobile
                    ? `zcare__container__achievements__timeline__item__title timeline__timelineContent ${
                        isLanguageLoaded ? "" : "skeletonActive"
                      }`
                    : "zcare__container__achievements__timeline__item__title"
                }
              >
                {isLanguageLoaded ? (
                  t("__zcare_timeline_subtitle4")
                ) : (
                  <Skeleton
                    variant="rect"
                    width={isMobile ? "100%" : "390%"}
                    height={25}
                    style={{ margin: "5px" }}
                  />
                )}
              </Typography>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <a href="#latinas">
                <TimelineDot>
                  <div
                    className={
                      isDesktop && !isMobile
                        ? "zcare__container__achievements__timeline__icon__latinas timeline__timelineIcon"
                        : "zcare__container__achievements__timeline__icon__latinas"
                    }
                  />
                </TimelineDot>
              </a>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent
              className={
                isDesktop && !isMobile
                  ? "timeline__timelineContentContainer"
                  : ""
              }
            >
              <Typography
                variant="h6"
                component="h1"
                className={
                  isDesktop && !isMobile
                    ? `zcare__container__achievements__timeline__item__title timeline__timelineContent ${
                        isLanguageLoaded ? "" : "skeletonActive"
                      }`
                    : "zcare__container__achievements__timeline__item__title"
                }
              >
                {isLanguageLoaded ? (
                  t("__zcare_timeline_subtitle3")
                ) : (
                  <Skeleton
                    variant="rect"
                    width={isMobile ? "100%" : "390%"}
                    height={25}
                    style={{ margin: "5px" }}
                  />
                )}
              </Typography>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <a href="#toyZcare">
                <TimelineDot>
                  <div
                    className={
                      isDesktop && !isMobile
                        ? "zcare__container__achievements__timeline__icon__toys timeline__timelineIcon"
                        : "zcare__container__achievements__timeline__icon__toys"
                    }
                  />
                </TimelineDot>
              </a>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent
              className={isDesktop ? "timeline__timelineContentContainer" : ""}
            >
              <Typography
                className={
                  isDesktop && !isMobile
                    ? `zcare__container__achievements__timeline__item__title timeline__timelineContent ${
                        isLanguageLoaded ? "" : "skeletonActive"
                      }`
                    : "zcare__container__achievements__timeline__item__title"
                }
                variant="h6"
                component="h1"
              >
                {isLanguageLoaded ? (
                  t("__zcare_timeline_subtitle2")
                ) : (
                  <Skeleton
                    variant="rect"
                    width={isMobile ? "100%" : "390%"}
                    height={25}
                    style={{ margin: "5px" }}
                  />
                )}
              </Typography>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <a href="#foodBank">
                <TimelineDot>
                  {/* <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "center",
                    horizontal: "left",
                  }}
                >
                  La Felicidad que fluye alrededor de los niños es una alegría
                  inigualable que sin duda motivó a Z Cares
                </Popover> */}
                  <div
                    onClick={handleClick}
                    className={
                      isDesktop && !isMobile
                        ? "zcare__container__achievements__timeline__icon__food timeline__timelineIcon"
                        : "zcare__container__achievements__timeline__icon__food"
                    }
                  />
                </TimelineDot>
              </a>
            </TimelineSeparator>
            <TimelineContent
              className={
                isDesktop && !isMobile
                  ? "timeline__timelineContentContainer"
                  : ""
              }
            >
              <Typography
                variant="h6"
                component="h1"
                className={
                  isDesktop && !isMobile
                    ? `zcare__container__achievements__timeline__item__title timeline__timelineContent ${
                        isLanguageLoaded ? "" : "skeletonActive"
                      }`
                    : "zcare__container__achievements__timeline__item__title"
                }
              >
                {isLanguageLoaded ? (
                  t("__zcare_timeline_subtitle1")
                ) : (
                  <Skeleton
                    variant="rect"
                    width={isMobile ? "100%" : "390%"}
                    height={25}
                    style={{ margin: "5px" }}
                  />
                )}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </div>
      <div
        id="forest"
        className={
          isMobile
            ? "zcare__container__achievements__forest__images_isMobile"
            : "zcare__container__achievements__forest__images"
        }
      >
        <div className="zcare__container__achievements__forest__description">
          <Typography
            variant="h5"
            component="h1"
            className="zcare__container__achievements__timeline__item__title text__golden"
          >
            <em>
              {" "}
              {isLanguageLoaded ? (
                t("__zcare_year_present")
              ) : (
                <Skeleton
                  variant="rect"
                  width={"20%"}
                  height={25}
                  style={{ margin: "5px" }}
                />
              )}{" "}
            </em>
          </Typography>
          <Typography
            variant="body1"
            className="zcare__container__achievements__timeline__item__title"
          >
            {isLanguageLoaded ? (
              t("__zcare_timeline_present_description")
            ) : (
              <Skeleton
                variant="rect"
                width={"90%"}
                height={25}
                style={{ margin: "5px" }}
              />
            )}
          </Typography>
        </div>
      </div>
      <div
        id="forest"
        className={
          isMobile
            ? "zcare__container__achievements__forest__images_isMobile"
            : "zcare__container__achievements__forest__images"
        }
      >
        <div className="zcare__container__achievements__forest__description">
          <Typography
            variant="h5"
            component="h1"
            className="zcare__container__achievements__timeline__item__title text__golden"
          >
            <em>
              {" "}
              {isLanguageLoaded ? (
                t("__zcare_year_2020")
              ) : (
                <Skeleton
                  variant="rect"
                  width={"20%"}
                  height={25}
                  style={{ margin: "5px" }}
                />
              )}{" "}
            </em>
          </Typography>
          <Typography
            variant="body1"
            className="zcare__container__achievements__timeline__item__title"
          >
            {isLanguageLoaded ? (
              t("__zcare_timeline_subtitle4_description")
            ) : (
              <Skeleton
                variant="rect"
                width={"90%"}
                height={25}
                style={{ margin: "5px" }}
              />
            )}
          </Typography>
        </div>
        <div className="zcare__container__achievements__forest__images">
          <img
            src={isDesktop || isTablet ? reforestacionPC : reforestacion}
            alt="aqui va la reforestacion"
          />
        </div>
      </div>
      <div
        id="latinas"
        className={
          isMobile
            ? "zcare__container__achievements__forest__images_isMobile"
            : "zcare__container__achievements__forest__images"
        }
      >
        <div className="zcare__container__achievements__forest__description">
          <Typography
            variant="h5"
            component="h1"
            className="zcare__container__achievements__timeline__item__title text__golden"
          >
            <em>
              {isLanguageLoaded ? (
                t("__zcare_year_2019")
              ) : (
                <Skeleton
                  variant="rect"
                  width={"20%"}
                  height={25}
                  style={{ margin: "5px" }}
                />
              )}{" "}
            </em>
          </Typography>
          <Typography
            variant="body1"
            className="zcare__container__achievements__timeline__item__title"
          >
            {isLanguageLoaded ? (
              t("__zcare_timeline_subtitle3_description")
            ) : (
              <Skeleton
                variant="rect"
                width={"90%"}
                height={25}
                style={{ margin: "5px" }}
              />
            )}
          </Typography>
        </div>
        <div className="zcare__container__achievements__forest__images">
          <img
            src={isDesktop || isTablet ? latinasImagePC : latinasImage}
            alt="aqui va la reforestacion"
          />
        </div>
      </div>
      <div
        id="toyZcare"
        className={
          isMobile
            ? "zcare__container__achievements__forest__images_isMobile"
            : "zcare__container__achievements__forest__images"
        }
      >
        <div className="zcare__container__achievements__forest__description">
          <Typography
            variant="h5"
            component="h1"
            className="zcare__container__achievements__timeline__item__title text__golden"
          >
            <em>
              {isLanguageLoaded ? (
                t("__zcare_year_2018")
              ) : (
                <Skeleton
                  variant="rect"
                  width={"20%"}
                  height={25}
                  style={{ margin: "5px" }}
                />
              )}{" "}
            </em>
          </Typography>
          <Typography
            variant="body1"
            className="zcare__container__achievements__timeline__item__title"
          >
            {isLanguageLoaded ? (
              t("__zcare_timeline_subtitle2_description")
            ) : (
              <Skeleton
                variant="rect"
                width={"90%"}
                height={25}
                style={{ margin: "5px" }}
              />
            )}
          </Typography>
        </div>
        <div className="zcare__container__achievements__forest__images">
          <img
            src={isDesktop || isTablet ? toyZcarePC : toyZcare}
            alt="aqui va la reforestacion"
          />
        </div>
      </div>
      <div
        id="foodBank"
        className={
          isMobile
            ? "zcare__container__achievements__forest__images_isMobile"
            : "zcare__container__achievements__forest__images"
        }
      >
        <div className="zcare__container__achievements__forest__description">
          <Typography
            variant="h5"
            component="h1"
            className="zcare__container__achievements__timeline__item__title text__golden"
          >
            <em>
              {" "}
              {isLanguageLoaded ? (
                t("__zcare_year_2017")
              ) : (
                <Skeleton
                  variant="rect"
                  width={"20%"}
                  height={25}
                  style={{ margin: "5px" }}
                />
              )}
            </em>
          </Typography>
          <Typography
            variant="body1"
            className="zcare__container__achievements__timeline__item__title"
          >
            {isLanguageLoaded ? (
              t("__zcare_timeline_subtitle1_description")
            ) : (
              <Skeleton
                variant="rect"
                width={"90%"}
                height={25}
                style={{ margin: "5px" }}
              />
            )}
          </Typography>
        </div>
        <div className="zcare__container__achievements__forest__images">
          <img
            src={isDesktop || isTablet ? foodbankPC : foodbank}
            alt="aqui va la reforestacion"
          />
        </div>
      </div>
    </Fragment>
  );
};

export default ZcareTimeline;
