import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { isIOS, isMobile, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
//MUI
import { IconButton, Skeleton } from "@mui/material";
import { Email, PhoneInTalk } from "@mui/icons-material";
//Utils
import {
  regexIsEmailAddressValid,
  restrictToOnlyLettersForEmail,
} from "./../../../../Libraries/Utils/regex";
//Actions
import {
  requestNewsletterEvaluation,
  cleanNewsLetterSuscribeValidationReducer,
} from "./../../../ReduxSaga/Actions/Client";
//Assets
import { ReactComponent as EnvelopeIcon } from "./../../../../assets/media/images/icons/envelopeIcon.svg";
import { ReactComponent as EnvelopeGrayIcon } from "./../../../../assets/media/images/icons/envelopeGrayIcon.svg";

const Footer = ({
  footerDataApp,
  isLoadingEnabled,
  dispatch,
  clientReducer,
  isLanguageLoaded,
  isWebpage,
}) => {
  //Notistack
  const { enqueueSnackbar } = useSnackbar();

  //Translation
  const { t } = useTranslation();

  ///State
  const [subscribeInfo, setSubscribeInfo] = useState("");

  //Effects
  useEffect(() => {
    if (typeof clientReducer?.newsLetterEvaluationResponse !== "undefined") {
      if (clientReducer?.newsLetterEvaluationResponse.code !== 100) {
        switch (clientReducer?.newsLetterEvaluationResponse.code) {
          case -100:
            enqueueSnackbar(
              t(
                "__forms_email_validation_already_subscribed_newsletter_message"
              ),
              {
                variant: "error",
              }
            );
            break;
          case -200:
          case -300:
            enqueueSnackbar(
              t("__message_footer_error_message_try_againg_later_label"),
              {
                variant: "error",
              }
            );
            break;
          default:
            break;
        }
        dispatch(cleanNewsLetterSuscribeValidationReducer());
      } else {
        setSubscribeInfo("");
        enqueueSnackbar(t("__message_navbar_subscribe_success_label"), {
          variant: "success",
        });
      }
    }
  }, [clientReducer?.newsLetterEvaluationResponse]);

  return (
    <Fragment>
      <footer
        className={
          isIOS && isMobile && !isTablet
            ? "is_iOS footer__content"
            : "footer__content"
        }
      >
        <div
          className={`footer__content__inner ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          <div
            className={`footer__content__inner__links ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            <>
              {isLoadingEnabled
                ? [0, 1, 2, 3, 4, 5, 6, 7, 8].map((index) => {
                    return <Skeleton key={index} width={"30%"} height={50} />;
                  })
                : footerDataApp.length > 0 &&
                  footerDataApp
                    .sort((a, b) => a.orderRender - b.orderRender)
                    .map((item, index) => {
                      if (item.idModContentType === 6) {
                        return (
                          <div
                            key={index}
                            className={`footer__content__text ${
                              isTablet ? "tablet" : isMobile ? "mobile" : ""
                            }`}
                          >
                            <Link
                              className="navbar__content__link"
                              to={item.anchorResources}
                            >
                              {item.valueResources}
                            </Link>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            key={index}
                            className={`footer__content__text ${
                              isTablet ? "tablet" : isMobile ? "mobile" : ""
                            }`}
                          >
                            <a
                              rel="noreferrer"
                              className="navbar__content__link"
                              target="_blank"
                              href={item.anchorResources}
                            >
                              {item.valueResources}
                            </a>
                          </div>
                        );
                      }
                    })}
            </>
          </div>
          <div
            className={`footer__content__inner__subscribe ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            {isLoadingEnabled ? (
              <Skeleton
                width={isTablet ? "75%" : isMobile ? "90%" : "95%"}
                height={isTablet ? 190 : isMobile ? 100 : 200}
                className={isMobile ? "skeletoSpaceFooter" : ""}
                style={{ marginLeft: 10, padding: 0 }}
              />
            ) : (
              <>
                <div className="footer__content__inner__subscribe__title">
                  <span>
                    {isLanguageLoaded ? t("__home_subscribe_footer") : ""}
                  </span>
                </div>
                <div className="subscribe__wrapper">
                  <input
                    type="text"
                    placeholder={
                      isLanguageLoaded
                        ? t("__home_subscribe_placeholder_footer")
                        : ""
                    }
                    value={subscribeInfo}
                    onChange={(e) => {
                      setSubscribeInfo(restrictToOnlyLettersForEmail(e));
                    }}
                  />
                  <div className="subscribe__wrapper__button__wrapper">
                    <IconButton
                      disabled={
                        subscribeInfo.length <= 0 ||
                        !regexIsEmailAddressValid(subscribeInfo)
                      }
                      onClick={() => {
                        // if (
                        //   subscribeInfo.length > 0 &&
                        //   regexIsEmailAddressValid(subscribeInfo)
                        // ) {
                        //   dispatch(requestNewsletterEvaluation(subscribeInfo));
                        // }
                        dispatch(requestNewsletterEvaluation(subscribeInfo));
                      }}
                    >
                      {subscribeInfo.length <= 0 ||
                      !regexIsEmailAddressValid(subscribeInfo) ? (
                        <EnvelopeGrayIcon />
                      ) : (
                        <EnvelopeIcon />
                      )}
                    </IconButton>
                  </div>

                  {/* <div className="subscribe__wrapper__form">
                    <TextFieldComponent
                      labelName=""
                      typeInput="text"
                      valueInput={subscribeInfo}
                      erroHelp={
                        subscribeInfo.length === 0
                          ? t("__forms_empty_field_error_message")
                          : !regexIsEmailAddressValid(subscribeInfo)
                          ? t("Email inválido")
                          : ""
                      }
                      placeholderInput={t(
                        "__home_subscribe_placeholder_footer"
                      )}
                      isActiveHelp={
                        subscribeInfo.length === 0 ||
                        !regexIsEmailAddressValid(subscribeInfo)
                      }
                      textareaEnabled={false}
                      setValue={(e) =>
                        setSubscribeInfo(restrictToOnlyLettersForEmail(e))
                      }
                    />
                  </div>
                  <div className="subscribe__wrapper__btn">
                    <ButtonComponent
                      countButton={false}
                      handleActionPrimary={() => {
                        if (
                          subscribeInfo.length > 0 &&
                          regexIsEmailAddressValid(subscribeInfo)
                        ) {
                          dispatch(requestNewsletterEvaluation(subscribeInfo));
                        }
                      }}
                      handleActionSecondary={() => {}}
                      valueCount=""
                      iconClass="envelopeIcon"
                      textButton=""
                      leftAling={false}
                    />
                  </div> */}
                </div>
                <div className="subscribe__wrapper__helper__text">
                  <span>
                    {subscribeInfo.length === 0
                      ? isLanguageLoaded
                        ? t("__forms_empty_field_error_message")
                        : ""
                      : !regexIsEmailAddressValid(subscribeInfo)
                      ? isLanguageLoaded
                        ? t("__message_email_invalid_label")
                        : ""
                      : ""}
                  </span>
                </div>
                {!isWebpage && (
                  <div className="subscribe__social">
                    <a
                      rel="noreferrer"
                      className="navbar__content__link"
                      target="_blank"
                      href="https://www.facebook.com/zermatusa/"
                    >
                      {" "}
                      <div className="facebookIcon" />{" "}
                    </a>
                    <a
                      rel="noreferrer"
                      className="navbar__content__link"
                      target="_blank"
                      href="https://www.instagram.com/zermatus/?hl=en"
                    >
                      <div className="instagramIcon" />{" "}
                    </a>
                    <a
                      rel="noreferrer"
                      className="navbar__content__link"
                      target="_blank"
                      href="https://www.youtube.com/channel/UCNC80gWIoSGS8tO4oU_TkCg"
                    >
                      <div className="youtubeIcon" />
                    </a>
                    <a
                      rel="noreferrer"
                      className="navbar__content__link"
                      target="_blank"
                      href="https://www.pinterest.com.mx/zermatusa/"
                    >
                      <div className="pinterestIcon" />
                    </a>
                    <a
                      rel="noreferrer"
                      className="navbar__content__link"
                      target="_blank"
                      href="https://api.whatsapp.com/send?phone=13468439702&text=Hola%20me%20interesa%20uno%20de%20sus%20productos"
                    >
                      <div className="whatsappIcon" />
                    </a>
                  </div>
                )}
              </>
            )}
          </div>
          <div
            className={`footer__content__inner__address ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            {isLoadingEnabled ? (
              <Skeleton
                width={isTablet ? "100%" : isMobile ? "85%" : "95%"}
                height={isTablet ? 170 : isMobile ? 100 : 200}
                className={isMobile ? "skeletoSpaceFooter" : ""}
                style={{ padding: 0 }}
              />
            ) : (
              !isWebpage && (
                <div className="customer__support__container__card__info__inner">
                  <div>
                    <PhoneInTalk className="icon--gray" />
                    <span className="text--gray">
                      {" "}
                      {isLanguageLoaded ? (
                        t("__customer_support_card_information_telephone_label")
                      ) : (
                        <Skeleton width={"100%"} height={20} variant="rect" />
                      )}
                    </span>
                  </div>
                  <div>
                    <Email className="icon--gray" />
                    <span className="text--gray">
                      {" "}
                      {isLanguageLoaded ? (
                        "customerservice@zermatusa.com"
                      ) : (
                        <Skeleton width={"100%"} height={20} variant="rect" />
                      )}
                    </span>
                  </div>
                  <div>
                    <span className="text--gray">
                      {" "}
                      {isLanguageLoaded ? (
                        t("__customer_support_card_information_schedule1_label")
                      ) : (
                        <Skeleton width={"100%"} height={20} variant="rect" />
                      )}
                    </span>
                  </div>
                  <div>
                    <span className="text--gray">
                      {isLanguageLoaded ? (
                        t("__customer_support_card_information_schedule2_label")
                      ) : (
                        <Skeleton width={"100%"} height={20} variant="rect" />
                      )}
                    </span>
                  </div>
                </div>
              )
            )}
          </div>
          <div
            className={`footer__content__inner__zermat ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            {isLoadingEnabled ? (
              <Skeleton width={"80%"} height={200} />
            ) : (
              <>
                <div
                  className={`logoBlack ${
                    isWebpage ? `smallLogo` : `largeLogo`
                  }`}
                />
                <div
                  className={`footer__content__inner__zermat__address ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  }`}
                >
                  {isLanguageLoaded ? (
                    t("__customer_support_card_address_information_label")
                  ) : (
                    <Skeleton width={"100%"} height={20} variant="rect" />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;
