import React, { Fragment } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const NoticeInStockModal = ({ isProductInStock, setIsProductInStock }) => {
  /// TRANSLATION
  const { t } = useTranslation();

  const handleCloseModal = () => {
    setIsProductInStock(false);
  };
  return (
    <Fragment>
      <Dialog
        className="modal__congratulations__content"
        open={isProductInStock}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="modal__congratulations__content__wrapper__title"
        >
          <IconButton
            onClick={handleCloseModal}
            aria-label="close"
            color="primary"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="modal__congratulations__content__wrapper__container">
          <div className="modal__notices__in__stock__icons">
            <div className="modal__notices__in__stock__icon__airplane" />
          </div>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="h5" component={"p"} gutterBottom>
              {t("__Notice_in_stck_modal_title")}
            </Typography>
            <Typography component={"span"} variant="body2" gutterBottom>
              {t("__Notice_in_stck_modal_quest")}
            </Typography>

            <TextField
              autoFocus
              margin="dense"
              id="name"
              label={t("__home_Entrepenour_Info_WebsPage_Doubt_Email_Text")}
              type="email"
              fullWidth
            />
          </DialogContentText>
          <Typography
            component={"span"}
            variant="caption"
            display="block"
            gutterBottom
          >
            {t("__home_Retargeting_Modal_Paragraph4")}
          </Typography>
          <div>
            <Button className="button__style" variant="contained">
              {t("btn_text_send")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default NoticeInStockModal;
