import { call, put, takeLatest } from "redux-saga/effects";
import {
  REQUEST_CUSTOM_LABELS_CONFIGURATION,
  RESPONSE_CUSTOM_LABELS_CONFIGURATION,
  RESPONSE_VALIDATE_CUSTOM_LABELS,
  REQUEST_VALIDATE_CUSTOM_LABELS,
  REQUEST_CUSTOM_LABEL_UPDATE_CONFIGURATION,
  RESPONSE_CUSTOM_LABEL_UPDATE_CONFIGURATION,
  REQUEST_CUSTOM_LABEL_BUILD_IMAGES_ON_SERVER,
  RESPONSE_CUSTOM_LABEL_BUILD_IMAGES_ON_SERVER,
  REQUEST_CUSTOM_LABELS_CONFIGURATION_BY_DETAIL,
  RESPONSE_CUSTOM_LABELS_CONFIGURATION_BY_DETAIL,
  REQUEST_CUSTOM_LABEL_UPDATE_CONFIGURATION_BY_DETAIL,
  RESPONSE_CUSTOM_LABEL_UPDATE_CONFIGURATION_BY_DETAIL,
  REQUEST_CUSTOM_LABEL_CONFIGURATION_BY_PROMOTION,
  RESPONSE_CUSTOM_LABEL_CONFIGURATION_BY_PROMOTION,
  DATA_FETCH_FAILED,
} from "../../Constants";
import CustomLabels from "../../../../Libraries/CustomLabels";

function* requestCustomLabelsConfiguration(data) {
  try {
    const response = yield call(
      CustomLabels.GetCustomLabelsConfiguration,
      data.payload.orderId
    );
    yield put({
      type: RESPONSE_CUSTOM_LABELS_CONFIGURATION,
      payload: {response: response.getCustomLabelsConfiguration },
    });
  } catch (error) {
    console.error("custom labels configuration error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}
function* requestValidateCustomLabelConfigurationOnOrder(data) {
  try {
    const response = yield call(
      CustomLabels.ValidateCustomLabelConfigurationOnOrder,
      data.payload.model
    );
    yield put({
      type: RESPONSE_VALIDATE_CUSTOM_LABELS,
      payload: {response: response.validateCustomLabelConfigurationOnOrder },
    });
  } catch (error) {
    console.error("custom label validate error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

function* requestUpdateCustomLabelConfigurationOnOrder(data) {
  try {
    const response = yield call(
      CustomLabels.UpdateLabelConfigurationOnOrder,
      data.payload.model
    );
    yield put({
      type: RESPONSE_CUSTOM_LABEL_UPDATE_CONFIGURATION,
      payload: {response: response.updateCustomLabelConfigurationOnOrder },
    });
  } catch (error) {
    console.error("custom label UPDATE error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}
function* requestBuildImagesOnServer(data) {
  try {
    const response = yield call(CustomLabels.BuildImagesOnServer, data.payload.orderId);
    yield put({type: RESPONSE_CUSTOM_LABEL_BUILD_IMAGES_ON_SERVER, payload: {response: response.buildCustomLabelsImages}})
  } catch (error) {
    console.error("custom label build Images on server error", error.statusText)
    yield put({type: DATA_FETCH_FAILED, message: error.statusText})
  }
}
function* requestCustomLabelsConfigurationByDetail(data) {
  try {
    const response = yield call(CustomLabels.GetCustomLabelsConfigurationByOrderDetail, data.payload.orderId, data.payload.orderDetailId)
    yield put({type: RESPONSE_CUSTOM_LABELS_CONFIGURATION_BY_DETAIL, payload: {response: response.getCustomLabelsConfigurationV2}})
  } catch (error) {
    console.error("custom label configuration by detail error", error.statusText)
    yield put({type: DATA_FETCH_FAILED, message: error.statusText})
  }
}
function* requestUpdateCustomLabelConfigurationOnOrderDetail(data) {
  try {
    const response = yield call(CustomLabels.UpdateLabelConfigurationOnOrderByDetail,data.payload.model)
    yield put({type: RESPONSE_CUSTOM_LABEL_UPDATE_CONFIGURATION_BY_DETAIL, payload: {response: response.updateCustomLabelConfigurationOnOrderByDetail}})
  } catch (error) {
    console.error("custom label by order detail UPDATE error", error.statusText)
    yield put({type: DATA_FETCH_FAILED, message: error.statusText})
  }
}
function* requestCustomLabelsConfigurationByPromo(data) {
  try {
    const response = yield call(CustomLabels.GetCustomLabelsConfigurationByPromotion, data.payload.itemCode)
    yield put({type: RESPONSE_CUSTOM_LABEL_CONFIGURATION_BY_PROMOTION, payload: {response: response.getCustomLabelsConfigurationByPromotion}})
  } catch (error) {
    console.error("custom label configuration by promotion error", error.statusText)
    yield put({type: DATA_FETCH_FAILED, message: error.statusText})
  }
}

//Watchers
export function* watchRequestCustomLabelsConfiguration() {
  yield takeLatest(
    REQUEST_CUSTOM_LABELS_CONFIGURATION,
    requestCustomLabelsConfiguration
  );
}
export function* watchValidateCustomLabelConfigurationOnOrder() {
  yield takeLatest(
    REQUEST_VALIDATE_CUSTOM_LABELS,
    requestValidateCustomLabelConfigurationOnOrder
  );
}

export function* watchRequestUpdateCustomLabelConfigurationOnOrder() {
  yield takeLatest(REQUEST_CUSTOM_LABEL_UPDATE_CONFIGURATION, requestUpdateCustomLabelConfigurationOnOrder);
}
export function* watchRequestBuildImagesOnServer(){
  yield takeLatest(REQUEST_CUSTOM_LABEL_BUILD_IMAGES_ON_SERVER, requestBuildImagesOnServer);
}
export function* watchRequestCustomLabelsConfigurationByDetail() {
  yield takeLatest(REQUEST_CUSTOM_LABELS_CONFIGURATION_BY_DETAIL, requestCustomLabelsConfigurationByDetail);
}
export function* watchRequestUpdateCustomLabelConfigurationOnOrderDetail(){
  yield takeLatest(REQUEST_CUSTOM_LABEL_UPDATE_CONFIGURATION_BY_DETAIL, requestUpdateCustomLabelConfigurationOnOrderDetail);
}
export function* watchRequestCustomLabelsConfigurationByPromo() {
  yield takeLatest(REQUEST_CUSTOM_LABEL_CONFIGURATION_BY_PROMOTION, requestCustomLabelsConfigurationByPromo);
}