import React from "react";
//MUI
import { ArrowUpward } from "@mui/icons-material";
//Utils
import { handleScrollToTop } from "../../../../Libraries/Utils/utils";

const ScrollComponent = () => (
  <div className="scroll__top">
    <ArrowUpward onClick={handleScrollToTop} className="icon--red" />
  </div>
);

export default ScrollComponent;
