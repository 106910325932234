import React, { useState, useEffect, Fragment } from "react";
import { Drawer, Input, InputAdornment, CircularProgress } from "@mui/material";
import { Search } from "@mui/icons-material";
import SearchListProduct from "../../products/components/SearchListProduct";
import nothingHere from "./../../../../assets/media/images/nothingHere.png";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const SearchComponent = ({
  isSearchActive,
  setIsSearchActive,
  handleGAEvent,
  props,
}) => {
  /// TRANSLATION
  const { t, i18n } = useTranslation();
  /// REDUX
  const appReducers = {};
  appReducers.products = useSelector((state) => state.products);

  /// HOOKS
  const [enableSearch, setEnableSearch] = useState(false);
  const [valueToSearch, setValueToSearch] = useState("");
  const [productsSearchedResult, setProductsSearchedResult] = useState([]);

  /// HANDLE
  const handleCloseSearch = () => {
    setIsSearchActive(false);
    setProductsSearchedResult([]);
  };

  /// EFFECTS
  useEffect(() => {
    if (!appReducers?.products?.errorLoadAllProducts) {
      if (i18n.language === "en") {
        if (appReducers?.products?.productsEn?.length > 0)
          setEnableSearch(true);
      } else if (i18n.language === "es") {
        if (appReducers?.products?.productsEs?.length > 0)
          setEnableSearch(true);
      }
    }
  }, [
    appReducers?.products?.errorLoadAllProducts,
    i18n.language,
    appReducers?.products?.productsEs,
    appReducers?.products?.productsEn,
  ]);

  useEffect(() => {
    if (valueToSearch.length >= 1) {
      let productsFiltered = [];
      let productsFilteredByTypePlan = [];
      let productsFilteredTotal = [];

      let productsFilteredPromo = [];
      let productsFilteredPromoByTypePlan = [];
      let productsFilteredPromoTotal = [];

      let productsFilteredFinal = [];
      //sacar el tipo de usuario
      const storage = JSON.parse(localStorage.getItem("ZUsaUInfo")); //temporal como se evalua de esta forma hasta que se haga la clase del disabled
      let typePlanAll = 5; //tipo de plan al que palican todos en la busquedas

      if (i18n.language === "en") {
        productsFiltered = appReducers?.products?.productsEn.filter(
          (p) =>
            typePlanAll === p.typePlan &&
            (p.itemCode.toUpperCase().includes(valueToSearch) ||
              p.nameArticle.toUpperCase().includes(valueToSearch))
        );
        if (typePlanAll !== parseInt(storage.typeUser)) {
          productsFilteredByTypePlan = appReducers?.products?.productsEn.filter(
            (p) =>
              parseInt(storage.typeUser) === p.typePlan &&
              (p.itemCode.toUpperCase().includes(valueToSearch) ||
                p.nameArticle.toUpperCase().includes(valueToSearch))
          );
        }
        productsFiltered.push.apply(
          productsFiltered,
          productsFilteredByTypePlan
        );

        productsFilteredPromo = appReducers?.products?.promotionsEn.filter(
          (p) =>
            typePlanAll === parseInt(p.typePlan) &&
            (p.itemCode.toUpperCase().includes(valueToSearch) ||
              p.nameArticle.toUpperCase().includes(valueToSearch))
        );
        if (typePlanAll !== parseInt(storage.typeUser)) {
          productsFilteredPromoByTypePlan =
            appReducers?.products?.promotionsEn.filter(
              (p) =>
                parseInt(storage.typeUser) === parseInt(p.typePlan) &&
                (p.itemCode.toUpperCase().includes(valueToSearch) ||
                  p.nameArticle.toUpperCase().includes(valueToSearch))
            );
        }
        productsFilteredPromo.push.apply(
          productsFilteredPromo,
          productsFilteredPromoByTypePlan
        );

        //v1
        ////const productsFilteredPromoTotal = productsFilteredPromo.concat(productsFilteredPromoByTypePlan);
        ////productsFilteredTotal.push.apply(productsFilteredTotal, productsFilteredPromoTotal);
        //const productsFilteredFinal =  productsFiltered.concat(productsFilteredPromo);

        //v2
        let productsFilteredFinal = productsFiltered;

        productsFilteredPromo.map((item) => {
          let tarObj = productsFiltered.find(
            (obj) => obj.itemCode === item.itemCode
          );
          if (!tarObj) {
            productsFilteredFinal.push(item);
          }
        });
        setProductsSearchedResult(productsFilteredFinal);
      } else if (i18n.language === "es") {
        productsFiltered = appReducers?.products?.productsEs.filter(
          (p) =>
            typePlanAll === p.typePlan &&
            (p.itemCode.toUpperCase().includes(valueToSearch) ||
              p.nameArticle.toUpperCase().includes(valueToSearch))
        );
        if (typePlanAll !== parseInt(storage.typeUser)) {
          productsFilteredByTypePlan = appReducers?.products?.productsEs.filter(
            (p) =>
              parseInt(storage.typeUser) === p.typePlan &&
              (p.itemCode.toUpperCase().includes(valueToSearch) ||
                p.nameArticle.toUpperCase().includes(valueToSearch))
          );
        }
        productsFiltered.push.apply(
          productsFiltered,
          productsFilteredByTypePlan
        );

        productsFilteredPromo = appReducers?.products?.promotionsEs.filter(
          (p) =>
            typePlanAll === parseInt(p.typePlan) &&
            (p.itemCode.toUpperCase().includes(valueToSearch) ||
              p.nameArticle.toUpperCase().includes(valueToSearch))
        );
        if (typePlanAll !== parseInt(storage.typeUser)) {
          productsFilteredPromoByTypePlan =
            appReducers?.products?.promotionsEs.filter(
              (p) =>
                parseInt(storage.typeUser) === parseInt(p.typePlan) &&
                (p.itemCode.toUpperCase().includes(valueToSearch) ||
                  p.nameArticle.toUpperCase().includes(valueToSearch))
            );
        }
        //v1
        //productsFilteredPromo.push.apply(productsFilteredPromo,productsFilteredPromoByTypePlan);
        //const productsFilteredPromoTotal = productsFilteredPromo.concat(productsFilteredPromoByTypePlan);
        //productsFilteredTotal.push.apply(productsFilteredTotal, productsFilteredPromoTotal);
        //const productsFilteredFinal =  productsFiltered.concat(productsFilteredPromo);

        //v2
        let productsFilteredFinal = productsFiltered;

        productsFilteredPromo.map((item) => {
          let tarObj = productsFiltered.find(
            (obj) => obj.itemCode === item.itemCode
          );
          if (!tarObj) {
            productsFilteredFinal.push(item);
          }
        });
        setProductsSearchedResult(productsFilteredFinal);

        setProductsSearchedResult(productsFilteredFinal);
      }
    } else {
      setProductsSearchedResult([]);
    }
  }, [
    valueToSearch,
    i18n.language,
    appReducers?.products?.productsEs,
    appReducers?.products?.productsEn,
  ]);

  return (
    <Drawer
      className="search__content__wrapper"
      anchor="top"
      open={isSearchActive}
      onClose={handleCloseSearch}
    >
      <div className="search__content__wrapper__container__result">
        {!enableSearch ? (
          <div className="search__content__wrapper__loading">
            <CircularProgress color="inherit" />
            {t("__wait_one_text")}
          </div>
        ) : (
          <Fragment>
            <div className="search__content__wrapper__container">
              <Input
                disabled={!enableSearch}
                label="TextField"
                id="input-with-icon-adornment"
                className="input__content__style"
                onInput={(e) => {
                  setValueToSearch(e.target.value.toUpperCase());
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                }
              />
            </div>
            {productsSearchedResult.length > 0 ? (
              <SearchListProduct
                productsFiltered={productsSearchedResult}
                handleCloseSearch={handleCloseSearch}
                handleGAEvent={handleGAEvent}
              />
            ) : (
              <div className="search__content__wrapper__container__result__nothing">
                <img
                  className="search__content__result__nothing__image"
                  src={nothingHere}
                  alt="Sorry"
                />
                <p>{t("__search_nothing_label")} </p>
                <p>{t("__search_try_label")}</p>
              </div>
            )}
          </Fragment>
        )}
      </div>
    </Drawer>
  );
};

export default SearchComponent;
