import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { isMobile, isTablet } from "react-device-detect";

const ItemReturnRepayment = ({ finalArrayReturnRender }) => {
  const { i18n } = useTranslation();

  return (
    <div>
      {finalArrayReturnRender ? (
        <div>
          {finalArrayReturnRender.map((prod, index) => {
            return (
              <div
                key={index}
                className={`return__repayment__container__order__detail__wrapper__products__repayment__item ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                <div>
                  <img
                    className="favorites__list__container__items__content__item__image "
                    src={prod.imgProd}
                    alt={prod.itemCode}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src =
                        "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
                    }}
                  />
                </div>
                <div>
                  <Typography variant="caption" display="block" gutterBottom>
                    {i18n.language === "es" ? prod.descEs : prod.descEn}
                  </Typography>
                  <Typography
                    className="shopping__list__container__wrapper__item__price"
                    variant="caption"
                    display="block"
                    gutterBottom
                  >
                    ${parseFloat(prod.amount).toFixed(2)}
                  </Typography>
                </div>
                <div>
                  <Typography variant="caption" display="block" gutterBottom>
                    {prod.quantity} pz
                  </Typography>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default ItemReturnRepayment;
