import React, { Fragment } from "react";
import ItemProduct from "./ItemProduct";
import { isTablet, isMobile } from "react-device-detect";

const SearchListProduct = (props, handleCloseSearch, handleGAEvent) => {
  return (
    <Fragment>
      <div
        className={
          isTablet && !isMobile
            ? "list__products__isTablet"
            : isMobile
            ? "list__products__isMobile"
            : "list__products"
        }
      >
        {props?.productsFiltered?.map((item, index) => (
          <div
            className="list__products__wrapper"
            key={index}
            onClick={() => {
              handleCloseSearch();
            }}
          >
            <ItemProduct item={item} handleGAEvent={handleGAEvent} />
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default SearchListProduct;
