import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import CenteredModal from "./../../React/Modules/base/components/CenteredModal";

export function RouterPrompt(props) {
  const { onBlock, onOK, onCancel, title, message, okText, cancelText } = props;

  ///HISTORY
  const history = useHistory();
  ///TRANSLATION
  const { t } = useTranslation();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    if (onBlock) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return "";
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, onBlock]);

  const handleOK = useCallback(async () => {
    if (onOK) {
      const canRoute = await Promise.resolve(onOK());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
  }, [currentPath, history, onOK]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  return showPrompt ? (
    //   <Modal
    //     title={title}
    //     visible={showPrompt}
    //     onOk={handleOK}
    //     okText={okText}
    //     onCancel={handleCancel}
    //     cancelText={cancelText}
    //     closable={true}
    //   >
    //     There are unsaved changes. Are you sure want to leave this page ?
    //   </Modal>
    // <div>
    //     <p>{title}</p>
    //     <p>
    //         {message}
    //     </p>
    //     <p>
    //         <button onClick={handleCancel}>{cancelText}</button>
    //         <button onClick={handleOK}>{okText}</button>
    //     </p>
    // </div>
    <CenteredModal
      open={showPrompt}
      title={t(title)}
      message={t(message)}
      handleClose={() => {
        handleCancel();
      }}
      handleConfirm={() => {
        handleOK();
      }}
      handleCancel={() => {
        handleCancel();
      }}
      alternateCancelLabel={t(cancelText)}
      alternateConfirmLabel={t(okText)}
    />
  ) : null;
}
