import React, { Fragment, useState } from "react";
import moment from "moment";
import { isMobile, isTablet } from "react-device-detect";
///MUI
import {
  Button,
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
//Utils
import {
  areOnlyLetters,
  regexIsCellphoneValid,
  restrictToOnlyNumbers,
  regexIsEmailAddressValid,
  restrictToOnlyLettersForEmail,
  isStrongPassword,
  restrictToOnlyLettersAndNumbers,
  restrictToOnlyNumbersTwo,
} from "../../../../Libraries/Utils/regex";

/// components
import TextFieldNewMembers from "./TextFieldNewMembers";
import TextFieldAndButtonNewMembers from "./TextFieldAndButtonNewMembers";
import FloatingPayProcess from "./FloatingPayProcess";
import AddressValidation from "../../base/components/AddressValidation";
import FloatingTermsAndConditions from "./FloatingTermsAndConditions";
import CenteredModal from "../../base/components/CenteredModal";
import { element } from "prop-types";

const FormNewMembers = ({
  t,
  isLanguageLoaded,
  i18n,
  isActiveFlotingPay,
  setIsActiveFlotingPay,
  wayToPay,
  setWayToPay,
  setIsPayShow,
  sponsorData,
  vCardWebPageEntreprenourData,
  statesResult,
  selectedDate,
  maxDate,
  handleDateChange,
  confirmPassword,
  setConfirmPassword,
  cellPhoneCode,
  setCellPhoneCode,
  showVerfieldCellPhoneField,
  setShowVerfieldCellPhoneField,
  isEmailVerified,
  emailMessageErrorServer,
  setEmailMessageErrorServer,
  setIsEmailVerified,
  isCellPhoneVerified,
  setIsCellPhoneVerified,
  cellMessageErrorServer,
  setCellMessageErrorServer,
  emailValidationOnBlur,
  cellPhoneValidationOnBlur,
  verifyCellPhoneCodeIsValid,
  identificationValidationOnBlur,
  isIdentificationNssValid,
  setIsIdentificationNssValid,
  isIdentificationLicValid,
  setIsIdentificationLicValid,
  isIdentificationRfcValid,
  setIsIdentificationRfcValid,
  handleGetGenderByName,
  isOpenAddressValidationModal,
  setIsOpenAddressValidationModal,
  addressListSugestion,
  addressTypeToValidate,
  handlerSelectAddressFromSugestion,
  handleAddressOpenValidation,
  isShipmentAddressVerified,
  setIsShipmentAddressVerified,
  isFiscalAddressVerified,
  setIsFiscalAddressVerified,
  useAsShippAddress,
  setUseAsShippAddress,
  initialDataPreregistration,
  setInitialDataPreregistration,
  checkAccept,
  setCheckAccept,
  currentKitObject,
  setCurrentKitObject,
  cardData,
  setCardData,
  orderData,
  handleKitAdmissionButtonClick,
  stepPay,
  setStepPay,
  handleRegiterNewEntreprenour,
  paypalStatusPay,
  handleOrderCreatorPaypal,
  handleOrderAprovedPaypal,
  isOpenValidationDataModal,
  setIsOpenValidationDataModal,
  userDataValidationErrors,
}) => {
  //constant values
  const kitArray = [
    {
      id: 1,
      name: "Trendy Mix Kit",
      bgColor: "6F0078",
      itemCode: "KS23",
    },
    {
      id: 2,
      name: "Just For All Kit",
      bgColor: "fe8f01",
      itemCode: "KF23",
    },
    {
      id: 3,
      name: "Elixir Kit",
      bgColor: "06cccd",
      itemCode: "KL23",
    },
  ];

  const [typeInputPassword, setTypeInputPassword] = useState("password");
  const [typeInputPasswordConfirm, setTypeInputPasswordConfirm] =
    useState("password");
  const [isActiveFlotingTerms, setIsActiveFlotingTerms] = useState(false);
  const [errorKitSelection, setErrorKitSelection] = useState({
    show: false,
    message: "",
  });
  const [errorName, setErrorName] = useState({ show: false, message: "" });
  const [errorLastName, setErrorLastName] = useState({
    show: false,
    message: "",
  });
  const [errorBirthDate, setErrorBirthDate] = useState({
    show: false,
    message: "",
  });
  const [errorPassword, setErrorPassword] = useState({
    showErrorLength: false,
    showErrorComparation: false,
    messageLength: "",
    messageComparation: "",
  });
  const [errorIdentification, setErrorIdentification] = useState({
    show: false,
    message: "",
  });
  const [errorCellPhone, setErrorCellPhone] = useState({
    show: false,
    message: "",
  });
  const [errorEmail, setErrorEmail] = useState({ show: false, message: "" });
  const [errorFiscalAddress, setErrorFiscalAddress] = useState({
    show: false,
    message: "",
  });
  const [errorShippingAddress, setErrorShippingAddress] = useState({
    show: false,
    message: "",
  });
  const [errorAcceptTermsConditions, setErrorAcceptTermsConditions] = useState({
    show: false,
    message: "",
  });

  //// message

  const errorEspValidation = [
    { id: 1, message: "El teléfono ya está registrado" },
    {
      id: 2,
      message: "Dirección y fecha de nacimiento ya se encuentra registrado",
    },
    {
      id: 3,
      message:
        "Fecha de nacimiento  y Nombre y apellido ya se encuentra registrado",
    },
    {
      id: 4,
      message:
        "Nombre y apellido,  dirección y fecha de nacimiento ya se encuentra registrado",
    },
  ];
  const errorEngValidation = [
    { id: 1, message: "The phone is already registered" },
    { id: 2, message: "Address and date of birth is already registered" },
    {
      id: 3,
      message: "Date of birth and Name and surname is already registered",
    },
    {
      id: 4,
      message:
        "Name and lastname, address and date of birth is already registered",
    },
  ];
  //handlers
  const handleEvaluationPristineForm = () => {
    if (currentKitObject.hasOwnProperty("itemCode") === false)
      setErrorKitSelection({
        show: true,
        message: "__form__new__members__info__warning__select__kit",
      });
    else setErrorKitSelection({ show: false, message: "" });

    setErrorName({
      show: initialDataPreregistration.name.length <= 0,
      message: "",
    });
    setErrorLastName({
      show: initialDataPreregistration.lastname.length <= 0,
      message: "",
    });
    setErrorBirthDate({ show: selectedDate === null, message: "" });
    setErrorPassword({
      showErrorLength: confirmPassword.length <= 0,
      showErrorComparation:
        initialDataPreregistration.password.length > 0 &&
        initialDataPreregistration.password !== confirmPassword,
      messageLength: "",
      messageComparation: "",
    });
    //setErrorIdentification({show: (isIdentificationNssValid === false && isIdentificationLicValid === false && isIdentificationRfcValid === false), message: ""})
    setErrorCellPhone({ show: isCellPhoneVerified === false, message: "" });
    //setErrorEmail({ show: isEmailVerified === false, message: "" });
    setErrorFiscalAddress({
      show: isFiscalAddressVerified === false,
      message: "",
    });
    setErrorShippingAddress({
      show: isShipmentAddressVerified === false,
      message: "",
    });
    setErrorAcceptTermsConditions({ show: checkAccept === false, message: "" });

    if (
      initialDataPreregistration.name.length <= 0 ||
      initialDataPreregistration.lastname.length <= 0 ||
      initialDataPreregistration.password.length <= 0 ||
      confirmPassword.length <= 0 ||
      (initialDataPreregistration.password.length > 0 &&
        initialDataPreregistration.password !== confirmPassword) ||
      //(isIdentificationNssValid === false && isIdentificationLicValid === false && isIdentificationRfcValid === false) ||
      selectedDate === null ||
      isCellPhoneVerified === false ||
      //isEmailVerified === false ||
      isShipmentAddressVerified === false ||
      isFiscalAddressVerified === false ||
      currentKitObject.hasOwnProperty("itemCode") === false ||
      checkAccept === false
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div
      className={`form__new__members__main ${
        isTablet ? "tablet" : isMobile ? "mobile" : ""
      }`}
    >
      <CenteredModal
        open={isOpenValidationDataModal}
        title={t("__centered_modal_validation_title")}
        message={
          <ul className="centered__modal__content__list__warning">
            {userDataValidationErrors.length > 0 &&
              userDataValidationErrors.map((error, index) => (
                <li key={index}>{t(error.validationDescription)}</li>
              ))}
          </ul>
        }
        children={
          <span className=" centered__modal__content__note__warning text__red">
            {t("__centered_modal_validation_footer_warning")}
          </span>
        }
        handleClose={() => {
          setIsOpenValidationDataModal(false);
        }}
      />
      <FloatingPayProcess
        open={isActiveFlotingPay}
        paypalStatusPay={paypalStatusPay}
        setIsActiveFlotingPay={setIsActiveFlotingPay}
        wayToPay={wayToPay}
        setWayToPay={setWayToPay}
        currentKitObject={currentKitObject}
        setCurrentKitObject={setCurrentKitObject}
        cardData={cardData}
        setCardData={setCardData}
        initialDataPreregistration={initialDataPreregistration}
        orderData={orderData}
        stepPay={stepPay}
        setStepPay={setStepPay}
        handleRegiterNewEntreprenour={handleRegiterNewEntreprenour}
        sponsorData={sponsorData}
        vCardWebPageEntreprenourData={vCardWebPageEntreprenourData}
        handleOrderCreatorPaypal={handleOrderCreatorPaypal}
        handleOrderAprovedPaypal={handleOrderAprovedPaypal}
      />
      <AddressValidation
        setIsOpenValidationModal={setIsOpenAddressValidationModal}
        addressListSugestion={addressListSugestion}
        handlerSelectAddressFromSugestion={handlerSelectAddressFromSugestion}
        open={isOpenAddressValidationModal}
        type={addressTypeToValidate}
      />
      <FloatingTermsAndConditions
        setIsActiveFlotingTerms={setIsActiveFlotingTerms}
        open={isActiveFlotingTerms}
        t={t}
        i18n={i18n}
      />
      <div
        className={`form__new__members__main__wrapper ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }`}
      >
        <Divider />
        <div
          className={`form__new__members__main__wrapper__sponsor__info ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          <span
            className={`form__new__members__main__wrapper__sponsor__info__title ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            {t("__form__new__members__name__sponsor")}
          </span>
          <br />
          <span
            className={`form__new__members__main__wrapper__sponsor__info__name ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            {sponsorData.nameClient}
          </span>
        </div>
        <div
          className={`form__new__members__main__wrapper__sponsor__info ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          <span
            className={`form__new__members__main__wrapper__sponsor__info__title ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            {t("__pay__thanks__footer__sponsor__phone")}
          </span>
          <br />
          <span
            className={`form__new__members__main__wrapper__sponsor__info__name ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            {sponsorData.cellPhone}
          </span>
        </div>
      </div>
      <div
        className={`form__new__members__main__choose__kit ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }`}
      >
        <span
          className={`form__new__members__main__choose__kit__title ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          {t("__form__new__members__choose__kit")}
        </span>

        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          {kitArray.map((item) => {
            return (
              <FormControlLabel
                style={{
                  backgroundColor: `#${item.bgColor}`,
                  width: isMobile ? 250 : 290,
                  justifyContent: isMobile ? "flex-start" : "center",
                  alignItems: "center",
                  marginBottom: isMobile ? 10 : 0,
                  boxShadow: "-4px -6px 18px -6px rgba(101,97,97,1)",
                }}
                value={item.id}
                control={
                  <Radio
                    checked={item.itemCode === currentKitObject.itemCode}
                  />
                }
                label={item.name}
                key={item.id}
                onClick={(e) => {
                  setCurrentKitObject(item);
                }}
              />
            );
          })}
        </RadioGroup>
        <div
          className={`form__new__members__main__choose__kit__warning ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          {errorKitSelection.show && (
            <span>{t(errorKitSelection.message)}</span>
          )}
        </div>
      </div>
      <div
        className={`form__new__members__main__form ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }`}
      >
        <span
          className={`form__new__members__main__form__title ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          {t("__form__new__members__title")}
        </span>

        <div
          className={`form__new__members__main__form__wrapper__fields ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          <Divider />
          <div
            className={`form__new__members__main__form__wrapper__fields__main ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            <span
              className={`form__new__members__main__form__wrapper__fields__main__title ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              {t("__form__new__members__title__info__personal")}
            </span>
            <div
              className={`form__new__members__main__form__wrapper__fields__main__personal ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              <TextFieldNewMembers
                labelName={t(
                  "__form__new__members__title__info__personal__name"
                )}
                typeInput="text"
                erroHelp={t(
                  "__customer_support_form_message_error_empty_label"
                )}
                isRequire={
                  //initialDataPreregistration.name.length <= 0 ? true : false
                  errorName.show
                }
                valueInput={initialDataPreregistration.name}
                isActiveHelp={initialDataPreregistration.name.length <= 0}
                setValue={(e) => {
                  setInitialDataPreregistration({
                    ...initialDataPreregistration,
                    name: e.target.value,
                  });
                }}
                onBlur={(e) => {
                  handleGetGenderByName(
                    initialDataPreregistration.name.trim().split(" ")[0]
                  );
                }}
              />
              <TextFieldNewMembers
                labelName={t(
                  "__form__new__members__title__info__personal__lastname__father"
                )}
                typeInput="text"
                erroHelp={t(
                  "__customer_support_form_message_error_empty_label"
                )}
                isRequire={
                  //initialDataPreregistration.lastname.length <= 0 ? true : false
                  errorLastName.show
                }
                valueInput={initialDataPreregistration.lastname}
                isActiveHelp={initialDataPreregistration.lastname.length <= 0}
                setValue={(e) => {
                  setInitialDataPreregistration({
                    ...initialDataPreregistration,
                    lastname: e.target.value,
                  });
                }}
              />
              <TextFieldNewMembers
                labelName={t(
                  "__form__new__members__title__info__personal__lastname__mother"
                )}
                isRequire={false}
                typeInput="text"
                //erroHelp="Campo vacío"
                valueInput={initialDataPreregistration.motherLastname}
                // isActiveHelp={
                //   initialDataPreregistration.motherLastName.length <= 0
                // }
                setValue={(e) => {
                  setInitialDataPreregistration({
                    ...initialDataPreregistration,
                    motherLastname: e.target.value,
                  });
                }}
              />
              <div className="textfield__new__members">
                <label className={errorBirthDate.show ? `text__required` : ``}>
                  {t("__form__new__members__title__info__personal__birthday")}
                </label>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <MobileDatePicker
                    closeOnSelect
                    openTo="year"
                    maxDate={maxDate}
                    format="YYYY-MM-DD"
                    value={selectedDate}
                    onChange={(value, context) => handleDateChange(value)}
                  />
                </LocalizationProvider>
              </div>
              <FormControl className="radio__wrapper">
                <label
                  className={
                    initialDataPreregistration.idLang ? `` : `text__required`
                  }
                >
                  {t("__form__new__members__title__info__personal__language")}
                </label>
                <div className="radios__wrapper__new__members">
                  <div className="radios__wrapper__new__members__item">
                    <input
                      type="radio"
                      id="es"
                      name="language"
                      value={0}
                      checked={initialDataPreregistration.idLang === 0}
                      onChange={(e) => {
                        i18n.changeLanguage("es");
                        setInitialDataPreregistration({
                          ...initialDataPreregistration,
                          idLang: parseInt(e.target.value),
                        });
                      }}
                    />
                    <label htmlFor="es">
                      {t(
                        "__form__new__members__title__info__personal__language__esp"
                      )}
                    </label>
                  </div>
                  <div className="radios__wrapper__new__members__item">
                    <input
                      type="radio"
                      id="en"
                      name="language"
                      value={1}
                      checked={initialDataPreregistration.idLang === 1}
                      onChange={(e) => {
                        i18n.changeLanguage("en");
                        setInitialDataPreregistration({
                          ...initialDataPreregistration,
                          idLang: parseInt(e.target.value),
                        });
                      }}
                    />
                    <label htmlFor="en">
                      {" "}
                      {t(
                        "__form__new__members__title__info__personal__language__eng"
                      )}
                    </label>
                  </div>
                </div>
              </FormControl>
              <FormControl className="radio__wrapper">
                <label>
                  {t(
                    "__form__new__members__title__info__personal__interesting"
                  )}
                </label>
                <div className="radios__wrapper__new__members">
                  <div className="radios__wrapper__new__members__item">
                    <input
                      type="radio"
                      id="li"
                      name="interest"
                      value={true}
                      checked={initialDataPreregistration.interested === true}
                      onChange={(e) => {
                        setInitialDataPreregistration({
                          ...initialDataPreregistration,
                          interested: true,
                        });
                      }}
                    />
                    <label htmlFor="li">
                      {t(
                        "__form__new__members__title__info__personal__interesting__leadership"
                      )}
                    </label>
                  </div>
                  <div className="radios__wrapper__new__members__item">
                    <input
                      type="radio"
                      id="ve"
                      name="interest"
                      value={false}
                      checked={initialDataPreregistration.interested === false}
                      onChange={(e) => {
                        setInitialDataPreregistration({
                          ...initialDataPreregistration,
                          interested: false,
                        });
                      }}
                    />
                    <label htmlFor="ve">
                      {t(
                        "__form__new__members__title__info__personal__interesting__sale"
                      )}
                    </label>
                  </div>
                  {/* <div className="radios__wrapper__new__members__item">
                    <input type="radio" id="am" name="interest" value="am" />
                    <label htmlFor="am"> {t(
                        "__form__new__members__title__info__personal__interesting__both"
                      )}{" "}</label>
                  </div> */}
                </div>
              </FormControl>
              <TextFieldAndButtonNewMembers
                labelName={t(
                  "__form__new__members__title__info__personal__create__pass"
                )}
                placeholderInput="********"
                isRequire={
                  // initialDataPreregistration.password.length <= 0 ||
                  // initialDataPreregistration.password !== confirmPassword
                  //   ? true
                  //   : false
                  errorPassword.showErrorLength ||
                  errorPassword.showErrorComparation
                }
                erroHelp={
                  initialDataPreregistration.password.length <= 0
                    ? t("__customer_support_form_message_error_empty_label")
                    : initialDataPreregistration.password !== confirmPassword
                    ? t(
                        "__forms_field_passwordConfirmed_NotEqual_validation_js_error_message"
                      )
                    : ""
                }
                isActiveHelp={
                  initialDataPreregistration.password.length <= 0 ||
                  initialDataPreregistration.password !== confirmPassword
                }
                valueInput={initialDataPreregistration.password}
                typeInput={typeInputPassword}
                setValue={(e) => {
                  setInitialDataPreregistration({
                    ...initialDataPreregistration,
                    password: e.target.value,
                  });
                }}
                disabledButton={false}
                iconClass="eyeRedOnlyIcon"
                handleEventButton={() => {
                  if (typeInputPassword === "password")
                    setTypeInputPassword("text");
                  else setTypeInputPassword("password");
                }}
                isIconActive={true}
                labelButton=""
                maxLength={8}
              />
              <TextFieldAndButtonNewMembers
                labelName={t(
                  "__form__new__members__title__info__personal__confirm__pass"
                )}
                isRequire={
                  // confirmPassword.length <= 0 ||
                  // initialDataPreregistration.password !== confirmPassword
                  //   ? true
                  //   : false
                  errorPassword.showErrorLength ||
                  errorPassword.showErrorComparation
                }
                placeholderInput="********"
                erroHelp={
                  confirmPassword.length <= 0
                    ? t("__customer_support_form_message_error_empty_label")
                    : initialDataPreregistration.password !== confirmPassword
                    ? t(
                        "__forms_field_passwordConfirmed_NotEqual_validation_js_error_message"
                      )
                    : ""
                }
                isActiveHelp={
                  confirmPassword.length <= 0 ||
                  initialDataPreregistration.password !== confirmPassword
                }
                valueInput={confirmPassword}
                typeInput={typeInputPasswordConfirm}
                setValue={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                disabledButton={false}
                iconClass="eyeRedOnlyIcon"
                handleEventButton={() => {
                  if (typeInputPasswordConfirm === "password")
                    setTypeInputPasswordConfirm("text");
                  else setTypeInputPasswordConfirm("password");
                }}
                isIconActive={true}
                labelButton=""
                maxLength={8}
              />
            </div>
            <div
              className={`form__new__account__content__row__item ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            ></div>
            <span
              className={`form__new__members__main__form__wrapper__fields__main__title ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              {t("__form__new__members__title__info__personal__id")}
            </span>
            <div
              className={`form__new__members__main__form__wrapper__fields__main__personal ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              <TextFieldNewMembers
                labelName={t(
                  "__form__new__members__title__info__personal__nss"
                )}
                typeInput="text"
                isRequire={errorIdentification.show}
                valueInput={initialDataPreregistration.nss}
                erroHelp={
                  initialDataPreregistration.nss.length > 0 &&
                  initialDataPreregistration.nss.length < 9 &&
                  t("__form__new__members__info__warning__nss")
                }
                isActiveHelp={
                  initialDataPreregistration.nss.length > 0 &&
                  initialDataPreregistration.nss.length < 9
                }
                setValue={(e) => {
                  if (isIdentificationNssValid)
                    setIsIdentificationNssValid(false);

                  setInitialDataPreregistration({
                    ...initialDataPreregistration,
                    nss: restrictToOnlyLettersAndNumbers(e),
                  });
                }}
                onBlur={(e) => {
                  if (initialDataPreregistration.nss.length === 9)
                    identificationValidationOnBlur("NSS");
                }}
                maxLength={9}
              />
              <TextFieldNewMembers
                labelName={t(
                  "__form__new__members__title__info__personal__no__license"
                )}
                typeInput="text"
                valueInput={initialDataPreregistration.license}
                isRequire={errorIdentification.show}
                erroHelp={
                  initialDataPreregistration.license.length > 0 &&
                  initialDataPreregistration.license.length < 5 &&
                  t("__form__new__members__info__warning__license")
                }
                isActiveHelp={
                  initialDataPreregistration.license.length > 0 &&
                  initialDataPreregistration.license.length < 5
                }
                setValue={(e) => {
                  if (isIdentificationLicValid)
                    setIsIdentificationLicValid(false);

                  setInitialDataPreregistration({
                    ...initialDataPreregistration,
                    license: restrictToOnlyLettersAndNumbers(e),
                  });
                }}
                onBlur={(e) => {
                  if (initialDataPreregistration.license.length > 5)
                    identificationValidationOnBlur("LIC");
                }}
                maxLength={17}
              />
              <TextFieldNewMembers
                labelName={t(
                  "__form__new__members__title__info__personal__other"
                )}
                typeInput="text"
                isRequire={errorIdentification.show}
                valueInput={initialDataPreregistration.anotherId}
                erroHelp={
                  initialDataPreregistration.anotherId.length > 0 &&
                  initialDataPreregistration.anotherId.length < 5 &&
                  "La Licencia de llevar 5 caracteres"
                }
                isActiveHelp={
                  initialDataPreregistration.anotherId.length > 0 &&
                  initialDataPreregistration.anotherId.length < 5
                }
                setValue={(e) => {
                  if (isIdentificationRfcValid)
                    setIsIdentificationRfcValid(false);

                  setInitialDataPreregistration({
                    ...initialDataPreregistration,
                    anotherId: restrictToOnlyLettersAndNumbers(e),
                  });
                }}
                onBlur={(e) => {
                  if (initialDataPreregistration.anotherId.length > 5)
                    identificationValidationOnBlur("RFC");
                }}
                maxLength={15}
              />
            </div>
            <span
              className={`form__new__members__main__form__wrapper__fields__main__title ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              {t("__form__new__members__info__contact")}
            </span>
            <div
              className={`form__new__members__main__form__wrapper__fields__main__personal ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              <TextFieldNewMembers
                labelName={t("__form__new__members__info__contact__phone")}
                isRequire={
                  // initialDataPreregistration.cellPhone.length <= 0 ||
                  // !regexIsCellphoneValid(
                  //   initialDataPreregistration.cellPhone
                  // ) ||
                  // cellMessageErrorServer.length > 0
                  //   ? true
                  //   : false
                  errorCellPhone.show
                }
                typeInput="text"
                valueInput={initialDataPreregistration.cellPhone}
                erroHelp={
                  initialDataPreregistration.cellPhone.length <= 0
                    ? t("__customer_support_form_message_error_empty_label")
                    : !regexIsCellphoneValid(
                        initialDataPreregistration.cellPhone
                      )
                    ? t("__forms_field_cellPhone_validation_js_error_message")
                    : cellMessageErrorServer.length > 0
                    ? cellMessageErrorServer
                    : ""
                }
                isActiveHelp={
                  initialDataPreregistration.cellPhone.length <= 0 ||
                  !regexIsCellphoneValid(
                    initialDataPreregistration.cellPhone
                  ) ||
                  cellMessageErrorServer.length > 0
                }
                setValue={(e) => {
                  if (showVerfieldCellPhoneField)
                    setShowVerfieldCellPhoneField(false);
                  if (isCellPhoneVerified) setIsCellPhoneVerified(false);
                  if (cellMessageErrorServer.length > 0)
                    setCellMessageErrorServer("");

                  setInitialDataPreregistration({
                    ...initialDataPreregistration,
                    cellPhone: restrictToOnlyNumbersTwo(e),
                  });
                }}
                maxLength={10}
                onBlur={(e) => {
                  if (
                    regexIsCellphoneValid(initialDataPreregistration.cellPhone)
                  )
                    cellPhoneValidationOnBlur();
                }}
              />
              {showVerfieldCellPhoneField && (
                <TextFieldAndButtonNewMembers
                  labelName={t(
                    "__form__new__members__info__contact__phone__message"
                  )}
                  placeholderInput="000000"
                  isRequire={errorCellPhone.show}
                  erroHelp={
                    cellPhoneCode.length <= 0
                      ? t(
                          "__ZUsa_NavBar_Enter_The_Code_You_Recieved_To_Confirm"
                        )
                      : cellPhoneCode.length > 0 && cellPhoneCode.length < 6
                      ? `${t(
                          "__form__new__members__info__warning__compleate__sms__one"
                        )}, ${t(
                          "__form__new__members__info__warning__compleate__sms__two"
                        )} ${6 - cellPhoneCode.length} ${t(
                          "__form__new__members__info__warning__compleate__sms__three"
                        )}`
                      : cellMessageErrorServer.length > 0
                      ? cellMessageErrorServer
                      : ""
                  }
                  isActiveHelp={
                    cellPhoneCode.length <= 0 ||
                    (cellPhoneCode.length > 0 && cellPhoneCode.length < 6) ||
                    cellMessageErrorServer.length > 0 // falta la válidacion del codigo
                  }
                  valueInput={cellPhoneCode}
                  typeInput={"text"}
                  setValue={(e) => {
                    setCellPhoneCode(restrictToOnlyNumbersTwo(e, 6));
                  }}
                  disabledButton={cellPhoneCode.length !== 6}
                  iconClass=""
                  handleEventButton={() => {
                    //debugger;
                    if (cellPhoneCode.length === 6) {
                      verifyCellPhoneCodeIsValid();
                    }
                  }}
                  isIconActive={false}
                  labelButton={t(
                    "__form__new__members__info__code__btn__valid"
                  )}
                  maxLength={6}
                />
              )}
              <TextFieldNewMembers
                labelName={t("__form__new__members__info__contact__email")}
                typeInput="text"
                valueInput={initialDataPreregistration.email}
                // isRequire={
                //   //initialDataPreregistration.email.length <= 0 ? true : false
                //   errorEmail.show
                // }
                erroHelp={
                  // initialDataPreregistration.email.length <= 0
                  //   ? t("__customer_support_form_message_error_empty_label")
                  //   :
                  !regexIsEmailAddressValid(initialDataPreregistration.email)
                    ? t("__message_email_invalid_label")
                    : emailMessageErrorServer.length > 0
                    ? emailMessageErrorServer
                    : "" // validacion existencía de email en bd (falta)
                }
                isActiveHelp={
                  //initialDataPreregistration.email.length <= 0 ||
                  !regexIsEmailAddressValid(initialDataPreregistration.email) ||
                  emailMessageErrorServer.length > 0
                }
                setValue={(e) => {
                  if (isEmailVerified === true) setIsEmailVerified(false);
                  if (emailMessageErrorServer.length > 0)
                    setEmailMessageErrorServer("");

                  setInitialDataPreregistration({
                    ...initialDataPreregistration,
                    email: restrictToOnlyLettersForEmail(e),
                  });
                }}
                onBlur={(e) => {
                  if (
                    regexIsEmailAddressValid(initialDataPreregistration.email)
                  )
                    emailValidationOnBlur();
                }}
              />
              <FormControl className="radio__wrapper">
                <label>{t("__form__new__members__info__contact__zone")}</label>
                <div className="radios__wrapper__new__members">
                  <div className="radios__wrapper__new__members__near">
                    <input
                      type="radio"
                      id="li"
                      name="zone"
                      value="PACIFIC"
                      checked={
                        initialDataPreregistration.timeZone === "PACIFIC"
                      }
                      onChange={(e) => {
                        setInitialDataPreregistration({
                          ...initialDataPreregistration,
                          timeZone: e.target.value,
                        });
                      }}
                    />
                    <label htmlFor="pa">
                      {t("__form__new__members__info__contact__pacific")}
                    </label>
                  </div>
                  <div className="radios__wrapper__new__members__near">
                    <input
                      type="radio"
                      id="ce"
                      name="zone"
                      value="CENTRAL"
                      checked={
                        initialDataPreregistration.timeZone === "CENTRAL"
                      }
                      onChange={(e) => {
                        setInitialDataPreregistration({
                          ...initialDataPreregistration,
                          timeZone: e.target.value,
                        });
                      }}
                    />
                    <label htmlFor="ce">
                      {" "}
                      {t("__form__new__members__info__contact__center")}
                    </label>
                  </div>
                  <div className="radios__wrapper__new__members__near">
                    <input
                      type="radio"
                      id="este"
                      name="zone"
                      value="EASTERN"
                      checked={
                        initialDataPreregistration.timeZone === "EASTERN"
                      }
                      onChange={(e) => {
                        setInitialDataPreregistration({
                          ...initialDataPreregistration,
                          timeZone: e.target.value,
                        });
                      }}
                    />
                    <label htmlFor="este">
                      {" "}
                      {t("__form__new__members__info__contact__east")}
                    </label>
                  </div>
                </div>
              </FormControl>
              <FormControl className="radio__wrapper">
                <label
                  className={
                    ""
                    // initialDataPreregistration.acceptZermatNofications ===false
                    // ? `text__required` : ``
                  }
                >
                  {t("__form__new__members__info__auth")}
                </label>
                <div className="radios__wrapper__new__members">
                  <div className="radios__wrapper__new__members__near">
                    <input
                      type="radio"
                      id="yes"
                      name="auth"
                      value={true}
                      onChange={(e) => {
                        setInitialDataPreregistration({
                          ...initialDataPreregistration,
                          acceptZermatNofications: true,
                        });
                      }}
                      checked={
                        initialDataPreregistration.acceptZermatNofications ===
                        true
                      }
                    />
                    <label htmlFor="yes">
                      {t("__form__new__members__info__auth__yes")}
                    </label>
                  </div>
                  <div className="radios__wrapper__new__members__near">
                    <input
                      type="radio"
                      id="no"
                      name="auth"
                      value={false}
                      onChange={(e) => {
                        setInitialDataPreregistration({
                          ...initialDataPreregistration,
                          acceptZermatNofications: false,
                        });
                      }}
                      checked={
                        initialDataPreregistration.acceptZermatNofications ===
                        false
                      }
                    />

                    <label htmlFor="no">
                      {t("__form__new__members__info__auth__no")}
                    </label>
                  </div>
                </div>
              </FormControl>
            </div>
            <span
              className={`form__new__members__main__form__wrapper__fields__main__title ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              {t("__form__new__members__info__address__bill")}
            </span>
            <div
              className={`form__new__members__main__form__wrapper__fields__main__personal ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              <TextFieldNewMembers
                labelName={t("__form__new__members__info__address__street")}
                typeInput="text"
                valueInput={initialDataPreregistration.streetFiscal}
                isRequire={
                  // initialDataPreregistration.streetFiscal.length <= 0
                  //   ? true
                  //   : false
                  errorShippingAddress.show
                }
                erroHelp={
                  initialDataPreregistration.streetFiscal.length <= 0 &&
                  t("__customer_support_form_message_error_empty_label")
                }
                isActiveHelp={
                  initialDataPreregistration.streetFiscal.length <= 0
                }
                setValue={(e) => {
                  if (isFiscalAddressVerified === true) {
                    setIsFiscalAddressVerified(false);
                    if (useAsShippAddress === true) {
                      setUseAsShippAddress(false);
                      setIsShipmentAddressVerified(false);
                      setInitialDataPreregistration({
                        ...initialDataPreregistration,
                        zipShipping: "",
                        cityShipping: "",
                        stateShipping: "",
                        streetShipping: "",
                        suiteShipping: "",
                        streetFiscal: e.target.value,
                      });
                    } else {
                      setInitialDataPreregistration({
                        ...initialDataPreregistration,
                        streetFiscal: e.target.value,
                      });
                    }
                  } else {
                    setInitialDataPreregistration({
                      ...initialDataPreregistration,
                      streetFiscal: e.target.value,
                    });
                  }
                }}
              />
              <TextFieldNewMembers
                labelName={t("__form__new__members__info__address__dpto")}
                typeInput="text"
                isRequire={false}
                valueInput={initialDataPreregistration.suiteFiscal}
                // erroHelp={initialDataPreregistration.suiteFiscal.length <= 0 && "Campo Vacío"}
                // isActiveHelp={initialDataPreregistration.suiteFiscal.length <= 0}
                setValue={(e) => {
                  if (isFiscalAddressVerified === true) {
                    setIsFiscalAddressVerified(false);
                    if (useAsShippAddress === true) {
                      setUseAsShippAddress(false);
                      setIsShipmentAddressVerified(false);
                      setInitialDataPreregistration({
                        ...initialDataPreregistration,
                        zipShipping: "",
                        cityShipping: "",
                        stateShipping: "",
                        streetShipping: "",
                        suiteShipping: "",
                        suiteFiscal: e.target.value,
                      });
                    } else {
                      setInitialDataPreregistration({
                        ...initialDataPreregistration,
                        suiteFiscal: e.target.value,
                      });
                    }
                  } else {
                    setInitialDataPreregistration({
                      ...initialDataPreregistration,
                      suiteFiscal: e.target.value,
                    });
                  }
                }}
              />
              <div
                className={`mui__select__wrapper ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                <FormControl
                  variant="standard"
                  fullWidth
                  error={errorFiscalAddress.show}
                >
                  <InputLabel
                    className={
                      // initialDataPreregistration.stateFiscal.length <= 0
                      //   ? "text__required"
                      //   : ""
                      errorFiscalAddress.show ? "text__required" : ""
                    }
                    id="stateFiscalLabel"
                  >
                    {t("__form__new__members__info__address__state")}
                  </InputLabel>
                  <Select
                    labelId="stateFiscalLabel"
                    required
                    fullWidth
                    value={initialDataPreregistration.stateFiscal}
                    onChange={(e) => {
                      if (isFiscalAddressVerified === true) {
                        setIsFiscalAddressVerified(false);
                        if (useAsShippAddress === true) {
                          setUseAsShippAddress(false);
                          setIsShipmentAddressVerified(false);
                          setInitialDataPreregistration({
                            ...initialDataPreregistration,
                            zipShipping: "",
                            cityShipping: "",
                            stateShipping: "",
                            streetShipping: "",
                            suiteShipping: "",
                            stateFiscal: e.target.value,
                          });
                        } else {
                          setInitialDataPreregistration({
                            ...initialDataPreregistration,
                            stateFiscal: e.target.value,
                          });
                        }
                      } else {
                        setInitialDataPreregistration({
                          ...initialDataPreregistration,
                          stateFiscal: e.target.value,
                        });
                      }
                    }}
                    error={initialDataPreregistration.stateFiscal.length === 0}
                  >
                    {statesResult.map((state, index) => (
                      <MenuItem key={index} value={state.state}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {initialDataPreregistration.stateFiscal.length === 0 && (
                    <span id="my-helper-text">
                      {t("__form__new__members__info__warning__select__state")}
                    </span>
                  )}
                </FormControl>
              </div>
              <TextFieldNewMembers
                labelName={t("__form__new__members__info__address__city")}
                isRequire={
                  // initialDataPreregistration.cityFiscal.length <= 0
                  //   ? true
                  //   : false
                  errorFiscalAddress.show
                }
                typeInput="text"
                valueInput={initialDataPreregistration.cityFiscal}
                erroHelp={
                  initialDataPreregistration.cityFiscal.length <= 0 &&
                  t("__customer_support_form_message_error_empty_label")
                }
                isActiveHelp={initialDataPreregistration.cityFiscal.length <= 0}
                setValue={(e) => {
                  if (isFiscalAddressVerified === true) {
                    setIsFiscalAddressVerified(false);
                    if (useAsShippAddress === true) {
                      setUseAsShippAddress(false);
                      setIsShipmentAddressVerified(false);
                      setInitialDataPreregistration({
                        ...initialDataPreregistration,
                        zipShipping: "",
                        cityShipping: "",
                        stateShipping: "",
                        streetShipping: "",
                        suiteShipping: "",
                        cityFiscal: e.target.value,
                      });
                    } else {
                      setInitialDataPreregistration({
                        ...initialDataPreregistration,
                        cityFiscal: e.target.value,
                      });
                    }
                  } else {
                    setInitialDataPreregistration({
                      ...initialDataPreregistration,
                      cityFiscal: e.target.value,
                    });
                  }
                }}
              />
              <TextFieldNewMembers
                labelName={t("__form__new__members__info__address__cp")}
                typeInput="text"
                valueInput={initialDataPreregistration.zipFiscal}
                erroHelp={
                  initialDataPreregistration.zipFiscal.length <= 0
                    ? t("__customer_support_form_message_error_empty_label")
                    : initialDataPreregistration.zipFiscal.length > 0 &&
                      initialDataPreregistration.zipFiscal.length !== 5
                    ? t("__form__new__members__info__warning__incorrect__zip")
                    : ""
                }
                isRequire={
                  // initialDataPreregistration.zipFiscal.length <= 0 ||
                  // (initialDataPreregistration.zipFiscal.length > 0 &&
                  //   initialDataPreregistration.zipFiscal.length !== 5)
                  //   ? true
                  //   : false
                  errorFiscalAddress.show
                }
                isActiveHelp={
                  initialDataPreregistration.zipFiscal.length <= 0 ||
                  (initialDataPreregistration.zipFiscal.length > 0 &&
                    initialDataPreregistration.zipFiscal.length !== 5)
                }
                setValue={(e) => {
                  if (isFiscalAddressVerified === true) {
                    setIsFiscalAddressVerified(false);
                    if (useAsShippAddress === true) {
                      setUseAsShippAddress(false);
                      setIsShipmentAddressVerified(false);
                      setInitialDataPreregistration({
                        ...initialDataPreregistration,
                        zipShipping: "",
                        cityShipping: "",
                        stateShipping: "",
                        streetShipping: "",
                        suiteShipping: "",
                        zipFiscal: restrictToOnlyNumbersTwo(e, 5),
                      });
                    } else {
                      setInitialDataPreregistration({
                        ...initialDataPreregistration,
                        zipFiscal: restrictToOnlyNumbersTwo(e, 5),
                      });
                    }
                  } else {
                    setInitialDataPreregistration({
                      ...initialDataPreregistration,
                      zipFiscal: restrictToOnlyNumbersTwo(e, 5),
                    });
                  }
                }}
                maxLength={5}
              />

              <div
                className={`form__new__members__main__form__wrapper__fields__main__button ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                <Button
                  className={`form__new__members__main__form__wrapper__fields__main__button__valid ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  } ${
                    isFiscalAddressVerified === true &&
                    initialDataPreregistration.zipFiscal.length !== 5 &&
                    initialDataPreregistration.cityFiscal.length <= 0 &&
                    initialDataPreregistration.stateFiscal.length <= 0 &&
                    initialDataPreregistration.streetFiscal.length <= 0
                      ? "button__click__me"
                      : ""
                  }`}
                  variant="contained"
                  disabled={
                    isFiscalAddressVerified === true ||
                    initialDataPreregistration.zipFiscal.length !== 5 ||
                    initialDataPreregistration.cityFiscal.length <= 0 ||
                    initialDataPreregistration.stateFiscal.length <= 0 ||
                    initialDataPreregistration.streetFiscal.length <= 0
                  }
                  onClick={(e) => {
                    handleAddressOpenValidation("f");
                  }}
                >
                  {t("__form__new__members__info__address__valid")}
                </Button>
              </div>

              <FormControl className="radio__wrapper">
                <label>{t("__form__new__members__info__use__address")} </label>
                <div className="radios__wrapper__new__members">
                  <div className="radios__wrapper__new__members__near">
                    <input
                      type="checkbox"
                      disabled={isFiscalAddressVerified === false}
                      checked={useAsShippAddress}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setInitialDataPreregistration({
                            ...initialDataPreregistration,
                            zipShipping: initialDataPreregistration.zipFiscal,
                            cityShipping: initialDataPreregistration.cityFiscal,
                            stateShipping:
                              initialDataPreregistration.stateFiscal,
                            streetShipping:
                              initialDataPreregistration.streetFiscal,
                            suiteShipping:
                              initialDataPreregistration.suiteFiscal,
                          });
                          setIsShipmentAddressVerified(true);
                        } else {
                          setInitialDataPreregistration({
                            ...initialDataPreregistration,
                            zipShipping: "",
                            cityShipping: "",
                            stateShipping: "",
                            streetShipping: "",
                            suiteShipping: "",
                          });
                          setIsShipmentAddressVerified(false);
                        }
                        setUseAsShippAddress(e.target.checked);
                      }}
                    />
                    <label htmlFor="yes">
                      {" "}
                      {t("__form__new__members__info__auth__yes")}
                    </label>
                  </div>
                  {/* <div className="radios__wrapper__new__members__near">
                    <input type="radio" id="no" name="invoice" value="no" />
                    <label htmlFor="no">No</label>
                  </div> */}
                </div>
              </FormControl>
            </div>
            {useAsShippAddress === false && (
              <Fragment>
                <span
                  className={`form__new__members__main__form__wrapper__fields__main__title ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  }`}
                >
                  {t("__form__new__members__info__address")}
                </span>
                <div
                  className={`form__new__members__main__form__wrapper__fields__main__personal ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  }`}
                >
                  <TextFieldNewMembers
                    labelName={t("__form__new__members__info__address__street")}
                    isRequire={
                      // initialDataPreregistration.streetShipping.length <= 0
                      //   ? true
                      //   : false
                      errorShippingAddress.show
                    }
                    typeInput="text"
                    valueInput={initialDataPreregistration.streetShipping}
                    erroHelp={
                      initialDataPreregistration.streetShipping.length <= 0 &&
                      t("__customer_support_form_message_error_empty_label")
                    }
                    isActiveHelp={
                      initialDataPreregistration.streetShipping.length <= 0
                    }
                    setValue={(e) => {
                      if (isShipmentAddressVerified === true)
                        setIsShipmentAddressVerified(false);

                      setInitialDataPreregistration({
                        ...initialDataPreregistration,
                        streetShipping: e.target.value,
                      });
                    }}
                  />
                  <TextFieldNewMembers
                    labelName={t("__form__new__members__info__address__dpto")}
                    typeInput="text"
                    valueInput={initialDataPreregistration.suiteShipping}
                    isRequire={false}
                    // erroHelp={initialDataPreregistration.suiteShipping.length <= 0 && "Campo Vacío"}
                    // isActiveHelp={initialDataPreregistration.suiteShipping.length <= 0}
                    setValue={(e) => {
                      if (isShipmentAddressVerified === true)
                        setIsShipmentAddressVerified(false);

                      setInitialDataPreregistration({
                        ...initialDataPreregistration,
                        suiteShipping: e.target.value,
                      });
                    }}
                  />
                  <div
                    className={`mui__select__wrapper ${
                      isTablet ? "tablet" : isMobile ? "mobile" : ""
                    }`}
                  >
                    <FormControl
                      variant="standard"
                      fullWidth
                      error={errorShippingAddress.show}
                    >
                      <InputLabel
                        id="stateShippLabel"
                        className={
                          // initialDataPreregistration.stateShipping.length === 0
                          //   ? "text__required"
                          //   : ""
                          errorShippingAddress.show ? "text__required" : ""
                        }
                      >
                        {t("__form__new__members__info__address__state")}
                      </InputLabel>
                      <Select
                        labelId="stateShippLabel"
                        fullWidth
                        required
                        value={initialDataPreregistration.stateShipping}
                        onChange={(e) => {
                          if (isShipmentAddressVerified === true)
                            setIsShipmentAddressVerified(false);

                          setInitialDataPreregistration({
                            ...initialDataPreregistration,
                            stateShipping: e.target.value,
                          });
                        }}
                        error={
                          initialDataPreregistration.stateShipping.length === 0
                        }
                      >
                        {statesResult.map((state, index) => (
                          <MenuItem
                            fullWidth
                            key={index}
                            value={state.state}
                            dense
                          >
                            {state.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {initialDataPreregistration.stateShipping.length ===
                        0 && (
                        <span id="my-helper-text">
                          {t(
                            "__form__new__members__info__warning__select__state"
                          )}
                        </span>
                      )}
                    </FormControl>
                  </div>

                  <TextFieldNewMembers
                    labelName={t("__form__new__members__info__address__city")}
                    isRequire={
                      // initialDataPreregistration.cityShipping.length <= 0
                      //   ? true
                      //   : false
                      errorShippingAddress.show
                    }
                    typeInput="text"
                    valueInput={initialDataPreregistration.cityShipping}
                    erroHelp={
                      initialDataPreregistration.cityShipping.length <= 0 &&
                      t("__customer_support_form_message_error_empty_label")
                    }
                    isActiveHelp={
                      initialDataPreregistration.cityShipping.length <= 0
                    }
                    setValue={(e) => {
                      if (isShipmentAddressVerified === true)
                        setIsShipmentAddressVerified(false);

                      setInitialDataPreregistration({
                        ...initialDataPreregistration,
                        cityShipping: e.target.value,
                      });
                    }}
                  />
                  <TextFieldNewMembers
                    labelName={t("__form__new__members__info__address__cp")}
                    isRequire={
                      // initialDataPreregistration.zipShipping.length <= 0 ||
                      // (initialDataPreregistration.zipShipping.length > 0 &&
                      //   initialDataPreregistration.zipShipping.length !== 5)
                      //   ? true
                      //   : false
                      errorShippingAddress.show
                    }
                    typeInput="text"
                    valueInput={initialDataPreregistration.zipShipping}
                    erroHelp={
                      initialDataPreregistration.zipShipping.length <= 0
                        ? t("__customer_support_form_message_error_empty_label")
                        : initialDataPreregistration.zipShipping.length > 0 &&
                          initialDataPreregistration.zipShipping.length !== 5
                        ? t(
                            "__form__new__members__info__warning__incorrect__zip"
                          )
                        : ""
                    }
                    isActiveHelp={
                      initialDataPreregistration.zipShipping.length <= 0 ||
                      (initialDataPreregistration.zipShipping.length > 0 &&
                        initialDataPreregistration.zipShipping.length !== 5)
                    }
                    setValue={(e) => {
                      if (isShipmentAddressVerified === true)
                        setIsShipmentAddressVerified(false);

                      setInitialDataPreregistration({
                        ...initialDataPreregistration,
                        zipShipping: restrictToOnlyNumbersTwo(e, 5),
                      });
                    }}
                    maxLength={5}
                  />

                  <div
                    className={`form__new__members__main__form__wrapper__fields__main__button ${
                      isTablet ? "tablet" : isMobile ? "mobile" : ""
                    }`}
                  >
                    <Button
                      className={`form__new__members__main__form__wrapper__fields__main__button__valid ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      } ${
                        isShipmentAddressVerified === true &&
                        initialDataPreregistration.zipShipping.length !== 5 &&
                        initialDataPreregistration.cityShipping.length <= 0 &&
                        initialDataPreregistration.stateShipping.length <= 0 &&
                        initialDataPreregistration.streetShipping.length <= 0
                          ? "button__click__me"
                          : ""
                      }`}
                      variant="contained"
                      disabled={
                        isShipmentAddressVerified === true ||
                        initialDataPreregistration.zipShipping.length !== 5 ||
                        initialDataPreregistration.cityShipping.length <= 0 ||
                        initialDataPreregistration.stateShipping.length <= 0 ||
                        initialDataPreregistration.streetShipping.length <= 0
                      }
                      onClick={(e) => {
                        handleAddressOpenValidation("e");
                      }}
                    >
                      {t("__form__new__members__info__address__valid")}
                    </Button>
                  </div>
                </div>
              </Fragment>
            )}

            <div
              className={`form__new__members__main__form__wrapper__fields__main__terms ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              <small>{t("__form__new__members__info__read__terms")}</small>
              <FormControl className="radio__wrapper">
                <div className="radios__wrapper__new__members">
                  <div className="radios__wrapper__new__members__item">
                    <a
                      onClick={() => {
                        setIsActiveFlotingTerms(true);
                      }}
                    >
                      <label
                        style={
                          //!checkAccept
                          errorAcceptTermsConditions.show
                            ? { color: "#ff5733" }
                            : { color: "#000" }
                        }
                        htmlFor="term"
                      >
                        {t("__form__new__members__info__acept__terms")}
                      </label>
                    </a>
                    <input
                      type="checkbox"
                      id="yes"
                      name="term"
                      checked={checkAccept}
                      onChange={(e) => {
                        setCheckAccept(e.target.checked);
                      }}
                    />
                  </div>
                </div>
              </FormControl>
            </div>
            <Divider />
            <div
              className={`form__new__members__main__form__wrapper__fields__main__register ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              <Button
                // disabled={
                //   initialDataPreregistration.name.length <= 0 ||
                //   initialDataPreregistration.lastname.length <= 0 ||
                //   initialDataPreregistration.password.length <= 0 ||
                //   confirmPassword.length <= 0 ||
                //   initialDataPreregistration.password !== confirmPassword ||
                //   selectedDate === null ||
                //   isCellPhoneVerified === false ||
                //   isEmailVerified === false ||
                //   isShipmentAddressVerified === false ||
                //   isFiscalAddressVerified === false ||
                //   currentKitObject.hasOwnProperty("itemCode") === false ||
                //   checkAccept === false
                // }
                onClick={() => {
                  if (handleEvaluationPristineForm())
                    handleKitAdmissionButtonClick();
                }}
                className={`form__new__members__main__form__wrapper__fields__main__register__button ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                {t("__form__new__members__info__register__button")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormNewMembers;
