import {
  RESPONSE_CUSTOM_LABELS_CONFIGURATION,
  RESPONSE_VALIDATE_CUSTOM_LABELS,
  RESPONSE_CUSTOM_LABEL_UPDATE_CONFIGURATION,
  RESPONSE_CUSTOM_LABEL_BUILD_IMAGES_ON_SERVER,
  RESPONSE_CUSTOM_LABELS_CONFIGURATION_BY_DETAIL,
  RESPONSE_CUSTOM_LABEL_UPDATE_CONFIGURATION_BY_DETAIL,
  RESPONSE_CUSTOM_LABEL_CONFIGURATION_BY_PROMOTION,
} from "../../Constants";

export default function customLabels(state = {}, action) {
  switch (action.type) {
    case RESPONSE_CUSTOM_LABELS_CONFIGURATION: {
      const responseCustomLabelsConfiguration = action.payload.response;
      return { ...state, responseCustomLabelsConfiguration };
    }
    case RESPONSE_VALIDATE_CUSTOM_LABELS: {
      const responseValidateCustomLabels = action.payload.response;
      return { ...state, responseValidateCustomLabels };
    }
    case RESPONSE_CUSTOM_LABEL_UPDATE_CONFIGURATION: {
      const responseUpdateCustomLabels = action.payload.response;
      return { ...state, responseUpdateCustomLabels}
    }
    case RESPONSE_CUSTOM_LABEL_BUILD_IMAGES_ON_SERVER: {
      const responsebuildImagesCustomLabels = action.payload.response;
      return { ...state, responsebuildImagesCustomLabels}
    }
    case RESPONSE_CUSTOM_LABELS_CONFIGURATION_BY_DETAIL: {
      const responseCustomLabelsConfigurationByDetail = action.payload.response;
      return { ...state, responseCustomLabelsConfigurationByDetail };
    }
    case RESPONSE_CUSTOM_LABEL_UPDATE_CONFIGURATION_BY_DETAIL: {
      const responseUpdateCustomLabelsByDetail = action.payload.response;
      return { ...state, responseUpdateCustomLabelsByDetail}
    }
    case RESPONSE_CUSTOM_LABEL_CONFIGURATION_BY_PROMOTION: {
      const responseCustomLabelsConfigurationByPromo = action.payload.response;
      return { ...state, responseCustomLabelsConfigurationByPromo}
    }
    default:
      return { ...state };
  }
}
