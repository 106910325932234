import React from "react";
import { useTranslation } from "react-i18next";

const CustomerBadge = ({ isDetailShow }) => {
  /// TRANSLATION
  const { t } = useTranslation();

  return (
    <>
      <div className="customer__badge">{t("__general__custom__labels")}</div>
    </>
  );
};

export default CustomerBadge;
