import {
  REQUEST_CUSTOM_LABELS_CONFIGURATION,
  REQUEST_VALIDATE_CUSTOM_LABELS,
  REQUEST_CUSTOM_LABEL_UPDATE_CONFIGURATION,
  REQUEST_CUSTOM_LABEL_BUILD_IMAGES_ON_SERVER,
  REQUEST_CUSTOM_LABELS_CONFIGURATION_BY_DETAIL,
  REQUEST_CUSTOM_LABEL_CONFIGURATION_BY_PROMOTION,
  REQUEST_CUSTOM_LABEL_UPDATE_CONFIGURATION_BY_DETAIL,
  CLEAN_CUSTOM_LABEL_REDUCER_UPDATE_CONFIGURATION,
  CLEAN_CUSTOM_LABEL_REDUCER_UPDATE_CONFIGURATION_BY_DETAIL,
  CLEAN_CUSTOM_LABEL_CONFIGURATION_BY_PROMOTION,
} from "./../../Constants";

export const requestCustomLabelsConfiguration = (orderId) => {
  return {
    type: REQUEST_CUSTOM_LABELS_CONFIGURATION,
    payload: {orderId},
  };
};

export const requestValidateCustomLabelConfigurationOnOrder = orderId => {
  let sessionId = 0
  if (localStorage.getItem("ZUsaUInfo")) {
      sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId
  }
  const model = {
    orderId,
    sessionId
  }
  return {
    type: REQUEST_VALIDATE_CUSTOM_LABELS,
    payload: {model},
  };
};

export const requestUpdateCustomLabelConfiguration = (orderId, customLabelList) => {
  let sessionId = 0
  if (localStorage.getItem("ZUsaUInfo")) {
      sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId
  }
  const updateTable = customLabelList.map(item => ({
    id: item.id,
    label: item.label,
    template: item.template
  }))
  const model = {
    orderId,
    sessionId,
    updateTable
  }
  return {
    type: REQUEST_CUSTOM_LABEL_UPDATE_CONFIGURATION,
    payload: { model }
  }

}
export const requestBuildCustomLabelImagesOnServer = orderId => {
  return {
    type: REQUEST_CUSTOM_LABEL_BUILD_IMAGES_ON_SERVER,
    payload: { orderId }
  }
}
export const requestCustomLabelsConfigurationByDetail = (orderId, orderDetailId) => {
  return {
    type: REQUEST_CUSTOM_LABELS_CONFIGURATION_BY_DETAIL,
    payload: {orderId, orderDetailId}
  }
}
export const requestCustomLabelsConfigurationByPromotion = itemCode => {
  return {
    type: REQUEST_CUSTOM_LABEL_CONFIGURATION_BY_PROMOTION,
    payload: {itemCode}
  }
}
export const requestUpdateCustomLabelConfigurationByDetail = (orderId, orderDetailId, customLabelList) => {
  let sessionId = 0
  if (localStorage.getItem("ZUsaUInfo")) {
      sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId
  }
  const updateTable = customLabelList.map(item => ({
    id: item.id,
    label: item.label,
    template: item.template
  }))
  const model = {
    orderId,
    orderDetailId,
    sessionId,
    updateTable
  }
  return {
    type: REQUEST_CUSTOM_LABEL_UPDATE_CONFIGURATION_BY_DETAIL,
    payload: { model }
  }
}

export const cleanReducerUpdateCustomLabelsConfiguration = () => {
  return {
    type: CLEAN_CUSTOM_LABEL_REDUCER_UPDATE_CONFIGURATION,
    payload: {}
  }
}

export const cleanReducerUpdateCustomLabelsConfigurationByDetail = () => {
  return {
    type: CLEAN_CUSTOM_LABEL_REDUCER_UPDATE_CONFIGURATION_BY_DETAIL,
    payload: {}
  }
}

export const cleanReducerConfigurationCustomLabelByPromo = () => {
  return {
    type: CLEAN_CUSTOM_LABEL_CONFIGURATION_BY_PROMOTION,
    payload: {}
  }
}
