import { call, put, takeLatest } from 'redux-saga/effects'
import {REQUEST_NAVBAR_MODULE_INFO, RESPONSE_NAVBAR_MODULE_INFO, DATA_FETCH_FAILED} from '../../Constants'
import NavbarModule from '../../../../Libraries/Navbar'



function* requestNabvarModuleInfo(){
    try {
        const response = yield call(NavbarModule.GetNavbarModule);
        yield put({type: RESPONSE_NAVBAR_MODULE_INFO, payload: {response:response.getModules}});

    } catch (error) {
        console.error("load navbar module error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText })
    }
}

export function* watchRequestNavbarModuleInfo() {
    yield takeLatest(REQUEST_NAVBAR_MODULE_INFO, requestNabvarModuleInfo);
}