import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Skeleton,
} from "@mui/material";
import { isMobile } from "react-device-detect";
import { Add, Remove } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const FAQ = ({ isLanguageLoaded }) => {
  const [expanded, setExpanded] = useState(false);
  /// TRANSLATION
  const { t } = useTranslation();

  //EFFECTS
  useEffect(() => {
    document.title = "Zermat USA :: FAQ";
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div
      id="firstWrapperInBaseContainer"
      className="firstWrapperInBaseContainer faq__content"
    >
      <div className="faq__container__title">
        {isLanguageLoaded ? (
          <Typography variant="h6" gutterBottom className="text__red">
            {t("__faq_title_Txt")}
          </Typography>
        ) : (
          <Skeleton
            variant="rect"
            width={isMobile ? "90%" : "30%"}
            height={30}
          />
        )}
      </div>

      <div className="faq__container__subtitle">
        {isLanguageLoaded ? (
          <Typography variant="subtitle1" gutterBottom>
            {t("__faq_gral_title_Txt")}
          </Typography>
        ) : (
          <Skeleton
            variant="rect"
            width={isMobile ? "90%" : "30%"}
            height={30}
          />
        )}
      </div>
      <div className="faq__container">
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel1" ? (
                <Remove className="text__red" />
              ) : (
                <Add className="text__red" />
              )
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            {isLanguageLoaded ? (
              <Typography>{t("__faq_gral_question1_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionSummary>
          <AccordionDetails>
            {isLanguageLoaded ? (
              <Typography>{t("__faq_gral_anwswer1_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel2" ? (
                <Remove className="text__red" />
              ) : (
                <Add className="text__red" />
              )
            }
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            {isLanguageLoaded ? (
              <Typography>{t("__faq_gral_question2_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionSummary>
          <AccordionDetails>
            {isLanguageLoaded ? (
              <Typography>{t("__faq_gral_anwswer2_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel3" ? (
                <Remove className="text__red" />
              ) : (
                <Add className="text__red" />
              )
            }
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            {isLanguageLoaded ? (
              <Typography>{t("__faq_gral_question3_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionSummary>
          <AccordionDetails>
            {isLanguageLoaded ? (
              <Typography>{t("__faq_gral_anwswer3_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel4" ? (
                <Remove className="text__red" />
              ) : (
                <Add className="text__red" />
              )
            }
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            {isLanguageLoaded ? (
              <Typography>{t("__faq_gral_question4_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionSummary>
          <AccordionDetails>
            {isLanguageLoaded ? (
              <Typography>
                {t("__faq_gral_anwswer4_Txt") + " "}
                <em>
                  <a
                    rel="fedex"
                    target="_blank"
                    href="https://www.fedex.com/en-us/home.html"
                  >
                    {t("__faq_gral_shipping_page1_Txt")}
                  </a>
                </em>
                {" " + t("__faq_gral_anwswer4_1Txt")}
                <em>
                  <a
                    rel="fedex"
                    target="_blank"
                    href="https://www.ups.com/us/en/global.page"
                  >
                    {t("__faq_gral_shipping_page2_Txt")}
                  </a>
                </em>
                {t("__faq_gral_anwswer4_2Txt")}
              </Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel5" ? (
                <Remove className="text__red" />
              ) : (
                <Add className="text__red" />
              )
            }
            aria-controls="panel5bh-content"
            id="panel4bh-header"
          >
            {isLanguageLoaded ? (
              <Typography>{t("__faq_enterprising_question5_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionSummary>
          <AccordionDetails>
            {isLanguageLoaded ? (
              <Typography>{t("__faq_enterprising_anwswer5_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel6" ? (
                <Remove className="text__red" />
              ) : (
                <Add className="text__red" />
              )
            }
            aria-controls="panel6bh-content"
            id="panel6bh-header"
          >
            {isLanguageLoaded ? (
              <Typography>{t("__faq_enterprising_question6_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionSummary>
          <AccordionDetails>
            {isLanguageLoaded ? (
              <Typography>{t("__faq_enterprising_anwswer6_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel7" ? (
                <Remove className="text__red" />
              ) : (
                <Add className="text__red" />
              )
            }
            aria-controls="panel7bh-content"
            id="panel7bh-header"
          >
            {isLanguageLoaded ? (
              <Typography>{t("__faq_enterprising_question7_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionSummary>
          <AccordionDetails>
            {isLanguageLoaded ? (
              <Typography>{t("__faq_enterprising_anwswer7_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionDetails>
        </Accordion>
      </div>

      <div className="faq__container__subtitle">
        {isLanguageLoaded ? (
          <Typography variant="subtitle1" gutterBottom>
            {t("__faq_enterprising_Txt")}
          </Typography>
        ) : (
          <Skeleton
            variant="rect"
            width={isMobile ? "90%" : "30%"}
            height={30}
          />
        )}
      </div>
      <div className="faq__container">
        <Accordion
          expanded={expanded === "panel8"}
          onChange={handleChange("panel8")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel8" ? (
                <Remove className="text__red" />
              ) : (
                <Add className="text__red" />
              )
            }
            aria-controls="panel8bh-content"
            id="panel8bh-header"
          >
            {isLanguageLoaded ? (
              <Typography>{t("__faq_enterprising_question1_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionSummary>
          <AccordionDetails>
            {isLanguageLoaded ? (
              <Typography>
                {t("__faq_enterprising_anwswer1_Txt")} <br />
                {t("__faq_enterprising_anwswer1_1_Txt")}
              </Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel9"}
          onChange={handleChange("panel9")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel8" ? (
                <Remove className="text__red" />
              ) : (
                <Add className="text__red" />
              )
            }
            aria-controls="panel9bh-content"
            id="panel9bh-header"
          >
            {isLanguageLoaded ? (
              <Typography>{t("__faq_enterprising_question2_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionSummary>
          <AccordionDetails>
            {isLanguageLoaded ? (
              <Typography>{t("__faq_enterprising_anwswer2_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel10"}
          onChange={handleChange("panel10")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel10" ? (
                <Remove className="text__red" />
              ) : (
                <Add className="text__red" />
              )
            }
            aria-controls="panel10bh-content"
            id="panel10bh-header"
          >
            {isLanguageLoaded ? (
              <Typography>{t("__faq_enterprising_question3_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionSummary>
          <AccordionDetails>
            {isLanguageLoaded ? (
              <Typography>{t("__faq_enterprising_anwswer3_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel11"}
          onChange={handleChange("panel11")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel11" ? (
                <Remove className="text__red" />
              ) : (
                <Add className="text__red" />
              )
            }
            aria-controls="panel11bh-content"
            id="panel11bh-header"
          >
            {isLanguageLoaded ? (
              <Typography>{t("__faq_enterprising_question4_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionSummary>
          <AccordionDetails>
            {isLanguageLoaded ? (
              <Typography>
                {t("__faq_enterprising_anwswer4_Txt")}
                <em>
                  <a
                    rel="zermat business"
                    target="_blank"
                    href="https://www.websizusa.net/zermatbusiness#/Login"
                  >
                    {t("__faq_enterprising_page_Txt")}
                  </a>
                </em>
                {t("__faq_enterprising_anwswer4_1_Txt")}
              </Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel12"}
          onChange={handleChange("panel12")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel12" ? (
                <Remove className="text__red" />
              ) : (
                <Add className="text__red" />
              )
            }
            aria-controls="panel12bh-content"
            id="panel12bh-header"
          >
            {isLanguageLoaded ? (
              <Typography>{t("__faq_enterprising_question5_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionSummary>
          <AccordionDetails>
            {isLanguageLoaded ? (
              <Typography>{t("__faq_enterprising_anwswer5_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel13"}
          onChange={handleChange("panel13")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel13" ? (
                <Remove className="text__red" />
              ) : (
                <Add className="text__red" />
              )
            }
            aria-controls="panel13bh-content"
            id="panel13bh-header"
          >
            {isLanguageLoaded ? (
              <Typography>{t("__faq_enterprising_question6_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionSummary>
          <AccordionDetails>
            {isLanguageLoaded ? (
              <Typography>{t("__faq_enterprising_anwswer6_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel14"}
          onChange={handleChange("panel14")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel14" ? (
                <Remove className="text__red" />
              ) : (
                <Add className="text__red" />
              )
            }
            aria-controls="panel14bh-content"
            id="panel14bh-header"
          >
            {isLanguageLoaded ? (
              <Typography>{t("__faq_enterprising_question7_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionSummary>
          <AccordionDetails>
            {isLanguageLoaded ? (
              <Typography>{t("__faq_enterprising_anwswer7_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="faq__container__subtitle">
        {isLanguageLoaded ? (
          <Typography variant="subtitle1" gutterBottom>
            {t("__faq_CP_Txt")}
          </Typography>
        ) : (
          <Skeleton
            variant="rect"
            width={isMobile ? "90%" : "30%"}
            height={30}
          />
        )}
      </div>
      <div className="faq__container">
        <Accordion
          expanded={expanded === "panel15"}
          onChange={handleChange("panel15")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel15" ? (
                <Remove className="text__red" />
              ) : (
                <Add className="text__red" />
              )
            }
            aria-controls="panel15bh-content"
            id="panel15bh-header"
          >
            {isLanguageLoaded ? (
              <Typography>{t("__faq_CP_question1_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionSummary>
          <AccordionDetails>
            {isLanguageLoaded ? (
              <Typography>
                {t("__faq_CP_anwswer1_Txt")} <br />{" "}
                {t("__faq_CP_anwswer1_1_Txt")} <br />{" "}
                {t("__faq_CP_anwswer1_2_Txt")}
              </Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel16"}
          onChange={handleChange("panel16")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel16" ? (
                <Remove className="text__red" />
              ) : (
                <Add className="text__red" />
              )
            }
            aria-controls="panel16bh-content"
            id="panel16bh-header"
          >
            {isLanguageLoaded ? (
              <Typography>{t("__faq_CP_question2_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionSummary>
          <AccordionDetails>
            {isLanguageLoaded ? (
              <Typography>{t("__faq_CP_anwswer2_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel17"}
          onChange={handleChange("panel17")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel17" ? (
                <Remove className="text__red" />
              ) : (
                <Add className="text__red" />
              )
            }
            aria-controls="panel17bh-content"
            id="panel17bh-header"
          >
            {isLanguageLoaded ? (
              <Typography>{t("__faq_CP_question3_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionSummary>
          <AccordionDetails>
            {isLanguageLoaded ? (
              <Typography>{t("__faq_CP_anwswer3_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel18"}
          onChange={handleChange("panel18")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel18" ? (
                <Remove className="text__red" />
              ) : (
                <Add className="text__red" />
              )
            }
            aria-controls="panel18bh-content"
            id="panel18bh-header"
          >
            {isLanguageLoaded ? (
              <Typography>{t("__faq_CP_question4_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionSummary>
          <AccordionDetails>
            {isLanguageLoaded ? (
              <Typography>{t("__faq_CP_anwswer4_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel19"}
          onChange={handleChange("panel19")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel19" ? (
                <Remove className="text__red" />
              ) : (
                <Add className="text__red" />
              )
            }
            aria-controls="panel19bh-content"
            id="panel19bh-header"
          >
            {isLanguageLoaded ? (
              <Typography>{t("__faq_CP_question5_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionSummary>
          <AccordionDetails>
            {isLanguageLoaded ? (
              <Typography>{t("__faq_CP_anwswer5_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel20"}
          onChange={handleChange("panel20")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel20" ? (
                <Remove className="text__red" />
              ) : (
                <Add className="text__red" />
              )
            }
            aria-controls="panel20bh-content"
            id="panel20bh-header"
          >
            {isLanguageLoaded ? (
              <Typography>{t("__faq_CP_question6_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionSummary>
          <AccordionDetails>
            {isLanguageLoaded ? (
              <Typography>{t("__faq_CP_anwswer6_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel21"}
          onChange={handleChange("panel21")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel21" ? (
                <Remove className="text__red" />
              ) : (
                <Add className="text__red" />
              )
            }
            aria-controls="panel21bh-content"
            id="panel21bh-header"
          >
            {isLanguageLoaded ? (
              <Typography>{t("__faq_CP_question7_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionSummary>
          <AccordionDetails>
            {isLanguageLoaded ? (
              <Typography>{t("__faq_CP_anwswer7_Txt")}</Typography>
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "30%"}
                height={30}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel22"}
          onChange={handleChange("panel22")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel22" ? (
                <Remove className="text__red" />
              ) : (
                <Add className="text__red" />
              )
            }
            aria-controls="panel21bh-content"
            id="panel22bh-header"
          >
            <Typography>{""}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{""}</Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default FAQ;
