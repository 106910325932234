import {
  RESPONSE_CLIENT_LOGIN,
  RESPONSE_REGISTER_NEW_CLIENT,
  RESPONSE_WEBPAGE_CONTACT_REGISTER,
  RESPONSE_CLIENT_ADMINISTRATION_MENU,
  RESPONSE_CLIENT_INFORMATION,
  RESPONSE_UPDATE_CLIENT_INFORMATION,
  RESPONSE_EMAIL_FROM_RETARGETING_PROGRAM,
  RESPONSE_PASSWORD_RECOVERY,
  RESPONSE_VALIDATE_PASSWORD_RECOVERY_CODE,
  RESPONSE_RESET_USER_PASSWORD,
  RESPONSE_GET_CLIENT_CONFIRMED_ORDERS,
  RESPONSE_VALIDATE_INITIAL_BANNER,
  RESPONSE_VALIDATE_IDTYPE,
  RESPONSE_UPDATE_DATA_FROM_CHECKOUT,
  RESPONSE_INSERT_FISCAL_ADDRESS,
  RESPONSE_VALIDATE_OWNER_EMAIL,
  RESPONSE_VALIDATE_OWNER_PHONE,
  RESPONSE_GET_DATA_SPONSOR_BY_ID,
  RESPONSE_GET_DATA_SPONSOR_BY_NAME,
  RESPONSE_GET_DATA_SPONSOR_BY_CITY,
  RESPONSE_ASSIGN_SPONSOR,
  RESPONSE_UPDATE_SHIPPING_ADDRESS,
  RESPONSE_CLIENT_UPLOAD_IMAGE_PROFILE,
  RESPONSE_CLIENT_REGISTER_QUESTION_SUPPORT,
  RESPONSE_GET_DATA_SPONSOR_AUTOMATIC,
  RESPONSE_CLIENT_IMMEDIATE_GAINS,
  RESPONSE_CLIENT_GUEST_INFORMATION_EMAIL,
  RESPONSE_NEWSLETTER_SUSCRIBE_EVALUATION,
  RESPONSE_USER_DATA_VALIDATION,
  RESPONSE_REGISTRATION_LOGIN_DATA,
  RESPONSE_NEW_REGISTRATION_CHECKOUT_DATA,
  RESPONSE_UPDATE_ADDRESS_CHECKOUT_DATA,
} from "../../Constants";

export default function client(state = {}, action) {
  switch (action.type) {
    case RESPONSE_CLIENT_LOGIN: {
      const responseClientLogin = action.payload.response;
      if (responseClientLogin.response.code === 100) {
        const clientLogin = responseClientLogin.userData;
        // localStorage.setItem("ZUsaUGuestId", clientLogin.idGuestUser);
        // localStorage.setItem("ZUsaUInfo", JSON.stringify(clientLogin));
        // localStorage.setItem("ZUsaIdPage", clientLogin.idPage);
        return { ...state, errorClientLogin: false, clientLogin };
      } else if (responseClientLogin.response.code === -200) {
        console.error(
          "error al logear al cliente",
          responseClientLogin.response
        );
        return { ...state, errorMultinivelLogin: true };
      } else {
        console.error(
          "error al logear al cliente",
          responseClientLogin.response
        );
        return { ...state, errorClientLogin: true };
      }
    }
    case RESPONSE_REGISTER_NEW_CLIENT: {
      const responseNewClientRegistration = action.payload.response;
      if (responseNewClientRegistration.response.code === 100) {
        const clientLogin = responseNewClientRegistration.userData;
        // localStorage.setItem("ZUsaUGuestId", clientLogin.idGuestUser);
        // localStorage.setItem("ZUsaUInfo", JSON.stringify(clientLogin));
        // localStorage.setItem("ZUsaIdPage", clientLogin.idPage);

        return { ...state, errorClientRegistration: false, clientLogin };
      } else {
        console.error(
          "error al registrar un nuevo cliente",
          responseNewClientRegistration.response
        );
        return { ...state, errorClientRegistration: true };
      }
    }
    case RESPONSE_CLIENT_INFORMATION: {
      const responseClientInformation = action.payload.response;
      const membershipInformation = action.payload?.membershipInformation;
      if (responseClientInformation.code === 100) {
        const clientLogedinInformation = responseClientInformation.clientInfo;
        const membershipInfo = membershipInformation.membershipInfo;
        return {
          ...state,
          errorLoadClientLogedInformation: false,
          clientLogedinInformation,
          membershipInfo,
        };
      } else {
        console.error(
          "error al traer la info del cliente logeado de la bd",
          responseClientInformation
        );
        return { ...state, errorLoadClientLogedInformation: true };
      }
    }
    case RESPONSE_UPDATE_CLIENT_INFORMATION: {
      const responseUpdateClientInformation = action.payload.response;
      if (responseUpdateClientInformation.code === 100) {
        return { ...state, errorClientUpdateInformation: false };
      } else {
        console.error(
          "error al actualizar la info del cliente logeado en la bd",
          responseUpdateClientInformation
        );
        return { ...state, errorClientUpdateInformation: true };
      }
    }
    case RESPONSE_WEBPAGE_CONTACT_REGISTER: {
      const responseContactWebPageRegister = action.payload.response;
      if (responseContactWebPageRegister.code === 100) {
        return { ...state, errorWebPageContactRegister: false };
      } else {
        console.error(
          "error al registrar un nuevo contacto WebPage",
          responseContactWebPageRegister
        );
        return { ...state, errorWebPageContactRegister: true };
      }
    }
    case RESPONSE_CLIENT_ADMINISTRATION_MENU: {
      const responseClientAdministrationMenu = action.payload.response;
      if (responseClientAdministrationMenu.code === 100) {
        const clientAdministrationMenu =
          responseClientAdministrationMenu.adminDataList;
        return {
          ...state,
          errorGetClientAdministrationMenu: false,
          clientAdministrationMenu,
        };
      } else {
        console.error(
          "Error al obtener el menú administrativo de la cuenta del cliente",
          responseClientAdministrationMenu
        );
        return { ...state, errorGetClientAdministrationMenu: true };
      }
    }
    case RESPONSE_EMAIL_FROM_RETARGETING_PROGRAM: {
      const responseSendEmail = action.payload.response;
      if (responseSendEmail.code === 100) {
        return {
          ...state,
          errorSendEmailForRetargetingProgram: false,
          responseSendEmail,
        };
      } else {
        console.error(
          "Error al setear el email del cliente invitado en la bd para el programa de retargeting",
          responseSendEmail
        );
        return {
          ...state,
          errorSendEmailForRetargetingProgram: true,
          responseSendEmail,
        };
      }
    }
    case RESPONSE_PASSWORD_RECOVERY: {
      const responsePasswordRecovery = action.payload.response;
      if (
        responsePasswordRecovery.code === 100 ||
        responsePasswordRecovery.code === -100 ||
        responsePasswordRecovery.code === -200
      ) {
        return { ...state, responsePasswordRecovery: responsePasswordRecovery };
      } else {
        console.error(
          "Error al solicitar una ecuperacion de contrase;a. Contactar al CallCenter",
          responsePasswordRecovery
        );
        return { ...state, errorRequestPasswordRecovery: true };
      }
    }
    case RESPONSE_VALIDATE_PASSWORD_RECOVERY_CODE: {
      const userRecoveryCodeValidation = action.payload.response;
      if (
        userRecoveryCodeValidation.code === 100 ||
        userRecoveryCodeValidation.code === -100
      ) {
        return {
          ...state,
          userRecoveryCodeValidation: userRecoveryCodeValidation,
        };
      } else {
        console.error(
          "Error al solicitar una ecuperacion de contrase;a",
          userRecoveryCodeValidation
        );
        return { ...state, errorUserRecoveryCodeValidation: true };
      }
    }
    case RESPONSE_RESET_USER_PASSWORD: {
      const resetUserPassword = action.payload.response;
      if (resetUserPassword.code === 100 || resetUserPassword.code === -100) {
        return { ...state, resetUserPassword: resetUserPassword };
      } else {
        console.error(
          "Error al solicitar una ecuperacion de contrase;a",
          resetUserPassword
        );
        return { ...state, errorResetUserPassword: true };
      }
    }
    case RESPONSE_GET_CLIENT_CONFIRMED_ORDERS: {
      const clientConfirmedOrders = action.payload.response;
      if (
        clientConfirmedOrders.code === 100 ||
        clientConfirmedOrders.code === -100
      ) {
        return { ...state, clientConfirmedOrders: clientConfirmedOrders };
      } else {
        console.error(
          "Error al solicitar los la validacion del banner",
          clientConfirmedOrders
        );
        return { ...state, errorClientConfirmedOrders: true };
      }
    }

    case RESPONSE_VALIDATE_INITIAL_BANNER: {
      const validateInitialBannerFlag = action.payload.response;
      if (
        validateInitialBannerFlag.code === 100 ||
        validateInitialBannerFlag.code === -100
      ) {
        return {
          ...state,
          validateInitialBannerFlag: validateInitialBannerFlag,
        };
      } else {
        console.error(
          "Error al solicitar los la validacion del banner",
          validateInitialBannerFlag
        );
        return { ...state, errorValidateInitialBannerFlag: true };
      }
    }
    case RESPONSE_VALIDATE_IDTYPE: {
      const validateIdentificationType = action.payload.response;
      if (
        validateIdentificationType.code === 100 ||
        validateIdentificationType.code === -100
      ) {
        return {
          ...state,
          validateIdentificationType: validateIdentificationType,
        };
      } else {
        console.error(
          "Error al solicitar los la validacion del banner",
          validateIdentificationType
        );
        return { ...state, errorValidateIdentificationType: true };
      }
    }
    case RESPONSE_UPDATE_DATA_FROM_CHECKOUT: {
      const updateDataFromCheckoutResponse = action.payload.response;
      if (updateDataFromCheckoutResponse.response.code === 100) {
        //const clientLogin = updateDataFromCheckoutResponse.userData;
        // localStorage.setItem("ZUsaUGuestId", clientLogin.idGuestUser);
        // localStorage.setItem("ZUsaUInfo", JSON.stringify(clientLogin));
        // localStorage.setItem("ZUsaIdPage", clientLogin.idPage);
        return {
          ...state,
          updateDataFromCheckoutResponse: updateDataFromCheckoutResponse,
        };
      } else {
        console.error(
          "Error al solicitar el registro desde el checkout",
          updateDataFromCheckoutResponse
        );
        return {
          ...state,
          errorUpdateDataFromCheckoutResponse: true,
          updateDataFromCheckoutResponse,
        };
      }
    }
    case RESPONSE_INSERT_FISCAL_ADDRESS: {
      const insertFiscalAddress = action.payload.response;
      if (insertFiscalAddress.code === 100) {
        return { ...state, insertFiscalAddress: insertFiscalAddress };
      } else {
        console.error(
          "Error al insertar fiscal address desde el checkout",
          insertFiscalAddress
        );
        return { ...state, errorInsertFiscalAddress: true };
      }
    }
    case RESPONSE_VALIDATE_OWNER_EMAIL: {
      const emailValidation = action.payload.response;
      if (emailValidation.code === 100) {
        return { ...state, emailValidation: emailValidation };
      } else {
        console.error(
          "Error al validar el email del due;o desde el checkout",
          emailValidation
        );
        return { ...state, errorEmailValidation: true };
      }
    }
    case RESPONSE_VALIDATE_OWNER_PHONE: {
      const phoneValidation = action.payload.response;
      if (phoneValidation.code === 100) {
        return { ...state, phoneValidation: phoneValidation };
      } else {
        console.error(
          "Error al validar el email del due;o desde el checkout",
          phoneValidation
        );
        return { ...state, errorTwilioCellValidation: true };
      }
    }
    case RESPONSE_GET_DATA_SPONSOR_BY_ID: {
      const sponsorsById = action.payload.response;
      if (sponsorsById.code === 100) {
        return { ...state, assignedSponsors: sponsorsById };
      } else {
        console.error(
          "Error al validar al obtener la lista de patrocinadores por idcliente",
          sponsorsById
        );
        return { ...state, errorAssignedSponsors: true };
      }
    }
    case RESPONSE_GET_DATA_SPONSOR_BY_NAME: {
      const sponsorsByName = action.payload.response;
      if (sponsorsByName.code === 100) {
        return { ...state, assignedSponsors: sponsorsByName };
      } else {
        console.error(
          "Error al validar al obtener la lista de patrocinadores por nombre",
          sponsorsByName
        );
        return { ...state, errorAssignedSponsors: true };
      }
    }
    case RESPONSE_GET_DATA_SPONSOR_BY_CITY: {
      const sponsorsByCity = action.payload.response;
      if (sponsorsByCity.code === 100) {
        return { ...state, assignedSponsors: sponsorsByCity };
      } else {
        console.error(
          "Error al validar al obtener la lista de patrocinadores por ciudad",
          sponsorsByCity
        );
        return { ...state, errorAssignedSponsors: true };
      }
    }
    case RESPONSE_ASSIGN_SPONSOR: {
      const assignResp = action.payload.response;

      return { ...state, assignResp: assignResp };

      // if(assignResp.code === 100){
      //     return { ...state, assignResp: assignResp}
      // }else{
      //     console.error("Error al validar al obtener la lista de patrocinadores por ciudad", assignResp)
      //     return { ...state, errorAssignResp: true }
      // }
    }
    case RESPONSE_UPDATE_SHIPPING_ADDRESS: {
      const updateShippingAddress = action.payload.response;
      //debugger;
      if (updateShippingAddress.code === 100) {
        return { ...state, updateShippingAddress: updateShippingAddress };
      } else {
        console.error(
          "Error al insertar fiscal address desde el checkout",
          updateShippingAddress
        );
        return { ...state, errorUpdateShippingAddress: true };
      }
    }
    case RESPONSE_CLIENT_UPLOAD_IMAGE_PROFILE: {
      const uploadProfileImageResponse = action.payload.response;
      return { ...state, uploadProfileImageResponse };
    }
    case RESPONSE_CLIENT_REGISTER_QUESTION_SUPPORT: {
      const registerSupportQuestionResponse = action.payload.response;
      return { ...state, registerSupportQuestionResponse };
    }
    case RESPONSE_CLIENT_IMMEDIATE_GAINS: {
      const requestImmediateGainsResponse = action.payload.response;
      return { ...state, requestImmediateGainsResponse };
    }
    case RESPONSE_GET_DATA_SPONSOR_AUTOMATIC: {
      const sponsorsAutomatic = action.payload.response;
      if (sponsorsAutomatic.code === 100) {
        return { ...state, assignedSponsorsAutomatic: sponsorsAutomatic };
      } else {
        console.error(
          "Error al validar al obtener la lista de patrocinadores por ciudad",
          sponsorsAutomatic
        );
        return { ...state, errorAssignedSponsorsAutomatic: true };
      }
    }

    case RESPONSE_CLIENT_GUEST_INFORMATION_EMAIL: {
      const clientGuestInfoResponse = action.payload.response;
      if (clientGuestInfoResponse.code === 100) {
        return {
          ...state,
          errorEmailClientGuest: false,
          clientGuestInfo: clientGuestInfoResponse.clientGuestInfo,
        };
      } else if (clientGuestInfoResponse.code === -110) {
        return {
          ...state,
          errorEmailClientGuest: true,
          clientGuestInfo: clientGuestInfoResponse,
        };
      } else {
        return { ...state, errorEmailClientGuest: true, clientGuestInfo: {} };
      }
    }
    case RESPONSE_NEWSLETTER_SUSCRIBE_EVALUATION: {
      const response = action.payload.response;
      return { ...state, newsLetterEvaluationResponse: response };
    }

    case RESPONSE_USER_DATA_VALIDATION: {
      const response = action.payload.response;
      return { ...state, userDataValidationResponse: response };
    }

    case RESPONSE_REGISTRATION_LOGIN_DATA: {
      const responseRegistrationLoginData = action.payload.response;
      const { response } = responseRegistrationLoginData;
      console.log(
        "Response de Registrarion Login Data desde el reducer",
        response
      );
      if (response.code === 100) {
        return {
          ...state,
          newRegistrationLoginData: responseRegistrationLoginData,
        };
      } else {
        console.error(
          "Error al solicitar el registro desde el login",
          responseRegistrationLoginData
        );
        return {
          ...state,
          errorRegistrationLoginDataResponse: true,
          newRegistrationLoginData: responseRegistrationLoginData,
        };
      }
    }

    case RESPONSE_NEW_REGISTRATION_CHECKOUT_DATA: {
      const newRegistrationCheckoutData = action.payload.response;
      return { ...state, newRegistrationCheckoutData };
    }

    case RESPONSE_UPDATE_ADDRESS_CHECKOUT_DATA: {
      const updateAddressCheckoutData = action.payload.response;
      return { ...state, updateAddressCheckoutData };
    }

    default:
      return { ...state };
  }
}
