import { call, put, takeLatest } from "redux-saga/effects";
import {
  REQUEST_NEWMEMBER_REGISTER_NEW_PROSPECT,
  RESPONSE_NEWMEMBER_REGISTER_NEW_PROSPECT,
  REQUEST_NEWMEMBER_GET_REGISTERED_NEW_PROSPECT,
  RESPONSE_NEWMEMBER__GET_REGISTERED_NEW_PROSPECT,
  DATA_FETCH_FAILED,
} from "./../../Constants";
import NewMember from "../../../../Libraries/NewMembers";

function* requestRegisterProspect(data) {
  try {
    const response = yield call(NewMember.RegisterProspect,data.payload.model);
    yield put({
      type: RESPONSE_NEWMEMBER_REGISTER_NEW_PROSPECT,
      payload: { response: response.requestRegisterNewClient },
    });
  } catch (error) {
    console.error("Error on register New Client from New Members Form", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}
function* requestGetRegisteredProspect(data) {
    try {
        const response = yield call(NewMember.GetRegisteredProspect, data.payload.clientId)
        yield put({
            type: RESPONSE_NEWMEMBER__GET_REGISTERED_NEW_PROSPECT,
            payload: { response: response.getNewClientInformation},
        })
    } catch (error) {
        console.error("Error on getting data of new registered prospect", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
    }
}

export function* watchRequestRegisterProspect() {
  yield takeLatest(REQUEST_NEWMEMBER_REGISTER_NEW_PROSPECT, requestRegisterProspect);
}
export function* watchRequestGetRegisteredProspect(){
    yield takeLatest(REQUEST_NEWMEMBER_GET_REGISTERED_NEW_PROSPECT, requestGetRegisteredProspect);
}
