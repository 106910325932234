import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { Tooltip } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { formatPrice } from "./../../../../Libraries/Utils/utils";
import { useTranslation } from "react-i18next";
import { requestLoggUserActivity } from "./../../../ReduxSaga/Actions/General";
import BlockProduct from "../../base/components/BlockProduct";

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#D39F46",
    color: "#fff",
    maxWidth: 350,
    fontSize: "12px",
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const ItemProduct = (props) => {
  /// TRANSLATION
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Fragment>
      <div className="products__list__container__item">
        <div className="products__list__container__item__icon">
          {/* {!isOnlyCF && (
            <Fragment>
              {isOnlyCP ? (
                <StyledTooltip
                  title="Exclusivo para cliente preferido"
                  placement="right"
                >
                  <div className="products__list__container__item__medal__customer" />
                </StyledTooltip>
              ) : (
                <StyledTooltip title="Exclusivo para emprendedor" placement="right">
                  <div className="products__list__container__item__medal__enterprising" />
                </StyledTooltip>
              )}
            </Fragment>
          )} */}
          {/* {parseInt(props.item.typePlan) < 2 && (
            <StyledTooltip
              title={t("__item_product_exclisive_Enterpreneurs")}
              placement="right"
            >
              <div className="products__list__container__item__medal__enterprising" />
            </StyledTooltip>
          )} */}
          {parseInt(props.item.typePlan) > 1 &&
            parseInt(props.item.typePlan) !== 5 && (
              <StyledTooltip
                title={t("__item_product_exclisive_cp")}
                placement="right"
              >
                <div className="products__list__container__item__medal__customer" />
              </StyledTooltip>
            )}
        </div>
        <Link
          to={
            localStorage.getItem("ZUsaUrlPage")
              ? `/product-detail/${localStorage.getItem("ZUsaUrlPage")}/${
                  props.item.itemCode
                }/${props.item.typeLine}`
              : `/product-detail/${props.item.itemCode}/${props.item.typeLine}`
          }
          onClick={() => {
            dispatch(
              requestLoggUserActivity(
                14,
                props?.item?.subCat3 > 0
                  ? props?.item?.subCat3
                  : props?.item?.subCat2 > 0
                  ? props?.item?.subCat2
                  : props?.item?.subCat1 > 0
                  ? props?.item?.subCat1
                  : props?.item?.idCatProductsCategoriesType,
                props?.item?.idCatProductsCategoriesType,
                props.item.itemCode,
                "Click en el Articulo: " + props.item.nameArticle
              )
            );
            // props?.handleGAEvent(
            //   "Navigation",
            //   "Usuario Navega Al Detalle Del Articulo " + props.item.itemCode
            // );
          }}
        >
          <div className="products__list__container__item__image">
            {/* {console.log("stock", props.item.stock)} */}
            {!props.item.available && <BlockProduct />}
            <img
              title={`${
                props.item.nameArticle
              } - ${props.item.descArticle.substring(0, 38)}...`}
              alt={`${
                props.item.nameArticle
              } - ${props.item.descArticle.substring(0, 38)}...`}
              src={props.item.image}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src =
                  "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
              }}
            />
          </div>
          <div className="products__list__container__item__descripcion">
            <div className="products__list__container__item__descripcion__name">
              {props.item.nameArticle}
            </div>

            <div className="products__list__container__item__descripcion__price">
              {props.item.typeLine === "PD" ? (
                // ||
                // props.item.typeLine === "P" ||
                // props.item.typeLine === "FV" ||
                // props.item.typeLine === "VV" ||
                // props.item.typeLine === "VF"
                <Fragment>
                  <em className="products__list__container__item__descripcion__price__promo">
                    {formatPrice(props.item.regularPrice)}
                  </em>
                  <em>{formatPrice(props.item.promotionPrice)}</em>
                </Fragment>
              ) : (
                <Fragment>
                  <em className="products__list__container__item__descripcion__price__normal">
                    {formatPrice(props.item.regularPrice)}
                  </em>
                </Fragment>
              )}
            </div>
          </div>
        </Link>
      </div>
    </Fragment>
  );
};

export default ItemProduct;
