import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { isMobile, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import TextFieldAndButtonComponent from "../../base/components/TextFieldAndButtonComponent";
///UTILS
import {
  isStrongPassword,
  restrictToOnlyLettersAndNumbers,
} from "./../../../../Libraries/Utils/regex";
///ACTIONS
import { requestLoggUserActivity } from "./../../../ReduxSaga/Actions/General";
import {
  requestCleanRecoveryPasswordStates,
  requestResetUserPassword,
} from "./../../../ReduxSaga/Actions/Client";
const NewResetPassword = ({ handleGAEvent }) => {
  //Notistack
  const { enqueueSnackbar } = useSnackbar();
  //// history
  const history = useHistory();
  ///REDUX
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.general = useSelector((state) => state.general);
  appReducers.client = useSelector((state) => state.client);
  /// traduccion
  const { t, i18n } = useTranslation();
  ///HOOKS
  const [clientId, setClientId] = useState(0);
  const [passwordFirst, setPasswordFirst] = useState("");
  const [typeInputPassword, setTypeInputPassword] = useState("password");
  const [passwordSecond, setPasswordSecond] = useState("");
  const [typeInputConfirmPassword, setTypeInputConfirmPassword] =
    useState("password");

  ///EFFECTS

  useEffect(() => {
    if (
      typeof appReducers?.client?.userRecoveryCodeValidation !== "undefined"
    ) {
      const recoveryCodeResponse =
        appReducers.client.userRecoveryCodeValidation;
      setClientId(recoveryCodeResponse.idClient);
    } else {
      history.replace("/login");
    }
  }, [appReducers?.client?.userRecoveryCodeValidation]);

  ///EFFECT ON REQUEST UPDATED PASSWROD
  useEffect(() => {
    //debugger;
    if (appReducers?.client?.resetUserPassword) {
      const resetUserPassword = appReducers.client.resetUserPassword;
      if (resetUserPassword.code === 100) {
        dispatch(requestCleanRecoveryPasswordStates());
        dispatch(
          requestLoggUserActivity(33, 0, 0, "", "Cambio de Passowrd Correcto")
        );
        handleGAEvent("User", "Reseteo de password correcto");
        enqueueSnackbar(t("__reset_password_success_message_reset"), {
          variant: "success",
        });
        history.push("/login");
      } else {
        enqueueSnackbar(t("__message_error_try_recovery_pass_label"), {
          variant: "error",
        });
      }
    }
  }, [appReducers?.client?.resetUserPassword]);

  return (
    <div
      id="firstWrapperInBaseContainer"
      className={`firstWrapperInBaseContainer new__recovery__password ${
        isTablet ? "tablet" : isMobile ? "mobile" : ""
      }`}
    >
      <div
        className={`new__recovery__password__content ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }`}
      >
        <div
          className={`new__recovery__password__content__header ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          {t("__reset_password_title")}
        </div>
      </div>
      <div
        className={`new__recovery__password__content__inner ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }`}
      >
        <div className="form__guest">
          <div className="form__guest__header">
            <div>
              <div className="userRedIcon" />
            </div>
            <div>
              <div className="form__guest__header__title">
                {t("__reset_password_content_title")}
              </div>
              <div className="form__guest__header__subtitle">
                {t("__reset_password_content_subtitle")}
              </div>
            </div>
          </div>
          <div className="form__guest__content">
            <div>
              <TextFieldAndButtonComponent
                labelName={t("__reset_password_password")}
                placeholderInput="********"
                erroHelp={
                  passwordFirst.length >= 0 &&
                  !isStrongPassword(passwordFirst) &&
                  t("__ZUsa_NavBar_OptionCart_Password_Error")
                }
                isActiveHelp={
                  passwordFirst.length >= 0 && !isStrongPassword(passwordFirst)
                }
                valueInput={passwordFirst}
                typeInput={typeInputPassword}
                setValue={(e) => {
                  setPasswordFirst(restrictToOnlyLettersAndNumbers(e));
                }}
                iconClass="eyeRedIcon"
                handleEventButton={() => {
                  if (typeInputPassword === "password")
                    setTypeInputPassword("text");
                  else setTypeInputPassword("password");
                }}
                disabledButton={false}
              />
            </div>
            <div>
              <TextFieldAndButtonComponent
                labelName={t("__reset_password_confirm_password")}
                placeholderInput=""
                erroHelp={
                  passwordSecond.length >= 0 &&
                  !isStrongPassword(passwordSecond)
                    ? t("__ZUsa_NavBar_OptionCart_Password_Error")
                    : passwordFirst !== passwordSecond
                    ? t(
                        "__forms_field_passwordConfirmed_NotEqual_validation_js_error_message"
                      )
                    : ""
                }
                isActiveHelp={
                  (passwordSecond.length >= 0 &&
                    !isStrongPassword(passwordSecond)) ||
                  passwordFirst !== passwordSecond
                }
                valueInput={passwordSecond}
                typeInput={typeInputConfirmPassword}
                setValue={(e) => {
                  setPasswordSecond(restrictToOnlyLettersAndNumbers(e));
                }}
                iconClass="eyeRedIcon"
                handleEventButton={() => {
                  if (typeInputConfirmPassword === "password")
                    setTypeInputConfirmPassword("text");
                  else setTypeInputConfirmPassword("password");
                }}
                disabledButton={false}
              />
            </div>
          </div>
          <div className="form__guest__actions">
            <div>
              <Button
                disabled={passwordFirst !== passwordSecond}
                onClick={() => {
                  if (clientId > 0 && passwordFirst.length >= 8)
                    dispatch(requestResetUserPassword(clientId, passwordFirst));
                }}
                className="button__with__icon bg__dark__pink no__left__margin"
              >
                <div className="button__with__icon__svg__wrapper dark__pink__ball">
                  <div className="userOutlineWhiteIcon" />
                </div>
                <span>{t("__reset_password_btn_reset")}</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewResetPassword;
