import {
  CLEAN_REDUCER_EMAIL_VALIDATION,
  REQUEST_EMAIL_VALIDATION,
} from "./../../Constants";

export const requestEmailValidation = (email, dbCheck) => {
  return {
    type: REQUEST_EMAIL_VALIDATION,
    payload: {
      email,
      dbCheck,
    },
  };
};
export const cleanEmailReducerValidation = () => {
  return {
    type: CLEAN_REDUCER_EMAIL_VALIDATION,
    payload: {},
  };
};
