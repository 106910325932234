import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
//Sagas
import { store } from "./React/ReduxSaga";
//Components
import Routes from "./Routes";
import ErrorBoundry from "./React/Modules/error/ErrorBoundry";

// const portalContainer = document.getElementById("portalInterceptor");

Sentry.init({
  dsn: "https://06df286f2d30422195664a31120dc920@o218986.ingest.sentry.io/6495558",
  integrations: [new BrowserTracing()],
  release:
    process.env.REACT_APP_STAGE === "dev"
      ? "zusad@1.0"
      : process.env.REACT_APP_STAGE === "qa"
      ? "zusaqa@1.0"
      : "zusap@1.0",

  //release: "zusad@1.1", //desarrollo
  //release: "zusaqa@1.1", //control de calidad

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundry>
    <Provider store={store}>
      <React.StrictMode>
        {/* {ReactDOM.createPortal(<Loader />, portalContainer)} */}
        <Routes />
      </React.StrictMode>
    </Provider>
  </ErrorBoundry>
);

// ReactDOM.render(
//   <ErrorBoundry>
//     <Provider store={store}>
//       <React.StrictMode>
//         {/* {ReactDOM.createPortal(<Loader />, portalContainer)} */}
//         <Routes />
//       </React.StrictMode>
//     </Provider>
//   </ErrorBoundry>,
//   document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
