import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  deviceDetect,
  isMobile,
  isTablet,
  isDesktop,
  deviceType,
  isMobileOnly,
} from "react-device-detect";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import { isIOS } from "react-device-detect";
import ReactGA from "react-ga";
import moment from "moment";
import { formatDistanceWithOptions } from "date-fns/fp";
import { CONSOLE_LEVELS } from "@sentry/utils";
import { useSnackbar } from "notistack";
import VCard from "vcard-creator";
//Utils
import { handleScrollToTop } from "../../../../Libraries/Utils/utils";
//Components
import Navbar from "./../../navigation/components/Navbar";
import Footer from "./../../navigation/components/Footer";
import ScrollComponent from "../components/ScrollComponent";
import FloatingCheckout from "../../navigation/components/FloatingCheckout";
import FloatingFavorites from "../../navigation/components/FloatingFavorites";
import Loading from "../components/Loading";

import { isEqual } from "lodash";
//Translation
import GeneralService from "./../../../../Libraries/General";
//Actions
import { requestFooterModuleInfo } from "./../../../ReduxSaga/Actions/Footer";
import { requestNavbarModuleInfo } from "./../../../ReduxSaga/Actions/NavBar";
import {
  requestFirstChargeSite,
  requestLoggUserActivity,
} from "./../../../ReduxSaga/Actions/General";
import {
  requestGetCartOrder,
  cleanCartOrderOperations,
  requestCartGetGiftList,
  cleanCartGetGiftList,
  requestGetCartCouponList,
} from "./../../../ReduxSaga/Actions/Cart";
import {
  requestAllProducts,
  requestTrPrEx,
  requestAllProductsByLang,
  requestAllProductsAsync,
  requestAllCaregoriesAsync,
  requestAllProductFamiliesAsync,
  requestFavorites,
  requestGetAdmissionKits,
  cleanSetFavoritesReducer,
} from "./../../../ReduxSaga/Actions/Products";
import {
  requestHomeModuleInfo,
  requestEntrepenourHomeModuleInfo,
} from "./../../../ReduxSaga/Actions/Home";
import {
  requestClientAdministrationMenu,
  requestClientInformation,
  requestClientLogOut,
} from "./../../../ReduxSaga/Actions/Client";
import { requestInstagramPosts } from "./../../../ReduxSaga/Actions/Facebook";
import { Language } from "@mui/icons-material";

ReactGA.initialize("UA-104837043-2");

const exclusiveRoutesForCartOrderListening = [
  "/personalized-fragance",
  "/product-detail",
  "/kit-config",
];

const BaseContainer = (props) => {
  //INITIALS STATES
  const initialGuestInfo = {
    name: "",
    lastName: "",
    email: "",
    stateS: "",
    cityS: "",
    streetS: "",
    zipS: "",
    suiteS: "",
    stateF: "",
    cityF: "",
    streetF: "",
    zipF: "",
    suiteF: "",
    phone: "",
    idClient: 0,
    isExists: false,
  };

  const maxDate = moment().subtract(18, "years").add(1, "days");
  const initialClientInfo = {
    orderId: 1,
    userId: 0,
    clientId: 0,
    sponsor: 0,
    name: "",
    lastName: "",
    email: "",
    cellPhone: "",
    password: "",
    benefitType: 5,
    birthdate: maxDate.format("YYYY-MM-DD"),
    gender: "",
    lng: "en",
    //Address
    shippStreet: "",
    shippSuite: "",
    shippCity: "",
    shippState: "",
    shippZip: "",
    fiscalStreet: "",
    fiscalSuite: "",
    fiscalCity: "",
    fiscalState: "",
    fiscalZip: "",
    identificationType: "",
    identification: "",
    withFiscalAddress: false,
    withShippmentAddress: false,
    withNoInfo: undefined,
    allReadyRegister: false,
  };
  //ERROR DEMO
  const error = () => {
    throw new Error("ya bailó");
  };
  //Notistack
  const { enqueueSnackbar } = useSnackbar();

  //Tranlation
  const { t, i18n } = useTranslation();

  ///ROUTER
  const history = useHistory();
  const location = useLocation();

  //React Redux
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.navBar = useSelector((state) => state.navBar);
  appReducers.footer = useSelector((state) => state.footer);
  appReducers.general = useSelector((state) => state.general);
  appReducers.products = useSelector((state) => state.products);
  appReducers.cart = useSelector((state) => state.cart);
  appReducers.client = useSelector((state) => state.client);
  appReducers.home = useSelector((state) => state.home);

  //State
  const [menuApp, setMenuApp] = useState([]);
  const [footerDataApp, setFooterDataApp] = useState([]);
  const [infoCartOrder, setInfoCartOrder] = useState({});
  const [isOpenFloatingCheckout, setIsOpenFloatingCheckout] = useState(false);
  const [isOpenFloatingFavorites, setIsOpenFloatingFavorites] = useState(false);
  const [footerIsLoadingEnable, setFooterIsLoadingEnable] = useState(true);
  const [clientFavorites, setClientFavorites] = useState([]);
  const [admissionKits, setAdmissionKits] = useState([]);
  const [cpMemberships, setCpMemberships] = useState([]);
  const [isWebpage, setIsWebpage] = useState(false);
  const [benefitType, setBenefitType] = useState(5);
  const [guestInfoToCloseOrder, setGuestInfoToCloseOrder] =
    useState(initialGuestInfo);
  const [clientInfoToCloseOrder, setClientInfoToCloseOrder] =
    useState(initialClientInfo);

  const [isOpenGiftSelectModal, setIsOpenGiftSelectModal] = useState(false);
  const [countGiftSelect, setCountGiftSelect] = useState(0);

  const [itemGiftResponseReducer, setItemGiftResponseReducer] = useState({});

  const [floatingCheckoutIconIsClicked, setFloatingCheckoutIconIsClicked] =
    useState(false);
  const [isOpenEntrepreneurContactForm, setIsOpenEntrepreneurContactForm] =
    useState(false);
  const [isActiveSearch, setIsActiveSearch] = useState(false);
  //
  const [typePlan, setTypePlan] = useState("regular");

  const [isLanguageLoaded, setIsLanguageLoaded] = useState(false);
  const [vCardWebPageEntreprenourData, setVCardWebPageEntreprenourData] =
    useState("");
  const [vCardSponsorSelection, setVCardSponsorSelection] = useState("");
  const [sponsorSelectionData, setSponsorSelectionData] = useState({});
  const [isProfileImageLoaded, setIsProfileImageLoaded] = useState(false);
  const [imageProfileUrl, setImageProfileUrl] = useState("");

  //Effects
  //didMount

  useEffect(() => {
    /////////////////////////////////////////////////////////////

    //console.log("isMobile: " + isMobile);
    //alert("isTablet: " + isTablet);
    //console.log("isTablet: " + isTablet);
    //console.log("deviceType", deviceType);
    //alert("isMobileOnly: " + isMobileOnly)
    //GetLanguages();
    const handleUnload = () => {
      dispatch(requestLoggUserActivity(74, 0, 0, "", "Salio del sitio"));
    };

    window.addEventListener("beforeunload", handleUnload);
    dispatch(requestNavbarModuleInfo());
    dispatch(requestFooterModuleInfo());
    const deviceDetector = deviceDetect();
    let modelFirstCharge = {};
    let urlPage = "";
    if (props?.match?.params?.hasOwnProperty("webPage")) {
      urlPage = props.match.params.webPage;
    }
    if (urlPage.length <= 0 && localStorage.getItem("ZUsaUrlPage")) {
      urlPage = localStorage.getItem("ZUsaUrlPage");
    }
    modelFirstCharge = {
      urlPage,
      browser:
        deviceDetector?.browserName === undefined
          ? ""
          : deviceDetector?.browserName,
      browserVersion:
        deviceDetector?.browserFullVersion === undefined
          ? ""
          : deviceDetector?.browserFullVersion,
      iP: "",
      os: deviceDetector?.osName === undefined ? "" : deviceDetector?.osName,
      osVersion:
        deviceDetector?.osVersion === undefined
          ? ""
          : deviceDetector?.osVersion,
      device:
        deviceDetector?.osName === undefined ? "" : deviceDetector?.osName,
      isDesktop,
      isMobile,
      isTablet,
      userAgent:
        deviceDetector?.userAgent === undefined
          ? ""
          : deviceDetector?.userAgent,
      email: "",
    };
    handleGAEvent(
      "Navigation",
      urlPage.length > 0
        ? "Carga inicial del sitio bajo página web de emprendedora: " + urlPage
        : "Carga inicial del sitio"
    );
    //home
    // if (urlPage.length = 0) {
    //   dispatch(requestHomeModuleInfo());
    // }
    //primera carga
    dispatch(requestFirstChargeSite(modelFirstCharge));
    //sendPetitionFACEBOOK
    dispatch(requestInstagramPosts());
    //familia
    dispatch(requestAllProductFamiliesAsync());
    //logg
    dispatch(
      requestLoggUserActivity(
        68,
        0,
        0,
        "",
        urlPage.length > 0
          ? "Carga inicial del sitio bajo página web de emprendedora: " +
              urlPage
          : "Carga inicial del sitio"
      )
    );
  }, []);

  //NAVBAR
  useEffect(() => {
    if (appReducers?.navBar?.errorLoadNavBarModule === false) {
      setMenuApp(appReducers?.navBar?.navigationBar?.items);
    }
  }, [
    appReducers?.navBar?.errorLoadNavBarModule,
    appReducers?.navBar?.navigationBar?.items,
  ]);
  //FOOTER
  useEffect(() => {
    if (appReducers?.footer?.errorLoadFooterModule === false) {
      setFooterIsLoadingEnable(false);
      if (i18n.language === "en")
        setFooterDataApp(appReducers?.footer?.footerModuleEn);
      if (i18n.language === "es")
        setFooterDataApp(appReducers?.footer?.footerModuleEs);
    }
  }, [
    appReducers?.footer?.errorLoadFooterModule,
    i18n.language,
    appReducers?.footer?.footerModuleEs,
    appReducers?.footer?.footerModuleEn,
  ]);
  //FIRST CHARGE SITE
  useEffect(() => {
    if (
      typeof appReducers?.general?.errorLoadFirstCharge !== "undefined" &&
      appReducers.general.errorLoadFirstCharge === false &&
      typeof appReducers?.general?.firstChargeModel !== "undefined" &&
      appReducers.products.promotionsEs &&
      appReducers.products.promotionsEn &&
      appReducers.products.newsEs &&
      appReducers.products.newsEn
    ) {
      //; //revisar esto //agregar la ruta al reducer de home //entrepenourPageInfo
      //if (props?.match?.params?.hasOwnProperty("webPage") || ) {
      //}
      const { isGuestUser } = appReducers.general.firstChargeModel;

      if (isGuestUser && benefitType !== 5) {
        setBenefitType(5);
        setClientInfoToCloseOrder({
          ...clientInfoToCloseOrder,
          benefitType: 5,
        });
      }

      if (!isGuestUser) dispatch(requestClientInformation());

      if (appReducers?.general?.firstChargeModel?.idPage === 0) {
        setIsWebpage(false);

        dispatch(requestHomeModuleInfo());
      }

      if (appReducers?.general?.firstChargeModel?.idPage > 0) {
        setIsWebpage(true);

        if (props?.location?.pathname === "/home") {
          history.replace(`/mypage/${localStorage.getItem("ZUsaUrlPage")}`);
        }
        dispatch(requestEntrepenourHomeModuleInfo());
      }

      const language =
        appReducers?.general?.firstChargeModel?.idLang === 1 ? "es" : "en";
      //i18n.changeLanguage(language);
      const languageSwiper = document.getElementById("languageSwiper");
      if (languageSwiper) {
        languageSwiper.swiper.slideToLoop(language === "en" ? 0 : 1);
      }

      // dispatch(
      //   requestAllProductsByLang(appReducers?.general?.firstChargeModel?.idLang)
      // );
      dispatch(requestAllCaregoriesAsync());
      dispatch(requestAllProductsAsync());
      dispatch(requestGetCartOrder());
      dispatch(requestClientAdministrationMenu());
    }
  }, [
    appReducers?.general?.errorLoadFirstCharge,
    appReducers?.general?.firstChargeModel,
    appReducers?.products?.promotionsEs,
    appReducers?.products?.promotionsEn,
    appReducers?.products?.newsEs,
    appReducers?.products?.newsEn,
    i18n,
  ]);

  // //ALL PRODUCTS
  // useEffect(() => {
  //   if (
  //     !appReducers?.products?.errorLoadAllCategories &&
  //     appReducers?.products &&
  //     appReducers?.products?.categoriesEs &&
  //     appReducers?.products?.productsEs &&
  //     appReducers?.products?.categoriesEn &&
  //     appReducers?.products?.productsEn
  //   ) {
  //     //;
  //     if (
  //       appReducers?.products?.categoriesEs.length === 0 &&
  //       appReducers?.products?.productsEs.length === 0 &&
  //       appReducers?.products?.categoriesEn.length > 0 &&
  //       appReducers?.products?.productsEn.length > 0
  //     ) {
  //       //;
  //       dispatch(requestAllProductsByLang(1));
  //     } else if (
  //       appReducers?.products?.categoriesEs.length > 0 &&
  //       appReducers?.products?.productsEs.length > 0 &&
  //       appReducers?.products?.categoriesEn.length === 0 &&
  //       appReducers?.products?.productsEn.length === 0
  //     ) {
  //       dispatch(requestAllProductsByLang(2));
  //     }

  //     //dispatch(requestAllProducts());
  //   }
  //   // else{
  //   //   if(i18n.language === "en")
  //   //    alert("prod en ingles")
  //   //   if(i18n.language === "es")
  //   //   alert("prod en español")
  //   // }
  // }, [
  //   //i18n.language,

  //   appReducers?.products?.errorLoadAllCategories,
  //   appReducers?.products?.categoriesEs,
  //   appReducers?.products?.categoriesEn,
  //   appReducers?.products?.productsEs,
  //   appReducers?.products?.productsEn,

  //   // appReducers?.products?.subCategoriesEsL1,
  //   // appReducers?.products?.subCategoriesEsL2,
  //   // appReducers?.products?.subCategoriesEsL3,

  //   // appReducers?.products?.subCategoriesEnL1,
  //   // appReducers?.products?.subCategoriesEnL2,
  //   // appReducers?.products?.subCategoriesEnL3,
  // ]);

  //PRODUCTS LOAD
  useEffect(() => {
    if (
      !appReducers?.products?.errorLoadTrPrExProducts &&
      !appReducers?.products?.promotionsEn &&
      !appReducers?.products?.newsEn &&
      appReducers.products.familyEs &&
      appReducers.products.familyEn
    ) {
      dispatch(requestTrPrEx());
    }
  }, [
    appReducers?.products?.errorLoadTrPrExProducts,
    appReducers?.products?.promotionsEs,
    appReducers?.products?.promotionsEn,
    appReducers?.products?.newsEs,
    appReducers?.products?.newsEn,
    appReducers?.products?.familyEs,
    appReducers?.products?.familyEn,
  ]);

  //ADMISSION KITS
  useEffect(() => {
    if (appReducers?.products?.admissionKitList) {
      const kits = appReducers.products.admissionKitList;
      setAdmissionKits(
        kits.admissionKitList.filter(
          (f) =>
            f.typePlan === 1 && f.idLang == (i18n.language === "es" ? 1 : 2)
        )
      );
      setCpMemberships(
        kits.admissionKitList.filter(
          (f) =>
            f.typePlan === 2 && f.idLang == (i18n.language === "es" ? 1 : 2)
        )
      );
    } else {
      dispatch(requestGetAdmissionKits());
    }
  }, [appReducers?.products?.admissionKitList, i18n.language]);

  useEffect(() => {
    if (typeof appReducers?.cart?.errorCartOrder !== "undefined") {
      if (
        appReducers?.cart?.cartOrder &&
        !appReducers?.cart?.errorCartOrdererrorCartOrder
      ) {
        const order = appReducers.cart.cartOrder;
        setInfoCartOrder(order);

        //si escucha cambios en la orden validar los regalos
        dispatch(requestCartGetGiftList());
      }
    }
    // else {
    //   ;
    //   console.log("Hola")
    //   //dispatch(requestGetCartOrder());
    // }
  }, [appReducers?.cart?.cartOrder, appReducers?.cart?.errorCartOrder]);

  useEffect(() => {
    if (location) {
      //console.log("el path", props.match.path);
      //console.log("location desde el BaseContainer", location);
      //alert(location);
      if (!location.pathname.includes("contactUs")) {
        //alert("si entre");
        handleScrollToTop();
      }
      if (
        !location.pathname.includes("summary") &&
        appReducers.general?.firstChargeModel?.typeUser === "1"
      ) {
        //console.log("desloguear");
        dispatch(requestClientLogOut());
      }
    }
  }, [location]);

  useEffect(() => {
    if (
      typeof appReducers?.products?.errorLoadfavorites !== "undefined" &&
      typeof appReducers?.general?.errorLoadFirstCharge !== "undefined"
    ) {
      if (
        !appReducers?.products?.errorLoadfavorites &&
        appReducers?.products?.favoritesEn &&
        appReducers?.products?.favoritesEs
      ) {
        if (
          i18n.language === "en" &&
          appReducers?.products?.favoritesEn.length >= 0
        ) {
          setClientFavorites(appReducers?.products?.favoritesEn);
        }
        if (
          i18n.language === "es" &&
          appReducers?.products?.favoritesEs.length >= 0
        ) {
          setClientFavorites(appReducers?.products?.favoritesEs);
        }
      }
    } else {
      if (typeof appReducers?.general?.errorLoadFirstCharge !== "undefined") {
        if (!appReducers.general.errorLoadFirstCharge) {
          const { isGuestUser } = appReducers.general.firstChargeModel;
          if (isGuestUser === false) dispatch(requestFavorites());
        }
      }
    }
  }, [
    appReducers?.products?.errorLoadfavorites,
    appReducers?.products?.favoritesEn,
    appReducers?.products?.favoritesEs,
    appReducers?.general?.errorLoadFirstCharge,
    appReducers?.general?.firstChargeModel,
    i18n.language,
  ]);

  useEffect(() => {
    if (
      typeof appReducers?.products?.errorSetProductOnFavorites !== "undefined"
    ) {
      if (!appReducers.products.errorSetProductOnFavorites) {
        if (appReducers.products.isFavorite)
          enqueueSnackbar(
            t(
              "__product_Detail_Module_Transaction_IsOnFavorites_Success_Message"
            ),
            {
              variant: "success",
            }
          );
        else
          enqueueSnackbar(
            t(
              "__product_Detail_Module_Transaction_IsNotOnFavorites_Success_Message"
            ),
            {
              variant: "success",
            }
          );
        dispatch(requestFavorites());
      } else {
        enqueueSnackbar(
          t("__product_Detail_Module_Transaction_IsOnFavorites_Error_Message"),
          {
            variant: "error",
          }
        );
      }
      dispatch(cleanSetFavoritesReducer());
    }
  }, [appReducers?.products?.errorSetProductOnFavorites]);

  //ORDER EFFECT
  useEffect(() => {
    if (
      typeof appReducers?.cart?.cartCurrentOrder !== "undefined" &&
      !isOpenFloatingCheckout &&
      !exclusiveRoutesForCartOrderListening.find((r) =>
        location.pathname.includes(r)
      )
    ) {
      switch (appReducers?.cart?.cartCurrentOrder?.code) {
        case 100:
          const typeOp = appReducers?.cart?.cartOpType;
          switch (typeOp) {
            case "add":
              enqueueSnackbar(t("__message_success_product_add_cart_label"), {
                variant: "success",
              });
              break;
            case "addClientCoupon":
              enqueueSnackbar(
                t("__message_success_client_coupon_add_cart_label"),
                {
                  variant: "success",
                }
              );
              dispatch(requestGetCartCouponList());
              break;
            case "delClientCoupon":
              enqueueSnackbar(
                t("__message_success_client_coupon_deleted_cart_label"),
                {
                  variant: "success",
                }
              );
              dispatch(requestGetCartCouponList());
              break;
            case "modify":
              enqueueSnackbar(
                t("__message_success_product_modified_cart_label"),
                {
                  variant: "success",
                }
              );
              break;
            case "delete":
              enqueueSnackbar(
                t("__message_success_product_deleted_cart_label"),
                {
                  variant: "warning",
                }
              );
              break;
            default:
          }
          // enqueueSnackbar(t("__message_success_product_add_cart_label"), {
          //   variant: "success",
          // });
          dispatch(requestGetCartOrder());
          dispatch(cleanCartOrderOperations());
          break;
        case 110:
          enqueueSnackbar(t("__message_beauty_boxes_exeded_product_label"), {
            variant: "warning",
          });
          break;
        case -100:
          enqueueSnackbar(appReducers?.cart?.cartCurrentOrder?.message, {
            variant: "error",
          });
          break;
        default:
      }
    }
  }, [
    appReducers?.cart?.cartCurrentOrder,
    appReducers?.cart?.cartOrder,
    appReducers?.cart?.errorCartOrder,
  ]);

  useEffect(() => {
    if (
      appReducers?.home?.entrepenourPageInfo &&
      appReducers?.general?.firstChargeModel?.idPage > 0
    ) {
      setClientInfoToCloseOrder({
        ...clientInfoToCloseOrder,
        sponsor: appReducers?.home?.entrepenourPageInfo.idClient,
      });
    }
  }, [
    appReducers?.home.entrepenourPageInfo,
    appReducers?.general.firstChargeModel,
  ]);

  ////EFFECT MEJORADO USER INFO
  useEffect(() => {
    if (typeof appReducers?.general?.errorLoadFirstCharge !== "undefined") {
      const { isGuestUser } = appReducers.general.firstChargeModel;
      if (
        typeof appReducers?.client?.errorLoadClientLogedInformation !==
        "undefined"
      ) {
        if (!appReducers.general.errorLoadFirstCharge) {
          if (!appReducers.client.errorLoadClientLogedInformation) {
            //debugger
            const {
              addFiscalExists,
              name,
              lastName,
              email,
              password,
              telcell,
              typeUser,
              birthday,
              identificationType,
              identification,
              gender,
              address,
              suite,
              city,
              state,
              zip,
            } = appReducers.client.clientLogedinInformation;

            const { idUser, sponsorId, idClient } =
              appReducers.general.firstChargeModel;

            downloadProfileImageValidation(idUser);

            console.log(
              "TESTING OK, SI ENTRO!!!",
              parseInt(typeUser) < benefitType
                ? parseInt(typeUser)
                : benefitType
            );

            if (parseInt(typeUser) < benefitType) {
              setBenefitType(parseInt(typeUser));
            }

            setClientInfoToCloseOrder({
              ...clientInfoToCloseOrder,
              userId: idUser,
              clientId: idClient,
              sponsor: sponsorId,
              name,
              lastName,
              email,
              cellPhone: telcell,
              password: password,
              birthdate: birthday,
              gender,
              lng: i18n.language,
              shippStreet: address,
              shippSuite: suite,
              shippCity: city,
              shippState: state,
              shippZip: zip,
              fiscalStreet: address,
              fiscalSuite: suite,
              fiscalCity: city,
              fiscalState: state,
              fiscalZip: zip,
              withFiscalAddress: addFiscalExists,
              withShippmentAddress: address.length > 0,
              withNoInfo: false,
              allReadyRegister: true,
              benefitType:
                parseInt(typeUser) < benefitType
                  ? parseInt(typeUser)
                  : benefitType,
            });
          } else {
            //solo la orden
          }
        }
      } else {
        //
      }
    }
  }, [
    appReducers?.general.errorLoadFirstCharge,
    appReducers?.general.firstChargeModel,
    appReducers?.client.errorLoadClientLogedInformation,
    appReducers?.client.clientLogedinInformation,
    i18n?.language,
  ]);

  useEffect(() => {
    if (infoCartOrder?.hasOwnProperty("code") && infoCartOrder?.code === 100) {
      if (infoCartOrder?.order && infoCartOrder?.order !== null) {
        // const { orderId } = infoCartOrder?.order;
        if (infoCartOrder?.lines.length > 0) {
          if (
            infoCartOrder?.lines.find((l) =>
              admissionKits.find((kits) => kits.itemCode === l.line.codart)
            )
          ) {
            setBenefitType(1);
            // setClientInfoToCloseOrder({
            //   ...clientInfoToCloseOrder,
            //   benefitType: 1,
            //   orderId,
            //   //withNoInfo: clientInfoToCloseOrder.name.length === 0,
            // });
          } else if (
            infoCartOrder?.lines.find((l) =>
              cpMemberships.find((kits) => kits.itemCode === l.line.codart)
            )
          ) {
            setBenefitType(2);
            // setClientInfoToCloseOrder({
            //   ...clientInfoToCloseOrder,
            //   benefitType: 2,
            //   orderId,
            //   //withNoInfo: clientInfoToCloseOrder.name.length === 0,
            // });
          } else {
            setBenefitType(clientInfoToCloseOrder.benefitType);
            // setClientInfoToCloseOrder({
            //   ...clientInfoToCloseOrder,
            //   benefitType: 5,
            //   orderId,
            //   //withNoInfo: clientInfoToCloseOrder.name.length === 0,
            // });
          }
        } else {
          setBenefitType(clientInfoToCloseOrder.benefitType);
          // setClientInfoToCloseOrder({
          //   ...clientInfoToCloseOrder,
          //   benefitType: 5,
          //   orderId,
          //   //withNoInfo: clientInfoToCloseOrder.name.length === 0,
          // });
        }
      } else {
        setBenefitType(clientInfoToCloseOrder.benefitType);
      }
    }
  }, [
    infoCartOrder,
    admissionKits,
    cpMemberships,
    clientInfoToCloseOrder.benefitType,
  ]);

  ///EFECT SESSION USER INFO
  // useEffect(() => {
  //   if (
  //     typeof appReducers?.client?.errorLoadClientLogedInformation !==
  //       "undefined" &&
  //     typeof appReducers?.general?.errorLoadFirstCharge !== "undefined"
  //   ) {

  //     if (!appReducers.general.errorLoadFirstCharge) {
  //       if (!appReducers.client.errorLoadClientLogedInformation) {
  //         //debugger

  //         const {
  //           addFiscalExists,
  //           name,
  //           lastName,
  //           email,
  //           password,
  //           telcell,
  //           typeUser,
  //           birthday,
  //           identificationType,
  //           identification,
  //           gender,
  //           address,
  //           suite,
  //           city,
  //           state,
  //           zip,
  //         } = appReducers.client.clientLogedinInformation;
  //         const { idUser, sponsorId, idClient } =
  //           appReducers.general.firstChargeModel;
  //         setClientInfoToCloseOrder({
  //           ...clientInfoToCloseOrder,
  //           userId: idUser,
  //           clientId: idClient,
  //           sponsor: sponsorId,
  //           name,
  //           lastName,
  //           email,
  //           cellPhone: telcell,
  //           password: password,
  //           benefitType: parseInt(typeUser),
  //           birthdate: birthday,
  //           gender,
  //           lng: i18n.language,
  //           shippStreet: address,
  //           shippSuite: suite,
  //           shippCity: city,
  //           shippState: state,
  //           shippZip: zip,
  //           fiscalStreet: address,
  //           fiscalSuite: suite,
  //           fiscalCity: city,
  //           fiscalState: state,
  //           fiscalZip: zip,
  //           withFiscalAddress: addFiscalExists,
  //           withShippmentAddress: address.length > 0,
  //           withNoInfo: false,
  //           allReadyRegister: true,
  //         });
  //         setBenefitType(parseInt(typeUser));
  //       } else {
  //         setClientInfoToCloseOrder({
  //           ...clientInfoToCloseOrder,
  //           withNoInfo: true,
  //         });
  //       }
  //     }

  //   }
  // }, [
  //   appReducers?.general.errorLoadFirstCharge,
  //   appReducers?.general.firstChargeModel,
  //   appReducers?.client.errorLoadClientLogedInformation,
  //   appReducers?.client.clientLogedinInformation,
  //   i18n?.language,
  //   infoCartOrder,
  //   admissionKits,
  //   cpMemberships,
  // ]);

  // useEffect(() => {
  //   if (infoCartOrder?.hasOwnProperty("code") && infoCartOrder?.code === 100) {
  //     if (infoCartOrder?.order && infoCartOrder?.order !== null) {
  //       const { orderId } = infoCartOrder?.order;
  //       if (infoCartOrder?.lines.length > 0)
  //       {
  //         let typeUsr = 5
  //         if (appReducers?.client.clientLogedinInformation)
  //         {
  //           typeUsr = parseInt(appReducers?.client.clientLogedinInformation.typeUser)
  //         }

  //         if (
  //           infoCartOrder?.lines.find((l) =>
  //             admissionKits.find((kits) => kits.itemCode === l.line.codart)
  //           )
  //         ) {
  //           setBenefitType(typeUsr > 1 ? 1 : typeUsr);
  //           setClientInfoToCloseOrder({
  //             ...clientInfoToCloseOrder,
  //             benefitType: typeUsr > 1 ? 1 : typeUsr,
  //             orderId,
  //           });
  //         } else if (
  //           infoCartOrder?.lines.find((l) =>
  //             cpMemberships.find((kits) => kits.itemCode === l.line.codart)
  //           )
  //         ) {

  //           setBenefitType(typeUsr > 2 ? 2 : typeUsr);
  //           setClientInfoToCloseOrder({
  //             ...clientInfoToCloseOrder,
  //             benefitType: typeUsr > 2 ? 2 : typeUsr,
  //             orderId,
  //           });
  //         } else {
  //           setBenefitType(typeUsr);
  //           setClientInfoToCloseOrder({
  //             ...clientInfoToCloseOrder,
  //             benefitType: typeUsr,
  //             orderId,
  //           });
  //         }
  //       } else {
  //         if (appReducers?.client.clientLogedinInformation)
  //           setBenefitType(
  //             parseInt(appReducers?.client.clientLogedinInformation.typeUser)
  //           );
  //         else{
  //           setBenefitType(5);
  //           setClientInfoToCloseOrder({
  //             ...clientInfoToCloseOrder,
  //             benefitType: 5,
  //             orderId,
  //           });
  //         }
  //       }
  //     } else {
  //       if (appReducers?.client.clientLogedinInformation){
  //         setBenefitType(
  //           parseInt(appReducers?.client.clientLogedinInformation.typeUser)
  //         );
  //         setClientInfoToCloseOrder({
  //           ...clientInfoToCloseOrder,
  //           benefitType: parseInt(appReducers?.client.clientLogedinInformation.typeUser),
  //         });
  //       }else{
  //         setBenefitType(5);
  //         setClientInfoToCloseOrder({
  //           ...clientInfoToCloseOrder,
  //           benefitType: 5,
  //         });
  //       }
  //     }
  //   }
  // }, [
  //   infoCartOrder,
  //   admissionKits,
  //   cpMemberships,
  //   appReducers?.client.clientLogedinInformation,
  // ]);

  //Lista de regalos
  useEffect(() => {
    if (appReducers?.cart?.cartGetGiftList) {
      let giftList = appReducers?.cart?.cartGetGiftList;
      if (giftList.code === 100) {
        if (giftList.giftSelectable.length > 0) {
          let { giftSelectable } = giftList;

          let arrayOnlyKey = [];
          giftSelectable.map((item) => {
            item.selected = false;

            let key = {};

            key.idRegalo = item.idRegalo;
            key.idx = item.idx;

            arrayOnlyKey.push(key);
          });
          //eliminar duplicados
          let arrayOnlyKeyUnique = arrayOnlyKey.reduce((acc, current) => {
            const x = acc.find(
              (item) =>
                item.idx === current.idx && item.idRegalo === current.idRegalo
            );
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);
          //agruparlos en un estado por el tipo
          let arrayFinal = [];
          arrayOnlyKeyUnique.map((key) => {
            let arrayKeyEquals = [];
            arrayKeyEquals = giftSelectable.filter(
              (item) => item.idx === key.idx && item.idRegalo === key.idRegalo
            );
            arrayFinal.push(arrayKeyEquals);
          });

          setIsOpenGiftSelectModal(true);
          setCountGiftSelect(arrayFinal.length);
        } else {
          setIsOpenGiftSelectModal(false);
          setCountGiftSelect(0);
        }
        //dispatch(cleanCartGetGiftList());
        //validar un estado con el objeto que va llegando
        if (
          !isEqual(appReducers.cart.cartGetGiftList, itemGiftResponseReducer)
        ) {
          dispatch(requestGetCartOrder());
          setItemGiftResponseReducer(appReducers?.cart.cartGetGiftList);
          //cerrar el mini checout
        }
      }
    }
  }, [appReducers?.cart.cartGetGiftList]);

  //On i18n resolved language
  useEffect(() => {
    if (i18n.resolvedLanguage) {
      //Ya cargo el idioma
      // console.log("i18n.resolvedLanguage", i18n.resolvedLanguage);
      setIsLanguageLoaded(true);
    }
  }, [i18n.resolvedLanguage]);

  useEffect(() => {
    if (
      typeof appReducers?.home?.errorLoadEntrepenourHomeModule !== "undefined"
    ) {
      if (
        !appReducers?.home?.errorLoadEntrepenourHomeModule &&
        appReducers?.home?.homeEntrepenourModuleEn
      ) {
        //debugger;
        buildVCardEntreprenourWebPageOwner(
          appReducers?.home?.entrepenourPageInfo
        );
      }
    } else {
      setVCardWebPageEntreprenourData("");
    }
  }, [
    appReducers?.home.errorLoadEntrepenourHomeModule,
    appReducers?.home.homeEntrepenourModuleEs,
    appReducers?.home.homeEntrepenourModuleEn,
  ]);

  // const handleUnload = () => {
  //   dispatch(requestLoggUserActivity(74, 0, 0, "", "Salio del sitio"));
  // };

  // useEffect(() => {
  //   window.addEventListener("beforeunload", handleUnload);

  //   return () => window.removeEventListener("beforeunload", handleUnload);
  // }, [handleUnload]);

  ///HANDLERS

  // const GetLanguages = async () => {
  //   const resultLngEn = await GeneralService.GeyEnTranslation();
  //   const resultLnges = await GeneralService.GeyEsTranslation();
  //   console.log("traducción ingles", resultLngEn)
  //   console.log("traducción español", resultLnges)
  // };

  const downloadProfileImageValidation = (uId = 0) => {
    if (uId === 0) uId = appReducers.general.firstChargeModel.idUser;

    const url =
      process?.env?.REACT_APP_STAGE === "dev"
        ? `http://10.10.1.16:8082/imageProfile/image_profile${uId}.jpg?V=${Math.floor(
            Math.random() * 100
          )}`
        : `https://www.zermatusa.com/imageProfile/image_profile${uId}.jpg?V=${Math.floor(
            Math.random() * 100
          )}`;
    var download = new Image();
    download.src = url;
    download.onload = () => {
      //resolve(download);
      setImageProfileUrl(url);
      setIsProfileImageLoaded(true);
    };
    download.onerror = function (err, msg) {
      //reject("name");
      setImageProfileUrl("");
      setIsProfileImageLoaded(false);
    };
    // return new Promise((resolve, reject) => {

    // });
  };

  const buildVCardEntreprenourWebPageOwner = (entreprenourInfo) => {
    let firstname = "";
    let lastname = "";
    const nameArray = entreprenourInfo.nameClient.split(" ");
    nameArray.forEach((n, index) => {
      if (index === 0) {
        firstname = n;
      } else if (index == 1) {
        lastname = n;
      }
    });
    const additional = `EntreprenourID: ${entreprenourInfo.idClient}`;
    // const prefix = ''
    // const suffix = ''
    const myVCard = new VCard();
    myVCard
      // Add personal data
      .addName(lastname, firstname, "", "", "")
      // Add work data
      .addCompany("Zermat USA")
      .addJobtitle("Zermat Entreprenour")
      .addRole(additional)
      .addEmail(entreprenourInfo.email)
      .addPhoneNumber(entreprenourInfo.cellPhone, "PREF;WORK")
      // .addPhoneNumber(123456789, 'WORK')
      //.addAddress(null, null, 'street', 'worktown', null, 'workpostcode', 'Belgium')
      .addURL(
        `https://www.zermatusa.com/${localStorage.getItem("ZUsaUrlPage")}`
      );
    setVCardWebPageEntreprenourData(myVCard.toString());
  };

  const buildVCardSponsorSelection = (entreprenourInfo) => {
    const additional = `EntreprenourID: ${entreprenourInfo.entreprenourId}`;
    // const prefix = ''
    // const suffix = ''
    const myVCard = new VCard();
    myVCard
      // Add personal data
      .addName(entreprenourInfo.lastName, entreprenourInfo.name, "", "", "")
      // Add work data
      .addCompany("Zermat USA")
      .addJobtitle("Zermat Entreprenour")
      .addRole(additional)
      .addEmail(entreprenourInfo.email)
      .addPhoneNumber(entreprenourInfo.cellPhone, "PREF;WORK");
    // .addPhoneNumber(123456789, 'WORK')
    //.addAddress(null, null, 'street', 'worktown', null, 'workpostcode', 'Belgium')
    setVCardSponsorSelection(myVCard.toString());
    setSponsorSelectionData(entreprenourInfo);
  };

  const cleanVCardSponsorSelection = () => {
    setVCardSponsorSelection("");
    setSponsorSelectionData({});
  };

  const handleGAEvent = (category, action) => {
    //DEFAULT CATEGORIES
    //Navigation
    //User
    //CartOrder
    ReactGA.event({
      category: category,
      action: action,
      nonInteraction: false,
    });
  };

  const handleResetInfoClient = () => {
    //debugger
    setClientInfoToCloseOrder(initialClientInfo);
    setGuestInfoToCloseOrder(initialGuestInfo);
  };
  const handleResetInfoRegisterForm = () => {
    //debugger
    setClientInfoToCloseOrder(initialClientInfo);
  };
  const handleResetInfoGuestForm = () => {
    //debugger
    setGuestInfoToCloseOrder(initialGuestInfo);
  };

  return (
    <Fragment>
      {/* <Loading /> */}
      {!true && error()}

      <Navbar
        menuApp={menuApp}
        infoCartOrder={infoCartOrder}
        handleGAEvent={handleGAEvent}
        setIsOpenFloatingCheckout={setIsOpenFloatingCheckout}
        setIsOpenFloatingFavorites={setIsOpenFloatingFavorites}
        isWebpage={isWebpage}
        handleResetInfoClient={handleResetInfoClient}
        setFloatingCheckoutIconIsClicked={setFloatingCheckoutIconIsClicked}
        isOpenEntrepreneurContactForm={isOpenEntrepreneurContactForm}
        setIsOpenEntrepreneurContactForm={setIsOpenEntrepreneurContactForm}
        isActiveSearch={isActiveSearch}
        setIsActiveSearch={setIsActiveSearch}
        isLanguageLoaded={isLanguageLoaded}
        isProfileImageLoaded={isProfileImageLoaded}
        imageProfileUrl={imageProfileUrl}
      />

      {/* FLOATING CHECKOUT */}
      <FloatingCheckout
        open={isOpenFloatingCheckout}
        setIsOpenFloatingCheckout={setIsOpenFloatingCheckout}
        infoCartOrder={infoCartOrder}
        admissionKits={admissionKits}
        cpMemberships={cpMemberships}
        benefitType={benefitType}
        setBenefitType={setBenefitType}
        handleGAEvent={handleGAEvent}
        isOpenGiftSelectModal={isOpenGiftSelectModal}
        countGiftSelect={countGiftSelect}
        floatingCheckoutIconIsClicked={floatingCheckoutIconIsClicked}
        setFloatingCheckoutIconIsClicked={setFloatingCheckoutIconIsClicked}
        clientInfoToCloseOrder={clientInfoToCloseOrder}
        handleResetInfoClient={handleResetInfoClient}
        handleResetInfoGuestForm={handleResetInfoGuestForm}
        isLanguageLoaded={isLanguageLoaded}
      />
      {/* END FLOATING CHECKOUT */}

      {/* FLOATING FAVORITES */}
      <FloatingFavorites
        open={isOpenFloatingFavorites}
        setIsOpenFloatingFavorites={setIsOpenFloatingFavorites}
        clientFavorites={clientFavorites}
        isLanguageLoaded={isLanguageLoaded}
        enqueueSnackbar={enqueueSnackbar}
      />
      {/* END FLOATING FAVORITES */}

      <div
        id="baseContainer__wrapper"
        className={`baseContainer__wrapper ${
          isMobile || isTablet ? "mobile" : ""
        } ${isIOS ? "is__iOS" : ""} ${
          (isMobile || isTablet) && isWebpage ? "is__webPage" : ""
        }`}
        onScroll={() => {
          //console.log("me estoy escroleando");
          //Close all floating windows onScroll (Checkout, SearchResults, Favorites, Webpage Form)
          //Checkout
          if (isOpenFloatingCheckout) {
            setIsOpenFloatingCheckout(false);
          }
          //SearchResults
          if (isActiveSearch) {
            setIsActiveSearch(false);
          }
          //Favorites
          if (isOpenFloatingFavorites) {
            setIsOpenFloatingFavorites(false);
          }
          //Webpage Form
          if (isOpenEntrepreneurContactForm) {
            setIsOpenEntrepreneurContactForm(false);
          }
        }}
      >
        {React.cloneElement(props.children, {
          infoCartOrder: infoCartOrder,
          clientFavorites: clientFavorites,
          admissionKits: admissionKits,
          cpMemberships: cpMemberships,
          benefitType: benefitType,
          setBenefitType: setBenefitType,
          isOpenFloatingCheckout: isOpenFloatingCheckout,
          guestInfoToCloseOrder: guestInfoToCloseOrder,
          setGuestInfoToCloseOrder: setGuestInfoToCloseOrder,
          clientInfoToCloseOrder: clientInfoToCloseOrder,
          setClientInfoToCloseOrder: setClientInfoToCloseOrder,
          handleGAEvent: handleGAEvent,
          handleResetInfoClient: handleResetInfoClient,
          typePlan: typePlan,
          setTypePlan: setTypePlan,
          handleResetInfoRegisterForm: handleResetInfoRegisterForm,
          handleResetInfoGuestForm: handleResetInfoGuestForm,
          countGiftSelect: countGiftSelect,
          isLanguageLoaded: isLanguageLoaded,
          vCardWebPageEntreprenourData: vCardWebPageEntreprenourData,
          isProfileImageLoaded: isProfileImageLoaded,
          imageProfileUrl: imageProfileUrl,
          downloadProfileImageValidation: downloadProfileImageValidation,
          vCardSponsorSelection: vCardSponsorSelection,
          sponsorSelectionData: sponsorSelectionData,
          setSponsorSelectionData: setSponsorSelectionData,
          buildVCardSponsorSelection: buildVCardSponsorSelection,
          cleanVCardSponsorSelection: cleanVCardSponsorSelection,
          isWebpage: isWebpage,
        })}
        <ScrollComponent />
        <Footer
          footerDataApp={footerDataApp}
          isLoadingEnabled={footerIsLoadingEnable}
          dispatch={dispatch}
          clientReducer={appReducers.client}
          isLanguageLoaded={isLanguageLoaded}
          isWebpage={isWebpage}
        />
        <div id="WrapperForLabelCanvas" />
      </div>
    </Fragment>
  );
};
///

export default withRouter(BaseContainer);
