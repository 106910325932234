import React, { Fragment, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";
import { useSnackbar } from "notistack";
import VCard from "vcard-creator";
import NavbarNewMembers from "./componets/NavbarNewMembers";
//MUI
import {
  FormControl,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Grid,
  TextField,
  LinearProgress,
  FormHelperText,
  Button,
  Skeleton,
} from "@mui/material";
import { CheckCircle, Face } from "@mui/icons-material";
import {
  deviceDetect,
  isMobile,
  isTablet,
  isDesktop,
} from "react-device-detect";
//Moment
import MomentUtils from "@date-io/moment";
import moment from "moment";
//Components
import LoaderSVG from "../base/components/LoaderSVG";
import HeaderNewMembers from "./componets/HeaderNewMembers";
import CompanyInformation from "./componets/CompanyInformation";
//Utils
import {
  areOnlyLetters,
  regexIsCellphoneValid,
  restrictToOnlyNumbers,
  regexIsEmailAddressValid,
  restrictToOnlyLettersForEmail,
  isStrongPassword,
  restrictToOnlyLettersAndNumbers,
} from "../../../Libraries/Utils/regex";
///Assets
import errorPlaceholder from "../../../assets/media/images/errorPlaceholder.png";
import LogoDoradoZermat from "../../../assets/media/images/LogoZermat.png";
import resumeBackground from "../../../assets/media/images/newMembersResumeBackground.png";
///client service
import ClientService from "./../../../Libraries/Client";
//Actions
import Payment from "./../../../Libraries/Payment";
import {
  requestFirstChargeSite,
  requestStatesAndCities,
  requestLoggUserActivity, //dispatch(requestLoggUserActivity(7, 0, 0, "", "Se cancelo la orden"));
} from "./../../ReduxSaga/Actions/General";
import { requestEntrepenourHomeModuleInfo } from "./../../ReduxSaga/Actions/Home";
import {
  requestEmailValidation,
  cleanEmailReducerValidation,
} from "./../../ReduxSaga/Actions/Email";
import {
  requestTwilioCellPhoneValidation,
  requestTwilioCellPhoneCodeValidation,
  cleanReducerCellPhoneDataValidation,
  cleanTwilioCellValidationReducer,
} from "./../../ReduxSaga/Actions/Twilio";

import {
  requestValidateIdentificationType,
  cleanValidateIdentificationTypeReducer,
  requestCleanAddressReducer,
  requestClientLogoutAndChargeNewMember,
  requestUserDataValidation,
  requestCleanUserDataValidation,
} from "./../../ReduxSaga/Actions/Client";

import {
  requestGetCartOrder,
  requestGetCartClosedOrder,
  requestCloseCartOrder,
  requestCartOrderAddMembership,
  requestCleanCartReducerAddMembership,
  requestCartCancelOrder,
  requestCleanCartReducerCancelOrder,
  requestCleanCartNewMembersReducers,
  requestCarCalculateOrderTax,
  cleanReducerOrderTaxesCalculation,
  requestCleanCartReducer,
} from "./../../ReduxSaga/Actions/Cart";
import { requestAddressValidation } from "./../../ReduxSaga/Actions/Address";
import {
  requestStripePayment,
  requestCleanPaymentReducer,
} from "./../../ReduxSaga/Actions/Payment";
import {
  requestGetRegisteredProspect,
  requestRegisterNewProspect,
  cleanNewMemberReducer,
} from "./../../ReduxSaga/Actions/NewMembers";
import BenefitsNewMembers from "./componets/BenefitsNewMembers";
import YourBusinessBanner from "./componets/YourBusinessBanner";
import ShowKitEnabled from "./componets/ShowKitEnabled";
import FormNewMembers from "./componets/FormNewMembers";
import FooterNewMembers from "./componets/FooterNewMembers";
import PayKitNewMembers from "./componets/PayKitNewMembers";
//Google Analytics
ReactGA.initialize("UA-104837043-2");

//Fake data
const fakeLanguageList = [
  { id: 1, code: "es", name: "Español" },
  { id: 2, code: "en", name: "English" },
];
///////////////////////////////////////////

const NewMembersContainer = (props) => {
  //Notistack
  const { enqueueSnackbar } = useSnackbar();

  //Moment Declarations
  const maxDate = moment().subtract(18, "years").add(1, "days");
  //const maxDate = moment("1900-01-01", "YYYY-MM-DD");

  //React Router
  const location = useLocation();
  const history = useHistory();

  //Translation
  const { t, i18n } = useTranslation();

  //Redux
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.general = useSelector((state) => state.general);
  appReducers.home = useSelector((state) => state.home);
  appReducers.products = useSelector((state) => state.products);
  appReducers.email = useSelector((state) => state.email);
  appReducers.client = useSelector((state) => state.client);
  appReducers.twilio = useSelector((state) => state.twilio);
  appReducers.cart = useSelector((state) => state.cart);
  appReducers.address = useSelector((state) => state.address);
  appReducers.payment = useSelector((state) => state.payment);
  appReducers.newMember = useSelector((state) => state.newMember);

  //Initial states
  const defaultState = {
    name: "",
    lastname: "",
    motherLastname: "",
    //birthdate: maxDate.format("YYYY-MM-DD"),
    birthdate: "",
    gender: "",
    idLang: 1,
    interested: true,
    password: "",
    nss: "",
    license: "",
    anotherId: "",
    homePhone: "",
    officePhone: "",
    cellPhone: "",
    timeZone: "PACIFIC",
    email: "",
    zipShipping: "",
    cityShipping: "",
    stateShipping: "",
    streetShipping: "",
    suiteShipping: "",
    zipFiscal: "",
    cityFiscal: "",
    stateFiscal: "",
    streetFiscal: "",
    suiteFiscal: "",
    acceptZermatNofications: true,
    sponsor: "",
  };
  const cardDefaultState = {
    cardNumber: "",
    cardExipirationDate: "",
    cardMonth: "",
    cardYear: "",
    cardCvc: "",
  };
  // const defaultState = {
  //   orderId: 1,
  //   userId: 0,
  //   clientId: 0,
  //   sponsor: 0,
  //   name: "",
  //   lastName: "",
  //   email: "",
  //   cellPhone: "",
  //   password: "",
  //   benefitType: 1,
  //   birthdate: maxDate.format("YYYY-MM-DD"),
  //   gender: "",
  //   lng: "en",
  //   //Address
  //   shippStreet: "",
  //   shippSuite: "",
  //   shippCity: "",
  //   shippState: "",
  //   shippZip: "",
  //   fiscalStreet: "",
  //   fiscalSuite: "",
  //   fiscalCity: "",
  //   fiscalState: "",
  //   fiscalZip: "",
  // };

  ///HOOKS

  const [vCardWebPageEntreprenourData, setVCardWebPageEntreprenourData] =
    useState("");
  const [wayToPay, setWayToPay] = useState("stripe");
  const [initialDataPreregistration, setInitialDataPreregistration] =
    useState(defaultState);
  const [cardData, setCardData] = useState(cardDefaultState);
  //ISEMAIL VERIFIED// IS CELLPHONE VERIFIED
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [emailMessageErrorServer, setEmailMessageErrorServer] = useState("");
  const [isCellPhoneVerified, setIsCellPhoneVerified] = useState(false);
  const [cellMessageErrorServer, setCellMessageErrorServer] = useState("");
  const [showVerfieldCellPhoneField, setShowVerfieldCellPhoneField] =
    useState(false);
  const [cellPhoneCode, setCellPhoneCode] = useState("");
  const [identificationType, setIdentificationType] = useState("");
  const [isIdentificationNssValid, setIsIdentificationNssValid] =
    useState(false);
  const [isIdentificationLicValid, setIsIdentificationLicValid] =
    useState(false);
  const [isIdentificationRfcValid, setIsIdentificationRfcValid] =
    useState(false);
  //KITS
  // const [admissionKits, setAdmissionKits] = useState([]);

  //Address
  const [statesResult, setstatesResult] = useState([]);
  // const [citiesResult, setcitiesResult] = useState([]);
  const [sponsorData, setSponsorData] = useState({}); //sponsorName
  const [languageList, setLanguageList] = useState(fakeLanguageList);
  const [orderData, setOrderData] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  //Control if kit are added to order
  const [closedOrderData, setClosedOrderData] = useState({});
  const [displayErrorOnWebPage, setDisplayErrorOnWebPage] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  // const [selectedKit, setSelectedKit] = useState(undefined);
  const [currentKitObject, setCurrentKitObject] = useState({});
  const [isActiveFlotingPay, setIsActiveFlotingPay] = useState(false);
  const [showResumeScreen, setShowResumeScreen] = useState(undefined);

  const [isShipmentAddressVerified, setIsShipmentAddressVerified] =
    useState(false);
  const [isFiscalAddressVerified, setIsFiscalAddressVerified] = useState(false);
  const [isOpenAddressValidationModal, setIsOpenAddressValidationModal] =
    useState(false);
  const [addressListSugestion, setAddressListSugestion] = useState([]);
  const [addressTypeToValidate, setAddressTypeToValidate] = useState("");
  const [useAsShippAddress, setUseAsShippAddress] = useState(false);
  const [checkAccept, setCheckAccept] = useState(false);
  const [clientDataRegistered, setClientDataRegistered] = useState({});
  const [stepPay, setStepPay] = useState(0);
  /// i18n
  const [isLanguageLoaded, setIsLanguageLoaded] = useState(false);
  // PAYPAL
  const [paypalStatusPay, setPaypalStatusPay] = useState("");

  /////// screen pruebas

  const [isPayShow, setIsPayShow] = useState(false);

  //// modal user data validation
  const [isOpenValidationDataModal, setIsOpenValidationDataModal] =
    useState(false);
  const [userDataValidationErrors, setUserDataValidationErrors] = useState([]);
  //////////////////////////////////////////////////////////////////////////////////

  //Effects
  useEffect(() => {
    document.title = "Zermat USA :: New Members";
    dispatch(
      requestLoggUserActivity(
        25,
        0,
        0,
        "",
        "(New Members) Cierra Sesión al cargar el formulario de nuevos miembros."
      )
    );
    handleFirstCharge();
  }, []);

  //On i18n resolved language
  useEffect(() => {
    if (i18n.resolvedLanguage) {
      //Ya cargo el idioma
      //console.log("i18n.resolvedLanguage", i18n.resolvedLanguage);
      setIsLanguageLoaded(true);
    }
  }, [i18n.resolvedLanguage]);

  // useEffect(() => {
  //   if (
  //     typeof appReducers?.client?.errorLoadClientLogedInformation !==
  //     "undefined"
  //   ) {

  //   } else {
  //     dispatch(requestClientLogOut());
  //     handleFirstCharge();
  //   }
  // }, [appReducers?.client?.clientLogedinInformation]);

  useEffect(() => {
    if (appReducers?.general?.firstChargeModel?.hasOwnProperty("idLang")) {
      if (appReducers?.general?.firstChargeModel?.idPage > 0) {
        dispatch(requestEntrepenourHomeModuleInfo());
        // if (!appReducers?.general?.firstChargeModel?.isGuestUser) {
        //   dispatch(requestClientInformation());
        // }
        const urlPage = localStorage.getItem("ZUsaUrlPage");
        handleGAEvent(
          "Navigation",
          "Carga formulario NewMembers bajo página web de emprendedora: " +
            urlPage
        );
        dispatch(
          requestLoggUserActivity(
            68,
            0,
            0,
            "",
            "Carga formulario NewMembers bajo página web de emprendedora: " +
              urlPage
          )
        );
      } else {
        console.error("nose encontró la página web");
        setDisplayErrorOnWebPage(true);
        handleGAEvent(
          "Navigation",
          "Carga formulario NewMembers no se encontró la página web"
        );
        dispatch(
          requestLoggUserActivity(
            68,
            0,
            0,
            "",
            "Carga formulario NewMembers no se encontró la página web"
          )
        );
      }
    }

    return () => {
      if (displayErrorOnWebPage) setDisplayErrorOnWebPage(false);
    };
  }, [
    appReducers?.general?.errorLoadFirstCharge,
    appReducers?.general?.firstChargeModel,
    i18n,
  ]);

  //*** SET INFORMACION PAGINA ***//
  useEffect(() => {
    if (appReducers?.home?.entrepenourPageInfo) {
      //ssi tiene datos
      setSponsorData(appReducers?.home?.entrepenourPageInfo);
      buildVCardEntreprenourWebPageOwner(
        appReducers?.home?.entrepenourPageInfo
      );
      dispatch(requestStatesAndCities());
      //dispatch(requestGetAdmissionKits());
      //setear el idioma de la pagina
    }

    return () => {
      if (sponsorData.hasOwnProperty("nameClient")) setSponsorData({});
    };
  }, [appReducers?.home?.entrepenourPageInfo]);

  //SponsorData Effect
  useEffect(() => {
    if (sponsorData.hasOwnProperty("idClient")) {
      setInitialDataPreregistration({
        ...initialDataPreregistration,
        sponsor: sponsorData.idClient,
      });
    }
  }, [sponsorData]);

  //*** GET KITS DE INGRESO ***//
  // useEffect(() => {
  //   if (appReducers?.products?.admissionKitList) {
  //     const kits = appReducers.products.admissionKitList;
  //     setAdmissionKits(
  //       kits.admissionKitList.filter(
  //         (f) =>
  //           f.typePlan === 1 && f.idLang == (i18n.language === "es" ? 1 : 2)
  //       )
  //     );
  //   }

  //   return () => {
  //     if (admissionKits?.length > 0) setAdmissionKits([]);
  //   };
  // }, [appReducers?.products?.admissionKitList]);

  //EFECTO PARA CIUDADES Y ESTADOS
  useEffect(() => {
    if (typeof appReducers?.general?.errorLoadStatesAndCities !== "undefined") {
      if (!appReducers?.general?.errorLoadStatesAndCities) {
        if (
          appReducers?.general?.statesAndCities &&
          appReducers?.general?.statesAndCities.length > 0
        ) {
          setstatesResult(appReducers?.general?.statesAndCities);
        }
      }
      //  else {
      //   // setDataNotification({
      //   //   isOpen: true,
      //   //   type: "error",
      //   //   message: t("__forms_states_cities_error_code_behind"),
      //   // });
      // }
    }
    return () => {
      if (statesResult?.length > 0) setstatesResult([]);
    };
  }, [
    appReducers?.general?.errorLoadStatesAndCities,
    appReducers?.general?.statesAndCities,
  ]);

  useEffect(() => {
    if (typeof appReducers?.cart?.errorCartOrder !== "undefined") {
      if (appReducers?.cart?.cartOrder && !appReducers?.cart?.errorCartOrder) {
        if (appReducers?.cart?.cartOrder?.order?.hasOwnProperty("orderId")) {
          setIsActiveFlotingPay(true);
          setOrderData(appReducers?.cart?.cartOrder);
          // setInitialDataPreregistration({
          //   ...initialDataPreregistration,
          //   orderId: appReducers?.cart?.cartOrder?.order?.orderId,
          // });
        }
      } else {
        setIsActiveFlotingPay(false);
        enqueueSnackbar(
          "There is an error on trying to get the kit that you selected.",
          {
            variant: "error",
          }
        );
      }
    }
  }, [appReducers?.cart?.cartOrder, appReducers?.cart?.errorCartOrder]);

  //Effect on Twilio CellPhone Validation
  useEffect(() => {
    if (
      typeof appReducers?.twilio?.errorTwilioCellValidation !== "undefined" || //limpiar este reducer
      typeof appReducers?.twilio?.code !== "undefined"
    ) {
      if (!appReducers?.twilio?.errorTwilioCellValidation) {
        setShowVerfieldCellPhoneField(true);
        setCellMessageErrorServer("");
        setShowVerfieldCellPhoneField(true);
      } else {
        if (showVerfieldCellPhoneField) setShowVerfieldCellPhoneField(false);
        if (showVerfieldCellPhoneField) setShowVerfieldCellPhoneField(false);

        switch (appReducers?.twilio?.code) {
          case -100: //error de ejecucion
            setCellMessageErrorServer(
              t("__forms_twilio_cell_validation_gen_error")
            );
            enqueueSnackbar(t("__forms_twilio_cell_validation_gen_error"), {
              variant: "error",
            });
            break;
          case -200: //error celular en bd
            setCellMessageErrorServer(
              t("__forms_twilio_cell_validation_db_error")
            );
            enqueueSnackbar(t("__forms_twilio_cell_validation_db_error"), {
              variant: "error",
            });
            break;
          case -300: //error celular no es un numero valido
            setCellMessageErrorServer(
              t("__forms_twilio_cell_validation_type_error")
            );
            enqueueSnackbar(t("__forms_twilio_cell_validation_type_error"), {
              variant: "error",
            });
            break;
          case -400: //error celular no se envió el sms porque no cubrimos la cobertura o error de twilio
            setCellMessageErrorServer(t("__forms_twilio_cell_send_sms_error"));
            enqueueSnackbar(t("__forms_twilio_cell_send_sms_error"), {
              variant: "error",
            });
            break;
          default:
            setCellMessageErrorServer(
              t("__forms_twilio_cell_validation_type_error")
            );
            enqueueSnackbar(t("__forms_twilio_cell_validation_type_error"), {
              variant: "error",
            });
            break;
        }
      }
      //limpiar
      dispatch(cleanTwilioCellValidationReducer());
    }
  }, [
    appReducers?.twilio?.errorTwilioCellValidation,
    appReducers?.twilio?.code,
  ]);

  //*** Effect on Email Validation ***//
  useEffect(() => {
    if (
      typeof appReducers?.email?.errorEmailValidation !== "undefined" ||
      typeof appReducers?.email?.code !== "undefined"
    )
      if (!appReducers?.email?.errorEmailValidation) {
        setIsEmailVerified(true);
        setEmailMessageErrorServer("");
        dispatch(cleanEmailReducerValidation());
      } else {
        if (isEmailVerified) setIsEmailVerified(false);

        switch (appReducers?.email?.code) {
          case -100: //error de ejecucion
            setEmailMessageErrorServer(
              t("__forms_email_validation_error_execution_message")
            );
            enqueueSnackbar(
              t("__forms_email_validation_error_execution_message"),
              { variant: "error" }
            );

            break;
          case -200: //error email en bd
            setEmailMessageErrorServer(
              t("__forms_email_validation_error_db_duplicity_message")
            );
            enqueueSnackbar(
              t("__forms_email_validation_error_db_duplicity_message"),
              { variant: "error" }
            );

            break;
          case -900: //error zerobounce
            setEmailMessageErrorServer(
              t("__forms_email_validation_error_zerobounce_message")
            );
            enqueueSnackbar(
              t("__forms_email_validation_error_zerobounce_message"),
              { variant: "error" }
            );

            break;
          default:
            setEmailMessageErrorServer(
              t("__forms_email_validation_error_execution_message")
            );
            enqueueSnackbar(
              t("__forms_email_validation_error_execution_message"),
              { variant: "error" }
            );

            break;
        }
      }
  }, [appReducers?.email?.errorEmailValidation, appReducers?.email?.code]);

  //*** Effect on twilio code Validation ***//
  useEffect(() => {
    if (
      typeof appReducers?.twilio?.errorTwilioCellCodeValidation !== "undefined" //limpiar este reducer
    ) {
      if (appReducers?.twilio?.errorTwilioCellCodeValidation === false) {
        enqueueSnackbar(t("__forms_twilio_cell_val_code_success_message"), {
          variant: "success",
        });
        setIsCellPhoneVerified(true);
        setCellMessageErrorServer("");
        setShowVerfieldCellPhoneField(false);
      } else {
        if (isCellPhoneVerified) setIsCellPhoneVerified(false);

        setCellMessageErrorServer(
          t("__forms_twilio_cell_val_code_error_message")
        );
        enqueueSnackbar(t("__forms_twilio_cell_val_code_error_message"), {
          variant: "error",
        });
      }
      dispatch(cleanReducerCellPhoneDataValidation());
    }
  }, [appReducers?.twilio?.errorTwilioCellCodeValidation]);

  //Effect on validateIdentificationType
  useEffect(() => {
    if (appReducers?.client?.validateIdentificationType) {
      const result = appReducers?.client?.validateIdentificationType;
      if (result.code === 100) {
        if (!result.isExists) {
          switch (identificationType) {
            case "NSS":
              setIsIdentificationNssValid(true);
              break;
            case "LIC":
              setIsIdentificationLicValid(true);
              break;
            case "RFC":
              setIsIdentificationRfcValid(true);
              break;
            default:
              break;
          }
          enqueueSnackbar(t("__message_valid_id_label"), {
            variant: "success",
          });
        } else {
          switch (identificationType) {
            case "NSS":
              setIsIdentificationNssValid(false);
              break;
            case "LIC":
              setIsIdentificationLicValid(false);
              break;
            case "RFC":
              setIsIdentificationRfcValid(false);
              break;
            default:
              break;
          }
          enqueueSnackbar(t("__message_register_id_label"), {
            variant: "error",
          });
        }
      } else {
        switch (identificationType) {
          case "NSS":
            setIsIdentificationNssValid(false);
            break;
          case "LIC":
            setIsIdentificationLicValid(false);
            break;
          case "RFC":
            setIsIdentificationRfcValid(false);
            break;
          default:
            break;
        }

        enqueueSnackbar(t("__message_register_id_label"), {
          variant: "error",
        });
      }
      dispatch(cleanValidateIdentificationTypeReducer());
    }
  }, [appReducers?.client?.validateIdentificationType]);

  useEffect(() => {
    if (appReducers?.cart?.cartAddMembership) {
      const AddMembership = appReducers.cart.cartAddMembership;
      if (AddMembership.code === 100) {
        //limpiar el reducer
        dispatch(requestCleanCartReducerAddMembership());

        //traer la nueva orden
        hanldeTaxCalculationOnOrder(initialDataPreregistration.zipShipping);
        //dispatch(requestGetCartOrder());
        enqueueSnackbar(t("__ZUsa_App_Js_Kit_Added_To_Your_Cart"), {
          variant: "success",
        });
      } else {
        enqueueSnackbar(t("__ZUsa_App_Js_Kit_Added_To_Your_Cart_Error"), {
          variant: "error",
        });
      }
    }
  }, [appReducers?.cart?.cartAddMembership]);

  //EFECTO PARA CONTROLAR LA CREACION DELA ORDEN Y OBTENERLA
  // useEffect(() => {
  //   if (typeof appReducers?.cart?.cartCurrentOrder !== "undefined") {
  //     if (
  //       appReducers?.cart?.cartCurrentOrder?.code &&
  //       appReducers?.cart?.cartCurrentOrder?.code === 100
  //     ) {
  //       dispatch(requestGetCartOrder());
  //       handleOpenSnackbar(
  //         t("__ZUsa_App_Js_Kit_Added_To_Your_Cart"),
  //         "success"
  //       );
  //     } else
  //       handleOpenSnackbar(
  //         t("__ZUsa_App_Js_Kit_Added_To_Your_Cart_Error"),
  //         "error"
  //       );
  //   }
  // }, [appReducers?.cart?.cartCurrentOrder]);

  useEffect(() => {
    if (typeof appReducers?.cart?.errorCartTaxOrder !== "undefined") {
      if (!appReducers?.cart?.errorCartTaxOrder) {
        dispatch(cleanReducerOrderTaxesCalculation());
        dispatch(requestGetCartOrder());
        handleGAEvent(
          "CartOrder",
          "(NewMembers) Se recaluclan impuestos en la orden de forma correcta."
        );
        dispatch(
          requestLoggUserActivity(
            72,
            0,
            0,
            "",
            "(NewMembers) Se recalculan impuestos en la orden de forma correcta."
          )
        );
      } else {
        //error
        handleGAEvent(
          "CartOrder",
          "(NewMembers) Se recaluclan impuestos en la orden de forma incorrecta."
        );
        dispatch(
          requestLoggUserActivity(
            72,
            0,
            0,
            "",
            "(NewMembers) Se recalculan impuestos en la orden de forma incorrecta."
          )
        );
        console.error(t("__checkout_order_messagge_calc_fail"));
      }
    }
  }, [appReducers?.cart?.errorCartTaxOrder, appReducers?.cart?.cartTaxOrder]);

  ///EFFECT ON ADDRESS VALIDATION
  useEffect(() => {
    if (
      typeof appReducers?.address?.errorClientAddressValidation !== "undefined"
    ) {
      const { typeAddress } = appReducers?.address;
      setAddressTypeToValidate(typeAddress);
      if (appReducers?.address?.errorClientAddressValidation) {
        if (
          appReducers?.address?.addressSugestion &&
          appReducers?.address?.addressSugestion.length > 0
        ) {
          setAddressListSugestion(appReducers?.address?.addressSugestion);
          setIsOpenAddressValidationModal(true);
        } else {
          enqueueSnackbar(
            t("__forms_address_verification_error_noData_message"),
            {
              variant: "error",
            }
          );
          if (typeAddress === "e") {
            setIsShipmentAddressVerified(false);
          } else {
            setIsFiscalAddressVerified(false);
          }
          setIsOpenAddressValidationModal(false);
          setAddressListSugestion([]);
          dispatch(requestCleanAddressReducer());
        }
      } else {
        enqueueSnackbar(t("__forms_address_verification_success_message"), {
          variant: "success",
        });
        setIsOpenAddressValidationModal(false);
        setAddressListSugestion([]);
        if (typeAddress === "e") {
          setIsShipmentAddressVerified(true);
          //hanldeTaxCalculationOnOrder(initialDataPreregistration.zipShipping);
        } else {
          setIsFiscalAddressVerified(true);
        }
        dispatch(requestCleanAddressReducer());
      }
    }
  }, [
    appReducers?.address.errorClientAddressValidation,
    appReducers?.address.addressSugestion,
  ]);

  //EFFECT ON REGISTRATION
  useEffect(() => {
    if (typeof appReducers?.newMember?.responseRegistration !== "undefined") {
      if (appReducers.newMember.responseRegistration.code === 100) {
        dispatch(
          requestGetRegisteredProspect(
            appReducers.newMember.responseRegistration.result
          )
        );
        if (wayToPay === "stripe") handleStripeCreditCardPayment();
        else dispatch(requestCloseCartOrder(1, false));

        dispatch(cleanNewMemberReducer());
      } else {
        enqueueSnackbar(t("An Error has Ocurred on your registration :("), {
          variant: "error",
        });
      }
    }
  }, [appReducers?.newMember?.responseRegistration]);

  ///EFFECT GETTING DATA CLIENT REGISTERED
  useEffect(() => {
    if (
      typeof appReducers?.newMember?.responseGetRegistration !== "undefined"
    ) {
      if (appReducers.newMember.responseGetRegistration.code === 100) {
        setClientDataRegistered(
          appReducers.newMember.responseGetRegistration.newClientInformation
        );
      }
    }
  }, [appReducers?.newMember?.responseGetRegistration]);

  ///EFFECT ON STRIPEPAYMENT PROCCESS
  useEffect(() => {
    if (appReducers?.payment?.responseStripePayment) {
      const pago = appReducers?.payment?.responseStripePayment;
      //console.log("pago", pago);
      if (pago.code === 100 || pago.code === -200) {
        enqueueSnackbar(t("__message_pay_success_label"), {
          variant: "success",
        });
        // dispatch(requestGetCartOrder());
        dispatch(requestCloseCartOrder(1, false));
        handleGAEvent(
          "CartOrder",
          "(NewMembers) Pago de orden realizado de forma correcta en esta sesion."
        );
        dispatch(
          requestLoggUserActivity(
            72,
            0,
            0,
            "",
            `(NewMembers) Pago de orden realizado de forma correcta en esta sesion. ${pago?.message}`
          )
        );
        handleGAEvent("CartOrder", "(NewMembers) Cierre de orden iniciado.");
        dispatch(
          requestLoggUserActivity(
            73,
            0,
            0,
            "",
            `(NewMembers) Cierre de orden iniciado en esta sesion`
          )
        );
      } else {
        handleGAEvent(
          "CartOrder",
          "(NewMembers) Pago de orden realizado de forma incorrecta en esta sesion."
        );
        dispatch(
          requestLoggUserActivity(
            72,
            0,
            0,
            "",
            `(NewMembers) Pago de orden realizado de forma incorrecta en esta sesion. ${pago?.message}`
          )
        );
        enqueueSnackbar(t("__message_order_payment_label_card_number_error"), {
          variant: "error",
        });
      }
      dispatch(requestCleanPaymentReducer());
    }
  }, [appReducers?.payment?.responseStripePayment]);

  ///EFFECT ON SERVICE TO CLOSE ORDER
  useEffect(() => {
    if (appReducers?.cart?.closeCartOrder) {
      const closed = appReducers.cart.closeCartOrder;
      if (closed.code === 100) {
        enqueueSnackbar(t("shopping__payment__finish_order_message"), {
          variant: "success",
        });
        setStepPay(1);
        const previousSession =
          appReducers?.general?.firstChargeModel?.sessionId;
        handleGAEvent(
          "CartOrder",
          "(NewMembers) Cierre de orden realizado de forma correcta en esta sesion."
        );
        dispatch(
          requestLoggUserActivity(
            73,
            0,
            0,
            "",
            `(NewMembers) Cierre de orden realizado de forma correcta en esta sesion ${previousSession}. ${closed.message}`
          )
        );
      } else {
        enqueueSnackbar(t("__message_order_close_label_error"), {
          variant: "error",
        });
        handleGAEvent(
          "CartOrder",
          "(NewMembers) Cierre de orden realizado de forma incorrecta en esta sesion."
        );
        dispatch(
          requestLoggUserActivity(
            73,
            0,
            0,
            "",
            `(NewMembers) Cierre de orden realizado de forma incorrecta en esta sesion. ${closed?.message}`
          )
        );
      }
    } else if (typeof appReducers?.cart?.errorCloseCartOrder !== "undefined") {
      if (appReducers?.cart?.errorCloseCartOrder === true) {
        enqueueSnackbar(t("__message_order_close_label_error"), {
          variant: "error",
        });
        handleGAEvent(
          "CartOrder",
          "(NewMembers) Cierre de orden realizado de forma incorrecta en esta sesion."
        );
        dispatch(
          requestLoggUserActivity(
            73,
            0,
            0,
            "",
            `(NewMembers) Cierre de orden realizado de forma incorrecta en esta sesion.`
          )
        );
      }
    }
  }, [
    appReducers?.cart?.closeCartOrder,
    appReducers?.cart?.errorCloseCartOrder,
  ]);

  //USER DATA VALIDATION REQUEST EFFECT
  useEffect(() => {
    if (
      typeof appReducers?.client?.userDataValidationResponse !== "undefined"
    ) {
      if (appReducers.client.userDataValidationResponse.code === 100) {
        if (
          appReducers.client.userDataValidationResponse.validationList.length <=
          0
        ) {
          setIsOpenValidationDataModal(false);
          setUserDataValidationErrors([]);
          let idPedidoDet = -1,
            idpromo = 0,
            planType = 1,
            withAssign = 1,
            itemCode = currentKitObject.itemCode;
          dispatch(
            requestCartOrderAddMembership(
              idPedidoDet,
              idpromo,
              planType,
              withAssign,
              itemCode
            )
          );
          const urlPage = localStorage.getItem("ZUsaUrlPage");
          handleGAEvent(
            "CartOrder",
            `se agrega kit: ${itemCode}. desde NewMembers, Página: ${urlPage}`
          );
          dispatch(
            requestLoggUserActivity(
              70,
              0,
              0,
              itemCode,
              `Se agrega un kit desde NewMembers, Pagina: ${urlPage}`
            )
          );
        } else {
          setIsOpenValidationDataModal(true);
          setUserDataValidationErrors(
            appReducers.client.userDataValidationResponse.validationList
          );
        }
      } else {
        setIsOpenValidationDataModal(false);
        setUserDataValidationErrors([]);
        enqueueSnackbar(
          t("Error al validar los datos proporcionados; intenta de nuevo :)"),
          { variant: "error" }
        );
      }
      dispatch(requestCleanUserDataValidation());
    }
  }, [appReducers?.client?.userDataValidationResponse]);

  //Handlers
  const buildVCardEntreprenourWebPageOwner = (entreprenourInfo) => {
    let firstname = "";
    let lastname = "";
    const nameArray = entreprenourInfo.nameClient.split(" ");
    nameArray.forEach((n, index) => {
      if (index === 0) {
        firstname = n;
      } else if (index == 1) {
        lastname = n;
      }
    });
    //const additional = `EntreprenourID: ${entreprenourInfo.idClient}`;
    // const prefix = ''
    // const suffix = ''
    const myVCard = new VCard();
    myVCard
      // Add personal data
      .addName(lastname, firstname, "", "", "")
      // Add work data
      .addCompany("Zermat USA")
      //.addJobtitle("Zermat Entreprenour")
      //.addRole(additional)
      .addEmail(entreprenourInfo.email)
      .addPhoneNumber(entreprenourInfo.cellPhone, "PREF;WORK")
      // .addPhoneNumber(123456789, 'WORK')
      //.addAddress(null, null, 'street', 'worktown', null, 'workpostcode', 'Belgium')
      .addURL(
        `https://www.zermatusa.com/${localStorage.getItem("ZUsaUrlPage")}`
      );
    setVCardWebPageEntreprenourData(myVCard.toString());
  };

  const handleGAEvent = (category, action) => {
    //DEFAULT CATEGORIES
    //Navigation
    //User
    //CartOrder
    ReactGA.event({
      category: category,
      action: action,
      nonInteraction: false,
    });
  };

  const handleStripeCreditCardPayment = () => {
    handleGAEvent("CartOrder", "(NewMembers) Se inicia proceso de pago.");
    dispatch(
      requestLoggUserActivity(
        72,
        0,
        0,
        "",
        "(NewMembers) Se inicia proceso de pago en esta sesion."
      )
    );
    const orderId = orderData?.order?.orderId;
    dispatch(
      requestStripePayment(
        initialDataPreregistration.email,
        cardData.cardNumber,
        cardData.cardExipirationDate,
        cardData.cardCvc,
        orderId
      )
    );
  };

  const handleRegiterNewEntreprenour = () => {
    const urlPage = localStorage.getItem("ZUsaUrlPage");
    const logText = `El usuario guarda su información personal para pasar a su pedido en NewMembers Pagina: ${urlPage}`;
    handleGAEvent("User", logText);
    dispatch(requestLoggUserActivity(69, 0, 0, "", logText));
    dispatch(requestRegisterNewProspect(initialDataPreregistration));
  };

  const handleDateChange = (date) => {
    setInitialDataPreregistration({
      ...initialDataPreregistration,
      birthdate: moment(date).format("YYYY-MM-DD"),
    });
    setSelectedDate(date);
  };

  const handleAddressOpenValidation = (type) => {
    setAddressTypeToValidate(type);
    if (type === "e") {
      //envio
      dispatch(
        requestAddressValidation(
          initialDataPreregistration.streetShipping,
          initialDataPreregistration.suiteShipping,
          initialDataPreregistration.stateShipping,
          initialDataPreregistration.cityShipping,
          initialDataPreregistration.zipShipping,
          type,
          //orderCloseType !== "guest"
          false
        )
      );
    } else {
      //fiscal
      dispatch(
        requestAddressValidation(
          initialDataPreregistration.streetFiscal,
          initialDataPreregistration.suiteFiscal,
          initialDataPreregistration.stateFiscal,
          initialDataPreregistration.cityFiscal,
          initialDataPreregistration.zipFiscal,
          type,
          //orderCloseType !== "guest"
          false
        )
      );
    }
  };

  const handlerSelectAddressFromSugestion = (
    address,
    suite,
    state,
    city,
    zip,
    type
  ) => {
    if (type === "e") {
      setInitialDataPreregistration({
        ...initialDataPreregistration,
        streetShipping: address,
        suiteShipping: suite.trim(),
        cityShipping: city,
        stateShipping: state,
        zipShipping: zip,
      });
      //hanldeTaxCalculationOnOrder(zip);
      setIsShipmentAddressVerified(true);
      setIsOpenAddressValidationModal(false);
    } else {
      setInitialDataPreregistration({
        ...initialDataPreregistration,
        streetFiscal: address,
        suiteFiscal: suite.trim(),
        cityFiscal: city,
        stateFiscal: state,
        zipFiscal: zip,
      });
      setIsFiscalAddressVerified(true);
      setIsOpenAddressValidationModal(false);
    }
    dispatch(requestCleanAddressReducer());
  };

  const hanldeTaxCalculationOnOrder = (zipCode) => {
    const parameters = {
      idPedidoDet: 0,
      idpromo: 0,
      itemCode: "",
      opc: 18,
      pedidosD: [],
      quantity: 0,
      type: "",
      withAssign: 0,
      zipCode: zipCode,
    };
    dispatch(requestCarCalculateOrderTax(parameters));
  };
  const handleKitAdmissionButtonClick = () => {
    const model = {
      type: "VDZ",
      channel: "ZU",
      cellPhone: initialDataPreregistration.cellPhone,
      email: initialDataPreregistration.email,
      name: initialDataPreregistration.name,
      lastName: initialDataPreregistration.lastname,
      motherLastName: initialDataPreregistration.motherLastname,
      birthdate: initialDataPreregistration.birthdate,
      street: initialDataPreregistration.streetFiscal,
      suite: initialDataPreregistration.suiteFiscal,
      state: initialDataPreregistration.stateFiscal,
      city: initialDataPreregistration.cityFiscal,
      zipCode: initialDataPreregistration.zipFiscal,
      userId: 1,
    };
    dispatch(requestUserDataValidation(model));
  };
  // const handleAdmissionKitConfiguratePanel = (
  //   configurationKit,
  //   setConfiguration
  // ) => {
  //   const parameters = {
  //     idPedidoDet: 1,
  //     idpromo: 1,
  //     itemCode: setConfiguration?.codigo,
  //     opc: 16,
  //     pedidosD: configurationKit,
  //     quantity: 1,
  //     type: "K",
  //     withAssign: 1,
  //   };
  //   dispatch(requestCarCurrentOrder(parameters));
  //   const urlPage = localStorage.getItem("ZUsaUrlPage");
  //   handleGAEvent(
  //     "CartOrder",
  //     `se agrega kit: ${setConfiguration?.codigo}. desde NewMembers, Página: ${urlPage}`
  //   );
  //   dispatch(
  //     requestLoggUserActivity(
  //       70,
  //       0,
  //       0,
  //       setConfiguration?.codigo,
  //       `Se agrega un kit desde NewMembers, Pagina: ${urlPage}`
  //     )
  //   );

  //   handleOpenSnackbar(t("__ZUsa_App_Js_Kit_Added_To_Your_Cart"), "success");
  // };
  const setIsResetSession = () => {
    //dispatch(requestResetSession());
  };
  const handleFirstCharge = () => {
    setInitialDataPreregistration(defaultState);
    // const deviceDetector = deviceDetect();
    // let modelFirstCharge = {};
    let urlPage = "";
    if (props?.match?.params?.hasOwnProperty("userWebPage")) {
      urlPage = props.match.params.userWebPage;
    }
    if (urlPage.length <= 0 && localStorage.getItem("ZUsaUrlPage")) {
      urlPage = localStorage.getItem("ZUsaUrlPage");
    }

    dispatch(requestClientLogoutAndChargeNewMember(urlPage));

    // modelFirstCharge = {
    //   urlPage,
    //   browser:
    //     deviceDetector?.browserName === undefined
    //       ? ""
    //       : deviceDetector?.browserName,
    //   browserVersion:
    //     deviceDetector?.browserFullVersion === undefined
    //       ? ""
    //       : deviceDetector?.browserFullVersion,
    //   iP: "",
    //   os: deviceDetector?.osName === undefined ? "" : deviceDetector?.osName,
    //   osVersion:
    //     deviceDetector?.osVersion === undefined
    //       ? ""
    //       : deviceDetector?.osVersion,
    //   device:
    //     deviceDetector?.osName === undefined ? "" : deviceDetector?.osName,
    //   isDesktop,
    //   isMobile,
    //   isTablet,
    //   userAgent:
    //     deviceDetector?.userAgent === undefined
    //       ? ""
    //       : deviceDetector?.userAgent,
    //   email: "",
    // };
    //dispatch(requestFirstChargeSite(modelFirstCharge));
  };
  const emailValidationOnBlur = () => {
    dispatch(requestEmailValidation(initialDataPreregistration.email, true));
  };
  const cellPhoneValidationOnBlur = () => {
    dispatch(
      requestTwilioCellPhoneValidation(
        initialDataPreregistration.cellPhone,
        true
      )
    );
  };

  const identificationValidationOnBlur = (type) => {
    setIdentificationType(type);
    switch (type) {
      case "NSS":
        dispatch(
          requestValidateIdentificationType(
            0,
            type,
            initialDataPreregistration.nss
          )
        );
        break;
      case "LIC":
        dispatch(
          requestValidateIdentificationType(
            0,
            type,
            initialDataPreregistration.license
          )
        );
        break;
      case "RFC":
        dispatch(
          requestValidateIdentificationType(
            0,
            type,
            initialDataPreregistration.anotherId
          )
        );
        break;
      default:
        break;
    }
  };

  const verifyCellPhoneCodeIsValid = () => {
    dispatch(
      requestTwilioCellPhoneCodeValidation(
        initialDataPreregistration.cellPhone,
        cellPhoneCode
      )
    );
  };

  const handleGetGenderByName = async (name) => {
    const result = await ClientService.GetGenderPosibilityByName(name);
    if (typeof result !== "undefined" && typeof result === "object") {
      if (typeof result.gender !== "undefined" && result.gender !== null)
        setInitialDataPreregistration({
          ...initialDataPreregistration,
          gender: result.gender === "male" ? "M" : "F",
        });
      else
        setInitialDataPreregistration({
          ...initialDataPreregistration,
          gender: "F",
        });
    }
  };

  //Paypal handlers
  const handleOrderCreatorPaypal = () => {
    return Payment.CreateOrderOnPaypal(orderData.order.orderId)
      .then((res) => {
        if (res.status === 401) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then((data) => {
        return data.id;
      })
      .catch((err) => {});
  };
  const handleOrderAprovedPaypal = (data) => {
    return Payment.AproveOrderOnPaypal(data.orderID)
      .then((res) => {
        if (res.status === 401) {
          //setFormUserData(initialStateFormUserData);
          return res.json();
        } else {
          return res.json();
        }
      })
      .then((details) => {
        //console.log("onAproved", details);
        if (details.status === "COMPLETED") {
          setPaypalStatusPay(details.status);

          handleRegiterNewEntreprenour();

          handleGAEvent(
            "CartOrder",
            "(Checkout por paypal) Pago de orden realizado de forma correcta en esta sesion."
          );
          dispatch(
            requestLoggUserActivity(
              64,
              0,
              0,
              "",
              `(Checkout por paypal) Pago de orden realizado de forma correcta en esta sesion. ${details.status}`
            )
          );
          handleGAEvent(
            "CartOrder",
            "(CheckOut por paypal) Cierre de orden iniciado."
          );
          dispatch(
            requestLoggUserActivity(
              73,
              0,
              0,
              "",
              `(Checkout por paypal) Cierre de orden iniciado en esta sesion`
            )
          );
          ////direccion fiscal
          // if (!step3Complete && isValidFiscalAddress) {
          //   handleInsertFiscalAddress();
          // } else {
          //   setDataNotification({
          //     isOpen: true,
          //     type: "success",
          //     message: t("__message_pay_success_label"),
          //   });
          //   dispatch(requestCloseCartOrder(kitOrMembership));
          //   setRegisterModel({
          //     street: "",
          //     city: "",
          //     email: "",
          //     lastName: "",
          //     name: "",
          //     bornDate: "",
          //     state: "",
          //     suite: "",
          //     phone: "",
          //     phoneCode: "",
          //     zip: "",
          //     gender: "",
          //     idType: "",
          //     idNumber: "",
          //   });
          //   if (kitOrMembership === 1) {
          //     //si tenia kit en la orden
          //     if (idClientPageOwner > 0) {
          //       //el patrocinador sera el dueno de ese nuevo ingreso
          //       dispatch(requestAssignSponsor(idClientPageOwner));
          //     } else {
          //       if (sponsorSelectionType === 1) {
          //         //crear accion que vaya al servidor y crear el algoritmo para seleccion de patrocinador Automatica
          //         dispatch(
          //           requestAssignSponsorAutomatic(
          //             isInfoEnterprisingAssignment.noEnterprising
          //           )
          //         );
          //       } else {
          //         ///seleccion manual del patrocinadr
          //         dispatch(
          //           requestAssignSponsor(selectedEntreprenour.entreprenourId)
          //         );
          //       }
          //     }
          //   }
          //   dispatch(
          //     requestLoggUserActivity(
          //       64,
          //       0,
          //       0,
          //       "",
          //       "Click en Continuar:Paso 6 Checkout (Pago) Completo. Se Cerro la Orden"
          //     )
          //   );
          //   handleGAEvent(
          //     "CartOrder",
          //     "Usuario Completo El Proceso De Pago De Forma Correcta. Se Cerro La Orden"
          //   );
          //   //dispatch(requestCloseOrder(userFormData));
          // }
        } else {
          //console.log("No se pudo hacer el pago en paypal");
          handleGAEvent(
            "CartOrder",
            "(Checkout por paypal) Pago de orden realizado de forma incorrecta en esta sesion."
          );
          dispatch(
            requestLoggUserActivity(
              64,
              0,
              0,
              "",
              `(CheckOut por paypal) Pago de orden realizado de forma incorrecta en esta sesion. ${details.status}`
            )
          );
          enqueueSnackbar(
            t("shopping__payment__error_pay_for_paypal_info_message"),
            { variant: "error" }
          );
        }
      })
      .catch((err) => {});
  };

  //Renders

  return (
    <div className=" baseContainer baseContainer__newMembers">
      {displayErrorOnWebPage ? (
        <Fragment>
          <NavbarNewMembers />
          <div className="new__members__error__in__webpage">
            <img src={errorPlaceholder} alt="Error" />
            {isLanguageLoaded ? (
              <span className="new__members__error__in__webpage__title">
                {t("_Zusa_NewMembers_Error_Webpage_Title")}
              </span>
            ) : (
              <Skeleton variant="rect" width={200} height={30} />
            )}

            {isLanguageLoaded ? (
              <span>{t("_Zusa_NewMembers_Error_WebPage_Subtitle")}</span>
            ) : (
              <Skeleton
                variant="rect"
                width={200}
                height={30}
                style={{ margin: "10px 0" }}
              />
            )}
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <NavbarNewMembers />
          <div>
            {props?.match?.params?.targetRoute === "resume" && (
              <div className="background__image__wrapper">
                <img src={resumeBackground} alt="" />
              </div>
            )}

            <div className="referrer__form type__new__members">
              {!isPayShow ? (
                <Fragment>
                  {" "}
                  <HeaderNewMembers
                    isLanguageLoaded={isLanguageLoaded}
                    backColor={"#f53fae"}
                    t={t}
                    i18n={i18n}
                  />
                  <CompanyInformation
                    isLanguageLoaded={isLanguageLoaded}
                    backColor={"#06cccd"}
                    t={t}
                  />
                  <BenefitsNewMembers
                    isLanguageLoaded={isLanguageLoaded}
                    t={t}
                    i18n={i18n}
                  />
                  <YourBusinessBanner
                    isLanguageLoaded={isLanguageLoaded}
                    t={t}
                  />
                  <ShowKitEnabled
                    isLanguageLoaded={isLanguageLoaded}
                    t={t}
                    i18n={i18n}
                  />
                  <FormNewMembers
                    isLanguageLoaded={isLanguageLoaded}
                    t={t}
                    i18n={i18n}
                    isActiveFlotingPay={isActiveFlotingPay}
                    paypalStatusPay={paypalStatusPay}
                    wayToPay={wayToPay}
                    setWayToPay={setWayToPay}
                    setIsActiveFlotingPay={setIsActiveFlotingPay}
                    sponsorData={sponsorData}
                    vCardWebPageEntreprenourData={vCardWebPageEntreprenourData}
                    statesResult={statesResult}
                    selectedDate={selectedDate}
                    maxDate={maxDate}
                    handleDateChange={handleDateChange}
                    confirmPassword={confirmPassword}
                    setConfirmPassword={setConfirmPassword}
                    cellPhoneCode={cellPhoneCode}
                    setCellPhoneCode={setCellPhoneCode}
                    showVerfieldCellPhoneField={showVerfieldCellPhoneField}
                    setShowVerfieldCellPhoneField={
                      setShowVerfieldCellPhoneField
                    }
                    isEmailVerified={isEmailVerified}
                    emailMessageErrorServer={emailMessageErrorServer}
                    setIsEmailVerified={setIsEmailVerified}
                    setEmailMessageErrorServer={setEmailMessageErrorServer}
                    isCellPhoneVerified={isCellPhoneVerified}
                    setIsCellPhoneVerified={setIsCellPhoneVerified}
                    cellMessageErrorServer={cellMessageErrorServer}
                    setCellMessageErrorServer={setCellMessageErrorServer}
                    emailValidationOnBlur={emailValidationOnBlur}
                    cellPhoneValidationOnBlur={cellPhoneValidationOnBlur}
                    verifyCellPhoneCodeIsValid={verifyCellPhoneCodeIsValid}
                    identificationValidationOnBlur={
                      identificationValidationOnBlur
                    }
                    isIdentificationNssValid={isIdentificationNssValid}
                    setIsIdentificationNssValid={setIsIdentificationNssValid}
                    isIdentificationLicValid={isIdentificationLicValid}
                    setIsIdentificationLicValid={setIsIdentificationLicValid}
                    isIdentificationRfcValid={isIdentificationRfcValid}
                    setIsIdentificationRfcValid={setIsIdentificationRfcValid}
                    handleGetGenderByName={handleGetGenderByName}
                    isOpenAddressValidationModal={isOpenAddressValidationModal}
                    setIsOpenAddressValidationModal={
                      setIsOpenAddressValidationModal
                    }
                    addressListSugestion={addressListSugestion}
                    addressTypeToValidate={addressTypeToValidate}
                    handlerSelectAddressFromSugestion={
                      handlerSelectAddressFromSugestion
                    }
                    handleAddressOpenValidation={handleAddressOpenValidation}
                    isShipmentAddressVerified={isShipmentAddressVerified}
                    setIsShipmentAddressVerified={setIsShipmentAddressVerified}
                    isFiscalAddressVerified={isFiscalAddressVerified}
                    setIsFiscalAddressVerified={setIsFiscalAddressVerified}
                    useAsShippAddress={useAsShippAddress}
                    setUseAsShippAddress={setUseAsShippAddress}
                    setInitialDataPreregistration={
                      setInitialDataPreregistration
                    }
                    initialDataPreregistration={initialDataPreregistration}
                    checkAccept={checkAccept}
                    setCheckAccept={setCheckAccept}
                    setIsPayShow={setIsPayShow}
                    currentKitObject={currentKitObject}
                    setCurrentKitObject={setCurrentKitObject}
                    cardData={cardData}
                    setCardData={setCardData}
                    orderData={orderData}
                    handleKitAdmissionButtonClick={
                      handleKitAdmissionButtonClick
                    }
                    stepPay={stepPay}
                    setStepPay={setStepPay}
                    handleRegiterNewEntreprenour={handleRegiterNewEntreprenour}
                    handleOrderCreatorPaypal={handleOrderCreatorPaypal}
                    handleOrderAprovedPaypal={handleOrderAprovedPaypal}
                    isOpenValidationDataModal={isOpenValidationDataModal}
                    setIsOpenValidationDataModal={setIsOpenValidationDataModal}
                    userDataValidationErrors={userDataValidationErrors}
                  />
                  <FooterNewMembers t={t} isLanguageLoaded={isLanguageLoaded} />
                </Fragment>
              ) : (
                <PayKitNewMembers
                  isLanguageLoaded={isLanguageLoaded}
                  t={t}
                  setIsPayShow={setIsPayShow}
                />
              )}
            </div>
          </div>

          {/* SNACKBAR */}
          {/* <NotificationMessage
            open={snackbarType !== "" && snackbarMessage !== ""}
            autoHideDuration={2500}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            handleCloseSnackbar={handleCloseSnackbar}
            snackBarStyle={{ marginBottom: 20 }}
            alertType={snackbarType}
            alertMessage={snackbarMessage}
          /> */}

          {/* EXPIRED SESSION */}
          <div
            id="expired__session__overlay"
            className="expired__session__overlay"
          >
            {isLanguageLoaded ? (
              <p>{t("_Zusa_Session_Time_Experied_NewMember")}</p>
            ) : (
              <Skeleton variant="rect" width={200} height={30} />
            )}

            <button
              onClick={() => {
                document
                  .getElementById("expired__session__overlay")
                  .classList.remove("active");
                setIsResetSession(true);
              }}
              className="button--regular button--color--gold--outlined text--capitalize color--cookies border--cookies"
            >
              {isLanguageLoaded ? t("__ZUsa_NavBar_OptionCart_Continue") : ""}
            </button>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default NewMembersContainer;
