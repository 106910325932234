import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Tooltip,
  Typography,
  Select,
  Fab,
  Skeleton,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
//import useUnsavedChangesWarning from "./../../../Libraries/Utils/UnsavedChangesWarning"
import { RouterPrompt } from "./../../../Libraries/Utils/UnsavedChangesWarningP";
import { requestClientInformation } from "./../../ReduxSaga/Actions/Client";
import { ConfirmationNumber, Save } from "@mui/icons-material";
import { isTablet, isMobile } from "react-device-detect";
import { useSnackbar } from "notistack";
import {
  regexIsEmailAddressValid,
  regexIsCellphoneValid,
  restrictToOnlyLetters,
  restrictToOnlyLettersAndNumbers,
  restrictToOnlyLettersForEmail,
  restrictToOnlyNumbers,
  restrictToOnlyNumbersTwo,
} from "./../../../Libraries/Utils/regex";
import {
  requestTwilioCellPhoneValidation,
  requestTwilioCellPhoneCodeValidation,
} from "./../../ReduxSaga/Actions/Twilio";
import { requestAddressValidation } from "./../../ReduxSaga/Actions/Address";
import {
  requestClientUpdateInformation,
  cleanReducerClientUpdateInformation,
  requestCleanUploadImageReducer,
  requestUploadProfileImage,
  requestImmediateGains,
  requestCleanClientImmediateGainsReducer,
} from "./../../ReduxSaga/Actions/Client";
import { requestGetCartCouponList } from "./../../ReduxSaga/Actions/Cart";
import {
  requestStatesAndCities,
  requestLoggUserActivity,
} from "./../../ReduxSaga/Actions/General";
import ModalValidationAdress from "../base/components/ModalValidationAdress";
import ImageProfile from "./ImageProfile";
import Timer from "../base/components/Timer";
import ModalCoupons from "./components/ModalCoupons";
import TextFieldComponent from "../base/components/TextFieldComponent";
import TextFieldAndButtonComponent from "../base/components/TextFieldAndButtonComponent";
import ButtonComponent from "../base/components/ButtonComponent";

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#D39F46",
    color: "#fff",
    maxWidth: 350,
    fontSize: "12px",
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const StyledFabTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#4d6a7b",
  },
}))(Tooltip);

const UserProfileContainer = (props) => {
  //Notistack
  const { enqueueSnackbar } = useSnackbar();
  /// TRANSLATION
  const { t } = useTranslation();
  /// REDUX
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.twilio = useSelector((state) => state.twilio);
  appReducers.address = useSelector((state) => state.address);
  appReducers.client = useSelector((state) => state.client);
  appReducers.general = useSelector((state) => state.general);
  appReducers.cart = useSelector((state) => state.cart);

  ///WARNING
  //const usess = useUnsavedChangesWarning()

  /// HOOKS
  const [clientLogedinInformation, setClientLogedinInformation] = useState({});
  // const [dataNotification, setDataNotification] = useState({
  //   isOpen: false,
  //   type: "",
  //   message: "",
  //   icon: "",
  // });

  const [openAddressSugestion, setOpenAddressSugestion] = useState(false);
  const [addressListSugestion, setAddressListSugestion] = useState([]);
  const [shippAddressVerified, setShippAddressVerified] = useState(false);
  const [isCellphoneValidateFinal, setIsCellphoneValidateFinal] =
    useState(false);
  const [name, setName] = useState({
    emptyField: true,
    value: "",
    error: true,
  });
  const [lastName, setLastName] = useState({
    emptyField: true,
    value: "",
    error: true,
  });
  const [email, setEmail] = useState({
    emptyField: true,
    value: "",
    error: true,
  });
  const [cellPhone, setCellPhone] = useState({
    emptyField: false,
    value: "",
    error: false,
  });
  const [isCellPhoneValidated, setIsCellPhoneValidated] = useState({
    flag: false,
    message: "",
  });
  const [password, setPassword] = useState({
    emptyField: true,
    value: "",
    error: true,
  });
  const [passwordConfirmed, setPasswordConfirmed] = useState({
    emptyField: true,
    value: "",
    error: true,
  });
  const [shippStreet, setShippStreet] = useState({
    emptyField: true,
    value: "",
    error: true,
  });
  const [shippSuite, setShippSuite] = useState({
    emptyField: false,
    value: "",
    error: false,
  });
  const [shippState, setShippState] = useState({
    emptyField: true,
    value: "",
    error: true,
  });
  const [shippCity, setShippCity] = useState({
    emptyField: true,
    value: "",
    error: true,
  });
  const [shippZipCode, setShippZipCode] = useState({
    emptyField: true,
    value: "",
    error: true,
  });
  const [verificationCode, setVerificationCode] = useState("");
  const [isCellPhoneCodeValidated, setIsCellPhoneCodeValidated] =
    useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [isReturnActive, setIsReturnActive] = useState(false);
  const [isStartCountDown, setIsStartCountDown] = useState(false);
  const [isCountDown, setIsCountDown] = useState(0);
  const [isOpenCouponsModal, setIsOpenCouponsModal] = useState(false);
  const [couponList, setCouponList] = useState([]);
  const openTooltip = true;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [isCellPhoneEdit, setIsCellPhoneEdit] = useState(false);
  const [isPasswordEdit, setIsPasswordEdit] = useState(false);
  const [isAddressEdit, setIsAddressEdit] = useState(false);

  /// EFFECTS

  useEffect(() => {
    if (cellPhone.value.length > 0) {
      setIsCellphoneValidateFinal(true);
    } else {
      setIsCellphoneValidateFinal(false);
    }

    if (
      cellPhone.value.length >= 10 &&
      cellPhone.value !== clientLogedinInformation.telcell
    ) {
      setIsCellPhoneEdit(true);
      setIsCellPhoneCodeValidated(false);
    } else {
      setIsCellPhoneEdit(false);
      setIsCellPhoneCodeValidated(true);
    }
  }, [cellPhone.value.length]);

  useEffect(() => {
    if (
      shippStreet.value !== clientLogedinInformation.address ||
      shippSuite.value !== clientLogedinInformation.suite ||
      shippState.value !== clientLogedinInformation.state ||
      shippCity.value !== clientLogedinInformation.city ||
      shippZipCode.value !== clientLogedinInformation.zip
    ) {
      setIsAddressEdit(true);
      setShippAddressVerified(false);
    } else {
      setIsAddressEdit(false);
      setShippAddressVerified(true);
    }
  }, [
    shippZipCode.value?.length,
    shippCity.value?.length,
    shippState.value?.length,
    shippSuite.value?.length,
    shippStreet.value?.length,
  ]);

  useEffect(() => {
    if (
      password.value.length >= 8 &&
      password.value !== clientLogedinInformation.password
    ) {
      setIsPasswordEdit(true);
    } else {
      setIsPasswordEdit(false);
    }
  }, [password.value.length]);

  useEffect(() => {
    if (
      typeof appReducers?.client?.errorLoadClientLogedInformation !==
      "undefined"
    ) {
      if (!appReducers?.client?.errorLoadClientLogedInformation) {
        const clientInfo = appReducers?.client?.clientLogedinInformation;
        setClientLogedinInformation(clientInfo);
        setName({
          emptyField: clientInfo.name.length <= 0,
          error: clientInfo.name.length <= 0,
          value: clientInfo.name,
        });
        setLastName({
          emptyField: clientInfo.lastName.length <= 0,
          error: clientInfo.lastName.length <= 0,
          value: clientInfo.lastName,
        });
        setEmail({
          emptyField: clientInfo.email.length <= 0,
          error: clientInfo.email.length <= 0,
          value: clientInfo.email,
        });
        setCellPhone({
          emptyField: clientInfo.telcell.length <= 0,
          error: clientInfo.telcell.length <= 0,
          value: clientInfo.telcell,
        });
        setPassword({
          emptyField: clientInfo.password.length <= 0,
          error: clientInfo.password.length <= 0,
          value: clientInfo.password,
        });
        setShippStreet({
          emptyField: clientInfo.address.length <= 0,
          error: clientInfo.address.length <= 0,
          value: clientInfo.address.toUpperCase(),
        });
        setShippSuite({
          emptyField: false,
          error: false,
          value: clientInfo.suite.toUpperCase(),
        });
        setShippState({
          emptyField: clientInfo.state.length <= 0,
          error: clientInfo.state.length <= 0,
          value: clientInfo.state.toUpperCase(),
        });
        setShippCity({
          emptyField: clientInfo.city.length <= 0,
          error: clientInfo.city.length <= 0,
          value: clientInfo.city.toUpperCase(),
        });
        setShippZipCode({
          emptyField: clientInfo.zip.length <= 0,
          error: clientInfo.zip.length <= 0,
          value: clientInfo.zip,
        });
        setIsCellPhoneEdit(false);
        setIsCellPhoneCodeValidated(clientInfo.telcell.length > 0);
        setIsPasswordEdit(false);

        setShippAddressVerified(true);
        setIsAddressEdit(false);
      } else
        enqueueSnackbar(
          t("__myAccount_Profile_Information_GettingInfo_Error_Message"),
          {
            variant: "error",
          }
        );
      // setDataNotification({
      //   isOpen: true,
      //   type: "error",
      //   message: t(
      //     "__myAccount_Profile_Information_GettingInfo_Error_Message"
      //   ),
      // });
    } else {
      dispatch(requestClientInformation());
    }
  }, [
    appReducers?.client.errorLoadClientLogedInformation,
    appReducers?.client.clientLogedinInformation,
  ]);

  //Effect on Twilio CellPhone Validation
  useEffect(() => {
    if (
      typeof appReducers?.twilio?.errorTwilioCellValidation !== "undefined" ||
      typeof appReducers?.twilio?.code !== "undefined"
    )
      if (!appReducers?.twilio?.errorTwilioCellValidation) {
        // if(cellPhone.value !== clientLogedinInformation.telcell){
        //   setIsCellPhoneCodeValidated(false);
        // }
        setIsCellPhoneValidated({
          flag: true,
          message: t("__forms_twilio_cell_validation_success_message"),
        });
        enqueueSnackbar(t("__forms_twilio_cell_validation_success_message"), {
          variant: "success",
        });
        // setDataNotification({
        //   isOpen: true,
        //   type: "success",
        //   message: t("__forms_twilio_cell_validation_success_message"),
        // });
      } else {
        switch (appReducers?.twilio?.code) {
          case -100: //error de ejecucion
            setIsCellPhoneValidated({
              flag: false,
              message: t("__forms_twilio_cell_validation_gen_error"),
            });
            enqueueSnackbar(t("__forms_twilio_cell_validation_gen_error"), {
              variant: "error",
            });
            // setDataNotification({
            //   isOpen: true,
            //   type: "error",
            //   message: t("__forms_twilio_cell_validation_gen_error"),
            // });
            break;
          case -200: //error celular en bd
            setIsCellPhoneValidated({
              flag: false,
              message: t("__forms_twilio_cell_validation_db_error"),
            });
            enqueueSnackbar(t("__forms_twilio_cell_validation_db_error"), {
              variant: "error",
            });
            // setDataNotification({
            //   isOpen: true,
            //   type: "error",
            //   message: t("__forms_twilio_cell_validation_db_error"),
            // });
            break;
          case -300: //error celular no es un numero valido
            setIsCellPhoneValidated({
              flag: false,
              message: t("__forms_twilio_cell_validation_type_error"),
            });
            enqueueSnackbar(t("__forms_twilio_cell_validation_type_error"), {
              variant: "error",
            });
            // setDataNotification({
            //   isOpen: true,
            //   type: "error",
            //   message: t("__forms_twilio_cell_validation_type_error"),
            // });
            break;
          default:
            setIsCellPhoneValidated({
              flag: false,
              message: t("__forms_twilio_cell_validation_type_error"),
            });
            enqueueSnackbar(t("__forms_twilio_cell_validation_type_error"), {
              variant: "error",
            });
            // setDataNotification({
            //   isOpen: true,
            //   type: "error",
            //   message: t("__forms_twilio_cell_validation_type_error"),
            // });
            break;
        }
      }
  }, [appReducers?.twilio.errorTwilioCellValidation, appReducers?.twilio.code]);
  //Effect on Twilio CellPhone Code Send Validation
  useEffect(() => {
    if (
      typeof appReducers?.twilio?.errorTwilioCellCodeValidation !== "undefined"
    )
      if (appReducers?.twilio?.errorTwilioCellCodeValidation) {
        setIsCellPhoneCodeValidated(false);
        enqueueSnackbar(t("__forms_twilio_cell_val_code_error_message"), {
          variant: "error",
        });
        // setDataNotification({
        //   isOpen: true,
        //   type: "error",
        //   message: t("__forms_twilio_cell_val_code_error_message"),
        // });
      } else {
        setIsCellPhoneCodeValidated(true);
        enqueueSnackbar(t("__forms_twilio_cell_val_code_success_message"), {
          variant: "success",
        });
        // setDataNotification({
        //   isOpen: true,
        //   type: "success",
        //   message: t("__forms_twilio_cell_val_code_success_message"),
        // });
      }
  }, [appReducers?.twilio.errorTwilioCellCodeValidation]);
  //Effect on Address Validation
  useEffect(() => {
    if (
      typeof appReducers?.address?.errorClientAddressValidation !== "undefined"
    )
      if (appReducers?.address?.errorClientAddressValidation) {
        if (
          appReducers?.address?.addressSugestion &&
          appReducers?.address?.addressSugestion.length > 0
        ) {
          setOpenAddressSugestion(true);
          setAddressListSugestion(appReducers?.address?.addressSugestion);
        } else {
          enqueueSnackbar(
            t("__forms_address_verification_error_noData_message"),
            {
              variant: "error",
            }
          );
          // setDataNotification({
          //   isOpen: true,
          //   type: "error",
          //   message: t("__forms_address_verification_error_noData_message"),
          // });
          setShippAddressVerified(false);
          setOpenAddressSugestion(false);
          setAddressListSugestion([]);
        }
      } else {
        enqueueSnackbar(t("__forms_address_verification_success_message"), {
          variant: "success",
        });
        // setDataNotification({
        //   isOpen: true,
        //   type: "success",
        //   message: t("__forms_address_verification_success_message"),
        // });
        setOpenAddressSugestion(false);
        setAddressListSugestion([]);
        setShippAddressVerified(true);
      }
  }, [
    appReducers.address.errorClientAddressValidation,
    appReducers.address.addressSugestion,
  ]);
  // Effect on Loading States and Cities List
  useEffect(() => {
    if (typeof appReducers?.general?.errorLoadStatesAndCities !== "undefined") {
      if (!appReducers?.general?.errorLoadStatesAndCities) {
        if (
          appReducers?.general?.statesAndCities &&
          appReducers?.general?.statesAndCities.length > 0
        )
          setStates(appReducers?.general?.statesAndCities);
      } else {
        enqueueSnackbar(t("__forms_states_cities_error_code_behind"), {
          variant: "error",
        });
        // setDataNotification({
        //   isOpen: true,
        //   type: "error",
        //   message: t("__forms_states_cities_error_code_behind"),
        // });
      }
    } else dispatch(requestStatesAndCities());
  }, [
    appReducers?.general.errorLoadStatesAndCities,
    appReducers?.general.statesAndCities,
  ]);
  //Effect set cities selecting a state
  useEffect(() => {
    if (typeof states.length && states.length > 0)
      if (shippState?.value) {
        const stateSelected = states?.find((s) => s.state === shippState.value);
        if (stateSelected !== undefined) {
          setCities(stateSelected.cities);
        }
      }
  }, [states, shippState?.value]);
  //Effect on client information update
  useEffect(() => {
    if (
      typeof appReducers?.client?.errorClientUpdateInformation !== "undefined"
    ) {
      if (appReducers?.client?.errorClientUpdateInformation) {
        //error
        enqueueSnackbar(
          t("__myAccount_Profile_Information_Error_Update_Message"),
          {
            variant: "error",
          }
        );
        // setDataNotification({
        //   isOpen: true,
        //   type: "error",
        //   message: t("__myAccount_Profile_Information_Error_Update_Message"),
        // });
        dispatch(
          requestLoggUserActivity(
            6,
            0,
            0,
            "",
            "Se realizo Cambio en la Informacion Personal"
          )
        );
        props?.handleGAEvent(
          "User",
          "Usuario Realizo Cambio En Su Informacion Personal"
        );
      } else {
        enqueueSnackbar(
          t("__myAccount_Profile_Information_Success_Update_Message"),
          {
            variant: "success",
          }
        );
        // setDataNotification({
        //   isOpen: true,
        //   type: "success",
        //   message: t("__myAccount_Profile_Information_Success_Update_Message"),
        // });
        dispatch(cleanReducerClientUpdateInformation());
        dispatch(requestClientInformation());
      }
    }
  }, [appReducers?.client.errorClientUpdateInformation]);
  //Effect on Upload Image
  useEffect(() => {
    if (
      typeof appReducers?.client?.uploadProfileImageResponse !== "undefined"
    ) {
      if (appReducers.client.uploadProfileImageResponse.code === 100) {
        props.downloadProfileImageValidation();
        enqueueSnackbar(t("__message_image_update_profile_label"), {
          variant: "success",
        });
        dispatch(
          requestLoggUserActivity(4, 0, 0, "", "Se realizo Cambio de imagen")
        );
        props?.handleGAEvent(
          "User",
          "Usuario Realizo Cambio En Su Imagen De Perfil"
        );
      } else {
        enqueueSnackbar(t("__message_error_avatar_profile_label"), {
          variant: "error",
        });
        // setDataNotification({
        //   isOpen: true,
        //   type: "error",
        //   message: t("__message_error_avatar_profile_label"),
        // });
      }
      dispatch(requestCleanUploadImageReducer());
    }
  }, [appReducers?.client.uploadProfileImageResponse]);
  //effect on cupons
  useEffect(() => {
    if (typeof appReducers.cart?.cartCouponList !== "undefined") {
      const clist = appReducers?.cart?.cartCouponList;
      if (clist.code === 100) {
        setCouponList(clist.couponList);
      }
    } else {
      dispatch(requestGetCartCouponList());
    }
  }, [appReducers?.cart.cartCouponList]);

  useEffect(() => {
    if (
      typeof appReducers?.client?.requestImmediateGainsResponse !== "undefined"
    ) {
      if (appReducers.client.requestImmediateGainsResponse.code === 100) {
        enqueueSnackbar(t("__myAccount_Profile_Message_Confirm_Label"), {
          variant: "success",
        });
        // setDataNotification({
        //   isOpen: true,
        //   type: "success",
        //   message: t(t("__myAccount_Profile_Message_Confirm_Label")),
        // });
      } else {
        enqueueSnackbar(t("__myAccount_Profile_Message_error_Label"), {
          variant: "error",
        });
        // setDataNotification({
        //   isOpen: true,
        //   type: "error",
        //   message: t("__myAccount_Profile_Message_error_Label"),
        // });
      }
      setIsOpenCouponsModal(false);
      dispatch(requestCleanClientImmediateGainsReducer());
      dispatch(requestGetCartCouponList());
    }
  }, [appReducers?.client.requestImmediateGainsResponse]);

  /// HANDLERS

  const handleCodeSenderVerdication = () => {
    dispatch(
      requestTwilioCellPhoneCodeValidation(cellPhone.value, verificationCode)
    );
  };
  const handleResendSmsCode = (event) => {
    setIsStartCountDown(true);
    setIsCountDown(60 * 5);
    setIsReturnActive(false);
    event.preventDefault();
    dispatch(requestTwilioCellPhoneValidation(cellPhone.value, true));
  };
  const handleValidateCellPhoneClient = () => {
    if (cellPhone.value.length === 10)
      dispatch(requestTwilioCellPhoneValidation(cellPhone.value, true));
    setIsStartCountDown(true);
  };
  const handleSendAddressToVerify = () => {
    dispatch(
      requestAddressValidation(
        shippStreet.value,
        shippSuite.value,
        shippState.value,
        shippCity.value,
        shippZipCode.value,
        "e",
        false
      )
    );
  };
  const handlerSelectAddressFromSugestion = (
    street,
    suite,
    state,
    city,
    zipCode
  ) => {
    setShippStreet({
      emptyField: street.length <= 0,
      error: street.length <= 0,
      value: street.toUpperCase(),
    });
    setShippSuite({
      emptyField: false,
      error: false,
      value: suite.toUpperCase(),
    });
    setShippState({
      emptyField: state.length <= 0,
      error: state.length <= 0,
      value: state.toUpperCase(),
    });
    setShippCity({
      emptyField: city.length <= 0,
      error: city.length <= 0,
      value: city.toUpperCase(),
    });
    setShippZipCode({
      emptyField: zipCode.length <= 0,
      error: zipCode.length <= 0,
      value: zipCode,
    });
    enqueueSnackbar(t("__forms_address_verification_success_message"), {
      variant: "success",
    });
    // setDataNotification({
    //   isOpen: true,
    //   type: "success",
    //   message: t("__forms_address_verification_success_message"),
    // });
    setOpenAddressSugestion(false);
    setShippAddressVerified(true);
  };
  const handleUpdatePersonalInformation = () => {
    dispatch(
      requestClientUpdateInformation(
        name.value,
        lastName.value,
        email.value,
        cellPhone.value,
        password.value,
        shippStreet.value,
        shippSuite.value,
        shippState.value,
        shippCity.value,
        shippZipCode.value
      )
    );
  };
  const handleUploadImage = (data, fileName) => {
    dispatch(requestUploadProfileImage(data, fileName));
  };

  const handleOpenCouponsModal = () => {
    setIsOpenCouponsModal(true);
  };
  const handleCloseCouponsModal = () => {
    setIsOpenCouponsModal(false);
  };
  const handleRequestImmediateGains = (paypalEmail, amount) => {
    dispatch(requestImmediateGains(amount, paypalEmail));
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div
      id="firstWrapperInBaseContainer"
      className="firstWrapperInBaseContainer"
    >
      {/* {usess.routerPrompt} */}

      <RouterPrompt
        onBlock={
          !(
            name?.value.length === 0 ||
            lastName?.value.length === 0 ||
            email?.value.length === 0 ||
            cellPhone?.value.length === 0 ||
            (isCellPhoneEdit
              ? isCellPhoneCodeValidated
                ? false
                : true
              : cellPhone?.value.length >= 10
              ? false
              : true) ||
            password?.value.length === 0 ||
            shippStreet?.value.length === 0 ||
            shippZipCode.value.length === 0 ||
            shippState.value.length === 0 ||
            shippCity.value.length === 0 ||
            (isPasswordEdit
              ? passwordConfirmed.value === password.value
                ? false
                : true
              : false) ||
            (name.value === clientLogedinInformation.name &&
              lastName.value === clientLogedinInformation.lastName &&
              email.value === clientLogedinInformation.email &&
              cellPhone.value === clientLogedinInformation.telcell &&
              password.value === clientLogedinInformation.password &&
              (isAddressEdit ? (shippAddressVerified ? false : true) : true))
          )
        }
        title={t("__myAccount_Profile_Message_Alert_Title_Unsaved_Changes")}
        message={t("__myAccount_Profile_Message_Alert_Message_Unsaved_Changes")}
        cancelText={t("btn_text_cancel")}
        okText={t("btn_text_continue")}
        onOK={() => true}
        onCancel={() => false}
      />

      {/* <BreadcrumbsNavigationComponet /> */}
      {/* <NotificationSnackbar
        isOpenNotification={dataNotification.isOpen}
        setIsOpenNotification={setDataNotification}
        message={dataNotification.message}
        status={dataNotification.type}
        icon={dataNotification.icon}
      /> */}
      {openAddressSugestion && (
        <ModalValidationAdress
          isOpenValidationModal={openAddressSugestion}
          setIsOpenValidationModal={setOpenAddressSugestion}
          addressListSugestion={addressListSugestion}
          handlerSelectAddressFromSugestion={handlerSelectAddressFromSugestion}
        />
      )}

      <ModalCoupons
        isOpenCouponsModal={isOpenCouponsModal}
        handleCloseCouponsModal={handleCloseCouponsModal}
        couponList={couponList}
        handleRequestImmediateGains={handleRequestImmediateGains}
      />

      {couponList?.length > 0 && (
        <div
          className={
            isMobile
              ? "user__profile__wrapper__coupons__mobile"
              : "user__profile__wrapper__coupons"
          }
        >
          <StyledFabTooltip
            placement="top-start"
            title={
              props.isLanguageLoaded
                ? t("__myAccount_Profile_Information_My_Coupons_Label")
                : ""
            }
            open={openTooltip}
          >
            <Fab
              color="primary"
              aria-label="coupons"
              onClick={handleOpenCouponsModal}
            >
              <ConfirmationNumber />
            </Fab>
          </StyledFabTooltip>
        </div>
      )}
      <div className="user__profile__wrapper">
        {clientLogedinInformation?.hasOwnProperty("name") && (
          <Fragment>
            <div className="user__profile__wrapper__title">
              {props.isLanguageLoaded ? (
                <Typography variant="h6" gutterBottom className="text__red">
                  {t("__myAccount_Profile_Information_Data_Title")}
                </Typography>
              ) : (
                <Skeleton variant="rect" width={"20%"} height={30} />
              )}
            </div>

            <div className="user__profile__wrapper__image">
              <ImageProfile
                handleUploadImage={handleUploadImage}
                isProfileImageLoaded={props.isProfileImageLoaded}
                imageProfileUrl={props.imageProfileUrl}
                name={`${name.value} ${lastName.value}`}
              />
            </div>

            <div
              className={`user__profile__wrapper__content ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              <div
                className={`user__profile__wrapper__content__user ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                <div className="user__profile__wrapper__content__user__left">
                  <TextFieldComponent
                    labelName={
                      props.isLanguageLoaded
                        ? t("__myAccount_Profile_Information_Txt_Name_Label")
                        : ""
                    }
                    typeInput="text"
                    valueInput={name.value}
                    erroHelp={
                      name.error
                        ? name.emptyField
                          ? props.isLanguageLoaded
                            ? t("__forms_empty_field_error_message")
                            : ""
                          : ""
                        : ""
                    }
                    isActiveHelp={name.value.length <= 0}
                    textareaEnabled={false}
                    setValue={(e) => {
                      restrictToOnlyLetters(e);
                      setName({
                        emptyField: e.target.value.length <= 0,
                        error: e.target.value.length <= 0,
                        value: e.target.value.toUpperCase(),
                      });
                    }}
                    onInput={(e) => {
                      restrictToOnlyLetters(e);
                      setName({
                        emptyField: e.target.value.length <= 0,
                        error: e.target.value.length <= 0,
                        value: e.target.value.toUpperCase(),
                      });
                    }}
                  />
                  <TextFieldComponent
                    labelName={
                      props.isLanguageLoaded
                        ? t(
                            "__myAccount_Profile_Information_Txt_LastName_Label"
                          )
                        : ""
                    }
                    typeInput="text"
                    valueInput={lastName.value}
                    erroHelp={
                      lastName.error
                        ? lastName.emptyField
                          ? props.isLanguageLoaded
                            ? t("__forms_empty_field_error_message")
                            : ""
                          : ""
                        : ""
                    }
                    isActiveHelp={lastName.value.length <= 0}
                    textareaEnabled={false}
                    setValue={(e) => {
                      restrictToOnlyLetters(e);
                      setLastName({
                        emptyField: e.target.value.length <= 0,
                        error: e.target.value.length <= 0,
                        value: e.target.value.toUpperCase(),
                      });
                    }}
                    onInput={(e) => {
                      restrictToOnlyLetters(e);
                      setLastName({
                        emptyField: e.target.value.length <= 0,
                        error: e.target.value.length <= 0,
                        value: e.target.value.toUpperCase(),
                      });
                    }}
                  />

                  <TextFieldComponent
                    labelName={
                      props.isLanguageLoaded
                        ? t("__myAccount_Profile_Information_Txt_Email_Label")
                        : ""
                    }
                    typeInput="email"
                    valueInput={email.value}
                    erroHelp={
                      email.error
                        ? email.emptyField
                          ? props.isLanguageLoaded
                            ? t("__forms_empty_field_error_message")
                            : ""
                          : email.error
                          ? props.isLanguageLoaded
                            ? t(
                                "__forms_field_email_validation_js_error_message"
                              )
                            : ""
                          : ""
                        : ""
                    }
                    placeholderInput={
                      props.isLanguageLoaded
                        ? t("__myAccount_Profile_Information_Txt_Email_Label")
                        : ""
                    }
                    isActiveHelp={
                      //email.length === 0 ||
                      !regexIsEmailAddressValid(email.value)
                    }
                    textareaEnabled={false}
                    setValue={(e) => {
                      if (!regexIsEmailAddressValid(e.target.value)) {
                        setEmail({
                          error: true,
                          emptyField: e.target.value.length <= 0,
                          value: e.target.value,
                        });
                      }
                    }}
                    onInput={(e) => {
                      restrictToOnlyLettersForEmail(e);
                      setEmail({
                        error: e.target.value.length <= 0,
                        emptyField: e.target.value.length <= 0,
                        value: e.target.value,
                      });
                    }}
                    onBlur={(e) => {
                      if (!regexIsEmailAddressValid(e.target.value)) {
                        setEmail({
                          error: true,
                          emptyField: e.target.value.length <= 0,
                          value: e.target.value,
                        });
                      }
                    }}
                  />
                </div>

                <div
                  id="user__profile__wrapper__content__user__right"
                  className="user__profile__wrapper__content__user__right"
                >
                  <TextFieldAndButtonComponent
                    labelName={
                      props.isLanguageLoaded
                        ? t("__login_Create_Account_Txt_CellPhone_Label")
                        : ""
                    }
                    placeholderInput={t(
                      "__login_Create_Account_Txt_CellPhone_Label"
                    )}
                    erroHelp={
                      cellPhone.error && !isCellPhoneValidated.flag
                        ? cellPhone.emptyField
                          ? props.isLanguageLoaded
                            ? t("__forms_empty_field_error_message")
                            : ""
                          : cellPhone.error
                          ? props.isLanguageLoaded
                            ? t(
                                "__forms_field_cellPhone_validation_js_error_message"
                              )
                            : ""
                          : !isCellPhoneValidated.flag
                          ? isCellPhoneValidated.message
                          : ""
                        : cellPhone.value.length < 0
                        ? t("__forms_empty_field_error_message")
                        : ""
                    }
                    isActiveHelp={
                      cellPhone.error ||
                      !isCellPhoneValidated.flag ||
                      cellPhone.value.length < 0
                    }
                    valueInput={cellPhone.value}
                    typeInput="tel"
                    setValue={(e) => {
                      restrictToOnlyNumbers(e);
                      setCellPhone({ ...cellPhone, value: e.target.value });
                    }}
                    iconClass={
                      cellPhone.value.length < 10 ||
                      isCellPhoneValidated.flag ||
                      cellPhone.value === clientLogedinInformation.telcell
                        ? "cellphoneGrayNewIcon "
                        : "cellphoneOutlineRed "
                    }
                    disabledButton={
                      cellPhone.value.length < 10 ||
                      isCellPhoneValidated.flag ||
                      cellPhone.value === clientLogedinInformation.telcell
                        ? true
                        : false
                    }
                    onInput={(e) => {
                      restrictToOnlyNumbers(e);
                      setCellPhone({ ...cellPhone, value: e.target.value });
                    }}
                    onBlur={(e) => {
                      if (!regexIsCellphoneValid(e.target.value)) {
                        setCellPhone({
                          ...cellPhone,
                          error: true,
                          value: e.target.value,
                        });
                      }
                    }}
                    handleEventButton={() => {
                      if (cellPhone.value.length === 10) {
                        handleValidateCellPhoneClient();
                      }
                    }}
                  />
                  <TextFieldAndButtonComponent
                    labelName={
                      props.isLanguageLoaded
                        ? t(
                            "__myAccount_Profile_Information_Txt_Password_Label"
                          )
                        : ""
                    }
                    disabledButton={false}
                    placeholderInput="********"
                    erroHelp={
                      password.error
                        ? password.emptyField
                          ? props.isLanguageLoaded
                            ? t("__forms_empty_field_error_message")
                            : ""
                          : password.error
                          ? props.isLanguageLoaded
                            ? t(
                                "__login_Create_Account_Txt_Password_Error_To_Short"
                              )
                            : ""
                          : password.value.length > 0 && ""
                        : ""
                    }
                    isActiveHelp={password.value.length < 0}
                    valueInput={password.value}
                    typeInput={showPassword ? "text" : "password"}
                    setValue={(e) => {
                      setPassword({
                        emptyField: e.target.value.length <= 0,
                        error: e.target.value.length < 8,
                        value: e.target.value,
                      });
                    }}
                    iconClass={"eyeRedOnlyIcon"}
                    handleEventButton={() => {
                      handleClickShowPassword();
                    }}
                    onInput={(e) => {
                      setPassword({
                        emptyField: e.target.value.length <= 0,
                        error: e.target.value.length < 8,
                        value: e.target.value,
                      });
                    }}
                  />
                  {isPasswordEdit && (
                    <TextFieldAndButtonComponent
                      labelName={
                        props.isLanguageLoaded
                          ? t(
                              "__login_Create_Account_Txt_ConfirmPassword_Label"
                            )
                          : ""
                      }
                      disabledButton={false}
                      placeholderInput="********"
                      erroHelp={
                        passwordConfirmed.error ||
                        passwordConfirmed.value !== password.value
                          ? passwordConfirmed.emptyField &&
                            passwordConfirmed.error
                            ? props.isLanguageLoaded
                              ? t("__forms_empty_field_error_message")
                              : ""
                            : passwordConfirmed.value !== password.value
                            ? props.isLanguageLoaded
                              ? t(
                                  "__forms_field_passwordConfirmed_NotEqual_validation_js_error_message"
                                )
                              : ""
                            : ""
                          : ""
                      }
                      isActiveHelp={passwordConfirmed.value.length < 0}
                      valueInput={passwordConfirmed.value}
                      typeInput={showConfirmPassword ? "text" : "password"}
                      setValue={(e) => {
                        setPasswordConfirmed({
                          emptyField: e.target.value.length <= 0,
                          error: e.target.value.length <= 8,
                          value: e.target.value,
                        });
                      }}
                      iconClass={"eyeRedOnlyIcon"}
                      handleEventButton={() => {
                        handleClickShowConfirmPassword();
                      }}
                      onInput={(e) => {
                        setPasswordConfirmed({
                          emptyField: e.target.value.length <= 0,
                          error: e.target.value.length <= 8,
                          value: e.target.value,
                        });
                      }}
                    />
                  )}

                  {isCellPhoneValidated.flag && !isCellPhoneCodeValidated && (
                    <div className="user__profile__wrapper__content__item__code">
                      <div className="user__profile__wrapper__content__item__code__up">
                        <div className="user__profile__wrapper__content__item__code__up__left">
                          <TextFieldComponent
                            labelName={
                              props.isLanguageLoaded
                                ? t(
                                    "__myAccount_Profile_Information_Txt_CellPhone_Code_Validation_Label"
                                  )
                                : ""
                            }
                            maxLength={6}
                            typeInput={isTablet || isMobile ? "number" : "text"}
                            valueInput={verificationCode}
                            erroHelp={verificationCode.length < 6 ? t("") : ""}
                            isActiveHelp={verificationCode.length !== 6}
                            textareaEnabled={false}
                            setValue={(e) => {
                              restrictToOnlyNumbersTwo(e, 6);
                              setVerificationCode(e.target.value);
                            }}
                            onInput={(e) => {
                              restrictToOnlyNumbersTwo(e, 6);
                              setVerificationCode(e.target.value);
                            }}
                          />
                        </div>
                        <div className="user__profile__wrapper__content__item__code__right">
                          {isCellPhoneValidated.flag && (
                            <Timer
                              setIsReturnActive={setIsReturnActive}
                              isStartCountDown={isStartCountDown}
                              setIsStartCountDown={setIsStartCountDown}
                              isCountDown={isCountDown}
                              setIsCountDown={setIsCountDown}
                            />
                          )}
                        </div>
                      </div>
                      <div className="user__profile__wrapper__content__item__code__down">
                        {!isReturnActive ? (
                          <div className=" new__members__form__left__valid">
                            <StyledTooltip
                              title={
                                props.isLanguageLoaded
                                  ? t(
                                      "__checkout_order_steps_pay_register_validate"
                                    )
                                  : ""
                              }
                            >
                              <ButtonComponent
                                disabledPrimary={verificationCode.length < 6}
                                countButton={false}
                                handleActionPrimary={() => {
                                  handleCodeSenderVerdication();
                                }}
                                handleActionSecondary={() => {}}
                                valueCount=""
                                iconClass={
                                  verificationCode.length < 6
                                    ? "cellphone__disabled"
                                    : "cellphone__enabled"
                                }
                                textButton={
                                  props.isLanguageLoaded
                                    ? t(
                                        "__new_members_kits_form_validate_code_label"
                                      )
                                    : ""
                                }
                                leftAling={true}
                              />
                            </StyledTooltip>
                          </div>
                        ) : (
                          <div className="new__members__form__left__valid">
                            {isReturnActive && !isStartCountDown && (
                              <ButtonComponent
                                countButton={false}
                                handleActionPrimary={() => {
                                  handleResendSmsCode();
                                }}
                                handleActionSecondary={() => {}}
                                valueCount=""
                                iconClass={"cellphone__enabled"}
                                textButton={
                                  props.isLanguageLoaded
                                    ? t("__timer_btn_code_is_forwarded")
                                    : ""
                                }
                                leftAling={true}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="user__profile__wrapper__subtitle">
              <Typography variant="h6" gutterBottom className="text__red">
                {props.isLanguageLoaded ? (
                  t("__myAccount_Profile_Information_Fiscal_Address_Title")
                ) : (
                  <Skeleton variant="rect" width={"20%"} height={30} />
                )}
              </Typography>
            </div>
            {/* direccion */}
            <div
              className={`user__profile__wrapper__content ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              <div
                className={`user__profile__wrapper__content__user ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                <div className="user__profile__wrapper__content__user__left">
                  <TextFieldComponent
                    labelName={
                      props.isLanguageLoaded
                        ? t(
                            "__myAccount_Profile_Information_Txt_ShippAddress_Street_Label"
                          )
                        : ""
                    }
                    typeInput="text"
                    valueInput={shippStreet.value}
                    erroHelp={
                      shippStreet.error
                        ? shippStreet.emptyField
                          ? props.isLanguageLoaded
                            ? t("__forms_empty_field_error_message")
                            : ""
                          : ""
                        : ""
                    }
                    isActiveHelp={shippStreet.value.length <= 0}
                    textareaEnabled={false}
                    setValue={(e) => {
                      restrictToOnlyLettersAndNumbers(e);
                      setShippStreet({
                        emptyField: e.target.value.length <= 0,
                        error: e.target.value.length <= 0,
                        value: e.target.value.toUpperCase(),
                      });
                    }}
                    onInput={(e) => {
                      restrictToOnlyLettersAndNumbers(e);
                      setShippStreet({
                        emptyField: e.target.value.length <= 0,
                        error: e.target.value.length <= 0,
                        value: e.target.value.toUpperCase(),
                      });
                    }}
                  />
                  <TextFieldComponent
                    labelName={
                      props.isLanguageLoaded
                        ? t(
                            "__myAccount_Profile_Information_Txt_ShippAddress_Suite_Label"
                          )
                        : ""
                    }
                    typeInput="text"
                    valueInput={shippSuite.value}
                    erroHelp={
                      shippSuite.error
                        ? shippSuite.emptyField
                          ? props.isLanguageLoaded
                            ? t("__forms_empty_field_error_message")
                            : ""
                          : ""
                        : ""
                    }
                    isActiveHelp={shippSuite.value.length <= 0}
                    textareaEnabled={false}
                    setValue={(e) => {
                      restrictToOnlyLettersAndNumbers(e);
                      setShippSuite({
                        emptyField: false,
                        error: false,
                        value: e.target.value.toUpperCase(),
                      });
                    }}
                    onInput={(e) => {
                      restrictToOnlyLettersAndNumbers(e);
                      setShippSuite({
                        emptyField: false,
                        error: false,
                        value: e.target.value.toUpperCase(),
                      });
                    }}
                  />
                  <div className="mui__select__wrapper">
                    <label id="lbl_select_states">
                      {props.isLanguageLoaded
                        ? t(
                            "__login_Create_Account_Txt_ShippAddress_State_Label"
                          )
                        : ""}
                    </label>
                    <FormControl
                      variant="standard"
                      required
                      fullWidth
                      error={shippState.error}
                    >
                      {/* <InputLabel id="lbl_select_states" variant="standard">
                        {props.isLanguageLoaded
                          ? t(
                              "__login_Create_Account_Txt_ShippAddress_State_Label"
                            )
                          : ""}
                      </InputLabel> */}
                      <Select
                        labelId="lbl_select_states"
                        value={shippState.value}
                        onChange={(e) => {
                          setShippState({
                            emptyField: e.target.value.length <= 0,
                            error: e.target.value.length <= 0,
                            value: e.target.value.toUpperCase(),
                          });
                        }}
                      >
                        {states.map((item, index) => (
                          <MenuItem key={index} value={item.state}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {shippState.emptyField
                          ? props.isLanguageLoaded
                            ? t("__forms_empty_field_error_message")
                            : ""
                          : ""}
                      </FormHelperText>
                    </FormControl>
                  </div>
                </div>

                <div className="user__profile__wrapper__content__user__right">
                  <TextFieldComponent
                    labelName={
                      props.isLanguageLoaded
                        ? t(
                            "__login_Create_Account_Txt_ShippAddress_City_Label"
                          )
                        : ""
                    }
                    placeholderInput={t(
                      "__login_Create_Account_Txt_ShippAddress_City_Label"
                    )}
                    erroHelp={
                      shippCity.emptyField
                        ? props.isLanguageLoaded
                          ? t("__forms_empty_field_error_message")
                          : ""
                        : ""
                    }
                    isActiveHelp={shippCity.error}
                    valueInput={shippCity.value}
                    typeInput="text"
                    setValue={(e) => {
                      setShippCity({
                        emptyField: false,
                        error: false,
                        value: restrictToOnlyLetters(e).toUpperCase(),
                      });
                    }}
                    onInput={(e) => {
                      setShippCity({
                        emptyField: false,
                        error: false,
                        value: restrictToOnlyLetters(e).toUpperCase(),
                      });
                    }}
                  />
                  <TextFieldComponent
                    labelName={
                      props.isLanguageLoaded
                        ? t("__login_Create_Account_Txt_ShippAddress_Zip_Label")
                        : ""
                    }
                    maxLength={5}
                    typeInput={isMobile || isTablet ? "number" : "text"}
                    valueInput={shippZipCode.value}
                    erroHelp={
                      shippZipCode.emptyField
                        ? props.isLanguageLoaded
                          ? t("__forms_empty_field_error_message")
                          : ""
                        : ""
                    }
                    isActiveHelp={shippZipCode.error}
                    textareaEnabled={false}
                    setValue={(e) => {
                      restrictToOnlyNumbers(e);
                      setShippZipCode({
                        emptyField: e.target.value.length <= 0,
                        error: e.target.value.length <= 0,
                        value: e.target.value,
                      });
                    }}
                    onInput={(e) => {
                      restrictToOnlyNumbers(e);
                      setShippZipCode({
                        emptyField: e.target.value.length <= 0,
                        error: e.target.value.length <= 0,
                        value: e.target.value,
                      });
                    }}
                  />
                  <div
                    className={
                      shippStreet.value.length <= 0 ||
                      shippState.value.length <= 0 ||
                      shippCity.value.length <= 0 ||
                      shippZipCode.value.length !== 5 ||
                      (shippStreet.value === clientLogedinInformation.address &&
                        shippSuite.value === clientLogedinInformation.suite &&
                        shippState.value === clientLogedinInformation.state &&
                        shippCity.value === clientLogedinInformation.city &&
                        shippZipCode.value === clientLogedinInformation.zip)
                        ? "user__profile__wrapper__address__button__disabled"
                        : "user__profile__wrapper__address__button "
                    }
                  >
                    <Button
                      onClick={() => handleSendAddressToVerify()}
                      disabled={
                        shippStreet.value.length <= 0 ||
                        shippState.value.length <= 0 ||
                        shippCity.value.length <= 0 ||
                        shippZipCode.value.length !== 5 ||
                        (shippStreet.value ===
                          clientLogedinInformation.address &&
                          shippSuite.value === clientLogedinInformation.suite &&
                          shippState.value === clientLogedinInformation.state &&
                          shippCity.value === clientLogedinInformation.city &&
                          shippZipCode.value === clientLogedinInformation.zip)
                      }
                      className={
                        shippStreet.value.length <= 0 ||
                        shippState.value.length <= 0 ||
                        shippCity.value.length <= 0 ||
                        shippZipCode.value.length !== 5 ||
                        (shippStreet.value ===
                          clientLogedinInformation.address &&
                          shippSuite.value === clientLogedinInformation.suite &&
                          shippState.value === clientLogedinInformation.state &&
                          shippCity.value === clientLogedinInformation.city &&
                          shippZipCode.value === clientLogedinInformation.zip &&
                          shippAddressVerified)
                          ? "button__with__icon bg__dark__pink no__left__margin"
                          : "button__with__icon bg__dark__pink no__left__margin button__click__me"
                      }
                    >
                      <div className="button__with__icon__svg__wrapper dark__pink__ball">
                        <div
                          className={
                            shippStreet.value.length <= 0 ||
                            shippState.value.length <= 0 ||
                            shippCity.value.length <= 0 ||
                            shippZipCode.value.length !== 5 ||
                            (shippStreet.value ===
                              clientLogedinInformation.address &&
                              shippSuite.value ===
                                clientLogedinInformation.suite &&
                              shippState.value ===
                                clientLogedinInformation.state &&
                              shippCity.value ===
                                clientLogedinInformation.city &&
                              shippZipCode.value ===
                                clientLogedinInformation.zip &&
                              shippAddressVerified)
                              ? "doorGrayIcon"
                              : "doorWhiteIcon"
                          }
                        />
                      </div>
                      <span>
                        {props.isLanguageLoaded
                          ? t(
                              "__checkout_order_steps_pay_register_shipping_Address"
                            )
                          : ""}
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="user__profile__wrapper__content__button">
              <Button
                onClick={() => handleUpdatePersonalInformation()}
                disabled={
                  //true  //bloqueado
                  //false //desbloqueado
                  name?.value.length === 0 ||
                  lastName?.value.length === 0 ||
                  email?.value.length === 0 ||
                  cellPhone?.value.length === 0 ||
                  (isCellPhoneEdit
                    ? isCellPhoneCodeValidated
                      ? false
                      : true
                    : cellPhone?.value.length >= 10
                    ? false
                    : true) ||
                  password?.value.length === 0 ||
                  shippStreet?.value.length === 0 ||
                  shippZipCode.value.length === 0 ||
                  shippState.value.length === 0 ||
                  shippCity.value.length === 0 ||
                  (isPasswordEdit
                    ? passwordConfirmed.value === password.value
                      ? false
                      : true
                    : false) ||
                  (name.value === clientLogedinInformation.name &&
                    lastName.value === clientLogedinInformation.lastName &&
                    email.value === clientLogedinInformation.email &&
                    cellPhone.value === clientLogedinInformation.telcell &&
                    password.value === clientLogedinInformation.password &&
                    (isAddressEdit
                      ? shippAddressVerified
                        ? false
                        : true
                      : true))

                  /*
                    (name.value === clientLogedinInformation.name &&
                      lastName.value === clientLogedinInformation.lastName &&
                      email.value === clientLogedinInformation.email &&
                      password.value === clientLogedinInformation.password &&
                      passwordConfirmed.error) ||
                    (passwordConfirmed.value !== password.value &&
                      isCellphoneValidateFinal &&
                      !shippAddressVerified)
                    */
                }
                className="button__with__icon bg__dark__pink no__left__margin"
              >
                <div className="button__with__icon__svg__wrapper dark__pink__ball">
                  <Save
                    className={
                      name?.value.length === 0 ||
                      lastName?.value.length === 0 ||
                      email?.value.length === 0 ||
                      cellPhone?.value.length === 0 ||
                      (isCellPhoneEdit
                        ? isCellPhoneCodeValidated
                          ? false
                          : true
                        : cellPhone?.value.length >= 10
                        ? false
                        : true) ||
                      password?.value.length === 0 ||
                      shippStreet?.value.length === 0 ||
                      shippZipCode.value.length === 0 ||
                      shippState.value.length === 0 ||
                      shippCity.value.length === 0 ||
                      (isPasswordEdit
                        ? passwordConfirmed.value === password.value
                          ? false
                          : true
                        : false) ||
                      (name.value === clientLogedinInformation.name &&
                        lastName.value === clientLogedinInformation.lastName &&
                        email.value === clientLogedinInformation.email &&
                        cellPhone.value === clientLogedinInformation.telcell &&
                        password.value === clientLogedinInformation.password &&
                        (isAddressEdit
                          ? shippAddressVerified
                            ? false
                            : true
                          : true))
                        ? "text__gray"
                        : "text__white"
                    }
                  />
                </div>
                <span>
                  {props.isLanguageLoaded
                    ? t("btn_text_update_data_profile").toUpperCase()
                    : ""}
                </span>
              </Button>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default UserProfileContainer;
