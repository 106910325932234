import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@mui/material";
import { isMobile, isTablet } from "react-device-detect";
const SalesSpot = ({ isLanguageLoaded }) => {
  /// TRANSLATION
  const { t } = useTranslation();
  //EEFECT
  useEffect(() => {
    document.title = "Zermat USA :: Sales Spot";
  }, []);
  return (
    <div
      id="firstWrapperInBaseContainer"
      className="firstWrapperInBaseContainer"
    >
      <div className="text__red sales__spot__title">
        {isLanguageLoaded ? (
          t("__sales_spot_title")
        ) : (
          <Skeleton variant="rect" width={"20%"} height={35} />
        )}
      </div>
      <div className="sales__spot__content">
        <iframe
          className={`sales__points__maps ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3461.5632714503718!2d-95.44901758504756!3d29.819159081964706!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c659ca3f006f%3A0x23b12aaf614a0183!2sZermat%20USA!5e0!3m2!1sen!2smx!4v1626980829720!5m2!1sen!2smx"
          allowFullScreen={true}
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};

export default SalesSpot;
