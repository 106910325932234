import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import html2canvas from "html2canvas";
import { isMobile, isTablet } from "react-device-detect";
//MUI
import { Button } from "@mui/material";
import { Save } from "@mui/icons-material";

const CardPersonalizedFragance = ({
  setCustomLabelList,
  listFragancesCustomer,
  item,
  dispatch,
  requestLoggUserActivity,
}) => {
  /// TRANSLATION
  const { t } = useTranslation();

  //Handlers
  const handleSetB64 = () => {
    //Generate b64
    html2canvas(document.getElementById(`divFinalCanvas${item.id}`), {
      backgroundColor: "white",
    }).then(function (canvas) {
      //document.body.appendChild(canvas);
      const WrapperForLabelCanvas = document.getElementById(
        "WrapperForLabelCanvas"
      );
      WrapperForLabelCanvas.appendChild(canvas);
      const b64 = canvas.toDataURL("image/jpeg");
      const cloneArray = [...listFragancesCustomer];
      const cloneItem = { ...item, template: b64, isSaved: true };
      const index = cloneArray.findIndex((i) => i.id === cloneItem.id);

      cloneArray[index] = cloneItem;
      setCustomLabelList(cloneArray);

      dispatch(
        requestLoggUserActivity(
          80,
          0,
          0,
          cloneItem.itemCode,
          "Texto configurado: " +
            cloneItem.label +
            " . Position Array: " +
            index
        )
      );
      // console.log(b64);
      // if (productOnEdition.hasOwnProperty("id")) {
      //   //get index on array
      //   const cloneArray = [...listFragancesCustomer];
      //   const cloneProductOnEdition = {
      //     ...productOnEdition,
      //     status: 0,
      //     template: b64,
      //   };
      //   const index = cloneArray.findIndex(
      //     (i) => i.id === cloneProductOnEdition.id
      //   );
      //   cloneArray[index] = cloneProductOnEdition;
      //   setCustomLabelList(cloneArray);
      //   setProductOnEdition({});
      //   setActiveStepCustomer(0);
      //}
    });
  };

  return (
    <div
      className={`card__personalizes__fragance ${
        isTablet ? "tablet" : isMobile ? "mobile" : ""
      }`}
    >
      <div>
        <div className="edit__customer__badge__content" id="finalDivForCanvas">
          <div className="card__personalizes__fragance__image">
            <img
              //src="https://www.websizusa.net/imgprod/fullsize/83277_.jpg"
              //"https://www.websizusa.net/imgprod/fullsize/82615_.jpg" //{FraganceBig1}
              src={item?.image}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src =
                  "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
              }}
              alt="imagen del producto"
            />
            <div
              className={`edit__customer__badge__content__left__center__wrapper__images__message ${
                //item?.templateId === 1 ? "hexagonal" : "rectangular"
                "hexagonal"
              }`}
            >
              <span>{item?.label}</span>
            </div>
            <div
              id={`divFinalCanvas${item.id}`}
              className={`div__final__canvas ${
                //item?.templateId === 1 ? "hexagonal" : "rectangular"
                "hexagonal"
              }`}
            >
              <span>{item?.label}</span>
            </div>
          </div>
          <div style={{ marginTop: 16 }}>
            <div className="textfield inner">
              <div className="label__and__button">
                <label htmlFor="my-input">
                  {t("__customer_support_form_message_label")}
                </label>
                <Button
                  className="button__with__icon bg__pink no__left__margin small"
                  disabled={item.isSaved || item.label.length <= 0}
                  onClick={() => {
                    handleSetB64();
                  }}
                >
                  <div className="button__with__icon__svg__wrapper pink__ball">
                    <Save />
                  </div>
                  <span className="button__with__icon__text">
                    {t("btn_text_save")}
                  </span>
                </Button>
              </div>

              <textarea
                id="my-input"
                maxLength={30}
                rows={isMobile ? "5" : "5"}
                cols={isMobile ? "30" : "30"}
                style={{ height: 50 }}
                onInput={(e) => {
                  //setMessage(e.target.value);
                  const updatedText = e.target.value.replace(
                    /[^a-zA-Z0-9!¡¿?áéíóúÁÉÍÓÚñÑ ]/gm,
                    ""
                  );
                  // setProductOnEdition({
                  //   ...productOnEdition,
                  //   label: updatedText,
                  // });
                  const foundIndex = listFragancesCustomer.findIndex(
                    (element) => element.id === item.id
                  );
                  //console.log("foundIndex", foundIndex);
                  const clone = [...listFragancesCustomer];
                  clone[foundIndex] = {
                    ...clone[foundIndex],
                    label: updatedText,
                    template: "",
                    isSaved: false,
                  };
                  setCustomLabelList(clone);
                  //setMessageFirstLevel(updatedText);
                }}
                inputprops={{ maxLength: 30 }}
                value={item.label}
                aria-describedby="my-helper-text"
              />
            </div>
          </div>
          <div
            className={`card__personalizes__fragance__warning ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            {t("__personalized__fragances_label_warning")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardPersonalizedFragance;
