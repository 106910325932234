import GraphqlHandler from "../GraphqlHandler";
import GraphqlHandlerNoInterceptor from "../GraphqlHandler/noInterceptor";
import { ENVIROMENT_URL } from "../../React/ReduxSaga/Constants";

import { getRequest } from "../Utils/utils";

const routeProduct = ENVIROMENT_URL + "apiV3/ProductMicroservice/Product";
const routeClient = ENVIROMENT_URL + "apiV3/ClientMicroservice/Client";

class ProductsSection {
  static GetPrTrExProducts() {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      routeProduct,
      //'http://localhost:65016/Product',
      `{
        getProductsSections
         {
           code,
           message,
           promotions
           {
            idLang
            idCatProductsCategories
            idCatProductsCategoriesType
            subCat1
            subCat2
            subCat3
            subCat4
            
            itemCode
            nameArticle
            descArticle
            modeArticle
            ingArticle
            
            regularPrice
            promotionPrice
            
            stock
            available
            image
            typePlan
            typeLine
            
            isLabelConfig
            idFamily
            
            keyWords

            minAmount
            maxSets
           },
           news {
            idLang
            idCatProductsCategories
            idCatProductsCategoriesType
            subCat1
            subCat2
            subCat3
            subCat4
            
            itemCode
            nameArticle
            descArticle
            modeArticle
            ingArticle
            
            regularPrice
            promotionPrice
            
            stock
            available
            image
            typePlan
            typeLine
            
            isLabelConfig
            idFamily
            
            keyWords

            minAmount
            maxSets
          }
         }
       }`,
      false
    );
    return result;
  }

  static GetFavorites(clientId) {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      routeProduct,
      //'http://localhost:65016/Product',
      `(@autodeclare){
        getProductsSections(clientId:$clientId)
         {
           code,
           message,
           favorites
           {
             idLang,
             idCatProductsCategoriesType,
             subCat1,
             subCat2,
             subCat3,
             itemCode,
             nameArticle,
             descArticle,
             modeArticle,
             ingArticle,
             achor,
             regularPrice,
             promotionPrice,
             stock,
             available,
             image,
             typePlan,
             typeLine,
             fpKey,
             notifyMe,
             isLabelConfig,
             colorArticleModels
             {
              anchorArticle
              codeColor
              nameColor
             },
             comentsAndRating
             {
              itemCode,
              name,
              rating,
              comments,
              dateAudit
             }
           }
         }
       }`,
      true,
      {
        "clientId!Int": clientId,
      }
    );
    return result;
  }

  static GetAllProducts() {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      //'http://localhost:65016/Product',
      routeProduct,
      `
      {
        getCategoriesAndProducts{    
          code,
          message,
          languages
          {
            id,
            code,
            name,
            categories
            {
              idCategories,
              idCategoriesType,
              nameCategories,
              subCategories
              {
                idSubCategories,
                nameSubCategories,
                subCategoriesList
                {
                  idSubCategories,
                  nameSubCategories,
                  subCategoriesList
                  {
                    idSubCategories,
                    nameSubCategories
                  }
                }
              }
              products
              {
                idLang,
                idCatProductsCategoriesType,
                subCat1,
                subCat2,
                subCat3,
                itemCode,
                nameArticle,
                descArticle,
                modeArticle,
                ingArticle,
                achor,
                regularPrice,
                promotionPrice,
                stock,
                available,
                image,
                typePlan,
                typeLine,
                isLabelConfig,
                comentsAndRating
                {
                  itemCode,
                  name,
                  rating,
                  comments,
                  dateAudit
                }
                relatedProducts
                {
                  idLang,
                  idCatProductsCategoriesType,
                  subCat1,
                  subCat2,
                  subCat3,
                  itemCode,
                  nameArticle,
                  descArticle,
                  modeArticle,
                  ingArticle,
                  achor,
                  regularPrice,
                  promotionPrice,
                  stock,
                  available,
                  image,
                  typePlan,
                  typeLine,
                  isLabelConfig,
                }
                alsoBought
                {
                  idLang,
                  idCatProductsCategoriesType,
                  subCat1,
                  subCat2,
                  subCat3,
                  itemCode,
                  nameArticle,
                  descArticle,
                  modeArticle,
                  ingArticle,
                  achor,
                  regularPrice,
                  promotionPrice,
                  stock,
                  available,
                  image,
                  typePlan,
                  typeLine,
                  isLabelConfig,
                }
                colorArticleModels{
                  anchorArticle
                  codeColor
                  nameColor
                }
              }
            }
          }    
        }
      }
      `,
      false
    );
    return result;
  }

  static SetProductOnFavorite(
    clientId,
    itemCode,
    typeLine,
    isFavorite,
    withNonStock
  ) {
    const result = GraphqlHandler.openRequestGraphQlMutation(
      routeClient,
      `
      (@autodeclare){
        setFavoriteProduct(clientId:$clientId, itemCode:$itemCode, typeLine:$typeLine, isFavorite:$isFavorite, withNonStock:$withNonStock)
        {
          code,
          message
        }
      }
      `,
      true,
      {
        "clientId!Int": clientId,
        itemCode,
        typeLine,
        isFavorite,
        withNonStock,
      }
    );
    return result;
  }

  static GetZCareProduct() {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      routeProduct,
      `
      (@autodeclare)
        {
          getZermatCareProduct
          {
            code,
            message,
            zCProducts
            {
              idLang,
              idCatProductsCategoriesType,
              subCat1,
              subCat2,
              subCat3,
              itemCode,
              nameArticle,
              descArticle,
              modeArticle,
              ingArticle,
              achor,
              regularPrice,
              promotionPrice,
              stock,
              available,
              image,
              typePlan,
              typeLine,
              comentsAndRating
              {
                itemCode,
                rating,
                comments,
                dateAudit
              }
              relatedProducts
              {
                itemCode,
                achor
              },
              alsoBought
              {
                itemCode,
                achor
              }
            }
          }
        }
      `,
      true,
      {}
    );
    return result;
  }

  static GetSetUpKitComponents(itemCode) {
    const result = GraphqlHandler.openRequestGraphQlQuery(
      routeProduct,
      `
      (@autodeclare)
      {
        getSetComponents(itemCode:$itemCode)
        {
          code,
          message,
          configuration{
            cant_DVariable
            cant_HVariable
            cantMaxSets
            codigo
            comisionable
            descripcion
            dVariable
            hVariable
            idPromo
            imageEn
            imageEs
            montoMin
            precio
            setvariable
            tipoPlan
            tresPorDos
            isKitIngreso
          },
          details
          {
            comisionable,
            description,
            idCdz,
            idLang,
            idPromo,
            itemCode,
            itemPrice,
            qty,
            qtyAddSet,
            qtyAddSetFijo,
            image
            isCustomLabel
          },
          header
          {
            comisionable,
            description,
            idCdz,
            idLang,
            idPromo,
            itemCode,
            itemPrice,
            qty,
            qtyAddSet,
            qtyAddSetFijo,
            image
            isCustomLabel,
            isGiftSet
          }
        }
      }
      `,
      true,
      {
        "itemCode!String": itemCode,
      }
    );
    return result;
  }

  static GetAdmissionKit() {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      routeProduct,
      `
      (@autodeclare)
      {
        getAdmissionKits
        {
          code,
          message,
          admissionKitList
          {
            achor,
            available,
            catProductsCategoriesTypeImage,
            descArticle,
            idCatProductsCategoriesType,
            idLang,
            image,
            ingArticle,
            itemCode,
            modeArticle,
            nameArticle,
            promotionPrice,
            regularPrice,
            stock,
            subCat1,
            subCat2,
            subCat3,
            typeLine,
            typePlan,

            minAmount,
            maxSets
            
          }
        }
      }
      `,
      true,
      {}
    );
    return result;
  }

  static GetBeautyBoxes() {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      routeProduct,
      `
      (@autodeclare)
      {
        getBeautyBoxes
        {
          code,
          message,
          admissionKitList
          {
            achor,
            available,
            catProductsCategoriesTypeImage,
            descArticle,
            idCatProductsCategoriesType,
            idLang,
            image,
            ingArticle,
            itemCode,
            modeArticle,
            nameArticle,
            promotionPrice,
            regularPrice,
            stock,
            subCat1,
            subCat2,
            subCat3,
            typeLine,
            typePlan
            
          }
        }
      }
      `,
      true,
      {}
    );
    return result;
  }

  ////Test\\\\
  static GetAllProductsContent() {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      //'http://localhost:65016/Product',
      routeProduct,
      `
       {
        getAllProductsContent{  
          code
          message
          languageComponentModels{
            id
            code
            name
            productDetailModels{
              idLang
              idCatProductsCategoriesType
              subCat1
              subCat2
              subCat3
              itemCode
              nameArticle
              descArticle
              modeArticle
              ingArticle
              regularPrice
              promotionPrice
              stock
              available
              image
              typePlan
              typeLine
              isLabelConfig
              idFamiliColor
              nameColor
              codeColor
              anchorArticle
              comentsAndRating {
                itemCode
                name
                rating
                comments
                dateAudit
              }
              relatedProducts{
                idLang
                idCatProductsCategoriesType
                subCat1
                subCat2
                subCat3
                itemCode
                nameArticle
                descArticle
                modeArticle
                ingArticle
                regularPrice
                promotionPrice
                stock
                available
                image
                typePlan
                typeLine
                isLabelConfig
                idFamiliColor
                nameColor
                codeColor
                anchorArticle
              }
              alsoBought{
                idLang
                idCatProductsCategoriesType
                subCat1
                subCat2
                subCat3
                itemCode
                nameArticle
                descArticle
                modeArticle
                ingArticle
                regularPrice
                promotionPrice
                stock
                available
                image
                typePlan
                typeLine
                isLabelConfig
                idFamiliColor
                nameColor
                codeColor
                anchorArticle
              }
              colorArticleModels{
                anchorArticle
                codeColor
                nameColor
              }
            }
            categorieModels{
              idLang
              idCategories
              idCategoriesType
              nameCategories
            }
            subCategoryModelLvl1{
              idLang
              idCategoriesType
              idSubCategories
              nameSubCategories
            }
            subCategoryModelLvl2{
              idLang
              isSubCategoryLevel1
              idSubCategories
              nameSubCategories
            }
            subCategoryModelLvl3{
              idLang
              idSubCategoryLevel1
              isSubCategoryLevel2
              idSubCategories
              nameSubCategories
            }
          }
        }
      }
      `,
      false
    );
    return result;
  }
  //articulos por idioma
  static GetAllProductsByLanguage(idLang) {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      //'http://localhost:65016/Product',
      routeProduct,
      `
      (@autodeclare)
      {
        getAllProductsByLanguage(language:$idLang)
        {
          code
          message
          products {
            idLang
            idCatProductsCategoriesType
            subCat1
            subCat2
            subCat3
            itemCode
            nameArticle
            descArticle
            modeArticle
            ingArticle
            regularPrice
            promotionPrice
            stock
            available
            image
            typePlan
            typeLine
            isLabelConfig
            idFamiliColor
            nameColor
            codeColor
            anchorArticle
            comentsAndRating {
              itemCode
              name
              rating
              comments
              dateAudit
            }
            relatedProducts {
              idLang
              idCatProductsCategoriesType
              subCat1
              subCat2
              subCat3
              itemCode
              nameArticle
              descArticle
              modeArticle
              ingArticle
              regularPrice
              promotionPrice
              stock
              available
              image
              typePlan
              typeLine
              isLabelConfig
              idFamiliColor
              nameColor
              codeColor
              anchorArticle
            }
            alsoBought {
              idLang
              idCatProductsCategoriesType
              subCat1
              subCat2
              subCat3
              itemCode
              nameArticle
              descArticle
              modeArticle
              ingArticle
              regularPrice
              promotionPrice
              stock
              available
              image
              typePlan
              typeLine
              isLabelConfig
              idFamiliColor
              nameColor
              codeColor
              anchorArticle
            }
            colorArticleModels {
              anchorArticle
              codeColor
              nameColor
            }
          }
        }
      }
      `,
      true,
      {
        "idLang!Int": idLang,
      }
    );
    return result;
  }
  //categorias por idioma
  static GetAllCategoriesByLanguage(idLang) {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      //'http://localhost:65016/Product',
      routeProduct,
      `
      (@autodeclare)
      {
        getAllCategoriesByLanguage(language:$idLang)
        {
          code
          message
          categorieModels{
            idCategories
            idCategoriesType
            idLang
            nameCategories
          }
          subCategoryModelLvl1s{
            idCategoriesType
            idLang
            idSubCategories
            nameSubCategories
          }
          subCategoryModelLvl2s{
            idLang
            idSubCategories
            isSubCategoryLevel1
            nameSubCategories
          }
          subCategoryModelLvl3s{
            idLang
            idSubCategories
            idSubCategoryLevel1
            isSubCategoryLevel2
            nameSubCategories
          }
          subCategoryModelLvl4s{
            idCategoriesType
            idLang
            idSubCategories
            nameSubCategories
          }
        }
      }
      `,
      true,
      {
        "idLang!Int": idLang,
      }
    );
    return result;
  }

  //static ASYNC v1
  //PRODUCTOS
  static GetProductsByLangAsync = async (idLang) => {
    let res = await getRequest(
      `${ENVIROMENT_URL}apiV3/ProductMicroservice/Articles/GetProductsByLangAsync/?idLang=${idLang}`
    );
    let response = await res.json();
    return response;
  };
  //COMENTARIOS
  static GetCommentsProductsAsync = async () => {
    let res = await getRequest(
      `${ENVIROMENT_URL}apiV3/ProductMicroservice/Articles/GetCommentsProductsAsync`
    );
    let response = await res.json();
    return response;
  };
  //RELACIONADOS
  static GetRelatedProductsAsync = async () => {
    let res = await getRequest(
      `${ENVIROMENT_URL}apiV3/ProductMicroservice/Articles/GetRelatedProductsAsync`
    );
    let response = await res.json();
    return response;
  };
  //OTRAS PERSONAS TAMBIEN COMPRARON
  static GetBoughtProductsAsync = async () => {
    let res = await getRequest(
      `${ENVIROMENT_URL}apiV3/ProductMicroservice/Articles/GetBoughtProductsAsync`
    );
    let response = await res.json();
    return response;
  };
  //FAMILIAS
  static GetFamilyProductsAsync = async (idLang) => {
    let res = await getRequest(
      `${ENVIROMENT_URL}apiV3/ProductMicroservice/Articles/GetAllFamilyProductsAsync/?idLang=${idLang}`
    );
    let response = await res.json();
    return response;
  };
  //TAGS
  static GetOffersTagsAsync = async () => {
    let res = await getRequest(
      `${ENVIROMENT_URL}apiV3/ProductMicroservice/Articles/GetOffersTagsAsync`
    );
    let response = await res.json();
    return response;
  };
  //IMAGES
  static GetAllImageArticlesAsync = async () => {
    let res = await getRequest(
      `${ENVIROMENT_URL}apiV3/ProductMicroservice/Articles/GetAllImageArticlesAsync`
    );
    let response = await res.json();
    return response;
  };
}

export default ProductsSection;
