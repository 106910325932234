import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  Typography,
  Tooltip,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import { NotificationsActive, Notifications } from "@mui/icons-material";
import ProductsCarousel from "../products/components/ProductsCarousel";
import {
  requestFavorites,
  requestSetProductOnFavorite,
  cleanSetFavoritesReducer,
} from "./../../ReduxSaga/Actions/Products";
import { requestLoggUserActivity } from "./../../ReduxSaga/Actions/General";
import { formatNumberWithCommas } from "./../../../Libraries/Utils/utils";
import nothingHere from "./../../../assets/media/images/nothingHere.png";
import NotificationSnackbar from "../base/components/NotificationSnackbar";

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#D39F46",
    color: "#fff",
    maxWidth: 350,
    fontSize: "12px",
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const FavoritesContainer = () => {
  //Notistack
  const { enqueueSnackbar } = useSnackbar();

  /// NAVIGATION
  const history = useHistory();
  /// TRANSLATION
  const { t, i18n } = useTranslation();
  /// REDUX
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.products = useSelector((state) => state.products);

  /// HOOKS
  const [dataNotification, setDataNotification] = useState({
    isOpen: false,
    type: "",
    message: "",
    icon: "",
  });
  const [userLogedInfo, setUserLogedInfo] = useState({});
  const [clientFavorites, setClientFavorites] = useState([]);
  const [promotionsData, setPromotionsData] = useState([]);

  /// EFFECTS
  useEffect(() => {
    if (
      !appReducers?.products?.errorLoadfavorites &&
      !appReducers?.products?.favoritesEn
    ) {
      dispatch(requestFavorites());
    } else {
      if (!appReducers?.products?.errorLoadfavorites) {
        if (i18n.language === "en") {
          setClientFavorites(appReducers?.products?.favoritesEn);
        }
        if (i18n.language === "es") {
          setClientFavorites(appReducers?.products?.favoritesEs);
        }
      }
    }
  }, [
    appReducers?.products?.errorLoadfavorites,
    i18n.language,
    appReducers?.products?.favoritesEn,
    appReducers?.products?.favoritesEs,
  ]);

  useEffect(() => {
    if (
      !appReducers?.products?.errorLoadTrPrExProducts &&
      appReducers?.products?.promotionsEn
    ) {
      if (i18n.language === "en") {
        setPromotionsData(appReducers?.products?.promotionsEn);
      }
      if (i18n.language === "es") {
        setPromotionsData(appReducers?.products?.promotionsEs);
      }
    }
  }, [
    appReducers?.products?.errorLoadTrPrExProducts,
    i18n.language,
    appReducers?.products?.promotionsEs,
    appReducers?.products?.promotionsEn,
  ]);

  useEffect(() => {
    if (localStorage.getItem("ZUsaUInfo")) {
      setUserLogedInfo(JSON.parse(localStorage.getItem("ZUsaUInfo")));
    }
  }, [localStorage.getItem("ZUsaUInfo")]);

  useEffect(() => {
    if (
      typeof appReducers?.products?.errorSetProductOnFavorites !== "undefined"
    ) {
      if (!appReducers.products.errorSetProductOnFavorites) {
        enqueueSnackbar(
          t(
            "__product_Detail_Module_Transaction_IsNotOnFavorites_Success_Message"
          ),
          {
            variant: "success",
          }
        );
        // setDataNotification({
        //   isOpen: true,
        //   type: "success",
        //   message: t(
        //     "__product_Detail_Module_Transaction_IsNotOnFavorites_Success_Message"
        //   ),
        // });
        dispatch(requestFavorites());
      } else {
        enqueueSnackbar(
          t("__product_Detail_Module_Transaction_IsOnFavorites_Error_Message"),
          {
            variant: "error",
          }
        );
        // setDataNotification({
        //   isOpen: true,
        //   type: "error",
        //   message: t(
        //     "__product_Detail_Module_Transaction_IsOnFavorites_Error_Message"
        //   ),
        // });
      }
      dispatch(cleanSetFavoritesReducer());
    }
  }, [appReducers?.products?.errorSetProductOnFavorites]);

  //HANDLERS
  //const handleChange = (e, item) => {
  // setState({ ...state, [event.target.name]: event.target.checked });
  // dispatch(requestSetProductOnFavorite(item.itemCode, item.typeLine, true, e.target.checked))
  //};

  const handleChangeFavorite = (e, item) => {
    dispatch(
      requestSetProductOnFavorite(
        item.itemCode,
        item.typeLine,
        e.target.checked,
        false
      )
    );

    dispatch(
      requestLoggUserActivity(
        39,
        0,
        0,
        "",
        "Click en Quitar de Favoritos: " + String(item.itemCode)
      )
    );
  };

  return (
    <div
      id="firstWrapperInBaseContainer"
      className="firstWrapperInBaseContainer favorites__content"
    >
      {/* <BreadcrumbsNavigationComponet /> */}
      {/* <NotificationSnackbar
        isOpenNotification={dataNotification.isOpen}
        setIsOpenNotification={setDataNotification}
        message={dataNotification.message}
        status={dataNotification.type}
        icon={dataNotification.icon}
      /> */}
      <div className="favorites__list__container">
        {userLogedInfo.hasOwnProperty("idClient") && (
          <div className="profile__container__title">
            <Typography variant="h5" gutterBottom>
              {t("__myAccount_Favorites_Module_Title")}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {`${t("__myAccount_Favorites_Module_SubTitle")} ${
                userLogedInfo.nameClient
              }`}
            </Typography>
          </div>
        )}
        <div className="favorites__list__container__items">
          {clientFavorites?.length > 0 ? (
            <List>
              {clientFavorites?.map((product, index) => (
                <div
                  className="favorites__list__container__items__content"
                  key={index}
                >
                  <ListItem>
                    <div className="favorites__list__container__items__content__item">
                      <img
                        className="favorites__list__container__items__content__item__image"
                        src={product.image}
                        alt={product.nameArticle}
                        title={product.nameArticle}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src =
                            "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
                        }}
                      />
                    </div>

                    <div className="favorites__list__container__items__content__item">
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        {product.nameArticle}
                      </Typography>
                      {product.typeLine === "PD" ||
                      product.typeLine === "P" ||
                      product.typeLine === "FV" ||
                      product.typeLine === "VV" ||
                      product.typeLine === "VF" ? (
                        <Fragment>
                          <em className="products__list__container__item__descripcion__price__promo">
                            ${formatNumberWithCommas(product.regularPrice)}
                          </em>
                          <em>
                            ${formatNumberWithCommas(product.promotionPrice)}
                          </em>
                        </Fragment>
                      ) : (
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                          className="shopping__list__container__wrapper__item__price"
                        >
                          ${formatNumberWithCommas(product.regularPrice)}
                        </Typography>
                      )}
                    </div>
                    <div className="favorites__list__container__items__content__item">
                      <FormControlLabel
                        control={
                          <StyledTooltip
                            arrow
                            title={
                              !product.notifyMe ? (
                                <Fragment>
                                  {t("__profile_favorites_notification")}
                                </Fragment>
                              ) : (
                                <Fragment>
                                  {t("__profile_favorites_delete_notification")}
                                </Fragment>
                              )
                            }
                            aria-label="add"
                            placement="top"
                          >
                            <Checkbox
                              checked={product.notifyMe}
                              //onChange={e => handleChange(e, product)}
                              icon={
                                <div className="bell__outline__gold__icon" />
                              }
                              checkedIcon={
                                <div className="bell__fill__gold__icon " />
                              }
                            />
                          </StyledTooltip>
                        }
                      />
                    </div>
                    <div className="favorites__list__container__items__content__item">
                      <FormControlLabel
                        control={
                          <StyledTooltip
                            arrow
                            title={t(
                              "__profile_favorites_delete_product_in_section"
                            )}
                            aria-label="add"
                            placement="top"
                          >
                            <Checkbox
                              checked
                              onChange={(e) => handleChangeFavorite(e, product)}
                              icon={
                                <div className="heart__outline__blue__icon" />
                              }
                              checkedIcon={
                                <div className="heart__fill__blue__icon" />
                              }
                            />
                          </StyledTooltip>
                        }
                      />
                    </div>
                  </ListItem>
                </div>
              ))}
            </List>
          ) : (
            <Fragment>
              <div className="search__content__wrapper__container__result__nothing">
                <img
                  className="search__content__result__nothing__image"
                  src={nothingHere}
                  alt="Sorry"
                />
                <p>{t("__myAccount_Favorites_Information_Nothing_Title")}</p>
              </div>
            </Fragment>
          )}
        </div>
      </div>

      <div className="favorites__carousel__other__products">
        {promotionsData.length > 0 && (
          <ProductsCarousel
            title={t("__checkout_order_carousel_others_bought")}
            ListProducts={promotionsData}
            links="/promotions"
          />
        )}
      </div>
    </div>
  );
};

export default FavoritesContainer;
