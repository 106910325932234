import GraphqlHandler from "../GraphqlHandler";
import GraphqlHandlerNoInterceptor from "../GraphqlHandler/noInterceptor";
import { ENVIROMENT_URL } from "../../React/ReduxSaga/Constants";

const route = ENVIROMENT_URL + "apiV3/CartMicroservice/Cart";

class Cart {
  static CartCancelOrder(sessionId) {
    const result = GraphqlHandler.openRequestGraphQlMutation(
      route,
      //'https://localhost:44343/Cart',
      `(@autodeclare)
      {
        cartOrder_CancelOrder(sessionId:$sessionId)
        {
          code,
          message,
          result
        }
      }`,
      true,
      {
        "sessionId!Int": sessionId,
      }
    );
    return result;
  }

  static CartOrderCharges(opc, orderId) {
    const result = GraphqlHandler.openRequestGraphQlMutation(
      route,
      //'https://localhost:44343/Cart',
      `(@autodeclare)
      {
        cartOrder_Charges(opc:$opc, orderId: $orderId)
        {
          code,
          message,
          result
        }
      }`,
      true,
      {
        "opc!Int": opc,
        "orderId!Int": orderId,
      }
    );
    return result;
  }

  static CartOrderAddMembership(
    idPedidoDet,
    idpromo,
    planType,
    sessionId,
    userGuid,
    withAssign,
    itemCode
  ) {
    const result = GraphqlHandler.openRequestGraphQlMutation(
      route,
      //'https://localhost:44343/Cart',
      `(@autodeclare)
      {
        cartOrder_AddMembershipOrder
        (
          idPedidoDet: $idPedidoDet,
          idpromo: $idpromo,
          planType: $planType,
          sessionId: $sessionId,
          userGuid: $userGuid,
          withAssign: $withAssign,
          itemCode: $itemCode
        )
        {
          code,
          message,
          result
        }
      }`,
      true,
      {
        "idPedidoDet!Int": idPedidoDet,
        "idpromo!Int": idpromo,
        "planType!Int": planType,
        "sessionId!Int": sessionId,
        "userGuid!String": userGuid,
        "withAssign!Int": withAssign,
        "itemCode!String": itemCode,
      }
    );
    return result;
  }

  static CartCloseOrder(sessionId, benefitType, evaluatept) {
    const result = GraphqlHandler.openRequestGraphQlMutation(
      route,
      //'https://localhost:44343/Cart',
      `(@autodeclare)
      {
        cartCloseOrder(sessionId:$sessionId, benefitType:$benefitType, evaluatept:$evaluatept)
        {
          code,
          message,
          user
          {
            email,
            idClient,
            idGuestUser,
            idLang,
            idPage,
            idUser,
            isGuestUser,
            isOlder,
            nameClient,
            sessionId,
            sponsorId,
            typeUser
          }
        }
      }`,
      true,
      {
        "sessionId!Int": sessionId,
        "benefitType!Int": benefitType,
        evaluatept,
      }
    );
    return result;
  }

  static CartCloseOrderGuest(sessionId, benefitType, parameters) {
    const result = GraphqlHandler.openRequestGraphQlMutation(
      route,
      //'http://localhost:60938/Cart',
      `(@autodeclare)
      {
        cartCloseOrderGuest(sessionId:$sessionId, benefitType:$benefitType, parameters:$parameters)
        {
          code,
          message,
          user
          {
            email,
            idClient,
            idGuestUser,
            idLang,
            idPage,
            idUser,
            isGuestUser,
            isOlder,
            nameClient,
            sessionId,
            sponsorId,
            typeUser
          }
        }
      }`,
      true,
      {
        "sessionId!Int": sessionId,
        "benefitType!Int": benefitType,
        "parameters!CartOrderGuestInfoClientType": parameters,
      }
    );
    return result;
  }

  static CartAddConfigurableGift(sessionId, parameters) {
    const result = GraphqlHandler.openRequestGraphQlMutation(
      route,
      //'http://localhost:60938/Cart',
      `(@autodeclare)
      {
        cartAddConfigurableGift(sessionId: $sessionId, parameters: $parameters)
        {
          code
          message
          
        }
      }`,
      true,
      {
        "sessionId!Int": sessionId,
        "parameters!CartGiftParametersType": parameters,
      }
    );
    return result;
  }

  static CartOrderOpCurrentOrder(parameters) {
    const result = GraphqlHandler.openRequestGraphQlMutation(
      route,
      //'http://localhost:60938/Cart',
      `(@autodeclare)
        {
          cartOrder_OpCurrentOrder(parameters:$parameters)
          {
            code,
            message,
            result
          }
        }`,
      true,
      {
        "parameters!CartOrderParametersType": parameters,
      }
    );
    return result;
  }

  static GetCartOrder(userId, sessionId) {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      route,
      //'https://localhost:44343/Cart',
      `(@autodeclare)
      {
        cartOrder_GetOrder(userId: $userId, sessionId: $sessionId)
        {
          code,
          message,
          lines
          {
            details
            {
              codart,
              codean,
              comisionable,
              descripcionEn,
              descripcionEs,
              disponibles,
              idpedidodet,
              idpromo,
              imageUrl,
              impuesto,
              montolinai,
              montolinai2,
              montolinii,
              montolinii2,
              nonstock,
              precioai,
              precioai2,
              preuniii,
              preuniii2,
              qtyasig,
              qtyped,
              qtyped2,
              qtysur,
              status,
              tipolinea,
              isCustomLabel,
              amountMin,
              cantMax
            }
            ,
            line
            {
              codart,
              codean,
              comisionable,
              descripcionEn,
              descripcionEs,
              disponibles,
              idpedidodet,
              idpromo,
              imageUrl,
              impuesto,
              montolinai,
              montolinai2,
              montolinii,
              montolinii2,
              nonstock,
              precioai,
              precioai2,
              preuniii,
              preuniii2,
              qtyasig,
              qtyped,
              qtyped2,
              qtysur,
              status,
              tipolinea,
              isCustomLabel,
              amountMin,
              cantMax
            }
          },
          order
          {
            abonos,
            aprobacion,
            cargos,
            decripciontdc,
            descuento,
            fecpedido,
            idcliente,
            importeai,
            importeai2,
            importeaic,
            importeaic2,
            importeainc,
            importeainc2,
            importeii,
            importeii2,
            importeiic,
            importeiic2,
            importeimp,
            importeimp2,
            importeimpc,
            importeimpc2,
            importeimpnc,
            importeimpnc2,
            orderId,
            porcimp,
            saldopagar,
            subtotal,
            tarjetano,
            userId
          },
          charges
          {
            id,
            description,
            idCharge,
            dateAudit,
            orderId,
            price
          }
        }
      }`,
      true,
      {
        "userId!String": userId,
        "sessionId!Int": sessionId,
      }
    );
    return result;
  }

  static GetCartClosedOrder(userId, sessionId) {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      route,
      //'https://localhost:44343/Cart',
      `(@autodeclare)
      {
        cartOrder_GetClosedOrder(userId: $userId, sessionId: $sessionId)
        {
          code,
          message,
          lines
          {
            details
            {
              codart,
              codean,
              comisionable,
              descripcionEn,
              descripcionEs,
              disponibles,
              idpedidodet,
              idpromo,
              imageUrl,
              impuesto,
              montolinai,
              montolinai2,
              montolinii,
              montolinii2,
              nonstock,
              precioai,
              precioai2,
              preuniii,
              preuniii2,
              qtyasig,
              qtyped,
              qtyped2,
              qtysur,
              status,
              tipolinea,
              isCustomLabel
            }
            ,
            line
            {
              codart,
              codean,
              comisionable,
              descripcionEn,
              descripcionEs,
              disponibles,
              idpedidodet,
              idpromo,
              imageUrl,
              impuesto,
              montolinai,
              montolinai2,
              montolinii,
              montolinii2,
              nonstock,
              precioai,
              precioai2,
              preuniii,
              preuniii2,
              qtyasig,
              qtyped,
              qtyped2,
              qtysur,
              status,
              tipolinea,
              isCustomLabel
            }
          },
          order
          {
            abonos,
            aprobacion,
            cargos,
            decripciontdc,
            descuento,
            fecpedido,
            idcliente,
            importeai,
            importeai2,
            importeaic,
            importeaic2,
            importeainc,
            importeainc2,
            importeii,
            importeii2,
            importeiic,
            importeiic2,
            importeimp,
            importeimp2,
            importeimpc,
            importeimpc2,
            importeimpnc,
            importeimpnc2,
            orderId,
            porcimp,
            saldopagar,
            subtotal,
            tarjetano,
            userId
          },
          charges
          {
            id,
            description,
            idCharge,
            dateAudit,
            orderId,
            price
          }
        }
      }`,
      true,
      {
        "userId!String": userId,
        "sessionId!Int": sessionId,
      }
    );
    return result;
  }

  static CartGetCouponList(sessionId) {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      route,
      //'https://localhost:44343/Cart',
      `(@autodeclare)
      {
        cartGetCouponList(sessionId: $sessionId)
        {
          code,
          message,
          couponList
          {
            idCoupon,
            itemCode,
            name,
            amount
          }
        }
      }`,
      true,
      {
        "sessionId!Int": sessionId,
      }
    );
    return result;
  }

  static CartGetGiftList(sessionId) {
    const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
      route,
      //'http://localhost:60938/Cart',
      `(@autodeclare)
      {
        cartGetGiftList(sessionId: $sessionId){
          code,
          message,
          giftSelectable{
            idx
            idRegalo
            image
            itemCode
            itemCodeGift
            motiveGift
            motiveGiftDesc
            nameEn
            nameEs
            nameGiftEn
            nameGiftEs
          },
          giftOverview{
            idx
            idRegalo
            image
            itemCode
            itemCodeGift
            motiveGift
            motiveGiftDesc
            nameEn
            nameEs
            nameGiftEn
            nameGiftEs
          }
        }
      }`,
      true,
      {
        "sessionId!Int": sessionId,
      }
    );
    return result;
  }
}

export default Cart;
