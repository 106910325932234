import React from "react";
import { useTranslation } from "react-i18next";
import { isMobile, isTablet } from "react-device-detect";
//Assets
import { ReactComponent as DiscountIcon } from "./../../../../assets/media/images/icons/discountIcon.svg";
import {
  capitalizeText,
  percentageUtility,
} from "../../../../Libraries/Utils/utils";

const SummaryCheckout = ({ orderLines, showPrice }) => {
  /// TRANSLATION
  const { i18n } = useTranslation();

  ///// hooks

  return (
    <div className="summary__checkout__content">
      {orderLines.map((line, index) => {
        const prod = line.line;
        const details = line.details ? line.details : [];
        return (
          <div
            key={index}
            className={`checkout__products__container__wrapper__list__item ${
              isMobile || isTablet ? "mobile" : ""
            }`}
          >
            <div className="checkout__products__container__wrapper__list__item__product">
              <img
                //className="favorites__list__container__items__content__item__image "
                src={prod.imageUrl}
                alt={prod.codart}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
                }}
              />
            </div>

            <div className="checkout__products__container__wrapper__list__item__texts__row">
              <div className="checkout__products__container__wrapper__list__item__texts__row__item">
                <span className="checkout__products__container__wrapper__list__item__texts__row__item__element type__title">
                  {i18n.language === "es"
                    ? capitalizeText(prod.descripcionEs)
                    : capitalizeText(prod.descripcionEn)}
                </span>

                <span className="checkout__products__container__wrapper__list__item__texts__row__item__element">
                  {prod.qtyped} pz
                </span>

                {showPrice ? (
                  <span
                    className={`checkout__products__container__wrapper__list__item__texts__row__item__element right__aligned ${
                      prod.montolinai !== prod.montolinai2
                        ? "discount__price"
                        : "red__price"
                    }`}
                    //className="shopping__list__container__wrapper__item__price checkout__products__container__wrapper__list__item__information__price"
                  >
                    ${parseFloat(prod.montolinai2).toFixed(2)}
                  </span>
                ) : (
                  <span
                    className="checkout__products__container__wrapper__list__item__texts__row__item__element"
                    style={{ visibility: "hidden" }}
                  >
                    $0.00
                  </span>
                )}
              </div>

              {showPrice && prod.montolinai !== prod.montolinai2 && (
                <div
                  className="checkout__products__container__wrapper__list__item__texts__row__item"
                  style={{
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    className="product__price__with__discount__wrapper__inner"
                    style={{ marginLeft: 0 }}
                  >
                    <span>{`- ${percentageUtility(
                      Math.floor(
                        100 - (prod.montolinai * 100) / prod.montolinai2
                      )
                    )}`}</span>
                    <DiscountIcon />
                  </div>

                  <span className="checkout__products__container__wrapper__list__item__texts__row__item__element right__aligned red__price">
                    ${parseFloat(prod.montolinai).toFixed(2)}
                  </span>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SummaryCheckout;
