import React, { useState, useEffect, Fragment } from "react";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import {
  Button,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  FormControlLabel,
  Checkbox,
  Skeleton,
  Rating,
} from "@mui/material";
import { FiberManualRecord, StarBorder } from "@mui/icons-material";
import NotificationSnackbar from "../base/components/NotificationSnackbar";
import VariantDetailComponent from "../products/components/VariantDetailComponent";
import {
  requestGetAdmissionKits,
  requestGetBeautyBoxes,
} from "./../../ReduxSaga/Actions/Products";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isMobile, isTablet } from "react-device-detect";
import {
  requestCartOrderAddMembership,
  requestGetCartOrder,
  requestCleanCartReducerAddMembership,
} from "./../../ReduxSaga/Actions/Cart";
import { requestLoggUserActivity } from "./../../ReduxSaga/Actions/General";
import {
  requestEntrepenourHomeModuleInfo,
  requestHomeModuleInfo,
} from "./../../ReduxSaga/Actions/Home";
// import SwiperCore, {
//   Navigation,
//   Pagination,
//   Autoplay,
//   EffectCoverflow,
// } from "swiper";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/swiper-bundle.css";
import { capitalizeText, formatPrice } from "../../../Libraries/Utils/utils";
////// imagenes
import BannerPCSpa from "../../../assets/media/images/PC/EmprendeBannerPCSpa.jpg";
import BannerPCEng from "../../../assets/media/images/PC/EmprendeBannerPCEng.jpg";
// import LockProduct from "../base/components/LockProduct";

// SwiperCore.use([Navigation, Pagination, Autoplay, EffectCoverflow]);

const NewEnterprisingContainer = ({
  admissionKits,
  benefitType,
  isLanguageLoaded,
}) => {
  //Notistack
  const { enqueueSnackbar } = useSnackbar();
  ///HISTORY
  const history = useHistory();

  /// TRANSLATION
  const { t, i18n } = useTranslation();
  // REDUX
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.products = useSelector((state) => state.products);
  appReducers.home = useSelector((state) => state.home);
  appReducers.general = useSelector((state) => state.general);
  appReducers.cart = useSelector((state) => state.cart);

  ///HOOKS

  const [isOpenNotification, setIsOpenNotification] = useState(false);
  const [isEnglisActive] = useState(false);
  const [isSharedActive, setIsSharedActive] = useState(false);
  const [isCompareActive, setIsCompareActive] = useState(false);
  const [isFavoriteActive, setIsFavoriteActive] = useState(false);
  const [resourcesMLModule, setResourcesMLModule] = useState([]);
  const [isLoadingEnable, setIsLoadingEnable] = useState(true);
  const [isAddFavorite, setIsAddFavorite] = useState(false);
  ////// modal slide

  const [isOpenSlideFC, setIsOpenSlideFC] = useState(false);
  const [beautyBoxList, setBeautyBoxList] = useState([]);

  const [dataNotification, setDataNotification] = useState({
    isOpen: false,
    type: "",
    message: "",
    icon: "",
  });
  const [pendNotification, setPendNotification] = useState(false);
  const [kitSelected, setKitSelected] = useState({});

  //USEEFFECTS

  useEffect(() => {
    //console.log("ENTERPRISING isLanguageLoaded", isLanguageLoaded);
  }, [isLanguageLoaded]);

  useEffect(() => {
    document.title = "Zermat USA :: Entrepenour";
    document.getElementById("firstWrapperInBaseContainer").scrollIntoView();
  }, []);

  useEffect(() => {
    if (appReducers?.cart?.cartAddMembership) {
      const respAddMemship = appReducers.cart?.cartAddMembership;
      if (respAddMemship.code === 100) {
        enqueueSnackbar(t("__message_success_product_add_cart_label"), {
          variant: "success",
        });
        dispatch(requestGetCartOrder());
      } else {
        enqueueSnackbar(respAddMemship.message, {
          variant: "error",
        });
      }
      dispatch(requestCleanCartReducerAddMembership());
    }
  }, [appReducers?.cart?.cartAddMembership]);

  // useEffect(() => {
  //   if (
  //     admissionKits.length > 0 &&
  //     !kitSelected.hasOwnProperty("nameArticle")
  //   ) {
  //     setKitSelected(admissionKits[0]);
  //   }
  // }, [admissionKits, kitSelected]);

  useEffect(() => {
    if (admissionKits.length > 0) {
      //console.log("admissionKits", admissionKits);
      setIsLoadingEnable(false);

      if (!kitSelected.hasOwnProperty("nameArticle")) {
        setKitSelected(admissionKits[0]);
      } else {
        if (kitSelected.hasOwnProperty("nameArticle")) {
          const foundIndex = admissionKits.findIndex(
            (item) => item.itemCode === kitSelected.itemCode
          );
          //console.log("foundIndex", foundIndex);
          setKitSelected(admissionKits[foundIndex]);
        }
      }
    } else {
      setIsLoadingEnable(true);
    }
  }, [admissionKits]);

  useEffect(() => {
    if (
      appReducers?.home?.enterprenourModuleEs &&
      appReducers?.home?.enterprenourModuleEn
    ) {
      if (i18n.language === "es") {
        //espanol
        setResourcesMLModule(appReducers.home.enterprenourModuleEs);
      } else {
        //ingles
        setResourcesMLModule(appReducers.home.enterprenourModuleEn);
      }
    } else {
      if (typeof appReducers?.general?.errorLoadFirstCharge !== "undefined") {
        if (!appReducers.general.errorLoadFirstCharge) {
          const { idPage } = appReducers.general.firstChargeModel;
          if (idPage > 0) {
            dispatch(requestEntrepenourHomeModuleInfo());
          } else {
            dispatch(requestHomeModuleInfo());
          }
        }
      }
    }
  }, [
    appReducers?.home?.enterprenourModuleEs,
    appReducers?.home?.enterprenourModuleEn,
    i18n.language,
  ]);

  const handleAddMembership = () => {
    dispatch(
      requestLoggUserActivity(
        14,
        0,
        0,
        kitSelected?.itemCode,
        "Click en Agregar al Carrito (KIT FIJO)"
      )
    );
    dispatch(requestCartOrderAddMembership(-1, 0, 1, 0, kitSelected.itemCode));
  };

  const renderListProducts = (listString) => {
    //console.log("listString", listString);
    const list = listString.split("•");
    return list.map((item, index) => {
      if (!item.includes("INCLUDE:") && !item.includes("INCLUYE:"))
        return <li key={index}>{`• ${item.trim()}`}</li>;
      else return <li key={index}></li>;
    });
  };

  const handleCopyLink = () => {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setDataNotification({
      isOpen: true,
      type: "success",
      message: t("__message_link_copy_label"),
    });
  };

  return (
    <div
      id="firstWrapperInBaseContainer"
      className={`firstWrapperInBaseContainer section LeftTopGray new__favorite__customer ${
        isTablet ? "tablet" : isMobile ? "mobile" : ""
      }`}
    >
      <div>
        <div
          className={`enterprising__motto ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          {isLanguageLoaded ? (
            <Typography className="price__red" variant="h5" gutterBottom>
              {t("__entrepreneur_motto_text")}
            </Typography>
          ) : (
            <Skeleton
              variant="rect"
              width={isMobile ? "90%" : "50%"}
              height={30}
              style={{ margin: "5px" }}
            />
          )}
        </div>
      </div>
      <div
        className={`enterprising__headers ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }`}
      >
        <div
          className={`enterprising__headers__image ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          <div>
            <div
              className={`new__favorite__customer__banner ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              <div
                className={`new__favorite__customer__banner__image  ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                {isLoadingEnable ? (
                  <Skeleton variant="rect" width={"100%"} height={280} />
                ) : (
                  <>
                    {resourcesMLModule &&
                      resourcesMLModule.length > 0 &&
                      resourcesMLModule.map((item) => {
                        if (item.idMod === 3 && item.idModContent === 41) {
                          // if (!isMobile) {
                          //   {
                          //     return item.content.map((res, idx) => {
                          //       if (res.idModuleContentResourcesDetailType === 3) {
                          //         return (
                          //           <img key={idx} alt={res.name} src={res.image} />
                          //         );
                          //       }
                          //     });
                          //   }
                          // } else {
                          return item.content.map((res, idx) => {
                            //if (res.idModuleContentResourcesDetailType === 1) {
                            if (res.idModuleContentResourcesDetailType === 3) {
                              return (
                                <img
                                  key={idx}
                                  alt={res.name}
                                  src={res.image}
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src =
                                      "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
                                  }}
                                />
                              );
                            }
                          });
                          //}
                        }
                      })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`enterprising__headers__message ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          <div
            className={`enterprising__headers__message__one ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            {isLanguageLoaded ? (
              t("__entrepreneur_description_text")
            ) : (
              <Skeleton
                variant="rect"
                width={isMobile ? "90%" : "50%"}
                height={30}
                style={{ margin: "5px" }}
              />
            )}
          </div>
          {isLanguageLoaded ? (
            <ul>
              <li>{t("__entrepreneur_some_reason_label")}</li>
              <li>{t("__entrepreneur_some_reason1_label")}</li>
              <li>{t("__entrepreneur_some_reason2_label")}</li>
              <li> {t("__entrepreneur_some_reason3_label")}</li>
              <li>{t("__entrepreneur_some_reason4_label")}</li>
              <li>{t("__entrepreneur_some_reason5_label")}</li>
            </ul>
          ) : (
            <ul>
              {[0, 1, 2, 3, 4].map((item) => {
                return (
                  <li key={item}>
                    <Skeleton
                      variant="rect"
                      width={isMobile ? "90%" : "50%"}
                      height={30}
                      style={{ margin: "5px" }}
                    />
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>

      <div
        className={`new__favorite__customer__content enterprising__content ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }`}
      >
        <div
          className={`new__favorite__customer__content__product ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          <div
            className={`new__favorite__customer__content__product__program ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            {!isLoadingEnable ? (
              <>
                <div
                  className={`new__favorite__customer__content__product__program__list ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  }`}
                >
                  {isLanguageLoaded ? (
                    t("__favorite_customer_program")
                  ) : (
                    <Skeleton
                      variant="rect"
                      width={isMobile ? "90%" : "50%"}
                      height={30}
                      style={{ margin: "5px" }}
                    />
                  )}
                  <ul>
                    {isLanguageLoaded ? (
                      <>
                        {" "}
                        <li>{t("__entrepreneur_program_list_one")}</li>
                        <li>{t("__entrepreneur_program_list_two")}</li>
                        <li> {t("__entrepreneur_program_list_three")}</li>
                        <li> {t("__entrepreneur_program_list_four")}</li>
                      </>
                    ) : (
                      [0, 1, 2].map((item) => {
                        return (
                          <li key={item}>
                            <Skeleton
                              variant="rect"
                              width={isMobile ? "90%" : "70%"}
                              height={30}
                              style={{ margin: "5px" }}
                            />
                          </li>
                        );
                      })
                    )}
                  </ul>
                </div>
              </>
            ) : (
              <Skeleton
                variant="rect"
                width={"90%"}
                height={isTablet ? 200 : isMobile ? 50 : 100}
                style={{ marginBottom: 10 }}
              />
            )}
          </div>
          <div
            className={`new__favorite__customer__content__product__imagen ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            {/* {admissionKits.map((kit, index) => {
              return (

              );
            })} */}
            {/* <LockProduct /> */}
            {kitSelected.hasOwnProperty("nameArticle") && (
              <div
                className={`new__favorite__customer__content__product__imagen__content ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                <div
                  className={`new__favorite__customer__content__product__imagen__content__img ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  }`}
                >
                  {!isLoadingEnable ? (
                    <img
                      alt="set"
                      src={kitSelected.image}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src =
                          "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
                      }}
                    />
                  ) : (
                    <Skeleton
                      variant="rect"
                      width={"100%"}
                      height={isTablet ? 200 : isMobile ? 290 : 100}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
          <div
            className={`new__favorite__customer__content__product__description ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            <div
              className={`new__favorite__customer__content__product__description__content ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              {/* <div
                className={`new__favorite__customer__content__product__description__content__favorite ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                {" "}
                {!isLoadingEnable ? (
                  <>
                    <FormControlLabel
                      onChange={handleChangeFavorite}
                      control={
                        <Checkbox
                          icon={<div className="heartOutlineIcon" />}
                          checkedIcon={<div className="heartFillIcon" />}
                        />
                      }
                    />
                  </>
                ) : (
                  <Skeleton width={"100%"} height={50} />
                )}{" "}
              </div> */}
              <div
                className={`new__favorite__customer__content__product__description__content__info ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                {/* {admissionKits.map((kit, index) => {
                  return (
                  
                  );
                })} */}
                {kitSelected.hasOwnProperty("nameArticle") && (
                  <div
                    className={`new__favorite__customer__content__product__description__content__info_gral ${
                      isTablet ? "tablet" : isMobile ? "mobile" : ""
                    }`}
                  >
                    {!isLoadingEnable ? (
                      <>
                        <div
                          className={`new__favorite__customer__content__product__description__content__info_one ${
                            isTablet ? "tablet" : isMobile ? "mobile" : ""
                          }`}
                        >
                          {/* <Typography>{kitSelected.descArticle}</Typography> */}
                          <div>
                            <Typography>
                              {capitalizeText(kitSelected.nameArticle)}
                            </Typography>
                            <Typography className="price__red">
                              {formatPrice(kitSelected.promotionPrice)}
                            </Typography>
                          </div>
                        </div>
                        <div
                          className={`new__favorite__customer__content__product__description__content__info_two ${
                            isTablet ? "tablet" : isMobile ? "mobile" : ""
                          }`}
                        >
                          {" "}
                          <div className="detail__ranking">
                            {!isLoadingEnable ? (
                              <>
                                {" "}
                                <Rating
                                  name="simple-controlled"
                                  size="small"
                                  value={5}
                                  emptyIcon={<StarBorder fontSize="inherit" />}
                                  // onChange={(event, newValue) => {
                                  //   setValue(newValue);
                                  // }}
                                  readOnly
                                />
                                <span className="detail__ranking__number">
                                  4.5
                                </span>
                              </>
                            ) : (
                              <Skeleton
                                variant="rect"
                                height={isTablet ? 20 : isMobile ? 30 : 100}
                                width={"100%"}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <Skeleton
                        variant="rect"
                        width={"100%"}
                        height={isTablet ? 20 : isMobile ? 30 : 50}
                        style={{ marginTop: 30 }}
                      />
                    )}
                  </div>
                )}
              </div>

              <div
                className={`new__favorite__customer__content__product__variants ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                {!isLoadingEnable ? (
                  <VariantDetailComponent
                    itemOnClick={(item) => {
                      const kit = admissionKits.find(
                        (i) => i.itemCode === item.id
                      );
                      //console.log("KIT", kit);
                      setKitSelected(kit);
                    }}
                    isColor={false}
                    arrayFake={admissionKits.map((item) => ({
                      id: item.itemCode,
                      urlPath: item.image,
                      nameProduct: capitalizeText(item.nameArticle),
                    }))}
                    activeId={kitSelected.itemCode}
                  />
                ) : (
                  <Skeleton
                    variant="rect"
                    width={"90%"}
                    height={isTablet ? 100 : isMobile ? 50 : 200}
                    style={{ marginTop: 30 }}
                  />
                )}
              </div>

              <div
                className={`new__favorite__customer__content__product__description__content__actions ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                {!isLoadingEnable ? (
                  <>
                    {" "}
                    <Button
                      className="button__with__icon no__left__margin bg__dark__pink"
                      onClick={() => {
                        if (kitSelected.typeLine === "P") {
                          handleAddMembership();
                        } else {
                          history.push(
                            `/kit-config/${kitSelected.itemCode}/${kitSelected.typeLine}`
                          );
                        }
                      }}
                    >
                      <div className="button__with__icon__svg__wrapper dark__pink__ball">
                        <div className="bagWhiteIcon" />
                      </div>
                      <span>
                        {kitSelected.typeLine === "P"
                          ? isLanguageLoaded
                            ? t("btn_cart_add_new")
                            : ""
                          : isLanguageLoaded
                          ? t("btn_cart_set_up_new")
                          : ""}
                      </span>
                    </Button>
                  </>
                ) : (
                  <Skeleton
                    variant="rect"
                    width={isMobile ? "90%" : "50%"}
                    height={30}
                    style={{ marginTop: 10, marginLeft: 20 }}
                  />
                )}
              </div>
            </div>
            <div
              className={`enterprising__container__benefits ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              {!isLoadingEnable ? (
                <>
                  <span>
                    {isLanguageLoaded ? (
                      t("__favorite_customer_benefit_label")
                    ) : (
                      <Skeleton
                        variant="rect"
                        width={isMobile ? "90%" : "50%"}
                        height={30}
                        style={{ marginTop: 10, marginLeft: 20 }}
                      />
                    )}
                  </span>
                  <ul>
                    {isLanguageLoaded ? (
                      <>
                        <li>
                          <div className="enterprising__benefit__paragraph">
                            {t("__entrepreneur_new_benefit_text")}
                          </div>
                        </li>
                        {/* <li>
                          <div className="enterprising__benefit__paragraph">
                            {t("__entrepreneur_benefit_2")}
                            {formatPrice(50)}
                            {t("__entrepreneur_benefit_conect_to")}
                            {formatPrice(169.9)}
                          </div>
                        </li>
                        <li>
                          <div className="enterprising__benefit__paragraph">
                            {t("__entrepreneur_benefit_3")}
                            {formatPrice(170)}
                            {t("__entrepreneur_benefit_conect_to")}
                            {formatPrice(300.9)}
                          </div>
                        </li>
                        <li>
                          <div className="enterprising__benefit__paragraph">
                            {t("__entrepreneur_benefit_4")}
                            {formatPrice(301)}
                            {t("__entrepreneur_benefit_4_1")}
                          </div>
                        </li> */}
                      </>
                    ) : (
                      [0, 1, 2].map((item) => {
                        return (
                          <li key={item}>
                            <Skeleton
                              variant="rect"
                              width={"70%"}
                              height={30}
                              style={{ margin: "5px" }}
                            />
                          </li>
                        );
                      })
                    )}
                  </ul>
                  <span>
                    {isLanguageLoaded ? (
                      // t("__entrepreneur_benefit_5")

                      <>
                        <li>
                          <div className="enterprising__benefit__paragraph">
                            {t("__entrepreneur_benefit_2")}
                          </div>
                        </li>

                        <li>
                          <div className="enterprising__benefit__paragraph">
                            {t("__entrepreneur_benefit_3")}
                          </div>
                        </li>

                        <li>
                          <div className="enterprising__benefit__paragraph">
                            {t("__entrepreneur_benefit_4")}
                          </div>
                        </li>
                      </>
                    ) : (
                      <Skeleton
                        variant="rect"
                        width={isMobile ? "90%" : "50%"}
                        height={30}
                        style={{ marginTop: 10 }}
                      />
                    )}
                  </span>
                </>
              ) : (
                <Skeleton variant="rect" width={"90%"} height={200} />
              )}
            </div>
            <div
              className={`new__favorite__customer__content__benefits ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              {!isLoadingEnable ? (
                <>
                  {kitSelected.hasOwnProperty("nameArticle") && (
                    <>
                      <span>
                        {isLanguageLoaded ? (
                          t("__favorite_customer_products_label")
                        ) : (
                          <Skeleton
                            variant="rect"
                            width={isMobile ? "90%" : "50%"}
                            height={30}
                            style={{ marginTop: 10 }}
                          />
                        )}
                      </span>
                      <List>{renderListProducts(kitSelected.descArticle)}</List>
                    </>
                  )}
                </>
              ) : (
                <Skeleton variant="rect" width={"90%"} height={200} />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="full__video__wrapper">
        <iframe
          src="https://www.youtube.com/embed/nCeB-ppqk3s"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen=""
        ></iframe>
      </div>
    </div>
  );
};

export default NewEnterprisingContainer;
