import React from "react";
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Close } from "@mui/icons-material";

const ModalWarning = ({
  isOpenModalWarning,
  handleOpenWarning,
  iconMain,
  title,
  subtitle,
  text,
  isButtonActive,
  isButtonText,
  navigationURL,
  isActiveStyleInSubtitle,
  listActive,
  listItems,
}) => {
  return (
    <Dialog
      open={isOpenModalWarning}
      className="modal__warning"
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogContent dividers={true}>
        <div className="modal__warning__title">
          <div className="modal__terms__and__coditions__title__icon">
            <IconButton onClick={handleOpenWarning} aria-label="close">
              <Close fontSize="small" />
            </IconButton>
          </div>
        </div>
        <div
          id="scroll-dialog-description"
          className="modal__warning__content"
          tabIndex={-1}
        >
          <div className={iconMain} />

          <Typography
            className="modal__warning__content__title"
            variant="h6"
            gutterBottom
          >
            {title}
          </Typography>

          <div
            className={
              isActiveStyleInSubtitle
                ? "modal__warning__content__subtitle__special"
                : "modal__warning__content__subtitle"
            }
          >
            <Typography variant="body1" gutterBottom>
              {subtitle}
            </Typography>
          </div>
          <Typography variant="body1" gutterBottom>
            {listActive ? (
              <List dense={true}>
                {listItems.map((item, index) => {
                  return (
                    <ListItem key={index}>
                      <ListItemText primary={item} />
                    </ListItem>
                  );
                })}
              </List>
            ) : (
              text
            )}
          </Typography>
          <br />
          {isButtonActive && (
            <div>
              <Button onClick={navigationURL} variant="contained">
                {isButtonText}
              </Button>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ModalWarning;
