import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const TermsAndConditionsContainer = () => {
  /// TRANSLATION
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "Zermat USA :: Terms and Conditions";
  }, []);

  return (
    <div
      id="firstWrapperInBaseContainer"
      className="firstWrapperInBaseContainer"
    >
      <div className="polices__privacy__container">
        <div>
          <Typography variant="h6" gutterBottom className="text__red">
            {t("__termsAndConditions_title_Txt")}
          </Typography>
        </div>
        <div>
          <Typography variant="body1" gutterBottom>
            <div>{t("__termsAndConditions_paragraph1_Txt")} </div>
            <br />
            <div>{t("__termsAndConditions_paragraph2_Txt")} </div>
            <br />
            <div>{t("__termsAndConditions_paragraph3_Txt")} </div>
            <br />
          </Typography>
        </div>
        <div className="text__golden">
          <Typography variant="subtitle1" gutterBottom>
            {t("__termsAndConditions_Rights_title_Txt")}
          </Typography>
        </div>
        <div>
          <Typography variant="body1" gutterBottom>
            <div>{t("__termsAndConditions__Rights_paragraph1_Txt")} </div>
            <br />
            <div>{t("__termsAndConditions_Rights_paragraph2_Txt")} </div>
            <br />
            <div>{t("__termsAndConditions_Rights_paragraph3_Txt")} </div>
            <br />
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsContainer;
