import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
//MUI
import { IconButton } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
//Assets
import { ReactComponent as BagActiveIcon } from "./../../../../assets/media/images/icons/bagActiveWhiteIcon.svg";
import { ReactComponent as TrashIcon } from "./../../../../assets/media/images/icons/trashWhiteIcon.svg";

const Counter = ({
  qtyOnOrder = 0,
  handleQtyMinus,
  disableMinus = false,
  handleQtyAdd,
  disableAdd = false,
  isPersonalizedFragrance,
  handleRemove,
  disabledRemove = false,
  withIcon = true,
  withTrash = true,
  showOnlyQty = false,
}) => {
  //Translation
  const { t } = useTranslation();

  return (
    <div className="counter__wrapper">
      {withIcon && (
        <Fragment>
          {qtyOnOrder > 0 && withTrash ? ( //&& !showOnlyQty
            <IconButton disabled={disabledRemove} onClick={handleRemove}>
              <TrashIcon />
            </IconButton>
          ) : !showOnlyQty ? (
            <BagActiveIcon />
          ) : (
            <span></span>
          )}
        </Fragment>
      )}

      {showOnlyQty ? (
        <div className="counter__wrapper__is__personalized__fragrance">
          <span className="kit__configuration__container__configurables__main__configurable__counters__counter__item__list__wrapper__list__item__counter__wrapper__counter__number">
            {qtyOnOrder}
          </span>
          {/* <span>{`${t("__general__fragrance_label")}${
            qtyOnOrder > 1 ? "s" : ""
          }`}</span> */}
        </div>
      ) : (
        <Fragment>
          <IconButton onClick={handleQtyMinus} disabled={disableMinus}>
            <Remove />
          </IconButton>

          <span className="kit__configuration__container__configurables__main__configurable__counters__counter__item__list__wrapper__list__item__counter__wrapper__counter__number">
            {qtyOnOrder}
          </span>
          <IconButton onClick={handleQtyAdd} disabled={disableAdd}>
            <Add />
          </IconButton>
        </Fragment>
      )}
    </div>
  );
};

export default Counter;
