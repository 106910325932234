import React, { useEffect } from "react";
import { Typography, Skeleton } from "@mui/material";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

const PolicesShippingContainer = ({ isLanguageLoaded }) => {
  /// TRANSLATION
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "Zermat USA :: Shipping Polices";
  }, []);

  return (
    <div
      id="firstWrapperInBaseContainer"
      className="firstWrapperInBaseContainer"
    >
      <div className="polices__shipping__content">
        <div className="polices__shipping__content__title">
          {isLanguageLoaded ? (
            <Typography variant="h6" gutterBottom className="text__red">
              {t("__policesShipping_title_Txt")}
            </Typography>
          ) : (
            <Skeleton
              variant="rect"
              width={isMobile ? "90%" : "30%"}
              height={30}
            />
          )}
        </div>

        <div className="text__black polices__shipping__content__subtitle">
          {isLanguageLoaded ? (
            <Typography variant="subtitle1" gutterBottom>
              {t("__policesShipping_subtitle_Re-delivery_under_Money_Txt")}
            </Typography>
          ) : (
            <Skeleton variant="rect" width={"90%"} height={30} />
          )}
        </div>
        <div>
          {isLanguageLoaded ? (
            <Typography variant="body1" gutterBottom>
              {t("__policesShipping__Re-delivery_under_Money_paragraph1_Txt")}
            </Typography>
          ) : (
            <Skeleton
              variant="rect"
              width={isMobile ? "90%" : "30%"}
              height={30}
            />
          )}
        </div>
        <div className="text__black polices__shipping__content__subtitle">
          {isLanguageLoaded ? (
            <Typography variant="subtitle1" gutterBottom>
              {t("__policesShipping_subtitle_Re-delivery_under_Credit_Txt")}
            </Typography>
          ) : (
            <Skeleton variant="rect" width={"90%"} height={30} />
          )}
        </div>
        <div>
          {isLanguageLoaded ? (
            <Typography variant="body1" gutterBottom>
              {t("__policesShipping__Re-delivery_under_Credit_paragraph1_Txt")}
            </Typography>
          ) : (
            <Skeleton variant="rect" width={"90%"} height={30} />
          )}
        </div>
        <div className="text__black polices__shipping__content__subtitle">
          {isLanguageLoaded ? (
            <Typography variant="subtitle1" gutterBottom>
              {t("__policesShipping_subtitle_Cancellations")}
            </Typography>
          ) : (
            <Skeleton
              variant="rect"
              width={isMobile ? "90%" : "30%"}
              height={30}
            />
          )}
        </div>
        <div>
          {isLanguageLoaded ? (
            <Typography variant="body1" gutterBottom>
              {t("__policesShipping_Cancellations_paragraph1_Txt")}
            </Typography>
          ) : (
            <Skeleton variant="rect" width={"90%"} height={30} />
          )}
        </div>
        <div>
          {isLanguageLoaded ? (
            <Typography variant="body1" gutterBottom>
              {t("__policesShipping_Cancellations_paragraph2_Txt")}
            </Typography>
          ) : (
            <Skeleton variant="rect" width={"90%"} height={30} />
          )}
        </div>
        <div>
          {isLanguageLoaded ? (
            <Typography variant="body1" gutterBottom>
              {t("__policesShipping_Cancellations_paragraph3_Txt")}
            </Typography>
          ) : (
            <Skeleton variant="rect" width={"90%"} height={30} />
          )}
        </div>
        <div>
          {isLanguageLoaded ? (
            <Typography variant="body1" gutterBottom>
              {t("__policesShipping_Cancellations_paragraph4_Txt")}
            </Typography>
          ) : (
            <Skeleton variant="rect" width={"90%"} height={30} />
          )}
        </div>
        <div className="text__black polices__shipping__content__subtitle">
          {isLanguageLoaded ? (
            <Typography variant="subtitle1" gutterBottom>
              {t("__policesShipping_subtitle_Lost_packages")}
            </Typography>
          ) : (
            <Skeleton
              variant="rect"
              width={isMobile ? "90%" : "30%"}
              height={30}
            />
          )}
        </div>
        <div>
          {isLanguageLoaded ? (
            <Typography variant="body1" gutterBottom>
              {t("__policesShipping_Lost_packages_paragraph1_Txt")}
            </Typography>
          ) : (
            <Skeleton variant="rect" width={"90%"} height={30} />
          )}
        </div>
        <div>
          {isLanguageLoaded ? (
            <Typography variant="body1" gutterBottom>
              {t("__policesShipping_Lost_packages_paragraph2_Txt")}
            </Typography>
          ) : (
            <Skeleton variant="rect" width={"90%"} height={30} />
          )}
        </div>
        <div>
          {isLanguageLoaded ? (
            <Typography variant="body1" gutterBottom>
              {t("__policesShipping_Lost_packages_paragraph3_Txt")}
            </Typography>
          ) : (
            <Skeleton variant="rect" width={"90%"} height={30} />
          )}
        </div>
        <div className="text__black polices__shipping__content__subtitle">
          {isLanguageLoaded ? (
            <Typography variant="subtitle1" gutterBottom>
              {t("__policesShipping_subtitle_Address_Changes_Txt")}
            </Typography>
          ) : (
            <Skeleton
              variant="rect"
              width={isMobile ? "90%" : "30%"}
              height={30}
            />
          )}
        </div>
        <div>
          {isLanguageLoaded ? (
            <Typography variant="body1" gutterBottom>
              {t("__policesShipping_Address_Changes_paragraph1_Txt")}
            </Typography>
          ) : (
            <Skeleton variant="rect" width={"90%"} height={30} />
          )}
        </div>
        <div className="text__black polices__shipping__content__subtitle">
          {isLanguageLoaded ? (
            <Typography variant="subtitle1" gutterBottom>
              {t("__policesShipping_Address_fee_title_Txt")}
            </Typography>
          ) : (
            <Skeleton
              variant="rect"
              width={isMobile ? "90%" : "30%"}
              height={30}
            />
          )}
        </div>
        <div>
          {isLanguageLoaded ? (
            <Typography variant="body1" gutterBottom>
              {t("__policesShipping_Address_fee_paragraph1_Txt")}{" "}
              {t("__policesShipping_Address_fee_paragraph2_Txt")}
            </Typography>
          ) : (
            <Skeleton variant="rect" width={"90%"} height={30} />
          )}
        </div>
        <div>
          {isLanguageLoaded ? (
            <Typography variant="body1" gutterBottom>
              {t("__policesShipping_Address_fee_paragraph3_Txt")}
              <br />
              <em> *{t("__policesShipping_Address_fee_paragraph4_Txt")}</em>
            </Typography>
          ) : (
            <Skeleton variant="rect" width={"90%"} height={30} />
          )}
        </div>
        <div>
          {isLanguageLoaded ? (
            <Typography variant="body1" gutterBottom>
              {t("__policesShipping_Address_fee_paragraph5_Txt")}
              <br />
              <em> *{t("__policesShipping_Address_fee_paragraph6_Txt")}</em>
            </Typography>
          ) : (
            <Skeleton variant="rect" width={"90%"} height={30} />
          )}
        </div>
        <div>
          {isLanguageLoaded ? (
            <Typography variant="body1" gutterBottom>
              {t("__policesShipping_Address_fee_paragraph7_Txt")}
              <br />
              {t("__policesShipping_Address_fee_paragraph8_Txt")}
              <em>{t("__policesShipping_Address_fee_paragraph9_Txt")}</em>
              {t("__policesShipping_Address_fee_paragraph10_Txt")}
            </Typography>
          ) : (
            <Skeleton variant="rect" width={"90%"} height={30} />
          )}
        </div>
        <div id="taxes" className="text__black">
          {isLanguageLoaded ? (
            <Typography variant="body1" gutterBottom>
              {t(
                "__policesShipping__Re-delivery_charge__texas_Money_paragraph1_Subitle"
              )}
            </Typography>
          ) : (
            <Skeleton
              variant="rect"
              width={isMobile ? "90%" : "30%"}
              height={30}
            />
          )}
        </div>
        <div className=" polices__shipping__content__subtitle">
          {isLanguageLoaded ? (
            <Typography variant="subtitle1" gutterBottom>
              {t("__policesShipping_subtitle_Re-delivery_charge__texas_Txt")}
            </Typography>
          ) : (
            <Skeleton variant="rect" width={"90%"} height={30} />
          )}
        </div>
      </div>
    </div>
  );
};

export default PolicesShippingContainer;
