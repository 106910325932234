import React, { Fragment, useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Typography,
  Radio,
  RadioGroup,
  List,
  ListItem,
  FormControl,
  IconButton,
  TextField,
  MenuItem,
  Tooltip,
  Checkbox,
  InputAdornment,
  InputLabel,
  Input,
  Card,
  CardContent,
} from "@mui/material";
import { Add, Remove, Save, DeleteForever, Error } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { ModalProductBanch } from "./ModalProductBanch";
import { isMobile } from "react-device-detect";

export const CardsMobileRepayment = ({
  value,
  handleChange,
  detailShopping,
  setIsOpenNotification,
  exchangeRateList,
  reasonsReturnList,
  kitsArray,
  setKitsArray,
  expanded,
  setExpanded,
  setIsSelectAnyItem,
}) => {
  //// state

  const [isOpenProductBatch, setIsOpenProductBatch] = useState(false);

  //// TRANSLATION
  const { t, i18n } = useTranslation();

  //// EFFECTS
  /// HOOK EFECTO DE LA LISTA
  useEffect(() => {
    if (detailShopping && detailShopping.length > 0) {
      if (kitsArray && kitsArray.length === 0) {
        let finalKits = [];
        detailShopping.forEach((element) => {
          element["piecesR"] = 0;

          ///+++NEW+++\\\
          //tipo de cambio
          element["exchangeType"] = 0;
          //rason de cambio
          element["reasonsReturn"] = 0;
          //seleccionado para devolver
          element["selectedReturn"] = false;

          element["comment"] = "";
        });
        const kits = detailShopping.filter(
          (item) =>
            item.typeLine !== "C" &&
            item.typeLine !== "N" &&
            item.typeLine !== "PD" &&
            item.typeLine !== "R"
        );
        const components = detailShopping.filter(
          (item) => item.typeLine === "C" || item.typeLine === "R"
        );
        const lineProduct = detailShopping.filter(
          (item) => item.typeLine === "N" || item.typeLine === "PD"
        );

        let newArrayKit = [];
        kits.forEach((i) => {
          const result = components.filter((e) => e.codean === i.idPedidoDet);
          const final = { ...i, child: result };
          newArrayKit.push(final);
        });
        finalKits = lineProduct.concat(newArrayKit);

        setKitsArray(finalKits);
      }
      // if (kitsArray && kitsArray.length > 0) {
      //   let finalKits = [];

      //   const kits = detailShopping.filter((item) => item.typeLine !== "C" && item.typeLine !== "N");
      //   const components = detailShopping.filter((item) => item.typeLine === "C");
      //   const lineProduct = detailShopping.filter((item) => item.typeLine === "N");

      //   finalKits.push(lineProduct);
      //   setKitsArray(finalKits);

      //   kits.forEach((i) => {
      //     const result = components.filter((e) => e.codean === i.idPedidoDet);
      //     const final = { ...i, child: result };
      //     finalKits.push(final);
      //   });
      // }
    }
  }, [detailShopping]);

  ///// FUNCIONES
  const handleChangeKit = (panel, event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSelectOpc = (event, i) => {
    let newArr = [...kitsArray]; // copying the old datas array
    newArr[i].reasonsReturn = event; // replace e.target.value with whatever you want to change it to

    if (event === 7) {
      newArr[i].comment = "";

      let current = document.getElementById(`parent${i}`);
      let children = current.children;
      document
        .getElementById(`is_other_active${i}`)
        .setAttribute("class", "is_active");
      children = [...children];
      const inputWrapper = children[0];
      const rowWrapper = children[1];
      inputWrapper.setAttribute("style", "display:block;");
      rowWrapper.setAttribute("style", "display:none;");
    } else {
      newArr[i].comment = t(
        reasonsReturnList.find((e) => e.id === event).value
      );
    }
    setKitsArray(newArr);
  };

  /// CHANGE PRODUCT BATCH
  const handleChangeProductBatch = (index, value) => {
    let cloneKitsArray = [...kitsArray];

    cloneKitsArray[index].productBatch = value;

    setKitsArray(cloneKitsArray);
  };

  const handleSelectOpcItem = (event, index, i) => {
    let newArr = [...kitsArray]; // copying the old datas array
    let newArrChilds = newArr[index].child;
    newArrChilds[i].reasonsReturn = event; // replace e.target.value with whatever you want to change it to

    if (event === 7) {
      newArrChilds[i].comment = "";

      let current = document.getElementById(`child${index}` + `_${i}`);
      let children = current.children;
      document
        .getElementById(`is_children_active${index}` + `_${i}`)
        .setAttribute("class", "is_active");
      children = [...children];
      const inputWrapper = children[0];
      const rowWrapper = children[1];
      inputWrapper.setAttribute("style", "display:block;");
      rowWrapper.setAttribute("style", "display:none;");
    } else {
      newArrChilds[i].comment = t(
        reasonsReturnList.find((e) => e.id === event).value
      );
    }
    setKitsArray(newArr);
  };

  const handleSelectReturnRepayment = (index, value) => {
    let newArr = [...kitsArray]; // copying the old datas array

    newArr[index].exchangeType = value; // replace e.target.value with whatever you want to change it to

    setKitsArray(newArr);
  };

  const handleSelectChildsReturnRepayment = (index, idxParent, value) => {
    let newArr = [...kitsArray]; // copying the old datas array

    let newArrChilds = newArr[idxParent].child;

    newArrChilds[index].exchangeType = value; // replace e.target.value with whatever you want to change it to

    setKitsArray(newArr);
  };

  const handleRemoveComment = (i) => {
    let current = document.getElementById(`parent${i}`);
    let children = current.children;
    children = [...children];
    const inputWrapper = children[0];
    const rowWrapper = children[1];
    inputWrapper.setAttribute("style", "display:none;");
    rowWrapper.setAttribute("style", "display:block;");
    document
      .getElementById(`is_other_active${i}`)
      .setAttribute("class", "line_n");
  };

  const handleSaveComment = (i) => {
    setIsOpenNotification(true);
    setTimeout(() => {
      let current = document.getElementById(`parent${i}`);
      let children = current.children;

      children = [...children];
      const inputWrapper = children[0];
      const rowWrapper = children[1];
      inputWrapper.setAttribute("style", "display:none;");
      rowWrapper.setAttribute("style", "display:block;");
      document
        .getElementById(`is_other_active${i}`)
        .setAttribute("class", "line_n");
    }, 2000);
  };

  const handleRemoveCommentItem = (index, i) => {
    let current = document.getElementById(`child${index}` + `_${i}`);
    let children = current.children;
    children = [...children];
    const inputWrapper = children[0];
    const rowWrapper = children[1];
    inputWrapper.setAttribute("style", "display:none;");
    rowWrapper.setAttribute("style", "display:block;");

    document
      .getElementById(`is_children_active${index}` + `_${i}`)
      .setAttribute("class", "line_n");
  };

  const handleSaveCommentItem = (index, i) => {
    setIsOpenNotification(true);
    setTimeout(() => {
      let current = document.getElementById(`child${index}` + `_${i}`);
      let children = current.children;
      // console.log(current);
      children = [...children];
      const inputWrapper = children[0];
      const rowWrapper = children[1];
      inputWrapper.setAttribute("style", "display:none;");
      rowWrapper.setAttribute("style", "display:block;");
      document
        .getElementById(`is_children_active${index}` + `_${i}`)
        .setAttribute("class", "line_n");
    }, 2000);
  };

  const handleAddElement = (val) => {
    let cloneKitsArray = [...kitsArray];
    if (val.cant > val.piecesR) {
      const found = cloneKitsArray.findIndex(
        (element) => element.idPedidoDet === val.idPedidoDet
      );

      if (found >= 0) {
        cloneKitsArray[found].piecesR = val.piecesR + 1;
      }

      let flagSelectArt = 0;
      cloneKitsArray.map((elemt) => {
        if (elemt?.child && elemt.child.length > 0) {
          if (elemt.piecesR > 0) {
            flagSelectArt = 1;
            return;
          } else {
            const cloneChildArray = elemt.child;
            cloneChildArray.map((child) => {
              if (child.piecesR > 0) {
                flagSelectArt = 1;
                return;
              }
            });
          }
        } else {
          if (elemt.piecesR > 0) {
            //si el papa (SET TIENE ESTA SELECCIONADO CON +1)
            flagSelectArt = 1;
            return;
          }
        }
      });

      setKitsArray([...cloneKitsArray]);
      setIsSelectAnyItem(flagSelectArt);
    }
  };

  const handleRemoveElement = (val) => {
    let cloneKitsArray = [...kitsArray];
    if (val.cant >= val.piecesR || val.piecesR === -1) {
      const found = cloneKitsArray.findIndex(
        (element) => element.idPedidoDet === val.idPedidoDet
      );

      if (found >= 0) {
        cloneKitsArray[found].piecesR = val.piecesR - 1;
      }

      let flagSelectArt = 0;
      cloneKitsArray.map((elemt) => {
        if (elemt?.child && elemt.child.length > 0) {
          if (elemt.piecesR > 0) {
            flagSelectArt = 1;
            return;
          } else {
            const cloneChildArray = elemt.child;
            cloneChildArray.map((child) => {
              if (child.piecesR > 0) {
                flagSelectArt = 1;
                return;
              }
            });
          }
        } else {
          if (elemt.piecesR > 0) {
            //si el papa (SET TIENE ESTA SELECCIONADO CON +1)
            flagSelectArt = 1;
            return;
          }
        }
      });

      setKitsArray([...cloneKitsArray]);
      setIsSelectAnyItem(flagSelectArt);
    }
  };

  ///AGRGAR NUMERO COMPONENTES DE SET\\\
  const handleAddElementChild = (idx, idxParent, val) => {
    let cloneKitsArray = [...kitsArray];

    if (val.cant > val.piecesR) {
      let newArrChilds = cloneKitsArray[idxParent].child;

      newArrChilds[idx].piecesR = val.piecesR + 1;

      let flagSelectArt = 0;
      cloneKitsArray.map((elemt) => {
        if (elemt?.child && elemt.child.length > 0) {
          if (elemt.piecesR > 0) {
            flagSelectArt = 1;
            return;
          } else {
            const cloneChildArray = elemt.child;
            cloneChildArray.map((child) => {
              if (child.piecesR > 0) {
                flagSelectArt = 1;
                return;
              }
            });
          }
        } else {
          if (elemt.piecesR > 0) {
            //si el papa (SET TIENE ESTA SELECCIONADO CON +1)
            flagSelectArt = 1;
            return;
          }
        }
      });

      setKitsArray([...cloneKitsArray]);
      setIsSelectAnyItem(flagSelectArt);
    }
  };

  ///ELIMINAR NUMERO COMPONENTES DE SET\\\
  const handleRemoveElementChild = (idx, idxParent, val) => {
    let cloneKitsArray = [...kitsArray];

    if (val.cant >= val.piecesR || val.piecesR === -1) {
      let newArrChilds = cloneKitsArray[idxParent].child;

      newArrChilds[idx].piecesR = val.piecesR - 1;

      let flagSelectArt = 0;
      cloneKitsArray.map((elemt) => {
        if (elemt?.child && elemt.child.length > 0) {
          if (elemt.piecesR > 0) {
            flagSelectArt = 1;
            return;
          } else {
            const cloneChildArray = elemt.child;
            cloneChildArray.map((child) => {
              if (child.piecesR > 0) {
                flagSelectArt = 1;
                return;
              }
            });
          }
        } else {
          if (elemt.piecesR > 0) {
            //si el papa (SET TIENE ESTA SELECCIONADO CON +1)
            flagSelectArt = 1;
            return;
          }
        }
      });

      setKitsArray([...cloneKitsArray]);
      setIsSelectAnyItem(flagSelectArt);
    }
  };

  ///SELECCION DE ARTICULO(CHECK)
  const handleSelectedReturnItem = (index, value) => {
    let newArr = [...kitsArray]; // copying the old datas array
    newArr[index].selectedReturn = value; // replace e.target.value with whatever you want to change it to
    if (!value) {
      newArr[index].exchangeType = 0;
      newArr[index].reasonsReturn = 0;
      newArr[index].piecesR = 0;
      newArr[index].comment = "";
    }
    if (newArr[index]?.child && newArr[index].child.length > 0) {
      let newArrChilds = newArr[index].child;

      newArrChilds.map((item, idx) => {
        newArrChilds[idx].selectedReturn = false;
        newArrChilds[idx].exchangeType = 0;
        newArrChilds[idx].reasonsReturn = 0;
        newArrChilds[idx].piecesR = 0;
        newArrChilds[idx].comment = "";
      });
    }

    let flagSelectArt = 0;
    newArr.map((elemt) => {
      if (elemt?.child && elemt.child.length > 0) {
        if (elemt.piecesR > 0) {
          flagSelectArt = 1;
          return;
        } else {
          const cloneChildArray = elemt.child;
          cloneChildArray.map((child) => {
            if (child.piecesR > 0) {
              flagSelectArt = 1;
              return;
            }
          });
        }
      } else {
        if (elemt.piecesR > 0) {
          //si el papa (SET TIENE ESTA SELECCIONADO CON +1)
          flagSelectArt = 1;
          return;
        }
      }
    });

    setKitsArray(newArr);
    setIsSelectAnyItem(flagSelectArt);
  };

  ///SELECCION DE ARTICULO(CHECK)
  const handleSelectedReturnItemChild = (index, idxParent, value) => {
    let newArr = [...kitsArray]; // copying the old datas array

    let newArrChilds = newArr[idxParent].child;

    newArrChilds[index].selectedReturn = value; // replace e.target.value with whatever you want to change it to

    if (!value) {
      newArrChilds[index].exchangeType = 0;
      newArrChilds[index].reasonsReturn = 0;
      newArrChilds[index].piecesR = 0;
      newArrChilds[index].comment = "";
    }

    let flagSelectArt = 0;
    newArr.map((elemt) => {
      if (elemt?.child && elemt.child.length > 0) {
        if (elemt.piecesR > 0) {
          flagSelectArt = 1;
          return;
        } else {
          const cloneChildArray = elemt.child;
          cloneChildArray.map((child) => {
            if (child.piecesR > 0) {
              flagSelectArt = 1;
              return;
            }
          });
        }
      } else {
        if (elemt.piecesR > 0) {
          //si el papa (SET TIENE ESTA SELECCIONADO CON +1)
          flagSelectArt = 1;
          return;
        }
      }
    });

    setKitsArray(newArr);
    setIsSelectAnyItem(flagSelectArt);
  };

  ///CHANGE COMENTARIO EN EL ELMENTO
  const handleChangeComment = (index, value) => {
    let cloneKitsArray = [...kitsArray];

    cloneKitsArray[index].comment = value;

    setKitsArray(cloneKitsArray);
  };

  ///CHANGE COMENTARIO EN EL ELMENTO HIJO
  const handleChangeCommentChild = (idxParent, idx, value) => {
    let cloneKitsArray = [...kitsArray];

    let newArrChilds = cloneKitsArray[idxParent].child;

    newArrChilds[idx].comment = value;

    setKitsArray(cloneKitsArray);
  };

  return (
    <div className="shopping__repayment__container__list__mobile">
      <ModalProductBanch
        isOpenProductBatch={isOpenProductBatch}
        setIsOpenProductBatch={setIsOpenProductBatch}
      />
      {kitsArray && kitsArray.length > 0 && (
        <Fragment>
          {kitsArray.map((kit, i) => {
            return (
              <div
                className="shopping__repayment__container__list__wrapper line_n"
                key={i}
                id={`is_other_active${i}`}
              >
                {kit.typeLine === "N" || kit.typeLine === "PD" ? (
                  <div
                    className={`shopping__repayment__container__list__item ${
                      isMobile ? "mobile" : ""
                    }`}
                    id={`parent${i}`}
                  >
                    <div
                      id="shopping__repayment__container__list__item_other"
                      className={
                        "shopping__repayment__container__list__item_other"
                      }
                    >
                      <div
                        className={`shopping__repayment__container__list__item_wrapper ${
                          isMobile ? "mobile" : ""
                        } `}
                      >
                        <div
                          className={`shopping__repayment__container__list__item_wrapper__return__select__others ${
                            isMobile ? "mobile" : ""
                          }`}
                        >
                          <div className="shopping__repayment__container__wrapper__other__up">
                            &nbsp; &nbsp;
                          </div>
                          <TextField
                            id="standard-multiline-flexible"
                            multiline
                            fullWidth
                            rows={2}
                            value={kit.comment}
                            onChange={(e) => {
                              handleChangeComment(i, e.target.value);
                            }}
                          />
                        </div>
                        <div className="shopping__repayment__container__list__item_wrapper__buttons">
                          <Tooltip
                            placement="left-start"
                            title={t("__notification_save_comment")}
                          >
                            <IconButton
                              aria-label="save"
                              onClick={() => {
                                handleSaveComment(i);
                              }}
                            >
                              <Save />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            placement="right-start"
                            title={t("__notification_delete_comment")}
                          >
                            <IconButton
                              aria-label="delete"
                              onClick={() => {
                                handleRemoveComment(i);
                              }}
                            >
                              <DeleteForever />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <div className="shopping__repayment__container__wrapper__other__down">
                          &nbsp; &nbsp;
                        </div>
                      </div>
                    </div>
                    <div id="shopping__repayment__container__list__item">
                      <Card className="shopping__card__repayment__wrapper">
                        <CardContent>
                          <div className="shopping__card__repayment__wrapper__information">
                            <div className="shopping__card__repayment__wrapper__information__check">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name={kit.itemCode}
                                    checked={kit.selectedReturn}
                                    onChange={(e) => {
                                      handleSelectedReturnItem(
                                        i,
                                        e.target.checked
                                      );
                                    }}
                                  />
                                }
                              />
                            </div>
                            <div className="shopping__card__repayment__wrapper__information__image">
                              <img
                                src={kit.imgProd}
                                alt={kit.itemCode}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src =
                                    "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
                                }}
                              />
                            </div>
                            <div className="shopping__card__repayment__wrapper__information__description">
                              <div className="shopping__card__repayment__wrapper__information__description__up">
                                <Typography
                                  variant="caption"
                                  display="block"
                                  gutterBottom
                                >
                                  {i18n.language === "es"
                                    ? kit.descEs
                                    : kit.descEn}
                                </Typography>
                              </div>
                              <div className="shopping__card__repayment__wrapper__information__description__down">
                                <div>
                                  <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                  >
                                    ${kit.price.toFixed(2)}
                                  </Typography>
                                </div>

                                <div>
                                  <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                  >
                                    {kit.cant} pz
                                  </Typography>
                                </div>
                              </div>
                            </div>
                            <div className="shopping__card__repayment__wrapper__information__controls">
                              <div className="shopping__card__repayment__wrapper__information__controls__remove">
                                <IconButton
                                  aria-label="remove"
                                  onClick={() => {
                                    handleRemoveElement(kit);
                                  }}
                                  disabled={
                                    !kit.selectedReturn ||
                                    kit.cant < kit.piecesR ||
                                    kit.piecesR === 0
                                  }
                                >
                                  <Remove />
                                </IconButton>
                              </div>
                              <div className="shopping__card__repayment__wrapper__information__controls__piece">
                                <Typography
                                  variant="caption"
                                  display="block"
                                  gutterBottom
                                >
                                  {kit.piecesR}
                                </Typography>
                              </div>
                              <div className="shopping__card__repayment__wrapper__information__controls__add">
                                <IconButton
                                  aria-label="add"
                                  onClick={() => {
                                    handleAddElement(kit);
                                  }}
                                  disabled={
                                    kit.exchangeType <= 0 ||
                                    kit.reasonsReturn <= 0 ||
                                    kit.piecesR >= kit.cant
                                  }
                                >
                                  <Add />
                                </IconButton>
                              </div>
                            </div>
                          </div>
                          <div className="shopping__card__repayment__wrapper__radios">
                            <div className="shopping__repayment__container__list__item_wrapper__change">
                              <div>
                                <FormControl
                                  component="fieldset"
                                  variant="caption"
                                  display="block"
                                  disabled={!kit.selectedReturn}
                                >
                                  <RadioGroup
                                    aria-label="gender"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                    value={kit.exchangeType}
                                    onChange={(e) => {
                                      handleSelectReturnRepayment(
                                        i,
                                        parseInt(e.target.value)
                                      );
                                    }}
                                  >
                                    {exchangeRateList.map((item, indx) => {
                                      return (
                                        <FormControlLabel
                                          key={indx}
                                          value={item.id}
                                          control={<Radio />}
                                          label={t(item.value)}
                                        />
                                      );
                                    })}
                                  </RadioGroup>
                                </FormControl>
                              </div>
                            </div>
                          </div>
                          <div className="shopping__card__repayment__wrapper__change">
                            <FormControl fullWidth>
                              <div>
                                <FormControl
                                  className={`choose__options ${
                                    isMobile ? "mobile" : ""
                                  } `}
                                  variant="filled"
                                >
                                  <TextField
                                    id="standard-select-currency"
                                    select
                                    value={kit.reasonsReturn}
                                    onChange={(e) => {
                                      handleSelectOpc(e.target.value, i);
                                    }}
                                    color="error"
                                    className={`shopping__repayment__container__wrapper__select ${
                                      isMobile ? "mobile" : ""
                                    }`}
                                    disabled={!kit.selectedReturn}
                                  >
                                    <MenuItem value={0} disabled>
                                      <em>
                                        {t(
                                          "__profile_repayment_container_select_label"
                                        )}
                                      </em>
                                    </MenuItem>

                                    {reasonsReturnList.map((item, indx) => {
                                      return (
                                        item.isPartialRefund && (
                                          <MenuItem key={indx} value={item.id}>
                                            {t(item.value)}
                                          </MenuItem>
                                        )
                                      );
                                    })}
                                  </TextField>
                                </FormControl>
                              </div>
                            </FormControl>
                          </div>
                          <div>
                            <div className="product__batch">
                              <FormControl sx={{ m: 1, width: "25ch" }}>
                                <div
                                  className={`product__batch__header ${
                                    kit.piecesR === 0 ? "disabled" : ""
                                  }`}
                                >
                                  <span>
                                    {t(
                                      "__profile_repayment_batch_product_label"
                                    )}
                                  </span>
                                  <IconButton
                                    disabled={kit.piecesR === 0}
                                    onClick={() => {
                                      setIsOpenProductBatch(true);
                                    }}
                                    edge="end"
                                  >
                                    <Error />
                                  </IconButton>
                                </div>
                                <Input
                                  disabled={kit.piecesR === 0}
                                  className="product__value"
                                  type="text"
                                  onInput={(e) => {
                                    handleChangeProductBatch(i, e.target.value);
                                  }}
                                  value={kit.productBatch}
                                />
                              </FormControl>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  </div>
                ) : (
                  <div>
                    <Accordion
                      className="shopping__repayment__container__list__item__mobile"
                      expanded={expanded === "panel" + i}
                    >
                      <AccordionSummary
                        expandIcon={
                          expanded === "panel" + i ? (
                            <Remove
                              onClick={(event) => {
                                handleChangeKit("panel" + i, event, false);
                              }}
                            />
                          ) : (
                            <Add
                              onClick={(event) => {
                                handleChangeKit("panel" + i, event, true);
                              }}
                            />
                          )
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        {kit.typeLine !== "N" && kit.typeLine !== "PD" && (
                          <div
                            className={`shopping__repayment__container__list__item ${
                              isMobile ? "mobile" : ""
                            }`}
                            id={`parent${i}`}
                          >
                            <div
                              id="shopping__repayment__container__list__item_other"
                              className={
                                "shopping__repayment__container__list__item_other"
                              }
                            >
                              <div
                                className={`shopping__repayment__container__list__item_wrapper ${
                                  isMobile ? "mobile" : ""
                                } `}
                              >
                                <div
                                  className={`shopping__repayment__container__list__item_wrapper__return__select__others ${
                                    isMobile ? "mobile" : ""
                                  }`}
                                >
                                  <div className="shopping__repayment__container__wrapper__other__up">
                                    &nbsp; &nbsp;
                                  </div>
                                  <TextField
                                    className="shopping__repayment__container__wrapper__other__down"
                                    id="standard-multiline-flexible"
                                    multiline
                                    fullWidth
                                    rows={2}
                                    value={kit.comment}
                                    onChange={(e) => {
                                      handleChangeComment(i, e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="shopping__repayment__container__list__item_wrapper__buttons">
                                  <Tooltip
                                    placement="left-start"
                                    title={t("__notification_save_comment")}
                                  >
                                    <IconButton
                                      aria-label="save"
                                      onClick={() => {
                                        handleSaveComment(i);
                                      }}
                                    >
                                      <Save />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip
                                    placement="right-start"
                                    title={t("__notification_delete_comment")}
                                  >
                                    <IconButton
                                      aria-label="delete"
                                      onClick={() => {
                                        handleRemoveComment(i);
                                      }}
                                    >
                                      <DeleteForever />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                            <div id="shopping__repayment__container__list__item">
                              <div className="shopping__card__repayment__wrapper__information">
                                <div className="shopping__card__repayment__wrapper__information__check">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name={kit.itemCode}
                                        checked={kit.selectedReturn}
                                        onChange={(e) => {
                                          handleSelectedReturnItem(
                                            i,
                                            e.target.checked
                                          );
                                        }}
                                      />
                                    }
                                  />
                                </div>
                                <div className="shopping__card__repayment__wrapper__information__image">
                                  <img
                                    src={kit.imgProd}
                                    alt={kit.itemCode}
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src =
                                        "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
                                    }}
                                  />
                                </div>
                                <div className="shopping__card__repayment__wrapper__information__description">
                                  <div className="shopping__card__repayment__wrapper__information__description__up">
                                    <Typography
                                      variant="caption"
                                      display="block"
                                      gutterBottom
                                    >
                                      {i18n.language === "es"
                                        ? kit.descEs
                                        : kit.descEn}
                                    </Typography>
                                  </div>
                                  <div className="shopping__card__repayment__wrapper__information__description__down">
                                    <div>
                                      <Typography
                                        variant="caption"
                                        display="block"
                                        gutterBottom
                                      >
                                        ${kit.price.toFixed(2)}
                                      </Typography>
                                    </div>

                                    <div>
                                      <Typography
                                        variant="caption"
                                        display="block"
                                        gutterBottom
                                      >
                                        {kit.cant} pz
                                      </Typography>
                                    </div>
                                  </div>
                                </div>
                                <div className="shopping__card__repayment__wrapper__information__controls">
                                  <div className="shopping__card__repayment__wrapper__information__controls__remove">
                                    <IconButton
                                      aria-label="remove"
                                      onClick={() => {
                                        handleRemoveElement(kit);
                                      }}
                                      disabled={
                                        !kit.selectedReturn ||
                                        kit.cant < kit.piecesR ||
                                        kit.piecesR === 0
                                      }
                                    >
                                      <Remove />
                                    </IconButton>
                                  </div>
                                  <div className="shopping__card__repayment__wrapper__information__controls__piece">
                                    <Typography
                                      variant="caption"
                                      display="block"
                                      gutterBottom
                                    >
                                      {kit.piecesR}
                                    </Typography>
                                  </div>
                                  <div className="shopping__card__repayment__wrapper__information__controls__add">
                                    <IconButton
                                      aria-label="add"
                                      onClick={() => {
                                        handleAddElement(kit);
                                      }}
                                      disabled={
                                        kit.exchangeType <= 0 ||
                                        kit.reasonsReturn <= 0 ||
                                        kit.piecesR >= kit.cant
                                      }
                                    >
                                      <Add />
                                    </IconButton>
                                  </div>
                                </div>
                              </div>
                              <div className="shopping__card__repayment__wrapper__radios">
                                <div className="shopping__repayment__container__list__item_wrapper__change">
                                  <div>
                                    <FormControl
                                      component="fieldset"
                                      variant="caption"
                                      display="block"
                                      disabled={!kit.selectedReturn}
                                    >
                                      <RadioGroup
                                        aria-label="gender"
                                        defaultValue="female"
                                        name="radio-buttons-group"
                                        value={kit.exchangeType}
                                        onChange={(e) => {
                                          handleSelectReturnRepayment(
                                            i,
                                            parseInt(e.target.value)
                                          );
                                        }}
                                      >
                                        {exchangeRateList.map((item, indx) => {
                                          return (
                                            <FormControlLabel
                                              key={indx}
                                              value={item.id}
                                              control={<Radio />}
                                              label={t(item.value)}
                                            />
                                          );
                                        })}
                                      </RadioGroup>
                                    </FormControl>
                                  </div>
                                </div>
                              </div>
                              <div className="shopping__card__repayment__wrapper__change">
                                <FormControl fullWidth>
                                  <div>
                                    <FormControl
                                      className={`choose__options ${
                                        isMobile ? "mobile" : ""
                                      } `}
                                      variant="filled"
                                    >
                                      <TextField
                                        id="standard-select-currency"
                                        select
                                        color="error"
                                        value={kit.reasonsReturn}
                                        onChange={(e) => {
                                          handleSelectOpc(e.target.value, i);
                                        }}
                                        className={`shopping__repayment__container__wrapper__select ${
                                          isMobile ? "mobile" : ""
                                        }`}
                                        disabled={!kit.selectedReturn}
                                      >
                                        <MenuItem value={0} disabled>
                                          <em>
                                            {t(
                                              "__profile_repayment_container_select_label"
                                            )}
                                          </em>
                                        </MenuItem>

                                        {reasonsReturnList.map((item, indx) => {
                                          return (
                                            item.isPartialRefund && (
                                              <MenuItem
                                                key={indx}
                                                value={item.id}
                                              >
                                                {t(item.value)}
                                              </MenuItem>
                                            )
                                          );
                                        })}
                                      </TextField>
                                    </FormControl>
                                  </div>
                                </FormControl>
                              </div>
                              <div className="shopping__card__repayment__wrapper">
                                <div className="product__batch">
                                  <FormControl sx={{ m: 1, width: "25ch" }}>
                                    <div
                                      className={`product__batch__header ${
                                        kit.piecesR === 0 ? "disabled" : ""
                                      }`}
                                    >
                                      <span>
                                        {t(
                                          "__profile_repayment_batch_product_label"
                                        )}
                                      </span>
                                      <IconButton
                                        disabled={kit.piecesR === 0}
                                        onClick={() => {
                                          setIsOpenProductBatch(true);
                                        }}
                                        edge="end"
                                      >
                                        <Error />
                                      </IconButton>
                                    </div>
                                    <Input
                                      disabled={kit.piecesR === 0}
                                      className="product__value"
                                      type="text"
                                      onInput={(e) => {
                                        handleChangeProductBatch(
                                          i,
                                          e.target.value
                                        );
                                      }}
                                      value={kit.productBatch}
                                    />
                                  </FormControl>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </AccordionSummary>

                      {kit.child.length > 0 && (
                        <AccordionDetails>
                          <List
                            dense
                            sx={{
                              width: "100%",
                              maxWidth: 360,
                              bgcolor: "#edf1fa",
                            }}
                          >
                            {kit.child.map((KitItem, index) => {
                              return (
                                <div
                                  id={`is_children_active${i}` + `_${index}`}
                                  disabled={true}
                                  key={index}
                                >
                                  <div
                                    className={`shopping__repayment__container__list__item ${
                                      isMobile ? "mobile" : ""
                                    }`}
                                    id={`child${i}` + `_${index}`}
                                  >
                                    <div
                                      id="shopping__repayment__container__list__item_other"
                                      className={`shopping__repayment__container__list__item_other ${
                                        isMobile ? "mobile" : ""
                                      }`}
                                    >
                                      <div
                                        className={`shopping__repayment__container__list__item_wrapper ${
                                          isMobile ? "mobile" : ""
                                        } `}
                                      >
                                        <div
                                          className={`shopping__repayment__container__list__item_wrapper__return__select__others ${
                                            isMobile ? "mobile" : ""
                                          }`}
                                        >
                                          <div className="shopping__repayment__container__wrapper__other__up">
                                            &nbsp; &nbsp;
                                          </div>
                                          <TextField
                                            id="standard-multiline-flexible"
                                            multiline
                                            fullWidth
                                            rows={2}
                                            value={KitItem.comment}
                                            onChange={(e) => {
                                              handleChangeCommentChild(
                                                i,
                                                index,
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </div>
                                        <div className="shopping__repayment__container__list__item_wrapper__buttons">
                                          <Tooltip
                                            placement="left-start"
                                            title={t(
                                              "__notification_save_comment"
                                            )}
                                          >
                                            <IconButton
                                              aria-label="save"
                                              onClick={() => {
                                                handleSaveCommentItem(i, index);
                                              }}
                                            >
                                              <Save />
                                            </IconButton>
                                          </Tooltip>
                                          <Tooltip
                                            placement="right-start"
                                            title={t(
                                              "__notification_delete_comment"
                                            )}
                                          >
                                            <IconButton
                                              aria-label="delete"
                                              onClick={() => {
                                                handleRemoveCommentItem(
                                                  i,
                                                  index
                                                );
                                              }}
                                            >
                                              <DeleteForever />
                                            </IconButton>
                                          </Tooltip>
                                        </div>
                                        <div className="shopping__repayment__container__wrapper__other__down">
                                          &nbsp; &nbsp;
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <ListItem key={value} disablePadding>
                                        <Fragment>
                                          <div className="shopping__card__repayment__wrapper__information">
                                            <div className="shopping__card__repayment__wrapper__information__check">
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    disabled={
                                                      kit.selectedReturn
                                                    }
                                                    name={KitItem.itemCode}
                                                    checked={
                                                      KitItem.selectedReturn
                                                    }
                                                    onChange={(e) => {
                                                      handleSelectedReturnItemChild(
                                                        index,
                                                        i,
                                                        e.target.checked
                                                      );
                                                    }}
                                                  />
                                                }
                                              />
                                            </div>
                                            <div className="shopping__card__repayment__wrapper__information__image">
                                              <img
                                                src={KitItem.imgProd}
                                                alt={KitItem.itemCode}
                                                onError={(e) => {
                                                  e.target.onerror = null;
                                                  e.target.src =
                                                    "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
                                                }}
                                              />
                                            </div>
                                            <div className="shopping__card__repayment__wrapper__information__description">
                                              <div className="shopping__card__repayment__wrapper__information__description__up">
                                                <Typography
                                                  variant="caption"
                                                  display="block"
                                                  gutterBottom
                                                >
                                                  {i18n.language === "es"
                                                    ? KitItem.descEs
                                                    : KitItem.descEn}
                                                </Typography>
                                              </div>
                                              <div className="shopping__card__repayment__wrapper__information__description__down">
                                                <div>
                                                  <Typography
                                                    variant="caption"
                                                    display="block"
                                                    gutterBottom
                                                  >
                                                    ${KitItem.price.toFixed(2)}
                                                  </Typography>
                                                </div>

                                                <div>
                                                  <Typography
                                                    variant="caption"
                                                    display="block"
                                                    gutterBottom
                                                  >
                                                    {KitItem.cant} pz
                                                  </Typography>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="shopping__card__repayment__wrapper__information__controls">
                                              <div className="shopping__card__repayment__wrapper__information__controls__remove">
                                                <IconButton
                                                  aria-label="remove"
                                                  onClick={() => {
                                                    handleRemoveElementChild(
                                                      index,
                                                      i,
                                                      KitItem
                                                    );
                                                  }}
                                                  disabled={
                                                    !KitItem.selectedReturn ||
                                                    KitItem.cant <
                                                      KitItem.piecesR ||
                                                    KitItem.piecesR === 0
                                                  }
                                                >
                                                  <Remove />
                                                </IconButton>
                                              </div>
                                              <div className="shopping__card__repayment__wrapper__information__controls__piece">
                                                <Typography
                                                  variant="caption"
                                                  display="block"
                                                  gutterBottom
                                                >
                                                  {KitItem.piecesR}
                                                </Typography>
                                              </div>
                                              <div className="shopping__card__repayment__wrapper__information__controls__add">
                                                <IconButton
                                                  aria-label="add"
                                                  onClick={() => {
                                                    handleAddElementChild(
                                                      index,
                                                      i,
                                                      KitItem
                                                    );
                                                  }}
                                                  disabled={
                                                    KitItem.exchangeType <= 0 ||
                                                    KitItem.reasonsReturn <=
                                                      0 ||
                                                    KitItem.piecesR >= kit.cant
                                                  }
                                                >
                                                  <Add />
                                                </IconButton>
                                              </div>
                                            </div>
                                            <div className="shopping__card__repayment__wrapper__radios">
                                              <div className="shopping__repayment__container__list__item_wrapper__change">
                                                <div>
                                                  <FormControl
                                                    component="fieldset"
                                                    variant="caption"
                                                    display="block"
                                                    disabled={
                                                      !KitItem.selectedReturn
                                                    }
                                                  >
                                                    <RadioGroup
                                                      aria-label="gender"
                                                      defaultValue="female"
                                                      name="radio-buttons-group"
                                                      value={
                                                        KitItem.exchangeType
                                                      }
                                                      onChange={(e) => {
                                                        handleSelectChildsReturnRepayment(
                                                          index,
                                                          i,
                                                          parseInt(
                                                            e.target.value
                                                          )
                                                        );
                                                      }}
                                                    >
                                                      {exchangeRateList.map(
                                                        (item, indx) => {
                                                          return (
                                                            <FormControlLabel
                                                              key={indx}
                                                              value={item.id}
                                                              control={
                                                                <Radio />
                                                              }
                                                              label={t(
                                                                item.value
                                                              )}
                                                            />
                                                          );
                                                        }
                                                      )}
                                                    </RadioGroup>
                                                  </FormControl>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="shopping__card__repayment__wrapper__change accordion__detail__change">
                                              <FormControl fullWidth>
                                                <div>
                                                  <FormControl
                                                    className={`choose__options ${
                                                      isMobile ? "mobile" : ""
                                                    } `}
                                                    variant="filled"
                                                  >
                                                    <TextField
                                                      id="standard-select-currency"
                                                      select
                                                      value={
                                                        KitItem.reasonsReturn
                                                      }
                                                      onChange={(e) => {
                                                        handleSelectOpcItem(
                                                          e.target.value,
                                                          i,
                                                          index
                                                        );
                                                      }}
                                                      color="error"
                                                      className={`shopping__repayment__container__wrapper__select ${
                                                        isMobile ? "mobile" : ""
                                                      }`}
                                                      disabled={
                                                        !KitItem.selectedReturn
                                                      }
                                                    >
                                                      <MenuItem
                                                        value={0}
                                                        disabled
                                                      >
                                                        <em>
                                                          {t(
                                                            "__profile_repayment_container_select_label"
                                                          )}
                                                        </em>
                                                      </MenuItem>
                                                      {reasonsReturnList.map(
                                                        (item, indx) => {
                                                          return (
                                                            item.isPartialRefund && (
                                                              <MenuItem
                                                                key={indx}
                                                                value={item.id}
                                                              >
                                                                {t(item.value)}
                                                              </MenuItem>
                                                            )
                                                          );
                                                        }
                                                      )}
                                                    </TextField>
                                                  </FormControl>
                                                </div>
                                              </FormControl>
                                            </div>
                                            <div className="shopping__card__repayment__wrapper">
                                              <div className="product__batch">
                                                <FormControl
                                                  sx={{ m: 1, width: "25ch" }}
                                                >
                                                  <div
                                                    className={`product__batch__header ${
                                                      kit.piecesR === 0
                                                        ? "disabled"
                                                        : ""
                                                    }`}
                                                  >
                                                    <span>
                                                      {t(
                                                        "__profile_repayment_batch_product_label"
                                                      )}
                                                    </span>
                                                    <IconButton
                                                      disabled={
                                                        KitItem.piecesR === 0
                                                      }
                                                      onClick={() => {
                                                        setIsOpenProductBatch(
                                                          true
                                                        );
                                                      }}
                                                      edge="end"
                                                    >
                                                      <Error />
                                                    </IconButton>
                                                  </div>
                                                  <Input
                                                    disabled={
                                                      KitItem.piecesR === 0
                                                    }
                                                    className="product__value"
                                                    type="text"
                                                    onInput={(e) => {
                                                      handleChangeProductBatch(
                                                        i,
                                                        e.target.value
                                                      );
                                                    }}
                                                    value={KitItem.productBatch}
                                                  />
                                                </FormControl>
                                              </div>
                                            </div>
                                          </div>
                                        </Fragment>
                                      </ListItem>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </List>
                        </AccordionDetails>
                      )}
                    </Accordion>
                  </div>
                )}
              </div>
            );
          })}
        </Fragment>
      )}
    </div>
  );
};
