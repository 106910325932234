import React from "react";
import { isChrome, isChromium, isMobile } from "react-device-detect";

const TextFieldComponent = ({
  labelName,
  typeInput,
  valueInput,
  erroHelp,
  isActiveHelp,
  textareaEnabled,
  setValue,
  placeholderInput,
  onBlur,
  maxLength = 0,
  withLeftMargin,
  id,
}) => {
  return (
    <>
      {!textareaEnabled ? (
        <div
          style={{ marginLeft: withLeftMargin ? 8 : 0 }}
          className="textfield inner"
        >
          <label htmlFor="my-input">{labelName}</label>
          {maxLength > 0 ? (
            <input
              // id="my-input"
              placeholder={placeholderInput !== "" ? placeholderInput : ""}
              type={typeInput}
              value={valueInput}
              aria-describedby="my-helper-text"
              onInput={setValue}
              onBlur={onBlur}
              maxLength={maxLength}
              autoComplete={isChromium || isChrome ? "nope" : "off"}
            />
          ) : (
            <input
              // id="my-input"
              placeholder={placeholderInput !== "" ? placeholderInput : ""}
              type={typeInput}
              value={valueInput}
              aria-describedby="my-helper-text"
              onInput={setValue}
              onBlur={onBlur}
              autoComplete={isChromium || isChrome ? "nope" : "off"}
            />
          )}
          {isActiveHelp && <span id="my-helper-text">{erroHelp}.</span>}
        </div>
      ) : (
        <div
          style={{ marginLeft: withLeftMargin ? 8 : 0 }}
          className="textfield inner"
        >
          <label htmlFor="my-input">{labelName}</label>
          <textarea
            // id="my-input"
            rows={isMobile ? "5" : "8"}
            cols={isMobile ? "80" : "100"}
            value={valueInput}
            onInput={setValue}
            aria-describedby="my-helper-text"
            onBlur={onBlur}
          />
          {isActiveHelp && <span id="my-helper-text">{erroHelp}.</span>}
        </div>
      )}
    </>
  );
};

export default TextFieldComponent;
