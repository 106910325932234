import {REQUEST_HOME_MODULE_INFO, REQUEST_ENTREPENOUR_HOME_MODULE_INFO} from './../../Constants'



export const requestHomeModuleInfo = () => {
    return {
        type: REQUEST_HOME_MODULE_INFO,
        payload: {}
    }
}

export const requestEntrepenourHomeModuleInfo = () => {
    let sessionId = 0;
    if(localStorage.getItem("ZUsaUInfo"))
    {
        sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
    }
    return {
        type: REQUEST_ENTREPENOUR_HOME_MODULE_INFO,
        payload: {sessionId}
    }
}