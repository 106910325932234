import { 
    REQUEST_CANCELLATION_COMPLETE,
    REQUEST_GET_EXCHANGE_RATE,
    REQUEST_GET_REASONS_RETURN,
    REQUEST_FULL_REFUND,
    REQUEST_PARTIAL_REFUND,
    CLEAN_REFOUND_REDUCER,
    CLEAN_REQUEST_FULL_REFUND_REDUCER,
    CLEAN_REQUEST_PARTIAL_REFUND_REDUCER,
  } from "./../../Constants";


  export const requestGetExchangeRate = () => {
    return {
      type: REQUEST_GET_EXCHANGE_RATE,
      payload: {},
    };
  };
  
  export const requestGetReasonsReturn = () => {
    return {
      type: REQUEST_GET_REASONS_RETURN,
      payload: {},
    };
  };

  export const requestCancelOrderComplete = (orderId) => {
    const sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId
    return {
      type: REQUEST_CANCELLATION_COMPLETE,
      payload: {
        orderId, sessionId
      },
    };
  };

  export const requestFullRefund = (orderId, exchangeType, refoundReason, refoundComment) => {
    const sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId
    return {
      type: REQUEST_FULL_REFUND,
      payload: {
        orderId, sessionId, exchangeType, refoundReason, refoundComment
      },
    };
  };

  export const requestPartialRefund = (orderId,articlesReturnModel ) => {
    const sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId
    return {
      type: REQUEST_PARTIAL_REFUND,
      payload: {
        orderId, sessionId, articlesReturnModel
      },
    };
  };

  export const requestCleanRefoundReducer = () => {
    return {
      type: CLEAN_REFOUND_REDUCER,
      payload: {}
    }
  }

  export const requestCleanRequestFullRefundReducer = () => {
    return {
      type: CLEAN_REQUEST_FULL_REFUND_REDUCER,
      payload: {}
    }
  }
  export const requestCleanRequestPartialRefundReducer = () => {
    return {
      type: CLEAN_REQUEST_PARTIAL_REFUND_REDUCER,
      payload: {}
    }
  }