import React, { useEffect, useState, Fragment } from "react";
import {
  isMobile,
  isTablet,
  isDesktop,
  isMobileOnly,
  isIOS,
} from "react-device-detect";
import { Skeleton } from "@mui/material";
import { Navigation, Pagination, Autoplay, EffectCoverflow } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { requestLoggUserActivity } from "../../../ReduxSaga/Actions/General";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

//SwiperCore.use([Navigation, Pagination, Autoplay, EffectCoverflow]);

const SlideCarousel = ({ sliderData }) => {
  /// REDUX
  const dispatch = useDispatch();

  ///HOOKS
  const [sliders, setSliders] = useState([]);
  ///EFFECTS
  useEffect(() => {
    // if (sliderData?.length > 0 && sliderData[0]?.content?.length > 0) {
    //   if (
    //     isTablet ||
    //     isDesktop
    //     //||(window.matchMedia && window.matchMedia("(min-width: 768px)").matches)
    //   ) {
    //     setSliders(
    //       sliderData[0].content.filter(
    //         (f) => f.idModuleContentResourcesDetailType === 3
    //       )
    //     );
    //   }
    //   if (!isTablet && isMobile) {
    //     setSliders(
    //       sliderData[0].content.filter(
    //         (f) => f.idModuleContentResourcesDetailType === 1
    //       )
    //     );
    //   }
    // }
    if (sliderData?.length > 0 && sliderData[0]?.content?.length > 0) {
      if (isMobileOnly) {
        //console.log("soy un telefonito")
        setSliders(
          sliderData[0].content.filter(
            (f) => f.idModuleContentResourcesDetailType === 1
          )
        );
      } else {
        //console.log("pintame grande")
        setSliders(
          sliderData[0].content.filter(
            (f) => f.idModuleContentResourcesDetailType === 3
          )
        );
      }
    }
  }, [sliderData]);
  return (
    <Fragment>
      {sliderData[0]?.content?.length > 0 && sliders.length > 0 ? (
        <Swiper
          navigation={false}
          effect={"fade"}
          slidesPerView={1}
          spaceBetween={50}
          centeredSlides={true}
          autoplay={{
            delay: 7000,
            disableOnInteraction: false,
          }}
          loop
          pagination={false}
          className={`swiper__banner__main__home ${
            isMobile || isTablet ? "mobile" : ""
          } ${isIOS && isTablet ? "is__iOS" : ""}`}
          modules={[Autoplay, Pagination, Navigation, EffectCoverflow]}
        >
          {sliders.map((item, index) => {
            //debugger;
            return (
              <SwiperSlide key={index}>
                {/* {(isTablet || isDesktop) ?
                < BrowserView >
                  <img alt="ejemplo" src={item.image} />
                </BrowserView>
                :  */}
                <Link
                  to={item.anchor}
                  onClick={() =>
                    dispatch(
                      requestLoggUserActivity(
                        67,
                        0,
                        0,
                        "",
                        "Click en Banner: " + item.name.toUpperCase()
                      )
                    )
                  }
                >
                  <img alt="mobile" src={item.image} />
                </Link>
                {/* } */}
              </SwiperSlide>
            );
          })}
        </Swiper>
      ) : (
        <Skeleton width={"100%"} height={300} variant="rect" />
      )}
    </Fragment>
  );
};

export default SlideCarousel;
