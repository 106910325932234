import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { isMobile, isTablet, isDesktop } from "react-device-detect";
import { Link } from "react-router-dom";
import { Skeleton } from "@mui/material";
// import SliderReferFriend from "./SliderReferFriend";
import { requestLoggUserActivity } from "../../../ReduxSaga/Actions/General";

const AdBanners = ({
  isEntrepenourHome,
  commercials,
  t,
  isLoadingEnable,
  isLanguageLoaded,
}) => {
  /// REDUX
  const dispatch = useDispatch();

  // const [isReferActive, setIsReferActive] = useState(false);

  return (
    <Fragment>
      {isEntrepenourHome === false ? (
        <Fragment>
          {(isDesktop || isTablet) && (
            <div className="ads ">
              <div
                className={`ads__content__title ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }  `}
              >
                {isLanguageLoaded ? (
                  t("__ads_title")
                ) : (
                  <Skeleton variant="rect" width={"30%"} height={30} />
                )}
              </div>

              <div
                className={`ads__content ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                <div
                  className={`ads__content__wrapper__inner ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  } ${isLoadingEnable ? "skeletonActive" : ""}`}
                >
                  {isLoadingEnable
                    ? [0, 1].map((index) => {
                        return (
                          <Skeleton
                            key={index}
                            width={
                              isMobile && !isTablet
                                ? "80%"
                                : isTablet
                                ? "80%"
                                : "50%"
                            }
                            height={isTablet ? 300 : isMobile ? 200 : 400}
                            className={isMobile ? "" : "skeletoSpace"}
                          />
                        );
                      })
                    : commercials.map((item, index) => {
                        return (
                          <div
                            className={`ads__content__wrapper ${
                              isTablet ? "tablet" : isMobile ? "mobile" : ""
                            }`}
                            key={index}
                          >
                            {/* <div className="text__red ads__content__subtitle">
                              {index === 0
                                ? t("__ads_subtitle")
                                : t("__ads_subtitle2")}
                            </div> */}
                            <img
                              className={`ads__content__image ${
                                isTablet ? "tablet" : isMobile ? "mobile" : ""
                              }`}
                              src={
                                item.content?.find(
                                  (f) =>
                                    f.idModuleContentResourcesDetailType === 3
                                )?.image
                              }
                            />
                            <div
                              className={`ads__content__button ${
                                isTablet ? "tablet" : isMobile ? "mobile" : ""
                              }`}
                            >
                              <Link
                                to={item.anchorResources}
                                onClick={() =>
                                  dispatch(
                                    requestLoggUserActivity(
                                      67,
                                      0,
                                      0,
                                      "",
                                      "Click en Comercial: " +
                                        item.nameModConten.toUpperCase()
                                    )
                                  )
                                }
                              >
                                <div
                                  className={`ads__content__button__inner ${
                                    isTablet
                                      ? "tablet"
                                      : isMobile
                                      ? "mobile"
                                      : ""
                                  } ${isLoadingEnable ? "skeletonActive" : ""}`}
                                >
                                  <div
                                    className={
                                      item.content?.find(
                                        (f) =>
                                          f.idModuleContentResourcesDetailType ===
                                          3
                                      )?.value
                                    }
                                  />
                                  <div>{item.valueResources}</div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        );
                      })}
                </div>
              </div>
            </div>
          )}

          {isMobile && !isTablet && !isDesktop && (
            <div className="ads">
              <div
                className={`ads__content ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                <div
                  className={`ads__content__title ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  }  `}
                >
                  {isLanguageLoaded ? (
                    t("__ads_title")
                  ) : (
                    <Skeleton variant="rect" width={"30%"} height={30} />
                  )}
                </div>

                <div
                  className={`ads__content__wrapper__inner ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  } ${isLoadingEnable ? "skeletonActive" : ""}`}
                >
                  {isLoadingEnable
                    ? [0, 1].map((itemX) => {
                        return (
                          <Skeleton
                            key={itemX}
                            width={
                              isMobile && !isTablet
                                ? "80%"
                                : isTablet
                                ? "80%"
                                : "50%"
                            }
                            height={isTablet ? 300 : isMobile ? 200 : 400}
                            className={isMobile ? "" : "skeletoSpace"}
                          />
                        );
                      })
                    : commercials.map((item, index) => {
                        return (
                          <div
                            className={`ads__content__wrapper ${
                              isTablet ? "tablet" : isMobile ? "mobile" : ""
                            }`}
                            key={index}
                          >
                            {/* <div className="text__red ads__content__subtitle">
                              {index === 0
                                ? t("__ads_subtitle")
                                : t("__ads_subtitle2")}
                            </div> */}
                            <img
                              className={`ads__content__image ${
                                isTablet ? "tablet" : isMobile ? "mobile" : ""
                              }`}
                              src={
                                item.content?.find(
                                  (f) =>
                                    f.idModuleContentResourcesDetailType === 3
                                )?.image
                              }
                            />
                            <div
                              className={`ads__content__button ${
                                isTablet ? "tablet" : isMobile ? "mobile" : ""
                              }`}
                            >
                              <Link
                                to={item.anchorResources}
                                onClick={() =>
                                  dispatch(
                                    requestLoggUserActivity(
                                      67,
                                      0,
                                      0,
                                      "",
                                      "Click en Comercial: " +
                                        item.nameModConten.toUpperCase()
                                    )
                                  )
                                }
                              >
                                <div
                                  className={`ads__content__button__inner ${
                                    isTablet
                                      ? "tablet"
                                      : isMobile
                                      ? "mobile"
                                      : ""
                                  } ${isLoadingEnable ? "skeletonActive" : ""}`}
                                >
                                  <div
                                    className={
                                      item.content?.find(
                                        (f) =>
                                          f.idModuleContentResourcesDetailType ===
                                          3
                                      )?.value
                                    }
                                  />
                                  <div>{item.valueResources}</div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        );
                      })}
                </div>
              </div>
            </div>
          )}
        </Fragment>
      ) : (
        <Fragment>
          {(isDesktop || isTablet) && (
            <div className="ads ">
              <div
                className={`ads__content__title ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }  `}
              >
                {isLanguageLoaded ? (
                  t("__ads_title")
                ) : (
                  <Skeleton variant="rect" width={"30%"} height={30} />
                )}
              </div>

              <div
                className={`ads__content ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                <div
                  className={`ads__content__wrapper__inner ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  } ${isLoadingEnable ? "skeletonActive" : ""}`}
                >
                  {isLoadingEnable
                    ? [0, 1].map((index) => {
                        return (
                          <Skeleton
                            key={index}
                            width={
                              isMobile && !isTablet
                                ? "80%"
                                : isTablet
                                ? "80%"
                                : "50%"
                            }
                            height={isTablet ? 300 : isMobile ? 200 : 400}
                            className={isMobile ? "" : "skeletoSpace"}
                          />
                        );
                      })
                    : commercials.map((item, index) => {
                        return (
                          <div
                            className={`ads__content__wrapper ${
                              isTablet ? "tablet" : isMobile ? "mobile" : ""
                            }`}
                            key={index}
                          >
                            {/* <div className="text__red ads__content__subtitle">
                            {index === 0
                              ? t("__ads_subtitle")
                              : t("__ads_subtitle2")}
                          </div> */}
                            <img
                              className={`ads__content__image ${
                                isTablet ? "tablet" : isMobile ? "mobile" : ""
                              }`}
                              src={
                                item.content?.find(
                                  (f) =>
                                    f.idModuleContentResourcesDetailType === 3
                                )?.image
                              }
                            />
                            <div
                              className={`ads__content__button ${
                                isTablet ? "tablet" : isMobile ? "mobile" : ""
                              }`}
                            >
                              <Link
                                to={() => {
                                  if (
                                    item.anchorResources ===
                                      "/favoriteCustomer" ||
                                    item.anchorResources === "/enterprising"
                                  ) {
                                    return localStorage.getItem("ZUsaUrlPage")
                                      ? `${
                                          item.anchorResources
                                        }/${localStorage.getItem(
                                          "ZUsaUrlPage"
                                        )}`
                                      : item.anchorResources;
                                  } else {
                                    return item.anchorResources;
                                  }
                                  //item.anchorResources
                                }}
                                onClick={() =>
                                  dispatch(
                                    requestLoggUserActivity(
                                      67,
                                      0,
                                      0,
                                      "",
                                      "Click en Comercial: " +
                                        item.nameModConten.toUpperCase()
                                    )
                                  )
                                }
                              >
                                <div
                                  className={`ads__content__button__inner ${
                                    isTablet
                                      ? "tablet"
                                      : isMobile
                                      ? "mobile"
                                      : ""
                                  } ${isLoadingEnable ? "skeletonActive" : ""}`}
                                >
                                  <div
                                    className={
                                      item.content?.find(
                                        (f) =>
                                          f.idModuleContentResourcesDetailType ===
                                          3
                                      )?.value
                                    }
                                  />
                                  <div>{item.valueResources}</div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        );
                      })}
                </div>
              </div>
            </div>
          )}

          {isMobile && !isTablet && !isDesktop && (
            <div className="ads">
              <div
                className={`ads__content ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                <div
                  className={`ads__content__title ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  }  `}
                >
                  {isLanguageLoaded ? (
                    t("__ads_title")
                  ) : (
                    <Skeleton variant="rect" width={"30%"} height={30} />
                  )}
                </div>

                <div
                  className={`ads__content__wrapper__inner ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  } ${isLoadingEnable ? "skeletonActive" : ""}`}
                >
                  {isLoadingEnable
                    ? [0, 1].map((itemX) => {
                        return (
                          <Skeleton
                            key={itemX}
                            width={
                              isMobile && !isTablet
                                ? "80%"
                                : isTablet
                                ? "80%"
                                : "50%"
                            }
                            height={isTablet ? 300 : isMobile ? 200 : 400}
                            className={isMobile ? "" : "skeletoSpace"}
                          />
                        );
                      })
                    : commercials.map((item, index) => {
                        return (
                          <div
                            className={`ads__content__wrapper ${
                              isTablet ? "tablet" : isMobile ? "mobile" : ""
                            }`}
                            key={index}
                          >
                            {/* <div className="text__red ads__content__subtitle">
                            {index === 0
                              ? t("__ads_subtitle")
                              : t("__ads_subtitle2")}
                          </div> */}
                            <img
                              className={`ads__content__image ${
                                isTablet ? "tablet" : isMobile ? "mobile" : ""
                              }`}
                              src={
                                item.content?.find(
                                  (f) =>
                                    f.idModuleContentResourcesDetailType === 3
                                )?.image
                              }
                            />
                            <div
                              className={`ads__content__button ${
                                isTablet ? "tablet" : isMobile ? "mobile" : ""
                              }`}
                            >
                              <Link
                                to={() => {
                                  if (
                                    item.anchorResources ===
                                      "/favoriteCustomer" ||
                                    item.anchorResources === "/enterprising"
                                  ) {
                                    return localStorage.getItem("ZUsaUrlPage")
                                      ? `${
                                          item.anchorResources
                                        }/${localStorage.getItem(
                                          "ZUsaUrlPage"
                                        )}`
                                      : item.anchorResources;
                                  } else {
                                    return item.anchorResources;
                                  }
                                  //item.anchorResources
                                }}
                                onClick={() =>
                                  dispatch(
                                    requestLoggUserActivity(
                                      67,
                                      0,
                                      0,
                                      "",
                                      "Click en Comercial: " +
                                        item.nameModConten.toUpperCase()
                                    )
                                  )
                                }
                              >
                                <div
                                  className={`ads__content__button__inner ${
                                    isTablet
                                      ? "tablet"
                                      : isMobile
                                      ? "mobile"
                                      : ""
                                  } ${isLoadingEnable ? "skeletonActive" : ""}`}
                                >
                                  <div
                                    className={
                                      item.content?.find(
                                        (f) =>
                                          f.idModuleContentResourcesDetailType ===
                                          3
                                      )?.value
                                    }
                                  />
                                  <div>{item.valueResources}</div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        );
                      })}
                </div>
              </div>
            </div>
          )}
        </Fragment>
      )}
      {/* <SliderReferFriend
        isReferActive={isReferActive}
        setIsReferActive={setIsReferActive}
      /> */}
    </Fragment>
  );
};

export default AdBanners;
