import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, EffectCoverflow } from "swiper";
import { useSnackbar } from "notistack";
//MUI
import {
  Button,
  IconButton,
  ListItem,
  Typography,
  Skeleton,
} from "@mui/material";
import { Add, ArrowBack, Edit, Help, Remove } from "@mui/icons-material";
//Components
import NotificationSnackbar from "../NotificationSnackbar";
import ModalWarning from "../ModalWarning";
import KitStepOne from "./KitStepOne";
import KitSpetTwo from "./KitSpetTwo";
import ModalGiftCheck from "./ModalGiftCheck";
import SummaryKitsConfiguration from "./SummaryKitsConfiguration";
import NotFoundComponent from "../NotFoundComponent";
import CardPersonalizedFraganceSet from "../../../products/components/CardPersonalizedFraganceSet";
import Counter from "../Counter";
//Actions
import {
  requestGetSetupKitComponents,
  cleanGetSetupKitComponents,
  svg,
} from "../../../../ReduxSaga/Actions/Products";
import {
  requestCarCurrentOrder,
  requestGetCartOrder,
  requestCartOrderAddMembership,
  cleanCartOrderOperations,
  requestCleanCartReducerAddMembership,
} from "../../../../ReduxSaga/Actions/Cart";
import { requestLoggUserActivity } from "../../../../ReduxSaga/Actions/General";
import { requestBuildCustomLabelImagesOnServer } from "../../../../ReduxSaga/Actions/CustomLabels";
//Assets
import { ReactComponent as BagIcon } from "./../../../../../assets/media/images/icons/bagIcon.svg";
import { ReactComponent as BagWhiteIcon } from "./../../../../../assets/media/images/icons/bagIconWhiteAlt.svg";
import { ReactComponent as BagGrayIcon } from "./../../../../../assets/media/images/icons/bagIconGrayAlt.svg";
import { ReactComponent as FiltersClearIcon } from "./../../../../../assets/media/images/icons/filtersClearIcon.svg";
import { ReactComponent as NextArrowOrangeIcon } from "./../../../../../assets/media/images/icons/nextArrowOrange.svg";
import { ReactComponent as GoBackArrow } from "./../../../../../assets/media/images/icons/goBackArrow.svg";
import { ReactComponent as WarningIcon } from "./../../../../../assets/media/images/icons/warningRedIcon.svg";
import { ReactComponent as ConfigurablesPersonalizedFragranceIcon } from "./../../../../../assets/media/images/icons/configurablesPersonalizedFragranceIcon.svg";
import { ReactComponent as ConfigurablesIncludedProductIcon } from "./../../../../../assets/media/images/icons/configurablesIncludedProductIcon.svg";
import { ReactComponent as ConfigurablesGiftIcon } from "./../../../../../assets/media/images/icons/configurablesGiftIcon.svg";
import { ReactComponent as ErrorPlaceholder } from "./../../../../../assets/media/images/errorPlaceholder.svg";
import CenteredModal from "../CenteredModal";
import { clone } from "lodash";
import {
  capitalizeText,
  formatPrice,
} from "../../../../../Libraries/Utils/utils";

const NewKitsConfiguration = ({
  isEntreprising,
  isUndertakeShow,
  setShowKitConfiguration,
  itemCodeProps,
  tipoLineaProps,
  promoPrice,
  origenSetConfiguration /* (1)=VIENE DE EL MODULO DE EMPRENDEDOR /// (2)=VIENE DEL MODULO DE NUEVOS MIEMBROS */, //propiedad hvc
  setdetailsPromotionContent /* REGRESA EL ARREGLO CON LA CONFIGURACION */, //propiedad hvc
  setComponentsPromotionRender /* REGRESA EL ARREGLO CON LA CONFIGURACION SOLO PARA RENDEREIZAR*/, //propiedad hvc
  setIsConfig /* REGRESA AL CARRUSEL DE LOS KITS (MODULO DE EMPRENDEDOR) */, //propiedad hvc
  setKitHeadersConfig /* REGRESA LOS COMPONENTES DE LOS KITS (MODULO DE EMPRENDEDOR) */,
  setKitDetailsConfig /* REGRESA LOS COMPONENTES DE LOS KITS (MODULO DE EMPRENDEDOR) */,
  handleCloseSlideModal,
  setTypePlan,
  typePlan,
  cpMemberships,
  admissionKits,
  infoCartOrder,
  isLanguageLoaded,
}) => {
  //Notistack
  const { enqueueSnackbar } = useSnackbar();

  //Translation
  const { t, i18n } = useTranslation();

  //React Router
  const history = useHistory();
  const itemCode = useParams().itemCode;
  const typeLine = useParams().typeLine;

  //Redux
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.products = useSelector((state) => state.products);
  appReducers.cart = useSelector((state) => state.cart);
  appReducers.general = useSelector((state) => state.general);

  //States
  const [kitOpc, setkitOpc] = useState();
  const [isChooseActive, setIsChooseActive] = useState(true);
  const [isGiftActive, setIsGiftActive] = useState(false);
  const [isDetailActive, setisDetailActive] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isOpenNotification, setIsOpenNotification] = useState(false);
  const [isAddActived, setIsAddActived] = useState(false);
  const [kitHeaders, setKitHeaders] = useState([]);
  const [kitDetails, setKitDetails] = useState([]);
  const [finalKit, setFinalKit] = useState({
    header: [],
    details: [],
  });
  const [codeItem, setCodeItem] = useState("");

  const [pendNotification, setPendNotification] = useState(false);
  const [promoConfiguration, setPromoConfiguration] = useState({});
  //state render Newmembers Config
  const [finalArrayRenderNM, setFinalArrayRenderNM] = useState([]);
  /////Modal Warning
  const [isOpenModalWarning, setIsOpenModalWarning] = useState(false);
  //News
  const [isSwiperLocked, setIsSwiperLocked] = useState(false);

  const [
    isOpenDuplicateSetPersonalizedFragrancesWarningModal,
    setIsOpenDuplicateSetPersonalizedFragrancesWarningModal,
  ] = useState(false);

  ////Configuration Set
  const [totalHeader, setTotalHeader] = useState(0);
  const [totalDetail, setTotalDetail] = useState(0);

  const [finalArray, setFinalArray] = useState([]); //fakeConfigurableSelecteds               //este es el resumen de lo que selecciono
  const [configurableArray, setConfigurableArray] = useState([]); //fakeConfigurable          //este es el que al final se recorre en swwepper
  const [configuredSets, setConfiguredSets] = useState([]); //fakeConfiguredSets              //listado separado

  const [typeUser, setTypeUser] = useState("");
  const [showFavoriteIcon, setShowFavoriteIcon] = useState(false);
  const [countSendSetsToServer, setCountSendSetsToServer] = useState(0);

  const [isKitIngreso, setIsKitIngreso] = useState(false);

  const [indexDuplicate, setIndexDuplicate] = useState(0);

  const [buildImages, setBuildImages] = useState(false);

  const [kitsInnerWrapperHeight, setKitsInnerWrapperHeight] = useState(0);

  //Effectd
  //didmounth
  useEffect(() => {
    if (itemCode) {
      dispatch(requestGetSetupKitComponents(itemCode));
    } else {
      dispatch(requestGetSetupKitComponents(itemCodeProps));
    }

    handleCalculateKitsInnerWrapperHeight();

    window.addEventListener("resize", handleCalculateKitsInnerWrapperHeight);

    return () => {
      window.removeEventListener(
        "resize",
        handleCalculateKitsInnerWrapperHeight
      );
    };
    /////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////
  }, []);

  useEffect(() => {
    if (typeLine?.length > 0) {
      setkitOpc(typeLine);
      getStepHeaders(typeLine);
    }
  }, [typeLine]);

  useEffect(() => {
    if (tipoLineaProps?.length > 0) {
      setkitOpc(tipoLineaProps);
      getStepHeaders(tipoLineaProps);
    }
  }, [tipoLineaProps]);

  useEffect(() => {
    if (appReducers?.products?.kitSetupComponents) {
      const kits = appReducers.products.kitSetupComponents;
      if (
        itemCodeProps === kits.configuration.codigo ||
        itemCode === kits.configuration.codigo
      ) {
        if (kits.code === 100) {
          const idLang = i18n.language === "es" ? 1 : 2;
          setKitHeaders(kits.header);
          setKitDetails(kits.details);

          if (origenSetConfiguration === 2) {
            setKitHeadersConfig(kits.header);
            setKitDetailsConfig(kits.details);
          }
          setPromoConfiguration(kits.configuration);

          //es un kit de ingreso
          setIsKitIngreso(kits.configuration.isKitIngreso);
        }
      }
      dispatch(cleanGetSetupKitComponents());
    }
  }, [appReducers?.products?.kitSetupComponents]);

  useEffect(() => {
    if (
      promoConfiguration.hasOwnProperty("idpromo") ||
      promoConfiguration.hasOwnProperty("idPromo")
    ) {
      //debugger;
      const idLang = i18n.language === "es" ? 1 : 2;

      let configurationArrayFinal = [];
      let configurationFPArrayFinal = [];
      //No configurable se van al RESUMEN (HEADERS)
      if (kitHeaders.length > 0 && promoConfiguration.hVariable == 0) {
        //debugger;
        const cloneHeaderArray = [];
        cloneHeaderArray.push.apply(
          cloneHeaderArray,
          kitHeaders
            .filter((i) => i.idLang === idLang)
            .map((item) => ({
              idPedidoDet: 0,
              idPedido: 0,
              codart: item.itemCode,
              descripcion: item.description,
              qtyped: item.qtyAddSetFijo,
              qtyped2: item.qtyAddSetFijo,
              precioai: item.itemPrice,
              codean: item.idPromo.toString(),
              tipoLinea: item.isGiftSet === true ? "R" : "C",
              idpromo: parseInt(item.idPromo),
              comisionable: item.comisionable,
              status: "A",
              isHeader: true,
              isConfig: false,
              pageOrigin: 0,
            }))
        );
        setFinalArray(cloneHeaderArray);

        ////FRAGANCIAS PERSONALIDAS\\\\
        //sacar la catidad del set fijo
        kitHeaders
          .filter((i) => i.idLang === idLang)
          .map((item) => {
            //debugger;
            if (item.isCustomLabel) {
              for (let x = 0; x < item.qtyAddSetFijo; x++) {
                // const cloneHeaderFPArray = [];
                // cloneHeaderFPArray.push.apply(
                //   cloneHeaderFPArray,
                //   kitHeaders
                //     .filter((i) => i.idLang === idLang && i.isCustomLabel === true)
                //     .map((item) => ({
                //       id: x,
                //       image: `https://www.websizusa.net/imgprod/fullsize/${item.itemCode}_.jpg`,
                //       isSaved: false,
                //       label: "",
                //       template: "",
                //       itemCode: item.itemCode,
                //     }))
                // );
                // if (cloneHeaderFPArray.length > 0) {
                //   cloneHeaderFPArray.map((item) => {
                //     let headersConfigurableArray = {
                //       order: 2,
                //       type: "personalizedFragrance",
                //       item: item,
                //     };
                //     configurationFPArrayFinal.push(headersConfigurableArray);
                //   });
                // }

                let itemTest = {
                  id: x, //crea
                  image: `https://www.websizusa.net/imgprod/fullsize/${item.itemCode}_.jpg`,
                  isSaved: false,
                  label: "",
                  template: "",
                  itemCode: item.itemCode,
                };

                let headersConfigurableArray = {
                  order: 2,
                  type: "personalizedFragrance",
                  item: itemTest,
                };

                configurationFPArrayFinal.push(headersConfigurableArray);
              }
            }
          });
        ////FRAGANCIAS PERSONALIDAS\\\\

        // //obtener el render arreglo del render en NM (Header)
        // setFinalArrayRenderNM(kitHeaders);
      }

      //Configurable se van al listado de seleccion (HEADERS)
      //debugger
      if (kitHeaders.length > 0 && promoConfiguration.hVariable == 1) {
        let headersConfigurableArray = {
          order: 1,
          type: "list",
          selectables: promoConfiguration.cant_HVariable,
          items: [],
        };
        const cloneHeaderConfigurableArray = [];
        cloneHeaderConfigurableArray.push.apply(
          cloneHeaderConfigurableArray,
          kitHeaders
            .filter((i) => i.idLang === idLang && i.isGiftSet === false)
            .map((item) => ({
              idPedidoDet: 0,
              idPedido: 0,
              codart: item.itemCode,
              descripcion: item.description,
              qtyped: item.qtyAddSetFijo,
              qtyped2: item.qtyAddSetFijo,
              precioai: item.itemPrice,
              codean: item.idPromo.toString(),
              tipoLinea: "C",
              idPromo: parseInt(item.idPromo),
              comisionable: item.comisionable,
              status: "A",
              isHeader: true,
              isConfig: true,
              pageOrigin: 0,
            }))
        );
        ////aun no se si este se va autilizar
        //setConfigurableArray(cloneHeaderConfigurableArray);

        headersConfigurableArray.items = cloneHeaderConfigurableArray;

        configurationArrayFinal.push(headersConfigurableArray);

        //meter los regalos en el array del resumen
        const cloneHeaderGiftsArray = [];
        cloneHeaderGiftsArray.push.apply(
          cloneHeaderGiftsArray,
          kitHeaders
            .filter((i) => i.idLang === idLang && i.isGiftSet === true)
            .map((item) => ({
              idPedidoDet: 0,
              idPedido: 0,
              codart: item.itemCode,
              descripcion: item.description,
              qtyped: item.qtyAddSetFijo,
              qtyped2: item.qtyAddSetFijo,
              precioai: item.itemPrice,
              codean: item.idPromo.toString(),
              tipoLinea: "R",
              idpromo: parseInt(item.idPromo),
              comisionable: item.comisionable,
              status: "A",
              isHeader: true,
              isConfig: false,
              pageOrigin: 0,
            }))
        );
        setFinalArray(cloneHeaderGiftsArray);

        // //obtener el render arreglo del render en NM (Header)
        // setFinalArrayRenderNM(kitHeaders);
      }

      //No configurable se van al RESUMEN (DETAILS)
      if (kitDetails.length > 0 && promoConfiguration.dVariable == 0) {
        const cloneDetailArray = [];
        cloneDetailArray.push.apply(
          cloneDetailArray,
          kitDetails
            .filter((i) => i.idLang === idLang)
            .map((item) => ({
              idPedidoDet: 0,
              idPedido: 0,
              codart: item.itemCode,
              descripcion: item.description,
              qtyped: item.qtyAddSetFijo,
              qtyped2: item.qtyAddSetFijo,
              precioai: item.itemPrice,
              codean: item.idPromo.toString(),
              tipoLinea: "C",
              idpromo: parseInt(item.idPromo),
              comisionable: item.comisionable,
              status: "A",
              isHeader: false,
              isConfig: false,
              pageOrigin: 1,
            }))
        );
        setFinalArray(cloneDetailArray);

        ////FRAGANCIAS PERSONALIDAS\\\\
        kitDetails
          .filter((i) => i.idLang === idLang)
          .map((item) => {
            if (item.isCustomLabel) {
              for (let x = 0; x < item.qtyAddSetFijo; x++) {
                let itemTest = {
                  id: x, //crea
                  image: `https://www.websizusa.net/imgprod/fullsize/${item.itemCode}_.jpg`,
                  isSaved: false,
                  label: "",
                  template: "",
                  itemCode: item.itemCode,
                };

                let headersConfigurableArray = {
                  order: 2,
                  type: "personalizedFragrance",
                  item: itemTest,
                };

                configurationFPArrayFinal.push(headersConfigurableArray);
              }
            }
          });
        // const cloneDetailFPArray = [];
        // cloneDetailFPArray.push.apply(
        //   cloneDetailFPArray,
        //   kitDetails
        //     .filter((i) => i.idLang === idLang && i.isCustomLabel === true)
        //     .map((item) => ({
        //       id: 0,
        //       image: `https://www.websizusa.net/imgprod/fullsize/${item.itemCode}_.jpg`,
        //       isSaved: false,
        //       label: "",
        //       template: "",
        //       itemCode: item.itemCode,
        //     }))
        // );

        // if (cloneDetailFPArray.length > 0) {
        //   cloneDetailFPArray.map((item) => {
        //     let headersConfigurableArray = {
        //       order: 2,
        //       type: "personalizedFragrance",
        //       item: item,
        //     };

        //     configurationFPArrayFinal.push(headersConfigurableArray);
        //   });
        // }

        // //obtener el render arreglo del render en NM (Detail)
        // setFinalArrayRenderNM(kitDetails);
      }

      //Configurable se van al listado de seleccion (DETAILS)
      if (kitDetails.length > 0 && promoConfiguration.dVariable == 1) {
        let detailsConfigurableArray = {
          order: 1,
          type: "list",
          selectables: promoConfiguration.cant_DVariable,
          items: [],
        };

        const cloneDetailConfigurableArray = [];
        cloneDetailConfigurableArray.push.apply(
          cloneDetailConfigurableArray,
          kitDetails
            .filter((i) => i.idLang === idLang)
            .map((item) => ({
              idPedidoDet: 0,
              idPedido: 0,
              codart: item.itemCode,
              descripcion: item.description,
              qtyped: item.qtyAddSetFijo,
              qtyped2: item.qtyAddSetFijo,
              precioai: item.itemPrice,
              codean: item.idPromo.toString(),
              tipoLinea: "C",
              idPromo: parseInt(item.idPromo),
              comisionable: item.comisionable,
              status: "A",
              isHeader: false,
              isConfig: true,
              pageOrigin: 1,
            }))
        );
        ////aun no se si este se va autilizar
        // setFinalArray(cloneDetailArray);

        detailsConfigurableArray.items = cloneDetailConfigurableArray;

        configurationArrayFinal.push(detailsConfigurableArray);

        // //obtener el render arreglo del render en NM (Detail)
        // setFinalArrayRenderNM(kitDetails);
      }

      //// INDICE A LAS FRAGANCIAS PERSONALIZADAS \\\\
      if (configurationFPArrayFinal.length > 0) {
        configurationFPArrayFinal.map((elemnt, idx) => {
          ////indice\\\\
          elemnt.item.id = idx;
          ////se agrega al arrlego final de configuracion\\\\
          configurationArrayFinal.push(elemnt);
        });
      }
      //// INDICE A LAS FRAGANCIAS PERSONALIZADAS \\\\
      //console.log("configurationArrayFinal", configurationArrayFinal);
      setConfigurableArray(configurationArrayFinal);
    }
  }, [promoConfiguration, kitHeaders, kitDetails]);

  //
  useEffect(() => {
    if (typeof appReducers?.general?.errorLoadFirstCharge !== "undefined") {
      if (!appReducers.general.errorLoadFirstCharge) {
        //debugger;
        const { isGuestUser } = appReducers.general.firstChargeModel;
        if (isGuestUser === false) setShowFavoriteIcon(true);
        //debugger;
        //userLogeado
        const { typeUser } = appReducers.general.firstChargeModel;

        if (typeUser === "2") {
          setTypePlan("desc");
        }
        setTypeUser(typeUser);
      }
    }
  }, [
    appReducers?.general.errorLoadFirstCharge,
    appReducers?.general.firstChargeModel,
  ]);

  //SE AGREGO UN KIT FIJO
  useEffect(() => {
    if (appReducers?.cart?.cartAddMembership) {
      const AddMembership = appReducers.cart.cartAddMembership;
      if (AddMembership.code === 100) {
        //limpiar el reducer
        dispatch(requestCleanCartReducerAddMembership());
        dispatch(cleanCartOrderOperations());

        const typeOp = appReducers?.cart?.cartOpType;
        switch (typeOp) {
          case "add":
            enqueueSnackbar(t("__message_success_product_add_cart_label"), {
              variant: "success",
            });
            break;
          case "modify":
            enqueueSnackbar(
              t("__message_success_product_modified_cart_label"),
              {
                variant: "success",
              }
            );
            break;
          case "delete":
            enqueueSnackbar(t("__message_success_product_deleted_cart_label"), {
              variant: "success",
            });
            break;
        }

        //traer la nueva orden
        dispatch(requestGetCartOrder());
        history.goBack();
      }
    }
  }, [appReducers?.cart?.cartAddMembership]);

  useEffect(() => {
    if (
      appReducers?.cart?.cartCurrentOrder?.code &&
      appReducers?.cart?.cartCurrentOrder?.code === 100
    ) {
      if (
        configuredSets.filter((item) => item.isSaved).length ===
        countSendSetsToServer
      ) {
        const typeOp = appReducers?.cart?.cartOpType;
        switch (typeOp) {
          case "add":
            enqueueSnackbar(t("__message_success_product_add_cart_label"), {
              variant: "success",
            });
            break;
          case "modify":
            enqueueSnackbar(
              t("__message_success_product_modified_cart_label"),
              {
                variant: "success",
              }
            );
            break;
          case "delete":
            enqueueSnackbar(t("__message_success_product_deleted_cart_label"), {
              variant: "success",
            });
            break;
        }

        //debugger;
        if (
          configuredSets.filter(
            (x) => x.personalizedFragrances.length > 0 && x.isSaved
          ).length > 0
        ) {
          setBuildImages(true);
        }

        if (typeUser !== "2") {
          const { lines } = appReducers.cart.cartOrder;
          let isAddMembership = lines.some(
            (item) => item.line.codart === cpMemberships[0].itemCode
          );
          if (typePlan === "desc" && !isAddMembership) {
            //si no tiene membresia en la orden debe agregarla
            if (cpMemberships.length > 0) {
              dispatch(
                requestCartOrderAddMembership(
                  -1,
                  0,
                  2,
                  0,
                  cpMemberships[0].itemCode
                )
              );
            } else {
              dispatch(requestCartOrderAddMembership(-1, 0, 2, 0, "02110"));
            }
          } else {
            dispatch(cleanCartOrderOperations());
            dispatch(requestGetCartOrder());
            history.goBack();
          }
        } else {
          //revisar a fondeee este else
          dispatch(cleanCartOrderOperations());
          dispatch(requestGetCartOrder());
          history.goBack();
        }
        //dispatch(cleanCartOrderOperations());
      }

      // setIsOpenNotification(true);
      //setPendNotification(false);
      // //validar si esta en el checkout (modal)
      // if (!isEntreprising) {
      //   handleCloseSlideModal();
      // }
      // else {
      //   setTimeout(() => {
      //     history.push("/promotions");
      //   }, 1000);
      // }
      //dispatch(requestGetCartOrder());
    }
  }, [appReducers?.cart?.cartCurrentOrder, countSendSetsToServer]);

  useEffect(() => {
    if (activeStep == 2) {
      // const PROMO = appReducers?.products?.promotionsEs.find(f => f.itemCode === itemCode);
      // const auxArray = [{
      //   codart: itemCode,
      //   codean: null,
      //   comisionable: 0,
      //   descripcion: PROMO.nameArticle,
      //   idPedido: 0,
      //   idPedidoDet: 0,
      //   idpromo: kitHeaders.find(f => f.idPromo !== "").idPromo,
      //   precioai: 35,
      //   qtyped: 1,
      //   qtyped2: 1,
      //   status: "A",
      //   tipoLinea: "K"
      // }];
      // const arrayProds = finalKit.header.concat(finalKit.details);
      //const final = auxArray.concat(arrayProds);
      //console.log("FINAL FINAL => ", arrayProds);

      //if del tocayo/ si ven que hace cosas raras me avisan o lo quitan TODO MENOS LO QUE ESTA DENTRO DEL IF :V
      if (origenSetConfiguration === 2) {
        if (finalArray.length > 0) {
          setdetailsPromotionContent(finalArray);
          setShowKitConfiguration(false);
        }
      }
    }
  }, [activeStep]);

  useEffect(() => {
    switch (activeStep) {
      case 0:
        setIsChooseActive(true);
        setIsGiftActive(false);
        setisDetailActive(false);

        break;
      case 1:
        //validar si es FV o VF
        if (kitOpc === "VF" || kitOpc === "VV") {
          setIsChooseActive(true);
          setIsGiftActive(false);
          setisDetailActive(false);
        } else {
          setIsChooseActive(true);
          setIsGiftActive(false);
          setisDetailActive(false);
        }

        break;
      case 2:
        setIsChooseActive(false);
        setIsGiftActive(false);
        setisDetailActive(true);

        break;
    }
  }, [activeStep]);

  useEffect(() => {
    const configurablesSwiper = document.getElementById("configurablesSwiper");
    if (configurablesSwiper) {
      //console.log("configurablesSwiper", configurablesSwiper);
      if (isSwiperLocked) {
        //console.log("lock");
        configurablesSwiper.swiper.slideTo(0);
        configurablesSwiper.swiper.disable();
      } else {
        //console.log("enable");
        configurablesSwiper.swiper.enable();
      }
    }
  }, [isSwiperLocked]);

  useEffect(() => {
    //debugger;
    if (isKitIngreso) {
      const configurablesSwiper = document.getElementById(
        "configurablesSwiper"
      );
      if (configuredSets.filter((x) => x.isSaved).length === 1) {
        configurablesSwiper.swiper.disable();
      } else {
        configurablesSwiper.swiper.enable();
      }
    }

    if (typeLine === "VV") {
      //debugger
      if (finalArray.filter((x) => x.tipoLinea === "C").length === 1) {
        //validar que no hayan mas de un array incopleto
        if (
          configuredSets.filter((x) => !x.isSaved).length === 0 &&
          configuredSets.filter((x) => x.isSaved).length === 0
        ) {
          //promo actual sin ser configurada
          //se agrega un nuevo elemnto vaciando
          let cloneConfiguredSets = [];
          cloneConfiguredSets.push({
            isSaved: false,
            itemCode: promoConfiguration.codigo,
            name: promoConfiguration.descripcion,
            image: `https://www.websizusa.net/imgprod/fullsize/${promoConfiguration.codigo}.jpg`,
            price: promoConfiguration.precio,
            contents: [],
            personalizedFragrances: [],
            numberConfig: 0,
            personalizedConfig: [],
          });
          setConfiguredSets(cloneConfiguredSets);
        } else {
          if (
            configuredSets.filter((x) => x.isSaved).length > 0 &&
            configuredSets.filter((x) => !x.isSaved).length === 0
          ) {
            //aqui esta el pedo
            let cloneConfiguredSets = [...configuredSets];
            cloneConfiguredSets.push({
              isSaved: false,
              itemCode: promoConfiguration.codigo,
              name: promoConfiguration.descripcion,
              image: `https://www.websizusa.net/imgprod/fullsize/${promoConfiguration.codigo}.jpg`,
              price: promoConfiguration.precio,
              contents: [],
              personalizedFragrances: [],
              numberConfig: 0,
              personalizedConfig: [],
            });
            setConfiguredSets(cloneConfiguredSets);
          }
        }
      } else {
        if (finalArray.filter((x) => x.tipoLinea === "C").length <= 0) {
          const pos = configuredSets.map((e) => e.isSaved).indexOf(false);
          if (pos >= 0) {
            let cloneConfiguredSets = [...configuredSets];
            cloneConfiguredSets.splice(pos, 1);
            setConfiguredSets(cloneConfiguredSets);
          }
        }
      }
    } else if (typeLine === "FV") {
      //debugger;
      if (
        finalArray.filter((x) => x.tipoLinea === "C" && x.isHeader === false)
          .length === 1
      ) {
        //validar que no hayan mas de un array incopleto
        if (
          configuredSets.filter((x) => !x.isSaved).length === 0 &&
          configuredSets.filter((x) => x.isSaved).length === 0
        ) {
          //promo actual sin ser configurada
          //se agrega un nuevo elemnto vaciando
          let cloneConfiguredSets = [];
          cloneConfiguredSets.push({
            isSaved: false,
            itemCode: promoConfiguration.codigo,
            name: promoConfiguration.descripcion,
            image: `https://www.websizusa.net/imgprod/fullsize/${promoConfiguration.codigo}.jpg`,
            price: promoConfiguration.precio,
            contents: [],
            personalizedFragrances: [],
            numberConfig: 0,
            personalizedConfig: [],
          });
          setConfiguredSets(cloneConfiguredSets);
        } else {
          if (
            configuredSets.filter((x) => x.isSaved).length > 0 &&
            configuredSets.filter((x) => !x.isSaved).length === 0
          ) {
            //aqui esta el pedo
            let cloneConfiguredSets = [...configuredSets];
            cloneConfiguredSets.push({
              isSaved: false,
              itemCode: promoConfiguration.codigo,
              name: promoConfiguration.descripcion,
              image: `https://www.websizusa.net/imgprod/fullsize/${promoConfiguration.codigo}.jpg`,
              price: promoConfiguration.precio,
              contents: [],
              personalizedFragrances: [],
              numberConfig: 0,
              personalizedConfig: [],
            });
            setConfiguredSets(cloneConfiguredSets);
          }
        }
      } else {
        //debugger;
        if (
          finalArray.filter((x) => x.tipoLinea === "C" && x.isHeader === false)
            .length <= 0
        ) {
          const pos = configuredSets.map((e) => e.isSaved).indexOf(false);
          if (pos >= 0) {
            let cloneConfiguredSets = [...configuredSets];
            cloneConfiguredSets.splice(pos, 1);
            setConfiguredSets(cloneConfiguredSets);
          }
        }
      }
    } else if (typeLine === "VF") {
      //debugger;
      if (
        finalArray.filter((x) => x.tipoLinea === "C" && x.isHeader === true)
          .length === 1
      ) {
        //validar que no hayan mas de un array incopleto
        if (
          configuredSets.filter((x) => !x.isSaved).length === 0 &&
          configuredSets.filter((x) => x.isSaved).length === 0
        ) {
          //promo actual sin ser configurada
          //se agrega un nuevo elemnto vaciando
          let cloneConfiguredSets = [];
          cloneConfiguredSets.push({
            isSaved: false,
            itemCode: promoConfiguration.codigo,
            name: promoConfiguration.descripcion,
            image: `https://www.websizusa.net/imgprod/fullsize/${promoConfiguration.codigo}.jpg`,
            price: promoConfiguration.precio,
            contents: [],
            personalizedFragrances: [],
            numberConfig: 0,
            personalizedConfig: [],
          });
          setConfiguredSets(cloneConfiguredSets);
        } else {
          if (
            configuredSets.filter((x) => x.isSaved).length > 0 &&
            configuredSets.filter((x) => !x.isSaved).length === 0
          ) {
            //aqui esta el pedo
            let cloneConfiguredSets = [...configuredSets];
            cloneConfiguredSets.push({
              isSaved: false,
              itemCode: promoConfiguration.codigo,
              name: promoConfiguration.descripcion,
              image: `https://www.websizusa.net/imgprod/fullsize/${promoConfiguration.codigo}.jpg`,
              price: promoConfiguration.precio,
              contents: [],
              personalizedFragrances: [],
              numberConfig: 0,
              personalizedConfig: [],
            });
            setConfiguredSets(cloneConfiguredSets);
          }
        }
      } else {
        //debugger;
        if (
          finalArray.filter((x) => x.tipoLinea === "C" && x.isHeader === true)
            .length <= 0
        ) {
          const pos = configuredSets.map((e) => e.isSaved).indexOf(false);
          if (pos >= 0) {
            let cloneConfiguredSets = [...configuredSets];
            cloneConfiguredSets.splice(pos, 1);
            setConfiguredSets(cloneConfiguredSets);
          }
        }
      }
    } else if (typeLine === "3x2") {
      //debugger;
      if (
        finalArray.filter((x) => x.tipoLinea === "C" && x.isHeader === true)
          .length === 1
      ) {
        //validar que no hayan mas de un array incopleto
        if (
          configuredSets.filter((x) => !x.isSaved).length === 0 &&
          configuredSets.filter((x) => x.isSaved).length === 0
        ) {
          //promo actual sin ser configurada
          //se agrega un nuevo elemnto vaciando
          let cloneConfiguredSets = [];
          cloneConfiguredSets.push({
            isSaved: false,
            itemCode: promoConfiguration.codigo,
            name: promoConfiguration.descripcion,
            image: `https://www.websizusa.net/imgprod/fullsize/${promoConfiguration.codigo}.jpg`,
            price: promoConfiguration.precio,
            contents: [],
            personalizedFragrances: [],
            numberConfig: 0,
            personalizedConfig: [],
          });
          setConfiguredSets(cloneConfiguredSets);
        } else {
          if (
            configuredSets.filter((x) => x.isSaved).length > 0 &&
            configuredSets.filter((x) => !x.isSaved).length === 0
          ) {
            //aqui esta el pedo
            let cloneConfiguredSets = [...configuredSets];
            cloneConfiguredSets.push({
              isSaved: false,
              itemCode: promoConfiguration.codigo,
              name: promoConfiguration.descripcion,
              image: `https://www.websizusa.net/imgprod/fullsize/${promoConfiguration.codigo}.jpg`,
              price: promoConfiguration.precio,
              contents: [],
              personalizedFragrances: [],
              numberConfig: 0,
              personalizedConfig: [],
            });
            setConfiguredSets(cloneConfiguredSets);
          }
        }
      } else {
        //debugger;
        if (
          finalArray.filter((x) => x.tipoLinea === "C" && x.isHeader === true)
            .length <= 0
        ) {
          const pos = configuredSets.map((e) => e.isSaved).indexOf(false);
          if (pos >= 0) {
            let cloneConfiguredSets = [...configuredSets];
            cloneConfiguredSets.splice(pos, 1);
            setConfiguredSets(cloneConfiguredSets);
          }
        }
      }
    }
  }, [finalArray]);

  //construir imagenes
  useEffect(() => {
    if (
      buildImages &&
      infoCartOrder?.hasOwnProperty("code") &&
      infoCartOrder?.code === 100
    ) {
      //debugger;
      if (infoCartOrder?.order && infoCartOrder?.order !== null) {
        const orderId = infoCartOrder?.order?.orderId;
        dispatch(requestBuildCustomLabelImagesOnServer(orderId));
        setBuildImages(false);
      }
    }
  }, [buildImages, infoCartOrder]);

  useEffect(() => {
    if (configurableArray.length > 0) {
      const result = configurableArray.find((item) =>
        item?.items?.find((item2) => item2.isHeader)
      );
      //console.log("result", result);
      if (totalHeader === result?.selectables) {
        //console.log("HEADER = Listo!!");
        const configurablesSwiper = document.getElementById(
          "configurablesSwiper"
        );
        if (configurablesSwiper) {
          configurablesSwiper.swiper.slideNext();
        }
      }
    }
  }, [totalHeader, configurableArray.length]);

  useEffect(() => {
    if (configurableArray.length > 0) {
      const result = configurableArray.find((item) =>
        item?.items?.find((item2) => !item2.isHeader)
      );
      //console.log("result", result);
      if (totalDetail === result?.selectables) {
        //console.log("DETAILS = Listo!!");
        const configurablesSwiper = document.getElementById(
          "configurablesSwiper"
        );
        if (configurablesSwiper) {
          configurablesSwiper.swiper.slideNext();
        }
      }
    }
  }, [totalDetail, configurableArray.length]);

  //Handlers
  const handleCalculateKitsInnerWrapperHeight = () => {
    //console.log("handleCalculateKitsInnerWrapperHeight");
    //Calculate height for kits inner wrapper
    const navbarFloatingBarHeight = document
      .querySelector(".navbar__floating__bar")
      .getBoundingClientRect().height;
    const baseContainerWrapperHeight = document
      .getElementById("baseContainer__wrapper")
      .getBoundingClientRect().height;

    if (baseContainerWrapperHeight && navbarFloatingBarHeight) {
      const kitsConfigurationContainerInnerWrapperHeight =
        baseContainerWrapperHeight - navbarFloatingBarHeight - 100;
      setKitsInnerWrapperHeight(kitsConfigurationContainerInnerWrapperHeight);
    }
  };
  const handleOpenWarning = () => {
    setIsOpenModalWarning(!isOpenModalWarning);
  };
  const handleGetCountSettedHeaders = (itemCode) => {
    const model = finalArray.find((f) => f.codart === itemCode && f.isHeader);
    if (model) return model.qtyped;
    else return 0;
  };
  const handleModifyKitHeader = (prod, quantity) => {
    const item = kitHeaders.find((x) => x.itemCode === prod.codart);
    //debugger;

    // console.log("configurable.selectables", configurableArray);
    // console.log("totalHeader", totalHeader + quantity);

    setTotalHeader(totalHeader + quantity);

    if (finalArray.find((i) => i.codart === item.itemCode && i.isHeader)) {
      const finalArrayCloned = [...finalArray];
      const model = finalArrayCloned.find(
        (i) => i.codart === item.itemCode && i.isHeader
      );
      // model.qtyped = model.qtyped + quantity;
      // model.qtyped2 = model.qtyped2 + quantity;
      model.qtyped =
        model.qtyped +
        (item.qtyAddSetFijo > 0 ? quantity * item.qtyAddSetFijo : quantity);
      model.qtyped2 =
        model.qtyped2 +
        (item.qtyAddSetFijo > 0 ? quantity * item.qtyAddSetFijo : quantity);
      const index = finalArrayCloned.findIndex(
        (i) => i.codart === item.itemCode && i.isHeader
      );
      if (model.qtyped <= 0) {
        finalArrayCloned.splice(index, 1);
      } else {
        finalArrayCloned.splice(index, 1, model);
      }
      setFinalArray(finalArrayCloned);

      //debugger;
      dispatch(
        requestLoggUserActivity(
          76,
          0,
          0,
          item.itemCode,
          quantity > 0
            ? "Se incrementó la cantidad del articulo en la configuracion"
            : "Se decremento la cantidad del articulo en la configuracion"
        )
      );
    } else {
      setFinalArray([
        ...finalArray,
        {
          idPedidoDet: 0,
          idPedido: 0,
          codart: item.itemCode,
          descripcion: item.description,
          qtyped:
            item.qtyAddSetFijo > 0 ? quantity * item.qtyAddSetFijo : quantity, //quantity,
          qtyped2:
            item.qtyAddSetFijo > 0 ? quantity * item.qtyAddSetFijo : quantity, //quantity,
          precioai: item.itemPrice,
          codean: item.idPromo.toString(),
          tipoLinea: "C",
          idpromo: parseInt(item.idPromo),
          comisionable: item.comisionable,
          status: "A",
          isHeader: true,
          isConfig: true,
          pageOrigin: 0,
        },
      ]);
      //debugger;
      dispatch(
        requestLoggUserActivity(
          76,
          0,
          0,
          item.itemCode,
          quantity > 0
            ? "Se agrego el articulo a la configuracion"
            : "Se elimino el articulo a la configuracion"
        )
      );
    }

    //FRAGANCIAS PERSONALIZADA

    if (quantity > 0 && item.isCustomLabel) {
      let cloneArrayFinal = [...configurableArray];

      for (let x = 0; x < item.qtyAddSetFijo; x++) {
        //se agrego
        let itemTest = {
          id: x, //crea
          image: `https://www.websizusa.net/imgprod/fullsize/${item.itemCode}_.jpg`,
          isSaved: false,
          label: "",
          template: "",
          itemCode: item.itemCode,
        };

        let headersConfigurableArray = {
          order: 2,
          type: "personalizedFragrance",
          item: itemTest,
        };

        cloneArrayFinal.push(headersConfigurableArray);
      }

      setConfigurableArray(cloneArrayFinal);
    } else if (quantity < 0 && item.isCustomLabel) {
      //debugger;
      let cloneArrayFinal = [...configurableArray];

      for (let x = 0; x < item.qtyAddSetFijo; x++) {
        let idxDel = cloneArrayFinal.findIndex(
          (i) =>
            i.type === "personalizedFragrance" &&
            i.item.itemCode === item.itemCode
        );

        cloneArrayFinal.splice(idxDel, item.qtyAddSetFijo);
      }

      setConfigurableArray(cloneArrayFinal);
    }
  };
  const handleGetCountSettedDetails = (itemCode) => {
    //debugger;
    const model = finalArray.find((f) => f.codart === itemCode && !f.isHeader);
    if (model) return model.qtyped;
    else return 0;
  };
  const handleModifyKitDetails = (prod, quantity) => {
    //debugger;
    const item = kitDetails.find((x) => x.itemCode === prod.codart);

    setTotalDetail(totalDetail + quantity);
    if (finalArray.find((i) => i.codart === item.itemCode && !i.isHeader)) {
      const finalArrayCloned = [...finalArray];
      const model = finalArrayCloned.find(
        (i) => i.codart === item.itemCode && !i.isHeader
      );
      model.qtyped =
        model.qtyped +
        (item.qtyAddSetFijo > 0 ? quantity * item.qtyAddSetFijo : quantity);
      model.qtyped2 =
        model.qtyped2 +
        (item.qtyAddSetFijo > 0 ? quantity * item.qtyAddSetFijo : quantity);
      const index = finalArrayCloned.findIndex(
        (i) => i.codart === item.itemCode && !i.isHeader
      );
      if (model.qtyped <= 0) {
        finalArrayCloned.splice(index, 1);
      } else {
        finalArrayCloned.splice(index, 1, model);
      }
      setFinalArray(finalArrayCloned);

      //debugger;
      dispatch(
        requestLoggUserActivity(
          77,
          0,
          0,
          item.itemCode,
          quantity > 0
            ? "Se incrementó la cantidad del articulo en la configuracion"
            : "Se decremento la cantidad del articulo en la configuracion"
        )
      );
    } else {
      setFinalArray([
        ...finalArray,
        {
          idPedidoDet: 0,
          idPedido: 0,
          codart: item.itemCode,
          descripcion: item.description,
          qtyped:
            item.qtyAddSetFijo > 0 ? quantity * item.qtyAddSetFijo : quantity, //quantity,
          qtyped2:
            item.qtyAddSetFijo > 0 ? quantity * item.qtyAddSetFijo : quantity, //quantity,
          precioai: item.itemPrice,
          codean: item.idPromo.toString(),
          tipoLinea: "C",
          idpromo: parseInt(item.idPromo),
          comisionable: item.comisionable,
          status: "A",
          isHeader: false,
          isConfig: true,
          pageOrigin: typeLine === "VV" ? 1 : typeLine === "FV" ? 0 : 0,
        },
      ]);

      //debugger;
      dispatch(
        requestLoggUserActivity(
          77,
          0,
          0,
          item.itemCode,
          quantity > 0
            ? "Se agrego el articulo a la configuracion"
            : "Se elimino el articulo a la configuracion"
        )
      );
    }

    //FRAGANCIAS PERSONALIZADA
    if (quantity > 0 && item.isCustomLabel) {
      //debugger
      let cloneArrayFinal = [...configurableArray];

      for (let x = 0; x < item.qtyAddSetFijo; x++) {
        //se agrego
        let itemTest = {
          id: x, //crea
          image: `https://www.websizusa.net/imgprod/fullsize/${item.itemCode}_.jpg`,
          isSaved: false,
          label: "",
          template: "",
          itemCode: item.itemCode,
        };

        let headersConfigurableArray = {
          order: 2,
          type: "personalizedFragrance",
          item: itemTest,
        };

        cloneArrayFinal.push(headersConfigurableArray);
      }

      setConfigurableArray(cloneArrayFinal);
    } else if (quantity < 0 && item.isCustomLabel) {
      //debugger;
      let cloneArrayFinal = [...configurableArray];

      let idxDel = cloneArrayFinal.findIndex(
        (i) =>
          i.type === "personalizedFragrance" &&
          i.item.itemCode === item.itemCode
      );

      cloneArrayFinal.splice(idxDel, item.qtyAddSetFijo);

      setConfigurableArray(cloneArrayFinal);
    }
  };
  // const handle ciclo mandar orden
  const handlePushToOrderV2 = () => {
    if (typeLine !== "3x2") {
      let saveToServer = configuredSets.filter((item) => item.isSaved);
      //debugger;
      if (saveToServer.length > 0) {
        let countCicle = 0;

        saveToServer.map((element) => {
          //let clonToSendOnServer = removeItemsDuplicates([...finalArray]);
          let cloneElements = element.contents;

          let arrayContentElementsSend = [];

          //barremos el array configurado para armar el objeto que necesitamos
          cloneElements.map((element) => {
            let objCustom = {};

            objCustom.idPedidoDet = element.idPedidoDet;
            objCustom.idPedido = element.idPedido;
            objCustom.codart = element.codart;
            objCustom.descripcion = element.descripcion;
            objCustom.qtyped = element.qtyped;
            objCustom.qtyped2 = element.qtyped2;
            objCustom.precioai = element.precioai;
            objCustom.codean = element.codean;
            objCustom.tipoLinea = element.tipoLinea;
            objCustom.idpromo = element.idpromo;
            objCustom.comisionable = element.comisionable;
            objCustom.status = element.status;

            arrayContentElementsSend.push(objCustom);
          });

          const promoModel = {
            idPedidoDet: 0,
            idPedido: 0,
            codart: promoConfiguration.codigo,
            descripcion: promoConfiguration.descripcion,
            qtyped: element.numberConfig,
            qtyped2: element.numberConfig,
            precioai: promoConfiguration.precio,
            codean: null,
            tipoLinea: promoConfiguration.isKitIngreso === 1 ? "K" : kitOpc,
            idpromo: promoConfiguration.idPromo,
            comisionable: promoConfiguration.comisionable
              ? promoConfiguration.comisionable
              : 0,
            status: "A",
            isHeader: true,
          };

          arrayContentElementsSend.push(promoModel);

          const parameters = {
            idPedidoDet: 1,
            idpromo: 1,
            itemCode: promoConfiguration.codigo,
            opc: element.personalizedFragrances.length > 0 ? 23 : 16,
            pedidosD: arrayContentElementsSend,
            quantity: element.numberConfig,
            type: kitOpc,
            withAssign: 0,
          };
          //debugger;
          dispatch(
            requestCarCurrentOrder(
              parameters,
              "add",
              element.personalizedFragrances.length > 0
                ? element.personalizedFragrances
                : []
            )
          );

          countCicle = countCicle + 1;
        });
        setCountSendSetsToServer(countCicle);
        //hacer back a la pagina anterior
        //history.goBack();
        dispatch(
          requestLoggUserActivity(
            79,
            0,
            0,
            "",
            "Se Envia al Servidor Las configuraciones del Set."
          )
        );
      }
    } else {
      let saveToServer = configuredSets.filter((item) => item.isSaved);
      //debugger;
      if (saveToServer.length > 0) {
        saveToServer.map((element) => {
          //let clonToSendOnServer = removeItemsDuplicates([...finalArray]);
          let cloneElements = element.contents;

          let arrayContentElementsSend = [];

          //barremos el array configurado para armar el objeto que necesitamos
          cloneElements.map((element) => {
            let objCustom = {};

            objCustom.idPedidoDet = element.idPedidoDet;
            objCustom.idPedido = element.idPedido;
            objCustom.codart = element.codart;
            objCustom.descripcion = element.descripcion;
            objCustom.qtyped = element.qtyped;
            objCustom.qtyped2 = element.qtyped2;
            objCustom.precioai = element.precioai;
            objCustom.codean = element.codean;
            objCustom.tipoLinea = element.tipoLinea;
            objCustom.idpromo = element.idpromo;
            objCustom.comisionable = element.comisionable;
            objCustom.status = element.status;

            arrayContentElementsSend.push(objCustom);
          });
          //debugger;
          const promoModel = {
            idPedidoDet: 0,
            idPedido: 0,
            codart: promoConfiguration.codigo,
            descripcion: promoConfiguration.descripcion,
            qtyped: element.numberConfig,
            qtyped2: element.numberConfig,
            precioai: element.price,
            codean: null,
            tipoLinea: promoConfiguration.isKitIngreso === 1 ? "K" : kitOpc,
            idpromo: promoConfiguration.idPromo,
            comisionable: promoConfiguration.comisionable
              ? promoConfiguration.comisionable
              : 0,
            status: "A",
            isHeader: true,
          };

          arrayContentElementsSend.push(promoModel);

          const parameters = {
            idPedidoDet: 1,
            idpromo: 1,
            itemCode: promoConfiguration.codigo,
            opc: 16,
            pedidosD: arrayContentElementsSend,
            quantity: element.numberConfig,
            type: kitOpc,
            withAssign: 0,
          };
          dispatch(requestCarCurrentOrder(parameters));

          setCountSendSetsToServer(countSendSetsToServer + 1);
        });
        //hacer back a la pagina anterior
        //history.goBack();
        dispatch(
          requestLoggUserActivity(
            79,
            0,
            0,
            "",
            "Se Envia al Servidor Las configuraciones del Set."
          )
        );
      }
    }
  };
  // se agrega el array del resumen de los sets ya configurados
  const handlePushToArrayFinalSetsConfig = () => {
    if (typeLine !== "3x2") {
      //debugger;
      let cloneConfiguredSets = [...configuredSets];

      if (cloneConfiguredSets.length > 0) {
        //buscar el elemnto que no esta terminado
        let cloneConfigure = cloneConfiguredSets.find(
          (set) => set.itemCode === itemCode && set.isSaved === false
        );
        if (cloneConfigure && finalArray.length > 0) {
          cloneConfigure.contents = finalArray.filter(
            (itemSet) => itemSet.tipoLinea === "C" || itemSet.tipoLinea === "R"
          );
          cloneConfigure.isSaved = true;
          cloneConfigure.numberConfig = 1;

          //fragancias personalizadas
          let cloneConfigurableArray = [...configurableArray];

          const personalizedFragranceFilter = cloneConfigurableArray
            .filter((x) => x.type === "personalizedFragrance" && x.item.isSaved)
            .map((elemnt, index) => ({
              id: index + 1,
              label: elemnt.item.label,
              itemCode: elemnt.item.itemCode,
              template: elemnt.item.template,
            }));

          cloneConfigure.personalizedFragrances = personalizedFragranceFilter;
          //fragancias personalizadas

          if (cloneConfigure.personalizedConfig.length <= 0) {
            cloneConfigure.personalizedConfig = personalizedFragranceFilter;
          }

          //setIsSwiperLocked(true);
          const configurablesSwiper = document.getElementById(
            "configurablesSwiper"
          );
          configurablesSwiper.swiper.slideTo(0);

          // //resetear los estados
          setFinalArray([]);

          enqueueSnackbar(t("__message_configurate_set_label"), {
            variant: "success",
          });

          handleResetconfigurationStates();
        }
        setConfiguredSets(cloneConfiguredSets);
      }
    } else {
      //debugger;
      let cloneConfiguredSets = [...configuredSets];

      if (cloneConfiguredSets.length > 0) {
        //buscar el elemnto que no esta terminado
        let cloneConfigure = cloneConfiguredSets.find(
          (set) => set.itemCode === itemCode && set.isSaved === false
        );

        if (cloneConfigure && finalArray.length > 0) {
          const lang = i18n?.language === "es" ? 1 : 2;

          let productList = finalArray.filter(
            (itemSet) => itemSet.tipoLinea === "C"
          );
          console.log("filtered", productList);
          debugger;
          productList.sort((a, b) => b.precioai - a.precioai);

          console.log("ordered", productList);
          debugger;

          const productListOrderPrice = [...productList];
          //tamano del arreglo
          const productListCount = productListOrderPrice.length;

          const headersByLang = kitHeaders?.filter((h) => h.idLang === lang);
          const detailsByLang = kitDetails?.filter((h) => h.idLang === lang);
          const productsFilter = [];

          headersByLang.forEach((element) => {
            if (
              productList.find(
                (pl) => pl.codart === element.itemCode && pl.isHeader
              )
            ) {
              let ProdListT = productList.filter(
                (pl) => pl.codart === element.itemCode && pl.isHeader
              );
              /*+++++ Es una prueba de 3x2 +++++*/
              ProdListT.forEach((prodObjH) => {
                if (prodObjH.qtyped === promoConfiguration.cant_HVariable) {
                  // es el mismo item seleccionado 3 veses
                  //mostramos 2 con el precio unitario
                  let itemEl = Object.assign({}, element);
                  itemEl.qtyped = prodObjH.qtyped - 1;
                  itemEl.qtyped2 = prodObjH.qtyped2 - 1;
                  itemEl.itemPrice = prodObjH.precioai;
                  productsFilter.push(itemEl);

                  //mostramos 1 gratis
                  let itemEl2 = Object.assign({}, element);
                  itemEl2.qtyped = 1;
                  itemEl2.qtyped2 = 1;
                  itemEl2.itemPrice = 0;
                  productsFilter.push(itemEl2);
                } else {
                  //ordernar del mas caro al menos caro
                  let idx = productListOrderPrice.indexOf(prodObjH);
                  if (idx === 0) {
                    //se agrega normal
                    let itemEl = Object.assign({}, element);
                    itemEl.qtyped = prodObjH.qtyped;
                    itemEl.qtyped2 = prodObjH.qtyped2;
                    itemEl.itemPrice = prodObjH.precioai;
                    productsFilter.push(itemEl);
                  } else if (idx === 1) {
                    if (prodObjH.qtyped === 1) {
                      if (productListCount > 2) {
                        //se agrega normal
                        let itemEl = Object.assign({}, element);
                        itemEl.qtyped = prodObjH.qtyped;
                        itemEl.qtyped2 = prodObjH.qtyped2;
                        itemEl.itemPrice = prodObjH.precioai;
                        productsFilter.push(itemEl);
                      } else {
                        //se agrega gratis
                        let itemEl2 = Object.assign({}, element);
                        itemEl2.qtyped = 1;
                        itemEl2.qtyped2 = 1;
                        itemEl2.itemPrice = 0;
                        productsFilter.push(itemEl2);
                      }
                    } else if (prodObjH.qtyped === 2) {
                      //se tiene que sacar uno con precio 0
                      let itemEl = Object.assign({}, element);
                      itemEl.qtyped = prodObjH.qtyped - 1;
                      itemEl.qtyped2 = prodObjH.qtyped2 - 1;
                      itemEl.itemPrice = prodObjH.precioai;
                      productsFilter.push(itemEl);

                      //mostramos 1 gratis
                      let itemEl2 = Object.assign({}, element);
                      itemEl2.qtyped = 1;
                      itemEl2.qtyped2 = 1;
                      itemEl2.itemPrice = 0;
                      productsFilter.push(itemEl2);
                    }
                  } else if (idx === 2) {
                    //se agrega con precio 0
                    let itemEl = Object.assign({}, element);
                    itemEl.qtyped = 1;
                    itemEl.qtyped2 = 1;
                    itemEl.itemPrice = 0;
                    productsFilter.push(itemEl);
                  }
                }
              });
            }
          });
          detailsByLang.forEach((element) => {
            if (
              productList.find(
                (pl) => pl.codart === element.itemCode && !pl.isHeader
              )
            ) {
              let prodObjD = productList.find(
                (pl) => pl.codart === element.itemCode && !pl.isHeader
              );
              element.qtyped = prodObjD.qtyped;
              element.qtyped2 = prodObjD.qtyped2;
              productsFilter.push(element);
            }
          });
          if (productsFilter.length > 0) {
            //setProducts(productsFilter.sort((a, b) => b.itemPrice - a.itemPrice));

            let newArrayTest = productsFilter.map((item) => ({
              idPedidoDet: 0,
              idPedido: 0,
              codart: item.itemCode,
              descripcion: item.description,
              qtyped: item.qtyped,
              qtyped2: item.qtyped2,
              precioai: item.itemPrice,
              codean: item.idPromo.toString(),
              tipoLinea: item.itemPrice === 0 ? "R" : "C",
              idpromo: parseInt(item.idPromo),
              comisionable: item.comisionable,
              status: "A",
              isHeader: true,
              isConfig: false,
              pageOrigin: 0,
            }));

            cloneConfigure.contents = newArrayTest.sort(
              (a, b) => b.itemPrice - a.itemPrice
            );
            cloneConfigure.isSaved = true;
            cloneConfigure.numberConfig = 1;
            cloneConfigure.price = newArrayTest.reduce(
              (accumulator, object) => {
                return accumulator + object.precioai * object.qtyped2;
              },
              0
            );

            const configurablesSwiper = document.getElementById(
              "configurablesSwiper"
            );
            configurablesSwiper.swiper.slideTo(0);

            // //resetear los estados
            setFinalArray([]);

            enqueueSnackbar(t("__kit_configuration_success_message"), {
              variant: "success",
            });

            handleResetconfigurationStates();
          }
          setConfiguredSets(cloneConfiguredSets);
        }
      }
    }
    //se guardo la configuracion del Set
    dispatch(
      requestLoggUserActivity(
        79,
        0,
        0,
        "",
        "Se Guardardo la Configuracion del Set."
      )
    );
  };
  //agregar otro elemento de un mismo set \\
  const handleAddSameSet = (value, idx) => {
    //debugger;
    let cloneConfiguredSets = [...configuredSets];
    let set = cloneConfiguredSets[idx];
    set.numberConfig = set.numberConfig + value;

    set.contents.map((item) => (item.qtyped = item.qtyped2 * set.numberConfig));

    if (set.personalizedFragrances.length > 0) {
      const clonePF = [...set.personalizedFragrances];
      const clonePC = [...set.personalizedConfig];

      clonePC.forEach((item) => {
        //item.id = clonePF.length + 1
        clonePF.push({
          ...item,
          id: clonePF.length + 1,
        });
      });

      set.personalizedFragrances = clonePF;
    }

    setConfiguredSets(cloneConfiguredSets);

    enqueueSnackbar(t("__kit_configuration_add_set_message"), {
      variant: "success",
    });

    //se guardo la configuracion del Set
    dispatch(
      requestLoggUserActivity(
        79,
        0,
        0,
        set.itemCode,
        "Se incremento en 1 Configuracion del mismo Set (Click en '+'). Position Array: " +
          idx
      )
    );
  };
  //agregar otro elemento de un mismo set \\
  const handleDeleteSameSet = (value, idx) => {
    //debugger;
    let cloneConfiguredSets = [...configuredSets];
    let set = cloneConfiguredSets[idx];
    set.numberConfig = set.numberConfig - value;

    set.contents.map((item) => (item.qtyped = item.qtyped2 * set.numberConfig));

    if (set.numberConfig <= 0) {
      //eliminamos la configuracion
      cloneConfiguredSets.splice(idx, 1);

      //validar si ya no hay sets configurados y guardados
      if (!cloneConfiguredSets.find((x) => x.isSaved)) {
        setFinalArray([]);
        setConfiguredSets(cloneConfiguredSets);
        handleResetconfigurationStates();
      } else {
        setFinalArray([]);
        setConfiguredSets(cloneConfiguredSets);
        handleResetconfigurationStates();
      }

      const configurablesSwiper = document.getElementById(
        "configurablesSwiper"
      );
      configurablesSwiper.swiper.slideTo(0);

      enqueueSnackbar(t("__kit_configuration_remove_set_success_message"), {
        variant: "success",
      });
      dispatch(
        requestLoggUserActivity(
          79,
          0,
          0,
          set.itemCode,
          "Se elimino la Configuracion del Set (Click en '-'). Position Array: " +
            idx
        )
      );
    } else {
      let clonePF = [...set.personalizedFragrances];

      clonePF = clonePF.slice(
        0,
        clonePF.length - set.personalizedConfig.length
      );

      set.personalizedFragrances = clonePF;
      setConfiguredSets(cloneConfiguredSets);

      enqueueSnackbar(t("__kit_configuration_remove_one_set_success_message"), {
        variant: "success",
      });

      //se guardo la configuracion del Set
      dispatch(
        requestLoggUserActivity(
          79,
          0,
          0,
          set.itemCode,
          "Se decremento en 1 Configuracion del mismo Set (Click en '-'). Position Array: " +
            idx
        )
      );
    }
  };
  //remove elemento de los arrays configurables
  const handleRemoveSet = (idx) => {
    let cloneConfiguredSets = [...configuredSets];
    cloneConfiguredSets.splice(idx, 1);
    //validar si ya no hay sets configurados y guardados
    if (!cloneConfiguredSets.find((x) => x.isSaved)) {
      //debugger;

      //setIsSwiperLocked(false);
      setFinalArray([]);
      setConfiguredSets(cloneConfiguredSets);
      handleResetconfigurationStates();
    } else {
      //setIsSwiperLocked(true);
      setFinalArray([]);
      setConfiguredSets(cloneConfiguredSets);
      handleResetconfigurationStates();
    }
    enqueueSnackbar(t("__kit_configuration_remove_set_success_message"), {
      variant: "success",
    });
    dispatch(
      requestLoggUserActivity(
        79,
        0,
        0,
        "",
        "Se elimino la Configuracion del Set (Click en 'trash'). Position Array: " +
          idx
      )
    );
  };
  //Resetear los estados
  const handleResetconfigurationStates = () => {
    //debugger;
    //setIsSwiperLocked(false);
    setConfigurableArray([]);
    setTotalHeader(0);
    setTotalDetail(0);
    //setFinalArray([]);

    const idLang = i18n.language === "es" ? 1 : 2;

    let configurationArrayFinal = [];
    let configurationFPArrayFinal = [];

    //No configurable se van al RESUMEN (HEADERS)
    if (kitHeaders.length > 0 && promoConfiguration.hVariable == 0) {
      const cloneHeaderArray = [];
      cloneHeaderArray.push.apply(
        cloneHeaderArray,
        kitHeaders
          .filter((i) => i.idLang === idLang)
          .map((item) => ({
            idPedidoDet: 0,
            idPedido: 0,
            codart: item.itemCode,
            descripcion: item.description,
            qtyped: item.qtyAddSetFijo,
            qtyped2: item.qtyAddSetFijo,
            precioai: item.itemPrice,
            codean: item.idPromo.toString(),
            tipoLinea: item.isGiftSet === true ? "R" : "C",
            idpromo: parseInt(item.idPromo),
            comisionable: item.comisionable,
            status: "A",
            isHeader: true,
            isConfig: false,
            pageOrigin: 0,
          }))
      );
      setFinalArray(cloneHeaderArray);

      ////FRAGANCIAS PERSONALIDAS\\\\
      const cloneHeaderFPArray = [];
      cloneHeaderFPArray.push.apply(
        cloneHeaderFPArray,
        kitHeaders
          .filter((i) => i.idLang === idLang && i.isCustomLabel === true)
          .map((item) => ({
            id: 0,
            image: `https://www.websizusa.net/imgprod/fullsize/${item.itemCode}_.jpg`,
            isSaved: false,
            label: "",
            template: "",
            itemCode: item.itemCode,
          }))
      );
      if (cloneHeaderFPArray.length > 0) {
        cloneHeaderFPArray.map((item) => {
          let headersConfigurableArray = {
            order: 2,
            type: "personalizedFragrance",
            item: item,
          };
          configurationFPArrayFinal.push(headersConfigurableArray);
        });
      }
      ////FRAGANCIAS PERSONALIDAS\\\\

      // //obtener el render arreglo del render en NM (Header)
      // setFinalArrayRenderNM(kitHeaders);
    }

    //Configurable se van al listado de seleccion (HEADERS)
    if (kitHeaders.length > 0 && promoConfiguration.hVariable == 1) {
      let headersConfigurableArray = {
        order: 1,
        type: "list",
        selectables: promoConfiguration.cant_HVariable,
        items: [],
      };
      const cloneHeaderConfigurableArray = [];
      cloneHeaderConfigurableArray.push.apply(
        cloneHeaderConfigurableArray,
        kitHeaders
          .filter((i) => i.idLang === idLang && i.isGiftSet === false)
          .map((item) => ({
            idPedidoDet: 0,
            idPedido: 0,
            codart: item.itemCode,
            descripcion: item.description,
            qtyped: item.qtyAddSetFijo,
            qtyped2: item.qtyAddSetFijo,
            precioai: item.itemPrice,
            codean: item.idPromo.toString(),
            tipoLinea: "C",
            idPromo: parseInt(item.idPromo),
            comisionable: item.comisionable,
            status: "A",
            isHeader: true,
            isConfig: true,
            pageOrigin: 0,
          }))
      );
      ////aun no se si este se va autilizar
      //setConfigurableArray(cloneHeaderConfigurableArray);

      headersConfigurableArray.items = cloneHeaderConfigurableArray;

      configurationArrayFinal.push(headersConfigurableArray);

      //meter los regalos en el array del resumen
      const cloneHeaderGiftsArray = [];
      cloneHeaderGiftsArray.push.apply(
        cloneHeaderGiftsArray,
        kitHeaders
          .filter((i) => i.idLang === idLang && i.isGiftSet === true)
          .map((item) => ({
            idPedidoDet: 0,
            idPedido: 0,
            codart: item.itemCode,
            descripcion: item.description,
            qtyped: item.qtyAddSetFijo,
            qtyped2: item.qtyAddSetFijo,
            precioai: item.itemPrice,
            codean: item.idPromo.toString(),
            tipoLinea: "R",
            idpromo: parseInt(item.idPromo),
            comisionable: item.comisionable,
            status: "A",
            isHeader: true,
            isConfig: false,
            pageOrigin: 0,
          }))
      );
      setFinalArray(cloneHeaderGiftsArray);

      // //obtener el render arreglo del render en NM (Header)
      // setFinalArrayRenderNM(kitHeaders);
    }

    //No configurable se van al RESUMEN (DETAILS)
    if (kitDetails.length > 0 && promoConfiguration.dVariable == 0) {
      const cloneDetailArray = [];
      cloneDetailArray.push.apply(
        cloneDetailArray,
        kitDetails
          .filter((i) => i.idLang === idLang)
          .map((item) => ({
            idPedidoDet: 0,
            idPedido: 0,
            codart: item.itemCode,
            descripcion: item.description,
            qtyped: item.qtyAddSetFijo,
            qtyped2: item.qtyAddSetFijo,
            precioai: item.itemPrice,
            codean: item.idPromo.toString(),
            tipoLinea: "C",
            idpromo: parseInt(item.idPromo),
            comisionable: item.comisionable,
            status: "A",
            isHeader: false,
            isConfig: false.valueOf,
            pageOrigin: 1,
          }))
      );
      setFinalArray(cloneDetailArray);

      ////FRAGANCIAS PERSONALIDAS\\\\
      const cloneDetailFPArray = [];
      cloneDetailFPArray.push.apply(
        cloneDetailFPArray,
        kitDetails
          .filter((i) => i.idLang === idLang && i.isCustomLabel === true)
          .map((item) => ({
            id: 0,
            image: `https://www.websizusa.net/imgprod/fullsize/${item.itemCode}_.jpg`,
            isSaved: false,
            label: "",
            template: "",
            itemCode: item.itemCode,
          }))
      );

      if (cloneDetailFPArray.length > 0) {
        cloneDetailFPArray.map((item) => {
          let headersConfigurableArray = {
            order: 2,
            type: "personalizedFragrance",
            item: item,
          };

          configurationFPArrayFinal.push(headersConfigurableArray);
        });
      }
      // //obtener el render arreglo del render en NM (Detail)
      // setFinalArrayRenderNM(kitDetails);
    }

    //Configurable se van al listado de seleccion (DETAILS)
    if (kitDetails.length > 0 && promoConfiguration.dVariable == 1) {
      let detailsConfigurableArray = {
        order: 1,
        type: "list",
        selectables: promoConfiguration.cant_DVariable,
        items: [],
      };

      const cloneDetailConfigurableArray = [];
      cloneDetailConfigurableArray.push.apply(
        cloneDetailConfigurableArray,
        kitDetails
          .filter((i) => i.idLang === idLang)
          .map((item) => ({
            idPedidoDet: 0,
            idPedido: 0,
            codart: item.itemCode,
            descripcion: item.description,
            qtyped: item.qtyAddSetFijo,
            qtyped2: item.qtyAddSetFijo,
            precioai: item.itemPrice,
            codean: item.idPromo.toString(),
            tipoLinea: "C",
            idPromo: parseInt(item.idPromo),
            comisionable: item.comisionable,
            status: "A",
            isHeader: false,
            isConfig: true,
            pageOrigin: 1,
          }))
      );
      ////aun no se si este se va autilizar
      // setFinalArray(cloneDetailArray);

      detailsConfigurableArray.items = cloneDetailConfigurableArray;

      configurationArrayFinal.push(detailsConfigurableArray);

      // //obtener el render arreglo del render en NM (Detail)
      // setFinalArrayRenderNM(kitDetails);
    }

    //// INDICE A LAS FRAGANCIAS PERSONALIZADAS \\\\
    if (configurationFPArrayFinal.length > 0) {
      configurationFPArrayFinal.map((elemnt, idx) => {
        ////indice\\\\
        elemnt.item.id = idx;
        ////se agrega al arrlego final de configuracion\\\\
        configurationArrayFinal.push(elemnt);
      });
    }
    //// INDICE A LAS FRAGANCIAS PERSONALIZADAS \\\\
    setConfigurableArray(configurationArrayFinal);
  };

  ///// switchs
  ////// Header Steps
  function getStepHeaders(steps) {
    switch (steps) {
      case "VV":
        return (
          <div className="kit__configuration__container__headers__newKits">
            <div className="kit__configuration__container__headers__newKits__item">
              {isChooseActive ? (
                <div className="kit__configuration__container__headers__item__icons">
                  {i18n.language === "en" ? (
                    <div className="new__choose__kit__eng__icon" />
                  ) : (
                    <div className="new__choose__kit__spa__icon" />
                  )}
                </div>
              ) : (
                <div className="kit__configuration__container__headers__item__icons">
                  {i18n.language === "en" ? (
                    <div className="new__choose__kit__eng__disabled__icon" />
                  ) : (
                    <div className="new__choose__kit__spa__disabled__icon" />
                  )}
                </div>
              )}
            </div>

            <div className="kit__configuration__container__headers__newKits__item">
              {isDetailActive ? (
                <div className="kit__configuration__container__headers__item__icons">
                  {i18n.language === "en" ? (
                    <div className="new__detail__kit__eng__icon" />
                  ) : (
                    <div className="new__detail__kit__spa__icon " />
                  )}
                </div>
              ) : (
                <div className="kit__configuration__container__headers__item__icons">
                  {i18n.language === "en" ? (
                    <div className="new__detail__kit__eng__disabled__icon" />
                  ) : (
                    <div className="new__detail__kit__spa__disabled__icon" />
                  )}
                </div>
              )}
            </div>
          </div>
        );

      case "3x2":
        return (
          <div className="kit__configuration__container__headers__newKits">
            <div className="kit__configuration__container__headers__newKits__item">
              {isChooseActive ? (
                <div className="kit__configuration__container__headers__item__icons">
                  {i18n.language === "en" ? (
                    <div className="new__choose__kit__eng__icon" />
                  ) : (
                    <div className="new__choose__kit__spa__icon" />
                  )}
                </div>
              ) : (
                <div className="kit__configuration__container__headers__item__icons">
                  {i18n.language === "en" ? (
                    <div className="new__choose__kit__eng__disabled__icon" />
                  ) : (
                    <div className="new__choose__kit__spa__disabled__icon" />
                  )}
                </div>
              )}
            </div>
            <div className="kit__configuration__container__headers__newKits__item">
              {isDetailActive ? (
                <div className="kit__configuration__container__headers__item__icons">
                  {i18n.language === "en" ? (
                    <div className="new__detail__kit__eng__icon" />
                  ) : (
                    <div className="new__detail__kit__spa__icon " />
                  )}
                </div>
              ) : (
                <div className="kit__configuration__container__headers__item__icons">
                  {i18n.language === "en" ? (
                    <div className="new__detail__kit__eng__disabled__icon" />
                  ) : (
                    <div className="new__detail__kit__spa__disabled__icon" />
                  )}
                </div>
              )}
            </div>
          </div>
        );

      case "VF":
        return (
          <div className="kit__configuration__container__headers__newKits">
            <div className="kit__configuration__container__headers__newKits__item">
              {isChooseActive ? (
                <div className="kit__configuration__container__headers__item__icons">
                  {i18n.language === "en" ? (
                    <div className="new__choose__kit__eng__icon" />
                  ) : (
                    <div className="new__choose__kit__spa__icon" />
                  )}
                </div>
              ) : (
                <div className="kit__configuration__container__headers__item__icons">
                  {i18n.language === "en" ? (
                    <div className="new__choose__kit__eng__disabled__icon" />
                  ) : (
                    <div className="new__choose__kit__spa__disabled__icon" />
                  )}
                </div>
              )}
            </div>
            <div className="kit__configuration__container__headers__newKits__item">
              {isDetailActive ? (
                <div className="kit__configuration__container__headers__item__icons">
                  {i18n.language === "en" ? (
                    <div className="new__detail__kit__eng__icon" />
                  ) : (
                    <div className="new__detail__kit__spa__icon " />
                  )}
                </div>
              ) : (
                <div className="kit__configuration__container__headers__item__icons">
                  {i18n.language === "en" ? (
                    <div className="new__detail__kit__eng__disabled__icon" />
                  ) : (
                    <div className="new__detail__kit__spa__disabled__icon" />
                  )}
                </div>
              )}
            </div>
          </div>
        );

      case "FV":
        return (
          <div className="kit__configuration__container__headers__newKits">
            <div className="kit__configuration__container__headers__newKits__item">
              {isChooseActive ? (
                <div className="kit__configuration__container__headers__item__icons">
                  {i18n.language === "en" ? (
                    <div className="new__choose__kit__eng__icon" />
                  ) : (
                    <div className="new__choose__kit__spa__icon" />
                  )}
                </div>
              ) : (
                <div className="kit__configuration__container__headers__item__icons">
                  {i18n.language === "en" ? (
                    <div className="new__choose__kit__eng__disabled__icon" />
                  ) : (
                    <div className="new__choose__kit__spa__disabled__icon" />
                  )}
                </div>
              )}
            </div>
            <div className="kit__configuration__container__headers__newKits__item">
              {isDetailActive ? (
                <div className="kit__configuration__container__headers__item__icons">
                  {i18n.language === "en" ? (
                    <div className="new__detail__kit__eng__icon" />
                  ) : (
                    <div className="new__detail__kit__spa__icon " />
                  )}
                </div>
              ) : (
                <div className="kit__configuration__container__headers__item__icons">
                  {i18n.language === "en" ? (
                    <div className="new__detail__kit__eng__disabled__icon" />
                  ) : (
                    <div className="new__detail__kit__spa__disabled__icon" />
                  )}
                </div>
              )}
            </div>
          </div>
        );
      default:
        return (
          <div className="kit__configuration__container__headers__newKits">
            <div className="kit__configuration__container__headers__newKits__item">
              {isGiftActive ? (
                <div className="kit__configuration__container__headers__item__icons">
                  {i18n.language === "en" ? (
                    <div className="new__choose__kit__eng__icon" />
                  ) : (
                    <div className="new__choose__kit__spa__icon" />
                  )}
                </div>
              ) : (
                <div className="kit__configuration__container__headers__item__icons">
                  {i18n.language === "en" ? (
                    <div className="new__choose__kit__eng__disabled__icon" />
                  ) : (
                    <div className="new__choose__kit__spa__disabled__icon" />
                  )}
                </div>
              )}
            </div>
            <div className="kit__configuration__container__headers__newKits__item">
              {isDetailActive ? (
                <div className="kit__configuration__container__headers__item__icons">
                  {i18n.language === "en" ? (
                    <div className="new__detail__kit__eng__icon" />
                  ) : (
                    <div className="new__detail__kit__spa__icon " />
                  )}
                </div>
              ) : (
                <div className="kit__configuration__container__headers__item__icons">
                  {i18n.language === "en" ? (
                    <div className="new__detail__kit__eng__disabled__icon" />
                  ) : (
                    <div className="new__detail__kit__spa__disabled__icon" />
                  )}
                </div>
              )}
            </div>
          </div>
        );
    }
  }

  return (
    <div
      id="firstWrapperInBaseContainer"
      className="firstWrapperInBaseContainer kit__configuration"
    >
      <ModalWarning
        isOpenModalWarning={isOpenModalWarning}
        handleOpenWarning={handleOpenWarning}
        isActiveStyleInSubtitle={false}
        iconMain="box_icon"
        title={t("__kit_configuration_instructions_title_Txt")}
        subtitle=""
        listActive={true}
        listItems={[
          t("__kit_configuration_instructions1_Txt"),
          t("__kit_configuration_instructions2_Txt"),
          t("__kit_configuration_instructions3_Txt"),
          t("__kit_configuration_instructions4_Txt"),
          t("__kit_configuration_instructions5_Txt"),
          t("__kit_configuration_instructions6_Txt"),
        ]}
        text=""
        isButtonActive={false}
        isButtonText={t("btn_cart_set_up")}
        navigationURL={handleOpenWarning}
      />

      <div
        className={`kit__configuration__container ${
          isMobile || isTablet ? "mobile" : ""
        }`}
      >
        {/* VERSION NUEVA */}
        <div
          className="kit__configuration__container__inner__wrapper"
          style={{ height: kitsInnerWrapperHeight }}
        >
          <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper">
            <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__title__wrapper">
              {isLanguageLoaded ? (
                <span>{t("configurables__set__configuration")}</span>
              ) : (
                <Skeleton variant="rect" width={"20%"} height={30} />
              )}

              {/* {isSwiperLocked && (
                <Button
                  className="button__with__icon bg__gray small"
                  onClick={() => {
                    setIsSwiperLocked(false);

                    // setConfiguredSets([
                    //   ...configuredSets,
                    //   {
                    //     isSaved: false,
                    //     name: "COLECCIÓN PLAYER INTENSE",
                    //     image:
                    //       "https://www.websizusa.net/imgprod/fullsize/83260.jpg",
                    //     price: 14,
                    //     contents: [],
                    //     personalizedFragrances: [],
                    //   },
                    // ]);
                  }}
                >
                  <div className="button__with__icon__svg__wrapper dark__pink__ball small__icon">
                    <FiltersClearIcon />
                  </div>
                  <span className="button__with__icon__text">
                    {t("configurables__configure__another__set")}
                  </span>
                </Button>
                )
                } */}
            </div>
            <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper">
              <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__arrow__prev">
                <GoBackArrow />
              </div>
              <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__arrow__next">
                <NextArrowOrangeIcon />
              </div>

              <Swiper
                id="configurablesSwiper"
                slidesPerView={1}
                updateOnWindowResize
                spaceBetween={30}
                //grabCursor={isDesktop ? true : false}
                shortSwipes={false}
                navigation={{
                  prevEl:
                    ".kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__arrow__prev",
                  nextEl:
                    ".kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__arrow__next",
                }}
                onBeforeSlideChangeStart={(swiper) => {
                  //console.log("onBeforeSlideChangeStart", swiper);
                  const prevArrow = document.querySelector(
                    ".kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__arrow__prev"
                  );
                  const nextArrow = document.querySelector(
                    ".kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__arrow__next"
                  );
                  if (swiper.activeIndex === 0) {
                    prevArrow?.classList.remove("active");
                    nextArrow?.classList.add("active");
                  } else if (swiper.activeIndex === swiper.slides.length - 1) {
                    prevArrow?.classList.add("active");
                    nextArrow?.classList.remove("active");
                  } else {
                    prevArrow.classList.add("active");
                    nextArrow.classList.add("active");
                  }
                }}
                onSlideChange={(swiper) => {
                  //console.log("onSlideChange", swiper);
                  const prevArrow = document.querySelector(
                    ".kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__arrow__prev"
                  );
                  const nextArrow = document.querySelector(
                    ".kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__arrow__next"
                  );
                  if (swiper.activeIndex === 0) {
                    prevArrow?.classList.remove("active");
                    nextArrow?.classList.add("active");
                  } else if (swiper.activeIndex === swiper.slides.length - 1) {
                    prevArrow?.classList.add("active");
                    nextArrow?.classList.remove("active");
                  } else {
                    prevArrow.classList.add("active");
                    nextArrow.classList.add("active");
                  }
                }}
                modules={[Pagination, Navigation, EffectCoverflow]}
              >
                {configurableArray.map((configurable, index) => {
                  if (configurable.type === "personalizedFragrance") {
                    return (
                      <SwiperSlide
                        key={index}
                        className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__slide__content"
                      >
                        {isSwiperLocked && (
                          <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__slide__content__blocked__wrapper"></div>
                        )}
                        <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__slide__content__tab__wrapper">
                          {isLanguageLoaded ? (
                            <span>{`${t("__general__step")} ${
                              index + 1
                            }`}</span>
                          ) : (
                            <Skeleton
                              variant="rect"
                              width={"30%"}
                              height={30}
                            />
                          )}
                        </div>

                        <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__slide__content__title">
                          {isLanguageLoaded ? (
                            <span>
                              {t(
                                "configurables__personalized__fragrance__title"
                              )}
                            </span>
                          ) : (
                            <Skeleton
                              variant="rect"
                              width={"20%"}
                              height={30}
                            />
                          )}
                        </div>
                        <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__slide__content__body">
                          <CardPersonalizedFraganceSet
                            item={configurable.item}
                            listFragancesCustomer={[{ ...configurable.item }]}
                            setConfigurableArray={setConfigurableArray}
                            configurableArray={configurableArray}
                            indexElement={index}
                            dispatch={dispatch}
                            requestLoggUserActivity={requestLoggUserActivity}
                          />
                        </div>
                      </SwiperSlide>
                    );
                  } else if (configurable.type === "list") {
                    return (
                      <SwiperSlide
                        key={index}
                        className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__slide__content"
                      >
                        <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__slide__content__tab__wrapper">
                          {isLanguageLoaded ? (
                            <span>{`${t("__general__step")} ${
                              index + 1
                            }`}</span>
                          ) : (
                            <Skeleton
                              variant="rect"
                              width={"20%"}
                              height={30}
                            />
                          )}
                        </div>
                        <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__slide__content__title full__width">
                          {isLanguageLoaded ? (
                            <span>
                              {`${t("_Zusa_Configurables_Stepper_Select")} ${
                                configurable.selectables
                              } ${t("__general__product")}${
                                configurable.selectables.length > 1 ? "s" : ""
                              } ${t(
                                "configurables__from__the__folowing__list"
                              )}:`}
                            </span>
                          ) : (
                            <Skeleton
                              variant="rect"
                              width={"20%"}
                              height={30}
                            />
                          )}
                        </div>
                        <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__slide__content__body">
                          <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__slide__content__body__list__wrapper">
                            {configurable.items.map((product) => {
                              return (
                                <div
                                  key={product.id}
                                  className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__slide__content__body__list__wrapper__list__item"
                                >
                                  <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__slide__content__body__list__wrapper__list__item__image__wrapper">
                                    <img
                                      src={`https://www.websizusa.net/imgprod/fullsize/${product.codart}.jpg`}
                                      alt=""
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src =
                                          "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
                                      }}
                                    />
                                  </div>
                                  <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__slide__content__body__list__wrapper__list__item__text__wrapper">
                                    <span className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__slide__content__body__list__wrapper__list__item__text__wrapper__product__title">
                                      {capitalizeText(product.descripcion)}
                                    </span>
                                    {/* <span className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__slide__content__body__list__wrapper__list__item__text__wrapper__product__price">
                                      {`$${product.precioai.toFixed(2)}`}
                                    </span> */}
                                  </div>
                                  <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__slide__content__body__list__wrapper__list__item__counter__wrapper">
                                    <Counter
                                      className="setKit"
                                      withIcon={false}
                                      qtyOnOrder={
                                        product.isHeader
                                          ? handleGetCountSettedHeaders(
                                              product.codart
                                            )
                                          : handleGetCountSettedDetails(
                                              product.codart
                                            )
                                      }
                                      handleQtyMinus={() =>
                                        product.isHeader
                                          ? handleModifyKitHeader(product, -1)
                                          : handleModifyKitDetails(product, -1)
                                      }
                                      disableMinus={
                                        product.isHeader
                                          ? handleGetCountSettedHeaders(
                                              product.codart
                                            ) < 1
                                          : handleGetCountSettedDetails(
                                              product.codart
                                            ) < 1
                                      }
                                      handleQtyAdd={() =>
                                        product.isHeader
                                          ? handleModifyKitHeader(product, 1)
                                          : handleModifyKitDetails(product, 1)
                                      }
                                      disableAdd={
                                        (product.isHeader
                                          ? totalHeader >=
                                            promoConfiguration.cant_HVariable
                                          : totalDetail >=
                                            promoConfiguration.cant_DVariable) ||
                                        (isKitIngreso
                                          ? configuredSets.filter(
                                              (x) => x.isSaved
                                            ).length === 1
                                          : false) ||
                                        (infoCartOrder &&
                                          infoCartOrder.lines &&
                                          configuredSets &&
                                          promoConfiguration &&
                                          promoConfiguration.cantMaxSets > 0 &&
                                          infoCartOrder.lines.reduce(
                                            (accumulator, object) =>
                                              object.line.codart ===
                                              promoConfiguration.codigo
                                                ? accumulator +
                                                  object.line.qtyped
                                                : accumulator,
                                            0
                                          ) +
                                            configuredSets.reduce(
                                              (accumulator, object) =>
                                                object.itemCode ===
                                                promoConfiguration.codigo
                                                  ? accumulator +
                                                    object.numberConfig
                                                  : accumulator,
                                              0
                                            ) +
                                            1 >
                                            promoConfiguration.cantMaxSets)
                                      }
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  }
                })}

                {/* SUMMARY SLIDE */}
                <SwiperSlide className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__slide__content">
                  <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__slide__content__tab__wrapper">
                    {isLanguageLoaded ? (
                      <span>{`${t("__general__step")} ${
                        configurableArray.length + 1
                      }`}</span>
                    ) : (
                      <Skeleton variant="rect" width={"30%"} height={30} />
                    )}
                  </div>
                  <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__slide__content__title full__width">
                    {isLanguageLoaded ? (
                      <span>{t("configurables__summary__title")}</span>
                    ) : (
                      <Skeleton variant="rect" width={"20%"} height={30} />
                    )}
                  </div>

                  {/* AQUI PEGAR */}
                  <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__slide__content__button__wrapper">
                    <Button
                      className="button__with__icon bg__green__success no__left__margin medium"
                      disabled={
                        //configurableArray VALIDAR PARA BLOQUEAR
                        (typeLine === "VV"
                          ? promoConfiguration.cant_HVariable - totalHeader >
                              0 ||
                            promoConfiguration.cant_DVariable - totalDetail > 0
                          : typeLine === "VF" || typeLine === "3x2"
                          ? promoConfiguration.cant_HVariable - totalHeader > 0
                          : typeLine === "FV"
                          ? promoConfiguration.cant_DVariable - totalDetail > 0
                          : true) ||
                        (configurableArray.filter(
                          (f) => f.type === "personalizedFragrance"
                        ).length > 0 &&
                          configurableArray.filter(
                            (f) =>
                              f.type === "personalizedFragrance" &&
                              !f.item.isSaved
                          ).length > 0) ||
                        // Bloquear cantidad maxima de Sets
                        (infoCartOrder &&
                          infoCartOrder.lines &&
                          configuredSets &&
                          promoConfiguration &&
                          promoConfiguration.cantMaxSets > 0 &&
                          infoCartOrder.lines.reduce(
                            (accumulator, object) =>
                              object.line.codart === promoConfiguration.codigo
                                ? accumulator + object.line.qtyped
                                : accumulator,
                            0
                          ) +
                            configuredSets.reduce(
                              (accumulator, object) =>
                                object.itemCode === promoConfiguration.codigo
                                  ? accumulator + object.numberConfig
                                  : accumulator,
                              0
                            ) +
                            1 >
                            promoConfiguration.cantMaxSets)
                      }
                      onClick={() => handlePushToArrayFinalSetsConfig()}
                    >
                      <div className="button__with__icon__svg__wrapper green__success__ball">
                        <BagIcon />
                      </div>
                      {isLanguageLoaded ? (
                        <span className="button__with__icon__text">
                          {t("configurables__save__selection")}
                        </span>
                      ) : (
                        ""
                      )}
                    </Button>
                  </div>

                  {((typeLine === "VV"
                    ? promoConfiguration.cant_HVariable - totalHeader > 0 ||
                      promoConfiguration.cant_DVariable - totalDetail > 0
                    : typeLine === "VF" || typeLine === "3x2"
                    ? promoConfiguration.cant_HVariable - totalHeader > 0
                    : typeLine === "FV"
                    ? promoConfiguration.cant_DVariable - totalDetail > 0
                    : true) ||
                    (configurableArray.filter(
                      (f) => f.type === "personalizedFragrance"
                    ).length > 0 &&
                      configurableArray.filter(
                        (f) =>
                          f.type === "personalizedFragrance" && !f.item.isSaved
                      ).length > 0)) && (
                    <span className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__slide__content__warning__message">
                      {t("configurables__label_missing_config")}
                    </span>
                  )}
                  {/* AQUI PEGAR */}

                  <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__slide__content__body">
                    <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__slide__content__body__list__wrapper">
                      {finalArray
                        .filter((x) => x.tipoLinea === "C")
                        .map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__slide__content__body__list__wrapper__list__item"
                            >
                              <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__slide__content__body__list__wrapper__list__item__image__wrapper">
                                <img
                                  src={`https://www.websizusa.net/imgprod/fullsize/${item.codart}.jpg`}
                                  alt=""
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src =
                                      "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
                                  }}
                                />
                              </div>
                              <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__slide__content__body__list__wrapper__list__item__text__wrapper">
                                <span className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__slide__content__body__list__wrapper__list__item__text__wrapper__product__title">
                                  {capitalizeText(item.descripcion)}
                                </span>
                                {/* <span className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__slide__content__body__list__wrapper__list__item__text__wrapper__product__price">
                                {`$${parseInt(item.precioai).toFixed(2)}`}
                              </span> */}
                              </div>
                              <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__slide__content__body__list__wrapper__list__item__qty__and__edit__wrapper">
                                <span className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__slide__content__body__list__wrapper__list__item__qty__and__edit__wrapper__qty">
                                  {item.qty}
                                </span>

                                {item.isConfig && (
                                  <Edit
                                    onClick={() => {
                                      const configurablesSwiper =
                                        document.getElementById(
                                          "configurablesSwiper"
                                        );
                                      if (configurablesSwiper) {
                                        configurablesSwiper.swiper.slideTo(
                                          item.pageOrigin
                                        );
                                      }
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  {/* <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__slide__content__footer">
                    <Button
                      className="button__with__icon bg__dark__pink no__left__margin"
                      // onClick={() => {
                      // enqueueSnackbar("CONFIGURASTE EL SET 1 CON EXITO", {
                      //   variant: "success",
                      // });
                      //   setIsSwiperLocked(true);
                      //   setConfiguredSets([
                      //     {
                      //       isSaved: true,
                      //       name: "COLECCIÓN PLAYER INTENSE",
                      //       image:
                      //         "https://www.websizusa.net/imgprod/fullsize/83260.jpg",
                      //       price: 14,
                      //       contents: [
                      //         {
                      //           type: "gift",
                      //           name: "1 gorra del mejor padre",
                      //         },
                      //         {
                      //           type: "included",
                      //           name: "1 player intense for him 120 ml 15 ml",
                      //         },
                      //         {
                      //           type: "included",
                      //           name: "2 player intense for her 120 mls 15 mls",
                      //         },
                      //         {
                      //           type: "included",
                      //           name: "1 player intense on deodorant 85g",
                      //         },
                      //         {
                      //           type: "included",
                      //           name: "2 fragancias personalizadas",
                      //         },
                      //       ],
                      //       personalizedFragrances: [
                      //         {
                      //           id: 1,
                      //           name: "PERSONALIZED FRAGRANCE FOR BOY 1.69 FL. OZ",
                      //           message: "Feliz cumpleaños!!! Te quiero",
                      //         },
                      //         {
                      //           id: 2,
                      //           name: "PERSONALIZED FRAGRANCE FOR GIRL 1.69 FL. OZ",
                      //           message: "La niña más divertida",
                      //         },
                      //       ],
                      //     },
                      //   ]);
                      // }}
                      disabled={
                        //configurableArray VALIDAR PARA BLOQUEAR
                        typeLine === "VV"
                          ? promoConfiguration.cant_HVariable - totalHeader >
                              0 ||
                            promoConfiguration.cant_DVariable - totalDetail > 0
                          : typeLine === "VF" || typeLine === "3x2"
                          ? promoConfiguration.cant_HVariable - totalHeader > 0
                          : typeLine === "FV"
                          ? promoConfiguration.cant_DVariable - totalDetail > 0
                          : true
                      }
                      onClick={() => handlePushToArrayFinalSetsConfig()}
                    >
                      <div className="button__with__icon__svg__wrapper dark__pink__ball">
                        <BagIcon />
                      </div>
                      <span className="button__with__icon__text">
                        {t("configurables__save__selection")}
                      </span>
                    </Button>
                  </div> */}
                </SwiperSlide>
                {/* END SUMMARY SLIDE */}
              </Swiper>
            </div>
          </div>

          <div className="kit__configuration__container__inner__wrapper__configurated__sets">
            <div
              className={`kit__configuration__container__inner__wrapper__set__configuration__wrapper__title__wrapper ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              {isLanguageLoaded ? (
                <Fragment>
                  <span>{t("configurables__my__sets")}</span>
                  {infoCartOrder &&
                    infoCartOrder.lines &&
                    configuredSets &&
                    promoConfiguration &&
                    promoConfiguration.cantMaxSets > 0 &&
                    infoCartOrder.lines.reduce(
                      (accumulator, object) =>
                        object.line.codart === promoConfiguration.codigo
                          ? accumulator + object.line.qtyped
                          : accumulator,
                      0
                    ) +
                      configuredSets.reduce(
                        (accumulator, object) =>
                          object.itemCode === promoConfiguration.codigo
                            ? accumulator + object.numberConfig
                            : accumulator,
                        0
                      ) +
                      1 >
                      promoConfiguration.cantMaxSets && (
                      <span
                        className={`kit__configuration__container__message__warning text__warning ${
                          isTablet ? "tablet" : isMobile ? "mobile" : ""
                        }`}
                      >
                        <WarningIcon />
                        {`${t(
                          "__configurables_warning_information_cant_max_pt1"
                        )} ${promoConfiguration?.cantMaxSets} ${t(
                          "__configurables_warning_information_cant_max_pt2"
                        )}`}
                      </span>
                    )}
                </Fragment>
              ) : (
                <Skeleton variant="rect" width={"30%"} height={30} />
              )}
            </div>

            <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper">
              {configuredSets && configuredSets.length > 0 ? (
                <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__configuration__set__list__wrapper">
                  {configuredSets.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__configuration__set__list__wrapper__item"
                      >
                        <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__configuration__set__list__wrapper__item__tab__wrapper">
                          <span>{`${t("configurables__set__configuration")} ${
                            index + 1
                          }`}</span>
                        </div>
                        <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__configuration__set__list__wrapper__item__content">
                          <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__configuration__set__list__wrapper__item__content__image__wrapper">
                            <img
                              src={item.image}
                              alt=""
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src =
                                  "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
                              }}
                            />
                          </div>
                          <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__configuration__set__list__wrapper__item__content__details__wrapper">
                            {!item.isSaved && (
                              <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__configuration__set__list__wrapper__item__content__details__wrapper__badge__wrapper">
                                <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__configuration__set__list__wrapper__item__content__details__wrapper__badge__wrapper__item warning__type">
                                  <span className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__configuration__set__list__wrapper__item__content__details__wrapper__badge__wrapper__item__text">
                                    {t("configurables__incomplete__selection")}
                                  </span>
                                  <WarningIcon />
                                </div>
                              </div>
                            )}

                            <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__configuration__set__list__wrapper__item__content__details__wrapper__header__wrapper">
                              <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__configuration__set__list__wrapper__item__content__details__wrapper__header__wrapper__product__name__and__price">
                                <span className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__configuration__set__list__wrapper__item__content__details__wrapper__header__wrapper__product__name__and__price__name">
                                  {capitalizeText(item.name)}
                                </span>
                                {typeLine !== "3x2" && (
                                  <span className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__configuration__set__list__wrapper__item__content__details__wrapper__header__wrapper__product__name__and__price__price">
                                    {/* {`$${item.price.toFixed(2)}`} */}
                                    {formatPrice(item.price)}
                                  </span>
                                )}
                              </div>
                              {item.isSaved && (
                                <Counter
                                  className="setKit"
                                  qtyOnOrder={item.numberConfig}
                                  disableAdd={
                                    isKitIngreso ||
                                    (infoCartOrder &&
                                      infoCartOrder.lines &&
                                      configuredSets &&
                                      promoConfiguration &&
                                      promoConfiguration.cantMaxSets > 0 &&
                                      infoCartOrder.lines.reduce(
                                        (accumulator, object) =>
                                          object.line.codart ===
                                          promoConfiguration.codigo
                                            ? accumulator + object.line.qtyped
                                            : accumulator,
                                        0
                                      ) +
                                        configuredSets.reduce(
                                          (accumulator, object) =>
                                            object.itemCode ===
                                            promoConfiguration.codigo
                                              ? accumulator +
                                                object.numberConfig
                                              : accumulator,
                                          0
                                        ) +
                                        1 >
                                        promoConfiguration.cantMaxSets)
                                  }
                                  handleQtyAdd={() => {
                                    // // HANDLE ADD MISMO SET \\ \\
                                    if (
                                      item.personalizedFragrances.length > 0
                                    ) {
                                      setIsOpenDuplicateSetPersonalizedFragrancesWarningModal(
                                        true
                                      );
                                      setIndexDuplicate(index);
                                    } else {
                                      handleAddSameSet(1, index);
                                    }
                                    // modal en caso de encontrar fragancias personalizadas \\
                                    //setIsOpenDuplicateSetPersonalizedFragrancesWarningModal(true); \\ \\
                                  }}
                                  disableMinus={isKitIngreso}
                                  handleQtyMinus={() => {
                                    // // HANDLE DELETE MISMO SET \\ \\
                                    handleDeleteSameSet(1, index);
                                  }}
                                  handleRemove={() => {
                                    // // HANDLE REMOVE ELEMENT \\ \\
                                    handleRemoveSet(index);
                                  }}
                                />
                              )}
                            </div>

                            <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__configuration__set__list__wrapper__item__content__details__wrapper__list__wrapper">
                              {item.contents
                                .filter(
                                  (x) =>
                                    x.tipoLinea === "R" || x.tipoLinea === "C"
                                )
                                .map((listItem, listItemIndex) => {
                                  return (
                                    <div
                                      key={listItemIndex}
                                      className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__configuration__set__list__wrapper__item__content__details__wrapper__list__wrapper__item"
                                    >
                                      {listItem.tipoLinea === "R" ? (
                                        <ConfigurablesGiftIcon />
                                      ) : (
                                        <ConfigurablesIncludedProductIcon />
                                      )}
                                      <span>
                                        {listItem.qtyped}{" "}
                                        {capitalizeText(listItem.descripcion)}
                                      </span>
                                    </div>
                                  );
                                })}
                            </div>

                            {item.personalizedFragrances.length > 0 && (
                              <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__configuration__set__list__wrapper__item__content__details__wrapper__personalized__fragrances__list">
                                <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__configuration__set__list__wrapper__item__content__details__wrapper__personalized__fragrances__list__title">
                                  <span>
                                    {t(
                                      "configurables__message__personalized__fragrances"
                                    )}
                                  </span>
                                </div>
                                {item.personalizedFragrances.map(
                                  (pf, pfIndex) => {
                                    return (
                                      <div
                                        key={pfIndex}
                                        className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__configuration__set__list__wrapper__item__content__details__wrapper__personalized__fragrances__list__item"
                                      >
                                        <div className="kit__configuration__container__inner__wrapper__set__configuration__wrapper__content__wrapper__configuration__set__list__wrapper__item__content__details__wrapper__personalized__fragrances__list__title">
                                          <ConfigurablesPersonalizedFragranceIcon />
                                          <span>{pf.label}</span>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <NotFoundComponent isLanguageLoaded={isLanguageLoaded} />
              )}
            </div>
          </div>
        </div>

        <div className="kit__configuration__container__footer">
          <Button
            className="button__with__icon bg__dark__pink no__left__margin"
            disabled={configuredSets.filter((x) => x.isSaved).length === 0}
            onClick={() => {
              //funcion para validar que todo este seleccionado
              //aquk validar

              let CountInOrder = infoCartOrder.lines.reduce(
                (accumulator, object) =>
                  object.line.codart === promoConfiguration.codigo
                    ? accumulator + object.line.qtyped
                    : accumulator,
                0
              );
              let CountConfig = configuredSets.reduce(
                (accumulator, object) =>
                  object.itemCode === promoConfiguration.codigo
                    ? accumulator + object.numberConfig
                    : accumulator,
                0
              );

              ////regla de cantidad Maxima de Sets
              if (
                promoConfiguration.cantMaxSets > 0 &&
                CountInOrder + CountConfig > promoConfiguration.cantMaxSets
              ) {
                //let e = promoConfiguration.cantMaxSets - (CountInOrder)
                enqueueSnackbar(t("__general_warning_information_cant_max"), {
                  variant: "warning",
                });
              } else {
                handlePushToOrderV2();
              }
            }}
          >
            <div className="button__with__icon__svg__wrapper dark__pink__ball">
              {configuredSets.filter((x) => x.isSaved).length === 0 ? (
                <BagGrayIcon />
              ) : (
                <BagWhiteIcon />
              )}
            </div>
            <span className="button__with__icon__text">
              {t("btn_text_continue_shop")}
            </span>
          </Button>
        </div>

        {/* END VERSION NUEVA */}
      </div>

      <CenteredModal
        open={isOpenDuplicateSetPersonalizedFragrancesWarningModal}
        title={t("configurables__duplicating__set__warning__title")}
        message={t("configurables__duplicating__set__warning__subtitle")}
        handleClose={() => {
          setIsOpenDuplicateSetPersonalizedFragrancesWarningModal(false);
        }}
        handleCancel={() => {
          setIsOpenDuplicateSetPersonalizedFragrancesWarningModal(false);

          handleAddSameSet(1, indexDuplicate);
        }}
        alternateCancelLabel={t("configurables__duplicate__set")}
        handleConfirm={() => {
          setIsOpenDuplicateSetPersonalizedFragrancesWarningModal(false);
          setIsSwiperLocked(false);
        }}
        alternateConfirmLabel={t("configurables__add__new__set")}
      />
    </div>
  );
};

export default NewKitsConfiguration;
