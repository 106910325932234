import React, { Fragment } from "react";
//MUI
import { Button } from "@mui/material";
//Components
import Counter from "./Counter";

const ButtonComponent = ({
  countButton,
  handleActionPrimary,
  disabledPrimary = false,
  handleActionSecondary,
  disabledSecundary = false,
  valueCount,
  iconClass,
  textButton,
  leftAling,
}) => {
  return (
    <Fragment>
      {!countButton ? (
        <Button
          className="button__with__icon bg__dark__pink product__shopping"
          disabled={disabledPrimary}
          onClick={handleActionPrimary}
        >
          {/* NEW */}

          <div className="button__with__icon__svg__wrapper dark__pink__ball">
            <div className={iconClass} />
          </div>
          <span className="button__with__icon__text">{textButton}</span>
        </Button>
      ) : (
        <Counter
          disableMinus={disabledPrimary}
          disableAdd={disabledSecundary}
          handleQtyMinus={handleActionPrimary}
          handleQtyAdd={handleActionSecondary}
          qtyOnOrder={valueCount}
          withTrash={false}
        />
      )}
    </Fragment>
  );
};

export default ButtonComponent;
