import {
  RESPONSE_CART_ADD_MEMBERSHIP,
  RESPONSE_CART_CANCEL_ORDER,
  RESPONSE_CART_COUPON_LIST,
  RESPONSE_CART_CURRENT_ORDER,
  RESPONSE_CART_GET_CLOSED_ORDER,
  RESPONSE_CART_GET_ORDER,
  RESPONSE_CART_ORDER_CHARGES,
  RESPONSE_CLOSE_CART_ORDER,
  RESPONSE_CART_TAX_ORDER,
  RESPONSE_CART_INVENTORY_ASSYGN,
  RESPONSE_CART_GET_GIFT_LIST,
  RESPONSE_CART_ADD_CONFIGURABLE_GIFT,
  REQUEST_CART_ORDER_CLOSE_INFO_CLI,
} from "../../Constants";

export default function cart(state = {}, action) {
  switch (action.type) {
    case RESPONSE_CART_CURRENT_ORDER: {
      const { response, opType } = action.payload;
      const cartCurrentOrder = response;

      if (cartCurrentOrder.code === 100 || cartCurrentOrder.code === 110) {
        return {
          ...state,
          cartCurrentOrder: cartCurrentOrder,
          cartOpType: opType,
        };
      } else {
        console.error(
          "Error al solicitar servicio de carrito",
          cartCurrentOrder
        );
        return {
          ...state,
          errorCartCurrentOrder: true,
          cartCurrentOrder: cartCurrentOrder,
          cartOpType: opType,
        };
      }
    }
    case RESPONSE_CART_TAX_ORDER: {
      const cartTaxOrder = action.payload.response;
      if (cartTaxOrder.code === 100 || cartTaxOrder.code === 100) {
        return { ...state, errorCartTaxOrder: false, cartTaxOrder };
      } else {
        console.error("Error al solicitar servicio de carrito", cartTaxOrder);
        return { ...state, errorCartTaxOrder: true };
      }
    }
    case RESPONSE_CART_INVENTORY_ASSYGN: {
      const cartInventoryAssign = action.payload.response;
      if (
        cartInventoryAssign.code === 100 ||
        cartInventoryAssign.code === 100
      ) {
        return {
          ...state,
          errorCartInventoryAssign: false,
          cartInventoryAssign,
        };
      } else {
        console.error(
          "Error al solicitar servicio de carrito",
          cartInventoryAssign
        );
        return { ...state, errorCartInventoryAssign: true };
      }
    }
    case RESPONSE_CART_GET_ORDER: {
      const cartOrder = action.payload.response;
      if (cartOrder.code === 100 || cartOrder.code === 100) {
        return { ...state, errorCartOrder: false, cartOrder: cartOrder };
      } else {
        console.error("Error al solicitar la orden de carrito", cartOrder);
        return { ...state, errorCartOrder: true };
      }
    }
    case RESPONSE_CART_COUPON_LIST: {
      const cartCouponList = action.payload.response;

      return { ...state, cartCouponList };
    }
    case RESPONSE_CART_CANCEL_ORDER: {
      const cartCancelOrder = action.payload.response;
      if (cartCancelOrder.code === 100 || cartCancelOrder.code === 100) {
        return { ...state, cartCancelOrder: cartCancelOrder };
      } else {
        console.error(
          "Error al solicitar la orden de carrito",
          cartCancelOrder
        );
        return { ...state, errorCartCancelOrder: true };
      }
    }
    case RESPONSE_CART_ORDER_CHARGES: {
      const cartOrderCharges = action.payload.response;
      if (cartOrderCharges.code === 100 || cartOrderCharges.code === 100) {
        return {
          ...state,
          errorCartOrderCharges: false,
          cartOrderCharges: cartOrderCharges,
        };
      } else {
        console.error(
          "Error al solicitar un cambios a los cargos de la orden de carrito",
          cartOrderCharges
        );
        return { ...state, errorCartOrderCharges: true };
      }
    }
    case RESPONSE_CART_ADD_MEMBERSHIP: {
      const cartAddMembership = action.payload.response;
      if (cartAddMembership.code === 100 || cartAddMembership.code === 100) {
        return { ...state, cartAddMembership: cartAddMembership };
      } else {
        console.error(
          "Error al solicitar un cambios a los cargos de la orden de carrito",
          cartAddMembership
        );
        return { ...state, errorCartAddMembership: true };
      }
    }
    case RESPONSE_CLOSE_CART_ORDER: {
      const closeCartOrder = action.payload.response;
      if (closeCartOrder.code === 100 || closeCartOrder.code === 100) {
        // const clientLogin = closeCartOrder.user;
        // localStorage.setItem("ZUsaUGuestId", clientLogin.idGuestUser);
        // localStorage.setItem("ZUsaUInfo", JSON.stringify(clientLogin));
        // localStorage.setItem("ZUsaIdPage", clientLogin.idPage);
        return { ...state, closeCartOrder: closeCartOrder };
      } else {
        console.error(
          "Error al solicitar un cambios a los cargos de la orden de carrito",
          closeCartOrder
        );
        return { ...state, errorCloseCartOrder: true, closeCartOrder };
      }
    }
    case RESPONSE_CART_GET_CLOSED_ORDER: {
      const cartClosedOrder = action.payload.response;
      if (cartClosedOrder.code === 100) {
        return { ...state, cartClosedOrder: cartClosedOrder };
      } else {
        console.error(
          "Error al solicitar la orden de carrito",
          cartClosedOrder
        );
        return { ...state, errorCartClosedOrder: true };
      }
    }

    case RESPONSE_CART_GET_GIFT_LIST: {
      const cartGetGiftList = action.payload.response;
      if (cartGetGiftList.code === 100) {
        return { ...state, cartGetGiftList: cartGetGiftList };
      } else {
        console.error("Error al obtener lista de regalos", cartGetGiftList);
        return { ...state, errorCartGetGiftList: true };
      }
    }

    case RESPONSE_CART_ADD_CONFIGURABLE_GIFT: {
      const cartAddConfigurableGift = action.payload.response;
      if (cartAddConfigurableGift.code === 100) {
        return { ...state, cartAddConfigurableGift: cartAddConfigurableGift };
      } else {
        console.error(
          "Error al insertar lista de regalos",
          cartAddConfigurableGift
        );
        return { ...state, errorCartAddConfigurableGift: true };
      }
    }

    case REQUEST_CART_ORDER_CLOSE_INFO_CLI: {
      const OrderCloseInfoCli = action.payload.parameters;
      if (OrderCloseInfoCli) {
        return { ...state, cartOrderCloseInfoCli: OrderCloseInfoCli };
      } else {
        console.error("Error al insertar lista de regalos", OrderCloseInfoCli);
        return { ...state, errorCartOrderCloseInfoCli: true };
      }
    }

    default:
      return { ...state };
  }
}
